import { ConfirmedIcon, PODIconV2, TelexIconV2, TransshipmentIconV2 } from "../../../../../assets/icons";
import { ETransportModes } from "../../../../../models";
const ICONS = [<ConfirmedIcon />, <TelexIconV2 />, <TransshipmentIconV2 />, <PODIconV2 />];

const LABELS = [
    "Pending Customs Release",
    "Pending Telex Release",
    "Transshipment",
    "POD Received",
    "Customs Released",
    "Telex Received",
    "Pending POD",
];

export const STRINGS = {
    completed: "completed",
    direct: "Direct",
    packages: "Packages",
    containers: "Containers",
    cbm: "cbm",
    kg: "kg",
    voyageNumber: "Voyage No.",
    vesselName: "Vessel",
    flightNumber: "Flight No.",
    flightName: "Airline",
};
export const ICONS_DICT: Record<ETransportModes, JSX.Element[]> = {
    [ETransportModes.Inland]: [ICONS[3]],
    [ETransportModes.Ocean]: [ICONS[0], ICONS[1], ICONS[2], ICONS[3]],
    [ETransportModes.Air]: [ICONS[0], ICONS[2], ICONS[3]],
};

export const LABELS_DICT: { [key: string]: string[] } = {
    Inland: [LABELS[3], LABELS[6]],
    Ocean: [...LABELS],
    Air: [LABELS[0], LABELS[2], LABELS[3], LABELS[4], LABELS[6]],
};
