import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../assets/data/ui";
import { theme } from "../../../assets/theme/theme";

const useStyles = makeStyles({
    formWrapper: {
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        padding: "32px",
        margin: "0px auto",
        maxWidth: "720px",
        width: "100%",
    },
    button: {
        width: "150px",
        margin: "0px auto !important",
    },
    noBoxShadow: {
        boxShadow: "none !important"
    },
    arrowIcon: {
        height: "10px",
        width: "10px",
    },
    link: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "17px",
        color: `${COLORS.main}`,
        textDecoration: "underline",
        textUnderlineOffset: "2px",
    },
    headerWrapper: {
        margin: "0px auto",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
    },
    header: {
        padding: "10px",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        marginBottom: "-20px",
        color: `${COLORS.main}`,
    },
    subHeader: {
        padding: "10px",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "17px",
        marginTop: "20px",
        color: `${COLORS.main}`,
    },
    textButton: {
        cursor: "pointer",
        marginInlineStart: "0.2rem",
        textDecoration: "underline",
    },
    inputsWrapper: {
        margin: "0px auto",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "24px",
        maxWidth: "495px",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "column",
            minWidth: "100%",
        },
    },
    inputWrapper: {
        width: "239px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    checkBoxWrapper: {
        margin: "0px auto",
        display: "flex",
        flexDirection: "column",
        maxWidth: "495px",
        minWidth: "400px",
        width: "100%",
        paddingInlineStart: "30px",
        alignItems: "flex-start",
        [theme.breakpoints.down("sm")]: {
            minWidth: "100%",
            gap: "20px",
            paddingInlineStart: "0px",
        },
    },
    phoneCover: {
        width: "50px",
        height: "100%",
        position: "absolute",
        background: "rgba(0, 0, 0, 0.08)",
        zIndex: "-1",
    },
    temsAndConditions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "2px",
    },
    temsAndConditionsLink: {
        textUnderlineOffset: "3px",
        textDecoration: "underline",
        cursor: "pointer",
    },
});
export interface ILoginValues {
    email: string;
    password: string;
}

export default useStyles;
