import { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "./PaymentsPage.module.scss";
import { ReactComponent as DecorativeLine1 } from "../../assets/icons/decorative-line-1.svg";
import Summary from "../../components/Summary/Summary";
import { ScreenWidthType } from "../../assets/data/ui";
import { useScreenWidthType } from "../../helpers/custom-hooks";
import { useAppDispatch, useAppSelector } from "../../store";
import { useLazyGetInvoicesByCustomerCodeQuery } from "../../store/api-slices/invoicesSlice.api";
import { InvoicesTable } from "../../components/InvoicesTable/InvoicesTable";
import {
    FilterTypes,
    IFilterOption,
    InvoicesFilterKeyNames,
    InvoicesFilterLabels,
} from "../../models/filters/filters.interfaces";
import {
    initialState,
    setFilters,
    setPaymentStage,
    setSelectedInvoices,
} from "../../store/reducers/invoicesSlice.store";
import { PaymentStage, ValidInvoiceStatus } from "../../models/invoices/invoices.interfaces";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { userLogOut } from "../../store/reducers/usersSlice.store";
import { tryTrackEvent } from "../../helpers/services/MixPanelService";

const PaymentsPage: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;
    const [searchParams] = useSearchParams();
    const invoiceNumber = searchParams.get("invoiceNumber");
    const headRef = useRef<HTMLDivElement>(null);

    const userStateCustomerName = useAppSelector((state) => state.rootReducer.users.customerName);
    const invoicesState = useAppSelector((state) => state.rootReducer.invoices);
    const customerCode = useAppSelector((state) => state.rootReducer.users.code);
    const selectedInvoices = invoicesState.selectedInvoices;
    const paymentStage = invoicesState.paymentStage;
    const page = invoicesState.page;
    const limit = invoicesState.limit;
    const filters = invoicesState.filters;
    const sort = invoicesState.sort;
    const search = filters.find((filter) => filter.keyName === InvoicesFilterKeyNames.SEARCH)?.options[0]
        .value as string;

    const [
        getInvoices,
        {
            data: invoicesData,
            error: errorFetchingInvoices,
            isLoading: isLoadingInvoices,
            isFetching: isFetchingInvoices,
            isSuccess: isSuccessFetchingInvoices,
            isError: isErrorFetchingInvoices,
        },
    ] = useLazyGetInvoicesByCustomerCodeQuery();
    const [invoices, setInvoices] = useState(invoicesData?.invoices);
    const [summary, setSummary] = useState(invoicesData?.summary);

    const [
        getQuickSelectionInvoices,
        {
            data: quickSelectionData,
            isFetching: isFetchingQuickSelection,
            isSuccess: isSuccessQuickSelection,
            isError: isErrorQuickSelection,
        },
    ] = useLazyGetInvoicesByCustomerCodeQuery();

    const handleQuickSelectionBtnClick = (currencyAcronym: string) => {
        tryTrackEvent("[Payments Page]: 'Quick selection' button clicked");
        // On click, get all Unpaid invoices (including Credit) of the selected currency and set them as the selectedInvoices
        getQuickSelectionInvoices({
            customerCode,
            params: {
                sortOrder: initialState.sort.sortOrder,
                sortType: initialState.sort.sortType,
                search: "",
                page: -1, // -1 means no pagination
                limit: -1, // -1 means no pagination
                filters: [
                    {
                        keyName: InvoicesFilterKeyNames.STATUS,
                        label: InvoicesFilterLabels.STATUS,
                        filterType: FilterTypes.MULTI_CHOICE_SELECT,
                        includeAllOption: false,
                        options: [
                            {
                                label: ValidInvoiceStatus.UNPAID,
                                isSelected: true,
                                value: ValidInvoiceStatus.UNPAID,
                            },
                        ],
                    },
                    {
                        keyName: InvoicesFilterKeyNames.CURRENCY,
                        label: InvoicesFilterLabels.CURRENCY,
                        filterType: FilterTypes.MULTI_CHOICE_SELECT,
                        includeAllOption: false,
                        options: [
                            {
                                label: currencyAcronym,
                                isSelected: true,
                                value: currencyAcronym,
                            },
                        ],
                    },
                ],
            },
        });
    };

    useEffect(() => {
        tryTrackEvent("[Payments Page]: Page viewed");
    }, []);

    useEffect(() => {
        if (isErrorQuickSelection) return;
        if (isSuccessQuickSelection && quickSelectionData?.invoices)
            dispatch(
                setSelectedInvoices(
                    // Filter out invoices that are not overdue
                    quickSelectionData.invoices.filter((invoice) => dayjs(invoice.dueDate).isBefore(dayjs())),
                ),
            );
    }, [isFetchingQuickSelection, isErrorQuickSelection, isSuccessQuickSelection, quickSelectionData]);

    useEffect(() => {
        // When the account (customerCode) changes, clear selected invoices
        dispatch(setSelectedInvoices([]));
    }, [customerCode]);

    useEffect(() => {
        if (isMobile) navigate("/overview");
    }, [isMobile]);

    useEffect(() => {
        if (isErrorFetchingInvoices && errorFetchingInvoices) {
            console.log("error fetching invoices", errorFetchingInvoices);
            if ("status" in errorFetchingInvoices && errorFetchingInvoices.status === 403) {
                dispatch(userLogOut());
            }

            return;
        }
        if (isFetchingInvoices || isLoadingInvoices) {
            return;
        }
        if (isSuccessFetchingInvoices) {
            setInvoices(invoicesData?.invoices);
            setSummary(invoicesData?.summary);
        }
    }, [isFetchingInvoices, isLoadingInvoices, isErrorFetchingInvoices, isSuccessFetchingInvoices, invoicesData]);

    useEffect(() => {
        dispatch(setPaymentStage(PaymentStage.InitialSelectingStage));
        getInvoices(
            {
                customerCode,
                params: {
                    page,
                    limit,
                    filters,
                    ...sort,
                    search,
                },
            },
            true,
        );
    }, [filters, sort, page, limit]);

    useEffect(() => {
        const quickSelection = searchParams.get("quickSelection");
        const currencyParam = searchParams.get("currency");

        // If invoiceNumber exists in query parameters, search for this invoiceNumber
        if (invoiceNumber) {
            dispatch(
                setFilters(
                    filters.map((filter) =>
                        filter.keyName === InvoicesFilterKeyNames.SEARCH
                            ? {
                                  ...filter,
                                  options: [{ isSelected: false, label: "Search", value: invoiceNumber }],
                              }
                            : filter.keyName === InvoicesFilterKeyNames.STATUS
                            ? {
                                  ...filter,
                                  options: filter.options.map((option, index) => ({ ...option, isSelected: false })),
                              }
                            : filter,
                    ),
                ),
            );
            return;
        }
        // If quickSelection and currencyParam exist in query parameters, handle quick selection
        if (quickSelection && currencyParam) {
            handleQuickSelectionBtnClick(currencyParam);
            return;
        }
        // compare the summaryData currency names with the currencies in the filters
        const newSummaryDataCurrencies = summary?.currencies?.map((currency) => currency.currencyAcronym) || [];
        const newFiltersCurrencies =
            filters
                .find((filter) => filter.keyName === InvoicesFilterKeyNames.CURRENCY)
                ?.options.map((option) => option.value) || [];
        if (newSummaryDataCurrencies.length === newFiltersCurrencies.length) {
            const isSame = newSummaryDataCurrencies.every((currency) => newFiltersCurrencies.includes(currency));
            if (isSame) {
                return;
            }
        }
        const currenciesOptions: IFilterOption[] =
            summary?.currencies?.map((currency) => {
                return {
                    label: currency.currencyAcronym,
                    isSelected: false,
                    value: currency.currencyAcronym,
                };
            }) || [];
        dispatch(
            setFilters(
                filters.map((filter) => {
                    if (filter.keyName === InvoicesFilterKeyNames.CURRENCY) {
                        return {
                            ...filter,
                            options: currenciesOptions,
                        };
                    }
                    return filter;
                }),
            ),
        );
    }, [summary]);

    const renderHead = () => {
        return (
            <div className={styles.PageHead} ref={headRef}>
                <h1 className={styles.HeaderTitle}>
                    Payments {userStateCustomerName ? "|" : ""}
                    <span className={styles.HeaderTitleSpan}>&nbsp;{userStateCustomerName}</span>
                </h1>
                <DecorativeLine1 />
            </div>
        );
    };

    const renderBody = () => {
        const headRefHeight = headRef.current?.offsetHeight;
        const bodyRefHeightStr = `calc(98% - ${headRefHeight}px)`;

        return (
            <div className={styles.PageBody} style={{ height: bodyRefHeightStr, maxHeight: "100%" }}>
                <Summary
                    summaryData={summary}
                    isLoading={!summary || isFetchingQuickSelection}
                    isError={isErrorFetchingInvoices || isErrorQuickSelection}
                    onQuickSelectionBtnClick={handleQuickSelectionBtnClick}
                />
                <InvoicesTable
                    invoices={paymentStage !== PaymentStage.InitialSelectingStage ? selectedInvoices : invoices}
                    summaryData={summary}
                    isError={isErrorFetchingInvoices || isErrorQuickSelection}
                    isLoading={isFetchingInvoices || isLoadingInvoices || isFetchingQuickSelection}
                    invoiceNumber={invoiceNumber}
                />
            </div>
        );
    };

    return (
        <section className={styles.root}>
            {renderHead()}
            {renderBody()}
        </section>
    );
};

export default PaymentsPage;
