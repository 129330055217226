import { Step, StepLabel, Stepper } from "@mui/material";
import { useMemo, useState } from "react";
import { ArrowNotificationIcon } from "../../../assets/icons";
import { MuiTheme } from "../../../helpers";
import { ETransportModes, IShipmentStep, IShipmentStepper, IStepProps } from "../../../models";
import { ACTIVE_STEP_LABELS, NEXT_STEP_LABELS, STRINGS } from "../resources";
import { StepsService } from "../services/StepsService";
import useStyles from "./useStyles";
import { getFormattedDate } from "../../../helpers/services";

export const MobileStepper = ({
    steps,
    activeStep = 0,
    fromLocation,
    destLocation,
    transportMode,
    shipmentDetails,
    ...rest
}: IShipmentStepper) => {
    const classes = useStyles();
    const stepsService = useMemo(() => {
        return new StepsService(activeStep, steps, transportMode);
    }, [steps, activeStep]);
    const [activeStepObj, setActiveStepObj] = useState<IShipmentStep>();
    const [nextStepObj, setNextStepObj] = useState<IShipmentStep>();

    const renderSteps = useMemo(() => {
        const stepsArray: JSX.Element[] = [];

        // First label is only for city & country in cases of Ocean & Air transports.
        if (transportMode !== ETransportModes.Inland) {
            stepsArray.push(<Step className={classes.stepMobile} key={"location-step"} completed />);
        }

        // loop over the actual steps and add them to the steps array.
        steps.forEach((step, i) => {
            const stepProps: IStepProps = {};
            let isHidden = false;
            // logic of INLAND steps is a bit different
            if (i === 0 && transportMode === ETransportModes.Inland) {
                i = -1;
            }
            if (stepsService.isStepCompleted(i)) {
                stepProps.completed = true;
                // If not the first index of a shipment of type 'Inland'.
                isHidden = true;
            } else if (stepsService.isStepActive(i)) {
                stepProps.active = true;
                setActiveStepObj(step);
            } else {
                if (stepsService.isNextStep(i)) {
                    setNextStepObj(step);
                }
                isHidden = true;
            }

            stepsArray.push(
                <Step
                    sx={isHidden ? { display: "none" } : { display: "block" }}
                    className={classes.stepMobile}
                    key={i}
                    {...stepProps} // TODO throws a warning in the console, fix it
                >
                    <StepLabel className={classes.stepLabel} StepIconComponent={stepsService.getStepIcon(i)} />
                </Step>,
            );
        });

        return stepsArray;
    }, [steps]);

    const pickupData = useMemo(() => {
        return steps.find((step) => step.stepName == "pickupForDeliveryArrival");
    }, [nextStepObj, activeStepObj, renderSteps]);

    const renderActiveLabel = useMemo(() => {
        const depCity = fromLocation?.city;
        const depCountry = fromLocation?.country;
        if (activeStepObj && !pickupData?.ATA && ACTIVE_STEP_LABELS[activeStepObj.stepName]) {
            const { ETD, ATD, ETA, ATA } = activeStepObj;
            const date = ATA || ETA || ATD || ETD || STRINGS.NULL_MSG;
            /* TODO - MAKE SURE THAT IF THE ESTIMATED IS AN ARRAY, WE NEED TO DISPLAY THE LAST ELEMENT ??? */
            const formattedDate = new Date(Array.isArray(date) ? date.slice(-1)[0] : date || "").toLocaleDateString(
                "en-US",
                {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                },
            );
            return (
                <div>
                    {ACTIVE_STEP_LABELS[activeStepObj.stepName]}{" "}
                    <span className={classes.activeStepDate}>
                        {formattedDate === "Invalid Date" ? STRINGS.NULL_MSG : formattedDate}
                    </span>
                </div>
            );
        }
        return (
            <div>
                {depCity && depCity} <span className={classes.activeStepDate}>{depCountry && depCountry}</span>
            </div>
        );
    }, [nextStepObj, activeStepObj, renderSteps]);

    const renderNextLabel = useMemo(() => {
        const arrivalCity = destLocation?.city;
        const arrivalCountry = destLocation?.country;

        if (nextStepObj && !pickupData?.ATA) {
            const { ETD, ATD, ETA, ATA } = nextStepObj;
            let date = ATA || ETA || ATD || ETD || STRINGS.NULL_MSG;
            // ! A business decision to never show the actual value of the last step's ATA.

            const formattedDate = new Date(Array.isArray(date) ? date.slice(-1)[0] : date || "").toLocaleDateString(
                "en-US",
                {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                },
            );

            if (nextStepObj.stepName === "pickupForDeliveryArrival") {
                date = STRINGS.NULL_MSG;
            }
            return (
                <div>
                    {NEXT_STEP_LABELS[nextStepObj.stepName] || STRINGS.NULL_MSG}{" "}
                    <span className={classes.nextStepDate}>
                        {formattedDate === "Invalid Date" ? STRINGS.NULL_MSG : formattedDate}
                    </span>
                </div>
            );
        }

        if (pickupData && arrivalCity && arrivalCountry) {
            return (
                <div>
                    <div>
                        {arrivalCity} <span className={classes.activeStepDate}>{arrivalCountry}</span>
                        {pickupData.ATA && <div>{getFormattedDate(pickupData.ATA)}</div>}
                    </div>
                </div>
            );
        }

        const lastStep = steps[steps.length - 1];
        if (lastStep != undefined && lastStep.stepName === "pickupForDelivery" && lastStep.ATA) {
            return (
                <div className={classes.detailsContainer}>
                    <div>
                        {arrivalCity} {arrivalCountry}
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div> {STRINGS.NULL_MSG}</div>
            </div>
        );
    }, [nextStepObj, activeStepObj, renderSteps]);

    const renderNextStepLabel = useMemo(
        () => nextStepObj && !pickupData?.ATA && STRINGS.NEXT_STEP,
        [nextStepObj, activeStepObj, renderSteps],
    );

    return (
        <div className={classes.root}>
            <div className={classes.stepperContainer}>
                <MuiTheme>
                    <Stepper className={classes.stepperRoot} activeStep={activeStep} {...rest}>
                        {renderSteps}
                    </Stepper>
                </MuiTheme>
            </div>
            <div className={classes.detailsContainer}>
                <div className={classes.nextStepLabel}>{renderNextStepLabel}</div>
                <div className={classes.detailsContentContainer}>
                    <div className={classes.activeStepDetails}>{renderActiveLabel}</div>
                    <div className={classes.detailsContentIcon}>{<ArrowNotificationIcon />}</div>
                    <div className={classes.nextStepDetails}>{renderNextLabel}</div>
                </div>
            </div>
        </div>
    );
};
