import { useState, useEffect } from "react";
import { useAppDispatch } from "../../store";
import GenericButton, { ButtonTypeStyles } from "../buttons/GenericButton/GenericButton";
import { IInvoice, PaymentStage } from "../../models/invoices/invoices.interfaces";
import { CrossIcon } from "../../assets/icons";
import { IInvoicesSliceState, resetInvoicesSlice, setPaymentStage } from "../../store/reducers/invoicesSlice.store";
import { formatNumberWithDecimal } from "../../helpers/services/DateFormatsService.service";
import TextButton from "../buttons/text-button/TextButton";
import { tryTrackEvent } from "../../helpers/services/MixPanelService";

interface IPaymentAmountContainerProps {
    paymentStage: PaymentStage;
    selectedInvoices: IInvoice[];
    onNextBtnClicked: () => void;
    onPayBtnClicked: () => void;
}

const PaymentAmountContainer = ({
    paymentStage,
    selectedInvoices,
    onNextBtnClicked,
    onPayBtnClicked,
}: IPaymentAmountContainerProps) => {
    const dispatch = useAppDispatch();
    const [sumOfAmountDueToPay, setSumOfAmountDueToPay] = useState<number>(0);

    useEffect(() => {
        const sumOfAmountDueOfSelectedInvoices = selectedInvoices.reduce(
            (sumOfAmountDue, invoice) => sumOfAmountDue + invoice.amountDue,
            0,
        );

        setSumOfAmountDueToPay(sumOfAmountDueOfSelectedInvoices);
    }, [selectedInvoices]);

    return (
        <section className={`PaymentAmountContainer ${selectedInvoices.length > 0 && "ShowPaymentBar"}`}>
            {paymentStage === PaymentStage.InitialSelectingStage ? (
                <div
                    className="ClearSelectionBtnContainer no-select"
                    onClick={() => dispatch(resetInvoicesSlice(IInvoicesSliceState.InitialState))}
                >
                    <CrossIcon className="ClearSelectionBtnCross" width={10} height={10} />
                    <div className="ClearSelectionBtn">Clear Selection</div>
                </div>
            ) : (
                <div
                    className="BackToStageBtnContainer"
                    onClick={() => {
                        tryTrackEvent("[Payments Page] 'Back' button clicked", {
                            elementName: "Back button",
                            flow: "Payment",
                            currentStage: paymentStage,
                            newStage: PaymentStage.InitialSelectingStage,
                        });
                        dispatch(setPaymentStage(PaymentStage.InitialSelectingStage));
                    }}
                >
                    <TextButton className="BackToStageBtn" withArrowLeft>
                        Back
                    </TextButton>
                </div>
            )}
            <div className={"TotalAmountToPayContainer"}>
                <div className="TotalAmountToPayText">
                    Total
                    <span className="Amount">
                        {selectedInvoices[0] ? selectedInvoices[0].currencySign : ""}
                        {formatNumberWithDecimal(sumOfAmountDueToPay, 2)}
                    </span>
                </div>
                {paymentStage === PaymentStage.InitialSelectingStage ? (
                    <GenericButton
                        text={"Next"}
                        initialBtnTypeStyle={ButtonTypeStyles.LightGreenStyle}
                        onClick={onNextBtnClicked}
                        isDisabled={sumOfAmountDueToPay <= 0}
                    />
                ) : (
                    <GenericButton
                        text={"Pay"}
                        initialBtnTypeStyle={ButtonTypeStyles.LightGreenStyle}
                        onClick={onPayBtnClicked}
                        isDisabled={sumOfAmountDueToPay <= 0}
                    />
                )}
            </div>
        </section>
    );
};

export default PaymentAmountContainer;
