import { useCallback, useEffect, useRef } from "react";

interface IUseTimeoutReturnParams {
    /**
     * @description Resets the timeout to the initial delay and restarts the countdown
     * @returns {void}
     */
    reset: () => void;
    /**
     * @description Clears the timeout and stops the countdown
     * @returns {void}
     */
    clear: () => void;
}

/**
 * @description A custom hook to create and handle a timeout.
 * @param callback A callback function to be called after the delay
 * @param delay Delay in milliseconds
 * @param delayTimeUnit The time unit of the delay
 * @returns {IUseTimeoutReturnParams} An object with reset and clear functions
 */
export default function useTimeout(
    callback: () => void,
    delay: number,
    delayTimeUnit: "seconds" | "minutes",
): IUseTimeoutReturnParams {
    const ONE_SECOND = 1000;
    const ONE_MINUTE = 60 * ONE_SECOND;
    const delayInMilliseconds = delay * (delayTimeUnit === "seconds" ? ONE_SECOND : ONE_MINUTE);
    const callbackRef = useRef(callback);
    const timeoutRef = useRef<NodeJS.Timeout>();
    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);
    const set = useCallback(() => {
        timeoutRef.current = setTimeout(() => callbackRef.current(), delayInMilliseconds);
    }, [delay]);
    const clear = useCallback(() => {
        timeoutRef.current && clearTimeout(timeoutRef.current);
    }, []);
    useEffect(() => {
        set();
        return clear;
    }, [delay, set, clear]);
    const reset = useCallback(() => {
        clear();
        set();
    }, [clear, set]);
    return { reset, clear };
}
