import { Fragment, FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "./UnicargoDateRangeFilter.module.scss";
import { ArrowDownIconStiff as ArrowIcon } from "../../assets/icons";
import { IconButton } from "@mui/material";
import { LineSeparator } from "../ui-decorations/LineSeparator/LineSeparator";
import { useOutsideClick } from "../../helpers/custom-hooks/useOutsideClick";
import {
    CalendarPurpose,
    DateRangeFilterOptions,
    IDateRangeFilter,
    IDateRangeFilterOption,
    YYYY_MM_DD,
} from "../../models/filters/filters.interfaces";
import {
    getAllOptionsWithUpdatedOption,
    getDateRangeFilterCustomDates,
    getDatesFromSelectedOptions,
    getSelectedOptionsFromAllOptions,
} from "../../helpers/services/FiltersService.service";
import { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

interface UnicargoDateRangeFilterProps {
    dateRangeFilter: IDateRangeFilter;
    onChange: (newFilter: IDateRangeFilter) => void;
}

const UnicargoDateRangeFilter: FunctionComponent<UnicargoDateRangeFilterProps> = ({
    dateRangeFilter,
    onChange,
}: UnicargoDateRangeFilterProps) => {
    const [allOptions, setAllOptions] = useState<IDateRangeFilterOption[]>(dateRangeFilter.options);
    const [selectedOptions, setSelectedOptions] = useState<IDateRangeFilterOption[]>(
        getSelectedOptionsFromAllOptions(dateRangeFilter.options) as IDateRangeFilterOption[],
    );
    const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
    const [tagValue, setTagValue] = useState<string>("");
    const [isCustomDateRangeCalendarOpen, setIsCustomDateRangeCalendarOpen] = useState<boolean>(false);
    const [currentOpenedCalendarPurpose, setCurrentOpenedCalendarPurpose] = useState<CalendarPurpose>(
        CalendarPurpose.START_DATE,
    );
    const UnicargoDateRangeFilterRef = useRef<HTMLDivElement>(null);
    const initialCustomDates = getDateRangeFilterCustomDates(dateRangeFilter);
    const [customStartDate, setCustomStartDate] = useState<Dayjs | null>(initialCustomDates[0]);
    const [customEndDate, setCustomEndDate] = useState<Dayjs | null>(initialCustomDates[1]);

    useEffect(() => {
        setAllOptions(dateRangeFilter.options);
    }, [dateRangeFilter]);

    useEffect(() => {
        if (!customStartDate || !customEndDate) {
            return;
        }
        setCurrentOpenedCalendarPurpose(CalendarPurpose.START_DATE);
        setIsCustomDateRangeCalendarOpen(false);
        setIsOptionsMenuOpen(false);
        setAllOptions(
            getAllOptionsWithUpdatedOption(
                allOptions,
                {
                    label: DateRangeFilterOptions.Custom,
                    isSelected: true,
                    value: [customStartDate.format(YYYY_MM_DD), customEndDate.format(YYYY_MM_DD)],
                },
                true,
            ) as IDateRangeFilterOption[],
        );
    }, [customEndDate]);

    // If the 'allOptions' array changes, we want to update the 'selectedOptions' array accordingly
    useEffect(() => {
        setSelectedOptions(getSelectedOptionsFromAllOptions(allOptions) as IDateRangeFilterOption[]);
        onChange({
            ...dateRangeFilter,
            options: allOptions,
        });
    }, [allOptions]);

    useEffect(() => {
        let newCustomStartDate: Dayjs | null = null;
        let newCustomEndDate: Dayjs | null = null;
        let newTagValue = "";
        switch (selectedOptions.length) {
            // If no options are selected, we don't want to show the tag value
            case 0:
                break;
            case 1:
                switch (selectedOptions[0].label) {
                    // If the selected option is "All Dates", we don't want to show the tag value
                    case DateRangeFilterOptions.AllDates:
                        break;
                    // If the selected option is "Custom", we want to show the tag value as the selected dates
                    case DateRangeFilterOptions.Custom:
                        newTagValue = `${customStartDate?.format("MMM DD, YYYY")} - ${customEndDate?.format(
                            "MMM DD, YYYY",
                        )}`;
                        newCustomStartDate = customStartDate;
                        newCustomEndDate = customEndDate;
                        break;
                    // If the selected option is any other option, we want to show the tag value as the option label
                    default:
                        newTagValue = selectedOptions[0].label;
                        break;
                }
                break;
            // More than one option selected is not possible in this filter
            default:
                newTagValue = "Invalid multiple selection";
        }
        setTagValue(newTagValue);
        setCustomStartDate(newCustomStartDate);
        setCustomEndDate(newCustomEndDate);
    }, [selectedOptions]);

    const renderTagView = () => {
        return (
            <div
                className={`${styles.ValueTag} ${tagValue && styles.Selected} ${
                    selectedOptions.length > 1 && styles.MultipleSelected
                }`}
            >
                {tagValue}
            </div>
        );
    };

    useOutsideClick(UnicargoDateRangeFilterRef, () => {
        setIsOptionsMenuOpen(false);
        setIsCustomDateRangeCalendarOpen(false);
    });

    const handleSelectClick = () => {
        setIsOptionsMenuOpen(!isOptionsMenuOpen);
        setIsCustomDateRangeCalendarOpen(false);
    };

    const handleOptionClick = (clickedOption: IDateRangeFilterOption) => {
        const updatedOption: IDateRangeFilterOption = {
            ...clickedOption,
            isSelected: !clickedOption.isSelected,
            value: DateRangeFilterOptions.AllDates,
        };
        // If the clicked option is already selected, we want to unselect it
        if (clickedOption.isSelected && clickedOption.label !== DateRangeFilterOptions.Custom) {
            updatedOption.value = DateRangeFilterOptions.AllDates;
            setAllOptions(getAllOptionsWithUpdatedOption(allOptions, updatedOption, true) as IDateRangeFilterOption[]);
            setCustomStartDate(null);
            setCustomEndDate(null);
            setIsCustomDateRangeCalendarOpen(false);
            return;
        }

        const newDates = getDatesFromSelectedOptions(updatedOption.label);

        // If the clicked option is "Custom", toggle the visibility of the custom date range calendars
        if (newDates === DateRangeFilterOptions.Custom) {
            setIsCustomDateRangeCalendarOpen(!isCustomDateRangeCalendarOpen);
            return;
        }

        // If the clicked option is any other option, we want to update the selected dates accordingly
        updatedOption.value = newDates;
        setAllOptions(getAllOptionsWithUpdatedOption(allOptions, updatedOption, true) as IDateRangeFilterOption[]);
        setIsCustomDateRangeCalendarOpen(false);
        setCustomStartDate(null);
        setCustomEndDate(null);
    };

    const renderOptionsMenu = () => {
        return (
            <div className={`${styles.OptionsMenu} ${isOptionsMenuOpen && styles.Opened}`}>
                {allOptions.map((option, index) => {
                    switch (option.label) {
                        case DateRangeFilterOptions.AllDates:
                            return (
                                <Fragment key={`${option.label}-${index}`}>
                                    <div
                                        className={`${styles.OptionContainer} ${styles.SingleChoiceSelect} ${styles.SelectAllOption}`}
                                        onClick={() => handleOptionClick(option)}
                                    >
                                        <div className={styles.OptionLabel}>{option.label}</div>
                                    </div>
                                    <LineSeparator className={styles.LineSeparator} width="100%" height="1px" />
                                </Fragment>
                            );
                        case DateRangeFilterOptions.Custom:
                            return (
                                <Fragment key={`${option.label}-${index}`}>
                                    <div
                                        className={`${styles.OptionContainer} ${styles.SingleChoiceSelect} ${styles.CustomSelectOption}`}
                                        key={`${option.label}-${index}`}
                                        onClick={() => handleOptionClick(option)}
                                    >
                                        <div className={styles.OptionLabel}>{option.label}</div>
                                    </div>
                                    {customStartDate && (
                                        <div className={styles.CustomDatesLabelsContainer}>
                                            <div className={styles.DateLabel} onClick={() => handleOptionClick(option)}>
                                                {customStartDate.format("MMM DD, YYYY")}
                                            </div>
                                            {customEndDate && (
                                                <div
                                                    className={styles.DateLabel}
                                                    onClick={() => handleOptionClick(option)}
                                                >
                                                    {customEndDate.format("MMM DD, YYYY")}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Fragment>
                            );
                        default:
                            return (
                                <div
                                    className={`${styles.OptionContainer} ${styles.SingleChoiceSelect}`}
                                    key={`${option.label}-${index}`}
                                    onClick={() => handleOptionClick(option)}
                                >
                                    <div className={styles.OptionLabel}>{option.label}</div>
                                </div>
                            );
                    }
                })}
            </div>
        );
    };

    const clearDates = () => {
        setCustomStartDate(null);
        setCustomEndDate(null);
        setCurrentOpenedCalendarPurpose(CalendarPurpose.START_DATE);
        setAllOptions(
            getAllOptionsWithUpdatedOption(
                allOptions,
                {
                    label: DateRangeFilterOptions.Custom,
                    isSelected: false,
                    value: [],
                },
                true,
            ) as IDateRangeFilterOption[],
        );
    };

    const renderStartDateCalendar = () => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={styles.DatePickerContainer}>
                <div className={styles.CalendarTitle}>From:</div>
                <DateCalendar
                    className={`${styles.DatePickerCalendar} ${styles.StartDatePicker}`}
                    maxDate={customEndDate}
                    value={customStartDate}
                    onChange={(value, selectionState) => {
                        setCustomStartDate(value);
                        if (selectionState === "finish") {
                            setCurrentOpenedCalendarPurpose(CalendarPurpose.END_DATE);
                        }
                    }}
                    sx={{
                        "& .MuiPickersArrowSwitcher-root": {
                            columnGap: "0.5rem",
                        },
                    }}
                />
                {customStartDate && (
                    <button className={styles.clearCustomDateRange} onClick={clearDates}>
                        Clear Dates
                    </button>
                )}
            </div>
        </LocalizationProvider>
    );

    const renderEndDateCalendar = () => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={styles.DatePickerContainer}>
                <div className={styles.CalendarTitle}>Till:</div>
                <DateCalendar
                    className={`${styles.DatePickerCalendar} ${styles.EndDatePicker}`}
                    minDate={customStartDate}
                    value={customEndDate}
                    onChange={(value, selectionState) => {
                        if (selectionState === "finish") {
                            setCustomEndDate(value);
                        }
                    }}
                    sx={{
                        "& .MuiPickersArrowSwitcher-root": {
                            columnGap: "0.5rem",
                        },
                    }}
                />
                {customStartDate && (
                    <button className={styles.clearCustomDateRange} onClick={clearDates}>
                        Clear Dates
                    </button>
                )}
            </div>
        </LocalizationProvider>
    );

    const renderDateRangeCalendars = () => {
        if (currentOpenedCalendarPurpose === CalendarPurpose.START_DATE) {
            return renderStartDateCalendar();
        }
        return renderEndDateCalendar();
    };

    return (
        <div className={styles.UnicargoDateRangeFilter} ref={UnicargoDateRangeFilterRef}>
            <div className={styles.SelectContainer} onClick={handleSelectClick}>
                <div className={styles.Label}>{dateRangeFilter.label}</div>
                <IconButton
                    className={styles.ArrowIconContainer}
                    aria-label="expand row"
                    size="small"
                    onClick={handleSelectClick}
                >
                    {isOptionsMenuOpen ? (
                        <ArrowIcon className={`${styles.ArrowIcon} ${styles.Opened}`} />
                    ) : (
                        <ArrowIcon className={`${styles.ArrowIcon} ${styles.Closed}`} />
                    )}
                </IconButton>
                {renderTagView()}
            </div>
            {renderOptionsMenu()}
            {isCustomDateRangeCalendarOpen && (
                <div className={styles.DatePickersContainer}>{renderDateRangeCalendars()}</div>
            )}
        </div>
    );
};

export default UnicargoDateRangeFilter;
