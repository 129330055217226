import { IShipment } from "../../../../../models";
import { EDetailTabs } from "./resources";
import { DocumentsTab } from "./sub-components/DocumentsTab/DocumentsTab";
import { RouteTab } from "./sub-components/RouteTab/RouteTab";
import { ShipmentTab } from "./sub-components/ShipmentTab/ShipmentTab";

export const renderTab = (tab: EDetailTabs, shipment: IShipment) => {
    switch (tab) {
        case EDetailTabs.route:
            return <RouteTab {...shipment} />;
        case EDetailTabs.shipment:
            return <ShipmentTab key={shipment.shipmentDetails.shipmentNumber} {...shipment} />;
        case EDetailTabs.documents:
            return (
                <DocumentsTab
                    insured={shipment.shipmentDetails.insured}
                    valueForInsurance={shipment.shipmentDetails.valueForInsurance}
                    customerCode={shipment.customerCode}
                    shipmentNumber={shipment.shipmentDetails.shipmentNumber}
                />
            );
    }
};
