import { Components } from "@mui/material";
import { buttonStyles } from "./buttons";
import { outlinedInputStyles } from "./form-elements/outlinedInputStyles";
import { inputBaseStyles, selectStyles } from "./form-elements";
import { stepConnectorStyles, stepIconStyles, stepLabelStyles, stepStyles, stepperStyles } from "./stepper";

export const components: Components = {
    /** Stepper */
    MuiStepper: { styleOverrides: stepperStyles },
    MuiStepConnector: { styleOverrides: stepConnectorStyles },
    MuiStep: { styleOverrides: stepStyles },
    MuiStepLabel: { styleOverrides: stepLabelStyles },
    MuiStepIcon: { styleOverrides: stepIconStyles },
    /** Buttons */
    MuiButton: { styleOverrides: buttonStyles },
    /** Text Fields */
    MuiInputBase: { styleOverrides: inputBaseStyles },
    MuiOutlinedInput: { styleOverrides: outlinedInputStyles },
    /** Select */
    MuiSelect: { styleOverrides: selectStyles },
};
