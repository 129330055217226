import {
    IUnicargoBankTransferAccount,
    IBankTransferReq,
    IFinishedPaymentFormReq,
    IFinishedPaymentFormRes,
    IGetPaymentPageURIReq,
    IGetPaymentPageURIRes,
    IUnicargoPayoneerAccount,
    IPayoneerTransferReq,
} from "../../models/invoices/payments.dto";
import { unicargoApiSlice } from "../apiSlice";
const endpointPrefix = "payments";
export const paymentApiSlice = unicargoApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPaymentPageURI: builder.query<IGetPaymentPageURIRes, IGetPaymentPageURIReq>({
            query: (body) => ({
                url: `/${endpointPrefix}/payment-page-uri`,
                method: "POST",
                body,
                cache: "no-cache",
            }),
        }),
        sendPaymentLoadingPageQueryParams: builder.query<IFinishedPaymentFormRes, IFinishedPaymentFormReq>({
            query: (body) => ({
                url: `/${endpointPrefix}/payment-finished-filling-form`,
                method: "POST",
                body,
                cache: "no-cache",
            }),
        }),
        getUnicargoBankAccounts: builder.query<IUnicargoBankTransferAccount[], string>({
            query: (customerCode) => ({
                url: `/${endpointPrefix}/${customerCode}/unicargo-bank-accounts`,
                method: "Get",
            }),
        }),
        getUnicargoPayoneerAccount: builder.query<IUnicargoPayoneerAccount, string>({
            query: (customerCode) => ({
                url: `/${endpointPrefix}/${customerCode}/unicargo-payoneer-account`,
                method: "Get",
            }),
        }),
        sendPayoneerTransferReq: builder.query<IFinishedPaymentFormRes, IPayoneerTransferReq>({
            query: (body) => ({
                url: `/${endpointPrefix}/payoneer-transfer-req`,
                method: "POST",
                body,
                cache: "no-cache",
            }),
        }),
        sendBankTransferReq: builder.query<IFinishedPaymentFormRes, IBankTransferReq>({
            query: (body) => ({
                url: `/${endpointPrefix}/bank-transfer-req`,
                method: "POST",
                body,
                cache: "no-cache",
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useLazyGetPaymentPageURIQuery,
    useLazySendPaymentLoadingPageQueryParamsQuery,
    useGetUnicargoBankAccountsQuery,
    useLazySendBankTransferReqQuery,
    useGetUnicargoPayoneerAccountQuery,
    useLazySendPayoneerTransferReqQuery,
} = paymentApiSlice;
