import { MuiTheme } from "../../helpers";
import { useScreenWidthType } from "../../helpers/custom-hooks/useScreenWidthType";
import { ScreenWidthType } from "../../assets/data/ui";
import { ICurrencyData, ISummaryData } from "../../models/http/ISummaryData";
import { LineSeparator } from "../ui-decorations/LineSeparator/LineSeparator";
import { ReactComponent as DecorativeLine2 } from "../../assets/icons/decorative-line-2.svg";
import GenericButton, { ButtonTypeStyles } from "../buttons/GenericButton/GenericButton";
import { ReactComponent as DecorativeCheckmark } from "../../assets/icons/decorative-checkmark.svg";
import { ReactComponent as PillContainerBackgroundSvg } from "../../assets/images/decorative-bg-svg1.svg";
import { formatNumberWithDecimal } from "../../helpers/services/DateFormatsService.service";
import { CurrencySymbols } from "../../models/invoices/invoices.interfaces";
import SkeletonLoaderItem from "../ui-decorations/SkeletonLoader/SkeletonLoaderItem";
import styles from "./Summary.module.scss";

interface ISummary {
    isLoading?: boolean;
    isError?: boolean;
    summaryData?: ISummaryData;
    onQuickSelectionBtnClick: (currencyAcronym: string) => void;
}

export const sortByUSDthenEURthenHighestTotalAmount = (currenciesArr: ICurrencyData[]) => {
    const newSortedCurrenciesArr = [...currenciesArr];
    return newSortedCurrenciesArr.sort((currencyA: ICurrencyData, currencyB: ICurrencyData) => {
        if (currencyA.currencyAcronym === "USD" && currencyB.currencyAcronym !== "USD") {
            return -1;
        }
        if (currencyA.currencyAcronym !== "USD" && currencyB.currencyAcronym === "USD") {
            return 1;
        }
        if (currencyA.currencyAcronym === "EUR" && currencyB.currencyAcronym !== "EUR") {
            return -1;
        }
        if (currencyA.currencyAcronym !== "EUR" && currencyB.currencyAcronym === "EUR") {
            return 1;
        }
        return currencyB.totalAmount - currencyA.totalAmount;
    });
};

const SkeletonLoaderView = (
    <div className={styles.SummarySkeletonLoaderContainer}>
        <SkeletonLoaderItem className={styles.SummarySkeletonLoaderItem} predefinedStyle="Circle" />
        <SkeletonLoaderItem className={styles.SummarySkeletonLoaderItem} customStyle={{ width: 128 }} />
        <SkeletonLoaderItem className={styles.SummarySkeletonLoaderItem} customStyle={{ width: 40 }} />
    </div>
);

const Summary = ({
    summaryData = {
        currencies: [],
        invoicesCount: 0,
        invoiceLinesCount: 0,
    },
    isLoading = false,
    isError = false,
    onQuickSelectionBtnClick,
}: ISummary) => {
    summaryData = {
        ...summaryData,
        currencies: summaryData.currencies.filter((currencyData) => currencyData.totalAmount > 0),
    };
    const numberOfItems = summaryData.currencies.length || 0;
    const isMultipleItems = numberOfItems > 1;
    const screenWidthType = useScreenWidthType();

    const isExtraLargeScreen = screenWidthType === ScreenWidthType.EXTRA_LARGE;

    const summaryDescriptionView = (
        <div className={`${styles.SummaryDescription}`}>
            <p className={styles.SummaryDescriptionTitle}>Summary</p>
            <p className={styles.SummaryDescriptionText}>Open amount - split by currency</p>
        </div>
    );

    const emptyStateView = (
        <div className={styles.EmptyState}>
            <DecorativeCheckmark />
            <p className={styles.EmptyStateText}>You're Good, Everything's Paid Up!</p>
        </div>
    );

    const currencyItemView = (currencyData: ICurrencyData, index: number) => {
        const { currencyAcronym, totalAmount, currencySymbol, overdueAmount } = currencyData;
        const symbolToDisplay: string =
            currencySymbol ?? CurrencySymbols[currencyAcronym as keyof typeof CurrencySymbols] ?? "";

        return (
            <div className={`${styles.PillContainerItem}`} key={index}>
                <div className={`${styles.CurrencySymbol}`}>
                    {currencyAcronym}
                    <DecorativeLine2 />
                </div>
                <div className={`${styles.TotalCurrencyAmount}`}>
                    {`${symbolToDisplay}${formatNumberWithDecimal(totalAmount, 2)}`}
                </div>
                <div className={`${styles.OverdueCurrencyAmount}`}>
                    {`${symbolToDisplay}${formatNumberWithDecimal(overdueAmount, 2)} Overdue`}
                </div>
                {overdueAmount > 0 && (
                    <GenericButton
                        className={`${styles.QuickSelectionButton}`}
                        initialBtnTypeStyle={ButtonTypeStyles.LightGreenStyle}
                        text="Quick Selection"
                        onClick={() => onQuickSelectionBtnClick(currencyAcronym)}
                    />
                )}
            </div>
        );
    };
    const pillContainerView = (
        <section className={`${styles.PillContainer} ${isMultipleItems && styles.MultipleItems}`}>
            <PillContainerBackgroundSvg
                className={`${styles.PillContainerBackgroundSvg} ${numberOfItems > 3 && styles.PushToRight}`}
            />
            {isExtraLargeScreen && summaryDescriptionView}
            {isExtraLargeScreen && <LineSeparator />}
            {isLoading ? (
                SkeletonLoaderView
            ) : isError ? (
                <h2 className={styles.ErrorText}>Error fetching data, please try again later!</h2>
            ) : summaryData && summaryData.currencies.length === 0 ? (
                emptyStateView
            ) : (
                sortByUSDthenEURthenHighestTotalAmount(summaryData.currencies)
                    .slice(0, Math.min(4, summaryData.currencies.length || 0))
                    .map((currencyData, index) => {
                        return currencyItemView(currencyData, index);
                    })
            )}
        </section>
    );

    return (
        <MuiTheme>
            <section className={`${styles.Summary}`}>
                {!isExtraLargeScreen && summaryDescriptionView}
                {pillContainerView}
            </section>
        </MuiTheme>
    );
};

export default Summary;
