import { TableHead, TableRow, TableCell } from "@mui/material";
import { FunctionComponent } from "react";
import { ITableColumn, ValidInvoiceColumnNames } from "../../models/invoices/invoices.interfaces";
import { InvoiceSortTypes, SortOrder } from "../../models/filters/filters.interfaces";
import SortIconComponent from "../SortIconComponent/SortIconComponent";
import { useAppDispatch, useAppSelector } from "../../store";
import { setSort } from "../../store/reducers/invoicesSlice.store";

export interface IInvoiceColumnsSortStates {
    [columnLabel: string]: SortOrder;
}

const columnClassNames = {
    [ValidInvoiceColumnNames.INVOICE_NUMBER]: "invoiceNumber",
    [ValidInvoiceColumnNames.FILE_NUMBER]: "fileNumber",
    [ValidInvoiceColumnNames.REFERENCES]: "references",
    [ValidInvoiceColumnNames.TOTAL_AMOUNT]: "totalAmount",
    [ValidInvoiceColumnNames.INVOICE_DATE]: "invoiceDate",
    [ValidInvoiceColumnNames.DUE_DATE]: "dueDate",
    [ValidInvoiceColumnNames.PAYMENT_STATUS]: "paymentStatus",
    [ValidInvoiceColumnNames.AMOUNT_DUE]: "amountDue",
};

const activateSortLogic = (sortState: SortOrder, onSortStateChange: (sortState: SortOrder) => void) => {
    let newSortState = SortOrder.NONE;
    switch (sortState) {
        case SortOrder.NONE:
            newSortState = SortOrder.DESC;
            break;
        case SortOrder.DESC:
            newSortState = SortOrder.ASC;
            break;
        case SortOrder.ASC:
            newSortState = SortOrder.DESC;
    }
    onSortStateChange(newSortState);
};

const InvoicesTableColumnLabel = ({
    column,
    sortOrder,
    onSortStateChange,
    disableSort = false,
}: {
    column: ITableColumn;
    sortOrder: SortOrder;
    onSortStateChange: (sortState: SortOrder) => void;
    disableSort?: boolean;
}) => {
    return (
        <TableCell className={columnClassNames[column.keyNameToMatch]} key={column.label} align="left">
            {column.sortable ? (
                <div className="SortableColumn">
                    <span className="SortLabel">{column.label}</span>
                    <SortIconComponent
                        className="SortIcon"
                        sortIconState={sortOrder}
                        onClick={() => {
                            if (disableSort) return;
                            activateSortLogic(sortOrder, onSortStateChange);
                        }}
                    />
                </div>
            ) : (
                <div>{column.label}</div>
            )}
        </TableCell>
    );
};

const InvoicesTableHead: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const invoicesState = useAppSelector((state) => state.rootReducer.invoices);
    const sort = invoicesState.sort;
    const columns = invoicesState.columns;
    const paymentStage = invoicesState.paymentStage;

    const updateSortState = (columnToSortBy: ITableColumn, newSortOrder: SortOrder) => {
        const sortType = columnToSortBy.keyNameToMatch.charAt(0).toUpperCase() + columnToSortBy.keyNameToMatch.slice(1);
        dispatch(
            setSort({
                sortType: sortType as InvoiceSortTypes,
                sortOrder: newSortOrder,
            }),
        );
    };
    return (
        <TableHead>
            <TableRow className="InvoicesTableHeadRow no-select">
                <TableCell className="EmptyTableHeadCell" />
                {columns.map((column) => {
                    const sortType = column.keyNameToMatch.charAt(0).toUpperCase() + column.keyNameToMatch.slice(1);
                    return (
                        <InvoicesTableColumnLabel
                            key={column.label}
                            column={column}
                            sortOrder={sortType === sort.sortType ? sort.sortOrder : SortOrder.NONE}
                            onSortStateChange={(newSortOrder) => {
                                updateSortState(column, newSortOrder);
                            }}
                            // ? TODO: Maybe instead of disabling the sort, we should show implement the sort on the selected invoices
                            disableSort={paymentStage !== "InitialSelectingStage"}
                        />
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

export default InvoicesTableHead;
