import { ContainedButton, ShipmentDetailsBlock, ShipmentsListBlock } from "../../components";
import styles from "./ShipmentPage.module.scss";
import { ReactComponent as DecorativeLine1 } from "../../assets/icons/decorative-line-1.svg";
import { STRINGS } from "../../assets/data/resources";
import { tryTrackEvent } from "../../helpers/services/MixPanelService";
import { useEffect, useMemo, useState } from "react";
import { SwipeableDrawer } from "@mui/material";
import { IShipment } from "../../models";
import { useAppDispatch, useAppSelector } from "../../store";
import { useGetShipmentsByCustomerCodeQuery } from "../../store/api-slices/shipmentsSlice.api";
import { clearGlobalShipment } from "../../store/reducers/shipmentsSlice.store";
import { useNavigate } from "react-router-dom";
import { ScreenWidthType } from "../../assets/data/ui";
import { useScreenWidthType } from "../../helpers/custom-hooks";
import ShipmentDetailsBlockSkeleton from "../../components/blocks/shipment-details/sub-components/ShipmentDetailsTabs/ShipmentDetailsBlockSkeleton";
import { userLogOut } from "../../store/reducers/usersSlice.store";

const ShipmentsPage = () => {
    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedShipment, setSelectedShipment] = useState<IShipment>();
    const userState = useAppSelector((state) => state.rootReducer.users);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const shipmentState = useAppSelector((state) => state.rootReducer.shipments);
    const { data, error, isFetching, isError, isSuccess, isLoading } = useGetShipmentsByCustomerCodeQuery({
        customerCode: userState.code,
        params: { status: ["destination", "inTransit", "origin"] },
    });
    const [shouldShowLoader, setShouldShowLoader] = useState<boolean>(true);

    useEffect(() => {
        if (isError && error) {
            if ("status" in error && error.status === 403) {
                dispatch(userLogOut());
            }
        }
        if (isSuccess) {
            setShouldShowLoader(false);
            return;
        }
        setShouldShowLoader(true);
    }, [isFetching, isLoading, selectedShipment, shipmentState, isError, isSuccess]);

    useEffect(() => {
        tryTrackEvent("[Shipments Page]: Page viewed");
        return () => {
            // reset global state when leaving page
            dispatch(clearGlobalShipment());
        };
    }, []);

    useEffect(() => {
        // set the first shipment from the request as the initial one.
        // if selectedShipment is null (which is probably on the first time entering the page) & there is a global shipment selected
        if (!selectedShipment && shipmentState.shipment) {
            setSelectedShipment(shipmentState.shipment);
        } else if (
            !isMobile &&
            isSuccess &&
            data.shipments?.length > 0 &&
            !selectedShipment &&
            !shipmentState?.shipment
        ) {
            setSelectedShipment(data.shipments[0]);
        }
    }, [data, isSuccess, isFetching, isLoading, shipmentState, selectedShipment]);
    //#endregion

    const handleGetQuote = () => {
        tryTrackEvent("[Shipments Page]: 'Get a quote' button clicked");
        navigate("/rfq");
    };
    //#region Handlers
    const handleShipmentSelected = (shipment: IShipment) => {
        setSelectedShipment(shipment);
        if (isMobile && shipment) {
            setIsDrawerOpen(true);
        }
    };

    const handleDrawerState = (isOpen: boolean) => {
        setIsDrawerOpen(isOpen);
        // reset selected shipment state if clicked the X button on mobile
        if (!isOpen) {
            setSelectedShipment(undefined);
        }
    };
    //#endregion

    const renderShipmentDetailsBlock = useMemo(() => {
        if (shouldShowLoader) {
            return <ShipmentDetailsBlockSkeleton />;
        } else if (!selectedShipment) {
            return null;
        } else {
            return (
                <ShipmentDetailsBlock
                    shipment={selectedShipment}
                    handleCloseClicked={() => handleDrawerState(false)}
                    originPage="Shipments Page"
                />
            );
        }
    }, [selectedShipment, shouldShowLoader]);

    //#region Memoized values
    const renderBody = useMemo(() => {
        const partialBody = isMobile ? (
            <SwipeableDrawer
                open={isDrawerOpen}
                onClose={() => handleDrawerState(false)}
                onOpen={() => handleDrawerState(true)}
                anchor="right"
            >
                {renderShipmentDetailsBlock}
            </SwipeableDrawer>
        ) : (
            <div className={styles.ShipmentDetailsBlock}>{renderShipmentDetailsBlock}</div>
        );
        return (
            <div className={styles.ShipmentPageBody}>
                <ShipmentsListBlock
                    initialShipments={data?.shipments}
                    selectedShipment={selectedShipment}
                    onShipmentSelected={handleShipmentSelected}
                />
                {partialBody}
            </div>
        );
    }, [isMobile, selectedShipment, data, isError, isDrawerOpen, shouldShowLoader]);

    const renderHead = useMemo(() => {
        if (isMobile) {
            return null;
        }
        return (
            <div className={styles.ShipmentPageHead}>
                <div className={styles.HeaderTitleContainer}>
                    <h1 className={styles.HeaderTitle}>
                        Shipments {userState?.customerName ? "|" : ""}
                        <span className={styles.HeaderTitleSpan}>&nbsp;{userState?.customerName}</span>
                    </h1>
                    <DecorativeLine1 />
                </div>
                <div className={styles.HeaderButtonContainer}>
                    {isMobile && (
                        <ContainedButton onClick={() => handleGetQuote()}>{STRINGS.GET_A_QUOTE}</ContainedButton>
                    )}
                </div>
            </div>
        );
    }, [isMobile]);

    //#endregion
    return (
        <div className={styles.Root}>
            {renderHead}
            {renderBody}
        </div>
    );
};

export default ShipmentsPage;
