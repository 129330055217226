import { useState } from "react";

type UseRfqModalReturnType = [boolean, () => void];

export const useModal = (initialMode = false): UseRfqModalReturnType => {
    const [isOpenModal, setIsOpenModal] = useState<boolean>(initialMode);
    const toggle = () => {
        setIsOpenModal(!isOpenModal);
    };
    return [isOpenModal, toggle];
};
