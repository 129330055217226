import { Fragment, FunctionComponent, useEffect, useState } from "react";
import {
    IInvoice,
    IInvoiceShipmentDataKeys,
    InvoiceSpecialServicesCodePrefix,
    InvoiceSpecialServicesCodePrefixType,
    InvoiceSubType,
    InvoiceSubTypes,
    ValidInvoiceLineKeysToDisplay,
    ValidLineItemsColumnNames,
    DisplayableInvoiceShipmentKeyOrder,
    IInvoiceLine,
    ShipmentReferencesKeysNames,
    InvoiceSubTypeTransportMethodMap,
} from "../../models/invoices/invoices.interfaces";
import styles from "./InvoicesInnerTable.module.scss";
import { LineSeparator } from "../ui-decorations/LineSeparator/LineSeparator";
import { DocumentIconV2, InfoV2Icon } from "../../assets/icons";
import { formatNumberWithDecimal } from "../../helpers/services/DateFormatsService.service";
import { roundNumber } from "../cards/shipment-cards/shared/services/shipment-cards.service";
import UnicargoTooltip from "../UnicargoTooltip/UnicargoTooltip";
import dayjs from "dayjs";
import { invoicesConstants } from "./shared/invoices.constants";
import { IDoc } from "../../models/cards/IDoc";
import ThreeDotsLoader from "../ui-decorations/ThreeDotsLoader/ThreeDotsLoader";
import {
    useLazyGetInvoiceDocumentsQuery,
    useGetShipmentDocsForInvoiceQuery,
} from "../../store/api-slices/invoicesSlice.api";
import { useLazyGetShipmentDocumentQuery } from "../../store/api-slices/shipmentsSlice.api";
import ReactCountryFlag from "react-country-flag";
import { UiSchemeV2 } from "../../assets/data/ui";

interface InvoicesInnerTableProps {
    invoice: IInvoice;
    customerCode: string;
}

const CommonFields: IInvoiceShipmentDataKeys[] = ["specialServicesEnglishName", "shipperName", "consigneeName"];
const SpecialFields: IInvoiceShipmentDataKeys[] = ["descriptionOfGoods"];

const getInvoiceFieldConfig = (
    specialServicesCode: InvoiceSpecialServicesCodePrefixType,
    invoiceSubType: InvoiceSubType,
): IInvoiceShipmentDataKeys[] => {
    if (specialServicesCode === InvoiceSpecialServicesCodePrefix.Warehouse) {
        return ["specialServicesEnglishName", "consigneeName", "commentsDate"];
    }
    const specialFields = (
        [
            InvoiceSpecialServicesCodePrefix.CBA,
            InvoiceSpecialServicesCodePrefix.CBO,
            InvoiceSpecialServicesCodePrefix.CUS,
        ] as string[]
    ).includes(specialServicesCode)
        ? SpecialFields
        : [];
    if (invoiceSubType === InvoiceSubTypes.AIR) {
        return [
            ...CommonFields,
            "numberOfPackages",
            "grossWeightInKG",
            "totalVolumInCBM",
            "chargeWeightInKG",
            ...specialFields,
        ];
    }
    if (invoiceSubType === InvoiceSubTypes.FCL) {
        return [...CommonFields, "numberOfContainers", "packageQuantityType", ...specialFields];
    }
    if (([InvoiceSubTypes.LCL, InvoiceSubTypes.FTL, InvoiceSubTypes.LTL] as string[]).includes(invoiceSubType)) {
        return [...CommonFields, "numberOfPackages", "grossWeightInKG", "totalVolumInCBM", ...specialFields];
    }
    return [];
};

const getServicesFieldTextBasedOnSpecialServicesCode = (
    specialServicesCode: InvoiceSpecialServicesCodePrefixType,
    invoiceSubType: InvoiceSubType,
    specialServicesEnglishName: string,
) => {
    // console.log(specialServicesCode, specialServicesEnglishName);
    if (specialServicesCode.startsWith(InvoiceSpecialServicesCodePrefix.Warehouse)) {
        return specialServicesEnglishName;
    }
    const invoiceSubTypeToCompare = invoiceSubType.toUpperCase();
    if (invoiceSubTypeToCompare === InvoiceSubTypes.AIR) {
        return `${invoiceSubType} (${specialServicesEnglishName})`;
    }
    if (([InvoiceSubTypes.FCL, InvoiceSubTypes.LCL] as string[]).includes(invoiceSubTypeToCompare)) {
        return `${specialServicesEnglishName}`;
    }
    if (([InvoiceSubTypes.FTL, InvoiceSubTypes.LTL] as string[]).includes(invoiceSubTypeToCompare)) {
        return `Inland (${specialServicesEnglishName})`;
    }
    console.log(`Unknown invoiceSubType: ${invoiceSubType}`);
    console.log(specialServicesCode, specialServicesEnglishName, invoiceSubType);
    return `${specialServicesEnglishName} | ${invoiceSubType} | ${specialServicesCode}`;
};

const shouldDisplayKey = ({ invoice, key }: { invoice: IInvoice; key: IInvoiceShipmentDataKeys }) => {
    const invoiceSpecialServiceCodePrefix: InvoiceSpecialServicesCodePrefixType =
        invoice.shipment.specialServicesCode?.slice(0, 3) as InvoiceSpecialServicesCodePrefixType;
    const invoiceSubType: InvoiceSubType = invoice.shipment.shipmentLoad?.toUpperCase() as InvoiceSubType;
    const validKeysToDisplayForType = getInvoiceFieldConfig(invoiceSpecialServiceCodePrefix, invoiceSubType) ?? [];
    return validKeysToDisplayForType.includes(key);
};

const renderFileInfoItems = (invoice: IInvoice) => {
    const fileInfoKeysToDisplay = (
        Object.keys(invoice.shipment).filter((key): key is IInvoiceShipmentDataKeys =>
            shouldDisplayKey({ invoice, key: key as IInvoiceShipmentDataKeys }),
        ) as (keyof typeof invoice.shipment)[]
    ).sort((a, b) => {
        return (
            (DisplayableInvoiceShipmentKeyOrder[a as keyof typeof DisplayableInvoiceShipmentKeyOrder] || 0) -
            (DisplayableInvoiceShipmentKeyOrder[b as keyof typeof DisplayableInvoiceShipmentKeyOrder] || 0)
        );
    });

    return fileInfoKeysToDisplay.map((key) => {
        let formattedValue = invoice.shipment[key];
        if (!Number.isNaN(Number(invoice.shipment[key]))) {
            formattedValue = formatNumberWithDecimal(roundNumber(invoice.shipment[key] as number, 2), 2) as string;
            if (formattedValue.endsWith(".00")) formattedValue = formattedValue.slice(0, -3);
        }
        if (formattedValue == undefined || formattedValue == null || formattedValue == 0) {
            formattedValue = "...";
        }
        switch (key) {
            case "specialServicesEnglishName": {
                if (!invoice.shipment.specialServicesEnglishName || !invoice.shipment.specialServicesCode) return null;
                formattedValue = getServicesFieldTextBasedOnSpecialServicesCode(
                    invoice.shipment.specialServicesCode as InvoiceSpecialServicesCodePrefixType,
                    invoice.shipment.shipmentLoad as InvoiceSubType,
                    invoice.shipment.specialServicesEnglishName,
                );

                return (
                    <Fragment key={key}>
                        <div className={`${styles.FileInfoItemKey} ${styles.SpecialServicesEnglishName} Bold`}>
                            Service
                        </div>
                        <div className={styles.FileInfoItemValueContainer}>
                            <span className={styles.FileInfoItemValueContent}>{formattedValue}</span>
                        </div>
                    </Fragment>
                );
            }
            case "shipperName": {
                const formattedShipperName = (formattedValue as string)
                    .toLowerCase()
                    .replace(/\b\w/g, (char) => char.toUpperCase());
                return (
                    <Fragment key={key}>
                        <div className={`${styles.FileInfoItemKey} ${styles.ShipperName} Bold`}>Shipper</div>
                        <div className={styles.FileInfoItemValueContainer}>
                            {invoice.shipment.shipperCountryCode && (
                                <ReactCountryFlag
                                    countryCode={invoice.shipment.shipperCountryCode}
                                    svg
                                    style={{
                                        border: `1px solid ${UiSchemeV2.colors.greys.grey100}`,
                                        width: "1.25em",
                                        marginTop: "0.15rem",
                                    }}
                                />
                            )}
                            <span className={styles.FileInfoItemValueContent}>{formattedShipperName}</span>
                        </div>
                    </Fragment>
                );
            }
            case "consigneeName": {
                let consigneeKeyLabel = "Consignee";
                // If the invoice is a warehouse invoice, then the consignee means the warehouse
                if (invoice.shipment.specialServicesCode?.startsWith(InvoiceSpecialServicesCodePrefix.Warehouse)) {
                    consigneeKeyLabel = "Warehouse";
                }
                return (
                    <Fragment key={key}>
                        <div className={`${styles.FileInfoItemKey} ${styles.ConsigneeName} Bold`}>
                            {consigneeKeyLabel}
                        </div>
                        <div className={styles.FileInfoItemValueContainer}>
                            {invoice.shipment.consigneeCountryCode && (
                                <ReactCountryFlag
                                    countryCode={invoice.shipment.consigneeCountryCode}
                                    svg
                                    style={{
                                        border: `1px solid ${UiSchemeV2.colors.greys.grey100}`,
                                        width: "1.25em",
                                        marginTop: "0.15rem",
                                    }}
                                />
                            )}
                            <span className={styles.FileInfoItemValueContent}>{formattedValue}</span>
                        </div>
                    </Fragment>
                );
            }
            case "commentsDate":
                formattedValue = dayjs(invoice.shipment[key] as string).format("MMMM YYYY");
                return (
                    <Fragment key={key}>
                        <div className={`${styles.FileInfoItemKey} ${styles.CommentsDate} Bold`}>Activity Month</div>
                        <div className={styles.FileInfoItemValueContainer}>
                            <span className={styles.FileInfoItemValueContent}>{formattedValue}</span>
                        </div>
                    </Fragment>
                );
            case "packageQuantityType":
                return (
                    <Fragment key={key}>
                        <div className={`${styles.FileInfoItemKey} ${styles.PackageQuantityType} Bold`}>
                            Containers Type
                        </div>
                        <div className={styles.FileInfoItemValueContainer}>
                            <span className={styles.FileInfoItemValueContent}>{formattedValue}</span>
                        </div>
                    </Fragment>
                );
            case "chargeWeightInKG":
                return (
                    <Fragment key={key}>
                        <div className={`${styles.FileInfoItemKey} ${styles.ChargeWeightInKG} Bold`}>
                            Chargeable Weight (KG)
                        </div>
                        <div className={styles.FileInfoItemValueContainer}>
                            <span className={styles.FileInfoItemValueContent}>{formattedValue}</span>
                        </div>
                    </Fragment>
                );
            case "numberOfContainers":
                return (
                    <Fragment key={key}>
                        <div className={`${styles.FileInfoItemKey} ${styles.NumberOfContainers} Bold`}>
                            Number of Containers
                        </div>
                        <div className={styles.FileInfoItemValueContainer}>
                            <span className={styles.FileInfoItemValueContent}>{formattedValue}</span>
                        </div>
                    </Fragment>
                );
            case "numberOfPackages":
                return (
                    <Fragment key={key}>
                        <div className={`${styles.FileInfoItemKey} ${styles.NumberOfPackages} Bold`}>Pieces</div>
                        <div className={styles.FileInfoItemValueContainer}>
                            <span className={styles.FileInfoItemValueContent}>{formattedValue}</span>
                        </div>
                    </Fragment>
                );
            case "grossWeightInKG":
                return (
                    <Fragment key={key}>
                        <div className={`${styles.FileInfoItemKey} ${styles.GrossWeightInKG} Bold`}>
                            Gross Weight (KG)
                        </div>
                        <div className={styles.FileInfoItemValueContainer}>
                            <span className={styles.FileInfoItemValueContent}>{formattedValue}</span>
                        </div>
                    </Fragment>
                );
            case "totalVolumInCBM":
                return (
                    <Fragment key={key}>
                        <div className={`${styles.FileInfoItemKey} ${styles.TotalVolumInCBM} Bold`}>Volume (CBM)</div>
                        <div className={styles.FileInfoItemValueContainer}>
                            <span className={styles.FileInfoItemValueContent}>{formattedValue}</span>
                        </div>
                    </Fragment>
                );
            case "descriptionOfGoods":
                return (
                    <Fragment key={key}>
                        <div className={`${styles.FileInfoItemKey} ${styles.DescriptionOfGoods} Bold`}>
                            Description of Goods
                        </div>
                        <div className={styles.FileInfoItemValueContainer}>
                            <span className={styles.FileInfoItemValueContent}>{formattedValue}</span>
                        </div>
                    </Fragment>
                );
            default:
                return null;
        }
    });
};

const renderLineItems = (invoice: IInvoice) => {
    const isWarehouseInvoice =
        invoice.shipment.specialServicesCode?.startsWith(InvoiceSpecialServicesCodePrefix.Warehouse) ?? false;
    const validLineItemsKeysToDisplay: ValidInvoiceLineKeysToDisplay[] = (
        isWarehouseInvoice
            ? ["lineDescription", "lineAmountInForeign", "lineAmountInInvoiceCurrency", "lineNotes"]
            : [
                  "lineDescription",
                  "uomCode",
                  "lineQuantity",
                  "lineUnitPrice",
                  "lineAmountInForeign",
                  "lineAmountInInvoiceCurrency",
                  "lineNotes",
              ]
    ).filter((key) => {
        // Remove lineAmountInForeign if all line items have the same currency as the invoice
        if (key === "lineAmountInForeign") {
            return !invoice.lines.every((lineItem) => lineItem.foreignCurrencyAcronym === invoice.currencyAcronym);
        }
        // Remove keys that are not present in any line item
        return invoice.lines.some((lineItem) => lineItem[key as keyof typeof lineItem]);
    }) as ValidInvoiceLineKeysToDisplay[];

    const lineItemsToDisplay: IInvoiceLine[] = invoice.lines
        .map((lineItem) => {
            if (validLineItemsKeysToDisplay.some((key) => lineItem[key as keyof typeof lineItem])) {
                if (lineItem.foreignCurrencyAcronym === invoice.currencyAcronym) {
                    return { ...lineItem, lineAmountInForeign: invoicesConstants.emptyCellContentPlaceholder };
                } else {
                    return lineItem;
                }
            }
            return null;
        })
        .filter((lineItem) => lineItem) as IInvoiceLine[];

    return (
        <section
            className={styles.LineItemsContainer}
            style={{
                gridTemplateColumns: `repeat(${validLineItemsKeysToDisplay.length}, 0.75fr)`,
                gridTemplateRows: `repeat(${lineItemsToDisplay.length}, 1fr)`,
            }}
        >
            {validLineItemsKeysToDisplay.map((key) => {
                return (
                    <div key={key} className={`${styles.LineItemsColumnTitle} Bold`}>
                        {ValidLineItemsColumnNames[key]}
                    </div>
                );
            })}
            {lineItemsToDisplay.map((lineItem, index) => {
                return validLineItemsKeysToDisplay.map((lineKey) => {
                    switch (lineKey) {
                        case "lineDescription":
                            return (
                                <div key={`${lineKey}-${index}`} className={styles.LineItem}>
                                    <div className={`${styles.LineItemContent} ${styles.LineDescription}`}>
                                        {lineItem[lineKey as keyof typeof lineItem]}
                                    </div>
                                </div>
                            );
                        case "lineNotes":
                            return (
                                <div key={`${lineKey}-${index}`} className={styles.LineItem}>
                                    {lineItem[lineKey as keyof typeof lineItem] &&
                                    (lineItem[lineKey as keyof typeof lineItem] as string).trim() ? (
                                        <UnicargoTooltip
                                            popperChildComponent={lineItem[lineKey as keyof typeof lineItem] as string}
                                            child={<InfoV2Icon />}
                                            popperPlacement="top"
                                        />
                                    ) : (
                                        <div className={styles.LineItemContent}>
                                            {invoicesConstants.emptyCellContentPlaceholder}
                                        </div>
                                    )}
                                </div>
                            );
                        case "lineUnitPrice":
                        case "lineAmountInForeign":
                            return (
                                <div key={`${lineKey}-${index}`} className={styles.LineItem}>
                                    <div className={styles.LineItemContent}>
                                        {lineItem[lineKey as keyof typeof lineItem] ===
                                        invoicesConstants.emptyCellContentPlaceholder ? (
                                            invoicesConstants.emptyCellContentPlaceholder
                                        ) : (
                                            <div className={styles.LineItemContent}>
                                                {formatNumberWithDecimal(
                                                    roundNumber(
                                                        lineItem[lineKey as keyof typeof lineItem] as number,
                                                        2,
                                                    ),
                                                    2,
                                                )}{" "}
                                                {lineItem.foreignCurrencyAcronym}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        case "lineAmountInInvoiceCurrency":
                            return (
                                <div key={`${lineKey}-${index}`} className={styles.LineItem}>
                                    {lineItem[lineKey as keyof typeof lineItem] ===
                                    invoicesConstants.emptyCellContentPlaceholder ? (
                                        invoicesConstants.emptyCellContentPlaceholder
                                    ) : (
                                        <div className={styles.LineItemContent}>
                                            {formatNumberWithDecimal(
                                                roundNumber(lineItem[lineKey as keyof typeof lineItem] as number, 2),
                                                2,
                                            )}{" "}
                                            {invoice.currencyAcronym}
                                        </div>
                                    )}
                                </div>
                            );
                        case "lineQuantity":
                            return (
                                <div key={`${lineKey}-${index}`} className={styles.LineItem}>
                                    {lineItem[lineKey as keyof typeof lineItem] ===
                                    invoicesConstants.emptyCellContentPlaceholder ? (
                                        invoicesConstants.emptyCellContentPlaceholder
                                    ) : (
                                        <div className={styles.LineItemContent}>
                                            {formatNumberWithDecimal(
                                                roundNumber(lineItem[lineKey as keyof typeof lineItem] as number, 2),
                                                2,
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        default:
                            return (
                                <div key={`${lineKey}-${index}`} className={styles.LineItem}>
                                    <div className={styles.LineItemContent}>
                                        {lineItem[lineKey as keyof typeof lineItem]}
                                    </div>
                                </div>
                            );
                    }
                });
            })}
        </section>
    );
};

const renderReferencesSection = (invoice: IInvoice) => {
    const referencesKeysToDisplay = Object.keys(
        ShipmentReferencesKeysNames,
    ) as (keyof typeof ShipmentReferencesKeysNames)[];
    const referencesValues = new Map<keyof typeof ShipmentReferencesKeysNames, string | undefined>([
        ["customerReferenceID", invoice.shipment.customerReferenceID],
        ["purchaseOrderNumber", invoice.shipment.purchaseOrderNumber],
        ["amazonShipmentID", invoice.shipment.amazonShipmentID],
        ["amazonReferenceID", invoice.shipment.amazonReferenceID],
    ]);
    // Remove keys with falsy values from the Map
    referencesValues.forEach((value, key) => {
        if (!value) referencesValues.delete(key);
    });

    // Remove the first key from the Map as the first will be displayed in the row's reference cell
    referencesValues.delete(referencesValues.keys().next().value);

    return (
        <section className={styles.ReferencesContainer}>
            <div className={styles.ReferencesItemsContainer}>
                {referencesKeysToDisplay.map((key: keyof typeof ShipmentReferencesKeysNames) => {
                    const value = referencesValues.get(key);
                    if (!value) return null;
                    return (
                        referencesValues.get(key) && (
                            <Fragment key={key}>
                                <div className={`${styles.ReferenceItemKey} Bold`}>
                                    {ShipmentReferencesKeysNames[key]}
                                </div>
                                <div className={styles.ReferenceItemValue}>{referencesValues.get(key)}</div>
                            </Fragment>
                        )
                    );
                })}
            </div>
        </section>
    );
};

const renderDocumentsSection = (invoice: IInvoice, customerCode: string) => {
    const [getInvoiceDocument, { data: invoiceBlobData, isSuccess: isInvoiceSuccess }] =
        useLazyGetInvoiceDocumentsQuery();
    const [getShipmentDocument, { data: shipmentBlobData, isSuccess: isShipmentBlobSuccess }] =
        useLazyGetShipmentDocumentQuery();
    const [shipmentDocs, setShipmentDocs] = useState<IDoc[]>([]);
    const shipmentDocsResponse = useGetShipmentDocsForInvoiceQuery({
        customerCode,
        params: { shipmentNumber: invoice.shipment.shipmentNumber },
    });
    useEffect(() => {
        if (shipmentDocsResponse.isError) {
            console.error(shipmentDocsResponse.error);
        }
        if (shipmentDocsResponse.isSuccess) {
            setShipmentDocs(shipmentDocsResponse.data.docs);
        }
    }, [shipmentDocsResponse]);

    const onInvoiceDocumentClick = async (invoiceNumber: string) => {
        const fileData = await getInvoiceDocument({
            customerCode,
            params: {
                invoiceNumber,
                shipmentNumber: invoice.shipment.shipmentNumber,
            },
        });
    };

    const onShipmentDocumentClick = async (
        documentSecurityId: string,
        fileName: string,
        fileExtension: string | undefined,
    ) => {
        const _ = await getShipmentDocument({
            documentSecurityId,
            shipmentNumber: invoice.shipment.shipmentNumber,
            customerCode,
            fileExtension,
            fileName,
            fileDownload: !(fileExtension === "pdf"),
        });
    };

    return (
        <section className={styles.DocumentsContainer}>
            <div className={`${styles.DocumentsTitle} Bold`}>Documents</div>
            <div className={styles.DocumentsItemsContainer}>
                <div
                    className={`${styles.DocumentItem} ${styles.InvoiceDocument}`}
                    onClick={() => onInvoiceDocumentClick(invoice.invoiceNumber)}
                >
                    <DocumentIconV2 className={styles.DocumentIcon} />
                    <span className={styles.DocumentTitle}>Invoice {invoice.invoiceNumber}</span>
                </div>
                {shipmentDocsResponse.isSuccess ? (
                    <Fragment>
                        {shipmentDocs &&
                            shipmentDocs.length > 0 &&
                            shipmentDocsResponse.data?.docs.map((doc) => (
                                <div
                                    key={doc.DocumentId}
                                    className={`${styles.DocumentItem} ${styles.ShipmentDocument}`}
                                    onClick={async () =>
                                        await onShipmentDocumentClick(doc.SecurityId, doc.Name, doc.FileExtension)
                                    }
                                >
                                    <DocumentIconV2 className={styles.DocumentIcon} />
                                    <span className={styles.DocumentTitle}>{doc.Name}</span>
                                </div>
                            ))}
                    </Fragment>
                ) : shipmentDocsResponse.isError ? (
                    "Failed to load documents"
                ) : (
                    <ThreeDotsLoader className="DocumentsLoader" />
                )}
            </div>
        </section>
    );
};

const InvoicesInnerTable: FunctionComponent<InvoicesInnerTableProps> = ({ invoice, customerCode }) => {
    // At least two references must exist to display the references section
    const someReferencesExist =
        [
            invoice.shipment.customerReferenceID,
            invoice.shipment.purchaseOrderNumber,
            invoice.shipment.amazonShipmentID,
            invoice.shipment.amazonReferenceID,
        ].filter((reference) => reference).length > 1;
    return (
        <section className={styles.InvoicesInnerTable}>
            <section className={styles.IdTable}>
                <div className={styles.FileInfoContainer}>
                    <div className={`${styles.FileInfoTitle} Bold`}>Shipment Information</div>
                    <div className={styles.FileInfoItemsContainer}>{renderFileInfoItems(invoice)}</div>
                </div>
                {someReferencesExist && <LineSeparator className={styles.IdTableLineSeparator} />}
                {renderReferencesSection(invoice)}
            </section>
            <LineSeparator className={styles.InnerTableSectionsLineSeparator} />
            <section className={styles.ChargeTable}>
                <section className={styles.LineItemsSection}>
                    <div className={`${styles.LineItemsTitle} Bold`}>Line Items</div>
                    {renderLineItems(invoice)}
                </section>
                {invoice.invoiceNotes && (
                    <section className={styles.NoteContainer}>
                        <div className={`${styles.NoteTitle} Bold`}>Note:</div>
                        <div className={styles.NoteContent}>{invoice.invoiceNotes}</div>
                    </section>
                )}
                {renderDocumentsSection(invoice, customerCode)}
            </section>
        </section>
    );
};

export default InvoicesInnerTable;
