import { BookingIcon } from "../../assets/icons";
import "./BookingCard.scss";
import { ReactComponent as RightSmallArrow } from "../../assets/icons/right-small-arrow.svg";
import { IBookingCard } from "./types";
import { getFormattedDate } from "../../helpers/services";

const BookingCard = ({ steps, mainCarriageFinalETA }: IBookingCard) => {
    const mainCarriageStep = steps.find((step) => step.stepName === "mainCarriage");
    const onCarriageStep = steps.find((step) => step.stepName === "onCarriage");

    const lastETD = mainCarriageStep?.ETD ? mainCarriageStep.ETD[mainCarriageStep.ETD.length - 1] : null;
    const lastETA =
        onCarriageStep?.ETA && onCarriageStep.ETA.length > 0
            ? onCarriageStep.ETA[onCarriageStep.ETA.length - 1]
            : mainCarriageFinalETA && mainCarriageFinalETA.length > 0
            ? mainCarriageFinalETA[mainCarriageFinalETA.length - 1]
            : null;

    const calculateDaysBetweenDates = (startDateString: string | null, endDateString: string | null) => {
        if (!startDateString || !endDateString) return "";

        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);
        const timeDiff = endDate.getTime() - startDate.getTime();
        const daysDiff = timeDiff / (1000 * 3600 * 24);

        return Math.round(daysDiff);
    };

    let daysBetween = calculateDaysBetweenDates(lastETD, lastETA);
    // min number of days should be 1
    if (daysBetween == 0) daysBetween = 1;

    return (
        <div className="booking-card">
            <div className="title-container">
                <BookingIcon />
                <span>Booking details</span>
            </div>
            <div className="dates-container">
                <div className="estimated-container">
                    <span className="date-title">Est. Departure:</span>
                    <span className="date-data">{lastETD ? getFormattedDate(lastETD) : "..."}</span>
                </div>
                <RightSmallArrow className="arrow-icon" />
                <div className="estimated-container">
                    <span className="date-title">Est. Arrival:</span>
                    <span className="date-data">{lastETA ? getFormattedDate(lastETA) : "..."}</span>
                </div>
            </div>
            {lastETD && lastETA && (
                <>
                    <hr className="separator-line" />
                    <span className="days-between-container">{daysBetween} Days</span>
                </>
            )}
        </div>
    );
};

export default BookingCard;
