import { FunctionComponent } from "react";
import styles from "./SkeletonLoaderItem.module.scss";

interface SkeletonLoaderItemProps {
    className?: string;
    predefinedStyle?: "Bar" | "Circle";
    isLoading?: boolean;
    customStyle?: React.CSSProperties;
    width?: string;
    height?: string;
    onClick?: () => void;
}

const SkeletonLoaderItem: FunctionComponent<SkeletonLoaderItemProps> = ({
    className = "",
    predefinedStyle = "Bar",
    isLoading = true,
    customStyle,
    width,
    height,
    onClick,
}) => {
    return (
        <div
            className={`${styles.SkeletonLoaderItem} ${isLoading && styles.Loading} ${
                styles[predefinedStyle]
            } ${className}`}
            style={{ width, height, ...customStyle }}
            onClick={onClick}
        ></div>
    );
};

export default SkeletonLoaderItem;
