import { airOrLandToggleButtonDetails, oceanradioOptions } from "../../../pages/rfq/second-form/secondPhaseFormUtils";
import { ToggleButton } from "../../../components/RFQ/form-elements/toggleButton/ToggleButton";
import { FormControl } from "@mui/material";
import { ISecondPhaseProps } from "./SecondPhaseUtils";
import { TabsInput } from "../tabs/TabsInput";

const SecondPhaseAirOrLandHeader = ({ control }: ISecondPhaseProps) => {
    return (
        <>
            <ToggleButton
                buttonsDetails={airOrLandToggleButtonDetails}
                name={"cargoType"}
                control={control}
                required={true}
                label={"cargo"}
            />
            <FormControl>
                <TabsInput
                    tabsDetails={oceanradioOptions}
                    type="text"
                    size="md"
                    required={true}
                    name={"isTotalShipment"}
                    control={control}
                    label={"cargo"}
                />
            </FormControl>
        </>
    );
};

export default SecondPhaseAirOrLandHeader;
