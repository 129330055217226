import { FC, useEffect, useRef } from "react";
import "./UnicargoTextInput.scss";
import { MuiTheme } from "../../helpers";
import { useAppSelector } from "../../store";
import { SxProps, TextField, Theme } from "@mui/material";

interface UnicargoTextInputProps {
    placeholderText?: string;
    value?: string;
    onValueChange?: (newValue: string) => void;
    customClassName?: string;
    customSx?: SxProps<Theme>;
}

const UnicargoTextInput: FC<UnicargoTextInputProps> = ({
    placeholderText,
    value = "",
    onValueChange,
    customClassName = "",
    customSx,
}) => {
    const autoFocus = useAppSelector((state) => state.rootReducer.searchBar.autoFocus);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleValueChange = (newValue: string) => {
        if (newValue === value || newValue.startsWith(" ") || /^$|^[0-9a-zA-Z\s]+$/.test(newValue) === false) return;
        if (onValueChange) onValueChange(newValue);
    };

    useEffect(() => {
        if (autoFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [autoFocus]);

    return (
        <MuiTheme>
            <TextField
                className={`TextInputField UnicargoTextInput ${customClassName ?? ""}`}
                InputProps={{ className: "TextInputField" }}
                value={value}
                inputRef={inputRef}
                placeholder={placeholderText}
                onChange={(e) => handleValueChange(e.target.value)}
                sx={customSx}
            />
        </MuiTheme>
    );
};

export default UnicargoTextInput;
