import { CSSProperties } from "react";

declare module "@mui/material/styles" {
    // Palette
    interface Palette {
        textColors: {
            white: CSSProperties["color"]; // Text White
            light: CSSProperties["color"]; // Text Light
            medium: CSSProperties["color"]; // Text Dark
            dark: CSSProperties["color"]; // Text Bold
        };
    }

    interface PaletteOptions {
        textColors?: {
            white?: string; // Text White
            light?: string; // Text Light
            medium?: string; // Text Dark
            dark?: string; // Text bold
        };
    }
}

// Text
export const textColors = {
    white: "#FFFFFF", // Text White
    light: "#BDBFC2", // Text Light
    medium: "#777C83", // Text Dark
    dark: "#3B4858", // Text bold
};
