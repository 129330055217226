import { combineReducers } from "redux";
import usersReducer from "./usersSlice.store";
import rfqReducer from "./rfqSlice.store";
import shipmentsReducer from "./shipmentsSlice.store";
import searchBarReducer from "./searchBarSlice";
import invoicesReducer from "./invoicesSlice.store";

export const rootReducer = combineReducers({
    users: usersReducer,
    shipments: shipmentsReducer,
    rfq: rfqReducer,
    searchBar: searchBarReducer,
    invoices: invoicesReducer,
});
