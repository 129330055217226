import { useLocation, Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

export function RequireAuth({ children }: any) {
    const location = useLocation();
    const { route } = useAuthenticator();

    if (route !== "authenticated") {
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else if (location.pathname === "/") {
        // If path is "/" - navigate to the main page ("/overview").
        return <Navigate to="/overview" />;
    } else {
        return children;
    }
}
