import { ReactComponent as RightSmallArrow } from "../../assets/icons/right-small-arrow.svg";
import styles from "./UpdateHistoryCard.module.scss";
import { DescriptionAndDate } from "./types";
import { getFormattedDate } from "../../helpers/services";
import { TimePastIcon } from "../../assets/icons";

export interface IUpdateHistoryCard {
    descriptionsAndDates: DescriptionAndDate;
    style: string;
}

export const UpdateHistoryCard = ({ style, descriptionsAndDates }: IUpdateHistoryCard) => {
    // Remove duplicates by converting it to a Set and back to an array
    const uniqueEstimatedDates = Array.from(new Set(descriptionsAndDates.estimatedDates));

    const estimatedDatesReversed = [...uniqueEstimatedDates].reverse();

    return (
        <div className={styles.UpdateHistoryCard} style={{ left: style }}>
            <div className={styles.TitleContainer}>
                <TimePastIcon />
                <span>Update history</span>
            </div>
            <div className={styles.DatesContainer}>
                <div className={styles.EstimatedContainer}>
                    <span className={styles.DateTitle}>Exp. date:</span>
                    {estimatedDatesReversed.map((date, index) => (
                        <span key={index} className={index === 0 ? styles.ActualDateData : styles.EstimatedDateData}>
                            {date}
                        </span>
                    ))}
                </div>
                <RightSmallArrow className={styles.ArrowIcon} />
                <div className={styles.EstimatedContainer}>
                    <span className={styles.DateTitle}>Act. date:</span>
                    <span className={styles.ActualDateData}>
                        {descriptionsAndDates.actualDates[0]
                            ? getFormattedDate(descriptionsAndDates.actualDates[0])
                            : "..."}
                    </span>
                </div>
            </div>
        </div>
    );
};
