import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../../assets/data/ui";

export const useStyles = makeStyles({
    toggleContainer:{
        width: "100%",
        display: "grid !important",
        gridTemplateColumns: "1fr 1fr",
    },
    toggleButtonWrapper: {
        display: "flex",
        gap: "10px",
        padding: "9px 16px !important",
        height: "40px",
        minWidth: "110px",
        width: "fit-content",
        borderRadius:"100px !important",
        border: `2px solid ${COLORS.primaryDark} !important`,
        color:`${COLORS.primaryDark} !important`,
        "&.MuiToggleButton-root.Mui-selected": {
            color: `${COLORS.white} !important`,
            backgroundColor:`${COLORS.primaryDark} !important`
        },
    },
    ml:{
        margin: "0 auto 0 8px !important"
    },
    mr:{
        margin: "0 8px 0 auto !important"
    },
    toggleIcon: {
        "& >svg":{
            transform: "translateY(3px)",
            width:"20px",
            height:"20px",
        }
    },
    label: {
        textTransform: "none",
        fontSize: "14px",
        lineHeight: "17px",
    },
});
