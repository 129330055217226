import { FunctionComponent } from "react";
import EmptyStateContainer from "../InvoicesEmptyStateView/InvoicesEmptyStateView";
import { NoMatchedInvoicesSvg, NoUnpaidInvoicesSvg } from "../../assets/icons";
import { useAppDispatch } from "../../store";
import { IInvoicesSliceState, resetInvoicesSlice } from "../../store/reducers/invoicesSlice.store";

export enum InvoicesTableEmptyStateType {
    NoInvoicesAtAll,
    NoUnpaid,
    NoMatched,
}

export const InvoicesTableEmptyState: FunctionComponent<{ type: InvoicesTableEmptyStateType }> = ({ type }) => {
    const dispatch = useAppDispatch();
    const emptyStateViews = {
        [InvoicesTableEmptyStateType.NoInvoicesAtAll]: (
            <EmptyStateContainer
                centerSvgComponent={<NoUnpaidInvoicesSvg className="CenterSvg" />}
                mainText="No invoices"
                showButton={false}
            />
        ),
        [InvoicesTableEmptyStateType.NoUnpaid]: (
            <EmptyStateContainer
                centerSvgComponent={<NoUnpaidInvoicesSvg className="CenterSvg" />}
                mainText="No unpaid invoices"
                btnText="See all"
                onBtnClick={() => {
                    dispatch(resetInvoicesSlice(IInvoicesSliceState.TotalClearState));
                }}
            />
        ),
        [InvoicesTableEmptyStateType.NoMatched]: (
            <EmptyStateContainer
                centerSvgComponent={<NoMatchedInvoicesSvg className="CenterSvg" />}
                mainText="No matched invoices"
                btnText="Back to all unpaid"
                onBtnClick={() => {
                    dispatch(resetInvoicesSlice(IInvoicesSliceState.InitialState));
                }}
            />
        ),
    };
    return emptyStateViews[type];
};
