import { Button } from "@mui/material";
import { TriangleIcon } from "../../../assets/icons";
import Block from "../block/Block";

const STRINGS = {
    systemExplain: "Here you can track your orders and see real-time updates!",
    nameShipmentExplainBold: "To name a shipment",
    nameShipmentExplain: " by yourself, click on ‘Shipment name’ header in the shipment drawer",
    closeModalButtonText: "Got it",
};

export interface INameShipmentModalProps {
    closeModal: React.Dispatch<any>;
}

export const NameShipmentModal = ({ closeModal }: INameShipmentModalProps) => {
    return (
        <Block className="name-shipment-modal">
            <TriangleIcon className="triangle-modal" />
            <div className="text-container">
                <p className="name-shipment-paragraph">{STRINGS.systemExplain}</p>
                <p className="name-shipment-paragraph">
                    <span className="name-shipment-bold">{STRINGS.nameShipmentExplainBold}</span>
                    {STRINGS.nameShipmentExplain}
                </p>
            </div>
            <Block className="name-shipment-modal-footer">
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: "#3B4858",
                        color: "#FFFFFF",
                        height: "32px",
                        margin: "8px",
                    }}
                    onClick={() => closeModal(false)}
                >
                    {STRINGS.closeModalButtonText}
                </Button>
            </Block>
        </Block>
    );
};
