import { FC, useEffect, useRef } from "react";
import "./UnicargoTextAreaInput.scss";
import { MuiTheme } from "../../helpers";
import { useAppSelector } from "../../store";
import { SxProps, TextField, Theme } from "@mui/material";

interface UnicargoTextAreaInputProps {
    placeholderText?: string;
    value?: string;
    onValueChange?: (newValue: string) => void;
    customClassName?: string;
    customSx?: SxProps<Theme>;
    maxRows?: number;
    maxChars?: number;
}

const UnicargoTextAreaInput: FC<UnicargoTextAreaInputProps> = ({
    placeholderText,
    value = "",
    onValueChange,
    customClassName = "",
    customSx,
    maxRows = 6,
    maxChars = 250,
}) => {
    const autoFocus = useAppSelector((state) => state.rootReducer.searchBar.autoFocus);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleValueChange = (newValue: string) => {
        if (
            newValue.length > maxChars ||
            newValue === value ||
            newValue[0] === " " ||
            /^$|^[0-9a-zA-Z\s]+$/.test(newValue) === false
        )
            return;
        if (onValueChange) onValueChange(newValue);
    };

    useEffect(() => {
        if (autoFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [autoFocus]);

    return (
        <MuiTheme>
            <TextField
                className={`TextInputField UnicargoTextAreaInput ${customClassName ?? ""}`}
                InputProps={{ className: "TextInputField" }}
                multiline
                maxRows={maxRows}
                value={value}
                inputRef={inputRef}
                placeholder={placeholderText}
                onChange={(e) => handleValueChange(e.target.value)}
                sx={customSx}
            />
        </MuiTheme>
    );
};

export default UnicargoTextAreaInput;
