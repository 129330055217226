import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const URL = process.env.REACT_APP_API_BASE_URL;
const autocompletePortsUrl = process.env.REACT_APP_PORTS_URL;

export const unicargoApiSlice = createApi({
    // unique API identifier for redux store.
    reducerPath: "api",
    // All of our requests will have URLs starting with '/api'.
    baseQuery: fetchBaseQuery({
        baseUrl: `${URL}/api/v1/`, // ? currently first version of api - v1.
        prepareHeaders: (headers, { getState }: any) => {
            // get token from users state slice.
            const { token } = getState().rootReducer.users;
            if (token) {
                headers.set("authorization", token);
                return headers;
            }
        },
    }),
    // The "endpoints" represent operations and requests for this server
    endpoints: () => ({}),
});

export const localApiSlice = createApi({
    // unique API identifier for redux store.
    reducerPath: "localApi",
    // All of our requests will have URLs starting with '/api'.
    baseQuery: fetchBaseQuery({
        baseUrl: "http://localhost:8080/api/v1/",
        prepareHeaders: (headers, { getState }: any) => {
            // get token from users state slice.
            const { token } = getState().rootReducer.users;
            if (token) {
                headers.set("authorization", token);
                return headers;
            }
        },
    }),
    // The "endpoints" represent operations and requests for this server
    endpoints: () => ({}),
});

// TODO: connect to google
export const googleApiSlice = createApi({
    reducerPath: "googleApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "googleBaseUrl",
    }),
    endpoints: () => ({}),
});

export const portsApiSlice = createApi({
    reducerPath: "portsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${autocompletePortsUrl}`,
    }),
    endpoints: () => ({}),
});
