/**
 *
 * @param key the key to be in the local storage.
 * @param fallbackValue optional fallback value.
 * @returns object of type T or null.
 */
export function getStorageItem<T>(key: string, fallbackValue?: T): T | null {
    const value = localStorage.getItem(key);
    if (value !== null) {
        return JSON.parse(value) as T;
    } else {
        return fallbackValue || null;
    }
}

/**
 * A function for receiving any key and value, stringify the value and save the key:value in the local storage.
 * @param key the key in the local storage.
 * @param value the value to be stringified and passed as the key's value.
 */
export function setStorageItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
}

/**
 * A function for removing the the item from the local storage.
 * @param key the key of the item to be removed
 */
export function removeStorageItem(key: string): void {
    localStorage.removeItem(key);
}
