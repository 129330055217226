import "./NotificationCardNegative.scss";
import NotificationCardBase from "../../notification-card-base/NotificationCardBase";
import { INotificationCard } from "../../../../../models";

const NotificationCardNegative = ({ className, onButtonClicked: onMarkClicked, ...rest }: INotificationCard) => (
    <NotificationCardBase
        onButtonClicked={onMarkClicked}
        className={`NotificationCardNegative ${className || ""}`}
        {...rest}
    />
);

export default NotificationCardNegative;
