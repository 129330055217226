import "./BaseButton.scss";
import { RoundRightArrow } from "../../../assets/icons";
import { IButton } from "../../../models";
import { MuiTheme } from "../../../helpers";
import { Button } from "@mui/material";

const BaseButton = ({
    withArrowLeft = false,
    withArrowRight = false,
    withBackgroundGif = false,
    fontSize = "normal",
    children,
    className,
    ...rest
}: IButton) => (
    <MuiTheme>
        <Button
            {...rest}
            className={`BaseButton  ${className ? className : ""}
                 ${withBackgroundGif ? "background-gif" : ""}
                `}
            disableRipple
            disableFocusRipple
        >
            {withArrowLeft && <RoundRightArrow style={{ rotate: "180deg" }} />}
            <div className={`children-container ${fontSize}`}>{children}</div>
            {withArrowRight && <RoundRightArrow />}
        </Button>
    </MuiTheme>
);

export default BaseButton;
