import { airportsData } from "./data/airportsData";
import { portsData } from "./data/portData";
import amazonData from "./data/amazonWarehouses.json";
import { warehouseData } from "./data/unicargoWarehouseData";
import { LocationType } from "../../../../models/rfq/rfq-types";
import { COLORS } from "../../../../assets/data/ui";

export function concatenateTruthyStrings(arr: string[]): string {
    // Filter out falsy values and join the remaining strings with a comma
    return arr.filter(Boolean).join(", ");
}

export const generateLabel = (locationType: LocationType, option: any) => {
    if (option === "" || option === undefined) return "";
    switch (locationType) {
        case LocationType.Airport: {
            return `${option.airportName}, ${option.city}, ${option.country} (${option.iata})`;
        }
        case LocationType.AmazonWarehouse: {
            return concatenateTruthyStrings([option.CODE, option.City, option.State, option.Country]);
        }
        case LocationType.Commercial: {
            return `${option.description}`;
        }
        case LocationType.Port: {
            return `${option.portName}, ${option.countryName} (${option.portCode})`;
        }
        case LocationType.Residential: {
            return `${option.description}`;
        }
        case LocationType.UnicargoWarehouse: {
            return `${option.WHCode}, ${option.City}, ${option.State}, ${option.Country}`;
        }
        case LocationType.AirportAndPort: {
            if (option.airportName) {
                return `${option.airportName}, ${option.city}, ${option.country} (${option.iata})`;
            }
            return `${option.portName}, ${option.countryName} (${option.portCode})`;
        }

        default:
            return "";
    }
};
export const convertToOBj = (locationType: LocationType, obj: any): IBannerData => {
    switch (locationType) {
        case LocationType.Airport: {
            return {
                label: obj.airportName,
            };
        }
        case LocationType.AmazonWarehouse: {
            return {
                label: obj.City,
            };
        }
        case LocationType.Commercial: {
            return {
                label: obj.description,
            };
        }
        case LocationType.Port: {
            return {
                label: obj.portName,
            };
        }
        case LocationType.Residential: {
            return {
                label: obj.description,
            };
        }
        case LocationType.UnicargoWarehouse: {
            return {
                label: obj.City,
            };
        }
        case LocationType.AirportAndPort: {
            if (obj.airportName) {
                return {
                    label: obj.airportName,
                };
            } else {
                return { label: obj.portName };
            }
        }
        default:
            return { label: "" };
    }
};
export interface IBannerData {
    label: string;
}
export const airportData = airportsData;
export const portData = portsData;
export const unicargoWarehouseData = warehouseData;
export const amazonWarehouseData = amazonData;
export const airportAndport = [...airportsData, ...portData];

export const menuPropsSx = {
    width: "254px",
    fontSize: "14px",
    color: COLORS.primaryDark,
    borderRadius: "16px !important",
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08) !important",
    margin: "10px 0",
    zIndex: 1,
};
