import { Box } from "@mui/system";
import { useEffect } from "react";
import TextInputWithUnitDropdown from "../form-elements/Text-input-with-unit-dropdown/TextInputWithUnitDropdown";
import { ISecondPhaseProps, volumeUnits, weightUnits } from "./SecondPhaseUtils";
import { useStyles } from "./useStyles";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";

const SecondPhaseTotalShipment = ({ control, setFormValue }: ISecondPhaseProps) => {
    useEffect(() => {
        tryTrackEvent("Rfq - Step 2]: 'Calculate Total Load' option selected");
    }, []);

    const classes = useStyles();
    return (
        <Box className={classes.wrapper}>
            <TextInputWithUnitDropdown
                name="weight"
                unitName="weightUnit"
                control={control}
                required={true}
                label={"Total weight"}
                options={weightUnits}
                setFormValue={setFormValue}
            />
            <TextInputWithUnitDropdown
                name="volume"
                unitName="volumeUnit"
                control={control}
                required={true}
                label={"Total volume"}
                options={volumeUnits}
                setFormValue={setFormValue}
            />
        </Box>
    );
};

export default SecondPhaseTotalShipment;
