import { FC } from "react";
import styles from "./UnicargoTextInfoContainer.module.scss";

interface UnicargoTextInfoContainerProps {
    label: string;
    value: string;
    customClassName?: string;
}

const UnicargoTextInfoContainer: FC<UnicargoTextInfoContainerProps> = ({ label, value, customClassName }) => {
    return (
        <div className={`${styles.UnicargoTextInfoContainer} ${customClassName}`}>
            <div className={styles.TextInfoContentLabel}>{label}</div>
            <div className={styles.TextInfoContentValue}>{value}</div>
        </div>
    );
};

export default UnicargoTextInfoContainer;
