import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    IInvoice,
    ITableColumn,
    PaymentStage,
    ValidInvoiceColumnNames,
    ValidInvoiceStatus,
} from "../../models/invoices/invoices.interfaces";
import {
    DateRangeFilterOptions,
    FilterTypes,
    IFilters,
    InvoicesFilterKeyNames,
    InvoicesFilterLabels,
    InvoiceSortTypes,
    ISort,
    SortOrder,
} from "../../models/filters/filters.interfaces";

export interface IInvoiceSlice {
    selectedInvoices: IInvoice[];
    paymentStage: PaymentStage;
    paymentFormPageUri?: string;
    columns: ITableColumn[];
    filters: IFilters;
    sort: ISort;
    page: number;
    limit: number;
}

export enum IInvoicesSliceState {
    InitialState,
    TotalClearState,
}

export const initialState: IInvoiceSlice = {
    sort: {
        sortType: InvoiceSortTypes.DUE_DATE,
        sortOrder: SortOrder.ASC,
    },
    page: 1,
    limit: 20,
    selectedInvoices: [],
    paymentStage: PaymentStage.InitialSelectingStage,
    paymentFormPageUri: undefined,
    // ! TODO: remove this mock data once fetching from API is implemented as expected
    columns: [
        {
            label: "Invoice #",
            keyNameToMatch: ValidInvoiceColumnNames.INVOICE_NUMBER,
            sortable: true,
        },
        {
            label: "File #",
            keyNameToMatch: ValidInvoiceColumnNames.FILE_NUMBER,
            sortable: true,
        },
        {
            label: "Reference",
            keyNameToMatch: ValidInvoiceColumnNames.REFERENCES,
        },
        {
            label: "Invoice Amount",
            keyNameToMatch: ValidInvoiceColumnNames.TOTAL_AMOUNT,
            sortable: true,
        },
        {
            label: "Invoice Date",
            keyNameToMatch: ValidInvoiceColumnNames.INVOICE_DATE,
            sortable: true,
        },
        {
            label: "Due Date",
            keyNameToMatch: ValidInvoiceColumnNames.DUE_DATE,
            sortable: true,
        },
        {
            label: "Status",
            keyNameToMatch: ValidInvoiceColumnNames.PAYMENT_STATUS,
        },
        {
            label: "Amount Due",
            keyNameToMatch: ValidInvoiceColumnNames.AMOUNT_DUE,
            sortable: true,
        },
    ],
    filters: [
        {
            keyName: InvoicesFilterKeyNames.STATUS,
            label: InvoicesFilterLabels.STATUS,
            filterType: FilterTypes.MULTI_CHOICE_SELECT,
            includeAllOption: true,
            options: [
                {
                    label: ValidInvoiceStatus.UNPAID,
                    isSelected: true,
                    value: ValidInvoiceStatus.UNPAID,
                },
                {
                    label: ValidInvoiceStatus.PAID,
                    isSelected: false,
                    value: ValidInvoiceStatus.PAID,
                },
            ],
        },
        {
            keyName: InvoicesFilterKeyNames.INVOICE_DATE,
            label: InvoicesFilterLabels.INVOICE_DATE,
            filterType: FilterTypes.DATE_RANGE,
            includeAllOption: true,
            options: [
                {
                    label: DateRangeFilterOptions.AllDates,
                    isSelected: false,
                    value: DateRangeFilterOptions.AllDates,
                },
                {
                    label: DateRangeFilterOptions.CurrentMonth,
                    isSelected: false,
                    value: null,
                },
                {
                    label: DateRangeFilterOptions.LastMonth,
                    isSelected: false,
                    value: null,
                },
                {
                    label: DateRangeFilterOptions.CurrentYear,
                    isSelected: false,
                    value: null,
                },
                {
                    label: DateRangeFilterOptions.Custom,
                    isSelected: false,
                    value: null,
                },
            ],
        },
        {
            keyName: InvoicesFilterKeyNames.CURRENCY,
            label: InvoicesFilterLabels.CURRENCY,
            filterType: FilterTypes.MULTI_CHOICE_SELECT,
            includeAllOption: true,
            options: [
                {
                    label: "USD",
                    isSelected: false,
                    value: "USD",
                },
                {
                    label: "EUR",
                    isSelected: false,
                    value: "EUR",
                },
            ],
        },
        {
            keyName: InvoicesFilterKeyNames.SERVICE,
            label: InvoicesFilterLabels.SERVICE,
            filterType: FilterTypes.MULTI_CHOICE_SELECT,
            includeAllOption: true,
            options: [
                {
                    label: "Warehouse",
                    isSelected: false,
                    value: "Warehouse",
                },
                {
                    label: "Air",
                    isSelected: false,
                    value: "Air",
                },
                {
                    label: "Ocean",
                    isSelected: false,
                    value: "Ocean",
                },
                {
                    label: "Inland",
                    isSelected: false,
                    value: "Inland",
                },
            ],
        },
        {
            keyName: InvoicesFilterKeyNames.SEARCH,
            label: InvoicesFilterLabels.SEARCH,
            filterType: FilterTypes.SEARCH,
            includeAllOption: true,
            options: [
                {
                    label: "Search",
                    isSelected: false,
                    value: "",
                },
            ],
        },
    ],
};
const invoicesSlice = createSlice({
    name: "invoices",
    initialState,
    reducers: {
        setGlobalInvoice(state, action: PayloadAction<IInvoiceSlice>) {
            state = action.payload;
            return action.payload;
        },
        resetInvoicesSlice(state, action: PayloadAction<IInvoicesSliceState>) {
            if (action.payload === IInvoicesSliceState.TotalClearState) {
                const initialFilters = initialState.filters.map((filter) => {
                    return {
                        ...filter,
                        options: filter.options.map((option) => {
                            return {
                                ...option,
                                isSelected: false,
                            };
                        }),
                    };
                });
                const initialSort = {
                    ...initialState.sort,
                    sortOrder: SortOrder.DESC,
                };
                state = {
                    ...initialState,
                    filters: initialFilters,
                    sort: initialSort,
                };
                return state;
            }
            state = initialState;
            return state;
        },
        setSelectedInvoices(state, action: PayloadAction<IInvoice[]>) {
            // Filter out invoices that are not payable
            state.selectedInvoices = action.payload.filter((invoice) =>
                [ValidInvoiceStatus.UNPAID, ValidInvoiceStatus.PARTIALLY_PAID, ValidInvoiceStatus.CREDIT].includes(
                    invoice.paymentStatus,
                ),
            );
        },
        setPaymentStage(state, action: PayloadAction<PaymentStage>) {
            state.paymentStage = action.payload;
        },
        setPaymentFormPageUri(state, action: PayloadAction<string>) {
            state.paymentFormPageUri = action.payload;
        },
        setColumns(state, action: PayloadAction<ITableColumn[]>) {
            state.columns = action.payload;
        },
        setFilters(state, action: PayloadAction<IFilters>) {
            state.filters = action.payload;
        },
        setSort(state, action: PayloadAction<ISort>) {
            if (action.payload.sortOrder === SortOrder.NONE) {
                state.sort = initialState.sort;
                return;
            }
            state.sort = action.payload;
        },
        setPage(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        setLimit(state, action: PayloadAction<number>) {
            state.limit = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => [setFilters.type, setSort.type].includes(action.type),
            (state) => {
                state.page = initialState.page;
            },
        );
        builder.addMatcher(
            (action) => [setSelectedInvoices.type].includes(action.type),
            (state) => {
                if (state.selectedInvoices.length === 0) state.paymentStage = initialState.paymentStage;
            },
        );
    },
});

export const {
    setGlobalInvoice,
    resetInvoicesSlice,
    setSelectedInvoices,
    setPaymentStage,
    setPaymentFormPageUri,
    setFilters,
    setColumns,
    setSort,
    setPage,
    setLimit,
} = invoicesSlice.actions;

export default invoicesSlice.reducer;
