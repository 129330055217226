import { useNavigate } from "react-router-dom";
import { ContainedButton } from "../../buttons";
import "./RFQBlock.scss";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";
import SkeletonLoaderItem from "../../ui-decorations/SkeletonLoader/SkeletonLoaderItem";
import { Fragment } from "react";

interface RFQBlockProps {
    shouldShowLoader?: boolean;
}

const renderLoader = (
    <div className="LoaderContainer">
        <div className="LeftContainer">
            <div className="TopLeftContainer">
                <SkeletonLoaderItem className="LeftLoader1" />
                <SkeletonLoaderItem className="LeftLoader2" />
            </div>
            <SkeletonLoaderItem className="LeftLoader3" />
        </div>
        <SkeletonLoaderItem className="RightLoader1" />
    </div>
);

const RFQBlock = ({ shouldShowLoader = false }: RFQBlockProps) => {
    const navigate = useNavigate();

    const handleGetQuote = () => {
        tryTrackEvent("[Overview Page]: 'Get a quote' button clicked");
        navigate("/rfq");
    };

    return (
        <div className={`RFQBlock ${shouldShowLoader && "Loading"}`}>
            {shouldShowLoader ? (
                renderLoader
            ) : (
                <Fragment>
                    <div className="message-container">
                        <div className="normal-text-container">Do you want to move</div>
                        <div className="bold-text-container">forward?</div>
                    </div>
                    <ContainedButton className="rfq-block-button" onClick={() => handleGetQuote()}>
                        Get a Quote
                    </ContainedButton>
                </Fragment>
            )}
        </div>
    );
};

export default RFQBlock;
