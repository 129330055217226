import { ETransportModes } from "../../../../../models/enums/ETransportModes";
import { AirplaneIconV2, BoatIconV2, TruckIconV2 } from "../../../../../assets/icons";
import { EShipmentStages } from "../../../../../models";
import { ShipmentCardDashboardOrigin, ShipmentCardDashboardTransit } from "../..";
import { ShipmentCardDashboardDestination } from "../../dashboard-cards";
import { COLORS } from "../../../../../assets/data/ui";

export const getShipmentCardIcon = (mode: ETransportModes) => {
    const icons = {
        [`${ETransportModes.Inland}`]: <TruckIconV2 />,
        [`${ETransportModes.Ocean}`]: <BoatIconV2 />,
        [`${ETransportModes.Air}`]: <AirplaneIconV2 />,
    };

    return icons[mode];
};

// TODO: pass stage as props instead of different types of cards and remove the function
export const getShipmentCard = (stage: EShipmentStages, props: any) => {
    switch (stage) {
        case EShipmentStages["Origin"]:
            return <ShipmentCardDashboardOrigin key={props.shipmentData.shipmentDetails.shipmentNumber} {...props} />;
        case EShipmentStages["In Transit"]:
            return <ShipmentCardDashboardTransit key={props.shipmentData.shipmentDetails.shipmentNumber} {...props} />;
        case EShipmentStages["Destination"]:
            return (
                <ShipmentCardDashboardDestination key={props.shipmentData.shipmentDetails.shipmentNumber} {...props} />
            );
        default:
            break;
    }
};

export const getCardColor = (stage: EShipmentStages) => {
    switch (stage) {
        case EShipmentStages.Origin:
            return COLORS.system.origin;
        case EShipmentStages["In Transit"]:
            return COLORS.system.inTransit;
        case EShipmentStages.Destination:
            return COLORS.system.destination;
        case EShipmentStages.Completed:
            return COLORS.system.completed;
    }
};

/**
 * Rounds a decimal number to a specified number of decimal places
 * @param {Number} number - The decimal number to be rounded
 * @param {Number} decimalPlaces - The number of decimal places to round the number to
 * @returns {Number} - The rounded number
 */
export const roundNumber = (number: number, decimalPlaces = 0) => {
    const factor = 10 ** decimalPlaces;
    return factor > 0 ? Math.round(number * factor) / factor : Math.round(number);
};

/**
 * Takes a string, seperates it to words (by whitespaces) and sets the first latter of each word to upper-case.
 * @param {String | undefined} value - The sentance to transform.
 * @returns {String} - The string with the first latter of each word as uppercase.
 */
export const stringToUpperCases = (value?: string) => {
    if (value) {
        const seperatedValue = value.trim().toLocaleLowerCase().split(" "); // split by white-spaces.
        // set each word's first latter to be uppercased.
        const updatedValue = seperatedValue.map((word) => {
            const UCLatter = word[0]?.toUpperCase(); // get upper cased first latter.
            return word.replace(word[0], UCLatter); // replace and return the new word.
        });
        return updatedValue.join(" ");
    }
    return "TBD"; // if undefined - return value as "To Be Determend" - TBD.
};

/**
 * Split the text on the queried term. Break the text into parts and include highlight the relevant parts.
 * @param {String} value - The string value to be compared to the query.
 * @param {String} query - The query's value.
 * @param {Boolean} isCaseSensitive - Whether the query is case sensitive or not.
 */
export const getHighlightedText = (value: string, query: string, isCaseSensitive = true) => {
    if (!query) return value;
    const lowerCaseQuery = query.toLowerCase();

    const separatedValue = isCaseSensitive ? value.split(query) : value.toLocaleLowerCase().split(lowerCaseQuery);

    const updatedValue = separatedValue.map((word, index) => {
        let originalWord = word;

        if (word.toLocaleUpperCase() !== word) originalWord = word.toLocaleUpperCase();
        if (index === separatedValue.length - 1) return originalWord;

        return (
            <span key={index}>
                {originalWord}
                <span style={{ color: COLORS.success }}>
                    {isCaseSensitive ? query : value.slice(originalWord.length, originalWord.length + query.length)}
                </span>
            </span>
        );
    });
    return updatedValue;
};
