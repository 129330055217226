import { Outlet } from "react-router-dom";
import { SidebarDesktop, SidebarMobile } from "./components";
import { useScreenWidthType } from "./helpers/custom-hooks/useScreenWidthType";
import { ScreenWidthType, UiSchemeV2 } from "./assets/data/ui";
import styles from "./App.module.scss";
import DecorativeLine from "./components/ui-decorations/decorative-backgrounds/DecorativeLine";
import { useEffect } from "react";
import useTimeout from "./helpers/custom-hooks/useTimeout";

const App = () => {
    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;
    const loadRelevantSidebar = () => (isMobile ? <SidebarMobile /> : <SidebarDesktop />);

    const handleTimeout = () => {
        window.location.reload();
        console.log("20 minutes passed, refreshing page to refresh token");
    };
    const { reset } = useTimeout(handleTimeout, 20, "minutes");

    useEffect(() => {
        // Reset the timeout on every click
        window.addEventListener("click", reset);
        return () => window.removeEventListener("click", reset);
    }, [reset]);

    return (
        <div className={styles.App}>
            {loadRelevantSidebar()}
            <div className={styles.outletContainer}>
                <Outlet />
            </div>
            <DecorativeLine
                width="100%"
                className={`${styles.LineBackgroundSvg}`}
                colorLeftLine={UiSchemeV2.colors.primaryLight}
                colorRightLine={UiSchemeV2.colors.secondaryGreen}
                colorMiddleCircle={"#05B834"}
                leftLinePercentage={60}
                height="52px"
                borderRadius="0"
                style={{ zIndex: 1 }}
            />
        </div>
    );
};

export default App;
