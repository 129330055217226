import { FormControl, FormHelperText, InputBaseComponentProps, InputLabel, OutlinedInput } from "@mui/material";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { COLORS, UiSchemeV2 } from "../../../../assets/data/ui";
import { TextFieldInputProps } from "../formInputProps";
import { MuiTheme } from "../../../../helpers";
import { useStyles } from "./useStyles";
import { handleNumericInput } from "../../../../pages/rfq/RfqPageUtils";

export const FormInputTextField = ({
    name,
    control,
    label,
    size,
    required,
    type,
    maxLength,
    validation,
    borderLeft,
    borderRight,
}: TextFieldInputProps) => {
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const classes = useStyles();

    const inputStyle: InputBaseComponentProps = {
        sx: { padding: "20px 14px 8px 14px" },
        maxLength: maxLength,
        inputMode: type === "number" ? "numeric" : "text",
        autoComplete: "off",
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (type === "number") {
            handleNumericInput(event);
        }
    };

    if (borderLeft) {
        inputStyle.sx.borderLeft = borderLeft;
    }
    if (borderRight) {
        inputStyle.sx.borderRight = borderRight;
    }

    return (
        <Controller
            name={name}
            rules={{ required: required, validate: validation }}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <MuiTheme>
                    <FormControl sx={{ width: "100%" }}>
                        <InputLabel
                            style={{
                                color: isFocus ? UiSchemeV2.colors.primaryDark : UiSchemeV2.colors.greys.grey300,
                                fontSize: "0.8rem",
                                fontFamily: "Lato-Regular",
                            }}
                            variant="filled"
                        >
                            {label && label + "*"}
                        </InputLabel>
                        <OutlinedInput
                            id="sandbox"
                            type={type}
                            className={classes.root}
                            style={{
                                width: size === "md" ? "100%" : "480px",
                            }}
                            value={value}
                            error={!!error}
                            onChange={onChange}
                            onKeyDown={handleKeyDown}
                            onFocus={() => setIsFocus((prevState) => !prevState)}
                            onBlur={() => setIsFocus((prevState) => !prevState)}
                            inputProps={inputStyle}
                        />
                        {error && <FormHelperText error>{error?.message}</FormHelperText>}
                    </FormControl>
                </MuiTheme>
            )}
        />
    );
};
