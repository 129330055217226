import { makeStyles } from "@mui/styles";
import { COLORS } from "../../assets/data/ui";
import { theme } from "../../assets/theme/theme";

const searchItemHeight = "42";

const useStyles = makeStyles({
    overviewPageHeaderContainer: {
        padding: "20px 0px 20px 2px",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyItems: "space-around",
        color: "white",
        gridArea: "h",
        width: "105%",
        fontSize: "14px",
        gap: "0px",
    },
    headLeft: {
        display: "none",
        justifySelf: "left",
        [theme.breakpoints.up("lg")]: {
            display: "flex",
            alignItems: "center",
            gridArea: "hl",
            fontSize: "1.5rem",
            paddingLeft: "1.5rem",
        },
    },
    headerRight: {
        gridArea: "hr",
        display: "flex",
        alignItems: "center",
        gap: "30px",
        paddingRight: "5%",
    },
    searchBarPopper: {
        backgroundColor: "white",
        marginTop: "8px !important",
        padding: "5px",
        width: "40%",
        zIndex: 2,
        borderRadius: "16px",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
        [theme.breakpoints.down("lg")]: {
            width: "75%",
        },
    },
    searchBarPopup: {
        maxHeight: "calc(42px * 5)",
        overflowY: "auto",
        display: "flex",
        gap: "0.5rem",
        flexDirection: "column",
        borderRadius: "16px",
        padding: "0.5rem 1rem",
    },
    noResultsMessage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: COLORS.primaryDark,
        height: `calc(${searchItemHeight}px * 2 - 2rem)`, // ? cant align self so height based on minimum container height - container padding
    },
    searchBarItemContainer: {
        height: `${searchItemHeight}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "1rem",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: COLORS.general.dividers,
            borderRadius: "4px",
        },
    },
    searchItemIcon: {
        minWidth: "26px",
    },
    searchBarItemDetails: {
        color: COLORS.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    divider: {
        height: "80%",
        borderLeft: `1px solid ${COLORS.main}`,
        margin: "0 5px",
    },
    reviewAndPayContainer: {
        textDecoration: "none",
        borderBottom: "1px solid white",
        paddingBottom: "0.5px",
        cursor: "pointer",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    unpaidInvoicesContainer: {
        display: "flex",
        alignItems: "center",
        gap: "6px",
    },
    unpaidInvoicesCount: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        height: "20px",
        width: "20px",
        color: "#1D4650",
        fontSize: "10px",
        backgroundColor: COLORS.success,
    },
    mobileSearch: {
        width: "100%",
        backgroundColor: "white",
        borderRadius: "20px",
    },
    customerNameSpan: {
        color: COLORS.white,
        fontSize: "18px",
        fontFamily: "Lato-Thin",
    },
});

export default useStyles;
