import React from "react";
import ReactDOM from "react-dom";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { Provider } from "react-redux";
import { store } from "./store";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify, Auth } from "aws-amplify";
import { AwsConfigAuth } from "./helpers/auth/auth";

// Configure aws-amplify to use our user pool.
Amplify.configure(AwsConfigAuth);
Auth.configure(AwsConfigAuth);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Authenticator.Provider>
                <RouterProvider router={router} />
            </Authenticator.Provider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root"),
);
