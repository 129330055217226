import { useEffect } from "react";

export const useOutsideClick = (
    ref: React.MutableRefObject<any>,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
};
