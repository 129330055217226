import { Box, LinearProgress, ThemeProvider } from "@mui/material";
import { stepperTheme } from "./stepperTheme";
import { StepperPropsType } from "../../../models/rfq/rfq-types";

const RfqStepper: React.FC<StepperPropsType> = ({ currentStep, numberOfSteps }) => {
    return (
        <ThemeProvider theme={stepperTheme}>
            <Box sx={{ width: "100%" }}>
                <LinearProgress variant="determinate" value={(currentStep * 100) / numberOfSteps} />
            </Box>
        </ThemeProvider>
    );
};

export default RfqStepper;
