export enum EDetailTabs {
    route,
    shipment,
    documents,
}

export const TABS: Record<EDetailTabs, string> = {
    [EDetailTabs.route]: "My Route",
    [EDetailTabs.shipment]: "My Shipment",
    [EDetailTabs.documents]: "My Documents",
};

export const STRINGS = {
    ACTUAL_DELIVERY_MSG: "Actual delivered to",
    TOTAL_DAYS_MSG: "Total days:",
};
