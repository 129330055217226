import { capitalizeFirstLetter } from "../../components/accordionStepper/utils";
import { IShipmentStep, ETransportModes, EShipmentStages, IShipment, IShipmentDetails } from "../../models";
import { EShipmentSteps } from "../../models/entities/shipment/EShipmentSteps";
import { getFormattedDate } from "./DateFormatsService.service";

export type ShipmentType = "Air" | "Ocean" | "Inland";
export type ShipmentStatus = "origin" | "inTransit" | "destination" | "close";

export interface IShipmentQueryParams {
    type?: ShipmentType[];
    status?: ShipmentStatus[];
    limit?: number;
    page?: number;
    search?: string;
}

export const checkInstanceOfShipmentType = (value: string): value is ShipmentType => {
    return value === "Air" || value === "Ocean" || value === "Inland";
};

export const checkInstanceOfShipmentStatus = (value: string): value is ShipmentStatus => {
    return value === "origin" || value === "inTransit" || value === "destination" || value === "close";
};

/**
 * Build a query string from an object.
 * @param params An object containing the query parameters.
 * @returns A string representation of the query parameters, in the form 'key1=value1&key2=value2'.
 */
export const buildShipmentQueryString = (params: IShipmentQueryParams): string => {
    const queryParams = new URLSearchParams();
    Object.entries(params).forEach((pair) => {
        const [key, value] = pair;
        if (key === "type" || key === "status") {
            const valuesArray = value as Array<ShipmentType | ShipmentStatus>;
            if (valuesArray) {
                valuesArray.forEach((element) => {
                    queryParams.append(key, element);
                });
            }
        } else {
            if (value) {
                queryParams.append(key, value);
            }
        }
    });
    return queryParams.toString();
};

function isDateAfter(dateString1: string, dateString2: string) {
    const date1 = parseDate(dateString1);
    const date2 = parseDate(dateString2);

    if (date1 < date2) {
        return true;
    } else {
        return false;
    }
}

function parseDate(dateString: string) {
    if (dateString.includes("T")) {
        return new Date(dateString);
    } else {
        const parts = dateString.split(" ");
        const month = getMonthNumber(parts[0]);
        const day = parseInt(parts[1]?.replace(",", ""));
        const year = parseInt(parts[2]);
        return new Date(year, month - 1, day);
    }
}

function getMonthNumber(month: string): number {
    const months: { [key: string]: number } = {
        Jan: 0,
        Feb: 1,
        Mar: 2,
        Apr: 3,
        May: 4,
        Jun: 5,
        Jul: 6,
        Aug: 7,
        Sep: 8,
        Oct: 9,
        Nov: 10,
        Dec: 11,
    };

    return months[month];
}

/**
 * Each step has 4 time values - ETD, ATD, ETA, ATA.
 * The first and last couples are a step, each.
 *
 * @param {IShipmentStep[]} steps - The original steps.
 * @param {string} transportMode - The transport mode (ETransportModes)
 * @returns [currentStage, activeStep]
 */
export const getShipmentCurrentStage = (
    steps: IShipmentStep[],
    transportMode: ETransportModes,
    PODReceiveDate?: string | null,
    mainCarriageATA?: string | null,
    shipmentDetails?: IShipmentDetails,
): EShipmentStages => {
    let currentStage = EShipmentStages["Origin"];

    let moveType;
    if (shipmentDetails?.moveTypeEnglishName) {
        moveType = capitalizeFirstLetter(shipmentDetails.moveTypeEnglishName);
    }

    // The logic for completed shipment:
    // Option 1 - OperationallyClosed === true
    // Option 2 - Last step + 7 days
    // What is "Last step" ?
    // If moveType is D2D||P2D ---> pickupForDeliveryATA
    // If moveType is D2P||P2P ---> onCarriage?(onCarriageATA), else(mainCarriageFinalATA)
    const currentDateMinus7Days: Date = new Date();
    currentDateMinus7Days.setDate(currentDateMinus7Days.getDate() - 7);

    let currentDateMinus7DaysString: string =
        currentDateMinus7Days.getMonth() +
        2 +
        ", " +
        currentDateMinus7Days.getDate() +
        ", " +
        currentDateMinus7Days.getFullYear();

    currentDateMinus7DaysString = getFormattedDate(currentDateMinus7DaysString);

    if (shipmentDetails?.operationallyClosed) {
        return EShipmentStages["Completed"];
    }

    if (shipmentDetails?.mainCarriageFinalATA && (moveType === "DoorToPort" || moveType === "PortToPort")) {
        if (currentDateMinus7Days) {
            currentStage = EShipmentStages["Completed"];
        }
    }

    if ([ETransportModes.Air, ETransportModes.Ocean].includes(transportMode)) {
        for (let i = 0; i < steps.length; i++) {
            const step = steps[i];
            if (step.stepName === "mainCarriage") {
                if (step.ATD || step.ATA) {
                    currentStage = EShipmentStages["In Transit"];
                }
            }
            if (step.stepName === "availableForDelivery" || step.stepName === "pickupForDelivery") {
                if (!!step.ATA || !!step.ATD) {
                    currentStage = EShipmentStages["Destination"];
                    // Destination card is not display in "port to port" / "door to port" move type
                    if (moveType === "DoorToPort" || moveType === "PortToPort") {
                        currentStage = EShipmentStages["In Transit"];
                    }
                }
            }
            if (step.stepName === "pickupForDelivery" && (moveType === "DoorToDoor" || moveType === "PortToDoor")) {
                if (!!step.ATA && isDateAfter(step.ATA, currentDateMinus7DaysString)) {
                    currentStage = EShipmentStages["Completed"];
                }
            }

            if (step.stepName === "onCarriage" && (moveType === "DoorToPort" || moveType === "PortToPort")) {
                if (!!step.ATA && isDateAfter(step.ATA, currentDateMinus7DaysString)) {
                    currentStage = EShipmentStages["Completed"];
                }
            }
        }
    }

    if ([ETransportModes.Inland].includes(transportMode)) {
        for (let i = 0; i < steps.length; i++) {
            const step = steps[i];
            if (step.stepName === "mainCarriage") {
                // In Inland shipment, if we have ATD in the pickup step, we consider it as In Transit
                const pickupStep = steps.find((step) => step.stepName === "pickup");
                if (step.ATD || step.ATA || pickupStep?.ATD) {
                    currentStage = EShipmentStages["In Transit"];
                }
            }
            if (step.stepName === "pickupForDelivery") {
                if (step.ATA) {
                    currentStage = EShipmentStages["Destination"];
                }
            }
            // TODO : ask Chen about it, make sure that in inland we only have door to door move type
            if (step.stepName === "availableForDelivery") {
                if (!!step.ATA && isDateAfter(step.ATA, currentDateMinus7DaysString)) {
                    currentStage = EShipmentStages["Completed"];
                }
            }
        }
    }

    return currentStage;
};

// TODO: See if can be improved by making it generic.
/**
 * The dashboard's steps calculation (without transhipments, etc).
 * @param {IShipmentStep[]} steps - The original steps from the server.
 * @returns
 */

export const getPartialSteps = (
    steps: IShipmentStep[],
    mainCarriageFinalATA?: string | null,
    transportMode?: string,
    moveTypeEnglishName?: string | null,
): [IShipmentStep[], number] => {
    let separatedSteps: IShipmentStep[] = [];
    let activeStep = 0;

    // Exclude the unnecessary steps
    const excludedSteps = ["transshipment1", "transshipment2", "transshipment3", "availableForDelivery"];

    const onCarriage = steps.find((step) => step.stepName === "onCarriage");
    let splitMainCarriage = false;

    // Check if the 'onCarriage' step exists in the steps array.
    if (onCarriage) {
        // Destructure the dates associated with the 'onCarriage' step for easier access.
        const { ATA, ATD, ETA, ETD } = onCarriage;

        // Determine if all date values are falsy, which suggests the step is effectively empty.
        const allValuesAreFalsy = [ATA, ATD, ETA, ETD].every(isEffectivelyFalsy);

        // If the 'onCarriage' step is found but contains no meaningful information (all values are falsy),
        // consider it an empty step and add it to the list of steps to exclude. Additionally, flag to split the main carriage.
        if (allValuesAreFalsy) {
            excludedSteps.push("onCarriage");
            splitMainCarriage = true;
        }
    } else {
        excludedSteps.push("onCarriage");
        splitMainCarriage = true;
    }

    steps.forEach((step) => {
        // Break down and assign the steps while ignoring the irrelevant steps in this specific process (relevant to dashboard stepper).
        if (!excludedSteps.includes(step.stepName)) {
            // Check if the current step is not in the list of steps to exclude.

            // If the step is "mainCarriage" and we've determined it needs to be split into departure and arrival steps.
            if (step.stepName === "mainCarriage" && splitMainCarriage) {
                const depStep: IShipmentStep = {
                    stepName: `${step.stepName}Departure`,
                    ETD: step.ETD,
                    ATD: step.ATD,
                    location: step.location,
                };
                separatedSteps.push(depStep);

                // The mainCarriageArrival available only on Ocean & Air
                if (transportMode !== "Inland") {
                    const arrivalStep: IShipmentStep = {
                        stepName: `${step.stepName}Arrival`,
                        ETA: step.ETA,
                        ATA: step.ATA,
                        location: step.destination,
                    };
                    separatedSteps.push(arrivalStep);
                }
            } else {
                separatedSteps.push(step);
            }
        }
    });

    // When move type is D2P or P2P, remove pickupForDelivery cause we don't need it anymore
    if(moveTypeEnglishName) {
        moveTypeEnglishName = capitalizeFirstLetter(moveTypeEnglishName);

        if (moveTypeEnglishName === "PortToPort" || moveTypeEnglishName === "DoorToPort") {
            separatedSteps = separatedSteps.filter(step => step.stepName !== "pickupForDelivery");
        }
    }

    // calculate activeStep
    for (let i = separatedSteps.length - 1; i >= 0; i--) {
        const step = separatedSteps[i];
        if (step.ATD || step.ATA) {
            // If this step is mainCarriageArrival and there is no mainCarriageFinalATA, we wont continue to the next step
            if (step.stepName === "mainCarriageArrival") {
                if (mainCarriageFinalATA) activeStep = i;
                else continue;
            }
            // If this step is onCarriage and there is no ATA, we wont continue to the next step
            if (step.stepName === "onCarriage") {
                if (step.ATA) activeStep = i;
                else continue;
            }
            // If this step is pickupForDelivery and there is no ATA, we wont continue to the next step
            if (step.stepName === "pickupForDelivery") {
                if (step.ATA) activeStep = i;
                else continue;
            }
            activeStep = i;
            break;
        } else {
            // If last element and it still doesn't have values in it, the active step is the 'locations' step (more details in the Stepper component).
            if (i === 0) {
                activeStep = -1;
            }
        }
    }
    
    return [separatedSteps, activeStep];
};

/**
 * Calculate a shipment's full path.
 * @param {IShipment} shipment - The shipment object
 * @returns {[IShipmentStep[],number]} The shipment's steps (seperated to stations) and a number, representing the active step in the shipment.
 */
export const getFullSteps = (shipment: IShipment): [IShipmentStep[], number] => {
    const { steps, preDelivery, postDelivery, shipmentDetails } = shipment;
    const { plannedCargoReadyDate, approvedCargoReadyDate, orderConfirmed } = preDelivery;
    const { transportMode } = shipmentDetails;
    const { booking } = postDelivery;
    const seperatedSteps: IShipmentStep[] = [];
    let activeStep = 0;
    let hasTransshipments = true;
    const isInlandMode = ETransportModes[transportMode] === ETransportModes.Inland;
    let mainCarriageObj: IShipmentStep = {
        stepName: "",
    };
    // Setting the initial steps
    seperatedSteps.push({
        stepName: "orderConfirmedDate",
        ATA: orderConfirmed || undefined,
    });

    if (!isInlandMode) {
        // ? supposed to be date value, instead will be any string value / undefined.
        seperatedSteps.push({
            stepName: "booked",
            ATA: booking ? "booked!" : undefined,
        });

        seperatedSteps.push({
            stepName: "cargoReady",
            ETA: plannedCargoReadyDate || undefined,
            ATA: approvedCargoReadyDate || undefined,
        });
    }
    if (isInlandMode) {
        steps.forEach((step) => {
            const { stepName, ETD, ATD, ETA, ATA, location, destination } = step;
            if (isStepName(stepName, EShipmentSteps.orderConfirmed)) {
                return;
            }

            // ETD,ATD
            const depStep: IShipmentStep = {
                stepName: `${stepName}Departure`,
                ETD,
                ATD,
                location,
            };

            // ETA, ATA
            const arrivalStep: IShipmentStep = {
                stepName: `${stepName}Arrival`,
                ETA,
                ATA,
                location: destination,
            };

            seperatedSteps.push(depStep);
            seperatedSteps.push(arrivalStep);
        });
    } else {
        steps.forEach((step, index) => {
            const { stepName, ETD, ATD, ETA, ATA, location, destination } = step;

            // handle specific cases
            if (isStepName(stepName, EShipmentSteps.availableForDelivery)) {
                seperatedSteps.push({
                    stepName: `${stepName}Date`,
                    ATA,
                });
            } else {
                // handle normal cases
                // ETD,ATD
                const depStep: IShipmentStep = {
                    stepName: `${stepName}Departure`,
                    ETD,
                    ATD,
                    location,
                };

                // ETA, ATA
                const arrivalStep: IShipmentStep = {
                    stepName: `${stepName}Arrival`,
                    ETA,
                    ATA,
                    location: destination,
                };

                // check if transshipments exist & save mainCarriageObj to assign the dest port at last existing element later.
                if (isStepName(stepName, EShipmentSteps.mainCarriage)) {
                    mainCarriageObj = step;
                    hasTransshipments = checkNextStepExists(steps[index + 1]);
                }

                // handle mainCarriage <-> onCarriage specific data cases.
                if (
                    isStepName(stepName, EShipmentSteps.mainCarriage) || // ! here on purpose
                    isStepName(stepName, EShipmentSteps.transshipment1) ||
                    isStepName(stepName, EShipmentSteps.transshipment2) ||
                    isStepName(stepName, EShipmentSteps.transshipment3)
                    // ||
                    // isStepName(stepName, EShipmentSteps.onCarriage)
                ) {
                    if (hasTransshipments) {
                        // If first transshipment exists
                        const nextStep = steps[index + 1];
                        if (checkNextStepExists(nextStep)) {
                            arrivalStep.location = nextStep.location;
                        } else {
                            // If next step doesn't exist, this step is the last one.
                            arrivalStep.location = mainCarriageObj.destination;
                            hasTransshipments = false;
                        }
                        seperatedSteps.push(depStep);
                        seperatedSteps.push(arrivalStep);
                    } else if (isStepName(stepName, EShipmentSteps.mainCarriage)) {
                        seperatedSteps.push(depStep);
                        seperatedSteps.push(arrivalStep);
                    }
                } else {
                    // Push final objects to the steps array
                    if (
                        (step.location && isStepName(stepName, EShipmentSteps.onCarriage)) ||
                        !isStepName(stepName, EShipmentSteps.onCarriage)
                    ) {
                        seperatedSteps.push(depStep);
                        seperatedSteps.push(arrivalStep);
                    }
                }
            }
        });
    }

    // calculate activeStep
    for (let i = seperatedSteps.length - 1; i >= 0; i--) {
        const step = seperatedSteps[i];
        if (step.ATD || step.ATA) {
            activeStep = i;
            break;
        }
    }
    return [seperatedSteps, activeStep];
};

const isStepName = (stepName: string, step: EShipmentSteps) => {
    return EShipmentSteps[stepName as keyof typeof EShipmentSteps] === step;
};

const checkNextStepExists = (nextStep: IShipmentStep) => {
    return Boolean(nextStep.location || nextStep.ETD || nextStep.ATD || nextStep.ETA || nextStep.ATA);
};

export const calcShipmentDaysSum = (
    orderConfirmedDate: string | null | undefined,
    destinationATA: string | null | undefined,
) => {
    if (!orderConfirmedDate || !destinationATA) {
        return null;
    }

    const startDate = new Date(orderConfirmedDate);
    const endDate = new Date(destinationATA);
    const fallbackValue = "Invalid Date";
    if (startDate.toDateString() === fallbackValue || endDate.toDateString() === fallbackValue) {
        return null;
    }

    const difference = endDate.getTime() - startDate.getTime();
    const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return totalDays;
};

function isEffectivelyFalsy(value: string | string[] | undefined | null) {
    return !value || (Array.isArray(value) && value.length === 0);
}
