import { EUpdateCardStatus, INotification } from "../../../../models";
import { NotificationCardDefault, NotificationCardNegative, NotificationCardPositive } from "../../../cards";

const getNotificationCardByType = (data: INotification, buttonCallback: (id: number) => void, key: string) => {
    switch (EUpdateCardStatus[data.type]) {
        case EUpdateCardStatus.positive:
            return <NotificationCardPositive key={key} onButtonClicked={() => buttonCallback(data.id)} {...data} />;
        case EUpdateCardStatus.negative:
            return <NotificationCardNegative key={key} onButtonClicked={() => buttonCallback(data.id)} {...data} />;
        case EUpdateCardStatus.default:
        default:
            return <NotificationCardDefault key={key} onButtonClicked={() => buttonCallback(data.id)} {...data} />;
    }
};

export default getNotificationCardByType;
