import { ToggleButtonGroup, ToggleButton as MuiToggleButton, FormControl } from "@mui/material";
import { Controller } from "react-hook-form";
import { MuiTheme } from "../../../../helpers";
import { ToggleButtonInput } from "./toggleButtonUtils";
import { useStyles } from "./useStyles";

export const ToggleButton = ({ name, control, required, buttonsDetails }: ToggleButtonInput) => {
    const classes = useStyles();
    return (
        <Controller
            name={name}
            rules={{ required: required }}
            control={control}
            render={({ field: { onChange, value } }) => (
                <MuiTheme>
                    <FormControl>
                        <ToggleButtonGroup
                            value={value}
                            exclusive
                            className={classes.toggleContainer}
                            onChange={(event, newValue) => {
                                if (newValue !== null) {
                                    onChange(newValue);
                                }
                            }}
                        >
                            <MuiToggleButton
                                value={buttonsDetails.firstButton.label}
                                className={`${classes.toggleButtonWrapper} ${classes.mr}`}
                                aria-label={buttonsDetails.firstButton.label}
                            >
                                <span className={classes.toggleIcon}>
                                    {value === buttonsDetails.firstButton.label
                                        ? buttonsDetails.firstButton.activeIcon
                                        : buttonsDetails.firstButton.icon}
                                </span>
                                <span className={classes.label}>{buttonsDetails.firstButton.label}</span>
                            </MuiToggleButton>
                            <MuiToggleButton
                                value={buttonsDetails.secondButton.label}
                                className={`${classes.toggleButtonWrapper} ${classes.ml}`}
                                aria-label={buttonsDetails.secondButton.label}
                            >
                                <span className={classes.toggleIcon}>
                                    {value === buttonsDetails.secondButton.label
                                        ? buttonsDetails.secondButton.activeIcon
                                        : buttonsDetails.secondButton.icon}
                                </span>
                                <span className={classes.label}>{buttonsDetails.secondButton.label}</span>
                            </MuiToggleButton>
                        </ToggleButtonGroup>
                    </FormControl>
                </MuiTheme>
            )}
        />
    );
};
