import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../../assets/data/ui";

export const useStyles = makeStyles({
    root: {
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${COLORS.veryLight} !important`,
        },
        "&:focus-within .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${COLORS.primaryDark} !important`,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${COLORS.primaryDark} !important`,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
            borderRadius: "16px",
        },
        "& input[type=number]": {
            "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
});
