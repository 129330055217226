export interface ITableColumn {
    label: string;
    keyNameToMatch: ValidInvoiceColumnNames;
    sortable?: boolean;
}

export type PickOnly<T, K extends keyof T> = Pick<T, K> & { [P in Exclude<keyof T, K>]?: never };

export type IBasicInvoice = PickOnly<IInvoice, "invoiceNumber" | "amountDue" | "currencyAcronym">;

export interface IInvoice {
    metadata?: unknown;
    invoiceNumber: string;
    paymentStatus: ValidInvoiceStatus;
    dueDate: Date | string | null;
    invoiceNotes: string | null;
    invoiceDate: Date | string | null;
    references?: string[];
    fileNumber: string;
    totalAmount: number;
    amountDue: number;
    billToCode: string;
    invoiceType: string;
    currencyAcronym: string;
    currencySign?: string;
    createdAt: Date | string | null;
    updatedAt?: Date | string | null;
    shipment: IInvoiceShipmentData;
    lines: IInvoiceLine[];
}

export interface IInvoiceShipmentData {
    reference?: string[]; // Optional
    customerReferenceID?: string; // Optional
    purchaseOrderNumber?: string; // Optional
    amazonShipmentID?: string; // Optional
    amazonReferenceID?: string; // Optional
    shipperName?: string; // Needed: On all cases except specialServiceCode: WHS
    consigneeName: string; // Needed: Always
    specialServicesCode: string; // Needed: Always
    shipmentLoad: string; // Needed: Always
    shipmentNumber: string; // Needed: Always
    transportMode: "Ocean" | "Air" | "Inland"; // Needed: Always
    descriptionOfGoods?: string; // Needed: Always
    shipperCountryCode?: string; // Needed: Always
    consigneeCountryCode?: string; // Needed: Always
    commentsDate?: string; // Needed: On specialServiceCode: WHS
    specialServicesEnglishName?: string; // Needed: On specialServiceCode: WHS
    numberOfContainers?: number; // Needed: On specialServiceCode: All except WHS & on shipmentLoad: FCL
    packageQuantityType?: string; // Needed: On specialServiceCode: All except WHS & on shipmentLoad: FCL
    chargeWeightInKG?: number; // Needed: On specialServiceCode: All except WHS & on shipmentLoad: FCL
    numberOfPackages?: number; // Needed: On specialServiceCode: All except WHS & on shipmentLoad: LCL, AIR, FTL, LTL
    grossWeightInKG?: number; // Needed: On specialServiceCode: All except WHS & on shipmentLoad: LCL, AIR, FTL, LTL
    totalVolumInCBM?: number; // Needed: On specialServiceCode: All except WHS & on shipmentLoad: LCL, AIR, FTL, LTL
    containerNumberType?: string | null; // Needed: On specialServiceCode: All except WHS & on shipmentLoad: FCL
    shipmentName?: string | null; // Not needed
    voyageNumber?: string | null; // Not needed
    quoteNumber?: string | null; // Not needed
    operationStatus?: string | null; // Not needed
}

export type IInvoiceShipmentDataKeys = keyof IInvoiceShipmentData;

type TShipmentReferences = Pick<
    IInvoiceShipmentData,
    "customerReferenceID" | "purchaseOrderNumber" | "amazonShipmentID" | "amazonReferenceID"
>;

export const ShipmentReferencesKeysNames: TShipmentReferences = {
    customerReferenceID: "Customer Reference ID",
    purchaseOrderNumber: "Purchase Order",
    amazonShipmentID: "Amazon Shipment ID",
    amazonReferenceID: "Amazon Reference ID",
};

export interface IInvoiceLine {
    lineDescription: string;
    lineChargeTypeCode: string;
    lineChargeEnglishName: string;
    uomCode: string;
    lineQuantity: number;
    lineUnitPrice: number;
    lineAmountInForeign: number;
    lineAmountInInvoiceCurrency: number;
    foreignCurrencyAcronym: string;
    foreignCurrencySign: string;
    lineNotes?: string;
}

export const DisplayableInvoiceShipmentKeyOrder = {
    specialServicesEnglishName: 1,
    shipperName: 2,
    consigneeName: 3,
    commentsDate: 4,
    numberOfContainers: 5,
    packageQuantityType: 6,
    numberOfPackages: 7,
    grossWeightInKG: 8,
    totalVolumInCBM: 9,
    chargeWeightInKG: 10,
    descriptionOfGoods: 11,
} as Record<IInvoiceShipmentDataKeys, number>;

export type IInvoiceLineKeys = keyof IInvoiceLine;
export type ValidInvoiceLineKeysToDisplay = keyof Pick<
    IInvoiceLine,
    | "lineDescription"
    | "uomCode"
    | "lineQuantity"
    | "lineUnitPrice"
    | "lineAmountInForeign"
    | "lineAmountInInvoiceCurrency"
    | "lineNotes"
>;

export const ValidLineItemsColumnNames: Record<ValidInvoiceLineKeysToDisplay, string> = Object.freeze({
    lineDescription: "Description",
    uomCode: "UOM",
    lineQuantity: "Quantity",
    lineUnitPrice: "Unit Price",
    lineAmountInForeign: "Foreign Amount",
    lineAmountInInvoiceCurrency: "Total Amount",
    lineNotes: "Notes",
});

export const InvoiceSpecialServicesCodePrefix = {
    Warehouse: "WHS",
    Unicargo: "UNC",
    CUS: "CUS",
    CBA: "CBA",
    CBO: "CBO",
    IMA: "IMA",
    IMO: "IMO",
} as const;
export type InvoiceSpecialServicesCodePrefixType =
    (typeof InvoiceSpecialServicesCodePrefix)[keyof typeof InvoiceSpecialServicesCodePrefix];

export const InvoiceSubTypes = {
    FCL: "FCL",
    LCL: "LCL",
    AIR: "AIR",
    FTL: "FTL",
    LTL: "LTL",
} as const;
export type InvoiceSubType = (typeof InvoiceSubTypes)[keyof typeof InvoiceSubTypes];

export const InvoiceSubTypeTransportMethodMap: Record<"Ocean" | "Air" | "Inland", InvoiceSubType[]> = {
    Ocean: [InvoiceSubTypes.FCL, InvoiceSubTypes.LCL],
    Air: [InvoiceSubTypes.AIR],
    Inland: [InvoiceSubTypes.FTL, InvoiceSubTypes.LTL],
};

export enum ValidInvoiceColumnNames {
    INVOICE_NUMBER = "invoiceNumber",
    PAYMENT_STATUS = "paymentStatus",
    REFERENCES = "references",
    DUE_DATE = "dueDate",
    FILE_NUMBER = "fileNumber",
    TOTAL_AMOUNT = "totalAmount",
    AMOUNT_DUE = "amountDue",
    INVOICE_DATE = "invoiceDate",
}

export enum ValidInvoiceStatus {
    PAID = "Paid",
    UNPAID = "Unpaid",
    PARTIALLY_PAID = "Partially Paid",
    CREDIT = "Credit",
    PROCESSING = "Processing",
}

export enum CurrencySymbols {
    USD = "$",
    CAD = "CA$",
    AUD = "AU$",
    EUR = "€",
    GBP = "£",
    JPY = "¥",
    ILS = "₪",
    NIS = "₪",
}

export enum PaymentStage {
    InitialSelectingStage = "InitialSelectingStage",
    ReadingSelectedInvoicesStage = "ReadingSelectedInvoicesStage",
    SelectingPaymentMethodStage = "SelectingPaymentMethodStage",
    FillingPaymentPageStage = "FillingPaymentPageStage",
    FillingBankTransferFormStage = "FillingBankTransferFormStage",
    FillingPayoneerFormStage = "FillingPayoneerFormStage",
    SendingPaymentQueryParamsResultStage = "SendingPaymentQueryParamsResultStage",
    ErrorInPaymentFlowStage = "ErrorInPaymentFlowStage",
    PaymentSuccessStage = "PaymentSuccessStage",
}

export enum PaymentMethod {
    CreditCard = "CreditCard",
    BankTransfer = "BankTransfer",
    Payoneer = "Payoneer",
}
