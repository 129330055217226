import SkeletonLoaderItem from "../../ui-decorations/SkeletonLoader/SkeletonLoaderItem";
import styles from "./ShipmentLoaderCard.module.scss";

const ShipmentLoaderCard = () => (
    <div className={`${styles.ShipmentCardLoader}`}>
        <div className={`${styles.startContainer}`}>
            <SkeletonLoaderItem className={`${styles.Loader} ${styles.Loader1}`} />
            <SkeletonLoaderItem className={`${styles.Loader} ${styles.Loader2}`} />
            <SkeletonLoaderItem className={`${styles.Loader} ${styles.Loader3}`} />
            <SkeletonLoaderItem className={`${styles.Loader} ${styles.Loader4}`} />
        </div>
        <div className={`${styles.endContainer}`}>
            <div className={`${styles.TopLoaderSection}`}>
                <div className={`${styles.TopLoadersContainer}`}>
                    <SkeletonLoaderItem className={`${styles.Loader} ${styles.Loader1}`} />
                    <SkeletonLoaderItem className={`${styles.Loader} ${styles.Loader2}`} />
                </div>
            </div>
            <SkeletonLoaderItem className={`${styles.Loader} ${styles.Loader3}`} />
            <div className={`${styles.BottomLoadersContainer}`}>
                <div className={`${styles.LeftLoadersContainer}`}>
                    <SkeletonLoaderItem className={`${styles.Loader} ${styles.Loader4}`} />
                    <SkeletonLoaderItem className={`${styles.Loader} ${styles.Loader5}`} />
                </div>
                <div className={`${styles.middleLoadersContainer}`}>
                    <SkeletonLoaderItem className={`${styles.Loader} ${styles.Loader6}`} />
                    <SkeletonLoaderItem className={`${styles.Loader} ${styles.Loader7}`} />
                </div>
                <div className={`${styles.rightLoadersSection}`}>
                    <div className={`${styles.rightLoadersContainer}`}>
                        <SkeletonLoaderItem className={`${styles.Loader} ${styles.Loader8}`} />
                        <SkeletonLoaderItem className={`${styles.Loader} ${styles.Loader9}`} />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default ShipmentLoaderCard;
