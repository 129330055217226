import { Tooltip, TooltipClasses, TooltipProps } from "@mui/material";
import { FunctionComponent, ReactElement } from "react";
import "./UnicargoTooltip.scss";

interface UnicargoTooltipProps {
    child: ReactElement;
    childStyle?: React.CSSProperties;
    childClassName?: string;
    onChildClick?: () => void;
    popperChildComponent?: React.ReactNode;
    popperClassName?: string;
    overallClasses?: Partial<TooltipClasses>;
    popperPlacement?: TooltipProps["placement"];
    popperStyle?: React.CSSProperties;
    onPopperClick?: () => void;
    isOpen?: boolean;
}

const UnicargoTooltip: FunctionComponent<UnicargoTooltipProps> = ({
    child,
    childStyle,
    childClassName,
    onChildClick,
    popperChildComponent,
    popperClassName,
    overallClasses,
    popperPlacement,
    popperStyle,
    onPopperClick,
    isOpen: isOpen,
}: UnicargoTooltipProps) => {
    return (
        <Tooltip
            open={isOpen}
            className={`UnicargoTooltipOriginalComponent ${childClassName}`}
            style={childStyle}
            placement={popperPlacement ?? "top"}
            arrow
            describeChild
            enterTouchDelay={0}
            classes={overallClasses}
            PopperProps={{
                className: `UnicargoTooltipPopper ${popperClassName}`,
                style: popperStyle,
                onClick: onPopperClick,
                sx: {
                    width: "max-content",
                },
            }}
            title={popperChildComponent}
            onClick={onChildClick}
        >
            {child}
        </Tooltip>
    );
};

export default UnicargoTooltip;
