import "./NotificationCardBase.scss";
import { Block } from "../../..";
import {
    ArrowNotificationIcon,
    ConfirmedIcon,
    GreenLines,
    MarkOneAsRead,
    RedLineAndRectangle,
} from "../../../../assets/icons";
import { INotificationCard } from "../../../../models";
import TextButton from "../../../buttons/text-button/TextButton";
import { useEffect, useMemo, useState } from "react";
import { STATUS_MAP, STRINGS } from "../shared/resources";
import { getFormattedDate, getTimeFromDateTime } from "../../../../helpers/services";
import { ScreenWidthType } from "../../../../assets/data/ui";
import { useScreenWidthType } from "../../../../helpers/custom-hooks/useScreenWidthType";
import { IconButton } from "@mui/material";

/**
 * This component holds the basic design and common functionality of any <Notification> component.
 */
const NotificationCardBase = ({
    className,
    onButtonClicked,
    shipment,
    statusCode,
    id,
    shipmentNumber,
    createdAt,
    oldValue,
    newValue,
    type,
}: INotificationCard) => {
    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;
    const [showConfirmIcon, setShowConfirmIcon] = useState(false);
    const { shipmentName = "Shipment name" } = shipment;
    const { icon, message, preMessage } = STATUS_MAP[statusCode];

    const { notificationDate, notificationTime } = useMemo(() => {
        return {
            notificationDate: getFormattedDate(createdAt || ""),
            notificationTime: getTimeFromDateTime(createdAt || ""),
        };
    }, [createdAt]);

    const { memoizedNewValue, memoizedOldValue } = useMemo(() => {
        const memoizedOldValue = getFormattedDate(oldValue || "");
        const memoizedNewValue = getFormattedDate(newValue);
        return {
            memoizedOldValue: memoizedOldValue === "Invalid Date" ? "" : memoizedOldValue,
            memoizedNewValue: memoizedNewValue === "Invalid Date" ? "" : memoizedNewValue,
        };
    }, [newValue, oldValue]);

    const handleButtonClicked = () => {
        if (!showConfirmIcon) {
            setShowConfirmIcon(true);
        }
    };

    useEffect(() => {
        if (showConfirmIcon) {
            setTimeout(function () {
                onButtonClicked(id);
            }, 2000);
        }
    }, [showConfirmIcon]);

    const renderNotification = useMemo(() => {
        if (isMobile) {
            return (
                <Block className={`NotificationCardBase mobile ${className || ""}`}>
                    <div className="notification-card-header-container">
                        <div className="header_icon">{icon}</div>
                        <div className="header_title">{message}</div>
                    </div>
                    <div className="notification-card-content-container">
                        <div className="content_title-container">
                            <div className="title-container_title">{shipmentName || shipmentNumber}</div>
                            <div className="title-container_date">{`${notificationDate} | ${notificationTime}`}</div>
                        </div>
                        <div className="content_details-container">
                            <div className="details-container_previous-message">
                                {`${memoizedOldValue ? `Previous ${preMessage} ${memoizedOldValue}` : ""}`}
                            </div>
                            <div className="details-container_current-message">
                                {`${preMessage || STRINGS.nullMessage} ${memoizedNewValue}`}
                            </div>
                        </div>
                    </div>
                    <div className="notification-card-footer-container">
                        <div className="footer_button-container">
                            <TextButton onClick={handleButtonClicked} className="button-container_button">
                                <span className={`text fade${showConfirmIcon ? " hidden" : ""}`}>
                                    {`${STRINGS.confirmButton}`}
                                </span>
                                <span className={`icon fade${showConfirmIcon ? "" : " hidden"}`}>
                                    <ConfirmedIcon />
                                </span>
                            </TextButton>
                        </div>
                    </div>
                </Block>
            );
        } else {
            return (
                <Block className={`NotificationCardBase desktop ${className || ""}`}>
                    <div className="notifications-inner-container">
                        <div className="notification-card_status-image">
                            {/* Important note - please do not delete this, after consulting with Yuli, as of the time of writing these lines, it was decided that 'positive', 'default' will receive the color green, or 'negative' - red. */}
                            {type === "positive" || type === "default" ? (
                                <GreenLines className="green-lines" />
                            ) : (
                                <RedLineAndRectangle className="red-lines-and-rectangle" />
                            )}
                        </div>
                        <div className="notification-details-container">
                            <div className="notification-card_details-container">
                                <div className="top-container">
                                    <div className="notification-message">{message}</div>

                                    {/* date test */}
                                    <div className="date">{`${notificationDate} | ${notificationTime}`}</div>
                                </div>
                                <div className="bottom-container">
                                    <div className="shipment-name">{shipmentName || shipmentNumber}</div>
                                    <div className="details-inner-container">
                                        {oldValue && (
                                            <>
                                                <div className="previous-message">
                                                    {`${
                                                        memoizedOldValue
                                                            ? `Previous ${preMessage} ${memoizedOldValue}:`
                                                            : ""
                                                    }`}
                                                </div>
                                                <ArrowNotificationIcon className="notification-arrow-icon" />
                                            </>
                                        )}
                                        <div className="current-message">{`${preMessage} ${memoizedNewValue}`}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="notification-inner-container_divider"></div>
                        <div className="approval-button-container">
                            {showConfirmIcon ? (
                                <ConfirmedIcon />
                            ) : (
                                <IconButton onClick={handleButtonClicked}>
                                    <MarkOneAsRead />
                                </IconButton>
                            )}
                        </div>
                    </div>
                </Block>
            );
        }
    }, [isMobile, showConfirmIcon, shipment]);

    return renderNotification;
};

export default NotificationCardBase;
