import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../../../../../assets/data/ui";
import { theme } from "../../../../../../../assets/theme/theme";

const useStyles = makeStyles({
    root: {
        border: `1px solid ${COLORS.veryLight}`,
        borderRadius:"8px",
        margin: "1rem 1.6875rem 1.125rem",
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    docsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        margin: "1.5rem",
        overflow: "auto",
        border: `1px solid ${COLORS.veryLight}`,
        borderRadius:"8px",
        textTransform: "capitalize",
        maxWidth: "627px",
        borderLeft: `9px solid ${COLORS.primaryDark}`,
        [theme.breakpoints.down("lg")]: {
            width: "90%",
        },
        [theme.breakpoints.down("sm")]: {
            margin: "1rem",
        },
    },
    decorativeTopRight: {
        position: "absolute",
        top: "0",
        right: "0"
    },
    noDocumentsLeft: {
        position: "absolute",
        bottom: "0",
        left: "0"
    },
    noDocumentsRight: {
        position: "absolute",
        top: "0",
        right: "0"
    },
    actionButtonsContainer: {
        borderTop: `1px solid ${COLORS.veryLight}`,
        marginTop: "auto",
        padding: "1.25rem 1.5rem", 
        display: "flex",
        justifyContent: "space-between",
        color: COLORS.primaryDark,
        fontSize: "0.9375rem",
        zIndex: "1"
    },
    selectContainer: {
        display: "flex",
        cursor: "pointer",
        gap: "5px"
    },
    downloadSelectedContainer: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",
        transition: "opacity 0.5s ease, visibility 0.5s ease", 
        visibility: "hidden",
        opacity: 0,
    },
    greenCircle: {
        width: "8px",
        height: "8px",
        backgroundColor: COLORS.success,
        borderRadius: "50%",
        opacity: "1",
        transition: "opacity 0.3s ease-out",
        animation: "$appearAndFadeOut 2s ease-out forwards",
    },
    "@keyframes appearAndFadeOut": {
        "0%": {
          opacity: 1,
          visibility: "visible",
        },
        "50%": {
          opacity: 1,
        },
        "100%": {
          opacity: 0,
          visibility: "hidden",
        },
    },
    downloadSelectedContainerVisible: {
        opacity: 1, 
        visibility: "visible"
    },
    docContainer: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "25px",
        padding: "1rem",
        width: "100%",
        height: "initial",
        borderBottom: `1px solid ${COLORS.veryLight}`,
        backgroundColor: `${COLORS.white}`,
        zIndex: 1,
        borderRadius: "0px",
        cursor: "pointer",
        [theme.breakpoints.down("lg")]: {
            gap: "8px",
        },
        

        "&:last-child": {
            borderBottom: "none",
        },

        "&:hover": {
            backgroundColor: "#F5F6F7",
            
            "& $exportIcon": {
                display: "block",
                position: "absolute",
                right: "1rem"
            },
        },
    },
    docContent: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        flex: 1,
        fontSize: "0.875rem",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    docName: {
        display: "inline-block",
        color: COLORS.primaryDark,
        width: "50%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        [theme.breakpoints.down("sm")]: {
            width: "45%"
        },
        
    },
    exportIcon: {
        display:"none"
    },
    noDocContainer:{
        color: COLORS.primaryDark,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "16px",
        fontSize: "1rem",
        height: "100%"
    },
    noDocIcon: {

    }
});

export default useStyles;
