import {
    CargoArrangement,
    HeightUnit,
    ISecondPhaseFormInput,
    MeasureType,
    RfqStep,
    ShipmentMethod,
    VolumeUnit,
    WeightUnit,
} from "../../../models/rfq/rfq-types";
import PreviousStepsBanner from "../../../components/RFQ/PreviousStepsBanner/PreviousStepsBanner";
import { useAppSelector } from "../../../store";
import { PreviousStepsTitles } from "../../../models/rfq/rfq-types";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../../store";
import { updateSecondPhase } from "../../../store/reducers/rfqSlice.store";
import useStyles from "./useStyles";
import { ContainedButton } from "../../../components";
import { HeaderTexts, calcTotalShipment, oceanCargoType } from "./secondPhaseFormUtils";
import { airOrLandToggleButtonDetails, oceanToggleButtonDetails } from "./secondPhaseFormUtils";
import SecondPhaseOceanHeader from "../../../components/RFQ/SecondPhase/SecondPhaseOceanHeader";
import SecondPhaseAirOrLandHeader from "../../../components/RFQ/SecondPhase/SecondPhaseAirOrLandHeader";
import SecondPhaseFullContainer from "../../../components/RFQ/SecondPhase/SecondPhaseFullContainer";
import SecondPhaseDescribe from "../../../components/RFQ/SecondPhase/SecondPhaseDescribe";
import SecondPhaseInsure from "../../../components/RFQ/SecondPhase/SecondPhaseInsure";
import { useEffect, useMemo } from "react";
import { ShipmentMethodType } from "../RfqPageUtils";
import SecondPhaseTotalShipment from "../../../components/RFQ/SecondPhase/SecondPhaseTotalShipment";
import SecondPhasePerUnit from "../../../components/RFQ/SecondPhase/SecondPhasePerUnit";
import SecondPhaseCalcShipmentPerUnit from "../../../components/RFQ/SecondPhase/SecondPhaseCalcShipmentPerUnit";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";

const SecondPhaseForm: React.FC<{
    updateFormState: React.Dispatch<React.SetStateAction<RfqStep>>;
}> = ({ updateFormState }) => {
    const { firstPhaseForm, secondPhaseForm, shipmentMethod } = useAppSelector((state) => state.rootReducer.rfq);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const methods = useForm<ISecondPhaseFormInput>({
        defaultValues: {
            ...secondPhaseForm,
            cargoType:
                secondPhaseForm.cargoType === ""
                    ? airOrLandToggleButtonDetails.firstButton.label
                    : secondPhaseForm.cargoType,
            fullVsPart:
                shipmentMethod.includes(ShipmentMethod.ByOcean) && secondPhaseForm.fullVsPart === ""
                    ? oceanToggleButtonDetails.firstButton.label
                    : secondPhaseForm.fullVsPart,
            totalValue: secondPhaseForm.totalValue ?? undefined,
        },
    });
    const {
        handleSubmit,
        register,
        unregister,
        control,
        setValue,
        watch,
        formState: { isValid },
    } = methods;
    const measureType = watch("measureType");
    const perUnitWeight = watch("weight");
    const boxPerUnitHeight = watch("boxPerUnitHeight");
    const boxPerUnitLength = watch("boxPerUnitLength");
    const boxPerUnitWidth = watch("boxPerUnitWidth");
    const perUnitQuantity = watch("quantity");
    const watchShipment = watch("isTotalShipment");
    const watchCargoType = watch("cargoType");
    const watchInsurance = watch("goodsInsurance");
    const watchFullVsPart = watch("fullVsPart");
    useEffect(() => {
        if (measureType === MeasureType.Metric) {
            setValue("weightUnit", WeightUnit.KG);
            setValue("heightUnit", HeightUnit.CM);
            setValue("volumeUnit", VolumeUnit.CBM);
        } else {
            setValue("weightUnit", WeightUnit.LB);
            setValue("heightUnit", HeightUnit.IN);
            setValue("volumeUnit", VolumeUnit.CFT);
        }
    }, [measureType]);

    const onSubmit = (data: ISecondPhaseFormInput) => {
        tryTrackEvent("[Rfq - Step 2]: 'Next' button clicked");

        updateFormState(RfqStep.ThirdStep);
        dispatch(updateSecondPhase({ ...data, totalShipmentCalculation }));
    };

    const totalShipmentCalculation: string = useMemo(
        () =>
            calcTotalShipment(
                measureType || 0,
                perUnitQuantity || "",
                perUnitWeight || "",
                boxPerUnitHeight || "",
                boxPerUnitLength || "",
                boxPerUnitWidth || "",
            ),
        [boxPerUnitHeight, boxPerUnitLength, boxPerUnitWidth, perUnitQuantity, perUnitWeight, measureType],
    );
    const methodsTypes = shipmentMethod.map((method: number) => ShipmentMethodType[method]);
    return (
        <Box className={classes.formWrapper}>
            <PreviousStepsBanner
                currentPhaseNumber={RfqStep.SecondStep}
                stepNumber={RfqStep.FirstStep}
                setFormPhase={updateFormState}
                stepTitle={PreviousStepsTitles.FirstPhase}
                details={{
                    type: methodsTypes,
                    from: firstPhaseForm.fromBannerData?.label as string,
                    to: firstPhaseForm.destinationBannerData?.label as string,
                    fullFromAddress: firstPhaseForm.fromAddress,
                    fullToAddress: firstPhaseForm.destinationAddress,
                }}
            />
            <Box className={classes.mainContainer}>
                <Box className={classes.headerWrapper}>
                    <span className={classes.header}>{HeaderTexts.mainHeader}</span>
                </Box>

                <Box className={classes.inputsWrapper}>
                    {shipmentMethod.includes(ShipmentMethod.ByOcean) ? (
                        <SecondPhaseOceanHeader control={control} watchFullVsPart={watchFullVsPart} />
                    ) : (
                        <SecondPhaseAirOrLandHeader control={control} setFormValue={setValue} />
                    )}

                    {watchFullVsPart === oceanCargoType.FULL_CONTAINER ? (
                        <SecondPhaseFullContainer control={control} setFormValue={setValue} />
                    ) : (
                        <>
                            {watchCargoType === "Pallets" &&
                                (watchShipment === CargoArrangement.TotalShipment ? (
                                    <SecondPhaseTotalShipment control={control} setFormValue={setValue} />
                                ) : (
                                    <SecondPhasePerUnit control={control} setFormValue={setValue} />
                                ))}
                            {watchCargoType === "Cartons" &&
                                (watchShipment === CargoArrangement.TotalShipment ? (
                                    <SecondPhaseTotalShipment control={control} setFormValue={setValue} />
                                ) : (
                                    <SecondPhasePerUnit control={control} setFormValue={setValue} />
                                ))}
                            {watchShipment === CargoArrangement.ShipmentPerUnit && (
                                <SecondPhaseCalcShipmentPerUnit totalShipmentCalculation={totalShipmentCalculation} />
                            )}
                        </>
                    )}
                    <SecondPhaseDescribe control={control} watchInsurance={watchInsurance} />
                    <SecondPhaseInsure
                        control={control}
                        setFormValue={(name, value: any) => {
                            value.length == 0 ? unregister("totalValue") : register("totalValue");
                            setValue(name, value);
                        }}
                        initialValue={secondPhaseForm.totalValue ? "goodsInsurance" : undefined}
                    />
                </Box>
                <ContainedButton
                    disabled={!isValid}
                    className={`${classes.button} ${classes.noBoxShadow}`}
                    onClick={handleSubmit(onSubmit)}
                >
                    Next
                </ContainedButton>
            </Box>
        </Box>
    );
};

export default SecondPhaseForm;
