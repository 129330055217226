import { Fragment, useState } from "react";
import {
    IInvoice,
    ITableColumn,
    InvoiceSpecialServicesCodePrefix,
    InvoiceSpecialServicesCodePrefixType,
    InvoiceSubType,
    InvoiceSubTypeTransportMethodMap,
    ValidInvoiceColumnNames,
    ValidInvoiceStatus,
} from "../../models/invoices/invoices.interfaces";
import { TableCell, TableRow, IconButton, Collapse } from "@mui/material";
import dayjs from "dayjs";
import {
    BoatIconV2,
    AirplaneIconV2,
    ArrowRightIcon as ArrowIcon,
    TruckIconV2,
    WarehouseIconV2,
    StampIconV2,
} from "../../assets/icons";
import { formatNumberWithDecimal } from "../../helpers/services/DateFormatsService.service";
import UnicargoCheckbox from "../UnicargoCheckbox/UnicargoCheckbox";
import { roundNumber } from "../cards/shipment-cards/shared/services/shipment-cards.service";
import InvoicesInnerTable from "../InvoicesInnerTable/InvoicesInnerTable";
import UnicargoTooltip from "../UnicargoTooltip/UnicargoTooltip";
import { tryTrackEvent } from "../../helpers/services/MixPanelService";

const getRelevantReference = (references: readonly (string | undefined)[]): string => {
    const relevantReferences = references.filter((reference) => reference);
    return relevantReferences[0] ?? "-";
};

const renderInvoiceTypeIcon = (invoice: IInvoice) => {
    const invoiceSpecialServiceCodePrefix: InvoiceSpecialServicesCodePrefixType =
        invoice.shipment.specialServicesCode?.slice(0, 3) as InvoiceSpecialServicesCodePrefixType;
    const invoiceSubType: InvoiceSubType = invoice.shipment.shipmentLoad?.toUpperCase() as InvoiceSubType;
    switch (invoiceSpecialServiceCodePrefix) {
        case InvoiceSpecialServicesCodePrefix.Warehouse:
            return <WarehouseIconV2 className="InvoiceTypeIcon" />;
        case InvoiceSpecialServicesCodePrefix.CBA:
        case InvoiceSpecialServicesCodePrefix.CBO:
        case InvoiceSpecialServicesCodePrefix.CUS:
            return <StampIconV2 className="InvoiceTypeIcon" />;
        case InvoiceSpecialServicesCodePrefix.Unicargo:
        case InvoiceSpecialServicesCodePrefix.IMA:
        case InvoiceSpecialServicesCodePrefix.IMO:
        default: {
            if (InvoiceSubTypeTransportMethodMap.Ocean.includes(invoiceSubType))
                return <BoatIconV2 className="InvoiceTypeIcon" />;
            if (InvoiceSubTypeTransportMethodMap.Air.includes(invoiceSubType))
                return <AirplaneIconV2 className="InvoiceTypeIcon" />;
            if (InvoiceSubTypeTransportMethodMap.Inland.includes(invoiceSubType))
                return <TruckIconV2 className="InvoiceTypeIcon" />;
            return <BoatIconV2 className="InvoiceTypeIcon" />;
        }
    }
};

const renderInvoicesTableCell = (
    invoice: IInvoice,
    column: ITableColumn,
    value: string | number | Date,
    handleFileNumberClicked: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, invoice: IInvoice) => void,
) => {
    let formattedValue = value ?? "-";
    let isOverdue = false;

    if (typeof value === "number") {
        formattedValue = formatNumberWithDecimal(roundNumber(formattedValue as number, 1), 1) as string;
        if (formattedValue.endsWith(".0")) {
            formattedValue = formattedValue.slice(0, -2);
        }
    }

    switch (column.keyNameToMatch) {
        case ValidInvoiceColumnNames.INVOICE_NUMBER:
            return (
                <TableCell className="InvoiceNumberCell" key={column.label} align="left">
                    <div className="InvoiceNumberDetailsContainer">
                        {renderInvoiceTypeIcon(invoice)}
                        <div className="InvoiceNumber">{formattedValue}</div>
                    </div>
                </TableCell>
            );
        case ValidInvoiceColumnNames.FILE_NUMBER: {
            const isShipmentFileNumber = invoice.fileNumber.startsWith("UNC");
            return (
                <TableCell
                    className={`FileNumberCell ${isShipmentFileNumber && "UnicargoFileNumber"}`}
                    key={column.label}
                    align="left"
                >
                    <span
                        className="FileNumberSpan"
                        onClick={(e) => {
                            if (!isShipmentFileNumber) return;
                            handleFileNumberClicked(e, invoice);
                        }}
                    >
                        {formattedValue}
                    </span>
                </TableCell>
            );
        }
        case ValidInvoiceColumnNames.REFERENCES: {
            const referenceToDisplay = formattedValue as string;
            const shouldCellHaveTooltip = referenceToDisplay.length > 10;
            const referencesCellContent = (
                <div className="ReferencesContainer">
                    <div className="ReferenceValue">{formattedValue}</div>
                </div>
            );
            return (
                <TableCell key={column.label} align="left" className="ReferencesCell">
                    {shouldCellHaveTooltip ? (
                        <UnicargoTooltip
                            popperChildComponent={<span>{referenceToDisplay}</span>}
                            child={referencesCellContent}
                            childClassName="childClassName"
                            popperClassName="popperClassName"
                        />
                    ) : (
                        referencesCellContent
                    )}
                </TableCell>
            );
        }
        case ValidInvoiceColumnNames.TOTAL_AMOUNT:
            return (
                <TableCell key={column.label} align="left" className="TotalAmountCell">
                    {`${formattedValue} ${invoice.currencyAcronym}`}
                </TableCell>
            );
        case ValidInvoiceColumnNames.AMOUNT_DUE:
            return (
                <TableCell key={column.label} align="left" className="AmountDueCell Bold">
                    {formattedValue != 0 && `${formattedValue} ${invoice.currencyAcronym}`}
                </TableCell>
            );
        case ValidInvoiceColumnNames.INVOICE_DATE:
            if (typeof value === "string") {
                formattedValue = dayjs(value).format("MMM DD, YYYY");
                isOverdue = dayjs(value).isBefore(dayjs());
            }
            return (
                <TableCell key={column.label} align="left">
                    {formattedValue}
                </TableCell>
            );
        case ValidInvoiceColumnNames.DUE_DATE: {
            if (typeof value === "string") {
                formattedValue = dayjs(value).format("MMM DD, YYYY");
                isOverdue = dayjs(value).isBefore(dayjs());
            }
            const dueDateCellClassName = `DueDateCell ${
                [ValidInvoiceStatus.UNPAID, ValidInvoiceStatus.PARTIALLY_PAID].includes(invoice.paymentStatus) &&
                isOverdue &&
                "Overdue"
            }`;
            return (
                <TableCell key={column.label} align="left" className={dueDateCellClassName}>
                    {formattedValue}
                </TableCell>
            );
        }
        case ValidInvoiceColumnNames.PAYMENT_STATUS:
            // TODO: This conditional should probably be done in the BE and sent back to the FE.
            if (invoice.invoiceNumber.startsWith("CR") && invoice.paymentStatus !== "Processing") {
                formattedValue = "Credit";
            }
            return (
                <TableCell key={column.label} align="left" className="PaymentStatusLabel">
                    <div className={`PaymentStatus ${invoice.paymentStatus.replaceAll(" ", "")}`}>{formattedValue}</div>
                </TableCell>
            );
        default:
            return (
                <TableCell key={column.label} align="left">
                    {formattedValue}
                </TableCell>
            );
    }
    return (
        <TableCell key={column.label} align="left">
            {"-"}
        </TableCell>
    );
};

export default function InvoiceRow({
    invoice,
    customerCode,
    columns,
    isRowSelected,
    onRowSelectedChange,
    selectedInvoices,
    handleFileNumberClicked,
    isInvoiceOpen,
}: {
    invoice: IInvoice;
    customerCode: string;
    columns: ITableColumn[];
    isRowSelected: boolean;
    onRowSelectedChange: (isSelected: boolean) => void;
    selectedInvoices: IInvoice[];
    handleFileNumberClicked: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, invoice: IInvoice) => void;
    isInvoiceOpen: boolean;
}) {
    const [isInnerTableOpen, setInnerTableOpen] = useState(isInvoiceOpen);

    const isInvoiceSelectable =
        !selectedInvoices[0] || selectedInvoices[0]?.currencyAcronym === invoice.currencyAcronym;

    const selectCheckbox = () => (
        <span className="IsSelectedInvoiceCheckboxContainer">
            <UnicargoCheckbox
                className="IsSelectedInvoiceCheckbox"
                isChecked={isRowSelected}
                isDisabled={!isInvoiceSelectable}
                onClick={() => {
                    onRowSelectedChange(!isRowSelected);
                }}
            />
        </span>
    );

    const handleInvoiceRowClicked = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        if (e.target instanceof HTMLTableCellElement && e.target.className.includes("ExpandRowButton")) return;
        if (!isInnerTableOpen) {
            tryTrackEvent("[Invoices Table] Invoice inner table opened", {
                elementType: "Invoice",
                invoiceStatus: invoice.paymentStatus,
                isOverdue: dayjs(invoice.dueDate).isBefore(dayjs()).toString(),
            });
        }
        setInnerTableOpen(!isInnerTableOpen);
    };

    return (
        <Fragment>
            <TableRow
                className="InvoicesTableRow"
                sx={{ "& > *": { cursor: "pointer" } }}
                onClick={handleInvoiceRowClicked}
            >
                <TableCell className="OptionsCell" align="left">
                    <div className="OptionsContainer">
                        <IconButton
                            className="ExpandRowButton
                  "
                            aria-label="expand row"
                            size="small"
                            onClick={() => setInnerTableOpen(!isInnerTableOpen)}
                        >
                            <ArrowIcon className={`ArrowIcon ${isInnerTableOpen ? "Opened" : "Closed"}`} />
                        </IconButton>
                        {[ValidInvoiceStatus.UNPAID, ValidInvoiceStatus.PARTIALLY_PAID].includes(
                            invoice.paymentStatus,
                        ) &&
                            (isInvoiceSelectable ? (
                                selectCheckbox()
                            ) : (
                                <UnicargoTooltip
                                    popperChildComponent={
                                        <span>You can only select invoices of the same currency</span>
                                    }
                                    popperPlacement="right"
                                    child={selectCheckbox()}
                                />
                            ))}
                    </div>
                </TableCell>
                {columns.map((column) => {
                    let value = invoice[column.keyNameToMatch] as string | number;
                    if (column.keyNameToMatch === "references") {
                        const referencesValues = [
                            invoice.shipment.customerReferenceID,
                            invoice.shipment.purchaseOrderNumber,
                            invoice.shipment.amazonShipmentID,
                            invoice.shipment.amazonReferenceID,
                        ] as const;

                        value = getRelevantReference(referencesValues);
                    }
                    // if the value is a string or a number or an array of strings, render the cell
                    if (typeof value === "string" || typeof value === "number") {
                        return renderInvoicesTableCell(invoice, column, value, handleFileNumberClicked);
                    }

                    return (
                        <TableCell key={column.label} align="left">
                            {"-"}
                        </TableCell>
                    );
                })}
            </TableRow>
            <TableRow className="CollapsibleRow">
                <TableCell
                    className="InnerTableRowCell"
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={columns.length + 1}
                >
                    <Collapse in={isInnerTableOpen} timeout="auto" unmountOnExit>
                        <InvoicesInnerTable invoice={invoice} customerCode={customerCode} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}
