import { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "./UnicargoSelectBtn.module.scss";
import { ArrowDownIconStiff as ArrowIcon } from "../../assets/icons";
import { IconButton } from "@mui/material";
import { LineSeparator } from "../ui-decorations/LineSeparator/LineSeparator";
import { useOutsideClick } from "../../helpers/custom-hooks/useOutsideClick";
import UnicargoCheckbox from "../UnicargoCheckbox/UnicargoCheckbox";
import { FilterTypes, IFilter, IFilterOption } from "../../models/filters/filters.interfaces";
import {
    getAllOptionsWithUpdatedOption,
    selectDeselectAll,
    getSelectedOptionsFromAllOptions,
} from "../../helpers/services/FiltersService.service";

interface UnicargoSelectBtnProps {
    filter: IFilter;
    onChange: (newFilter: IFilter) => void;
    includeMenuTopSection?: boolean;
}

const UnicargoSelectBtn: FunctionComponent<UnicargoSelectBtnProps> = ({
    filter,
    onChange,
    includeMenuTopSection = false,
}: UnicargoSelectBtnProps) => {
    const [allOptions, setAllOptions] = useState<IFilterOption[]>(filter.options);
    const [selectedOptions, setSelectedOptions] = useState<IFilterOption[]>(
        getSelectedOptionsFromAllOptions(filter.options),
    );
    const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
    const [tagValue, setTagValue] = useState<string>("");
    const UnicargoSelectBtnRef = useRef<HTMLDivElement>(null);

    const topSectionOption: IFilterOption = {
        value: "All",
        label: "All",
        isSelected: selectedOptions.length === allOptions.length,
    };

    useEffect(() => {
        setAllOptions(filter.options);
    }, [filter]);

    useEffect(() => {
        setSelectedOptions(getSelectedOptionsFromAllOptions(allOptions));
        onChange({
            ...filter,
            options: allOptions,
        });
    }, [allOptions]);

    useEffect(() => {
        switch (selectedOptions.length) {
            case 0:
                setTagValue("");
                break;
            case 1:
                setTagValue(selectedOptions[0].label);
                break;
            default:
                setTagValue(`${selectedOptions.length}`);
                break;
        }
    }, [selectedOptions]);

    const renderTagView = () => {
        return (
            <div
                className={`${styles.ValueTag} ${tagValue && styles.Selected} ${
                    selectedOptions.length > 1 && styles.MultipleSelected
                }`}
            >
                {tagValue}
            </div>
        );
    };

    useOutsideClick(UnicargoSelectBtnRef, () => {
        setIsOptionsMenuOpen(false);
    });

    const handleSelectClick = () => {
        setIsOptionsMenuOpen(!isOptionsMenuOpen);
    };

    const handleOptionClick = (clickedOption: IFilterOption) => {
        const updatedOption = { ...clickedOption, isSelected: !clickedOption.isSelected };
        setAllOptions(
            getAllOptionsWithUpdatedOption(
                allOptions,
                updatedOption,
                filter.filterType === FilterTypes.SINGLE_CHOICE_SELECT,
            ),
        );
    };

    const handleSelectAllClick = () => {
        const areAllOptionsSelected = selectedOptions.length === allOptions.length;
        const shouldSelectAll = !areAllOptionsSelected;
        if (shouldSelectAll) {
            setAllOptions(selectDeselectAll(allOptions));
            return;
        }
        setAllOptions(selectDeselectAll(allOptions, true));
    };

    const renderOptionsMenu = () => {
        if (filter.filterType === FilterTypes.SINGLE_CHOICE_SELECT) {
            return (
                <div className={`${styles.OptionsMenu} ${isOptionsMenuOpen && styles.Opened}`}>
                    {allOptions.map((option, index) => (
                        <div
                            className={`${styles.OptionContainer} ${styles.SingleChoiceSelect}`}
                            key={`${option.label}-${index}`}
                            onClick={() => handleOptionClick(option)}
                        >
                            <div className={styles.OptionLabel}>{option.label}</div>
                        </div>
                    ))}
                </div>
            );
        }
        return (
            <div className={`${styles.OptionsMenu} ${isOptionsMenuOpen && styles.Opened}`}>
                {includeMenuTopSection && (
                    <>
                        {
                            <div className={styles.OptionContainer} key={`${topSectionOption.label}`}>
                                <UnicargoCheckbox
                                    isChecked={selectedOptions.length === allOptions.length}
                                    onClick={() => handleSelectAllClick()}
                                />
                                <div className={styles.Option}>{topSectionOption.label}</div>
                            </div>
                        }
                        <LineSeparator className={styles.LineSeparator} width="100%" height="1px" />
                    </>
                )}
                {allOptions.map((option, index) => (
                    <div className={styles.OptionContainer} key={`${option.label}-${index}`}>
                        {filter.filterType === FilterTypes.MULTI_CHOICE_SELECT && (
                            <UnicargoCheckbox
                                isChecked={
                                    selectedOptions.findIndex(
                                        (selectedOption) => selectedOption.label === option.label,
                                    ) !== -1
                                }
                                onClick={() => handleOptionClick(option)}
                            />
                        )}
                        <div className={styles.OptionLabel}>{option.label}</div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={styles.UnicargoSelectBtn} ref={UnicargoSelectBtnRef}>
            <div className={styles.SelectContainer} onClick={handleSelectClick}>
                <div className={styles.Label}>{filter.label}</div>
                <IconButton
                    className={styles.ArrowIconContainer}
                    aria-label="expand row"
                    size="small"
                    onClick={handleSelectClick}
                >
                    {
                        <ArrowIcon
                            className={`${styles.ArrowIcon} ${isOptionsMenuOpen ? styles.Opened : styles.Closed}`}
                        />
                    }
                </IconButton>
                {renderTagView()}
            </div>
            {renderOptionsMenu()}
        </div>
    );
};

export default UnicargoSelectBtn;
