import { makeStyles } from "@mui/styles";
import { theme } from "../../../assets/theme/theme";
import { COLORS } from "../../../assets/data/ui";

const useStyles = makeStyles({
    formWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        flexDirection: "column",
        padding: "32px",
        width: "100%",
    },
    button: {
        width: "80px",
        margin: "0px auto !important",
    },
    noBoxShadow: {
        boxShadow: "none !important",
    },
    headerWrapper: {
        margin: "0px auto",
        textAlign: "center",
    },

    header: {
        // padding: "10px",
        color: COLORS.main,
        fontWeight: "700",
        fontSize: "24px",
    },
    inputsWrapper: {
        margin: "0px auto",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        minWidth: "404px",
        maxWidth: "404px",
        [theme.breakpoints.down("sm")]: {
            minWidth: "100%",
            maxWidth: "100%",
        },
    },
    mainContainer: {
        margin: "0 auto 0",
        maxWidth: "444px",
        flexDirection: "column",
        display: "flex",
        gap: "24px",
        [theme.breakpoints.down("sm")]: {
            minWidth: "100%",
            maxWidth: "100%",
        },
    },
});

export default useStyles;
