import {
    IFinalPhaseFormInput,
    IFinaltModalPhaseFormInput,
    IFirstPhaseFormInput,
    ISecondPhaseFormInput,
    IThirdPhaseFormInput,
    ShipmentMethod,
} from "../../models/rfq/rfq-types";
import { unicargoApiSlice } from "../apiSlice";

interface SendSubmitRfq {
    firstPhaseForm: IFirstPhaseFormInput;
    secondPhaseForm: ISecondPhaseFormInput;
    thirdPhaseForm: IThirdPhaseFormInput;
    finalPhaseForm?: IFinalPhaseFormInput;
    shipmentMethod: ShipmentMethod[];
    extraDetails?: ExtraDetails;
}
export interface ExtraDetails {
    finalModalPhaseForm?: IFinaltModalPhaseFormInput;
    email?: string;
    fullName?: string;
    companyName?: string;
}
export const rfqApiSlice = unicargoApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        sendSubmitRfq: builder.mutation<any, SendSubmitRfq>({
            query: (body) => ({
                url: "emails/rfq",
                method: "POST",
                body,
            }),
        }),
    }),
    overrideExisting: false,
});
export const { useSendSubmitRfqMutation } = rfqApiSlice;
