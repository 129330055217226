import { Button } from "@mui/material";
import { DetailedHTMLProps, FunctionComponent, ImgHTMLAttributes, SVGAttributes, useEffect, useState } from "react";
import "./GenericButton.scss";

export enum SvgPosition {
    Left,
    Right,
}

export enum ButtonTypes {
    RegularBtn = "RegularBtn",
    ToggleBtn = "ToggleBtn",
}

export enum ButtonTypeStyles {
    FilledStyle = "FilledStyle",
    HollowStyle = "HollowStyle",
    LightGreenStyle = "LightGreenStyle",
}

interface GenericButtonProps {
    text: string;
    btnType?: ButtonTypes;
    initialBtnTypeStyle?: ButtonTypeStyles;
    className?: string;
    width?: string;
    height?: string;
    leftImageElementToDisplay?: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
    rightImageElementToDisplay?: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
    leftSvgToDisplay?: FunctionComponent<SVGAttributes<SVGSVGElement>>;
    rightSvgToDisplay?: FunctionComponent<SVGAttributes<SVGSVGElement>>;
    isSelected?: boolean;
    onClick: () => void;
    isDisabled?: boolean;
}

const GenericButton: FunctionComponent<GenericButtonProps> = ({
    text,
    btnType = ButtonTypes.RegularBtn,
    initialBtnTypeStyle = ButtonTypeStyles.LightGreenStyle,
    className = "",
    onClick,
    leftImageElementToDisplay,
    rightImageElementToDisplay,
    leftSvgToDisplay,
    rightSvgToDisplay,
    width,
    height,
    isSelected = false,
    isDisabled = false,
}) => {
    const LeftSvgComponent = leftSvgToDisplay;
    const RightSvgComponent = rightSvgToDisplay;
    const [btnTypeStyle, setBtnTypeStyle] = useState(
        btnType === ButtonTypes.RegularBtn
            ? initialBtnTypeStyle
            : btnType === ButtonTypes.ToggleBtn
            ? isSelected
                ? ButtonTypeStyles.FilledStyle
                : ButtonTypeStyles.HollowStyle
            : ButtonTypeStyles.LightGreenStyle,
    );

    useEffect(() => {
        if (btnType === ButtonTypes.ToggleBtn) {
            setBtnTypeStyle(isSelected ? ButtonTypeStyles.FilledStyle : ButtonTypeStyles.HollowStyle);
        }
    }, [isSelected]);

    const handleBtnClick = () => {
        if (isDisabled) return;
        onClick();
    };

    return (
        <Button
            className={`GenericButton ${btnType} ${btnTypeStyle} ${className}`}
            sx={{ width, height }}
            onClick={handleBtnClick}
            disabled={isDisabled}
        >
            {leftImageElementToDisplay}
            {LeftSvgComponent && <LeftSvgComponent />}
            {text}
            {RightSvgComponent && <RightSvgComponent />}
            {rightImageElementToDisplay}
        </Button>
    );
};

export default GenericButton;
