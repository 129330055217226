import Block from "../../../block/Block";
import ShipmentsDetailsBlockHeaderLoader from "../../ShipmentsDetailsBlockHeaderLoader";
import { ShipmentDetailsTabs } from "./ShipmentDetailsTabs";
import { IShipment } from "../../../../../models";
import styles from "./ShipmentDetailsBlockSkeleton.module.scss";

const ShipmentDetailsBlockSkeleton = () => {
    return (
        <Block className={styles.root}>
            <ShipmentsDetailsBlockHeaderLoader />
            <ShipmentDetailsTabs shipment={{} as IShipment} shouldShowLoader />
        </Block>
    );
};

export default ShipmentDetailsBlockSkeleton;
