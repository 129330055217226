import { IconButton, TableCell, TableRow } from "@mui/material";
import { ArrowRightIcon as ArrowIcon } from "../../assets/icons";
import { ITableColumn, ValidInvoiceColumnNames } from "../../models/invoices/invoices.interfaces";
import SkeletonLoaderItem from "../ui-decorations/SkeletonLoader/SkeletonLoaderItem";

const InvoiceSkeletonRowItem = ({ column }: { column: ITableColumn }) => {
    const maxWidth = "128px";
    switch (column.keyNameToMatch) {
        case ValidInvoiceColumnNames.INVOICE_NUMBER:
            return (
                <TableCell className={`InvoiceSkeletonRowCell ${column.label}`}>
                    <div className="InvoiceSkeletonRowItemContainer">
                        <SkeletonLoaderItem predefinedStyle="Circle" />
                        <SkeletonLoaderItem customStyle={{ width: "unset", minWidth: 60, flexGrow: 1 }} />
                    </div>
                </TableCell>
            );
        case ValidInvoiceColumnNames.PAYMENT_STATUS:
            return (
                <TableCell className={`InvoiceSkeletonRowCell ${column.label}`}>
                    <div className="InvoiceSkeletonRowItemContainer">
                        <SkeletonLoaderItem customStyle={{ height: "1.5rem", maxWidth: "80px" }} />
                    </div>
                </TableCell>
            );
        default:
            return (
                <TableCell className={`InvoiceSkeletonRowCell ${column.label}`}>
                    <div className="InvoiceSkeletonRowItemContainer">
                        <SkeletonLoaderItem customStyle={{ maxWidth }} />
                    </div>
                </TableCell>
            );
    }
};

export const InvoiceSkeletonRow = ({ columns }: { columns: ITableColumn[] }) => {
    return (
        <TableRow className="InvoiceSkeletonRow">
            <TableCell className="OptionsCell" align="left">
                <div className="OptionsContainer">
                    <IconButton
                        className="ExpandRowButton
                  "
                        aria-label="expand row"
                        size="small"
                    >
                        <ArrowIcon className={"ArrowIcon Closed"} />
                    </IconButton>
                </div>
            </TableCell>
            {columns.map((column) => {
                return <InvoiceSkeletonRowItem key={column.label} column={column} />;
            })}
        </TableRow>
    );
};
