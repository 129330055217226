import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon, UnicargoLogoIcon, ArrowRightRoundedWhiteBg, UnicargoLogoNoText } from "../../../assets/icons";
import { HeaderPropsType } from "../../../models/rfq/rfq-types";
import { RfqStep } from "../../../models/rfq/rfq-types";
import { useAppDispatch } from "../../../store";
import { resetRfqForms } from "../../../store/reducers/rfqSlice.store";
import { RouteToPreviousPage } from "../../../routes";
import { ScreenWidthType } from "../../../assets/data/ui";
import { useScreenWidthType } from "../../../helpers/custom-hooks/useScreenWidthType";
import PillContainer from "../../PillContainer/PillContainer";
import RfqStepper from "../Stepper/stepper";
import "./styles.scss";

const STEPS = {
    firstStep: "Set route",
    secondStep: "Describe cargo",
    thirdStep: "Schedule shipment",
};

const NUM_RFQ_STEPS = 3;

const RfqHeader: React.FC<HeaderPropsType> = ({ currentStep, updateFormState }) => {
    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleBackToOverviewPage = () => {
        dispatch(resetRfqForms());
        updateFormState(RfqStep.FirstStep);
        navigate(RouteToPreviousPage);
    };
    return (
        <>
            {isMobile ? (
                <div className="mobile-header-container">
                    <div className="layout">
                        <div className="back-cta">
                            <ArrowLeftIcon
                                fill="white"
                                onClick={() => handleBackToOverviewPage()}
                                className="arrow-icon-mobile"
                            />
                            <UnicargoLogoNoText className="unicargo-logo-mobile" />
                        </div>
                        <div className="mobile-text-header-container">
                            {currentStep === RfqStep.FirstStep && (
                                <span className="selected-step-mobile">{STEPS.firstStep}</span>
                            )}
                            {currentStep === RfqStep.SecondStep && (
                                <span className="selected-step-mobile">{STEPS.secondStep}</span>
                            )}
                            {currentStep === RfqStep.ThirdStep && (
                                <span className="selected-step-mobile">{STEPS.thirdStep}</span>
                            )}
                        </div>
                    </div>
                    <RfqStepper currentStep={currentStep} numberOfSteps={NUM_RFQ_STEPS} />
                </div>
            ) : (
                <div className="header">
                    <PillContainer className="pill-container">
                        <div className="logo-container">
                            <ArrowLeftIcon
                                fill="white"
                                height={"14px"}
                                width={"14px"}
                                onClick={() => handleBackToOverviewPage()}
                                className="arrow-icon"
                            />
                            <UnicargoLogoIcon className="unicargo-logo" />
                        </div>
                        <div className="steps-container">
                            <span className={`${currentStep === RfqStep.FirstStep ? "selected-step" : "step"}`}>
                                {STEPS.firstStep}
                            </span>
                            {currentStep === RfqStep.FirstStep ? (
                                <ArrowRightRoundedWhiteBg />
                            ) : (
                                <ArrowRightRoundedWhiteBg style={{ opacity: 0.5 }} />
                            )}
                            <span className={`${currentStep === RfqStep.SecondStep ? "selected-step" : "step"}`}>
                                {STEPS.secondStep}
                            </span>
                            {currentStep === RfqStep.SecondStep ? (
                                <ArrowRightRoundedWhiteBg />
                            ) : (
                                <ArrowRightRoundedWhiteBg style={{ opacity: 0.5 }} />
                            )}
                            <span className={`${currentStep === RfqStep.ThirdStep ? "selected-step" : "step"}`}>
                                {STEPS.thirdStep}
                            </span>
                        </div>
                    </PillContainer>
                </div>
            )}
        </>
    );
};

export default RfqHeader;
