import { PaletteColorOptions, PaletteOptions } from "@mui/material";
import { CSSProperties } from "react";
import { backgroundColors } from "./background";
import { textColors } from "./text";
import { COLORS } from "../../data/ui";

declare module "@mui/material/styles" {
    // PaletteColors
    interface PaletteColor {
        colors: {
            white: CSSProperties["color"];
            light: CSSProperties["color"];
            medium: CSSProperties["color"];
            main: CSSProperties["color"];
        };
    }
    interface PaletteColorOptions {
        white?: string;
        light?: string;
        medium?: string;
        main?: string;
    }
}

// Error - Orange
const errorColors = {
    main: COLORS.error,
};

// Success - Green
const successColors = {
    main: COLORS.success,
};

const primary: PaletteColorOptions = {
    white: COLORS.white,
    light: COLORS.light,
    medium: COLORS.medium,
    main: COLORS.main,
};

export const palette: PaletteOptions = {
    error: errorColors,
    success: successColors,
    backgroundColors,
    textColors,
    primary,
};
