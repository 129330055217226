import { makeStyles } from "@mui/styles";
import { COLORS, TYPOGRAPHY, UiSchemeV2 } from "../../../../assets/data/ui";
import { theme } from "../../../../assets/theme/theme";

const useStyles = makeStyles({
    root: {
        display: "flex",
        height: "initial",
        border: `1px solid ${COLORS.general.dividers}`,
        borderRadius: "8px",
        "&:hover": {
            cursor: "pointer",
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
        },
    },
    contentContainer: {
        display: "flex",
        width: "100%",
        borderLeft: "9.8px solid",
        borderRadius: "8px",
        // * borderLeftColor assigned at component
        "&.selected": {
            borderLeftWidth: "8px",
        },
    },
    innerContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        columnGap: "1rem",
        width: "100%",
        padding: "0.5rem",
        borderRadius: "0 8px 8px 0",
        alignItems: "center",

        "&.selected": {
            marginLeft: "1.2px",
            backgroundColor: UiSchemeV2.colors.primaryDark,
            color: UiSchemeV2.colors.greys.white,
        },

        [theme.breakpoints.up("xl")]: {
            gap: "80px",
        },
    },
    // Shipment Details Container (left side)
    shipmentDetailsContainer: {
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: "0.5rem",
        padding: "0 0.5rem",
        height: "100%",
        maxWidth: "100%",
        display: "flex",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    shipmentNameSection: {
        display: "flex",
        gap: "5px",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: TYPOGRAPHY.title,

        "& svg": {
            height: "17px",
            width: "17px",
            "& path": {
                stroke: "currentcolor",
                fill: "currentcolor",
            },
        },
    },
    shipmentOrderDetailsContainer: {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
    },
    shipmentOrderDetailsContainerIcon: {
        color: COLORS.light,
        "&.selected": {
            color: UiSchemeV2.colors.greys.white,
        },
    },
    orderDetailsSection: {
        maxWidth: "100%",
        display: "flex",
        flexDirection: "row",
        gap: "5px",
    },
    shipmentNumberSection: {
        display: "flex",
        alignItems: "center",
        fontSize: TYPOGRAPHY.text,
    },
    shipmentPurchaseNumberSection: {
        maxWidth: "95%",
        fontSize: TYPOGRAPHY.text,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    divider: {
        borderLeft: `1px solid ${COLORS.main}`,
        "&.selected": {
            borderLeft: `1px solid ${UiSchemeV2.colors.greys.white}`,
        },
    },
    // Step Details Container (right side)
    stepsDetailsContainer: {
        height: "100%",
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        fontSize: TYPOGRAPHY.text,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    locationsContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        maxWidth: "100%",
        gap: "0.5rem",
        color: COLORS.medium,
        fontSize: TYPOGRAPHY.title,
        "&.selected": {
            color: UiSchemeV2.colors.greys.white,
        },
    },
    fromAndDestLocationSection: {
        maxWidth: "45%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    iconContainer: {
        maxWidth: "20%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: COLORS.medium,

        "&.selected": {
            color: UiSchemeV2.colors.greys.white,
        },
        "& svg": {
            "& circle": {
                stroke: "currentColor",
            },
        },
    },
    activeLabelSection: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: "0.5rem",
        alignItems: "center",
        fontSize: TYPOGRAPHY.text,

        "& svg": {
            "& path": {
                stroke: "currentcolor",
            },
        },
    },
    activeLabel: {
        fontSize: TYPOGRAPHY.text,
        maxWidth: "87%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",

        "&.selected": {
            color: UiSchemeV2.colors.greys.white,
        },
    },
});

export default useStyles;
