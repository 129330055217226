import { FunctionComponent, SVGAttributes } from "react";
import { UiSchemeV2 } from "../../../assets/data/ui";
import { AirplaneIconV2, BoatIconV2, TruckIconV2 } from "../../../assets/icons";
import { ShipmentStatus, ShipmentType } from "../../../helpers/services/ShipmentService";

export const STRINGS = {
    FILTER: "Filter",
    ACTIVE: "active",
    ACTIVE_SHIPMENTS: "Active Shipments",
    ALL_SHIPMENTS: "All Shipments",
};

interface ITabs {
    [key: string]: string;
    activeShipments: string;
    allShipments: string;
}
export const TABS: ITabs = {
    activeShipments: STRINGS.ACTIVE_SHIPMENTS,
    allShipments: STRINGS.ALL_SHIPMENTS,
};
// export const TABS = [STRINGS.ACTIVE_SHIPMENTS, STRINGS.ALL_SHIPMENTS];

export const SHIPMENT_TYPES = {
    air: "Air",
    inland: "Inland",
    ocean: "Ocean",
};

export const CHIP_COLORS: { [key: string]: { primary: string; secondary?: string; trinary?: string } } = {
    Origin: {
        primary: UiSchemeV2.colors.originLightBlue,
        secondary: UiSchemeV2.colors.originMedium,
        trinary: UiSchemeV2.colors.originDark,
    },
    "In Transit": {
        primary: UiSchemeV2.colors.secondaryGreen,
        secondary: UiSchemeV2.colors.inTransitMedium,
        trinary: UiSchemeV2.colors.inTransitDark,
    },
    Destination: {
        primary: UiSchemeV2.colors.destinationPurple,
        secondary: UiSchemeV2.colors.destinationMedium,
        trinary: UiSchemeV2.colors.destinationDark,
    },
    Completed: {
        primary: UiSchemeV2.colors.greys.grey100,
        secondary: UiSchemeV2.colors.greys.grey200,
        trinary: UiSchemeV2.colors.greys.grey300,
    },
};

// Key names by the label values of SHIPMENT_TYPES & EShipmentStages
// ! Set to initial state
export const FILTERS: Record<string, boolean> = {
    Origin: false,
    "In Transit": false,
    Destination: false,
    Completed: false,
    Air: false,
    Inland: false,
    Ocean: false,
};

export const FILTER_TO_QUERY_KEY: Record<string, ShipmentStatus | ShipmentType> = {
    Origin: "origin",
    "In Transit": "inTransit",
    Destination: "destination",
    Completed: "close",
    Air: "Air",
    Inland: "Inland",
    Ocean: "Ocean",
};

// export const QUERY_KEY_

export const TYPE_CHIP_ICONS: {
    [key: string]: FunctionComponent<SVGAttributes<SVGSVGElement>>;
} = {
    Air: AirplaneIconV2,
    Inland: TruckIconV2,
    Ocean: BoatIconV2,
};
