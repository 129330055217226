import "./SearchBar.scss";
import { ReactComponent as SearchIcon } from "../../assets/icons/UnicargoSearchIcon.svg";
import { TextField } from "@mui/material";
import { MuiTheme } from "../../helpers";
import { useAppSelector } from "../../store";
import { useEffect, useRef } from "react";

interface ISearchProps {
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    backgroundColor?: "gray" | "white";
    width?: string;
    height?: string;
    value: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
export const SearchBar = ({
    value,
    onChange,
    backgroundColor = "white",
    width,
    height,
    onClick,
}: ISearchProps) => {
    const autoFocus = useAppSelector((state) => state.rootReducer.searchBar.autoFocus);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (autoFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [autoFocus]);

    return (
        <MuiTheme>
            <div
                onClick={onClick}
                className={`SearchBar ${backgroundColor}`}
                style={{ width: `${width}`, height: `${height}` }}
            >
                <SearchIcon />
                <TextField
                    id="searchBar"
                    InputProps={{
                        className: "SearchBarTextField",
                        sx: {
                            "& .MuiInputBase-input::placeholder": {
                                opacity: 1,
                            },
                        },
                    }}
                    value={value}
                    inputRef={inputRef}
                    fullWidth
                    placeholder="Search my shipments"
                    onChange={onChange}
                    autoComplete="off"
                />
            </div>
        </MuiTheme>
    );
};

export default SearchBar;
