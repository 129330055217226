import { makeStyles } from "@mui/styles";
import { COLORS, TYPOGRAPHY, UiSchemeV2 } from "../../../assets/data/ui";
import { theme } from "../../../assets/theme/theme";

const useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "32px",
        gap: "0.5rem",
        padding: "6px 12px",
        margin: "0",
        border: `2px solid ${UiSchemeV2.colors.primaryDark} !important`,
        borderRadius: "29px",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: `${UiSchemeV2.colors.greys.background} !important`,

            "&.selected": {
                "&:hover": {
                    backgroundColor: `${UiSchemeV2.colors.primaryDark} !important`,
                },
            },
        },
    },
    RfqContainer: {
        gap: "10px",
        padding: "9px 16px",
        height: "40px",
        minWidth: "110px",
    },
    label: {
        color: UiSchemeV2.colors.primaryDark,
        fontSize: TYPOGRAPHY.title,
        lineHeight: "17px",
        whiteSpace: "nowrap",
        [theme.breakpoints.down("md")]: {
            fontSize: TYPOGRAPHY.titleLarge,
        },
    },
    iconContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
            height: "20px",
            width: "20px",
            " &path": {
                fill: "currentcolor",
            },
        },
        "&.selected": {
            color: UiSchemeV2.colors.greys.white,
            "& svg path": {
                fill: "currentcolor",
            },
        },
    },
    rfqIconContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: COLORS.main,
        "& svg path": {
            fill: COLORS.primaryDark,
            height: "16px",
            width: "16px",
        },

        "&.selected": {
            backgroundColor: COLORS.primaryDark,
            "& svg path": {
                fill: "white",
            },
        },
    },
    counterContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0.5rem",
        height: "1rem",
        borderRadius: "46px",
    },
    counter: {
        whiteSpace: "nowrap",
        fontSize: TYPOGRAPHY.text,
        fontWeight: "400",
    },
});

export default useStyles;
