import { MuiTheme } from "../../helpers";
import "./InvoicesTable.scss";
import { useState, Fragment, useEffect } from "react";
import { Table, TableBody, TableContainer, Paper, SwipeableDrawer } from "@mui/material";
import { InvoicesDefaultBackgroundSvg } from "../../assets/icons";
import FilterNavbar from "../FilterNavbar/FilterNavbar";
import InvoiceRow from "./InvoiceRow";
import { IInvoice, PaymentStage, ValidInvoiceStatus } from "../../models/invoices/invoices.interfaces";
import { InvoicesTableEmptyState } from "./InvoicesTableEmptyState";
import InvoicesTableHead from "./InvoicesTableHead";
import { useAppDispatch, useAppSelector } from "../../store";
import {
    IInvoicesSliceState,
    initialState,
    resetInvoicesSlice,
    setFilters,
    setPage,
    setPaymentStage,
    setSelectedInvoices,
} from "../../store/reducers/invoicesSlice.store";
import { FilterTypes, InvoicesFilterKeyNames, InvoicesFilterLabels } from "../../models/filters/filters.interfaces";
import PaymentAmountContainer from "./PaymentAmountContainer";
import PaymentPopup from "../paymentPopup/PaymentPopup";
import UnicargoPaginationBtn from "../UnicargoPaginationBtn/UnicargoPaginationBtn";
import { ISummaryData } from "../../models/http/ISummaryData";
import { getInvoicesEmptyStateType } from "../../helpers/services/FiltersService.service";
import { InvoiceSkeletonRow } from "./InvoiceSkeletonRow";
import { IShipment } from "../../models";
import { ShipmentDetailsBlock } from "../blocks";
import "./InvoicesTable.scss";
import { useLazyGetInvoicesByCustomerCodeQuery } from "../../store/api-slices/invoicesSlice.api";
import { useLazyGetShipmentsByCustomerCodeQuery } from "../../store/api-slices/shipmentsSlice.api";
import UnicargoExportBtn from "./UnicargoExportBtn";
import { tryTrackEvent } from "../../helpers/services/MixPanelService";
import dayjs from "dayjs";
import ShipmentsEmptyState from "../blocks/shipment-details/sub-components/ShipmentDetailsTabs/ShipmentsDetailsEmptyState";
import ShipmentDetailsBlockSkeleton from "../blocks/shipment-details/sub-components/ShipmentDetailsTabs/ShipmentDetailsBlockSkeleton";

const getInvoicesArrWithoutSpecificInvoice = (invoices: IInvoice[], invoiceToRemove: IInvoice) => {
    return invoices.filter((invoice) => invoice.invoiceNumber !== invoiceToRemove.invoiceNumber);
};

const isCreditInvoice = (invoice: IInvoice) => invoice.invoiceNumber.startsWith("CR");

interface IInvoicesTable {
    invoices?: IInvoice[];
    summaryData?: ISummaryData;
    isLoading?: boolean;
    isError?: boolean;
    invoiceNumber?: string | null;
}

export const InvoicesTable = ({ invoices, summaryData, isLoading, isError, invoiceNumber }: IInvoicesTable) => {
    const dispatch = useAppDispatch();

    // Invoices variables
    const areInvoicesEmpty = !invoices || invoices.length === 0;
    const invoicesState = useAppSelector((state) => state.rootReducer.invoices);
    const [openPaymentOptions, setOpenPaymentOptions] = useState(false);
    const customerCode = useAppSelector((state) => state.rootReducer.users.code);
    const columns = invoicesState.columns;
    const filters = invoicesState.filters;
    const selectedInvoices = invoicesState.selectedInvoices;
    const paymentStage = invoicesState.paymentStage;
    const page = invoicesState.page;
    const limit = invoicesState.limit;

    // Shipment variables
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedShipment, setSelectedShipment] = useState<IShipment>();
    const [
        triggerGetShipmentsByCustomerCodeQuery,
        {
            data: shipmentsData,
            isFetching: isFetchingShipments,
            isError: isErrorShipments,
            isSuccess: isSuccessShipments,
        },
    ] = useLazyGetShipmentsByCustomerCodeQuery();

    // Get the shipment details of the clicked invoice file number (the file number is the shipment number)
    useEffect(() => {
        if (isErrorShipments) return;
        if (isSuccessShipments && shipmentsData?.shipments) {
            if (shipmentsData.shipments.length !== 1) {
                console.error("There should be only one shipment");
                return;
            }
            setSelectedShipment(shipmentsData.shipments[0]);
        }
    }, [isErrorShipments, isSuccessShipments, shipmentsData]);

    // On invoice file number clicked, get the shipment details of the clicked invoice file number
    const handleFileNumberClicked = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, invoice: IInvoice) => {
        tryTrackEvent("[Payments Page]: File number clicked (to reach drawer)");
        e.stopPropagation();
        if (!invoice.fileNumber) return;
        setIsDrawerOpen(true);
        if (selectedShipment?.shipmentDetails.shipmentNumber === invoice.fileNumber) return;
        triggerGetShipmentsByCustomerCodeQuery(
            {
                customerCode,
                params: {
                    search: invoice.fileNumber,
                },
            },
            true,
        );
    };

    const handleNextButtonClicked = () => {
        tryTrackEvent("[Payments Page]: 'Next' button clicked", {
            flow: "Payment",
            currentStage: paymentStage,
            newStage: PaymentStage.ReadingSelectedInvoicesStage,
        });
        dispatch(setPaymentStage(PaymentStage.ReadingSelectedInvoicesStage));
    };

    const handlePayButtonClicked = () => {
        tryTrackEvent("[Payments Page]: 'Pay' button clicked", {
            flow: "Payment",
            currentStage: paymentStage,
            newStage: PaymentStage.SelectingPaymentMethodStage,
        });
        setOpenPaymentOptions(true);
        dispatch(setPaymentStage(PaymentStage.SelectingPaymentMethodStage));
    };
    const [
        getInvoicesRelatedToShipment,
        {
            data: invoicesRelatedToShipmentData,
            isFetching: isFetchingInvoicesRelatedToShipment,
            isSuccess: isSuccessInvoicesRelatedToShipment,
            isError: isErrorInvoicesRelatedToShipment,
        },
    ] = useLazyGetInvoicesByCustomerCodeQuery();

    const handleUnpaidCreditInvoiceSelected = (currencyAcronym: string, fileNumber: string) => {
        // On click, get all Unpaid invoices (including Credit) of the selected currency and set them as the selectedInvoices
        getInvoicesRelatedToShipment({
            customerCode,
            params: {
                sortOrder: initialState.sort.sortOrder,
                sortType: initialState.sort.sortType,
                search: fileNumber,
                page: -1, // -1 means no pagination
                limit: -1, // -1 means no pagination
                filters: [
                    {
                        keyName: InvoicesFilterKeyNames.STATUS,
                        label: InvoicesFilterLabels.STATUS,
                        filterType: FilterTypes.MULTI_CHOICE_SELECT,
                        includeAllOption: false,
                        options: [
                            {
                                label: ValidInvoiceStatus.UNPAID,
                                isSelected: true,
                                value: ValidInvoiceStatus.UNPAID,
                            },
                        ],
                    },
                    {
                        keyName: InvoicesFilterKeyNames.CURRENCY,
                        label: InvoicesFilterLabels.CURRENCY,
                        filterType: FilterTypes.MULTI_CHOICE_SELECT,
                        includeAllOption: false,
                        options: [
                            {
                                label: currencyAcronym,
                                isSelected: true,
                                value: currencyAcronym,
                            },
                        ],
                    },
                ],
            },
        });
    };

    useEffect(() => {
        if (isErrorInvoicesRelatedToShipment) return;
        if (isSuccessInvoicesRelatedToShipment && invoicesRelatedToShipmentData?.invoices)
            dispatch(
                setSelectedInvoices(
                    selectedInvoices
                        .filter(
                            (selectedInvoice) =>
                                !invoicesRelatedToShipmentData.invoices.find(
                                    (invoiceFromReq) => invoiceFromReq.invoiceNumber === selectedInvoice.invoiceNumber,
                                ),
                        )
                        .concat(invoicesRelatedToShipmentData.invoices),
                ),
            );
    }, [
        isFetchingInvoicesRelatedToShipment,
        isErrorInvoicesRelatedToShipment,
        isSuccessInvoicesRelatedToShipment,
        invoicesRelatedToShipmentData,
    ]);

    const renderInvoiceItems = () => {
        if (isLoading) {
            const skeletonRows = Array.from({ length: 8 }).map((_, index) => (
                <InvoiceSkeletonRow key={index} columns={columns} />
            ));
            return skeletonRows;
        }

        if (invoices) {
            return invoices.map((invoice) => (
                <InvoiceRow
                    key={invoice.invoiceNumber}
                    invoice={invoice}
                    customerCode={customerCode}
                    columns={columns}
                    handleFileNumberClicked={(e) => handleFileNumberClicked(e, invoice)}
                    isRowSelected={selectedInvoices.some(
                        (selectedInvoice) => selectedInvoice.invoiceNumber === invoice.invoiceNumber,
                    )}
                    onRowSelectedChange={(isSelecting) => {
                        if (isSelecting) {
                            tryTrackEvent("[Invoices Table]: Invoice select", {
                                invoiceStatus: invoice.paymentStatus,
                                isOverdue: dayjs(invoice.dueDate).isBefore(dayjs()).toString(),
                            });
                            if (isCreditInvoice(invoice)) {
                                handleUnpaidCreditInvoiceSelected(invoice.currencyAcronym, invoice.fileNumber);
                                return;
                            }
                            dispatch(setSelectedInvoices([...selectedInvoices, invoice]));
                            return;
                        }
                        // If the invoice is a credit invoice, deselect it
                        if (!isCreditInvoice(invoice)) {
                            const relatedSelectedInvoices = selectedInvoices.filter(
                                (selectedInvoice) => selectedInvoice.fileNumber === invoice.fileNumber,
                            );
                            const numberOfRelatedSelectedInvoicesWithoutCredit = relatedSelectedInvoices.filter(
                                (selectedInvoice) => !isCreditInvoice(selectedInvoice),
                            ).length;
                            // If there is only one related invoice selected and it's not a credit invoice, deselect all
                            if (numberOfRelatedSelectedInvoicesWithoutCredit === 1) {
                                // Deselect all related invoices!
                                dispatch(
                                    setSelectedInvoices(
                                        selectedInvoices.filter(
                                            (selectedInvoice) => selectedInvoice.fileNumber !== invoice.fileNumber,
                                        ),
                                    ),
                                );
                                return;
                            }
                        }
                        // Deselect the invoice
                        dispatch(setSelectedInvoices(getInvoicesArrWithoutSpecificInvoice(selectedInvoices, invoice)));
                    }}
                    selectedInvoices={selectedInvoices}
                    isInvoiceOpen={invoice.invoiceNumber === invoiceNumber}
                />
            ));
        }
    };
    return (
        <MuiTheme>
            <section className={"InvoicesSection"}>
                {isError ? (
                    <div className="ErrorTextContainer">
                        <h2 className="ErrorText">Error fetching data, please try again later!</h2>
                    </div>
                ) : (
                    <Fragment>
                        {paymentStage === PaymentStage.InitialSelectingStage ? (
                            <div className="FilteringSection">
                                <FilterNavbar
                                    className="FiltersBar"
                                    filters={filters}
                                    onChange={(newFilters) => dispatch(setFilters(newFilters))}
                                    onDefaultViewBtnClick={() =>
                                        dispatch(resetInvoicesSlice(IInvoicesSliceState.InitialState))
                                    }
                                />
                                <div className="OtherOptionsContainer">
                                    <UnicargoExportBtn
                                        summaryData={summaryData}
                                        invoicesState={invoicesState}
                                        customerCode={customerCode}
                                    />
                                    <UnicargoPaginationBtn
                                        className="PaginationBtn"
                                        currentPage={page}
                                        rowsPerPage={limit}
                                        totalAmountOfRows={summaryData?.invoicesCount ?? 0}
                                        onPreviousPageBtnClick={() => dispatch(setPage(page - 1))}
                                        onNextPageBtnClick={() => dispatch(setPage(page + 1))}
                                    />
                                </div>
                            </div>
                        ) : (
                            <h2 className="ReadSelectedInvoicesStageTitle Bold">Invoices for payment</h2>
                        )}
                        <TableContainer
                            className={`InvoicesTableContainer ${
                                areInvoicesEmpty ? "Empty" : selectedInvoices.length > 0 && "ShownPaymentBar"
                            }`}
                            component={Paper}
                        >
                            <Table
                                className={`InvoicesTable ${areInvoicesEmpty && "Empty"}`}
                                aria-label="invoices-collapsible table"
                                sx={{ tableLayout: "fixed" }}
                            >
                                <InvoicesTableHead />
                                <TableBody className={`${areInvoicesEmpty && "Empty"}`}>
                                    {renderInvoiceItems()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <PaymentAmountContainer
                            paymentStage={paymentStage}
                            selectedInvoices={selectedInvoices}
                            onPayBtnClicked={handlePayButtonClicked}
                            onNextBtnClicked={handleNextButtonClicked}
                        />
                        <PaymentPopup isOpen={openPaymentOptions} setIsOpen={setOpenPaymentOptions} />
                        {!isLoading && areInvoicesEmpty ? (
                            <InvoicesTableEmptyState type={getInvoicesEmptyStateType(filters)} />
                        ) : (
                            <InvoicesDefaultBackgroundSvg className="InvoicesBackgroundSvg" />
                        )}
                    </Fragment>
                )}
                <SwipeableDrawer
                    open={isDrawerOpen}
                    onClose={() => {
                        setIsDrawerOpen(false);
                    }}
                    onOpen={() => setIsDrawerOpen(true)}
                    anchor="right"
                    className={"drawer-container"}
                >
                    {isFetchingShipments ? (
                        <ShipmentDetailsBlockSkeleton />
                    ) : !selectedShipment ? (
                        <ShipmentsEmptyState />
                    ) : (
                        <ShipmentDetailsBlock
                            shipment={selectedShipment}
                            handleCloseClicked={() => setIsDrawerOpen(false)}
                            originPage="Payments Page"
                        />
                    )}
                </SwipeableDrawer>
            </section>
        </MuiTheme>
    );
};
