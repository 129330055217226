import "./NotificationCardPositive.scss";
import NotificationCardBase from "../../notification-card-base/NotificationCardBase";
import { INotificationCard } from "../../../../../models";

const NotificationCardPositive = ({ className, onButtonClicked: onMarkClicked, ...rest }: INotificationCard) => (
    <NotificationCardBase
        onButtonClicked={onMarkClicked}
        className={`NotificationCardPositive ${className || ""}`}
        {...rest}
    />
);

export default NotificationCardPositive;
