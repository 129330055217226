import { useMemo } from "react";

import { ConfirmedIcon, HashtagIcon, LongRightArrow } from "../../../../assets/icons";
import { ETransportModes, IShipment, IShipmentLocation } from "../../../../models";
import { Block } from "../../../blocks";
import {
    getCardColor,
    getShipmentCardIcon,
    getHighlightedText,
    stringToUpperCases,
} from "../shared/services/shipment-cards.service";
import useStyles from "./useStyles";
import { ACTIVE_STEP_LABELS, STRINGS } from "../shared/resources";
import { getFullSteps, getShipmentCurrentStage } from "../../../../helpers/services/ShipmentService";
import { tryTrackEvent } from "../../../../helpers/services/MixPanelService";
interface ISearchableCard {
    query: string;
    selected: boolean;
    shipmentData: IShipment;
    onClick: (shipment: IShipment) => void;
}

export const ShipmentListCard = ({ query, shipmentData, selected, onClick }: ISearchableCard) => {
    const classes = useStyles();
    const { preDelivery, shipmentDetails, postDelivery, steps } = shipmentData;
    const { transportMode, shipmentNumber, shipmentName, fromLocation, destLocation, mainCarriageATA } =
        shipmentDetails;
    const { purchaseOrderNumber } = preDelivery;
    const { PODRecievedDate } = postDelivery;
    const [seperatedSteps, activeStep] = getFullSteps(shipmentData);

    const activeLabel = ACTIVE_STEP_LABELS[seperatedSteps[activeStep]?.stepName] ?? STRINGS.nullPlaceholder;

    const cardColor = useMemo(() => {
        const currentStage = getShipmentCurrentStage(
            steps,
            ETransportModes[transportMode],
            PODRecievedDate,
            mainCarriageATA,
            shipmentDetails,
        );
        return getCardColor(currentStage);
    }, [steps, transportMode, shipmentDetails]);

    const highlightTextByQuery = (value?: string | null) => {
        return value ? getHighlightedText(value, query) : "";
    };

    const handleCardClicked = () => {
        onClick(shipmentData);
        tryTrackEvent("[Shipment Page]: Shipment card clicked");
    };

    const memoizedFromLocation = useMemo<IShipmentLocation>(() => {
        return {
            city: stringToUpperCases(fromLocation?.city),
            country: fromLocation?.country,
        };
    }, [fromLocation]);

    const memoizedDestLocation = useMemo<IShipmentLocation>(() => {
        return {
            city: stringToUpperCases(destLocation?.city),
            country: destLocation?.country,
        };
    }, [destLocation]);

    return (
        <Block className={classes.root} onClick={() => handleCardClicked()}>
            <div
                className={`${classes.contentContainer} ${selected && "selected"}`}
                style={{ borderLeftColor: cardColor }}
            >
                <div className={`${classes.innerContainer} ${selected && "selected"}`}>
                    <div className={classes.shipmentDetailsContainer}>
                        {/* left side container */}
                        <div className={classes.shipmentNameSection}>
                            {/* Icon */}
                            {getShipmentCardIcon(ETransportModes[transportMode])}
                            {/* Shipment Name Is Shipment Number*/}
                            {highlightTextByQuery(shipmentNumber)}
                        </div>
                        <div className={classes.shipmentOrderDetailsContainer}>
                            <div className={`${classes.shipmentOrderDetailsContainerIcon} ${selected && "selected"}`}>
                                {purchaseOrderNumber && <HashtagIcon />}
                            </div>
                            <div className={classes.orderDetailsSection}>
                                {purchaseOrderNumber && (
                                    <>
                                        <div className={`${selected && "selected"}`}></div>
                                        <div
                                            className={classes.shipmentPurchaseNumberSection}
                                            title={purchaseOrderNumber}
                                        >
                                            {highlightTextByQuery(purchaseOrderNumber)}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* right side container */}
                    <div className={classes.stepsDetailsContainer}>
                        <div className={`${classes.locationsContainer} ${selected && "selected"}`}>
                            <div className={classes.fromAndDestLocationSection} title={memoizedFromLocation.city}>
                                {highlightTextByQuery(memoizedFromLocation.city)}
                            </div>
                            <div className={` ${classes.iconContainer} ${selected && "selected"}`}>
                                {<LongRightArrow />}
                            </div>
                            <div className={classes.fromAndDestLocationSection} title={memoizedDestLocation.city}>
                                {/* dest location */}
                                {highlightTextByQuery(memoizedDestLocation.city)}
                            </div>
                        </div>
                        <div className={classes.activeLabelSection}>
                            {activeLabel && (
                                <>
                                    <ConfirmedIcon />
                                    <div className={` ${classes.activeLabel} ${selected && "selected"}`}>
                                        {activeLabel}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Block>
    );
};
