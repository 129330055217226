import { useMemo, useState } from "react";
import { getFormattedDate } from "../../../../../helpers/services/DateFormatsService.service";
import { ETransportModes, IShipment, IShipmentDetails, IShipmentStep } from "../../../../../models";
import { ICONS_DICT, LABELS_DICT, STRINGS } from "./resources";
import AdditionalDetailsStatus from "./sub-conmponents/AdditionalDetailsStatus/AdditionalDetailsStatus";

import useStyles from "./useStyles";

export const getStatusIconsByShipment = (shipment: IShipment) => {
    switch (ETransportModes[shipment.shipmentDetails.transportMode]) {
        case ETransportModes.Inland:
            return <InlandIconsRow {...shipment} />;
        case ETransportModes.Ocean:
            return <OceanIconsRow {...shipment} />;
        case ETransportModes.Air:
            return <AirIconsRow {...shipment} />;
        default:
            break;
    }
};

const InlandIconsRow = (shipment: IShipment) => {
    const classes = useStyles();
    const [showLabel, setShowLabel] = useState(false);

    const { shipmentDetails, postDelivery } = shipment;
    const { transportMode } = shipmentDetails;
    const { PODRecievedDate } = postDelivery;
    const icons = ICONS_DICT[ETransportModes[transportMode]];
    const labels = LABELS_DICT[transportMode];
    const isTooltipDisplayPOD = useMemo(() => (showLabel && PODRecievedDate) as boolean, [showLabel, PODRecievedDate]);

    if (!PODRecievedDate) return null;

    return (
        <div
            onMouseEnter={() => {
                setShowLabel(true);
            }}
            onMouseLeave={() => setShowLabel(false)}
            className={`${classes.iconContainer} ${STRINGS.completed} POD`}
        >
            {icons[0]}
            <AdditionalDetailsStatus
                isTooltipDisplay={isTooltipDisplayPOD}
                toolTipTitle={getFormattedDate(PODRecievedDate as string)}
                statusLabel={labels[0]}
            />
        </div>
    );
};

const OceanIconsRow = (shipment: IShipment) => {
    const classes = useStyles();
    const [showLabel0, setShowLabel0] = useState(false);
    const [showLabel1, setShowLabel1] = useState(false);
    const [showLabel3, setShowLabel3] = useState(false);

    const { shipmentDetails, postDelivery, steps } = shipment;
    const { transportMode, customsClearence, telexReleasDate } = shipmentDetails;
    const { PODRecievedDate } = postDelivery;
    const transshipmentsCount = getTranshipmentsDetails(steps);
    const icons = ICONS_DICT[ETransportModes[transportMode]];
    const labels = LABELS_DICT[transportMode];
    const isTooltipDisplayCustoms = useMemo(
        () => (showLabel0 && customsClearence?.clearenceDate) as boolean,
        [showLabel0, customsClearence?.clearenceDate],
    );
    const isTooltipDisplayTelex = useMemo(
        () => (showLabel1 && telexReleasDate) as boolean,
        [showLabel1, telexReleasDate],
    );
    const isTooltipDisplayPOD = useMemo(
        () => (showLabel3 && PODRecievedDate) as boolean,
        [showLabel3, PODRecievedDate],
    );

    return (
        <>
            {customsClearence?.clearenceDate && (
                <div
                    onMouseEnter={() => setShowLabel0(true)}
                    onMouseLeave={() => setShowLabel0(false)}
                    className={`${classes.iconContainer} ${customsClearence?.cleared && STRINGS.completed}`}
                >
                    {icons[0]}
                    <AdditionalDetailsStatus
                        isTooltipDisplay={isTooltipDisplayCustoms}
                        toolTipTitle={getFormattedDate(customsClearence?.clearenceDate as string)}
                        statusLabel={labels[4]}
                    />
                </div>
            )}
            {telexReleasDate && (
                <div
                    onMouseEnter={() => setShowLabel1(true)}
                    onMouseLeave={() => setShowLabel1(false)}
                    className={`${classes.iconContainer} ${STRINGS.completed}`}
                >
                    {icons[1]}
                    <AdditionalDetailsStatus
                        isTooltipDisplay={isTooltipDisplayTelex}
                        toolTipTitle={getFormattedDate(telexReleasDate as string)}
                        statusLabel={labels[5]}
                    />
                </div>
            )}
            <div className={`${classes.iconContainer} carriage`}>
                {icons[2]}
                {transshipmentsCount > 0 ? <>{`${labels[2]} (${transshipmentsCount})`}</> : <>{STRINGS.direct}</>}
            </div>
            {PODRecievedDate && (
                <div
                    onMouseEnter={() => setShowLabel3(true)}
                    onMouseLeave={() => setShowLabel3(false)}
                    className={`${classes.iconContainer} ${PODRecievedDate && STRINGS.completed} POD`}
                >
                    {icons[3]}
                    <AdditionalDetailsStatus
                        isTooltipDisplay={isTooltipDisplayPOD}
                        toolTipTitle={getFormattedDate(PODRecievedDate as string)}
                        statusLabel={labels[3]}
                    />
                </div>
            )}
        </>
    );
};

const AirIconsRow = (shipment: IShipment) => {
    const classes = useStyles();
    const [showLabel0, setShowLabel0] = useState(false);
    const [showLabel2, setShowLabel2] = useState(false);

    const { shipmentDetails, postDelivery, steps } = shipment;
    const { transportMode, customsClearence } = shipmentDetails;
    const { PODRecievedDate } = postDelivery;
    const transshipmentsCount = getTranshipmentsDetails(steps);
    const icons = ICONS_DICT[ETransportModes[transportMode]];
    const labels = LABELS_DICT[transportMode];

    const isTooltipDisplayCustoms = useMemo(
        () => (showLabel0 && customsClearence?.clearenceDate) as boolean,
        [showLabel0, customsClearence?.clearenceDate],
    );
    const isTooltipDisplayPOD = useMemo(
        () => (showLabel2 && PODRecievedDate) as boolean,
        [showLabel2, PODRecievedDate],
    );

    return (
        <>
            {customsClearence?.clearenceDate && (
                <div
                    onMouseEnter={() => setShowLabel0(true)}
                    onMouseLeave={() => setShowLabel0(false)}
                    className={`${classes.iconContainer} ${customsClearence?.cleared && STRINGS.completed}`}
                >
                    {icons[0]}
                    <AdditionalDetailsStatus
                        isTooltipDisplay={isTooltipDisplayCustoms}
                        toolTipTitle={getFormattedDate(customsClearence?.clearenceDate as string)}
                        statusLabel={labels[3]}
                    />
                </div>
            )}
            <div className={`${classes.iconContainer} carriage`}>
                {icons[1]}
                {transshipmentsCount > 0 ? <>{`${labels[1]} (${transshipmentsCount})`}</> : <>{STRINGS.direct}</>}
            </div>
            {PODRecievedDate && (
                <div
                    onMouseEnter={() => setShowLabel2(true)}
                    onMouseLeave={() => setShowLabel2(false)}
                    className={`${classes.iconContainer} ${PODRecievedDate && STRINGS.completed} POD`}
                >
                    {icons[2]}
                    <AdditionalDetailsStatus
                        isTooltipDisplay={isTooltipDisplayPOD}
                        toolTipTitle={getFormattedDate(customsClearence?.clearenceDate as string)}
                        statusLabel={PODRecievedDate ? labels[2] : null}
                    />
                </div>
            )}
        </>
    );
};

export const getTranshipmentsDetails = (steps: IShipmentStep[]) => {
    const includedSteps = ["transshipment1", "transshipment2", "transshipment3"];
    let count = 0;
    steps.forEach((step) => {
        if (includedSteps.includes(step.stepName)) {
            // If step has ANY value / location
            if (step.ETD?.length || step.ATD || step.ETA?.length || step.ATA || step.location) {
                count++;
            }
        }
    });
    return count;
};

interface ICarrierDetails {
    preNumberMsg: string;
    preNameMsg: string;
    carrierNumber?: string | null;
    carrierName?: string | null;
}

export const renderContainerByTransportMode = (
    transportMode: ETransportModes,
    shipmentDetails: IShipmentDetails,
): JSX.Element | null => {
    const { voyageNumber, vesselName, airlineCode, airlineKey, airlineName } = shipmentDetails;

    switch (transportMode) {
        case ETransportModes.Ocean:
            return voyageNumber || vesselName
                ? renderCarrierDetails({
                      preNameMsg: STRINGS.vesselName,
                      preNumberMsg: STRINGS.voyageNumber,
                      carrierName: vesselName,
                      carrierNumber: voyageNumber,
                  })
                : null;
        case ETransportModes.Air:
            return (airlineKey !== 1 && airlineCode) || airlineName
                ? renderCarrierDetails({
                      preNameMsg: STRINGS.flightName,
                      preNumberMsg: STRINGS.flightNumber,
                      carrierName: airlineName,
                      carrierNumber: `${airlineCode}${airlineKey}`,
                  })
                : null;
        case ETransportModes.Inland:
            return null;
    }
};

const renderCarrierDetails = ({ preNameMsg, preNumberMsg, carrierName, carrierNumber }: ICarrierDetails) => {
    return (
        <div className={"carrierDetails"}>
            {(carrierNumber || carrierName) && (
                <div className={"vesselDetailsContainer"}>
                    {carrierNumber && <div className={"carrierNumber"}>{`${preNumberMsg} ${carrierNumber}`}</div>}
                    {carrierName && (
                        <>
                            {carrierName && <div className={"divider"}></div>}
                            {`${preNameMsg} ${carrierName}`}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
