import styles from "./SidebarDesktop.module.scss";
import { EmailIcon } from "../../../assets/icons";
import { UnicargoLogo } from "../../../assets/images";
import NavigationBar from "../../navigation-bar/NavigationBar";
import { useAppDispatch, useAppSelector } from "../../../store";
import { userLogOut } from "../../../store/reducers/usersSlice.store";
import { Dialog, Tooltip } from "@mui/material";
import ContactUs from "../../contactus/ContactUs";
import { Fragment, useState } from "react";
import { COLORS } from "../../../assets/data/ui";
import TermsAndConditions from "../../termsAndConditions/TermsAndConditions";
import { ClickAwayListener } from "@mui/base";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";
import SwitchAccounts from "./switch-accounts/SwitchAccounts";

export const SidebarDesktop = () => {
    const [istermsOpen, setIsTermsOpen] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isUserActionsOpen, setIsUserActionsOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const userState = useAppSelector((state) => state.rootReducer.users);
    const getInitials = () => {
        const { name = "" } = userState;
        const splittedName = name.trim().split(" ");
        const initials = splittedName.slice(0, 2).map((word) => {
            return word[0];
        });
        return initials.join("").toUpperCase();
    };

    const handleDeleteAccount = () => {
        setIsOpen(true);
        setIsUserActionsOpen((prevValue) => !prevValue);
        tryTrackEvent("[Delete account]: 'Delete account' button clicked");
    };

    const handleLogout = () => {
        dispatch(userLogOut());
        setIsUserActionsOpen((prevValue) => !prevValue);
        tryTrackEvent("[NavBar]: 'Log out' button clicked");
    };
    const handleTermsAndConditionClicked = () => {
        setIsTermsOpen(true);
        setIsUserActionsOpen((prevValue) => !prevValue);
        tryTrackEvent("[NavBar]: 'Terms and Conditions' button clicked");
    };

    const userOptions = [
        {
            title: "Terms and conditions",
            onClickFunction: handleTermsAndConditionClicked,
        },
        {
            title: "Delete account",
            onClickFunction: handleDeleteAccount,
        },
        {
            title: "Log out",
            onClickFunction: handleLogout,
        },
    ];

    const emailUsBtnPress = () => {
        setIsOpen(true);
        tryTrackEvent("[NavBar]: 'Email us' button clicked (in desktop)");
    };

    const InitialsBtnPress = () => {
        setIsUserActionsOpen((prevValue) => !prevValue);
        tryTrackEvent("[Navbar]: Initial button clicked");
    };

    const onCloseUserActions = () => {
        setIsUserActionsOpen(false);
    };

    return (
        <div className={styles.SidebarDesktop}>
            <div className={styles.sidebarTopSection}>
                <UnicargoLogo />
                <NavigationBar />
            </div>
            <div className={styles.sidebarBottomContainer}>
                <button onClick={() => emailUsBtnPress()} className={`${styles.icon} ${styles.emailUsIconContainer}`}>
                    <EmailIcon />
                    Email us
                </button>
                <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                    <ContactUs setIsOpen={setIsOpen} />
                </Dialog>
                <Dialog maxWidth="md" open={istermsOpen} onClose={() => setIsTermsOpen(false)}>
                    <TermsAndConditions />
                </Dialog>

                <div className={styles.divider}></div>
                <ClickAwayListener onClickAway={() => setIsUserActionsOpen(false)}>
                    <div className={styles.customerIconContainer}>
                        <Tooltip
                            className={styles.userOptionsTooltip}
                            open={isUserActionsOpen}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        borderRadius: "4px",
                                        marginBottom: "12px !important",
                                        marginLeft: "22px !important",
                                        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.14)",
                                        bgcolor: `${COLORS.white}`,
                                        "& .MuiTooltip-arrow": {
                                            color: `${COLORS.white}`,
                                        },
                                        "& .MuiTooltip-arrow:before": {
                                            boxShadow: 1,
                                        },
                                    },
                                },
                            }}
                            title={<UserOptions options={userOptions} onCloseUserActions={onCloseUserActions} />}
                            placement="right"
                        >
                            <button className={styles.customerIcon} onClick={() => InitialsBtnPress()}>
                                {/* Get the customer's name initials (like LS) and display it here. */}
                                {getInitials()}
                            </button>
                        </Tooltip>
                    </div>
                </ClickAwayListener>
            </div>
        </div>
    );
};

export interface IUserOptionsProps {
    options?: { title: string; onClickFunction?: () => void }[];
    onCloseUserActions: () => void;
}

export default function UserOptions({ options, onCloseUserActions }: IUserOptionsProps) {
    return (
        <div className={styles.userOptions}>
            <SwitchAccounts onCloseUserActions={onCloseUserActions} />
            <div className={styles.divider}></div>
            {options?.map((option, index) => {
                return (
                    <Fragment key={index}>
                        {index === options.length - 1 && <div className={styles.divider}></div>}
                        <div className={styles.userOption}>
                            <button className={styles.userOptionButton} onClick={option.onClickFunction}>
                                {option.title}
                            </button>
                        </div>
                    </Fragment>
                );
            })}
        </div>
    );
}
