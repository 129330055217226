import { ReactComponent as CheckMarkIcon } from "../../assets/icons/check-mark.svg";
import { UiSchemeV2 } from "../../assets/data/ui";
import { IRenderStepProps } from "./types";
import { RenderDate } from "./RenderDate";
import { useEffect, useRef, useState } from "react";
import useStyles from "./useStyles";
import "./RenderStep.scss";

export const RenderStep = ({ containerWidth, step, color, isDisabled, shipmentDetails }: IRenderStepProps) => {
    const Icon = step.icon;
    const [descriptionLeft, setDescriptionLeft] = useState(0);
    const descriptionRef = useRef<HTMLSpanElement>(null);

    const inlandTransportMode = shipmentDetails?.transportMode === "Inland";

    let inLandColor = color;

    if (inlandTransportMode) {
        if (step.cardName === "origin") {
            inLandColor = UiSchemeV2.colors.originMedium;
        }
        if (step.cardName === "inTransit") {
            inLandColor = UiSchemeV2.colors.inTransitMedium;
        }
        if (step.cardName === "destination") {
            inLandColor = UiSchemeV2.colors.destinationPurple;
        }
    }

    const { iconContainer } = useStyles({
        color: isDisabled ? UiSchemeV2.colors.greys.grey200 : inlandTransportMode ? inLandColor : color,
    });

    useEffect(() => {
        const updateDescriptionLeft = () => {
            if (descriptionRef.current) {
                const rect = descriptionRef.current.getBoundingClientRect();
                setDescriptionLeft(rect.left);
            }
        };

        updateDescriptionLeft();

        window.addEventListener("resize", updateDescriptionLeft);

        return () => window.removeEventListener("resize", updateDescriptionLeft);
    }, []);

    return (
        <div className={`step-wrapper ${isDisabled && "disable"}`}>
            <div className="step-location-container">
                <div className={`${iconContainer} icon-container`}>
                    <div
                        className={`step-dot ${step.isDone && "is-done-dot"}`}
                        style={{ backgroundColor: step.dotColor }}
                    />
                    <Icon />
                </div>
                <div className="step-location" id="start">
                    {step.destination}
                </div>
            </div>

            <div className="step-description-and-date-container">
                <div className="step-description-and-icon-container">
                    <span className="step-description">
                        {step.descriptionsAndDates.map((item, index) => (
                            <span className="step-description-item">
                                <span className="icon-container">
                                    {step.descriptionsAndDates[index].isDone && <CheckMarkIcon />}
                                </span>
                                <span
                                    ref={descriptionRef}
                                    className={`${
                                        (isDisabled || !step.descriptionsAndDates[index].isDone) && "disable-line"
                                    } description`}
                                >
                                    {item.description}
                                </span>
                                <span className="dates">
                                    <RenderDate
                                        isDisabled={isDisabled || !step.descriptionsAndDates[index].isDone}
                                        descriptionLeft={descriptionLeft}
                                        containerWidth={containerWidth}
                                        descriptionAndDate={item}
                                    />
                                </span>
                            </span>
                        ))}
                    </span>
                </div>
            </div>
        </div>
    );
};
