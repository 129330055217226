import { configureStore } from "@reduxjs/toolkit";
import { unicargoApiSlice, portsApiSlice, localApiSlice } from "./apiSlice";
import { rootReducer } from "./reducers/rootReducer.store";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

/**
 * Store config function.
 * Configures redux-thunk by default.
 */
export const store = configureStore({
    reducer: {
        rootReducer,
        [unicargoApiSlice.reducerPath]: unicargoApiSlice.reducer,
        [portsApiSlice.reducerPath]: portsApiSlice.reducer,
        [localApiSlice.reducerPath]: localApiSlice.reducer,
    },
    // devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            unicargoApiSlice.middleware,
            portsApiSlice.middleware,
            localApiSlice.middleware,
        ]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

// Use this instead of plain useSelector / useDispatch for store types.
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
