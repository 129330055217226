import { FunctionComponent } from "react";
import styles from "./DecorativeLine.module.scss";

interface DecorativeLineProps {
    width?: string;
    height?: string;
    colorLeftLine: string;
    colorRightLine: string;
    colorMiddleCircle: string;
    leftLinePercentage: number;
    className?: string;
    borderRadius?: string;
    style?: React.CSSProperties;
}

const DecorativeLine: FunctionComponent<DecorativeLineProps> = ({
    width,
    height,
    colorLeftLine,
    colorRightLine,
    colorMiddleCircle,
    leftLinePercentage,
    className,
    borderRadius,
    style,
}: DecorativeLineProps) => {
    const leftLinePercentageString = `${leftLinePercentage}%`;
    const rightLinePercentageString = `${100 - leftLinePercentage}%`;
    return (
        <div
            className={`${styles.DecorativeLine} ${className ? styles.className : ""}`}
            style={{ width, height, ...style }}
        >
            <div
                className={`${styles.SideLine} ${styles.LeftLine}`}
                style={{
                    backgroundColor: colorLeftLine,
                    width: leftLinePercentageString,
                    borderRadius: `${borderRadius && `${borderRadius} 0 0 ${borderRadius}`}`,
                }}
            />
            <div
                className={`${styles.SideLine} ${styles.RightLine}`}
                style={{
                    backgroundColor: colorRightLine,
                    width: rightLinePercentageString,
                    borderRadius: `${borderRadius ? `0 ${borderRadius} ${borderRadius} 0` : "0"}`,
                }}
            />
            <div
                className={styles.Circle}
                style={{ backgroundColor: colorMiddleCircle, width: height, height, left: leftLinePercentageString }}
            />
        </div>
    );
};

export default DecorativeLine;
