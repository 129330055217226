import { createTheme } from "@mui/material";
import { palette } from "./palette";
import { typography } from "./typography";
import { components } from "./components";
import { BREAKPOINTS } from "../data/ui";

export const theme = createTheme({
    palette: palette,
    typography: typography,
    components: components,
    spacing: 1, // multiplies the sx props by the current value (relevant for padding / margin).
    breakpoints: {
        values: BREAKPOINTS,
    },
});
