import "./UnicargoSearchBar.scss";
import { ReactComponent as SearchIcon } from "../../assets/icons/UnicargoSearchIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/cross.svg";
import { TextField } from "@mui/material";
import { MuiTheme } from "../../helpers";
import { useAppSelector } from "../../store";
import { useEffect, useRef, useState } from "react";
import { UiSchemeV2 } from "../../assets/data/ui";
import { IFilter } from "../../models/filters/filters.interfaces";
import { useSearchDebounce } from "../../helpers/custom-hooks";
import { useSearchParams } from "react-router-dom";

interface IUnicargoSearchProps {
    searchFilter: IFilter;
    onChange: (newFilter: IFilter) => void;
    backgroundColor?: string;
    placeholder?: string;
    debounceTime?: number;
}
export const UnicargoSearchBar = ({
    searchFilter,
    onChange,
    backgroundColor = UiSchemeV2.colors.greys.background,
    placeholder,
    debounceTime,
}: IUnicargoSearchProps) => {
    const initialSearchValue = searchFilter.options[0].value as string;
    const autoFocus = useAppSelector((state) => state.rootReducer.searchBar.autoFocus);
    const inputRef = useRef<HTMLInputElement>(null);
    const [searchValue, setSearchValue] = useState<string>(initialSearchValue);
    const debouncedSearchValue = debounceTime ? useSearchDebounce(searchValue, debounceTime) : searchValue;
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        setSearchValue(searchFilter.options[0].value as string);
    }, [searchFilter]);

    useEffect(() => {
        const newSearchFilter: IFilter = {
            ...searchFilter,
            options: [
                {
                    ...searchFilter.options[0],
                    isSelected: debouncedSearchValue !== "",
                    value: debouncedSearchValue,
                },
            ],
        };
        onChange(newSearchFilter);
    }, [debouncedSearchValue]);

    useEffect(() => {
        if (autoFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [autoFocus]);

    const handleClearBtnClick = () => {
        searchParams.delete("invoiceNumber");
        setSearchParams(searchParams);
        setSearchValue("");
    };
    return (
        <MuiTheme>
            <div className={"UnicargoSearchBar"} style={{ backgroundColor }}>
                <SearchIcon className="SearchIcon" />
                <TextField
                    className="SearchBarTextField"
                    id="searchBar"
                    InputProps={{ className: "SearchBarTextField" }}
                    value={searchValue}
                    inputRef={inputRef}
                    placeholder={placeholder}
                    onChange={(e) => setSearchValue(e.target.value.trim())}
                />
                {searchValue && (
                    <div className="ClearBtn" onClick={() => handleClearBtnClick()}>
                        <CloseIcon width={"10.5px"} height={"10.5px"} />
                    </div>
                )}
            </div>
        </MuiTheme>
    );
};
