import styles from "./StageColorContainer.module.scss";

interface IColorContainerProps {
    color: string;
    width?: string;
    height?: string;
}

const StageColorContainer = ({ color, width, height }: IColorContainerProps) => (
    <div className={styles.ColorContainer} style={{ backgroundColor: `${color}`, width: width, height: height }} />
);

export default StageColorContainer;
