import { FC } from "react";
import styles from "./UnicargoKeyValueTextInfoContainer.module.scss";
import SpinnerLoader from "../ui-decorations/SpinnerLoader/SpinnerLoader";

interface UnicargoKeyValueTextInfoContainerProps {
    value: Record<string, string>;
    legend?: Record<string, string>;
    customClassName?: string;
    showLoader?: boolean;
}

const UnicargoKeyValueTextInfoContainer: FC<UnicargoKeyValueTextInfoContainerProps> = ({
    value,
    customClassName,
    legend,
    showLoader = false,
}) => {
    const searchLoader = (
        <div style={{ display: "flex", alignItems: "center", gap: "0.75rem" }}>
            <SpinnerLoader size="2rem" />
            <span>Loading...</span>
        </div>
    );
    return (
        <div className={`${styles.UnicargoKeyValueTextInfoContainer} ${customClassName}`}>
            {showLoader
                ? searchLoader
                : Object.keys(value).map((key, index) => (
                      <div className={styles.KeyValueContainer} key={index}>
                          <div className={`${styles.Key} Bold`}>{legend?.[key] ?? key}</div>
                          <div className={styles.Value}>{value[key]}</div>
                      </div>
                  ))}
        </div>
    );
};

export default UnicargoKeyValueTextInfoContainer;
