import { FunctionComponent } from "react";
import styles from "./ShipmentsListBlockCardLoader.module.scss";
import SkeletonLoaderItem from "../../ui-decorations/SkeletonLoader/SkeletonLoaderItem";

interface ShipmentsListBlockCardLoaderProps {
    className?: string;
}

const ShipmentsListBlockCardLoader: FunctionComponent<ShipmentsListBlockCardLoaderProps> = ({ className}) => {
    return (
            <div className={`${styles.ShipmentsListBlockCardLoader} ${className}`}>
                <div className={styles.LeftSideContainer}>
                    <SkeletonLoaderItem className={styles.TopLine} />
                    <SkeletonLoaderItem className={styles.BottomLine} />
                </div>
                <div className={styles.RightSideContainer}>
                    <SkeletonLoaderItem className={styles.TopLine} />
                    <SkeletonLoaderItem className={styles.BottomLine} />
                </div>
            </div>
    );
};

export default ShipmentsListBlockCardLoader;
