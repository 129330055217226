import { INotification } from "../../models";
import { INotificationRequest } from "../../models/entities/notification/INotification";
import { unicargoApiSlice } from "../apiSlice";

const urlPrefix = "/notifications";
export const notificationsApiSlice = unicargoApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getNotificationsByCustomerCode: builder.query<INotificationRequest, { customerCode: string, page?: number, limit?: number }>({
            query: ({ customerCode, page, limit }) => `${urlPrefix}/${customerCode}?page=${page}&limit=${limit}`,
        }),
        updateNotificationSeenStatus: builder.mutation<INotification, number>({
            query: (notificationId: number) => ({
                url: `${urlPrefix}`,
                method: "PUT",
                body: {
                    notificationId: notificationId,
                },
            }),
        }),
        updateNotificationsSeenStatus: builder.mutation<INotification[], string>({
            query: (customerCode: string) => ({
                url: `${urlPrefix}/MarkAllAsRead/${customerCode}`,
                method: "PUT",
            }),
        }),
    }),
    overrideExisting: false,
});
export const {
    useUpdateNotificationsSeenStatusMutation,
    useLazyGetNotificationsByCustomerCodeQuery,
    useUpdateNotificationSeenStatusMutation,
} = notificationsApiSlice;
