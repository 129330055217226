import SkeletonLoaderItem from "../../../../../../ui-decorations/SkeletonLoader/SkeletonLoaderItem";
import styles from "./SkeletonDocumentTab.module.scss";

export const SkeletonDocumentTab = () => {
    return (
        <div className={styles.SkeletonDocumentTabContainer}>
            {Array.from(Array(5).keys()).map((_, index) => (
                <div className={styles.ItemContainer} key={index}>
                    <SkeletonLoaderItem className={styles.skeleton} />
                </div>
            ))}
        </div>
    );
};
