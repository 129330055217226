import { makeStyles } from "@mui/styles";
import { COLORS, UiSchemeV2 } from "../../../assets/data/ui";
import { theme } from "../../../assets/theme/theme";

export const useStyles = makeStyles({
    wrapperPerUnit: {
        display: "flex",
        gap: "24px",
        width: "100%",
        flexDirection: "column",
    },
    wrapper: {
        display: "flex",
        gap: "18px",
    },
    wrapperPerUnitBoxes: {
        display: "flex",
        flexDirection: "row",
        minWidth: "60%",
    },
    wrapperPerUnitSecondSection: {
        display: "flex",
        border: "none",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "16px",
        backgroundColor:"white",
        
        "& .MuiOutlinedInput-notchedOutline":{
            boxShadow:"none !important",
            borderRadius: "16px",
        },

    },
    headerWrapper: {
        width: "100%",
        gap: "20px",
    },
    subHeader: {
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "17px",
        color: COLORS.main,
        alignSelf: "center",
    },
    chooseWrapper: {
        margin: "17px 0 0 0 !important",
        gap: "24px",
        width: "100%",
        "& .MuiToggleButtonGroup-root":{
            width:"300px",
            alignSelf:"center",
        }
    },
    TabsWrapper: {
        margin: "17px 0 0 0 !important",
        gap: "24px",
        width: "100%",
    },
    insureWrapper: {
        display: "flex",
        position: "relative",
        gap: "9px",
    },
    wrapperDescribe: {
        display: "flex",
        gap: "12px",
        width: "100%",
        [theme.breakpoints.down("lg")]: {
            gap: "24px",
            flexDirection: "column",
        },
    },
    wrapperInsurance: {
        width: "100%",
    },
    goodsInsurance: {
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            width: "50%",
        },
    },
    calcPerUnitWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems:"center",
    },

    container:{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    },
    greyLine:{
        width: "100%",
        height: "1px",
        background: COLORS.veryLight,
    },
    title: {
        color: COLORS.medium,
        fontSize: "14px",
        fontWeight: "400",
    },
    calcPerUnit: {
        color: UiSchemeV2.colors.primaryDark,
        fontSize: "14px",
        fontWeight: "400",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        border: `1px solid ${COLORS.veryLight}`,
        borderRadius: "16px",
        width: "196px",
        height: "48px",
        padding: "12px 16px",
    },
});
