import { Box } from "@mui/material";
import { CargoArrangement, PreviousStepsBannerProps } from "../../../models/rfq/rfq-types";
import { useStyles } from "./useStyles";
import { PreviousStepsTitles } from "../../../models/rfq/rfq-types";
import { EditIcon, RfqV } from "../../../assets/icons";
import { oceanCargoType } from "../../../pages/rfq/second-form/secondPhaseFormUtils";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";

const PreviousStepsBanner: React.FC<PreviousStepsBannerProps> = ({
    currentPhaseNumber,
    stepTitle,
    stepNumber,
    details,
    setFormPhase,
}) => {
    const classes = useStyles();
    const renderPreviousFormDetails = (stepType: PreviousStepsTitles): any => {
        //use case of amazon warehouse
        const fromLocationHelper = details.fullFromAddress?.Address
            ? details.fullFromAddress?.Address + ", " + details.fullFromAddress?.City
            : details.fullFromAddress?.City;
        //use case of amazon warehouse
        const toLocationHelper = details.fullToAddress?.Address
            ? details.fullToAddress?.Address + ", " + details.fullToAddress?.City
            : details.fullToAddress?.City;
        const fromLocation =
            details.fullFromAddress?.state || details.fullFromAddress?.city || fromLocationHelper || details.from;
        const fromCountry =
            details.fullFromAddress?.countryName ||
            details.fullFromAddress?.country ||
            details.fullFromAddress?.State ||
            details.fullFromAddress?.Country ||
            "";
        const toLocation =
            details.fullToAddress?.state || details.fullToAddress?.city || toLocationHelper || details.to;
        const toCountry =
            details.fullToAddress?.countryName ||
            details.fullToAddress?.country ||
            details.fullToAddress?.State ||
            details.fullToAddress?.Country ||
            "";

        const additionalStartingFromAddress =
            details.fullFromAddress?.CODE ||
            details.fullFromAddress?.portName ||
            details.fullFromAddress?.airportName ||
            details.fullFromAddress?.WHCode ||
            "";
        const additionalStartingToAddress =
            details.fullToAddress?.CODE ||
            details.fullToAddress?.portName ||
            details.fullToAddress?.airportName ||
            details.fullToAddress?.WHCode ||
            "";
        const additionalEndingFromAddress =
            details.fullFromAddress?.iata ||
            details.fullFromAddress?.portCode ||
            details.fullFromAddress?.Country ||
            "";
        const additionalEndingToAddress =
            details.fullToAddress?.iata || details.fullToAddress?.portCode || details.fullToAddress?.Country || "";

        switch (stepType) {
            case PreviousStepsTitles.FirstPhase:
                return (
                    <span>
                        <span className={classes.greyWord}>By</span> {details.type?.join(", ")}
                        <span className={classes.inlineWordDivider}>|</span>
                        <span className={classes.greyWord}>From:</span> {additionalStartingFromAddress}
                        {additionalStartingFromAddress !== "" ? ", " : ""}
                        {fromLocation !== additionalStartingFromAddress
                            ? fromLocation + (fromCountry != "" ? ", " : "")
                            : ""}
                        {fromCountry}
                        {additionalEndingFromAddress !== "" ? ", " : ""}
                        {additionalEndingFromAddress}
                        <span className={classes.inlineWordDivider}>|</span>
                        <br />
                        <span className={classes.greyWord}>To:</span> {additionalStartingToAddress}
                        {additionalStartingToAddress !== "" ? ", " : ""}
                        {toLocation !== additionalStartingToAddress ? toLocation + (toCountry != "" ? ", " : "") : ""}
                        {toCountry}
                        {additionalEndingToAddress !== "" ? ", " : ""}
                        {additionalEndingToAddress}
                    </span>
                );
            case PreviousStepsTitles.SecondPhase: {
                const {
                    isTotalShipment,
                    totalShipmentCalculation,
                    quantity,
                    cargoType,
                    desc,
                    weight,
                    weightUnit,
                    volume,
                    volumeUnit,
                    fullVsPart,
                    palletType,
                } = details;
                if (fullVsPart === oceanCargoType.FULL_CONTAINER) {
                    return (
                        <span>
                            <span>{` ${quantity} ${fullVsPart} ${palletType}`}</span>
                            <span className={classes.inlineWordDivider}>|</span>
                            <span>{`${desc} `}</span>
                        </span>
                    );
                }
                if (isTotalShipment === CargoArrangement.TotalShipment) {
                    return (
                        <span>
                            <span>{` ${cargoType}`}</span>
                            <span className={classes.inlineWordDivider}>|</span>
                            <span>{`${desc}, ${weight}  ${weightUnit} ${volume}  ${volumeUnit} `}</span>
                        </span>
                    );
                } else {
                    return (
                        <span>
                            <span>{` ${quantity} ${cargoType}`}</span>
                            <span className={classes.inlineWordDivider}>|</span>
                            <span>{` ${desc}`}</span>
                            <span className={classes.inlineWordDivider}>|</span>
                            <span>{` ${totalShipmentCalculation} `}</span>
                        </span>
                    );
                }
            }
            case PreviousStepsTitles.ThirdPhase: {
                const { readyTime } = details;
                return ` Shipping ${readyTime}`;
            }
            default: {
                return "";
            }
        }
    };

    const shouldRenderBottomDivider = () => currentPhaseNumber - stepNumber === 1;

    return (
        <Box className={`${classes.bannerWrapper} ${shouldRenderBottomDivider() && classes.bottomDivider}`}>
            <Box className={classes.iconV}>
                <RfqV />
            </Box>
            <Box className={classes.textWrapper}>
                <span className={classes.bannerTitle}>{stepTitle}</span>
                <span className={classes.bannerDescription}>{renderPreviousFormDetails(stepTitle)}</span>
            </Box>
            <button
                onClick={() => {
                    tryTrackEvent(`[Rfq]: Edit step ${stepNumber} clicked from step ${currentPhaseNumber}`);
                    setFormPhase(stepNumber);
                }}
                className={classes.editIcon}
            >
                <EditIcon />
            </button>
        </Box>
    );
};

export default PreviousStepsBanner;
