import { useEffect, useMemo, useState } from "react";
import { ScreenWidthType } from "../../assets/data/ui";

export const useScreenWidthType = (givenWidth?: number): ScreenWidthType => {
    const [width, setWidth] = useState(givenWidth ?? window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return useMemo(() => {
        switch (true) {
            case width >= ScreenWidthType.EXTRA_LARGE:
                return ScreenWidthType.EXTRA_LARGE;
            case width >= ScreenWidthType.LARGE:
                return ScreenWidthType.LARGE;
            case width >= ScreenWidthType.MOBILE:
            default:
                return ScreenWidthType.MOBILE;
        }
    }, [width]);
};
