import { makeStyles } from "@mui/styles";
import { theme } from "../../../assets/theme/theme";
import { COLORS } from "../../../assets/data/ui";

export const useStyles = makeStyles({
    inputWrapper: {
        width: "100%",
        "& .MuiOutlinedInput-input": {
            color: COLORS.primaryDark,
            fontSize: "14px",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: COLORS.veryLight,
        },
        "& .MuiTextField-root:focus-within": {
            border: `1px solid ${COLORS.primaryDark}`,
            borderRadius: "16px",
        },
        "&:focus-within .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${COLORS.primaryDark}`,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
            borderRadius: "16px",
        },
    },
    checkBoxWrapper: {
        margin: "0px auto",
        display: "flex",
        flexDirection: "column",
        maxWidth: "495px",
        minWidth: "400px",
        width: "100%",
        paddingInlineStart: "30px",
        alignItems: "flex-start",
    },
    phoneCover: {
        width: "52px",
        height: "100%",
        position: "absolute",
        background: "rgba(0, 0, 0, 0.08)",
        borderRadius: "16px",
    },
});
