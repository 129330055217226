import "./OutlinedButton.scss";
import { MuiTheme } from "../../../helpers";
import { IButton } from "../../../models";
import BaseButton from "../base-button/BaseButton";

const OutlinedButton = ({ className, ...rest }: IButton) => (
    <MuiTheme>
        <BaseButton {...rest} className={`OutlinedButton ${className ? className : ""}`} variant={"outlined"} />
    </MuiTheme>
);

export default OutlinedButton;
