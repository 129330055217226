export const SHIPMENT_INFO_LABELS: Record<string, string> = {
    shipperName: "Shipper",
    incoterm: "Incoterm",
    cargoInsurance: "Cargo Insurance",
    consignee: "Consignee",
    descriptionOfGoods: "Description of Goods",
    subCategory: "Service"
} as const;

export const CARGO_DETAILS_LABELS: Record<string, string> = {
    shippingLine: "Shipping Line",
    vesselName: "Vessel Name",
    billOfLading: "Bill of Lading",
    isf: "ISF#",
    grossWeight: "Gross Weight (KG)",
    volume: "Volume (CBM)",
    chargeWeight: "Chargeable Weight (KG)",
    containerNumber: "Container Number",
} as const;

export const TEXTS = {
    shipmentInfo: "Shipment Info",
    cargoDetails: "Cargo Details"
};

export const toTitleCase = (str: string) => {
    return str
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };