import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../assets/data/ui";

const useStyles = makeStyles({
    popupWarpper: {
        display: "flex",
        gap: "1.5rem",
        flexDirection: "column",
        padding: "1.5rem",
        margin: "0px auto",
    },
    formbox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
    },
    close: {
        position: "absolute",
        right: "0px",
        top: "10px",
        cursor: "pointer",
    },

    headerWrapper: {
        margin: "0",
        textAlign: "center",
        padding: "10px",
        position: "relative",
    },
    header: {
        whiteSpace: "nowrap",
        color: COLORS.main,
        fontSize: "14px",
    },
    subHeader: {
        color: " #777C83",
        fontSize: "14px",
        maxWidth: "350px",
        textAlign: "center",
    },

    buttonsWarpper: {
        display: "flex",
        justifyContent: "center",
        justifyItems: "center",
        gap: "0.625rem",
    },
    button: {
        display: "flex",
        alignItems: "center",
        borderRadius: "4px",
        padding: "0.719rem 1.48rem",
        border: "1px solid",
        width: "fit-content",
        background: "#3B4858",
        color: "#FFFFFF",
    },
    buttonBack: {
        display: "flex",
        alignItems: "center",
        borderRadius: "4px",
        padding: "0.719rem 1.48rem",
        border: "1px solid #DCDCDC",
        gap: "0.5rem",
    },
    buttonText: {
        fontSize: "14px",
    },
});

export default useStyles;
