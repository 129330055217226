import { Select, SelectChangeEvent, MenuItem, Button, SxProps } from "@mui/material";
import "../../../pages/select-customer/SelectCustomerPage.scss";

import { useEffect, useMemo, useState } from "react";
import { setCurrentCustomer } from "../../../store/reducers/usersSlice.store";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useGetCustomersByEmailQuery } from "../../../store/api-slices/customersSlice.api";
import { ICustomer } from "../../../models";
import { menuPropsStyles } from "../../../pages/select-customer/SelectCustomerUtils";
import { COLORS } from "../../../assets/data/ui";
import { registerSuperPropertiesForMixpanel } from "../../../helpers/services/MixPanelService";

const SelectCustomerRfq = () => {
    const userData = useAppSelector((state) => state.rootReducer.users);
    const customersReq = useGetCustomersByEmailQuery(userData.email); // mock data as an array of objects.
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<string>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (userData.code) {
            setSelectedCustomer(
                JSON.stringify({
                    code: userData.code,
                    name: userData.name,
                    customerName: userData.customerName,
                    isNewUser: userData.isNewUser,
                }),
            );
        }
    }, [userData]);

    const sxStyle: SxProps = {
        ".MuiOutlinedInput-notchedOutline": {
            borderColor: COLORS.light,
            borderBottom: isMenuOpen ? "none !important" : "",
            borderRadius: isMenuOpen ? "4px 4px 0px 0px" : "",
        },

        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${COLORS.success} !important`,
            borderBottom: isMenuOpen ? "none !important" : "",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: COLORS.system.desitnation,
        },
        "& legend": {
            display: "none",
        },
    };

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedCustomer(event.target.value);
    };

    // If has a selected value, dispatch the customer code and navigate to the next page.
    const handleClick = () => {
        if (selectedCustomer) {
            const parsedObj: ICustomer = JSON.parse(selectedCustomer);
            dispatch(
                setCurrentCustomer({
                    ...userData,
                    customerName: parsedObj.customerName,
                    code: parsedObj.code,
                    name: parsedObj.name,
                    isNewUser: parsedObj.isNewUser,
                }),
            );

            registerSuperPropertiesForMixpanel({
                username: parsedObj.name ?? "",
                customerName: parsedObj.customerName ?? "",
            });
        }
    };

    const renderSelectItems = useMemo(() => {
        if (customersReq.isError) {
            return <span>No Customers</span>;
        } else {
            if (customersReq.isSuccess) {
                if (customersReq.data && customersReq.data.customers) {
                    if (customersReq.data.customers.length === 0) {
                        return <span>No Customers</span>;
                    } else if (customersReq.data.customers.length === 1) {
                        dispatch(
                            setCurrentCustomer({
                                ...userData,
                                code: customersReq.data?.customers[0].code,
                                name: customersReq.data?.customers[0].name ?? "",
                                isNewUser: customersReq.data?.customers[0].isNewUser,
                            }),
                        );
                    } else {
                        return customersReq.data?.customers?.map((data) => {
                            const actualData = {
                                code: data.code,
                                customerName: data.name,
                                name: customersReq.data?.name,
                                isNewUser: customersReq.data?.isNewUser,
                            };
                            return (
                                <MenuItem value={JSON.stringify(actualData)} key={data.code}>
                                    {`${data.code}${data.name ? `- ${data.name}` : ""}`}
                                </MenuItem>
                            );
                        });
                    }
                } else {
                    return <span>No Customers</span>;
                }
            }
        }
    }, [customersReq, userData]);

    return (
        <div className="SelectCustomerPage rfq-view">
            <div className="select-customer-sub-title">
                <span>Choose your account</span>
            </div>
            <div className="select-customer-box-container">
                <Select
                    className="select-customer-box"
                    value={selectedCustomer}
                    onChange={handleChange}
                    label="Choose your account"
                    MenuProps={menuPropsStyles}
                    sx={sxStyle}
                    onClose={() => {
                        setIsMenuOpen((prevValue) => !prevValue);
                    }}
                    onOpen={() => {
                        setIsMenuOpen((prevValue) => !prevValue);
                    }}
                >
                    {renderSelectItems}
                </Select>
            </div>
            <div className="select-customer-button-container">
                <Button className="select-customer-login-button" onClick={handleClick}>
                    Log in
                </Button>
            </div>
            <div className="select-customer-footer">
                <span>Can’t find your account?</span>
                <span>
                    Email us at <span>info@unicargo.com</span>
                </span>
            </div>
        </div>
    );
};

export default SelectCustomerRfq;
