import { makeStyles } from "@mui/styles";
import { COLORS, TYPOGRAPHY, UiSchemeV2 } from "../../../../../assets/data/ui";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: "100%",
    },
    tabsContainer: {
        display: "flex",
        alignItems: "center",
        width: "90%",
        margin: "0 2rem",
        borderBottom: `1px solid ${COLORS.light}`,
        gap: "1rem",
    },
    tab: {
        margin: "0",
        border: "none",
        backgroundColor: "transparent",
        padding: "0.5rem 0.25rem",
        color: COLORS.medium,
        fontWeight: "600",
        fontSize: TYPOGRAPHY.title,

        "&:hover": {
            cursor: "pointer",
            opacity: "0.7",
            "&:active": {
                opacity: "0.9",
            },
        },
        "&.selected": {
            borderBottom: `1px solid ${UiSchemeV2.colors.primaryDark}`,
            color: UiSchemeV2.colors.primaryDark,
        },
    },
    dynamicContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        position: "relative",
        overflow: "hidden", // removes double scrollbar
    },
    EmptyActiveShipmentsContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        zIndex: UiSchemeV2.zIndices.zIndexBackgroundImage,
    },

    EmptyActiveShipmentsContent: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },

    EmptyStateGrayLinesLeft: {
        width: "100%",
        transform: "translate(100px, -100px)",
    },

    EmptyActiveShipmentsCenter: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
    },

    NoActiveShipmentsMessage: {
        paddingTop: "10px",
    },
    EmptyStateGrayLinesRight: {
        width: "100%",
    },
});



export default useStyles;
