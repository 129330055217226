import { News } from "../../models/blocks/INewsBlock";
import { unicargoApiSlice } from "../apiSlice";

const urlPrefix = "/news";
export const newsApiSlice = unicargoApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getNews: builder.query<News[], void>({
            query: () => `${urlPrefix}`,
            keepUnusedDataFor: 3600,
        }),
    }),
    overrideExisting: false,
});
export const { useGetNewsQuery } = newsApiSlice;
