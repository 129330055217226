import { Authenticator, Button, View, useAuthenticator } from "@aws-amplify/ui-react";
import Dialog from "@mui/material/Dialog";
import { Auth, Hub, I18n } from "aws-amplify";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { SESSION_STORAGE } from "../assets/data/resources";
import { UiSchemeV2 } from "../assets/data/ui";
import { ArrowLeftIcon, EmailIcon } from "../assets/icons";
import { UnicargoLogoFull } from "../assets/images";
import ContactUsForm from "../components/contactus/ContactUs";
import TermsAndConditions from "../components/termsAndConditions/TermsAndConditions";
import DecorativeLine from "../components/ui-decorations/decorative-backgrounds/DecorativeLine";
import { getStorageItem, removeStorageItem } from "../helpers/services";
import { insertGoogleAnalyticsScript } from "../helpers/services/GoogleAnalyticsService.service";
import {
    initMixpanel,
    registerSuperPropertiesForMixpanel,
    setUserId,
    tryTrackEvent,
} from "../helpers/services/MixPanelService";
import { ICustomer } from "../models";
import { useAppDispatch, useAppSelector } from "../store";
import { userLogOut, userLoggedIn } from "../store/reducers/usersSlice.store";
import HelpButtonDialog from "./HelpButtonDialog";
import { PasswordValidations } from "./PasswordValidations";

import "@aws-amplify/ui-react/styles.css";
import styles from "../App.module.scss";
import "./AuthGuard.scss";

/**
 * Will display the the Cognito login form if the user doesn't have a cookie in it's browser.
 * If it has - will navigate to the last url it's been at, or "/overview".
 */

export interface IAuthGuardProps {
    isRfqView?: boolean;
    setNextStepRfqLogin?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthGuard = ({ isRfqView, setNextStepRfqLogin: setNextStepRfqLogin }: IAuthGuardProps) => {
    const dispatch = useAppDispatch();
    const userState = useAppSelector((state) => state.rootReducer.users);
    const navigate = useNavigate();
    const location = useLocation();
    const { route, user } = useAuthenticator();
    const from = location.state?.from?.pathname || "/overview";
    const search = location.state?.from?.search;
    const [initialState, setInitialState] = useState<"signIn" | "resetPassword" | "signUp" | undefined>("signIn");
    const [isTermsView, setTermsView] = useState(false);
    const [isLoginRfq, setIsLoginRfq] = useState(false);
    const [isContactUsOpen, setIsContactUsOpen] = useState(false);
    const [isValidationsShown, setIsValidationsShown] = useState(false);
    const [passwordValidations, setPasswordValidations] = useState({
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        hasSymbol: false,
        isLongEnough: false,
        passwordsMatch: true,
    });

    const setMixpanelEventsData = (userId: string) => {
        const customerData = getStorageItem<ICustomer>(SESSION_STORAGE.customerData);
        setUserId(userId);
        registerSuperPropertiesForMixpanel({
            username: customerData?.name ?? "",
            customerName: customerData?.customerName ?? "",
            is_user_logged_in: true,
        });
    };

    useEffect(() => {
        initMixpanel();
        insertGoogleAnalyticsScript();
    }, []);

    useEffect(() => {
        tryTrackEvent("[Login page]: app launch via login page");
        Hub.listen("auth", (data) => {
            if (data.payload.event === "signOut") {
                navigate(0);
            }
        });
        setInitialState(
            window.location.pathname === "/login"
                ? "signIn"
                : window.location.pathname === "/signup"
                ? "signUp"
                : "signIn",
        );
        const dict = {
            en: {
                "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
                    "Password must contain 10 characters, 1 capital letter, 1 number, 1 unique character.",
                "Custom auth lambda trigger is not configured for the user pool.": "Email does not exists.",
            },
        };
        I18n.putVocabularies(dict);
        if (route === "authenticated") {
            // get and set user data
            const customerData = getStorageItem<ICustomer>(SESSION_STORAGE.customerData);
            const userData: ICustomer = {
                email: user.attributes?.email || "",
                sub: user.attributes?.sub,
                token: user.getSignInUserSession()?.getAccessToken().getJwtToken(),
                code: customerData?.code ?? "",
                name: customerData?.name ?? "",
                isNewUser: customerData?.isNewUser ?? false,
                customerName: customerData?.customerName ?? "",
            };

            // if user's customer code state is empty and doesn't exist in the local storage - navigate to /select-customer.
            if (!userState.code && !customerData && !isRfqView) {
                navigate("/select-customer", {
                    replace: true,
                    state: { from: initialState, redirectFromCustomerSelection: `${from}${search || ""}` },
                });
            } else if (!isRfqView) {
                navigate(`${from}${search || ""}`, { replace: true });
            }

            dispatch(userLoggedIn(userData));

            if (isRfqView) {
                setNextStepRfqLogin && setNextStepRfqLogin(true);
            }
        } else if (route === "signOut" || route === "signIn") {
            removeStorageItem(SESSION_STORAGE.customerData);
        }
    }, [from, route, navigate, initialState, isLoginRfq]);

    const validatePassword = (password: string) => {
        const validations = {
            hasUpperCase: /[A-Z]/.test(password),
            hasLowerCase: /[a-z]/.test(password),
            hasNumber: /\d/.test(password),
            hasSymbol: /[^A-Za-z0-9]/.test(password),
            isLongEnough: password.length >= 10,
        };

        return validations;
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const validations = validatePassword(value);
        setPasswordValidations((prevState) => ({ ...prevState, ...validations }));
    };

    const onNavigateBack = () => {
        dispatch(userLogOut());
    };

    const formFields = {
        signIn: {
            username: {
                labelHidden: true,
                placeholder: "Email",
            },
            password: {
                labelHidden: true,
                placeholder: "Password",
            },
        },
        signUp: {
            username: {
                labelHidden: true,
                placeholder: "Email",
            },
            password: {
                labelHidden: true,
                placeholder: "Password",
                onChange: handlePasswordChange,
                onFocus: () => setIsValidationsShown(true),
            },
            confirm_password: {
                labelHidden: true,
                placeholder: "Confirm password",
            },
            acceptTerms: {
                label: "",
                descriptiveText: (
                    <label className="amplify-label">
                        I understand and accept the{" "}
                        <a
                            className="terms-and-condition-link"
                            onClick={() => {
                                setTermsView(true);
                            }}
                        >
                            terms and conditions
                        </a>{" "}
                    </label>
                ),
                name: "acceptTerms",
                type: "checkbox",
                isRequired: true,
                class: "terms",
            },

            // newsletter: {
            //     label: "Sign me up for the latest updates and exclusive content!",
            //     name: "newsletter",
            //     type: "checkbox",
            //     isRequired: false,
            //     class: "newsletter",
            // },
        },
        confirmSignUp: {
            confirmation_code: {
                labelHidden: true,
                placeholder: "Confirmation code",
            },
        },
        forceNewPassword: {
            password: {
                labelHidden: true,
                placeholder: "Password",
            },
            confirm_password: {
                labelHidden: true,
                placeholder: "Confirm password",
            },
        },
        resetPassword: {
            username: {
                labelHidden: true,
                placeholder: "Email",
            },
        },
        confirmResetPassword: {
            confirmation_code: {
                labelHidden: true,
                placeholder: "Confirmation code",
            },
            password: {
                labelHidden: true,
                placeholder: "Password",
                onChange: handlePasswordChange,
                onFocus: () => setIsValidationsShown(true),
            },
            confirm_password: {
                labelHidden: true,
                placeholder: "Confirm password",
            },
        },
        confirmVerifyUser: {
            confirmation_code: {
                labelHidden: true,
                placeholder: "Confirmation code",
            },
        },
    };

    const authComponents = {
        SignIn: {
            Header() {
                return isRfqView ? (
                    <div className="auth-message"></div>
                ) : (
                    <div className="auth-message">
                        <span className="title">Hello!</span>
                        <span className="title">Welcome back</span>
                        <span className="description">Log in to your account</span>
                    </div>
                );
            },
            Footer() {
                const { toResetPassword, toSignUp } = useAuthenticator();

                const onSignUpButtonClicked = () => {
                    tryTrackEvent("[Login Page]: 'SignUp' button clicked");
                    toSignUp();
                    navigate("/signup");
                };

                const onForgotPasswordButtonClicked = () => {
                    tryTrackEvent("[Login Page]: 'Forgot Password' button clicked");

                    toResetPassword();
                };

                return isRfqView ? (
                    <View>
                        {" "}
                        <Button
                            className="reset-password"
                            fontWeight="normal"
                            onClick={onForgotPasswordButtonClicked}
                            size="small"
                        >
                            Forgot password?
                        </Button>
                    </View>
                ) : (
                    <View>
                        <Button
                            className="reset-password"
                            fontWeight="normal"
                            onClick={onForgotPasswordButtonClicked}
                            size="small"
                        >
                            Forgot password?
                        </Button>
                        <Button
                            className="login-button"
                            fontWeight="normal"
                            onClick={onSignUpButtonClicked}
                            size="small"
                        >
                            Don't have a user? Sign up
                        </Button>
                    </View>
                );
            },
        },
        SignUp: {
            Header() {
                return (
                    <>
                        <div className="auth-message">
                            <span className="title">Hey!</span>
                            <span className="title">We’re glad you’re here</span>
                            <span className="description">
                                Sign up and stay informed with real-time tracking updates!
                            </span>
                        </div>
                        <PasswordValidations
                            passwordValidations={passwordValidations}
                            isValidationsShown={isValidationsShown}
                        />
                    </>
                );
            },
            Footer() {
                const { toSignIn } = useAuthenticator();
                return (
                    <>
                        <Dialog maxWidth="md" open={isTermsView} onClose={() => setTermsView(false)}>
                            <TermsAndConditions />
                        </Dialog>
                        <Button
                            className="signup-button"
                            fontWeight="normal"
                            onClick={() => {
                                toSignIn();
                                navigate("/login");
                            }}
                            size="small"
                        >
                            Already a user? Log in
                        </Button>
                        <HelpButtonDialog />
                    </>
                );
            },
        },
        ConfirmResetPassword: {
            Header() {
                return (
                    <>
                        <div className="auth-message reset-password">
                            <span className="title">Reset Your Password</span>
                        </div>
                        <PasswordValidations
                            passwordValidations={passwordValidations}
                            isValidationsShown={isValidationsShown}
                        />
                    </>
                );
            },
            Footer() {
                const { toSignUp } = useAuthenticator();

                const onSignUpButtonClicked = () => {
                    dispatch(userLogOut());
                    toSignUp();
                    navigate("/signup");
                };

                return (
                    <>
                        <div className="msg-container">
                            <p>
                                If we found a user with your email address, you will receive an email shortly
                                with your confirmation code.
                            </p>
                            <p>
                                Can't find the email? Try checking your spam folder, or contact our
                                <span> </span>
                                <span className="btn-contact" onClick={() => setIsContactUsOpen(true)}>
                                    support team.
                                </span>
                            </p>
                        </div>
                        <Button
                            className="login-button"
                            fontWeight="normal"
                            onClick={onSignUpButtonClicked}
                            size="small"
                        >
                            Don't have a user? <span>Sign up</span>
                        </Button>
                    </>
                );
            },
        },
        ConfirmSignUp: {
            Footer() {
                return <HelpButtonDialog />;
            },
        },
    };

    const services = {
        async handleSignUp(formData: any) {
            const { username, password, attributes } = formData;

            const user = await Auth.signUp({
                username,
                password,
                attributes,
                autoSignIn: {
                    enabled: true,
                },
            });

            setMixpanelEventsData(username);
            tryTrackEvent("[SignUp Page]: 'Sign up' button clicked");

            return user;
        },
        async handleSignIn(formData: any) {
            const { username, password } = formData;
            const user = await Auth.signIn({
                username,
                password,
            });

            setMixpanelEventsData(username);

            if (isRfqView) {
                setIsLoginRfq(true);
                tryTrackEvent("[Rfq - Login Flow]: 'Login' button clicked");
            } else {
                tryTrackEvent("[Login Page]: 'Login' button clicked");
            }

            return user;
        },
        async handleForgotPasswordSubmit(formData: any) {
            tryTrackEvent("[Forgot Password Flow]: 'Submit' button clicked");

            const { username, password, code } = formData;

            const data = await Auth.forgotPasswordSubmit(username, code, password);
            return data;
        },
    };

    return isRfqView ? (
        <div className="auth-container rfq-view">
            <Authenticator formFields={formFields} components={authComponents} services={services} />
        </div>
    ) : (
        <>
            <div className="auth-container">
                <div className="auth-header">
                    {route !== "signUp" && route !== "signIn" && route !== "resetPassword" && (
                        <div className="back-btn">
                            <ArrowLeftIcon height={"14px"} width={"14px"} onClick={onNavigateBack} />
                        </div>
                    )}
                    <UnicargoLogoFull />
                </div>
                <Authenticator
                    initialState={initialState}
                    formFields={formFields}
                    components={authComponents}
                    services={services}
                />
            </div>
            <DecorativeLine
                width="100%"
                className={`${styles.LineBackgroundSvg}`}
                colorLeftLine={UiSchemeV2.colors.primaryLight}
                colorRightLine={UiSchemeV2.colors.secondaryGreen}
                colorMiddleCircle={"#05B834"}
                leftLinePercentage={60}
                height="52px"
                borderRadius="0"
                style={{ zIndex: 1 }}
            />
            <Dialog open={isContactUsOpen} onClose={() => setIsContactUsOpen(false)}>
                <ContactUsForm setIsOpen={setIsContactUsOpen} />
            </Dialog>
        </>
    );
};
