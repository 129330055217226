import { Fragment, FunctionComponent } from "react";
import styles from "./UnicargoPaginationBtn.module.scss";
import { formatNumberWithDecimal } from "../../helpers/services/DateFormatsService.service";
import { ArrowRightFullIcon } from "../../assets/icons";

interface UnicargoPaginationBtnProps {
    className?: string;
    totalAmountOfRows: number;
    rowsPerPage: number;
    currentPage: number;
    onPreviousPageBtnClick: () => void;
    onNextPageBtnClick: () => void;
}

const UnicargoPaginationBtn: FunctionComponent<UnicargoPaginationBtnProps> = ({
    className,
    totalAmountOfRows,
    rowsPerPage,
    currentPage,
    onPreviousPageBtnClick,
    onNextPageBtnClick,
}: UnicargoPaginationBtnProps) => {
    const baseClassNames = `${styles.UnicargoPaginationBtn} ${className}`;
    const baseRowNumber = (currentPage - 1) * rowsPerPage;
    const formattedNumbers = {
        numberOfFirstShownRow: formatNumberWithDecimal(baseRowNumber + 1, 0) ?? 0,
        numberOfLastShownRow: formatNumberWithDecimal(Math.min(baseRowNumber + rowsPerPage, totalAmountOfRows)) ?? 0,
        numberOfAbsoluteLastRow: formatNumberWithDecimal(totalAmountOfRows, 0) ?? 0,
    };
    const shouldPrevBtnBeGreyedOut = currentPage === 1;
    const shouldNextBtnBeGreyedOut = formattedNumbers.numberOfLastShownRow === formattedNumbers.numberOfAbsoluteLastRow;

    return (
        <Fragment>
            {!!totalAmountOfRows && (
                <div className={baseClassNames}>
                    <div className={styles.PaginationText}>
                        {`${formattedNumbers.numberOfFirstShownRow}-${formattedNumbers.numberOfLastShownRow} of ${formattedNumbers.numberOfAbsoluteLastRow}`}
                    </div>
                    <div className={styles.PaginationBtnsContainer}>
                        <ArrowRightFullIcon
                            className={`${styles.PaginationBtnIcon} ${styles.PreviousPageBtn} ${
                                shouldPrevBtnBeGreyedOut && styles.DisabledBtn
                            }`}
                            onClick={shouldPrevBtnBeGreyedOut ? undefined : onPreviousPageBtnClick}
                        />
                        <ArrowRightFullIcon
                            className={`${styles.PaginationBtnIcon} ${styles.NextPageBtn} ${
                                shouldNextBtnBeGreyedOut && styles.DisabledBtn
                            }`}
                            onClick={shouldNextBtnBeGreyedOut ? undefined : onNextPageBtnClick}
                        />
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default UnicargoPaginationBtn;
