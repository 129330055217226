import { Box } from "@mui/system";
import { FormInputTextField } from "../form-elements/text-field/formInputTextField";
import { FormInputDropdown } from "../form-elements/dropdown/formInputDropdown";
import { useStyles } from "./useStyles";
import { ISecondPhaseProps, fullContainerDropdownOption } from "./SecondPhaseUtils";

const SecondPhaseFullContainer = ({ control }: ISecondPhaseProps) => {
    const classes = useStyles();
    return (
        <Box className={classes.wrapper}>
            <FormInputTextField
                type="number"
                size="md"
                required={true}
                name="quantity"
                control={control}
                label={"Number of containers"}
            />
            <FormInputDropdown
                required={true}
                items={fullContainerDropdownOption}
                name="palletType"
                control={control}
                label="Container type*"
            />
        </Box>
    );
};

export default SecondPhaseFullContainer;
