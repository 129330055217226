import { StepConnectorClassKey } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const stepConnectorStyles: Partial<OverridesStyleRules<StepConnectorClassKey>> = {
    root: {
        zIndex: "0",
    },
    horizontal: {
        // Make the connector whole instead of having gaps (will be relevant when display: none).
        top: "7px",
        width: "100%",
        zIndex: "0",
        left: "-50%",
        "&.Mui-active, &.Mui-completed": {
            top: "6px",
            "& .MuiStepConnector-line": {
                borderTopWidth: "3px",
                borderColor: "#777C83",
            },
        },
    },
    vertical: {
        marginLeft: "7px",
        position: "initial",
        "& .MuiStepConnector-line": {
            minHeight: "80px",
        },
        "&.Mui-active, &.Mui-completed": {
            marginLeft: "6px",
            top: "6px",
            "& .MuiStepConnector-line": {
                borderLeftWidth: "3px",
                borderColor: "#777C83",
            },
        },
    },
};
