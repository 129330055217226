import { makeStyles } from "@mui/styles";
import { COLORS, TYPOGRAPHY } from "../../../../assets/data/ui";

const center = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};
const useStyles = makeStyles({
    root: {
        display: "flex",
        border: `1px solid ${COLORS.general.dividers}`,
    },
    rootInnerContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "1rem",
        borderLeft: "7px solid", // TODO: might need to change that
        gap: "1.5rem",
    },
    headContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
    },
    // Head - title (stages & locations)
    headTitleContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    hiddenLocation: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "5rem",
        whiteSpace: "nowrap",
        margin: "0 !important",
    },
    visibleLocation: {
        overflow: "visible",
        margin: "0 !important",
    },
    stageSection: {},
    locationsSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: "wrap",
        gap: "0.5rem",
        color: COLORS.medium,
        "& svg": {
            "& path": {
                fill: COLORS.system.destination,
            },
            "& circle": {
                stroke: COLORS.system.destination,
            },
        },
    },
    // Head content - details
    headContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "0.5rem",
    },
    shipmentNameSection: {
        display: "flex",
        gap: "5px",
    },
    shipmentNameIcon: {
        ...center,
        "& svg path": {
            width: "16px",
            height: "16px",
        },
    },
    shipmentName: {
        ...center,
        fontWeight: "bold",
        fontSize: TYPOGRAPHY.titleLarge,
    },
    shipmentNumberSection: {
        ...center,
        gap: "0.5rem",
    },
    shipmentNumberIcon: {
        ...center,
        "& svg path": {
            width: "16px",
            height: "16px",
        },
    },
    shipmentNumber: {
        fontSize: TYPOGRAPHY.title,
    },
    bodyContainer: {},
    stepperContainer: {},
});

export default useStyles;
