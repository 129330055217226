import { useEffect, useRef } from "react";
import styles from "./PaymentFormPageIFrameContainerView.module.scss";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useLazySendPaymentLoadingPageQueryParamsQuery } from "../../../store/api-slices/paymentSlice.api";
import { receiveCustomPostMessageFromChild } from "../../../helpers/services/PostMessageService.service";
import { IPaymentPostMessage } from "../../../models/invoices/payments.interfaces";
import { setPaymentStage } from "../../../store/reducers/invoicesSlice.store";
import { PaymentStage } from "../../../models/invoices/invoices.interfaces";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";

const PaymentFormPageIFrameContainerView = () => {
    const dispatch = useAppDispatch();

    const invoicesState = useAppSelector((state) => state.rootReducer.invoices);
    const paymentStage = invoicesState.paymentStage;
    const customerCode = useAppSelector((state) => state.rootReducer.users.code);
    const paymentFormPageUri = invoicesState.paymentFormPageUri;
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [sendPaymentLoadingPageQueryParamsQuery, { data, error, isLoading }] =
        useLazySendPaymentLoadingPageQueryParamsQuery();

    useEffect(() => {
        if (!data) return;
        if (data.errorMessage) {
            tryTrackEvent("[Payments Page Popup]: Credit Card Fail", {
                flow: "Payment",
                currentStage: paymentStage,
                newStage: PaymentStage.ErrorInPaymentFlowStage,
            });
            dispatch(setPaymentStage(PaymentStage.ErrorInPaymentFlowStage));
            return;
        }
        tryTrackEvent("[Payments Page Popup]: Credit Card Success", {
            flow: "Payment",
            currentStage: paymentStage,
            newStage: PaymentStage.PaymentSuccessStage,
        });
        dispatch(setPaymentStage(PaymentStage.PaymentSuccessStage));
    }, [data, error, isLoading]);

    const receivePaymentResultFromIFrame = () => {
        receiveCustomPostMessageFromChild((event: IPaymentPostMessage) => {
            dispatch(setPaymentStage(PaymentStage.SendingPaymentQueryParamsResultStage));
            const { status, messageData: urlSearchParams } = event;
            if (status === "pending") return;
            if (status === "success")
                sendPaymentLoadingPageQueryParamsQuery({ customerCode, queryParamsString: urlSearchParams });
        });
    };

    useEffect(() => {
        if (!paymentFormPageUri) {
            tryTrackEvent("[Payments Page Popup]: Credit Card Fail", {
                flow: "Payment",
                currentStage: paymentStage,
                newStage: PaymentStage.ErrorInPaymentFlowStage,
            });
            dispatch(setPaymentStage(PaymentStage.ErrorInPaymentFlowStage));
            return;
        }
        receivePaymentResultFromIFrame();
    }, [paymentFormPageUri]);

    return (
        <div className={styles.PaymentFormPageIFrameContainerView}>
            <iframe
                ref={iframeRef}
                className={styles.PaymentFormPageIframe}
                src={paymentFormPageUri}
                title="YaadSarig Payment Form Page iFrame"
            />
        </div>
    );
};

export default PaymentFormPageIFrameContainerView;
