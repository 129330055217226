import { CargoArrangement, DropdownItemtype, ISecondPhaseFormInput, PalletType } from "../../../models/rfq/rfq-types";

import { Control, UseFormSetValue } from "react-hook-form";
export const oceanHeaderText = {
    containerText: "Container Load",
    loadTypeText: "Load Type",
    shipmentDescription: "Shipment Description",
};
export interface ISecondPhaseOceanHeaderProps {
    watchFullVsPart: string | undefined;
    control: Control<ISecondPhaseFormInput, any>;
}

export interface ISecondPhaseDescribeProps {
    control: Control<ISecondPhaseFormInput, any>;
    watchInsurance: string[];
}
export interface ISecondPhaseProps {
    control: Control<ISecondPhaseFormInput, any>;
    setFormValue: UseFormSetValue<ISecondPhaseFormInput>;
    initialValue?: any;
}

export const airRadioOptions = [
    {
        label: "Shipment Per Unit",
        value: CargoArrangement.ShipmentPerUnit,
    },
    {
        label: "Total Shipment",
        value: CargoArrangement.TotalShipment,
    },
];
export const PalltTypeDropdownOptions: DropdownItemtype[] = [
    {
        value: PalletType.SmallCm,
    },
    {
        value: PalletType.MediumCm,
    },
    {
        value: PalletType.LargeCm,
    },
    {
        value: PalletType.SmallIn,
    },
    {
        value: PalletType.MediumIn,
    },
];
export const fullContainerDropdownOption: DropdownItemtype[] = [
    {
        value: "20 FT",
    },
    {
        value: "40 FT",
    },
    {
        value: "40 FT HC",
    },
    {
        value: "45 FT HC",
    },
];
export const weightUnits = ["KG", "LB"];
export const volumeUnits = ["CBM", "CFT", "CIN"];
export const heightUnits = ["CM", "IN"];
export const checkboxOptions = [
    {
        label: "Add cargo insurance to my quote",
        value: "goodsInsurance",
    },
];
export const ExclamationInfoText = "Don’t risk it! We cover the combined value of goods and freight costs up to $500K.";
