import { ReactComponent as RightSmallArrow } from "../../assets/icons/right-small-arrow.svg";
import { DescriptionAndDate } from "./types";
import { UpdateHistoryCard } from "./UpdateHistoryCard";
import { useEffect, useRef, useState } from "react";
import { useScreenWidthType } from "../../helpers/custom-hooks";
import { ScreenWidthType } from "../../assets/data/ui";
import "./RenderDate.scss";

interface IRenderDate {
    descriptionAndDate: DescriptionAndDate;
    containerWidth: number;
    descriptionLeft: number;
    isDisabled?: boolean;
}

export const RenderDate = ({ descriptionLeft, containerWidth, descriptionAndDate, isDisabled }: IRenderDate) => {
    const [isHovering, setIsHovering] = useState(false);
    const { actualDates, estimatedDates, type } = descriptionAndDate;
    const lastDate = actualDates[0] || estimatedDates[estimatedDates.length - 1];
    const dateRef = useRef<HTMLSpanElement>(null);
    const [dateLeft, setDateLeft] = useState(0);
    const screenWidthType = useScreenWidthType();

    const isExtraLargeScreen = screenWidthType === ScreenWidthType.EXTRA_LARGE;

    const handleMouseEnter = () => setIsHovering(true);
    const handleMouseLeave = () => setIsHovering(false);

    const calcLeft = () => {
        return (dateLeft - descriptionLeft) * -1;
    };

    useEffect(() => {
        const updateDateLeft = () => {
            if (dateRef.current) {
                const rect = dateRef.current.getBoundingClientRect();
                setDateLeft(rect.left);
            }
        };

        updateDateLeft();

        window.addEventListener("resize", updateDateLeft);

        return () => window.removeEventListener("resize", updateDateLeft);
    }, [descriptionLeft, dateRef.current]);

    const calcDateToRender = () => {
        let dateToRender;

        for (let i = descriptionAndDate.estimatedDates.length - 1; i >= 0; i--) {
            if (descriptionAndDate.actualDates[0] !== descriptionAndDate.estimatedDates[i]) {
                dateToRender = descriptionAndDate.estimatedDates[i];
                return dateToRender;
            }
        }
    };

    const styleAdjustment = isExtraLargeScreen ? -20 : -90;
    const style = `${containerWidth + calcLeft() + styleAdjustment}px`;

    if (type === "ONE_DATE") {
        return (
            <span className={isDisabled ? "disabled-date" : "step-date"}>{actualDates[0] || estimatedDates[0]}</span>
        );
    }

    if (type === "TWO_DATES") {
        return (
            <span className="step-date">
                <div className="dates-row">
                    <span className="deleted-date">
                        {
                            descriptionAndDate.estimatedDates[
                                descriptionAndDate.actualDates[0] ? descriptionAndDate.estimatedDates.length - 1 : 0
                            ]
                        }
                    </span>
                    <RightSmallArrow />
                    <span className={isDisabled ? "disabled-date" : ""}>{lastDate}</span>
                </div>
            </span>
        );
    }

    if (type === "MULTIPLE_DATES") {
        return (
            <span className="step-date">
                <div className="dates-row">
                    <span
                        ref={dateRef}
                        className={isDisabled ? "disabled-date display-tooltip" : "display-tooltip"}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        {descriptionAndDate.actualDates[0] ===
                        descriptionAndDate.estimatedDates[descriptionAndDate.estimatedDates.length - 1]
                            ? calcDateToRender()
                            : descriptionAndDate.estimatedDates[
                                  descriptionAndDate.actualDates[0]
                                      ? descriptionAndDate.estimatedDates.length - 1
                                      : descriptionAndDate.estimatedDates.length - 2
                              ]}
                    </span>
                    <RightSmallArrow />
                    <span className={isDisabled ? "disabled-date" : ""}>{lastDate}</span>
                </div>
                {isHovering && <UpdateHistoryCard style={style} descriptionsAndDates={descriptionAndDate} />}
            </span>
        );
    }

    return null;
};
