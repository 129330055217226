import { FunctionComponent } from "react";
import { NoInvoicesLeftSvg, NoInvoicesRightSvg } from "../../assets/icons";

interface EmptyStateContainerProps {
    centerSvgComponent: JSX.Element;
    mainText: string;
    btnText?: string;
    onBtnClick?: () => void;
    showButton?: boolean;
}

const EmptyStateContainer: FunctionComponent<EmptyStateContainerProps> = ({
    centerSvgComponent,
    mainText,
    btnText,
    onBtnClick,
    showButton = true,
}) => {
    return (
        <section className={"EmptyStateContainer"}>
            <div className="EmptyStateImageContainer">
                <NoInvoicesLeftSvg className="NoInvoicesLeftSvg" />
                <div className="CenterSvgContainer">
                    {centerSvgComponent}
                    <div className="EmptyStateMessageView">{mainText}</div>
                    {showButton && (
                        <button className="EmptyStateBtn" onClick={onBtnClick}>
                            {btnText}
                        </button>
                    )}
                </div>
                <NoInvoicesRightSvg className="NoInvoicesRightSvg" />
            </div>
        </section>
    );
};

export default EmptyStateContainer;
