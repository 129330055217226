import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setPaymentStage } from "../../../store/reducers/invoicesSlice.store";
import { PaymentStage } from "../../../models/invoices/invoices.interfaces";
import styles from "./PayoneerForm.module.scss";
import { formatNumberWithDecimal } from "../../../helpers/services/DateFormatsService.service";
import UnicargoTextInput from "../../UnicargoTextInput/UnicargoTextInput";
import UnicargoTextInfoContainer from "../../UnicargoTextInfoContainer/UnicargoTextInfoContainer";
import GenericButton, { ButtonTypeStyles } from "../../buttons/GenericButton/GenericButton";
import {
    useGetUnicargoPayoneerAccountQuery,
    useLazySendPayoneerTransferReqQuery,
} from "../../../store/api-slices/paymentSlice.api";
import { IUnicargoPayoneerAccount } from "../../../models/invoices/payments.dto";
import UnicargoKeyValueTextInfoContainer from "../../UnicargoKeyValueTextInfoContainer/UnicargoKeyValueTextInfoContainer";
import UnicargoTextAreaInput from "../../UnicargoTextAreaInput/UnicargoTextAreaInput";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";

const PayoneerForm = () => {
    const dispatch = useAppDispatch();
    const invoicesState = useAppSelector((state) => state.rootReducer.invoices);
    const paymentStage = invoicesState.paymentStage;
    const customerCode = useAppSelector((state) => state.rootReducer.users.code);
    const selectedInvoices = invoicesState.selectedInvoices;
    const sumOfAmountDueToPay = selectedInvoices.reduce((acc, curr) => acc + curr.amountDue, 0);
    const [note, setNote] = useState("");
    const { data: unicargoPayoneerAccount, isFetching: isFetchingUnicargoPayoneerAccount } =
        useGetUnicargoPayoneerAccountQuery(customerCode);
    const [sendPayoneerTransferReq, { data: payoneerTransferRes, isFetching: isFetchingPayoneerTransferReq }] =
        useLazySendPayoneerTransferReqQuery();
    const [paymentTransferNumber, setPaymentTransferNumber] = useState<string | undefined>();
    const payoneerLinkUrl = "https://login.payoneer.com/";

    const onPayoneerLinkClick = () => tryTrackEvent("[Payments Page Popup]: Payoneer 'Payoneer' link clicked");

    const title = (
        <span>
            <span>Please pay through your </span>
            <a
                href={payoneerLinkUrl}
                target="_blank"
                rel="noreferrer"
                onClick={onPayoneerLinkClick}
                onAuxClick={onPayoneerLinkClick}
            >
                Payoneer
            </a>
            <span> account and fill in the transfer details</span>
        </span>
    );
    const subTitle = "These will assist us in processing your payment quicker";

    useEffect(() => {
        if (isFetchingPayoneerTransferReq || !payoneerTransferRes) return;
        if (payoneerTransferRes.successMessage) {
            tryTrackEvent("[Payments Page Popup]: Payoneer Success", {
                flow: "Payment",
                currentStage: paymentStage,
                newStage: PaymentStage.PaymentSuccessStage,
            });
            dispatch(setPaymentStage(PaymentStage.PaymentSuccessStage));
        }
        if (payoneerTransferRes.errorMessage) {
            console.error(payoneerTransferRes.errorMessage);
            tryTrackEvent("[Payments Page Popup]: Payoneer Fail", {
                flow: "Payment",
                currentStage: paymentStage,
                newStage: PaymentStage.ErrorInPaymentFlowStage,
            });
            dispatch(setPaymentStage(PaymentStage.ErrorInPaymentFlowStage));
        }
    }, [payoneerTransferRes]);

    return (
        <div className={styles.PayoneerForm}>
            <h1 className={styles.Title}>{title}</h1>
            <h2 className={styles.SubTitle}>{subTitle}</h2>
            <section className={styles.FormSection}>
                <UnicargoTextInfoContainer
                    label="Amount"
                    value={`${selectedInvoices[0].currencySign} ${
                        formatNumberWithDecimal(sumOfAmountDueToPay, 2) as string
                    }`}
                />
                {
                    <UnicargoKeyValueTextInfoContainer
                        value={
                            (unicargoPayoneerAccount ?? {
                                Message: "Failed to fetch Unicargo's payoneer account, please contact support",
                            }) as Record<string, string>
                        }
                        showLoader={isFetchingUnicargoPayoneerAccount}
                        legend={
                            {
                                payoneerAccount: "Payoneer Account",
                            } as Record<keyof IUnicargoPayoneerAccount, string>
                        }
                    />
                }
                <UnicargoTextInput
                    placeholderText="Payment transfer number"
                    value={paymentTransferNumber}
                    onValueChange={setPaymentTransferNumber}
                />
                <UnicargoTextAreaInput placeholderText="Note" value={note} onValueChange={setNote} />
                <section className={styles.ButtonsContainer}>
                    <GenericButton
                        text="Cancel"
                        onClick={() => {
                            tryTrackEvent("[Payments Page Popup]: Payoneer 'Cancel' button clicked", {
                                elementName: "Cancel",
                                flow: "Payment",
                                currentStage: paymentStage,
                                newStage: PaymentStage.SelectingPaymentMethodStage,
                            });
                            dispatch(setPaymentStage(PaymentStage.SelectingPaymentMethodStage));
                        }}
                        initialBtnTypeStyle={ButtonTypeStyles.HollowStyle}
                        className={styles.CancelButton}
                    />
                    <GenericButton
                        text="Submit"
                        onClick={() => {
                            if (!unicargoPayoneerAccount || !paymentTransferNumber) {
                                console.error("Missing data", {
                                    unicargoBankAccount: unicargoPayoneerAccount,
                                    transferNumber: paymentTransferNumber,
                                });
                                return;
                            }
                            sendPayoneerTransferReq({
                                customerCode,
                                clientAmountToPay: sumOfAmountDueToPay,
                                unicargoPayoneerAccount,
                                unicargoPayoneerTransferInfo: {
                                    transferNumber: paymentTransferNumber,
                                    confirmationNumber: undefined,
                                    note,
                                },
                                selectedInvoices: selectedInvoices.map(
                                    ({ invoiceNumber, amountDue, currencyAcronym }) => ({
                                        invoiceNumber,
                                        amountDue,
                                        currencyAcronym,
                                    }),
                                ),
                            });
                        }}
                        initialBtnTypeStyle={ButtonTypeStyles.LightGreenStyle}
                        className={styles.SubmitButton}
                        isDisabled={
                            !paymentTransferNumber || !unicargoPayoneerAccount || isFetchingUnicargoPayoneerAccount
                        }
                    />
                </section>
            </section>
        </div>
    );
};

export default PayoneerForm;
