import { makeStyles } from "@mui/styles";
import { COLORS, TYPOGRAPHY, UiSchemeV2 } from "../../../../../assets/data/ui";
import { theme } from "../../../../../assets/theme/theme";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        maxHeight: "100%",
        backgroundColor: "transparent",
        padding: "24px",
        gap: "0.6rem",
        color: `${UiSchemeV2.colors.greys.white}`,

        [theme.breakpoints.down("lg")]: {
            gap: "0.4rem",
        },
    },
    // Volumes container + section
    divider: {
        height: "70%",
        margin: "0 5px",
        borderLeft: `1px solid ${COLORS.light}`,
    },
    volumesContainer: {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        fontSize: TYPOGRAPHY.title,

        "& svg": {
            width: "16px",
            height: "16px",
            "& path": {
                fill: "currentColor",
            },
        },
    },
    volumesSection: {
        display: "flex",
        gap: "4px",
    },
    packagesSection: {
        display: "flex",
        alignItems: "center",
    },
    weightSection: {
        display: "flex",
        alignItems: "center",
    },
    cbmSection: {
        display: "flex",
        alignItems: "center",
    },
    shipmentLoadSection: {
        display: "flex",
        alignItems: "center",
    },
    // Locations container + section
    locationsContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "0.5rem",
        fontWeight: "600",
    },
    carrierDetails: {
        display: "flex",
    },
    locationsInnerContainer: {
        display: "flex",
        alignItems: "center",

        gap: "0.5rem",
        "& .carrierDetails": {
            display: "flex",
            "& .vesselDetailsContainer": {
                display: "flex",
                alignItems: "center",
                fontSize: TYPOGRAPHY.title,
                [theme.breakpoints.up("lg")]: {
                    marginRight: "2rem",
                },
                "& .carrierNumber": {},
                "& .divider": {
                    height: "70%",
                    margin: "0 5px",
                    borderLeft: `1px solid ${COLORS.light}`,
                },
            },
        },
    },
    vesselDetailsContainer: {
        display: "flex",
        alignItems: "center",
        fontSize: TYPOGRAPHY.title,
        [theme.breakpoints.up("lg")]: {
            marginRight: "2rem",
        },
    },
    carrierNumber: {},
    locationsSection: {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        fontWeight: "600",
        fontSize: TYPOGRAPHY.title,
        "& svg": {
            "& path": {
                fill: "currentColor",
            },
            "& circle": {
                stroke: "currentColor",
            },
        },
    },
    // Shipment status icons container
    statusIconsContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "1rem",
        flexWrap: "wrap",
        fontSize: TYPOGRAPHY.text,
    },
    // Inside getStatusIconsByShipment() function
    iconContainer: {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        color: UiSchemeV2.colors.greys.white,
        "& svg": {
            height: "1rem",
            width: "1rem",
            "& path": {
                stroke: "currentcolor",
            },
        },
    },
});

export default useStyles;
