import { IBannerData } from "../../components/RFQ/form-elements/autocomplete/autocompleteUtils";

export type StepperPropsType = {
    currentStep: RfqStep;
    numberOfSteps: number;
};

export type HeaderPropsType = {
    currentStep: RfqStep;
    updateFormState: React.Dispatch<React.SetStateAction<RfqStep>>;
};

export enum RfqStep {
    FirstStep = 1,
    SecondStep = 2,
    ThirdStep = 3,
    FinalStep = 4,
}

export interface IFirstPhaseFormInput {
    fromType: string;
    fromAddress: string;
    fromBannerData?: IBannerData;
    destinationType: string;
    destinationAddress: string;
    destinationBannerData?: IBannerData;
    shippingMethod: {
        air: boolean;
        ocean: boolean;
        land: boolean;
    };
}

export const firstPhaseFormInitialValue: IFirstPhaseFormInput = {
    fromType: "",
    fromAddress: "",
    destinationType: "",
    destinationAddress: "",
    shippingMethod: {
        air: false,
        ocean: false,
        land: false,
    },
};

export enum WeightUnit {
    KG = "KG",
    LB = "LB",
}

export enum VolumeUnit {
    CBM = "CBM",
    CFT = "CFT",
}

export enum HeightUnit {
    CM = "CM",
    IN = "IN",
}

export enum PalletType {
    SmallCm = "120X80",
    MediumCm = "100X120",
    LargeCm = "110X110",
    SmallIn = "42inX42in",
    MediumIn = "40inX48in",
}

export enum CargoArrangement {
    TotalShipment = "totalShipment",
    ShipmentPerUnit = "shipmentPerUnit",
}
export interface ISecondPhaseFormInput {
    cargoType: string;
    isTotalShipment: CargoArrangement;
    weight: string;
    weightUnit: WeightUnit;
    volume: string;
    volumeUnit: VolumeUnit;
    description: string;
    goodsInsurance: string[];
    quantity?: string;
    palletType?: string;
    height?: string;
    heightUnit?: HeightUnit;
    boxPerUnitWidth?: string;
    boxPerUnitLength?: string;
    boxPerUnitHeight?: string;
    boxPerUnitUnit?: string;
    totalValue?: string;
    fullVsPart?: string;
    measureType?: MeasureType;
    totalShipmentCalculation?: string;
}

export enum MeasureType {
    Metric,
    Imperial,
}

export const secondPhaseFormInitialValue: ISecondPhaseFormInput = {
    cargoType: "",
    isTotalShipment: CargoArrangement.ShipmentPerUnit,
    weight: "",
    weightUnit: WeightUnit.KG,
    volume: "",
    volumeUnit: VolumeUnit.CBM,
    description: "",
    goodsInsurance: [],
    quantity: "",
    palletType: "",
    height: "",
    heightUnit: HeightUnit.CM,
    boxPerUnitWidth: "",
    boxPerUnitLength: "",
    boxPerUnitHeight: "",
    boxPerUnitUnit: "",
    totalValue: "",
    fullVsPart: "",
    measureType: MeasureType.Metric,
};

export enum CargoReadyTime {
    Now = "Now!",
    TwoWeeks = "In 2 weeks",
    Later = "Later date",
}

export interface IThirdPhaseFormInput {
    isReadyTime: CargoReadyTime | "";
    additionalToAdd: string;
}

export const thirdPhaseFormInitialValue: IThirdPhaseFormInput = {
    isReadyTime: "",
    additionalToAdd: "",
};
export interface IFinalPhaseFormInput {
    email: string;
    fullName: string;
    phoneNumber?: any;
    customerCode?: string;
    customerName?: string;
    companyName?: string;
    isAcceptTerms: boolean;
    isUpdateContent: boolean;
}

export const finalPhaseFormInitialValue: IFinalPhaseFormInput = {
    email: "",
    fullName: "",
    phoneNumber: "",
    companyName: "",
    isAcceptTerms: false,
    isUpdateContent: false,
};

export interface IFinaltModalPhaseFormInput {
    shipped?: string;
    helpeType?: string[];
    couponCode?: string;
    fullName?: string;
}

export const finalModalPhaseFormInitialValue: IFinaltModalPhaseFormInput = {
    shipped: "",
    helpeType: [],
    couponCode: "",
};

export enum ShipmentMethod {
    ByAir,
    ByOcean,
    ByLand,
}

export interface IRfqForm {
    firstPhaseForm: IFirstPhaseFormInput;
    secondPhaseForm: ISecondPhaseFormInput;
    thirdPhaseForm: IThirdPhaseFormInput;
    finalPhaseForm?: IFinalPhaseFormInput;
    finalModalPhaseForm?: IFinaltModalPhaseFormInput;
    shipmentMethod: ShipmentMethod[];
}

export interface IRFQDetails {
    type?: ShipmentMethod[] | string[];
    from?: string;
    to?: string;
    fullFromAddress?: any;
    fullToAddress?: any;
    cargoType?: string;
    isTotalShipment?: CargoArrangement;
    weight?: string;
    weightUnit?: WeightUnit;
    volume?: string;
    volumeUnit?: VolumeUnit;
    description?: string;
    readyTime?: any;
    goodsInsurance?: string[];
    quantity?: string;
    palletType?: string;
    height?: string;
    heightUnit?: HeightUnit;
    boxPerUnitWidth?: string;
    boxPerUnitLength?: string;
    boxPerUnitHeight?: string;
    boxPerUnitUnit?: string;
    totalValue?: string;
    fullVsPart?: string;
    measureType?: MeasureType;
    desc?: any;
    totalShipmentCalculation?: string;
}

export interface PreviousStepsBannerProps {
    currentPhaseNumber: RfqStep;
    stepTitle: PreviousStepsTitles;
    stepNumber: RfqStep;
    details: IRFQDetails;
    setFormPhase: React.Dispatch<React.SetStateAction<RfqStep>>;
}

export enum PreviousStepsTitles {
    FirstPhase = "Shipping route",
    SecondPhase = "Cargo details",
    ThirdPhase = "Shipment schedule",
}

export interface IRadioOptions {
    label: string;
    value: any;
}

export interface ICheckboxOptions {
    label: JSX.Element | string;
    value: string;
}

export enum LocationType {
    Airport = "Airport",
    AmazonWarehouse = "Amazon warehouse",
    Commercial = "Commercial",
    Port = "Port",
    Residential = "Residential",
    UnicargoWarehouse = "Unicargo warehouse",
    AirportAndPort = "Port / Airport",
}

export type DropdownItemtype = {
    value: string;
    icon?: JSX.Element;
};
