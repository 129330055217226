import { CheckedIcon, ErrorIcon } from "../assets/icons";

interface PasswordValidation {
    condition: boolean;
    text: string;
}

interface PasswordValidationsProps {
    passwordValidations: {
        hasUpperCase: boolean;
        hasLowerCase: boolean;
        hasNumber: boolean;
        hasSymbol: boolean;
        isLongEnough: boolean;
    };
    isValidationsShown: boolean;
}

export const PasswordValidations: React.FC<PasswordValidationsProps> = ({
    passwordValidations,
    isValidationsShown,
}) => {
    const displayStyle = isValidationsShown ? { display: "grid" } : { display: "none" };

    const validations: PasswordValidation[] = [
        { condition: !passwordValidations.hasUpperCase, text: "1 Uppercase letter" },
        { condition: !passwordValidations.hasSymbol, text: "1 Symbol" },
        { condition: !passwordValidations.hasLowerCase, text: "1 Lowercase letter" },
        { condition: !passwordValidations.isLongEnough, text: "At least 10 characters" },
        { condition: !passwordValidations.hasNumber, text: "1 Number" },
    ];

    return (
        <div className="password-validations" style={displayStyle}>
            {validations.map((validation, index) => (
                <span key={index} className={validation.condition ? "error" : "checked"}>
                    {validation.condition ? <ErrorIcon /> : <CheckedIcon />}
                    {validation.text}
                </span>
            ))}
        </div>
    );
};
