import { FormControl, Tab, Tabs } from "@mui/material";
import { Controller } from "react-hook-form";
import { TextFieldInputProps } from "../form-elements/formInputProps";
import { MuiTheme } from "../../../helpers";
import { useStyles } from "./useStyles";
import { COLORS, UiSchemeV2 } from "../../../assets/data/ui";

export const TabsInput = ({ name, control, required, tabsDetails }: TextFieldInputProps) => {
    const classes = useStyles();
    return (
        <Controller
            name={name}
            rules={{ required: required }}
            control={control}
            render={({ field: { onChange, value } }) => (
                <MuiTheme>
                    <FormControl sx={{ width: "100%", borderBottom: 1, borderColor: UiSchemeV2.colors.greys.grey300 }}>
                        <Tabs
                            value={value}
                            onChange={(event, newValue) => {
                                onChange(newValue);
                            }}
                            aria-label="scrollable prevent tabs example"
                            variant="scrollable"
                            scrollButtons={false}
                            style={{ minHeight: "38px" }}
                            className={classes.tabsWrapper}
                            TabIndicatorProps={{ style: { background: COLORS.medium } }}
                        >
                            <Tab
                                key={tabsDetails?.firstTabs.label}
                                label={tabsDetails?.firstTabs.label}
                                className={classes.toggleButtonWrapper}
                                value={tabsDetails?.firstTabs.value}
                            />
                            <Tab
                                key={tabsDetails?.secondTabs.label}
                                label={tabsDetails?.secondTabs.label}
                                className={classes.toggleButtonWrapper}
                                value={tabsDetails?.secondTabs.value}
                            />
                        </Tabs>
                    </FormControl>
                </MuiTheme>
            )}
        />
    );
};
