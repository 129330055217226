import { useState, useRef, useEffect } from "react";
import { Button, FormControlLabel, Popover, Radio, RadioGroup } from "@mui/material";
import { ExportExcelIcon } from "../../assets/icons";
import { useLazyGetInvoicesDataForDownloadByCustomerCodeQuery } from "../../store/api-slices/invoicesSlice.api";
import { UiSchemeV2 } from "../../assets/data/ui";
import { ISummaryData } from "../../models/http/ISummaryData";
import UnicargoTooltip from "../UnicargoTooltip/UnicargoTooltip";
import { IInvoiceSlice } from "../../store/reducers/invoicesSlice.store";
import { tryTrackEvent } from "../../helpers/services/MixPanelService";

interface IProps {
    invoicesState: IInvoiceSlice;
    summaryData?: ISummaryData;
    customerCode: string;
}

const radioButtonOptions = {
    MAIN_ITEMS_ONLY: "MAIN_ITEMS_ONLY",
    INCLUDE_CHARGE_TYPE: "INCLUDE_CHARGE_TYPE",
};

const UnicargoExportBtn = ({ invoicesState, customerCode, summaryData }: IProps) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const numOfInvoices = summaryData?.invoicesCount;
    const [selectedOption, setSelectedOption] = useState(radioButtonOptions.MAIN_ITEMS_ONLY);
    const buttonRef = useRef(null);
    const [getInvoicesData, { isError, isFetching }] = useLazyGetInvoicesDataForDownloadByCustomerCodeQuery();
    const [shouldShowError, setShouldShowError] = useState(false);

    const shouldSendEmail = summaryData && summaryData.invoiceLinesCount > 5000;

    useEffect(() => {
        if (isFetching) return;
        if (isError) {
            handleError();
        }
    }, [isError, isFetching]);

    const handleError = () => {
        tryTrackEvent("[Payments Page]: Error Exporting file", {
            numOfInvoices: numOfInvoices?.toString() ?? "0",
        });
        setShouldShowError(true);
        setTimeout(() => {
            setShouldShowError(false);
        }, 5000);
    };

    const handleOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleButtonPress = () => {
        const isComplex = selectedOption === radioButtonOptions.INCLUDE_CHARGE_TYPE;
        const exportType = isComplex ? "Extended" : "Summary";
        tryTrackEvent(`[Payments Page]: Export ${exportType} by ${shouldSendEmail ? "Email" : "Download"}`);
        getInvoicesData({
            customerCode,
            params: {
                ...invoicesState,
                sortOrder: invoicesState.sort.sortOrder,
                sortType: invoicesState.sort.sortType,
                isComplex,
            },
        });

        handleClose();
    };

    const errorElement = <div style={{ fontSize: "10px" }}>Error downloading file, Please try again later!</div>;

    const exportBtn = (
        <div>
            <Button
                style={{
                    background: "white",
                    border: `1px solid ${UiSchemeV2.colors.primaryDark}`,
                    display: "flex",
                    width: "101px",
                    height: "32px",
                    padding: "5px 10px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px",
                    flexShrink: "0",
                    boxShadow: "none",
                    fontSize: "13px",
                }}
                ref={buttonRef}
                variant="contained"
                startIcon={<ExportExcelIcon />}
                onClick={handleOpen}
            >
                Export
            </Button>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        borderRadius: "16px",
                    },
                }}
                style={{
                    marginTop: "10px",
                }}
            >
                <div
                    className="ExportBtnPopoverContent"
                    style={{
                        width: "200px",
                        padding: "10px 20px",
                        flexShrink: "0",
                        background: "white",
                        borderRadius: "16px",
                        border: `1px solid ${UiSchemeV2.colors.greys.grey100}`,
                        boxShadow: "0px 4px 10px 0px rgba(51, 51, 51, 0.12)",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                    }}
                >
                    <RadioGroup value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                        <FormControlLabel
                            value={radioButtonOptions.MAIN_ITEMS_ONLY}
                            control={<Radio />}
                            label="Summary"
                            style={{ marginRight: "0" }}
                            componentsProps={{ typography: { fontSize: "14px" } }}
                        />
                        <FormControlLabel
                            value={radioButtonOptions.INCLUDE_CHARGE_TYPE}
                            control={<Radio />}
                            label="Extended"
                            style={{ marginRight: "0" }}
                            componentsProps={{ typography: { fontSize: "14px" } }}
                        />
                    </RadioGroup>

                    <Button
                        style={{ margin: 0, width: shouldSendEmail ? "118px" : "92px", height: "32px" }}
                        variant="contained"
                        onClick={handleButtonPress}
                        disabled={isFetching || !numOfInvoices}
                    >
                        {shouldSendEmail ? "Send To Email" : "Download"}
                    </Button>
                </div>
            </Popover>
        </div>
    );

    return (
        <div>
            <UnicargoTooltip
                isOpen={shouldShowError}
                popperChildComponent={errorElement}
                child={exportBtn}
                childClassName="childClassName"
                popperClassName="popperClassName"
            />
        </div>
    );
};

export default UnicargoExportBtn;
