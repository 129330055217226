import { useEffect, useMemo, useState } from "react";
import { ArrowNotificationIcon, HashtagIcon } from "../../../../assets/icons";
import { getShipmentCurrentStage, getPartialSteps } from "../../../../helpers/services/ShipmentService";
import { EShipmentStages, ETransportModes, IShipmentCard, IShipmentLocation, IShipmentStep } from "../../../../models";
import { Block } from "../../../blocks";
import { MobileStepper } from "../../../stepper/mobile/MobileStepper";
import {
    getCardColor,
    getHighlightedText,
    getShipmentCardIcon,
    stringToUpperCases,
} from "../shared/services/shipment-cards.service";
import useStyles from "./useStyle";

export const ShipmentCardMobile = ({ shipmentData, handleCardClicked: handleCardSelected, query }: IShipmentCard) => {
    const classes = useStyles();
    const { shipmentDetails, postDelivery, steps } = shipmentData;
    const {
        transportMode, // (Inland | Air | Ocean)
        shipmentNumber,
        shipmentName,
        fromLocation,
        destLocation,
        mainCarriageATA,
        moveTypeEnglishName,
    } = shipmentDetails;
    const { PODRecievedDate } = postDelivery;
    const shipmentStage = getShipmentCurrentStage(
        steps,
        ETransportModes[shipmentDetails.transportMode],
        PODRecievedDate,
        mainCarriageATA,
        shipmentDetails,
    );
    const cardColor = getCardColor(EShipmentStages[shipmentStage]);
    const shipmentIcon = getShipmentCardIcon(ETransportModes[transportMode]);
    const [seperatedSteps, setSeperatedSteps] = useState<IShipmentStep[]>([]);
    const [activeStep, setActiveStep] = useState(0);
    const [overflowFromStyle, setOverflowFromStyle] = useState<string>(classes.hiddenLocation);
    const [overflowDestStyle, setOverflowDestStyle] = useState<string>(classes.hiddenLocation);

    const truncateLocation = (
        event: React.MouseEvent<HTMLElement>,
        overflowStyle: string,
        setOverflowStyle: React.SetStateAction<any>,
    ) => {
        event.stopPropagation();
        if (overflowStyle === classes.hiddenLocation) {
            setOverflowStyle(classes.visibleLocation);
        } else setOverflowStyle(classes.hiddenLocation);
    };

    useEffect(() => {
        const [seperatedSteps, activeStep] = getPartialSteps(
            steps,
            mainCarriageATA,
            transportMode,
            moveTypeEnglishName,
        );
        setSeperatedSteps(seperatedSteps);
        setActiveStep(activeStep);
    }, [steps]);

    const handleOnClick = () => {
        handleCardSelected(shipmentData);
    };

    const memoizedFromLocation = useMemo<IShipmentLocation>(() => {
        return {
            city: stringToUpperCases(shipmentDetails.fromLocation?.city),
            country: shipmentDetails.fromLocation?.country,
        };
    }, [fromLocation]);

    const memoizedDestLocation = useMemo<IShipmentLocation>(() => {
        return {
            city: stringToUpperCases(shipmentDetails.destLocation?.city),
            country: shipmentDetails.destLocation?.country,
        };
    }, [destLocation]);

    return (
        <Block className={classes.root} onClick={() => handleOnClick()}>
            <div className={classes.rootInnerContainer} style={{ borderLeftColor: cardColor }}>
                <div className={classes.headContainer}>
                    <div className={classes.headTitleContainer}>
                        <div className={classes.stageSection} style={{ color: cardColor }}>
                            {shipmentStage}
                        </div>
                        <div className={classes.locationsSection}>
                            <p
                                className={overflowFromStyle}
                                onClick={(e) => truncateLocation(e, overflowFromStyle, setOverflowFromStyle)}
                            >
                                {query && memoizedFromLocation.city
                                    ? getHighlightedText(memoizedFromLocation.city, query)
                                    : memoizedFromLocation.city}
                            </p>
                            {<ArrowNotificationIcon />}
                            <p
                                className={overflowDestStyle}
                                onClick={(e) => truncateLocation(e, overflowDestStyle, setOverflowDestStyle)}
                            >
                                {query && memoizedDestLocation.city
                                    ? getHighlightedText(memoizedDestLocation.city, query)
                                    : memoizedDestLocation.city}
                            </p>
                        </div>
                    </div>
                    <div className={classes.headContent}>
                        <div className={classes.shipmentNameSection}>
                            <div className={classes.shipmentNameIcon}>{shipmentIcon}</div>
                            <div className={classes.shipmentName}>
                                <div className={classes.shipmentNumber}>
                                    {query ? getHighlightedText(shipmentNumber, query) : shipmentNumber}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.stepperContainer}>
                    <MobileStepper
                        steps={seperatedSteps}
                        activeStep={activeStep}
                        fromLocation={memoizedFromLocation}
                        destLocation={memoizedDestLocation}
                        transportMode={ETransportModes[transportMode]}
                        shipmentDetails={shipmentDetails}
                    />
                </div>
            </div>
        </Block>
    );
};
