import React, { useState } from "react";
import { Button } from "@aws-amplify/ui-react";
import { Dialog } from "@mui/material";
import ContactUsForm from "../components/contactus/ContactUs";
import { EmailIcon } from "../assets/icons";

const HelpButtonDialog = () => {
    const [isContactUsOpen, setIsContactUsOpen] = useState(false);

    return (
        <>
            <Button className="help-button" onClick={() => setIsContactUsOpen(true)}>
                Need help? <EmailIcon /> <span>Email us</span>
            </Button>
            <Dialog open={isContactUsOpen} onClose={() => setIsContactUsOpen(false)}>
                <ContactUsForm setIsOpen={setIsContactUsOpen} />
            </Dialog>
        </>
    );
};

export default HelpButtonDialog;
