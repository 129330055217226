import React, { useState } from "react";
import { Box, FormControl, MenuItem, Select, SxProps } from "@mui/material";
import { Controller } from "react-hook-form";
import { DropdownInputProps } from "../formInputProps";
import { COLORS, UiSchemeV2 } from "../../../../assets/data/ui";
import "./styles.scss";
import { MuiTheme } from "../../../../helpers";
import { DropdownItemtype } from "../../../../models/rfq/rfq-types";
import { menuPropsStyles } from "./formInputDropdownUtils";
import useDidMountEffect from "../../../../pages/rfq/hooks/useDidMountEffect";
import { ArrowDown } from "../../../../assets/icons";

export const FormInputDropdown: React.FC<DropdownInputProps> = ({
    name,
    control,
    items,
    required,
    disabled,
    width,
    label,
    setFormValue,
    nameFieldAutocomplete,
}) => {
    const [valueColor, setValueColor] = useState<string>(COLORS.main);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [selectedIcon, setSelectedIcon] = useState<JSX.Element>();

    useDidMountEffect(() => {
        setSelectedIcon(<></>);
    }, [items]);

    const sxTypes: SxProps = {
        color: valueColor,
        fontSize: "14px",
        maxWidth: width ? width : "100%",
        ".MuiOutlinedInput-notchedOutline": {
            border: "none",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
            borderRadius: "16px",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${COLORS.primaryDark}`,
            borderRadius: "16px",
            paddingRight: "20px",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${COLORS.veryLight}`,
        },
        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: COLORS.light,
        },
        "& svg": {
            right: "9px",
        },
        "& > .Mui-disabled": {
            "-webkit-text-fill-color": `${UiSchemeV2.colors.greys.grey300} !important`,
        },
    };

    const generateSingleOptions = () => {
        return items.map((option: DropdownItemtype) => {
            return (
                <MenuItem
                    style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        paddingRight: "0",
                        fontSize: "0.8rem",
                        fontWeight: "400",
                        lineHeight: "17px",
                        color: COLORS.primaryDark,
                    }}
                    key={option.value}
                    value={option.value}
                >
                    {option.icon}
                    {option.value}
                </MenuItem>
            );
        });
    };
    const setIconBydDropdownType = (value: string) => {
        if (value !== "Location type*") {
            const option = items.filter((option: DropdownItemtype) => option.value === value)[0];
            if (option) {
                setSelectedIcon(option.icon);
            }
        }
    };

    return (
        <MuiTheme>
            <FormControl sx={{ width: width ? width : "100%" }}>
                <Controller
                    control={control}
                    name={name}
                    rules={{ required: required }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Select
                            labelId="dropdown-label"
                            id="dropdown-select"
                            onChange={(e) => {
                                onChange(e);
                                if (nameFieldAutocomplete && setFormValue) {
                                    setFormValue(nameFieldAutocomplete, "");
                                }
                            }}
                            value={value}
                            disabled={disabled}
                            displayEmpty
                            IconComponent={ArrowDown}
                            error={!!error}
                            renderValue={(value) => {
                                return (
                                    <Box
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                            color: COLORS.primaryDark,
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {selectedIcon ? selectedIcon : setIconBydDropdownType(value)}
                                        <span>{value || label}</span>
                                    </Box>
                                );
                            }}
                            MenuProps={menuPropsStyles}
                            onClose={() => {
                                setValueColor(COLORS.main);
                                setIsMenuOpen((prevValue) => !prevValue);
                            }}
                            onOpen={() => {
                                setValueColor(value ? COLORS.main : COLORS.system.desitnation);
                                setIsMenuOpen((prevValue) => !prevValue);
                            }}
                            sx={sxTypes}
                        >
                            {generateSingleOptions()}
                        </Select>
                    )}
                />
            </FormControl>
        </MuiTheme>
    );
};
