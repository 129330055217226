import { DocIcon, ExportIcon } from "../../../../../../../assets/icons";
import { tryTrackEvent } from "../../../../../../../helpers/services/MixPanelService";
import { IDoc } from "../../../../../../../models/cards/IDoc";
import { useLazyGetShipmentDocumentQuery } from "../../../../../../../store/api-slices/shipmentsSlice.api";
import UnicargoCheckbox from "../../../../../../UnicargoCheckbox/UnicargoCheckbox";
import Block from "../../../../../block/Block";
import useStyles from "./useStyles";

interface IDocument {
    doc: IDoc;
    shipmentNumber: string;
    customerCode: string;
    isSelected: boolean;
    toggleDocumentSelection: (documentId: string) => void;
}

export const Document = ({ doc, customerCode, shipmentNumber, isSelected, toggleDocumentSelection }: IDocument) => {
    const [getShipmentDocument, { data, isSuccess }] = useLazyGetShipmentDocumentQuery();
    const classes = useStyles();

    const formattedDate = doc.ReceivedDate
        ? new Date(doc.ReceivedDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "2-digit",
          })
        : "";

    const onShipmentDocumentClick = async () => {
        const _ = await getShipmentDocument({
            customerCode,
            shipmentNumber,
            documentSecurityId: doc.SecurityId,
            fileExtension: doc.FileExtension,
            fileName: doc.Name,
        });

        tryTrackEvent(
            "[Shipment drawer]: Document component clicked (to view/download, it is under 'My Document' tab)",
        );
    };

    return (
        <Block className={classes.docContainer} key={doc.DocumentId}>
            <UnicargoCheckbox isChecked={isSelected} onClick={() => toggleDocumentSelection(doc.SecurityId)} />
            <div className={classes.docContent}>
                <DocIcon />
                <span className={classes.docName}>{doc.Name}</span>
            </div>
            <ExportIcon className={classes.exportIcon} onClick={async () => await onShipmentDocumentClick()} />
        </Block>
    );
};
