import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    select: {   
        
        "& fieldset": {
            border: "none",
            display: "none",
        },
    },
    numericInput: {
        "& input[type=number]": {
            "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
});
