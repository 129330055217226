import { makeStyles } from "@mui/styles";
import { theme } from "../../assets/theme/theme";

const useStyles = makeStyles({
    OverviewPage: {
        [theme.breakpoints.down("lg")]: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            padding: "1rem",
            gap: "1rem",
        },
        [theme.breakpoints.up("lg")]: {
            display: "grid",
            height: "100%",
            gridTemplateRows: "3rem 27% 1fr",
            gridTemplateColumns: "repeat(4, 1fr) 30%",
            gridTemplateAreas: `
            "h h h h h"
            "a a a a c"
            "b b b b d"
            "b b b b d"
            "b b b b e"
            `,
            gridGap: "1.5rem",
        },
    },

    OverviewPageNoNotifications: {
        [theme.breakpoints.up("lg")]: {
            gridTemplateRows: "3rem 9% 18% 1fr",
            gridTemplateAreas: `
            "h h h h h"
            "a a a a c"
            "b b b b c"
            "b b b b d"
            "b b b b d"
            "b b b b e"
            `,
        },
    },

    // page grid sections
    notificationsSection: {
        gridArea: "a",
    },
    activeShipmentsSection: {
        gridArea: "b",
    },
    RFQBlockSection: {
        [theme.breakpoints.down("lg")]: {
            display: "none",
        },
        gridArea: "c",
    },
    newsBlockSection: {
        gridArea: "d",
        width: "100%",
    },
    assistanceBlockSection: {
        // TODO: change name to "contact us".
        gridArea: "e",
    },
});

export default useStyles;
