import { makeStyles } from "@mui/styles";
import { COLORS } from "../../assets/data/ui";
import { theme } from "../../assets/theme/theme";

const useStyles = makeStyles({
    formWrapper: {
        display: "flex",
        gap: "10px",
        flexDirection: "column",
        padding: "30px",
        margin: "0px auto",
    },
    formbox: {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
    },
    formLine: {
        display: "flex",
        gap: "16px",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    headerWrapper: {
        margin: "0",
        textAlign: "center",
        padding: "10px",
        position: "relative",
    },
    header: {
        whiteSpace: "nowrap",
        color: COLORS.primaryDark,
    },
    phone: {
        width: "100%",
    },
    button: {
        width: "80px",
        borderRadius: "20px",
        alignSelf: "center",
        backgroundColor: COLORS.success,
        color: COLORS.primaryDark,
        "&:hover": {
            backgroundColor: COLORS.success,
        },
    },
    successModal: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "36px 27px 24px",
    },
    successModalIcon: {
        marginTop: "27px",
    },
    successModalText: {
        marginTop: "27px",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "17px",
        textAlign: "center",
        width: "300px",
        color: COLORS.main,
    },
});

export default useStyles;
