import { formatNumberWithDecimal } from "../../../../helpers/services/DateFormatsService.service";
import { roundNumber } from "../../../cards/shipment-cards/shared/services/shipment-cards.service";

export const getGrossWeightInKGFormattedValue = (grossWeightInKG: number | null) => {
    let grossWeightInKGFormattedValue = formatNumberWithDecimal(
        roundNumber(grossWeightInKG as unknown as number, 2),
        2,
    ) as string;
    if (grossWeightInKGFormattedValue.endsWith(".00"))
        grossWeightInKGFormattedValue = grossWeightInKGFormattedValue.slice(0, -3);
    return grossWeightInKGFormattedValue;
};
