import "./TextButton.scss";
import { MuiTheme } from "../../../helpers";
import { ITextButton } from "../../../models/buttons";
import BaseButton from "../base-button/BaseButton";

const TextButton = ({ className, ...rest }: ITextButton) => {
    return (
        <MuiTheme>
            <BaseButton {...rest} variant="text" className={`TextButton ${className ? className : ""}`} />
        </MuiTheme>
    );
};

export default TextButton;
