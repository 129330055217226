import { Autocomplete, TextField, debounce } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { COLORS, UiSchemeV2 } from "../../../../assets/data/ui";
import { MuiTheme } from "../../../../helpers";
import { LocationType } from "../../../../models/rfq/rfq-types";
import { AutocompleteInputProps } from "../formInputProps";
import {
    airportAndport,
    airportData,
    amazonWarehouseData,
    generateLabel,
    menuPropsSx,
    portData,
    unicargoWarehouseData,
} from "./autocompleteUtils";
import useStyles from "./useStyles";
import { ArrowDown } from "../../../../assets/icons";

export const FormInputAutocomplete: React.FC<AutocompleteInputProps> = ({
    name,
    control,
    label,
    required,
    locationType,
    disabled,
}) => {
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [inputValue, setValue] = useState<string>("");
    const autocompleteService = new window.google.maps.places.AutocompleteService();
    const classes: any = useStyles();

    useEffect(() => {
        if (locationType === LocationType.Commercial || locationType === LocationType.Residential) {
            const fetchFromGoogleMaps = debounce((address: string) => {
                autocompleteService.getPlacePredictions({ input: address }, (predictions, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        if (predictions) {
                            setSuggestions(predictions);
                        }
                    } else {
                        setSuggestions([]);
                    }
                });
            }, 100);

            fetchFromGoogleMaps(inputValue);
        }
    }, [inputValue]);

    const filterOptions = (options: any) => {
        return locationType === LocationType.Commercial || locationType === LocationType.Residential
            ? options
            : options.filter((option: any) => {
                  return generateLabel(locationType as LocationType, option)
                      .toLocaleLowerCase()
                      .includes(inputValue.toLocaleLowerCase());
              });
    };

    useEffect(() => {
        switch (locationType) {
            case LocationType.AirportAndPort:
                {
                    setSuggestions(airportAndport);
                }
                break;
            case LocationType.Airport:
                {
                    setSuggestions(airportData);
                }
                break;
            case LocationType.AmazonWarehouse:
                {
                    setSuggestions(amazonWarehouseData);
                }
                break;
            case LocationType.Port:
                {
                    setSuggestions(portData);
                }
                break;
            case LocationType.UnicargoWarehouse:
                {
                    setSuggestions(unicargoWarehouseData);
                }
                break;
            case LocationType.Commercial:
                {
                    setSuggestions([]);
                }
                break;
            case LocationType.Residential:
                {
                    setSuggestions([]);
                }
                break;

            default:
                break;
        }
    }, [locationType]);

    return (
        <MuiTheme>
            <Controller
                name={name}
                rules={{ required: required }}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <Autocomplete
                        disabled={disabled}
                        autoSelect
                        popupIcon={<ArrowDown />}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        value={value}
                        options={suggestions}
                        getOptionLabel={(option) => option && generateLabel(locationType as LocationType, option)}
                        filterOptions={filterOptions}
                        noOptionsText="No locations"
                        onChange={(event, value) => onChange(value)}
                        onInputChange={(e, v) => {
                            setValue(v);
                        }}
                        componentsProps={{
                            paper: {
                                sx: menuPropsSx,
                            },
                        }}
                        ListboxProps={{
                            sx: {
                                maxHeight: "200px",
                            },
                        }}
                        classes={!value && classes}
                        renderInput={(params) => (
                            <div>
                                <TextField
                                    {...params}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            padding: "16px 9px 0px 9px",
                                            fontSize: "14px",
                                            cursor: "pointer",
                                            width: "250px",
                                        },
                                        "& .MuiOutlinedInput-input": {
                                            margin: "0 auto",
                                            fontSize: "14px",
                                            color: COLORS.main,
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            display: "none",
                                        },
                                        border: "1px solid transparent",
                                        borderColor: open ? COLORS.veryLight : "transparent",
                                        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
                                        borderRadius: "16px",
                                        "&:hover": {
                                            border: `1px solid ${COLORS.veryLight}`,
                                        },
                                        "&:focus-within": {
                                            border: `1px solid ${COLORS.primaryDark}`,
                                        },
                                        "& .MuiSvgIcon-root": {
                                            color: COLORS.primaryDark,
                                            width: "12px",
                                            height: "12px",
                                        },
                                        "& .MuiAutocomplete-endAdornment": {
                                            "& .MuiAutocomplete-clearIndicator": {
                                                marginRight: "10px",
                                            },
                                        },
                                    }}
                                    key={params.id}
                                    label={label + "*"}
                                    InputLabelProps={{
                                        variant: "filled",
                                        color: "success",
                                        style: {
                                            fontSize: "14px",
                                            color:
                                                disabled || inputValue != ""
                                                    ? UiSchemeV2.colors.greys.grey300
                                                    : COLORS.main,
                                        },
                                    }}
                                />
                            </div>
                        )}
                    />
                )}
            />
        </MuiTheme>
    );
};
