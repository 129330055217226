import { FormControl } from "@mui/material";
import { MuiTheme } from "../../../helpers";
import { useStyles } from "./useStyles";
import { FormInputProps } from "../form-elements/formInputProps";
import { Controller } from "react-hook-form";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { sxPhone } from "./PhoneInputUtil";
import { COLORS, ScreenWidthType } from "../../../assets/data/ui";
import { useScreenWidthType } from "../../../helpers/custom-hooks/useScreenWidthType";

const PhoneInput: React.FC<FormInputProps> = ({
    name,
    control,
    required = false,
    dropdownWidth,
    dropdownWidthMobile = "calc(100% - 40px)",
}) => {
    const classes = useStyles();
    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;

    const menuPropsStyles = {
        PaperProps: {
            sx: {
                width: `${isMobile ? dropdownWidthMobile : dropdownWidth}`,
                padding: "8px",
                borderRadius: "16px",
                boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
                marginTop: "10px",
                overflow: "hidden",
            },
        },
        MenuListProps: {
            sx: {
                maxHeight: "200px",
                overflowY: "auto",
                "& .MuiTypography-root": {
                    fontSize: "0.8rem",
                    color: COLORS.main,
                },
                "& .MuiListItemIcon-root": {
                    minWidth: "1px !important",
                },
                "& .MuiMenuItem-gutters": {
                    padding: "6px",
                },
                "& .MuiListItemText-primary": {
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                },
            },
        },
    };
    return (
        <MuiTheme>
            <FormControl className={classes.inputWrapper} style={{ position: "relative" }}>
                <div className={classes.phoneCover} />
                <Controller
                    name={name}
                    control={control}
                    rules={{ validate: required ? matchIsValidTel : undefined }}
                    render={({ field }) => (
                        <MuiTelInput
                            {...field}
                            forceCallingCode
                            defaultCountry={"US"}
                            focusOnSelectCountry
                            disableFormatting
                            flagSize="small"
                            sx={sxPhone}
                            MenuProps={menuPropsStyles}
                            autoComplete="off"
                        />
                    )}
                />
            </FormControl>
        </MuiTheme>
    );
};

export default PhoneInput;
