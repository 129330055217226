export const sxPhone = {
    "& .MuiTypography-root": {
        paddingInlineStart: "15px",
    },
    ".MuiOutlinedInput-root": {
        paddingLeft: "8px",
        fontSize: "14px",
    },
    "&:focus-within": {
        "&:not(.SearchBarTextField)": {
            boxShadow: "none",
            borderRadius: "16px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "transparent !important",
        },
    },
};
