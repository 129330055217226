import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../store";
import useStyles from "./useStyles";
import { Box, Dialog, FormControl } from "@mui/material";
import PreviousStepsBanner from "../../../components/RFQ/PreviousStepsBanner/PreviousStepsBanner";
import { IFinalPhaseFormInput, PreviousStepsTitles, RfqStep } from "../../../models/rfq/rfq-types";
import { ButtonText, HeaderTexts, checkboxOptionsSecond, validateEmail } from "./finalPhaseFormUtils";
import PhoneInput from "../../../components/RFQ/Phone-input/PhoneInput";
import { FormInputTextField } from "../../../components/RFQ/form-elements/text-field/formInputTextField";
import { FormInputMultiCheckbox } from "../../../components/RFQ/form-elements/checkbox/FormInputCheckbox";
import { ContainedButton } from "../../../components";
import { resetRfqForms, updateFinalPhase } from "../../../store/reducers/rfqSlice.store";
import RfqFinalModal from "../../../components/RFQ/modal/submit-modal/RfqFinalModal";
import { useModal } from "../hooks/useModal";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { AuthGuard } from "../../../guards";
import { ShipmentMethodType } from "../RfqPageUtils";
import RfqSuccessModal from "../../../components/RFQ/modal/success-modal/RfqSuccessModal";
import TermsAndConditions from "../../../components/termsAndConditions/TermsAndConditions";
import SelectCustomerRfq from "../../../components/RFQ/select-customer-rfq/SelectCustomerRfq";
import { setUserId, tryTrackEvent } from "../../../helpers/services/MixPanelService";
import { sendGoogleAnalyticsEvent, EventMessages } from "../../../helpers/services/GoogleAnalyticsService.service";

const STRING_EMPTY = "";

const FinalPhaseForm: React.FC<{
    updateFormState: React.Dispatch<React.SetStateAction<RfqStep>>;
}> = ({ updateFormState }) => {
    const { firstPhaseForm, secondPhaseForm, thirdPhaseForm, finalPhaseForm, shipmentMethod } = useAppSelector(
        (state) => state.rootReducer.rfq,
    );
    const classes = useStyles();
    const [isTerms, setIsTerms] = useState<boolean>(false);
    const [istermsOpen, setIsTermsOpen] = useState<boolean>(false);
    const [isSelectCustomer, setIsSelectCustomer] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [isLogin, setIsLogin] = useState<boolean>(false);
    const [isOpenModal, toggleModal] = useModal(false);
    const [isOpenSuccessModal, toggleSuccessModal] = useModal(false);
    const navigate = useNavigate();
    const { authStatus } = useAuthenticator();
    const methods = useForm<IFinalPhaseFormInput>({
        defaultValues: {
            ...finalPhaseForm,
        },
    });
    const { handleSubmit, control, setValue } = methods;

    const onSubmit = (data: IFinalPhaseFormInput) => {
        const referrer = document.referrer;
        sendGoogleAnalyticsEvent(EventMessages.GetQuoteButtonClicked, referrer);

        if (validateEmail(data.email) !== true) {
            console.error("Email is not valid");
            return;
        }
        setUserId(data.email);
        tryTrackEvent("[Rfq - Step 4]: 'Get your quote' button clicked");

        dispatch(updateFinalPhase(data));
        toggleModal();
    };
    const handleSuccesModalClose = () => {
        toggleSuccessModal();
        dispatch(resetRfqForms());
        authStatus === "authenticated" ? navigate("/overview") : window.location.replace("https://www.unicargo.com");
    };
    const handleTermsAndConditionClicked = () => {
        setIsTermsOpen(true);
    };

    const checkboxOptionsFirst = [
        {
            label: (
                <span>
                    I understand and accept the{" "}
                    <span className={classes.temsAndConditionsLink} onClick={() => handleTermsAndConditionClicked()}>
                        terms and conditions
                    </span>
                </span>
            ),
            value: "isAcceptTerms",
        },
    ];
    const methodsTypes = shipmentMethod.map((method: number) => ShipmentMethodType[method]);

    return (
        <Box className={classes.formWrapper}>
            <>
                <PreviousStepsBanner
                    currentPhaseNumber={RfqStep.FinalStep}
                    setFormPhase={updateFormState}
                    stepNumber={RfqStep.FirstStep}
                    stepTitle={PreviousStepsTitles.FirstPhase}
                    details={{
                        type: methodsTypes,
                        from: firstPhaseForm.fromBannerData?.label as string,
                        to: firstPhaseForm.destinationBannerData?.label as string,
                        fullFromAddress: firstPhaseForm.fromAddress,
                        fullToAddress: firstPhaseForm.destinationAddress,
                    }}
                />
                <PreviousStepsBanner
                    currentPhaseNumber={RfqStep.FinalStep}
                    setFormPhase={updateFormState}
                    stepNumber={RfqStep.SecondStep}
                    stepTitle={PreviousStepsTitles.SecondPhase}
                    details={{
                        isTotalShipment: secondPhaseForm.isTotalShipment,
                        totalShipmentCalculation: secondPhaseForm.totalShipmentCalculation || STRING_EMPTY,
                        quantity: secondPhaseForm.quantity || STRING_EMPTY,
                        cargoType: secondPhaseForm.cargoType,
                        desc: secondPhaseForm.description,
                        weight: secondPhaseForm.weight,
                        weightUnit: secondPhaseForm.weightUnit,
                        volume: secondPhaseForm.volume,
                        volumeUnit: secondPhaseForm.volumeUnit,
                        fullVsPart: secondPhaseForm.fullVsPart || STRING_EMPTY,
                        palletType: secondPhaseForm.palletType || STRING_EMPTY,
                    }}
                />
                <PreviousStepsBanner
                    currentPhaseNumber={RfqStep.FinalStep}
                    setFormPhase={updateFormState}
                    stepNumber={RfqStep.ThirdStep}
                    stepTitle={PreviousStepsTitles.ThirdPhase}
                    details={{
                        readyTime: thirdPhaseForm.isReadyTime,
                    }}
                />
            </>
            <Box className={classes.headerWrapper}>
                <span className={classes.header}>{HeaderTexts.mainHeader}</span>
                {!isLogin && (
                    <span className={classes.subHeader}>
                        {HeaderTexts.subHeader}
                        <span
                            className={classes.textButton}
                            onClick={() => {
                                tryTrackEvent("[Rfq - Step 4 (Not Authenticated User Flow)]: Login link clicked");
                                setIsLogin(true);
                            }}
                        >
                            Log in
                        </span>
                    </span>
                )}
                {isLogin && (
                    <>
                        {!isSelectCustomer && <AuthGuard isRfqView={true} setNextStepRfqLogin={setIsSelectCustomer} />}
                        {isSelectCustomer && <SelectCustomerRfq />}
                        <span className="">
                            {HeaderTexts.backToDetails}
                            <span
                                className={classes.textButton}
                                onClick={() => {
                                    setIsLogin(false);
                                    setIsSelectCustomer(false);
                                }}
                            >
                                Back
                            </span>
                        </span>
                    </>
                )}
            </Box>
            {!isLogin && (
                <>
                    <Box className={classes.inputsWrapper}>
                        <FormControl className={classes.inputWrapper}>
                            <FormInputTextField
                                type="email"
                                size="md"
                                required={true}
                                name="email"
                                control={control}
                                label={"Email-address"}
                                validation={validateEmail}
                            />
                        </FormControl>
                        <FormControl className={classes.inputWrapper}>
                            <FormInputTextField
                                type="text"
                                size="md"
                                required={true}
                                name="fullName"
                                control={control}
                                label={"Full-name"}
                            />
                        </FormControl>
                        <FormControl className={classes.inputWrapper}>
                            <PhoneInput
                                required={false}
                                name="phoneInput"
                                control={control}
                                label={"Phone-Number"}
                                dropdownWidth="300px"
                            />
                        </FormControl>
                        <FormControl className={classes.inputWrapper}>
                            <FormInputTextField
                                type="text"
                                size="md"
                                required={true}
                                name="companyName"
                                control={control}
                                label={"Company-name"}
                            />
                        </FormControl>
                    </Box>
                    <Box className={classes.checkBoxWrapper}>
                        <Box className={classes.temsAndConditions}>
                            <FormInputMultiCheckbox
                                control={control}
                                name="isAcceptTerms"
                                required={true}
                                setIsTerms={setIsTerms}
                                label="insurance"
                                options={checkboxOptionsFirst}
                                setValue={setValue}
                            />
                        </Box>
                        <FormInputMultiCheckbox
                            control={control}
                            name="isUpdateContent"
                            required={false}
                            label="insurance"
                            options={checkboxOptionsSecond}
                            setValue={setValue}
                        />
                    </Box>
                </>
            )}

            {!isLogin && (
                <ContainedButton
                    disabled={!isTerms}
                    className={`${classes.button} ${classes.noBoxShadow}`}
                    onClick={handleSubmit(onSubmit)}
                >
                    {ButtonText}
                </ContainedButton>
            )}
            <Dialog maxWidth="md" open={istermsOpen} onClose={() => setIsTermsOpen(false)}>
                <TermsAndConditions />
            </Dialog>
            <RfqFinalModal isOpen={isOpenModal} setIsOpen={toggleModal} toggleSuccessModal={toggleSuccessModal} />
            <RfqSuccessModal isOpen={isOpenSuccessModal} setIsOpen={handleSuccesModalClose} />
        </Box>
    );
};

export default FinalPhaseForm;
