import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    firstPhaseFormInitialValue,
    IRfqForm,
    IFirstPhaseFormInput,
    ShipmentMethod,
    secondPhaseFormInitialValue,
    ISecondPhaseFormInput,
    thirdPhaseFormInitialValue,
    IThirdPhaseFormInput,
    finalPhaseFormInitialValue,
    IFinalPhaseFormInput,
    finalModalPhaseFormInitialValue,
} from "../../models/rfq/rfq-types";

const initialState: IRfqForm = {
    firstPhaseForm: firstPhaseFormInitialValue,
    secondPhaseForm: secondPhaseFormInitialValue,
    thirdPhaseForm: thirdPhaseFormInitialValue,
    finalPhaseForm: finalPhaseFormInitialValue,
    finalModalPhaseForm: finalModalPhaseFormInitialValue,
    shipmentMethod: [ShipmentMethod.ByAir],
};

const rfqSlice = createSlice({
    name: "rfq",
    initialState,
    reducers: {
        updateFirstPhase(state, action: PayloadAction<IFirstPhaseFormInput>) {
            state.firstPhaseForm = action.payload;
        },
        updateSecondPhase(state, action: PayloadAction<ISecondPhaseFormInput>) {
            state.secondPhaseForm = action.payload;
        },
        updateThirdPhase(state, action: PayloadAction<IThirdPhaseFormInput>) {
            state.thirdPhaseForm = action.payload;
        },
        updateFinalPhase(state, action: PayloadAction<IFinalPhaseFormInput>) {
            state.finalPhaseForm = action.payload;
        },

        updateShipmentMethod(state, action: PayloadAction<ShipmentMethod[]>) {
            state.shipmentMethod = action.payload;
        },

        resetRfqForms(state) {
            state.firstPhaseForm = firstPhaseFormInitialValue;
            state.secondPhaseForm = secondPhaseFormInitialValue;
            state.thirdPhaseForm = thirdPhaseFormInitialValue;
            state.finalPhaseForm = finalPhaseFormInitialValue;
            state.finalModalPhaseForm = finalModalPhaseFormInitialValue;
            state.shipmentMethod = [ShipmentMethod.ByAir];
        },
    },
});

// Export actions and reducer.
export const {
    updateFirstPhase,
    updateSecondPhase,
    updateShipmentMethod,
    updateThirdPhase,
    updateFinalPhase,
    resetRfqForms,
} = rfqSlice.actions;
export default rfqSlice.reducer;
