export const portsData = [
    {
        portCode: "AEAJM",
        portName: "Ajman",
        state: null,
        countryCode: "AE",
        countryName: "United Arab Emirates",
    },
    {
        portCode: "AEDAS",
        portName: "Das Island",
        state: null,
        countryCode: "AE",
        countryName: "United Arab Emirates",
    },
    {
        portCode: "AEDXB",
        portName: "Dubai (Port Rashid)",
        state: null,
        countryCode: "AE",
        countryName: "United Arab Emirates",
    },
    {
        portCode: "AEFJR",
        portName: "Fujairah",
        state: null,
        countryCode: "AE",
        countryName: "United Arab Emirates",
    },
    {
        portCode: "AEJEA",
        portName: "Jebel Dhanna/Ruwais",
        state: null,
        countryCode: "AE",
        countryName: "United Arab Emirates",
    },
    {
        portCode: "AEKLF",
        portName: "Khor Fakkan",
        state: null,
        countryCode: "AE",
        countryName: "United Arab Emirates",
    },
    {
        portCode: "AEMSA",
        portName: "Mina Saqr",
        state: null,
        countryCode: "AE",
        countryName: "United Arab Emirates",
    },
    {
        portCode: "AEMZD",
        portName: "Mina Zayed",
        state: null,
        countryCode: "AE",
        countryName: "United Arab Emirates",
    },
    {
        portCode: "ALDRZ",
        portName: "Durres (Durazzo)",
        state: null,
        countryCode: "AL",
        countryName: "Albania",
    },
    {
        portCode: "ALSAR",
        portName: "Sarande",
        state: null,
        countryCode: "AL",
        countryName: "Albania",
    },
    {
        portCode: "ALSHG",
        portName: "Shengjjin",
        state: null,
        countryCode: "AL",
        countryName: "Albania",
    },
    {
        portCode: "ALVOA",
        portName: "Vlore (Vlora, Vlone)",
        state: null,
        countryCode: "AL",
        countryName: "Albania",
    },
    {
        portCode: "AOCAB",
        portName: "Cabinda",
        state: null,
        countryCode: "AO",
        countryName: "Angola",
    },
    {
        portCode: "AOLAD",
        portName: "Luanda",
        state: null,
        countryCode: "AO",
        countryName: "Angola",
    },
    {
        portCode: "AOLOB",
        portName: "Lobito",
        state: null,
        countryCode: "AO",
        countryName: "Angola",
    },
    {
        portCode: "AOMSZ",
        portName: "Namibe",
        state: null,
        countryCode: "AO",
        countryName: "Angola",
    },
    {
        portCode: "AOPBN",
        portName: "Porto Amboim",
        state: null,
        countryCode: "AO",
        countryName: "Angola",
    },
    {
        portCode: "AOSOQ",
        portName: "Soyo",
        state: null,
        countryCode: "AO",
        countryName: "Angola",
    },
    {
        portCode: "ARBHI",
        portName: "Bahia Blanca",
        state: null,
        countryCode: "AR",
        countryName: "Argentina",
    },
    {
        portCode: "ARBUE",
        portName: "Buenos Aires",
        state: null,
        countryCode: "AR",
        countryName: "Argentina",
    },
    {
        portCode: "ARCOU",
        portName: "Concepcion del Uruguay",
        state: null,
        countryCode: "AR",
        countryName: "Argentina",
    },
    {
        portCode: "ARCRD",
        portName: "Comodoro Rivadavia",
        state: null,
        countryCode: "AR",
        countryName: "Argentina",
    },
    {
        portCode: "ARMDQ",
        portName: "Mar del Plata",
        state: null,
        countryCode: "AR",
        countryName: "Argentina",
    },
    {
        portCode: "ARNEC",
        portName: "Necochea",
        state: null,
        countryCode: "AR",
        countryName: "Argentina",
    },
    {
        portCode: "ARPMY",
        portName: "Puerto Madryn",
        state: null,
        countryCode: "AR",
        countryName: "Argentina",
    },
    {
        portCode: "ARRGL",
        portName: "Rio Gallegos",
        state: null,
        countryCode: "AR",
        countryName: "Argentina",
    },
    {
        portCode: "ARROS",
        portName: "Rosario",
        state: null,
        countryCode: "AR",
        countryName: "Argentina",
    },
    {
        portCode: "ARSFN",
        portName: "Santa Fe",
        state: null,
        countryCode: "AR",
        countryName: "Argentina",
    },
    {
        portCode: "ARUSH",
        portName: "Ushuaia",
        state: null,
        countryCode: "AR",
        countryName: "Argentina",
    },
    {
        portCode: "ASPPG",
        portName: "Pago Pago",
        state: null,
        countryCode: "AS",
        countryName: "American Samoa",
    },
    {
        portCode: "AUBNE",
        portName: "Brisbane",
        state: null,
        countryCode: "AU",
        countryName: "Australia",
    },
    {
        portCode: "AUCNS",
        portName: "Cairns",
        state: null,
        countryCode: "AU",
        countryName: "Australia",
    },
    {
        portCode: "AUDPO",
        portName: "Devonport",
        state: null,
        countryCode: "AU",
        countryName: "Australia",
    },
    {
        portCode: "AUDRW",
        portName: "Darwin",
        state: null,
        countryCode: "AU",
        countryName: "Australia",
    },
    {
        portCode: "AUFRE",
        portName: "Fremantle",
        state: null,
        countryCode: "AU",
        countryName: "Australia",
    },
    {
        portCode: "AUGEX",
        portName: "Geelong",
        state: null,
        countryCode: "AU",
        countryName: "Australia",
    },
    {
        portCode: "AUHBA",
        portName: "Hobart",
        state: null,
        countryCode: "AU",
        countryName: "Australia",
    },
    {
        portCode: "AUMEL",
        portName: "Melbourne",
        state: null,
        countryCode: "AU",
        countryName: "Australia",
    },
    {
        portCode: "AUMKY",
        portName: "Mackay",
        state: null,
        countryCode: "AU",
        countryName: "Australia",
    },
    {
        portCode: "AUPAE",
        portName: "Adelaide",
        state: null,
        countryCode: "AU",
        countryName: "Australia",
    },
    {
        portCode: "AUSYD",
        portName: "Sydney",
        state: null,
        countryCode: "AU",
        countryName: "Australia",
    },
    {
        portCode: "AUTSV",
        portName: "Townsville",
        state: null,
        countryCode: "AU",
        countryName: "Australia",
    },
    {
        portCode: "AWORJ",
        portName: "Oranjestad",
        state: null,
        countryCode: "AW",
        countryName: "Aruba",
    },
    {
        portCode: "AZBAK",
        portName: "Baku",
        state: null,
        countryCode: "AZ",
        countryName: "Azerbaijan",
    },
    {
        portCode: "BBBGI",
        portName: "Bridgetown",
        state: null,
        countryCode: "BB",
        countryName: "Barbados",
    },
    {
        portCode: "BDCGP",
        portName: "Chittagong",
        state: null,
        countryCode: "BD",
        countryName: "Bangladesh",
    },
    {
        portCode: "BDMGL",
        portName: "Mongla",
        state: null,
        countryCode: "BD",
        countryName: "Bangladesh",
    },
    {
        portCode: "BEANR",
        portName: "Antwerp",
        state: null,
        countryCode: "BE",
        countryName: "Belgium",
    },
    {
        portCode: "BEBRU",
        portName: "Brussels (Bruxelles)",
        state: null,
        countryCode: "BE",
        countryName: "Belgium",
    },
    {
        portCode: "BEGNE",
        portName: "Ghent",
        state: null,
        countryCode: "BE",
        countryName: "Belgium",
    },
    {
        portCode: "BELGG",
        portName: "Liege",
        state: null,
        countryCode: "BE",
        countryName: "Belgium",
    },
    {
        portCode: "BEZEE",
        portName: "Zeebrugge",
        state: null,
        countryCode: "BE",
        countryName: "Belgium",
    },
    {
        portCode: "BGVAR",
        portName: "Varna",
        state: null,
        countryCode: "BG",
        countryName: "Bulgaria",
    },
    {
        portCode: "BHMIN",
        portName: "Mina Salman",
        state: null,
        countryCode: "BH",
        countryName: "Bahrain",
    },
    {
        portCode: "BHSIT",
        portName: "Sitra",
        state: null,
        countryCode: "BH",
        countryName: "Bahrain",
    },
    {
        portCode: "BJCOO",
        portName: "Cotonou",
        state: null,
        countryCode: "BJ",
        countryName: "Benin",
    },
    {
        portCode: "BMBDA",
        portName: "Hamilton",
        state: null,
        countryCode: "BM",
        countryName: "Bermuda",
    },
    {
        portCode: "BMSGE",
        portName: "St Georges",
        state: null,
        countryCode: "BM",
        countryName: "Bermuda",
    },
    {
        portCode: "BNKUB",
        portName: "Kuala Belait",
        state: null,
        countryCode: "BN",
        countryName: "Brunei Darussalam",
    },
    {
        portCode: "BNMUA",
        portName: "Muara",
        state: null,
        countryCode: "BN",
        countryName: "Brunei Darussalam",
    },
    {
        portCode: "BRBEL",
        portName: "Belem",
        state: null,
        countryCode: "BR",
        countryName: "Brazil",
    },
    {
        portCode: "BRFOR",
        portName: "Fortaleza",
        state: null,
        countryCode: "BR",
        countryName: "Brazil",
    },
    {
        portCode: "BRIBB",
        portName: "Imbituba",
        state: null,
        countryCode: "BR",
        countryName: "Brazil",
    },
    {
        portCode: "BRMAO",
        portName: "Manaus",
        state: null,
        countryCode: "BR",
        countryName: "Brazil",
    },
    {
        portCode: "BRPNG",
        portName: "Paranagua",
        state: null,
        countryCode: "BR",
        countryName: "Brazil",
    },
    {
        portCode: "BRPOA",
        portName: "Porto Alegre",
        state: null,
        countryCode: "BR",
        countryName: "Brazil",
    },
    {
        portCode: "BRREC",
        portName: "Recife",
        state: null,
        countryCode: "BR",
        countryName: "Brazil",
    },
    {
        portCode: "BRRIG",
        portName: "Rio Grande",
        state: null,
        countryCode: "BR",
        countryName: "Brazil",
    },
    {
        portCode: "BRRIO",
        portName: "Rio De Janeiro",
        state: null,
        countryCode: "BR",
        countryName: "Brazil",
    },
    {
        portCode: "BRSSA",
        portName: "Salvador",
        state: null,
        countryCode: "BR",
        countryName: "Brazil",
    },
    {
        portCode: "BRSSZ",
        portName: "Santos",
        state: null,
        countryCode: "BR",
        countryName: "Brazil",
    },
    {
        portCode: "BRVIX",
        portName: "Vitoria",
        state: null,
        countryCode: "BR",
        countryName: "Brazil",
    },
    {
        portCode: "BSFPO",
        portName: "Freeport",
        state: null,
        countryCode: "BS",
        countryName: "Bahamas",
    },
    {
        portCode: "BSNAS",
        portName: "Nassau",
        state: null,
        countryCode: "BS",
        countryName: "Bahamas",
    },
    {
        portCode: "BZBZE",
        portName: "Belize City",
        state: null,
        countryCode: "BZ",
        countryName: "Belize",
    },
    {
        portCode: "CABEC",
        portName: "Becancour",
        state: null,
        countryCode: "CA",
        countryName: "Canada",
    },
    {
        portCode: "CACHV",
        portName: "Churchill",
        state: null,
        countryCode: "CA",
        countryName: "Canada",
    },
    {
        portCode: "CAHAL",
        portName: "Halifax",
        state: null,
        countryCode: "CA",
        countryName: "Canada",
    },
    {
        portCode: "CAHAM",
        portName: "Hamilton",
        state: null,
        countryCode: "CA",
        countryName: "Canada",
    },
    {
        portCode: "CAMTR",
        portName: "Montreal",
        state: null,
        countryCode: "CA",
        countryName: "Canada",
    },
    {
        portCode: "CANNO",
        portName: "Nanaimo",
        state: null,
        countryCode: "CA",
        countryName: "Canada",
    },
    {
        portCode: "CANWE",
        portName: "Fraser River Port (Port of New Westminster)",
        state: null,
        countryCode: "CA",
        countryName: "Canada",
    },
    {
        portCode: "CASEI",
        portName: "Sept Iles (Seven Islands)",
        state: null,
        countryCode: "CA",
        countryName: "Canada",
    },
    {
        portCode: "CASJF",
        portName: "St Johns",
        state: null,
        countryCode: "CA",
        countryName: "Canada",
    },
    {
        portCode: "CASYD",
        portName: "Sydney",
        state: null,
        countryCode: "CA",
        countryName: "Canada",
    },
    {
        portCode: "CATHU",
        portName: "Thunder Bay",
        state: null,
        countryCode: "CA",
        countryName: "Canada",
    },
    {
        portCode: "CATOR",
        portName: "Toronto",
        state: null,
        countryCode: "CA",
        countryName: "Canada",
    },
    {
        portCode: "CATRR",
        portName: "Trois-Rivieres",
        state: null,
        countryCode: "CA",
        countryName: "Canada",
    },
    {
        portCode: "CAVAN",
        portName: "Vancouver",
        state: null,
        countryCode: "CA",
        countryName: "Canada",
    },
    {
        portCode: "CAWND",
        portName: "Windsor",
        state: null,
        countryCode: "CA",
        countryName: "Canada",
    },
    {
        portCode: "CGPNR",
        portName: "Pointe Noire",
        state: null,
        countryCode: "CG",
        countryName: "Congo",
    },
    {
        portCode: "CHBSL",
        portName: "Basel",
        state: null,
        countryCode: "CH",
        countryName: "Switzerland",
    },
    {
        portCode: "CIABJ",
        portName: "Abidjan",
        state: null,
        countryCode: "CI",
        countryName: "Côte d'Ivoire",
    },
    {
        portCode: "CLANF",
        portName: "Antofagasta",
        state: null,
        countryCode: "CL",
        countryName: "Chile",
    },
    {
        portCode: "CLARI",
        portName: "Arica",
        state: null,
        countryCode: "CL",
        countryName: "Chile",
    },
    {
        portCode: "CLCNR",
        portName: "Chanaral",
        state: null,
        countryCode: "CL",
        countryName: "Chile",
    },
    {
        portCode: "CLIQQ",
        portName: "Iquique",
        state: null,
        countryCode: "CL",
        countryName: "Chile",
    },
    {
        portCode: "CLPMC",
        portName: "Puerto Montt",
        state: null,
        countryCode: "CL",
        countryName: "Chile",
    },
    {
        portCode: "CLPUQ",
        portName: "Punta Arenas",
        state: null,
        countryCode: "CL",
        countryName: "Chile",
    },
    {
        portCode: "CLSAI",
        portName: "San Antonio",
        state: null,
        countryCode: "CL",
        countryName: "Chile",
    },
    {
        portCode: "CLSVE",
        portName: "San Vicente",
        state: null,
        countryCode: "CL",
        countryName: "Chile",
    },
    {
        portCode: "CLTAL",
        portName: "Talcahuano",
        state: null,
        countryCode: "CL",
        countryName: "Chile",
    },
    {
        portCode: "CLVAP",
        portName: "Valparaiso",
        state: null,
        countryCode: "CL",
        countryName: "Chile",
    },
    {
        portCode: "CMDLA",
        portName: "Douala",
        state: null,
        countryCode: "CM",
        countryName: "Cameroon",
    },
    {
        portCode: "CMKBI",
        portName: "Kribi",
        state: null,
        countryCode: "CM",
        countryName: "Cameroon",
    },
    {
        portCode: "CMPAB",
        portName: "Alberni",
        state: null,
        countryCode: "CM",
        countryName: "Cameroon",
    },
    {
        portCode: "CMTKC",
        portName: "Tiko",
        state: null,
        countryCode: "CM",
        countryName: "Cameroon",
    },
    {
        portCode: "CNCAN",
        portName: "Guangzhou",
        state: null,
        countryCode: "CN",
        countryName: "China",
    },
    {
        portCode: "CNDLC",
        portName: "Dalian",
        state: null,
        countryCode: "CN",
        countryName: "China",
    },
    {
        portCode: "CNFOC",
        portName: "Fuzhou",
        state: null,
        countryCode: "CN",
        countryName: "China",
    },
    {
        portCode: "CNHAK",
        portName: "Haikou",
        state: null,
        countryCode: "CN",
        countryName: "China",
    },
    {
        portCode: "CNLYG",
        portName: "Lianyungang",
        state: null,
        countryCode: "CN",
        countryName: "China",
    },
    {
        portCode: "CNNGB",
        portName: "Ningbo",
        state: null,
        countryCode: "CN",
        countryName: "China",
    },
    {
        portCode: "CNNTG",
        portName: "Nantong",
        state: null,
        countryCode: "CN",
        countryName: "China",
    },
    {
        portCode: "CNSHA",
        portName: "Shanghai",
        state: null,
        countryCode: "CN",
        countryName: "China",
    },
    {
        portCode: "CNSHP",
        portName: "Qinhuangdao",
        state: null,
        countryCode: "CN",
        countryName: "China",
    },
    {
        portCode: "CNSWA",
        portName: "Shantou",
        state: null,
        countryCode: "CN",
        countryName: "China",
    },
    {
        portCode: "CNTAO",
        portName: "Qingdao",
        state: null,
        countryCode: "CN",
        countryName: "China",
    },
    {
        portCode: "CNTSN",
        portName: "Tianjin",
        state: null,
        countryCode: "CN",
        countryName: "China",
    },
    {
        portCode: "CNXMN",
        portName: "Xiamen",
        state: null,
        countryCode: "CN",
        countryName: "China",
    },
    {
        portCode: "CNZHA",
        portName: "Zhanjiang",
        state: null,
        countryCode: "CN",
        countryName: "China",
    },
    {
        portCode: "COBAQ",
        portName: "Barranquilla",
        state: null,
        countryCode: "CO",
        countryName: "Colombia",
    },
    {
        portCode: "COBUN",
        portName: "Buenaventura",
        state: null,
        countryCode: "CO",
        countryName: "Colombia",
    },
    {
        portCode: "COCTG",
        portName: "Cartagena",
        state: null,
        countryCode: "CO",
        countryName: "Colombia",
    },
    {
        portCode: "COPBO",
        portName: "Puerto Bolivar",
        state: null,
        countryCode: "CO",
        countryName: "Colombia",
    },
    {
        portCode: "COTCO",
        portName: "Tumaco",
        state: null,
        countryCode: "CO",
        countryName: "Colombia",
    },
    {
        portCode: "COTRB",
        portName: "Turbo",
        state: null,
        countryCode: "CO",
        countryName: "Colombia",
    },
    {
        portCode: "CRCAL",
        portName: "Caldera",
        state: null,
        countryCode: "CR",
        countryName: "Costa Rica",
    },
    {
        portCode: "CRGLF",
        portName: "Golfito",
        state: null,
        countryCode: "CR",
        countryName: "Costa Rica",
    },
    {
        portCode: "CRPAS",
        portName: "Puntarenas",
        state: null,
        countryCode: "CR",
        countryName: "Costa Rica",
    },
    {
        portCode: "CRXQP",
        portName: "Quepos",
        state: null,
        countryCode: "CR",
        countryName: "Costa Rica",
    },
    {
        portCode: "CUHAV",
        portName: "Havana",
        state: null,
        countryCode: "CU",
        countryName: "Cuba",
    },
    {
        portCode: "CUMZO",
        portName: "Manzanillo",
        state: null,
        countryCode: "CU",
        countryName: "Cuba",
    },
    {
        portCode: "CUNVT",
        portName: "Nuevitas",
        state: null,
        countryCode: "CU",
        countryName: "Cuba",
    },
    {
        portCode: "CUQMA",
        portName: "Matanzas",
        state: null,
        countryCode: "CU",
        countryName: "Cuba",
    },
    {
        portCode: "CUSCU",
        portName: "Santiago de Cuba",
        state: null,
        countryCode: "CU",
        countryName: "Cuba",
    },
    {
        portCode: "CVPGR",
        portName: "Porto Grande",
        state: null,
        countryCode: "CV",
        countryName: "Cabo Verde",
    },
    {
        portCode: "CYAKT",
        portName: "Akrotiri",
        state: null,
        countryCode: "CY",
        countryName: "Cyprus",
    },
    {
        portCode: "CYFMG",
        portName: "Famagusta",
        state: null,
        countryCode: "CY",
        countryName: "Cyprus",
    },
    {
        portCode: "CYLAT",
        portName: "Larnaca",
        state: null,
        countryCode: "CY",
        countryName: "Cyprus",
    },
    {
        portCode: "CYLMS",
        portName: "Limassol",
        state: null,
        countryCode: "CY",
        countryName: "Cyprus",
    },
    {
        portCode: "CYPFO",
        portName: "Paphos",
        state: null,
        countryCode: "CY",
        countryName: "Cyprus",
    },
    {
        portCode: "CYVAS",
        portName: "Vassiliko",
        state: null,
        countryCode: "CY",
        countryName: "Cyprus",
    },
    {
        portCode: "DEBKE",
        portName: "Brake",
        state: null,
        countryCode: "DE",
        countryName: "Germany",
    },
    {
        portCode: "DEBRE",
        portName: "Bremen",
        state: null,
        countryCode: "DE",
        countryName: "Germany",
    },
    {
        portCode: "DEBRV",
        portName: "Bremerhaven",
        state: null,
        countryCode: "DE",
        countryName: "Germany",
    },
    {
        portCode: "DEDUI",
        portName: "Duisburg",
        state: null,
        countryCode: "DE",
        countryName: "Germany",
    },
    {
        portCode: "DEDUS",
        portName: "Dusseldorf",
        state: null,
        countryCode: "DE",
        countryName: "Germany",
    },
    {
        portCode: "DEEME",
        portName: "Emden",
        state: null,
        countryCode: "DE",
        countryName: "Germany",
    },
    {
        portCode: "DEHAM",
        portName: "Hamburg",
        state: null,
        countryCode: "DE",
        countryName: "Germany",
    },
    {
        portCode: "DEKAE",
        portName: "Karlsruhe",
        state: null,
        countryCode: "DE",
        countryName: "Germany",
    },
    {
        portCode: "DEKEL",
        portName: "Kiel",
        state: null,
        countryCode: "DE",
        countryName: "Germany",
    },
    {
        portCode: "DELBC",
        portName: "Lubeck",
        state: null,
        countryCode: "DE",
        countryName: "Germany",
    },
    {
        portCode: "DEMHG",
        portName: "Mannheim",
        state: null,
        countryCode: "DE",
        countryName: "Germany",
    },
    {
        portCode: "DERSK",
        portName: "Rostock",
        state: null,
        countryCode: "DE",
        countryName: "Germany",
    },
    {
        portCode: "DEWVN",
        portName: "Wilhelmshavn",
        state: null,
        countryCode: "DE",
        countryName: "Germany",
    },
    {
        portCode: "DJJIB",
        portName: "Djibouti",
        state: null,
        countryCode: "DJ",
        countryName: "Djibouti",
    },
    {
        portCode: "DKAAR",
        portName: "Aarhus",
        state: null,
        countryCode: "DK",
        countryName: "Denmark",
    },
    {
        portCode: "DKEBJ",
        portName: "Esbjerg",
        state: null,
        countryCode: "DK",
        countryName: "Denmark",
    },
    {
        portCode: "DKFDH",
        portName: "Frederikshavn",
        state: null,
        countryCode: "DK",
        countryName: "Denmark",
    },
    {
        portCode: "DKFRC",
        portName: "Fredericia",
        state: null,
        countryCode: "DK",
        countryName: "Denmark",
    },
    {
        portCode: "DKGRE",
        portName: "Grenaa",
        state: null,
        countryCode: "DK",
        countryName: "Denmark",
    },
    {
        portCode: "DKODE",
        portName: "Odense",
        state: null,
        countryCode: "DK",
        countryName: "Denmark",
    },
    {
        portCode: "DKRNN",
        portName: "Ronne",
        state: null,
        countryCode: "DK",
        countryName: "Denmark",
    },
    {
        portCode: "DMPOR",
        portName: "Portsmouth",
        state: null,
        countryCode: "DM",
        countryName: "Dominica",
    },
    {
        portCode: "DMRSU",
        portName: "Roseau",
        state: null,
        countryCode: "DM",
        countryName: "Dominica",
    },
    {
        portCode: "DOBRX",
        portName: "Barahona",
        state: null,
        countryCode: "DO",
        countryName: "Dominican Republic",
    },
    {
        portCode: "DOHAI",
        portName: "Rio Haina",
        state: null,
        countryCode: "DO",
        countryName: "Dominican Republic",
    },
    {
        portCode: "DOLRM",
        portName: "La Romana",
        state: null,
        countryCode: "DO",
        countryName: "Dominican Republic",
    },
    {
        portCode: "DOPOP",
        portName: "Puerto Plata",
        state: null,
        countryCode: "DO",
        countryName: "Dominican Republic",
    },
    {
        portCode: "DOSDQ",
        portName: "Santo Domingo",
        state: null,
        countryCode: "DO",
        countryName: "Dominican Republic",
    },
    {
        portCode: "DOSPM",
        portName: "San Pedro de Macoris",
        state: null,
        countryCode: "DO",
        countryName: "Dominican Republic",
    },
    {
        portCode: "DZAAE",
        portName: "Annaba (Ex Bone)",
        state: null,
        countryCode: "DZ",
        countryName: "Algeria",
    },
    {
        portCode: "DZALG",
        portName: "Algiers",
        state: null,
        countryCode: "DZ",
        countryName: "Algeria",
    },
    {
        portCode: "DZAZW",
        portName: "Arzew",
        state: null,
        countryCode: "DZ",
        countryName: "Algeria",
    },
    {
        portCode: "DZBJA",
        portName: "Bejaia (Ex Bougie)",
        state: null,
        countryCode: "DZ",
        countryName: "Algeria",
    },
    {
        portCode: "DZDEL",
        portName: "Dellys",
        state: null,
        countryCode: "DZ",
        countryName: "Algeria",
    },
    {
        portCode: "DZGHA",
        portName: "Ghazaouet",
        state: null,
        countryCode: "DZ",
        countryName: "Algeria",
    },
    {
        portCode: "DZMOS",
        portName: "Mostaganem",
        state: null,
        countryCode: "DZ",
        countryName: "Algeria",
    },
    {
        portCode: "DZORN",
        portName: "Oran",
        state: null,
        countryCode: "DZ",
        countryName: "Algeria",
    },
    {
        portCode: "DZSKI",
        portName: "Skikda (Ex Philippeville)",
        state: null,
        countryCode: "DZ",
        countryName: "Algeria",
    },
    {
        portCode: "DZTEN",
        portName: "Tenes",
        state: null,
        countryCode: "DZ",
        countryName: "Algeria",
    },
    {
        portCode: "ECESM",
        portName: "Esmeraldas",
        state: null,
        countryCode: "EC",
        countryName: "Ecuador",
    },
    {
        portCode: "ECGYE",
        portName: "Guayaquil",
        state: null,
        countryCode: "EC",
        countryName: "Ecuador",
    },
    {
        portCode: "ECLLD",
        portName: "La Libertad",
        state: null,
        countryCode: "EC",
        countryName: "Ecuador",
    },
    {
        portCode: "ECMEC",
        portName: "Manta",
        state: null,
        countryCode: "EC",
        countryName: "Ecuador",
    },
    {
        portCode: "ECPBO",
        portName: "Puerto Bolivar",
        state: null,
        countryCode: "EC",
        countryName: "Ecuador",
    },
    {
        portCode: "ECSLR",
        portName: "San Lorenzo",
        state: null,
        countryCode: "EC",
        countryName: "Ecuador",
    },
    {
        portCode: "EEPYA",
        portName: "Pärnu",
        state: null,
        countryCode: "EE",
        countryName: "Estonia",
    },
    {
        portCode: "EETLL",
        portName: "Tallinn",
        state: null,
        countryCode: "EE",
        countryName: "Estonia",
    },
    {
        portCode: "EGDAM",
        portName: "Damietta",
        state: null,
        countryCode: "EG",
        countryName: "Egypt",
    },
    {
        portCode: "EGEDK",
        portName: "Alexandria",
        state: null,
        countryCode: "EG",
        countryName: "Egypt",
    },
    {
        portCode: "EGPSD",
        portName: "Port Said",
        state: null,
        countryCode: "EG",
        countryName: "Egypt",
    },
    {
        portCode: "ESAGP",
        portName: "Malaga",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "ESAVS",
        portName: "Aviles",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "ESBCN",
        portName: "Barcelona",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "ESBIO",
        portName: "Bilbao",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "ESCAD",
        portName: "Cadiz",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "ESCAR",
        portName: "Cartagena",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "ESCAS",
        portName: "Castellon",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "ESCEU",
        portName: "Ceuta",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "ESHUV",
        portName: "Huelva",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "ESLPA",
        portName: "Las Palmas",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "ESPAS",
        portName: "Pasajes",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "ESSCT",
        portName: "Santa Cruz de Tenerife",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "ESSDR",
        portName: "Santander",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "ESTAR",
        portName: "Tarragona",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "ESVGO",
        portName: "Vigo",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "ESVLC",
        portName: "Valencia",
        state: null,
        countryCode: "ES",
        countryName: "Spain",
    },
    {
        portCode: "FIHEL",
        portName: "Helsinki",
        state: null,
        countryCode: "FI",
        countryName: "Finland",
    },
    {
        portCode: "FIHMN",
        portName: "Hamina",
        state: null,
        countryCode: "FI",
        countryName: "Finland",
    },
    {
        portCode: "FIKOK",
        portName: "Kokkola",
        state: null,
        countryCode: "FI",
        countryName: "Finland",
    },
    {
        portCode: "FIKTK",
        portName: "Kotka",
        state: null,
        countryCode: "FI",
        countryName: "Finland",
    },
    {
        portCode: "FILOV",
        portName: "Loviisa",
        state: null,
        countryCode: "FI",
        countryName: "Finland",
    },
    {
        portCode: "FIOUL",
        portName: "Oulu",
        state: null,
        countryCode: "FI",
        countryName: "Finland",
    },
    {
        portCode: "FIPOR",
        portName: "Pori",
        state: null,
        countryCode: "FI",
        countryName: "Finland",
    },
    {
        portCode: "FIRAU",
        portName: "Rauma",
        state: null,
        countryCode: "FI",
        countryName: "Finland",
    },
    {
        portCode: "FITKU",
        portName: "Turku",
        state: null,
        countryCode: "FI",
        countryName: "Finland",
    },
    {
        portCode: "FIUKI",
        portName: "Uusikaupunki",
        state: null,
        countryCode: "FI",
        countryName: "Finland",
    },
    {
        portCode: "FIVAA",
        portName: "Vaasa",
        state: null,
        countryCode: "FI",
        countryName: "Finland",
    },
    {
        portCode: "FJLBS",
        portName: "Labasa (Lambasa)",
        state: null,
        countryCode: "FJ",
        countryName: "Fiji",
    },
    {
        portCode: "FJLEV",
        portName: "Levuka",
        state: null,
        countryCode: "FJ",
        countryName: "Fiji",
    },
    {
        portCode: "FJLTK",
        portName: "Lautoka",
        state: null,
        countryCode: "FJ",
        countryName: "Fiji",
    },
    {
        portCode: "FJSUV",
        portName: "Suva",
        state: null,
        countryCode: "FJ",
        countryName: "Fiji",
    },
    {
        portCode: "FJSVU",
        portName: "Savu Savu",
        state: null,
        countryCode: "FJ",
        countryName: "Fiji",
    },
    {
        portCode: "FMPNI",
        portName: "Pohnpei (Ex Ponape)",
        state: null,
        countryCode: "FM",
        countryName: "Micronesia (Federated States of)",
    },
    {
        portCode: "FOKVI",
        portName: "Klaksvik",
        state: null,
        countryCode: "FO",
        countryName: "Faroe Islands",
    },
    {
        portCode: "FOTHO",
        portName: "Torshavn",
        state: null,
        countryCode: "FO",
        countryName: "Faroe Islands",
    },
    {
        portCode: "FRBOD",
        portName: "Bordeaux",
        state: null,
        countryCode: "FR",
        countryName: "France",
    },
    {
        portCode: "FRBOL",
        portName: "Boulogne Sur Mer",
        state: null,
        countryCode: "FR",
        countryName: "France",
    },
    {
        portCode: "FRCER",
        portName: "Cherbourg",
        state: null,
        countryCode: "FR",
        countryName: "France",
    },
    {
        portCode: "FRDKK",
        portName: "Dunkerque",
        state: null,
        countryCode: "FR",
        countryName: "France",
    },
    {
        portCode: "FRLEH",
        portName: "Le Havre",
        state: null,
        countryCode: "FR",
        countryName: "France",
    },
    {
        portCode: "FRLPE",
        portName: "La Rochelle-Pallice",
        state: null,
        countryCode: "FR",
        countryName: "France",
    },
    {
        portCode: "FRMRS",
        portName: "Marseille",
        state: null,
        countryCode: "FR",
        countryName: "France",
    },
    {
        portCode: "FRNTE",
        portName: "Nantes-St. Nazaire",
        state: null,
        countryCode: "FR",
        countryName: "France",
    },
    {
        portCode: "FRPAR",
        portName: "Paris",
        state: null,
        countryCode: "FR",
        countryName: "France",
    },
    {
        portCode: "FRSML",
        portName: "St Malo",
        state: null,
        countryCode: "FR",
        countryName: "France",
    },
    {
        portCode: "FRSXB",
        portName: "Strasbourg",
        state: null,
        countryCode: "FR",
        countryName: "France",
    },
    {
        portCode: "FRTLN",
        portName: "Toulon",
        state: null,
        countryCode: "FR",
        countryName: "France",
    },
    {
        portCode: "FRURO",
        portName: "Rouen",
        state: null,
        countryCode: "FR",
        countryName: "France",
    },
    {
        portCode: "GACLZ",
        portName: "Cap Lopez",
        state: null,
        countryCode: "GA",
        countryName: "Gabon",
    },
    {
        portCode: "GALBV",
        portName: "Libreville",
        state: null,
        countryCode: "GA",
        countryName: "Gabon",
    },
    {
        portCode: "GAOWE",
        portName: "Owendo",
        state: null,
        countryCode: "GA",
        countryName: "Gabon",
    },
    {
        portCode: "GAPOG",
        portName: "Port Gentil",
        state: null,
        countryCode: "GA",
        countryName: "Gabon",
    },
    {
        portCode: "GBABD",
        portName: "Aberdeen",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBBEL",
        portName: "Belfast",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBBRS",
        portName: "Bristol",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBDVR",
        portName: "Dover",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBFAL",
        portName: "Falmouth",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBFXT",
        portName: "Felixstowe",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBGLW",
        portName: "Glasgow",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBGRG",
        portName: "Grangemouth",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBHUL",
        portName: "Hull",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBLEI",
        portName: "Leith",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBLIV",
        portName: "Liverpool",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBLON",
        portName: "London",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBMNC",
        portName: "Manchester",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBPHD",
        portName: "Peterhead Bay",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBPLY",
        portName: "Plymouth",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBPME",
        portName: "Portsmouth",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBRCS",
        portName: "Thamesport",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBSFW",
        portName: "Scapa Flow",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBSOU",
        portName: "Southampton",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBSUL",
        portName: "Sullom Voe",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBTEE",
        portName: "Teesport",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GBTYN",
        portName: "Tyne",
        state: null,
        countryCode: "GB",
        countryName: "United Kingdom",
    },
    {
        portCode: "GEBUS",
        portName: "Batumi",
        state: null,
        countryCode: "GE",
        countryName: "Georgia",
    },
    {
        portCode: "GEPTI",
        portName: "Poti",
        state: null,
        countryCode: "GE",
        countryName: "Georgia",
    },
    {
        portCode: "GESUI",
        portName: "Sukhumi",
        state: null,
        countryCode: "GE",
        countryName: "Georgia",
    },
    {
        portCode: "GFCAY",
        portName: "Cayenne",
        state: null,
        countryCode: "GF",
        countryName: "French Guiana",
    },
    {
        portCode: "GHTEM",
        portName: "Tema",
        state: null,
        countryCode: "GH",
        countryName: "Ghana",
    },
    {
        portCode: "GHTKD",
        portName: "Takoradi",
        state: null,
        countryCode: "GH",
        countryName: "Ghana",
    },
    {
        portCode: "GIGIB",
        portName: "Gibraltar",
        state: null,
        countryCode: "GI",
        countryName: "Gibraltar",
    },
    {
        portCode: "GLGOH",
        portName: "Nuuk (Godthaab)",
        state: null,
        countryCode: "GL",
        countryName: "Greenland",
    },
    {
        portCode: "GLJHS",
        portName: "Holsteinsborg (Sisimiut)",
        state: null,
        countryCode: "GL",
        countryName: "Greenland",
    },
    {
        portCode: "GLJNN",
        portName: "Nanortalik",
        state: null,
        countryCode: "GL",
        countryName: "Greenland",
    },
    {
        portCode: "GLUAK",
        portName: "Narsarsuaq",
        state: null,
        countryCode: "GL",
        countryName: "Greenland",
    },
    {
        portCode: "GMBJL",
        portName: "Banjul",
        state: null,
        countryCode: "GM",
        countryName: "Gambia",
    },
    {
        portCode: "GNCKY",
        portName: "Conakry",
        state: null,
        countryCode: "GN",
        countryName: "Guinea",
    },
    {
        portCode: "GNKMR",
        portName: "Port Kamsar",
        state: null,
        countryCode: "GN",
        countryName: "Guinea",
    },
    {
        portCode: "GPGUS",
        portName: "Gustavia",
        state: null,
        countryCode: "GP",
        countryName: "Guadeloupe",
    },
    {
        portCode: "GPPTP",
        portName: "Pointe-a-Pitre",
        state: null,
        countryCode: "GP",
        countryName: "Guadeloupe",
    },
    {
        portCode: "GQBSG",
        portName: "Bata",
        state: null,
        countryCode: "GQ",
        countryName: "Equatorial Guinea",
    },
    {
        portCode: "GQSSG",
        portName: "Malabo (Rey Malabo)",
        state: null,
        countryCode: "GQ",
        countryName: "Equatorial Guinea",
    },
    {
        portCode: "GREEU",
        portName: "Eleusis",
        state: null,
        countryCode: "GR",
        countryName: "Greece",
    },
    {
        portCode: "GRGPA",
        portName: "Patras",
        state: null,
        countryCode: "GR",
        countryName: "Greece",
    },
    {
        portCode: "GRHER",
        portName: "Iraklion (Heraklion)",
        state: null,
        countryCode: "GR",
        countryName: "Greece",
    },
    {
        portCode: "GRKVA",
        portName: "Kavala",
        state: null,
        countryCode: "GR",
        countryName: "Greece",
    },
    {
        portCode: "GRPIR",
        portName: "Piraeus",
        state: null,
        countryCode: "GR",
        countryName: "Greece",
    },
    {
        portCode: "GRSKG",
        portName: "Thessaloniki",
        state: null,
        countryCode: "GR",
        countryName: "Greece",
    },
    {
        portCode: "GRVOL",
        portName: "Volos",
        state: null,
        countryCode: "GR",
        countryName: "Greece",
    },
    {
        portCode: "GTCHR",
        portName: "Champerico",
        state: null,
        countryCode: "GT",
        countryName: "Guatemala",
    },
    {
        portCode: "GTPBR",
        portName: "Puerto Barrios",
        state: null,
        countryCode: "GT",
        countryName: "Guatemala",
    },
    {
        portCode: "GTSTC",
        portName: "Santo Tomas De Castilla",
        state: null,
        countryCode: "GT",
        countryName: "Guatemala",
    },
    {
        portCode: "GUAPR",
        portName: "Apra (Agana)",
        state: null,
        countryCode: "GU",
        countryName: "Guam",
    },
    {
        portCode: "GWBXO",
        portName: "Bissau",
        state: null,
        countryCode: "GW",
        countryName: "Guinea-Bissau",
    },
    {
        portCode: "GYGEO",
        portName: "Georgetown",
        state: null,
        countryCode: "GY",
        countryName: "Guyana",
    },
    {
        portCode: "GYQSK",
        portName: "New Amsterdam",
        state: null,
        countryCode: "GY",
        countryName: "Guyana",
    },
    {
        portCode: "HKHKG",
        portName: "Hong Kong",
        state: null,
        countryCode: "HK",
        countryName: "Hong Kong",
    },
    {
        portCode: "HNLCE",
        portName: "La Ceiba",
        state: null,
        countryCode: "HN",
        countryName: "Honduras",
    },
    {
        portCode: "HNPCA",
        portName: "Puerto Castilla",
        state: null,
        countryCode: "HN",
        countryName: "Honduras",
    },
    {
        portCode: "HNPCR",
        portName: "Puerto Cortes",
        state: null,
        countryCode: "HN",
        countryName: "Honduras",
    },
    {
        portCode: "HNSLO",
        portName: "San Lorenzo",
        state: null,
        countryCode: "HN",
        countryName: "Honduras",
    },
    {
        portCode: "HNTEA",
        portName: "Tela",
        state: null,
        countryCode: "HN",
        countryName: "Honduras",
    },
    {
        portCode: "HRDBV",
        portName: "Dubrovnik",
        state: null,
        countryCode: "HR",
        countryName: "Croatia",
    },
    {
        portCode: "HROMI",
        portName: "Omisalj",
        state: null,
        countryCode: "HR",
        countryName: "Croatia",
    },
    {
        portCode: "HRPLE",
        portName: "Ploce",
        state: null,
        countryCode: "HR",
        countryName: "Croatia",
    },
    {
        portCode: "HRPUY",
        portName: "Pula",
        state: null,
        countryCode: "HR",
        countryName: "Croatia",
    },
    {
        portCode: "HRRJK",
        portName: "Rijeka Bakar",
        state: null,
        countryCode: "HR",
        countryName: "Croatia",
    },
    {
        portCode: "HRSIB",
        portName: "Sibenik",
        state: null,
        countryCode: "HR",
        countryName: "Croatia",
    },
    {
        portCode: "HRSPU",
        portName: "Split",
        state: null,
        countryCode: "HR",
        countryName: "Croatia",
    },
    {
        portCode: "HRZAD",
        portName: "Zadar",
        state: null,
        countryCode: "HR",
        countryName: "Croatia",
    },
    {
        portCode: "HTPAP",
        portName: "Port Au Prince",
        state: null,
        countryCode: "HT",
        countryName: "Haiti",
    },
    {
        portCode: "IDCBN",
        portName: "Cirebon, Java",
        state: null,
        countryCode: "ID",
        countryName: "Indonesia",
    },
    {
        portCode: "IDCXP",
        portName: "Cilacap, Java (Tanjung Intan)",
        state: null,
        countryCode: "ID",
        countryName: "Indonesia",
    },
    {
        portCode: "IDKOE",
        portName: "Kupang, Timor",
        state: null,
        countryCode: "ID",
        countryName: "Indonesia",
    },
    {
        portCode: "IDPLM",
        portName: "Palembang, Sumatra",
        state: null,
        countryCode: "ID",
        countryName: "Indonesia",
    },
    {
        portCode: "IDSRG",
        portName: "Semarang, Java (Tanjung Emas)",
        state: null,
        countryCode: "ID",
        countryName: "Indonesia",
    },
    {
        portCode: "IDSUB",
        portName: "Surabaya, Java (Tanjung Perak)",
        state: null,
        countryCode: "ID",
        countryName: "Indonesia",
    },
    {
        portCode: "IDTPP",
        portName: "Jakarta, Java (Tanjung Priok)",
        state: null,
        countryCode: "ID",
        countryName: "Indonesia",
    },
    {
        portCode: "IDUPG",
        portName: "Ujung Pandang, Sulawesi (Makassar)",
        state: null,
        countryCode: "ID",
        countryName: "Indonesia",
    },
    {
        portCode: "IEARK",
        portName: "Arklow",
        state: null,
        countryCode: "IE",
        countryName: "Ireland",
    },
    {
        portCode: "IEDRO",
        portName: "Drogheda",
        state: null,
        countryCode: "IE",
        countryName: "Ireland",
    },
    {
        portCode: "IEDUB",
        portName: "Dublin",
        state: null,
        countryCode: "IE",
        countryName: "Ireland",
    },
    {
        portCode: "IEFOV",
        portName: "Foynes (Shannon Foynes Port)",
        state: null,
        countryCode: "IE",
        countryName: "Ireland",
    },
    {
        portCode: "IEGWY",
        portName: "Galway",
        state: null,
        countryCode: "IE",
        countryName: "Ireland",
    },
    {
        portCode: "IENRS",
        portName: "New Ross",
        state: null,
        countryCode: "IE",
        countryName: "Ireland",
    },
    {
        portCode: "IEORK",
        portName: "Cork",
        state: null,
        countryCode: "IE",
        countryName: "Ireland",
    },
    {
        portCode: "IEWAT",
        portName: "Waterford",
        state: null,
        countryCode: "IE",
        countryName: "Ireland",
    },
    {
        portCode: "ILASH",
        portName: "Ashdod",
        state: null,
        countryCode: "IL",
        countryName: "Israel",
    },
    {
        portCode: "ILETH",
        portName: "Eilat",
        state: null,
        countryCode: "IL",
        countryName: "Israel",
    },
    {
        portCode: "ILHFA",
        portName: "Haifa",
        state: null,
        countryCode: "IL",
        countryName: "Israel",
    },
    {
        portCode: "INBOM",
        portName: "Bombay (Mumbai)",
        state: null,
        countryCode: "IN",
        countryName: "India",
    },
    {
        portCode: "INCCU",
        portName: "Calcutta (Kolkata)",
        state: null,
        countryCode: "IN",
        countryName: "India",
    },
    {
        portCode: "INCOK",
        portName: "Cochin",
        state: null,
        countryCode: "IN",
        countryName: "India",
    },
    {
        portCode: "INIXY",
        portName: "Kandla",
        state: null,
        countryCode: "IN",
        countryName: "India",
    },
    {
        portCode: "INMAA",
        portName: "Chennai (Madras)",
        state: null,
        countryCode: "IN",
        countryName: "India",
    },
    {
        portCode: "INNSA",
        portName: "Nhava Sheva\n(Jawaharlal Nehru)",
        state: null,
        countryCode: "IN",
        countryName: "India",
    },
    {
        portCode: "INTUT",
        portName: "Tuticorin",
        state: null,
        countryCode: "IN",
        countryName: "India",
    },
    {
        portCode: "INVTZ",
        portName: "Vishakhapatnam",
        state: null,
        countryCode: "IN",
        countryName: "India",
    },
    {
        portCode: "IQBSR",
        portName: "Basra",
        state: null,
        countryCode: "IQ",
        countryName: "Iraq",
    },
    {
        portCode: "IQKAZ",
        portName: "Khor al Zubair",
        state: null,
        countryCode: "IQ",
        countryName: "Iraq",
    },
    {
        portCode: "IQUQR",
        portName: "Umm Qasr",
        state: null,
        countryCode: "IQ",
        countryName: "Iraq",
    },
    {
        portCode: "IRABD",
        portName: "Abadan",
        state: null,
        countryCode: "IR",
        countryName: "Iran (Islamic Republic of)",
    },
    {
        portCode: "IRBAZ",
        portName: "Bandar Anzali",
        state: null,
        countryCode: "IR",
        countryName: "Iran (Islamic Republic of)",
    },
    {
        portCode: "IRBKM",
        portName: "Imam Khomeini",
        state: null,
        countryCode: "IR",
        countryName: "Iran (Islamic Republic of)",
    },
    {
        portCode: "IRBND",
        portName: "Bandar Abbas",
        state: null,
        countryCode: "IR",
        countryName: "Iran (Islamic Republic of)",
    },
    {
        portCode: "IRBUZ",
        portName: "Bushehr",
        state: null,
        countryCode: "IR",
        countryName: "Iran (Islamic Republic of)",
    },
    {
        portCode: "IRKHO",
        portName: "Khorramshahr",
        state: null,
        countryCode: "IR",
        countryName: "Iran (Islamic Republic of)",
    },
    {
        portCode: "IRLVP",
        portName: "Lavan",
        state: null,
        countryCode: "IR",
        countryName: "Iran (Islamic Republic of)",
    },
    {
        portCode: "IRMRX",
        portName: "Bandar Mahshahr",
        state: null,
        countryCode: "IR",
        countryName: "Iran (Islamic Republic of)",
    },
    {
        portCode: "IRSXI",
        portName: "Sirri Island",
        state: null,
        countryCode: "IR",
        countryName: "Iran (Islamic Republic of)",
    },
    {
        portCode: "ISAKU",
        portName: "Akureyri",
        state: null,
        countryCode: "IS",
        countryName: "Iceland",
    },
    {
        portCode: "ISHAF",
        portName: "Hafnarfjordur (Straumsvik)",
        state: null,
        countryCode: "IS",
        countryName: "Iceland",
    },
    {
        portCode: "ISISA",
        portName: "Isafjordur",
        state: null,
        countryCode: "IS",
        countryName: "Iceland",
    },
    {
        portCode: "ISKEV",
        portName: "Keflavik",
        state: null,
        countryCode: "IS",
        countryName: "Iceland",
    },
    {
        portCode: "ISREY",
        portName: "Reykjavik",
        state: null,
        countryCode: "IS",
        countryName: "Iceland",
    },
    {
        portCode: "ISVES",
        portName: "Vestmannaeyjar",
        state: null,
        countryCode: "IS",
        countryName: "Iceland",
    },
    {
        portCode: "ITAUG",
        portName: "Augusta",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITBDS",
        portName: "Brindisi",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITBLN",
        portName: "Bagnoli",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITBRI",
        portName: "Bari",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITGEA",
        portName: "Gela",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITGOA",
        portName: "Genoa",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITLIV",
        portName: "Livorno",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITMLZ",
        portName: "Milazzo",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITMSN",
        portName: "Messina",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITNAP",
        portName: "Napoli (Naples)",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITPFX",
        portName: "Sarroch (Porto Foxi)",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITPMO",
        portName: "Palermo",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITPTO",
        portName: "Porto Torres",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITRAN",
        portName: "Ravenna",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITSPE",
        portName: "La Spezia",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITSVN",
        portName: "Savona-Vado",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITTAR",
        portName: "Taranto",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITTRS",
        portName: "Trieste",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "ITVCE",
        portName: "Venice",
        state: null,
        countryCode: "IT",
        countryName: "Italy",
    },
    {
        portCode: "JMKIN",
        portName: "Kingston",
        state: null,
        countryCode: "JM",
        countryName: "Jamaica",
    },
    {
        portCode: "JMMBJ",
        portName: "Montego Bay",
        state: null,
        countryCode: "JM",
        countryName: "Jamaica",
    },
    {
        portCode: "JMOCJ",
        portName: "Ocho Rios",
        state: null,
        countryCode: "JM",
        countryName: "Jamaica",
    },
    {
        portCode: "JMPEV",
        portName: "Port Esquivel",
        state: null,
        countryCode: "JM",
        countryName: "Jamaica",
    },
    {
        portCode: "JMPOT",
        portName: "Port Antonio",
        state: null,
        countryCode: "JM",
        countryName: "Jamaica",
    },
    {
        portCode: "JMROP",
        portName: "Rocky Point",
        state: null,
        countryCode: "JM",
        countryName: "Jamaica",
    },
    {
        portCode: "JOAQJ",
        portName: "Aqaba (El Akaba)",
        state: null,
        countryCode: "JO",
        countryName: "Jordan",
    },
    {
        portCode: "JPAMA",
        portName: "Amagasaki",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPAXT",
        portName: "Akita",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPCHB",
        portName: "Chiba",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPHHE",
        portName: "Hachinohe",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPHHR",
        portName: "Higashiharima",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPHIJ",
        portName: "Hiroshima",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPHIM",
        portName: "Himeji",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPHKD",
        portName: "Hakodate, Hokkaido",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPKNU",
        portName: "Kinuura",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPKUH",
        portName: "Kushiro, Hokkaido",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPKWS",
        portName: "Kawasaki",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPMIZ",
        portName: "Mizushima",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPMOJ",
        portName: "Moji",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPNGO",
        portName: "Nagoya",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPOSA",
        portName: "Osaka",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPSAK",
        portName: "Sakai",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPSKT",
        portName: "Sakata",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPSMZ",
        portName: "Shimizu",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPTMK",
        portName: "Tomakomai, Hokkaido",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPTYO",
        portName: "Tokyo",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPUKB",
        portName: "Kobe",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "JPYOK",
        portName: "Yokohama",
        state: null,
        countryCode: "JP",
        countryName: "Japan",
    },
    {
        portCode: "KELAU",
        portName: "Lamu",
        state: null,
        countryCode: "KE",
        countryName: "Kenya",
    },
    {
        portCode: "KEMBA",
        portName: "Mombasa",
        state: null,
        countryCode: "KE",
        countryName: "Kenya",
    },
    {
        portCode: "KHKOS",
        portName: "Kompongsom (also known as: Sihanoukville)",
        state: null,
        countryCode: "KH",
        countryName: "Cambodia",
    },
    {
        portCode: "KHPNH",
        portName: "Phnom Penh",
        state: null,
        countryCode: "KH",
        countryName: "Cambodia",
    },
    {
        portCode: "KITRW",
        portName: "Betio (Tarawa)",
        state: null,
        countryCode: "KI",
        countryName: "Kiribati",
    },
    {
        portCode: "KMMUT",
        portName: "Mutsamudu",
        state: null,
        countryCode: "KM",
        countryName: "Comoros",
    },
    {
        portCode: "KMYVA",
        portName: "Moroni",
        state: null,
        countryCode: "KM",
        countryName: "Comoros",
    },
    {
        portCode: "KNBAS",
        portName: "Basseterre",
        state: null,
        countryCode: "KN",
        countryName: "Saint Kitts and Nevis",
    },
    {
        portCode: "KRCHF",
        portName: "Chinhae",
        state: null,
        countryCode: "KR",
        countryName: "South Korea (the Republic of)",
    },
    {
        portCode: "KRINC",
        portName: "Inchon",
        state: null,
        countryCode: "KR",
        countryName: "South Korea (the Republic of)",
    },
    {
        portCode: "KRKPO",
        portName: "Pohang",
        state: null,
        countryCode: "KR",
        countryName: "South Korea (the Republic of)",
    },
    {
        portCode: "KRMAS",
        portName: "Masan",
        state: null,
        countryCode: "KR",
        countryName: "South Korea (the Republic of)",
    },
    {
        portCode: "KRMOK",
        portName: "Mokpo",
        state: null,
        countryCode: "KR",
        countryName: "South Korea (the Republic of)",
    },
    {
        portCode: "KRPUS",
        portName: "Busan (Pusan)",
        state: null,
        countryCode: "KR",
        countryName: "South Korea (the Republic of)",
    },
    {
        portCode: "KRTGH",
        portName: "Tonghae",
        state: null,
        countryCode: "KR",
        countryName: "South Korea (the Republic of)",
    },
    {
        portCode: "KRUSN",
        portName: "Ulsan",
        state: null,
        countryCode: "KR",
        countryName: "South Korea (the Republic of)",
    },
    {
        portCode: "KRYOS",
        portName: "Yosu (Yeosu)",
        state: null,
        countryCode: "KR",
        countryName: "South Korea (the Republic of)",
    },
    {
        portCode: "KWMEA",
        portName: "Mina al Ahmadi",
        state: null,
        countryCode: "KW",
        countryName: "Kuwait",
    },
    {
        portCode: "KWMIS",
        portName: "Mina Saud",
        state: null,
        countryCode: "KW",
        countryName: "Kuwait",
    },
    {
        portCode: "KWSWK",
        portName: "Shuwaikh",
        state: null,
        countryCode: "KW",
        countryName: "Kuwait",
    },
    {
        portCode: "KYGEC",
        portName: "Georgetown, Grand Cayman",
        state: null,
        countryCode: "KY",
        countryName: "Cayman Islands",
    },
    {
        portCode: "LBBEY",
        portName: "Beirut",
        state: null,
        countryCode: "LB",
        countryName: "Lebanon",
    },
    {
        portCode: "LBCHK",
        portName: "Chekka",
        state: null,
        countryCode: "LB",
        countryName: "Lebanon",
    },
    {
        portCode: "LBKYE",
        portName: "Tripoli",
        state: null,
        countryCode: "LB",
        countryName: "Lebanon",
    },
    {
        portCode: "LBSDN",
        portName: "Sidon",
        state: null,
        countryCode: "LB",
        countryName: "Lebanon",
    },
    {
        portCode: "LBSEL",
        portName: "Selaata",
        state: null,
        countryCode: "LB",
        countryName: "Lebanon",
    },
    {
        portCode: "LCSLU",
        portName: "Castries (St Lucia)",
        state: null,
        countryCode: "LC",
        countryName: "Saint Lucia",
    },
    {
        portCode: "LCVIF",
        portName: "Vieux Fort",
        state: null,
        countryCode: "LC",
        countryName: "Saint Lucia",
    },
    {
        portCode: "LKCMB",
        portName: "Colombo",
        state: null,
        countryCode: "LK",
        countryName: "Sri Lanka",
    },
    {
        portCode: "LKJAF",
        portName: "Jaffna",
        state: null,
        countryCode: "LK",
        countryName: "Sri Lanka",
    },
    {
        portCode: "LKTRR",
        portName: "Trincomalee",
        state: null,
        countryCode: "LK",
        countryName: "Sri Lanka",
    },
    {
        portCode: "LRGRE",
        portName: "Greenville",
        state: null,
        countryCode: "LR",
        countryName: "Liberia",
    },
    {
        portCode: "LRMLW",
        portName: "Monrovia",
        state: null,
        countryCode: "LR",
        countryName: "Liberia",
    },
    {
        portCode: "LRUCN",
        portName: "Buchanan",
        state: null,
        countryCode: "LR",
        countryName: "Liberia",
    },
    {
        portCode: "LTKLJ",
        portName: "Klaipeda\n(includes sixsubports: )",
        state: null,
        countryCode: "LT",
        countryName: "Lithuania",
    },
    {
        portCode: "LVLPX",
        portName: "Liepaja",
        state: null,
        countryCode: "LV",
        countryName: "Latvia",
    },
    {
        portCode: "LVVNT",
        portName: "Ventspils",
        state: null,
        countryCode: "LV",
        countryName: "Latvia",
    },
    {
        portCode: "LYBEN",
        portName: "Bingazi (Benghazi)",
        state: null,
        countryCode: "LY",
        countryName: "Libya",
    },
    {
        portCode: "LYDNF",
        portName: "Derna",
        state: null,
        countryCode: "LY",
        countryName: "Libya",
    },
    {
        portCode: "LYLMQ",
        portName: "Marsa El Brega",
        state: null,
        countryCode: "LY",
        countryName: "Libya",
    },
    {
        portCode: "LYMRA",
        portName: "Misurata (Qasr Ahmed)",
        state: null,
        countryCode: "LY",
        countryName: "Libya",
    },
    {
        portCode: "LYRLA",
        portName: "Ras Lanuf",
        state: null,
        countryCode: "LY",
        countryName: "Libya",
    },
    {
        portCode: "LYTIP",
        portName: "Tripoli",
        state: null,
        countryCode: "LY",
        countryName: "Libya",
    },
    {
        portCode: "LYTOB",
        portName: "Tobruk",
        state: null,
        countryCode: "LY",
        countryName: "Libya",
    },
    {
        portCode: "LYZUA",
        portName: "Zuara",
        state: null,
        countryCode: "LY",
        countryName: "Libya",
    },
    {
        portCode: "MAAGA",
        portName: "Agadir",
        state: null,
        countryCode: "MA",
        countryName: "Morocco",
    },
    {
        portCode: "MACAS",
        portName: "Casablanca",
        state: null,
        countryCode: "MA",
        countryName: "Morocco",
    },
    {
        portCode: "MAELJ",
        portName: "El Jadida",
        state: null,
        countryCode: "MA",
        countryName: "Morocco",
    },
    {
        portCode: "MAMOH",
        portName: "Mohammedia",
        state: null,
        countryCode: "MA",
        countryName: "Morocco",
    },
    {
        portCode: "MANNA",
        portName: "Kenitra",
        state: null,
        countryCode: "MA",
        countryName: "Morocco",
    },
    {
        portCode: "MASFI",
        portName: "Safi",
        state: null,
        countryCode: "MA",
        countryName: "Morocco",
    },
    {
        portCode: "MATNG",
        portName: "Tangier",
        state: null,
        countryCode: "MA",
        countryName: "Morocco",
    },
    {
        portCode: "MCMON",
        portName: "Monaco",
        state: null,
        countryCode: "MC",
        countryName: "Monaco",
    },
    {
        portCode: "MGDIE",
        portName: "Antsiranana\n(Diego Suarez)",
        state: null,
        countryCode: "MG",
        countryName: "Madagascar",
    },
    {
        portCode: "MGMJN",
        portName: "Majunga (Mahajanga)",
        state: null,
        countryCode: "MG",
        countryName: "Madagascar",
    },
    {
        portCode: "MGTLE",
        portName: "Tulear (Toliara)",
        state: null,
        countryCode: "MG",
        countryName: "Madagascar",
    },
    {
        portCode: "MGTOA",
        portName: "Toamasina (Tamatave)",
        state: null,
        countryCode: "MG",
        countryName: "Madagascar",
    },
    {
        portCode: "MMBSX",
        portName: "Bassein",
        state: null,
        countryCode: "MM",
        countryName: "Myanmar",
    },
    {
        portCode: "MMMNU",
        portName: "Moulmein",
        state: null,
        countryCode: "MM",
        countryName: "Myanmar",
    },
    {
        portCode: "MMRGN",
        portName: "Yangon",
        state: null,
        countryCode: "MM",
        countryName: "Myanmar",
    },
    {
        portCode: "MOFM",
        portName: "Macau (Macao, Aomen)",
        state: null,
        countryCode: "MO",
        countryName: "Macao",
    },
    {
        portCode: "MQFDF",
        portName: "Fort de France",
        state: null,
        countryCode: "MQ",
        countryName: "Martinique",
    },
    {
        portCode: "MRNDB",
        portName: "Nouadhibou",
        state: null,
        countryCode: "MR",
        countryName: "Mauritania",
    },
    {
        portCode: "MRNKC",
        portName: "Nouakchott",
        state: null,
        countryCode: "MR",
        countryName: "Mauritania",
    },
    {
        portCode: "MSPLY",
        portName: "Plymouth",
        state: null,
        countryCode: "MS",
        countryName: "Montserrat",
    },
    {
        portCode: "MTMLA",
        portName: "Valletta",
        state: null,
        countryCode: "MT",
        countryName: "Malta",
    },
    {
        portCode: "MUPLU",
        portName: "Port Louis",
        state: null,
        countryCode: "MU",
        countryName: "Mauritius",
    },
    {
        portCode: "MXACA",
        portName: "Acapulco",
        state: null,
        countryCode: "MX",
        countryName: "Mexico",
    },
    {
        portCode: "MXCOA",
        portName: "Coatzacoalcos",
        state: null,
        countryCode: "MX",
        countryName: "Mexico",
    },
    {
        portCode: "MXGYM",
        portName: "Guaymas",
        state: null,
        countryCode: "MX",
        countryName: "Mexico",
    },
    {
        portCode: "MXLAP",
        portName: "La Paz",
        state: null,
        countryCode: "MX",
        countryName: "Mexico",
    },
    {
        portCode: "MXLZC",
        portName: "Lazaro Cardenas",
        state: null,
        countryCode: "MX",
        countryName: "Mexico",
    },
    {
        portCode: "MXMZT",
        portName: "Mazatlan",
        state: null,
        countryCode: "MX",
        countryName: "Mexico",
    },
    {
        portCode: "MXPGO",
        portName: "Progreso",
        state: null,
        countryCode: "MX",
        countryName: "Mexico",
    },
    {
        portCode: "MXSCX",
        portName: "Salina Cruz",
        state: null,
        countryCode: "MX",
        countryName: "Mexico",
    },
    {
        portCode: "MXTAM",
        portName: "Tampico",
        state: null,
        countryCode: "MX",
        countryName: "Mexico",
    },
    {
        portCode: "MXTPB",
        portName: "Topolobampo",
        state: null,
        countryCode: "MX",
        countryName: "Mexico",
    },
    {
        portCode: "MXTUX",
        portName: "Tuxpan",
        state: null,
        countryCode: "MX",
        countryName: "Mexico",
    },
    {
        portCode: "MXVER",
        portName: "Veracruz",
        state: null,
        countryCode: "MX",
        countryName: "Mexico",
    },
    {
        portCode: "MXZLO",
        portName: "Manzanillo",
        state: null,
        countryCode: "MX",
        countryName: "Mexico",
    },
    {
        portCode: "MYBKI",
        portName: "Kota Kinabalu, Sabah",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYBTU",
        portName: "Bintulu, Sarawak",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYKCH",
        portName: "Kuching, Sarawak",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYKUA",
        portName: "Kuantan (Tanjong Gelang)",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYKUD",
        portName: "Kudat, Sabah",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYLBU",
        portName: "Labuan, Sabah",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYLDU",
        portName: "Lahad Datu, Sabah",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYLUM",
        portName: "Lumut",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYMYY",
        portName: "Miri, Sarawak",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYPDI",
        portName: "Port Dickson",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYPEN",
        portName: "Penang (Georgetown)",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYPGU",
        portName: "Pasir Gudang, Johor",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYPKL",
        portName: "Port Klang (Kelang)",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYSBW",
        portName: "Sibu, Sarawak",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYSDK",
        portName: "Sandakan, Sabah",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYTPP",
        portName: "Tanjung Pelepas, Johor",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MYTWU",
        portName: "Tawau, Sabah",
        state: null,
        countryCode: "MY",
        countryName: "Malaysia",
    },
    {
        portCode: "MZBEW",
        portName: "Beira",
        state: null,
        countryCode: "MZ",
        countryName: "Mozambique",
    },
    {
        portCode: "MZINH",
        portName: "Inhambane",
        state: null,
        countryCode: "MZ",
        countryName: "Mozambique",
    },
    {
        portCode: "MZMNC",
        portName: "Nacala",
        state: null,
        countryCode: "MZ",
        countryName: "Mozambique",
    },
    {
        portCode: "MZMPM",
        portName: "Maputo",
        state: null,
        countryCode: "MZ",
        countryName: "Mozambique",
    },
    {
        portCode: "MZPOL",
        portName: "Pemba",
        state: null,
        countryCode: "MZ",
        countryName: "Mozambique",
    },
    {
        portCode: "MZUEL",
        portName: "Quelimane",
        state: null,
        countryCode: "MZ",
        countryName: "Mozambique",
    },
    {
        portCode: "NALUD",
        portName: "Luderitz",
        state: null,
        countryCode: null,
        countryName: "Namibia",
    },
    {
        portCode: "NAWVB",
        portName: "Walvis Bay",
        state: null,
        countryCode: null,
        countryName: "Namibia",
    },
    {
        portCode: "NCNOU",
        portName: "Noumea",
        state: null,
        countryCode: "NC",
        countryName: "New Caledonia",
    },
    {
        portCode: "NCTHI",
        portName: "Thio",
        state: null,
        countryCode: "NC",
        countryName: "New Caledonia",
    },
    {
        portCode: "NGCBQ",
        portName: "Calabar",
        state: null,
        countryCode: "NG",
        countryName: "Nigeria",
    },
    {
        portCode: "NGLOS",
        portName: "Lagos",
        state: null,
        countryCode: "NG",
        countryName: "Nigeria",
    },
    {
        portCode: "NGONN",
        portName: "Onne",
        state: null,
        countryCode: "NG",
        countryName: "Nigeria",
    },
    {
        portCode: "NGPHC",
        portName: "Port Harcourt",
        state: null,
        countryCode: "NG",
        countryName: "Nigeria",
    },
    {
        portCode: "NGSPL",
        portName: "Sapele",
        state: null,
        countryCode: "NG",
        countryName: "Nigeria",
    },
    {
        portCode: "NGTIN",
        portName: "Tin Can Island",
        state: null,
        countryCode: "NG",
        countryName: "Nigeria",
    },
    {
        portCode: "NGWAR",
        portName: "Warri",
        state: null,
        countryCode: "NG",
        countryName: "Nigeria",
    },
    {
        portCode: "NIBEF",
        portName: "Bluefields",
        state: null,
        countryCode: "NI",
        countryName: "Nicaragua",
    },
    {
        portCode: "NICIO",
        portName: "Corinto",
        state: null,
        countryCode: "NI",
        countryName: "Nicaragua",
    },
    {
        portCode: "NIELB",
        portName: "El Bluff",
        state: null,
        countryCode: "NI",
        countryName: "Nicaragua",
    },
    {
        portCode: "NIPSN",
        portName: "Puerto Sandino",
        state: null,
        countryCode: "NI",
        countryName: "Nicaragua",
    },
    {
        portCode: "NIPUZ",
        portName: "Puerto Cabezas",
        state: null,
        countryCode: "NI",
        countryName: "Nicaragua",
    },
    {
        portCode: "NISJS",
        portName: "San Juan Del Sur",
        state: null,
        countryCode: "NI",
        countryName: "Nicaragua",
    },
    {
        portCode: "NLAMS",
        portName: "Amsterdam",
        state: null,
        countryCode: "NL",
        countryName: "Netherlands",
    },
    {
        portCode: "NLDOR",
        portName: "Dordrecht",
        state: null,
        countryCode: "NL",
        countryName: "Netherlands",
    },
    {
        portCode: "NLDZL",
        portName: "Delfzijl",
        state: null,
        countryCode: "NL",
        countryName: "Netherlands",
    },
    {
        portCode: "NLEEM",
        portName: "Eemshaven",
        state: null,
        countryCode: "NL",
        countryName: "Netherlands",
    },
    {
        portCode: "NLGRQ",
        portName: "Groningen",
        state: null,
        countryCode: "NL",
        countryName: "Netherlands",
    },
    {
        portCode: "NLIJM",
        portName: "IJmuiden",
        state: null,
        countryCode: "NL",
        countryName: "Netherlands",
    },
    {
        portCode: "NLRTM",
        portName: "Rotterdam",
        state: null,
        countryCode: "NL",
        countryName: "Netherlands",
    },
    {
        portCode: "NLTNZ",
        portName: "Terneuzen",
        state: null,
        countryCode: "NL",
        countryName: "Netherlands",
    },
    {
        portCode: "NOBGO",
        portName: "Bergen",
        state: null,
        countryCode: "NO",
        countryName: "Norway",
    },
    {
        portCode: "NODRM",
        portName: "Drammen",
        state: null,
        countryCode: "NO",
        countryName: "Norway",
    },
    {
        portCode: "NOHAU",
        portName: "Haugesund",
        state: null,
        countryCode: "NO",
        countryName: "Norway",
    },
    {
        portCode: "NOHFT",
        portName: "Hammerfest",
        state: null,
        countryCode: "NO",
        countryName: "Norway",
    },
    {
        portCode: "NOHRD",
        portName: "Harstad",
        state: null,
        countryCode: "NO",
        countryName: "Norway",
    },
    {
        portCode: "NOKRS",
        portName: "Kristiansand",
        state: null,
        countryCode: "NO",
        countryName: "Norway",
    },
    {
        portCode: "NOLAR",
        portName: "Larvik",
        state: null,
        countryCode: "NO",
        countryName: "Norway",
    },
    {
        portCode: "NOOSL",
        portName: "Oslo",
        state: null,
        countryCode: "NO",
        countryName: "Norway",
    },
    {
        portCode: "NOSVG",
        portName: "Stavanger",
        state: null,
        countryCode: "NO",
        countryName: "Norway",
    },
    {
        portCode: "NOTRD",
        portName: "Trondheim",
        state: null,
        countryCode: "NO",
        countryName: "Norway",
    },
    {
        portCode: "NRINU",
        portName: "Nauru Island",
        state: null,
        countryCode: "NR",
        countryName: "Nauru",
    },
    {
        portCode: "NZAKL",
        portName: "Auckland",
        state: null,
        countryCode: "NZ",
        countryName: "New Zealand",
    },
    {
        portCode: "NZORR",
        portName: "Otago Harbour (Dunedin, Port Chalmers, Ravensbourne)",
        state: null,
        countryCode: "NZ",
        countryName: "New Zealand",
    },
    {
        portCode: "NZTRG",
        portName: "Tauranga",
        state: null,
        countryCode: "NZ",
        countryName: "New Zealand",
    },
    {
        portCode: "NZWLG",
        portName: "Wellington",
        state: null,
        countryCode: "NZ",
        countryName: "New Zealand",
    },
    {
        portCode: "PACTB",
        portName: "Cristobal",
        state: null,
        countryCode: "PA",
        countryName: "Panama",
    },
    {
        portCode: "PAONX",
        portName: "Colon",
        state: null,
        countryCode: "PA",
        countryName: "Panama",
    },
    {
        portCode: "PAVAC",
        portName: "Vacamonte",
        state: null,
        countryCode: "PA",
        countryName: "Panama",
    },
    {
        portCode: "PECHM",
        portName: "Chimbote",
        state: null,
        countryCode: "PE",
        countryName: "Peru",
    },
    {
        portCode: "PECLL",
        portName: "Callao",
        state: null,
        countryCode: "PE",
        countryName: "Peru",
    },
    {
        portCode: "PEILO",
        portName: "Ilo",
        state: null,
        countryCode: "PE",
        countryName: "Peru",
    },
    {
        portCode: "PEMRI",
        portName: "Matarani",
        state: null,
        countryCode: "PE",
        countryName: "Peru",
    },
    {
        portCode: "PEPAI",
        portName: "Paita",
        state: null,
        countryCode: "PE",
        countryName: "Peru",
    },
    {
        portCode: "PESVY",
        portName: "Salaverry",
        state: null,
        countryCode: "PE",
        countryName: "Peru",
    },
    {
        portCode: "PETYL",
        portName: "Talara",
        state: null,
        countryCode: "PE",
        countryName: "Peru",
    },
    {
        portCode: "PFPPT",
        portName: "Papeete",
        state: null,
        countryCode: "PF",
        countryName: "French Polynesia",
    },
    {
        portCode: "PGKIE",
        portName: "Kieta",
        state: null,
        countryCode: "PG",
        countryName: "Papua New Guinea",
    },
    {
        portCode: "PGLAE",
        portName: "Lae",
        state: null,
        countryCode: "PG",
        countryName: "Papua New Guinea",
    },
    {
        portCode: "PGMAG",
        portName: "Madang",
        state: null,
        countryCode: "PG",
        countryName: "Papua New Guinea",
    },
    {
        portCode: "PGPOM",
        portName: "Port Moresby",
        state: null,
        countryCode: "PG",
        countryName: "Papua New Guinea",
    },
    {
        portCode: "PGRAB",
        portName: "Rabaul",
        state: null,
        countryCode: "PG",
        countryName: "Papua New Guinea",
    },
    {
        portCode: "PHBTG",
        portName: "Batangas",
        state: null,
        countryCode: "PH",
        countryName: "Philippines",
    },
    {
        portCode: "PHCEB",
        portName: "Cebu",
        state: null,
        countryCode: "PH",
        countryName: "Philippines",
    },
    {
        portCode: "PHCGY",
        portName: "Cagayan de Oro,\nMindanao",
        state: null,
        countryCode: "PH",
        countryName: "Philippines",
    },
    {
        portCode: "PHDVO",
        portName: "Davao, Mindanao",
        state: null,
        countryCode: "PH",
        countryName: "Philippines",
    },
    {
        portCode: "PHIGN",
        portName: "Iligan, Mindanao",
        state: null,
        countryCode: "PH",
        countryName: "Philippines",
    },
    {
        portCode: "PHILO",
        portName: "Iloilo",
        state: null,
        countryCode: "PH",
        countryName: "Philippines",
    },
    {
        portCode: "PHJOL",
        portName: "Jolo",
        state: null,
        countryCode: "PH",
        countryName: "Philippines",
    },
    {
        portCode: "PHLGP",
        portName: "Legaspi, Davao",
        state: null,
        countryCode: "PH",
        countryName: "Philippines",
    },
    {
        portCode: "PHMNL",
        portName: "Manila",
        state: null,
        countryCode: "PH",
        countryName: "Philippines",
    },
    {
        portCode: "PHPPS",
        portName: "Puerto Princesa,\nPalawan",
        state: null,
        countryCode: "PH",
        countryName: "Philippines",
    },
    {
        portCode: "PHSFE",
        portName: "San Fernando, Luzon",
        state: null,
        countryCode: "PH",
        countryName: "Philippines",
    },
    {
        portCode: "PHSFS",
        portName: "Subic Bay",
        state: null,
        countryCode: "PH",
        countryName: "Philippines",
    },
    {
        portCode: "PHZAM",
        portName: "Zamboanga, Mindanao",
        state: null,
        countryCode: "PH",
        countryName: "Philippines",
    },
    {
        portCode: "PKBQM",
        portName: "Muhammad Bin Qasim",
        state: null,
        countryCode: "PK",
        countryName: "Pakistan",
    },
    {
        portCode: "PKKHI",
        portName: "Karachi",
        state: null,
        countryCode: "PK",
        countryName: "Pakistan",
    },
    {
        portCode: "PLGDN",
        portName: "Gdansk",
        state: null,
        countryCode: "PL",
        countryName: "Poland",
    },
    {
        portCode: "PLGDY",
        portName: "Gdynia",
        state: null,
        countryCode: "PL",
        countryName: "Poland",
    },
    {
        portCode: "PLKOL",
        portName: "Kolobrzeg",
        state: null,
        countryCode: "PL",
        countryName: "Poland",
    },
    {
        portCode: "PLSWI",
        portName: "Swinoujscie",
        state: null,
        countryCode: "PL",
        countryName: "Poland",
    },
    {
        portCode: "PLSZZ",
        portName: "Szczecin",
        state: null,
        countryCode: "PL",
        countryName: "Poland",
    },
    {
        portCode: "PLUST",
        portName: "Ustka",
        state: null,
        countryCode: "PL",
        countryName: "Poland",
    },
    {
        portCode: "PMFSP",
        portName: "St Pierre",
        state: null,
        countryCode: "PM",
        countryName: "Saint Pierre and Miquelon",
    },
    {
        portCode: "PRGUX",
        portName: "Guanica Harbour",
        state: null,
        countryCode: "PR",
        countryName: "Puerto Rico",
    },
    {
        portCode: "PRGUY",
        portName: "Guayanilla",
        state: null,
        countryCode: "PR",
        countryName: "Puerto Rico",
    },
    {
        portCode: "PRLAM",
        portName: "Las Mareas",
        state: null,
        countryCode: "PR",
        countryName: "Puerto Rico",
    },
    {
        portCode: "PRPSE",
        portName: "Ponce",
        state: null,
        countryCode: "PR",
        countryName: "Puerto Rico",
    },
    {
        portCode: "PRSJU",
        portName: "San Juan",
        state: null,
        countryCode: "PR",
        countryName: "Puerto Rico",
    },
    {
        portCode: "PTAVE",
        portName: "Aveiro",
        state: null,
        countryCode: "PT",
        countryName: "Portugal",
    },
    {
        portCode: "PTLEI",
        portName: "Leixoes",
        state: null,
        countryCode: "PT",
        countryName: "Portugal",
    },
    {
        portCode: "PTLIS",
        portName: "Lisbon",
        state: null,
        countryCode: "PT",
        countryName: "Portugal",
    },
    {
        portCode: "PTPRM",
        portName: "Portimao",
        state: null,
        countryCode: "PT",
        countryName: "Portugal",
    },
    {
        portCode: "PTSET",
        portName: "Setubal",
        state: null,
        countryCode: "PT",
        countryName: "Portugal",
    },
    {
        portCode: "PTVDC",
        portName: "Viana Do Castelo",
        state: null,
        countryCode: "PT",
        countryName: "Portugal",
    },
    {
        portCode: "QADOH",
        portName: "Doha",
        state: null,
        countryCode: "QA",
        countryName: "Qatar",
    },
    {
        portCode: "QAHAL",
        portName: "Halul Island",
        state: null,
        countryCode: "QA",
        countryName: "Qatar",
    },
    {
        portCode: "QARLF",
        portName: "Ras Laffan",
        state: null,
        countryCode: "QA",
        countryName: "Qatar",
    },
    {
        portCode: "QAUMS",
        portName: "Umm Said (Mesaieed)",
        state: null,
        countryCode: "QA",
        countryName: "Qatar",
    },
    {
        portCode: "REPDG",
        portName: "Port Reunion\n(Pointe des Galets)",
        state: null,
        countryCode: "RE",
        countryName: "Réunion",
    },
    {
        portCode: "ROBRA",
        portName: "Braila",
        state: null,
        countryCode: "RO",
        countryName: "Romania",
    },
    {
        portCode: "ROCND",
        portName: "Constantza",
        state: null,
        countryCode: "RO",
        countryName: "Romania",
    },
    {
        portCode: "ROMAG",
        portName: "Mangalia",
        state: null,
        countryCode: "RO",
        countryName: "Romania",
    },
    {
        portCode: "ROSUL",
        portName: "Sulina",
        state: null,
        countryCode: "RO",
        countryName: "Romania",
    },
    {
        portCode: "RUKHO",
        portName: "Kholmsk",
        state: null,
        countryCode: "RU",
        countryName: "Russian Federation",
    },
    {
        portCode: "RULED",
        portName: "St Petersburg",
        state: null,
        countryCode: "RU",
        countryName: "Russian Federation",
    },
    {
        portCode: "RUMMK",
        portName: "Murmansk",
        state: null,
        countryCode: "RU",
        countryName: "Russian Federation",
    },
    {
        portCode: "RUNJK",
        portName: "Nakhodka",
        state: null,
        countryCode: "RU",
        countryName: "Russian Federation",
    },
    {
        portCode: "RUNVS",
        portName: "Novorossiysk",
        state: null,
        countryCode: "RU",
        countryName: "Russian Federation",
    },
    {
        portCode: "RUTUA",
        portName: "Tuapse",
        state: null,
        countryCode: "RU",
        countryName: "Russian Federation",
    },
    {
        portCode: "RUVVO",
        portName: "Vladivostok",
        state: null,
        countryCode: "RU",
        countryName: "Russian Federation",
    },
    {
        portCode: "RUVYG",
        portName: "Vyborg",
        state: null,
        countryCode: "RU",
        countryName: "Russian Federation",
    },
    {
        portCode: "SADHU",
        portName: "Dhuba",
        state: null,
        countryCode: "SA",
        countryName: "Saudi Arabia",
    },
    {
        portCode: "SADMN",
        portName: "Dammam",
        state: null,
        countryCode: "SA",
        countryName: "Saudi Arabia",
    },
    {
        portCode: "SAGIZ",
        portName: "Gizan",
        state: null,
        countryCode: "SA",
        countryName: "Saudi Arabia",
    },
    {
        portCode: "SAJED",
        portName: "Jeddah",
        state: null,
        countryCode: "SA",
        countryName: "Saudi Arabia",
    },
    {
        portCode: "SAJUB",
        portName: "Jubail",
        state: null,
        countryCode: "SA",
        countryName: "Saudi Arabia",
    },
    {
        portCode: "SARAB",
        portName: "Rabigh",
        state: null,
        countryCode: "SA",
        countryName: "Saudi Arabia",
    },
    {
        portCode: "SARAM",
        portName: "Ras al Mishab",
        state: null,
        countryCode: "SA",
        countryName: "Saudi Arabia",
    },
    {
        portCode: "SARTA",
        portName: "Ras Tanura",
        state: null,
        countryCode: "SA",
        countryName: "Saudi Arabia",
    },
    {
        portCode: "SAYNB",
        portName: "Yanbu",
        state: null,
        countryCode: "SA",
        countryName: "Saudi Arabia",
    },
    {
        portCode: "SBHIR",
        portName: "Honiara",
        state: null,
        countryCode: "SB",
        countryName: "Solomon Islands",
    },
    {
        portCode: "SBNOR",
        portName: "Noro, New Georgia",
        state: null,
        countryCode: "SB",
        countryName: "Solomon Islands",
    },
    {
        portCode: "SBVIU",
        portName: "Viru Harbour",
        state: null,
        countryCode: "SB",
        countryName: "Solomon Islands",
    },
    {
        portCode: "SBXYA",
        portName: "Yandina, Pavuvu Island",
        state: null,
        countryCode: "SB",
        countryName: "Solomon Islands",
    },
    {
        portCode: "SCPOV",
        portName: "Port Victoria",
        state: null,
        countryCode: "SC",
        countryName: "Seychelles",
    },
    {
        portCode: "SDPZU",
        portName: "Port Sudan",
        state: null,
        countryCode: "SD",
        countryName: "Sudan",
    },
    {
        portCode: "SEGOT",
        portName: "Gothenburg (Goteborg)",
        state: null,
        countryCode: "SE",
        countryName: "Sweden",
    },
    {
        portCode: "SEGVX",
        portName: "Gavle",
        state: null,
        countryCode: "SE",
        countryName: "Sweden",
    },
    {
        portCode: "SEHAD",
        portName: "Halmstad",
        state: null,
        countryCode: "SE",
        countryName: "Sweden",
    },
    {
        portCode: "SEKAN",
        portName: "Karlshamn",
        state: null,
        countryCode: "SE",
        countryName: "Sweden",
    },
    {
        portCode: "SEKLR",
        portName: "Kalmar",
        state: null,
        countryCode: "SE",
        countryName: "Sweden",
    },
    {
        portCode: "SEMMA",
        portName: "Malmo",
        state: null,
        countryCode: "SE",
        countryName: "Sweden",
    },
    {
        portCode: "SESDL",
        portName: "Sundsvall",
        state: null,
        countryCode: "SE",
        countryName: "Sweden",
    },
    {
        portCode: "SESOL",
        portName: "Solvesborg",
        state: null,
        countryCode: "SE",
        countryName: "Sweden",
    },
    {
        portCode: "SESTO",
        portName: "Stockholm",
        state: null,
        countryCode: "SE",
        countryName: "Sweden",
    },
    {
        portCode: "SGSIN",
        portName: "Singapore",
        state: null,
        countryCode: "SG",
        countryName: "Singapore",
    },
    {
        portCode: "SHASI",
        portName: "Georgetown",
        state: null,
        countryCode: "SH",
        countryName: "Saint Helena, Ascension and Tristan da Cunha",
    },
    {
        portCode: "SHSHN",
        portName: "Jamestown",
        state: null,
        countryCode: "SH",
        countryName: "Saint Helena, Ascension and Tristan da Cunha",
    },
    {
        portCode: "SIIZO",
        portName: "Izola",
        state: null,
        countryCode: "SI",
        countryName: "Slovenia",
    },
    {
        portCode: "SIKOP",
        portName: "Koper",
        state: null,
        countryCode: "SI",
        countryName: "Slovenia",
    },
    {
        portCode: "SIPIR",
        portName: "Piran",
        state: null,
        countryCode: "SI",
        countryName: "Slovenia",
    },
    {
        portCode: "SLFNA",
        portName: "Freetown",
        state: null,
        countryCode: "SL",
        countryName: "Sierra Leone",
    },
    {
        portCode: "SLPEP",
        portName: "Pepel",
        state: null,
        countryCode: "SL",
        countryName: "Sierra Leone",
    },
    {
        portCode: "SNDKR",
        portName: "Dakar",
        state: null,
        countryCode: "SN",
        countryName: "Senegal",
    },
    {
        portCode: "SOBBO",
        portName: "Berbera",
        state: null,
        countryCode: "SO",
        countryName: "Somalia",
    },
    {
        portCode: "SOKMU",
        portName: "Kismayu",
        state: null,
        countryCode: "SO",
        countryName: "Somalia",
    },
    {
        portCode: "SOMER",
        portName: "Merca",
        state: null,
        countryCode: "SO",
        countryName: "Somalia",
    },
    {
        portCode: "SOMGQ",
        portName: "Mogadishu",
        state: null,
        countryCode: "SO",
        countryName: "Somalia",
    },
    {
        portCode: "SRAGI",
        portName: "Wageningen",
        state: null,
        countryCode: "SR",
        countryName: "Suriname",
    },
    {
        portCode: "SRICK",
        portName: "Nieuw Nickerie",
        state: null,
        countryCode: "SR",
        countryName: "Suriname",
    },
    {
        portCode: "SRMOJ",
        portName: "Moengo",
        state: null,
        countryCode: "SR",
        countryName: "Suriname",
    },
    {
        portCode: "SRPBM",
        portName: "Paramaribo",
        state: null,
        countryCode: "SR",
        countryName: "Suriname",
    },
    {
        portCode: "SRPRM",
        portName: "Paranam",
        state: null,
        countryCode: "SR",
        countryName: "Suriname",
    },
    {
        portCode: "STSAA",
        portName: "Santo Antonio",
        state: null,
        countryCode: "ST",
        countryName: "Sao Tome and Principe",
    },
    {
        portCode: "STTMS",
        portName: "Sao Tome",
        state: null,
        countryCode: "ST",
        countryName: "Sao Tome and Principe",
    },
    {
        portCode: "SVAQJ",
        portName: "Acajutla",
        state: null,
        countryCode: "SV",
        countryName: "El Salvador",
    },
    {
        portCode: "SVCUT",
        portName: "Cutuco",
        state: null,
        countryCode: "SV",
        countryName: "El Salvador",
    },
    {
        portCode: "SYBAN",
        portName: "Baniyas",
        state: null,
        countryCode: "SY",
        countryName: "Syrian Arab Republic",
    },
    {
        portCode: "TCGDT",
        portName: "Grand Turk Island",
        state: null,
        countryCode: "TC",
        countryName: "Turks and Caicos Islands",
    },
    {
        portCode: "TCPLS",
        portName: "Providenciales",
        state: null,
        countryCode: "TC",
        countryName: "Turks and Caicos Islands",
    },
    {
        portCode: "TGKPE",
        portName: "Kpeme",
        state: null,
        countryCode: "TG",
        countryName: "Togo",
    },
    {
        portCode: "TGLFW",
        portName: "Lome",
        state: null,
        countryCode: "TG",
        countryName: "Togo",
    },
    {
        portCode: "THBKK",
        portName: "Bangkok",
        state: null,
        countryCode: "TH",
        countryName: "Thailand",
    },
    {
        portCode: "THHKT",
        portName: "Phuket",
        state: null,
        countryCode: "TH",
        countryName: "Thailand",
    },
    {
        portCode: "THLCH",
        portName: "Laem Chabang",
        state: null,
        countryCode: "TH",
        countryName: "Thailand",
    },
    {
        portCode: "THPAN",
        portName: "Pattani",
        state: null,
        countryCode: "TH",
        countryName: "Thailand",
    },
    {
        portCode: "THSAT",
        portName: "Sattahip",
        state: null,
        countryCode: "TH",
        countryName: "Thailand",
    },
    {
        portCode: "THSGZ",
        portName: "Songkhla",
        state: null,
        countryCode: "TH",
        countryName: "Thailand",
    },
    {
        portCode: "THSRI",
        portName: "Sriracha",
        state: null,
        countryCode: "TH",
        countryName: "Thailand",
    },
    {
        portCode: "TNBIZ",
        portName: "Bizerte",
        state: null,
        countryCode: "TN",
        countryName: "Tunisia",
    },
    {
        portCode: "TNGAE",
        portName: "Gabes",
        state: null,
        countryCode: "TN",
        countryName: "Tunisia",
    },
    {
        portCode: "TNLGN",
        portName: "La Goulette",
        state: null,
        countryCode: "TN",
        countryName: "Tunisia",
    },
    {
        portCode: "TNSFA",
        portName: "Sfax",
        state: null,
        countryCode: "TN",
        countryName: "Tunisia",
    },
    {
        portCode: "TNSUS",
        portName: "Sousse",
        state: null,
        countryCode: "TN",
        countryName: "Tunisia",
    },
    {
        portCode: "TNTUN",
        portName: "Tunis",
        state: null,
        countryCode: "TN",
        countryName: "Tunisia",
    },
    {
        portCode: "TONEI",
        portName: "Neiafu",
        state: null,
        countryCode: "TO",
        countryName: "Tonga",
    },
    {
        portCode: "TOPAN",
        portName: "Pangai",
        state: null,
        countryCode: "TO",
        countryName: "Tonga",
    },
    {
        portCode: "TOTBU",
        portName: "Nukualofa",
        state: null,
        countryCode: "TO",
        countryName: "Tonga",
    },
    {
        portCode: "TRDIK",
        portName: "Dikili",
        state: null,
        countryCode: "TR",
        countryName: "Turkey",
    },
    {
        portCode: "TRDRC",
        portName: "Derince",
        state: null,
        countryCode: "TR",
        countryName: "Turkey",
    },
    {
        portCode: "TRGEM",
        portName: "Gemlik",
        state: null,
        countryCode: "TR",
        countryName: "Turkey",
    },
    {
        portCode: "TRHOP",
        portName: "Hopa, Artvin",
        state: null,
        countryCode: "TR",
        countryName: "Turkey",
    },
    {
        portCode: "TRISK",
        portName: "Iskenderun, Hatay",
        state: null,
        countryCode: "TR",
        countryName: "Turkey",
    },
    {
        portCode: "TRIST",
        portName: "Istanbul",
        state: null,
        countryCode: "TR",
        countryName: "Turkey",
    },
    {
        portCode: "TRTZX",
        portName: "Trabzon",
        state: null,
        countryCode: "TR",
        countryName: "Turkey",
    },
    {
        portCode: "TTPOS",
        portName: "Spain",
        state: null,
        countryCode: "TT",
        countryName: "Trinidad and Tobago",
    },
    {
        portCode: "TTPTF",
        portName: "Point Fortin",
        state: null,
        countryCode: "TT",
        countryName: "Trinidad and Tobago",
    },
    {
        portCode: "TTPTP",
        portName: "Pointe a Pierre",
        state: null,
        countryCode: "TT",
        countryName: "Trinidad and Tobago",
    },
    {
        portCode: "TTPTS",
        portName: "Point Lisas",
        state: null,
        countryCode: "TT",
        countryName: "Trinidad and Tobago",
    },
    {
        portCode: "TTSCA",
        portName: "Scarborough",
        state: null,
        countryCode: "TT",
        countryName: "Trinidad and Tobago",
    },
    {
        portCode: "TTTEM",
        portName: "Tembladora",
        state: null,
        countryCode: "TT",
        countryName: "Trinidad and Tobago",
    },
    {
        portCode: "TVFUN",
        portName: "Funafuti",
        state: null,
        countryCode: "TV",
        countryName: "Tuvalu",
    },
    {
        portCode: "TWHUN",
        portName: "Hualien",
        state: null,
        countryCode: "TW",
        countryName: "Taiwan (Province of China)",
    },
    {
        portCode: "TWKEL",
        portName: "Keelung (Chilung)",
        state: null,
        countryCode: "TW",
        countryName: "Taiwan (Province of China)",
    },
    {
        portCode: "TWKHH",
        portName: "Kaohsiung",
        state: null,
        countryCode: "TW",
        countryName: "Taiwan (Province of China)",
    },
    {
        portCode: "TWSUO",
        portName: "Suao",
        state: null,
        countryCode: "TW",
        countryName: "Taiwan (Province of China)",
    },
    {
        portCode: "TWTXG",
        portName: "Taichung",
        state: null,
        countryCode: "TW",
        countryName: "Taiwan (Province of China)",
    },
    {
        portCode: "TZDAR",
        portName: "Dar Es Salaam",
        state: null,
        countryCode: "TZ",
        countryName: "Tanzania, United Republic of",
    },
    {
        portCode: "TZKIM",
        portName: "Kilwa Masoko",
        state: null,
        countryCode: "TZ",
        countryName: "Tanzania, United Republic of",
    },
    {
        portCode: "TZLDI",
        portName: "Lindi",
        state: null,
        countryCode: "TZ",
        countryName: "Tanzania, United Republic of",
    },
    {
        portCode: "TZMYW",
        portName: "Mtwara",
        state: null,
        countryCode: "TZ",
        countryName: "Tanzania, United Republic of",
    },
    {
        portCode: "TZPAN",
        portName: "Pangani",
        state: null,
        countryCode: "TZ",
        countryName: "Tanzania, United Republic of",
    },
    {
        portCode: "TZTGT",
        portName: "Tanga",
        state: null,
        countryCode: "TZ",
        countryName: "Tanzania, United Republic of",
    },
    {
        portCode: "UAERD",
        portName: "Berdyansk",
        state: null,
        countryCode: "UA",
        countryName: "Ukraine",
    },
    {
        portCode: "UAKHE",
        portName: "Kherson",
        state: null,
        countryCode: "UA",
        countryName: "Ukraine",
    },
    {
        portCode: "UAODS",
        portName: "Odessa",
        state: null,
        countryCode: "UA",
        countryName: "Ukraine",
    },
    {
        portCode: "UARNI",
        portName: "Reni",
        state: null,
        countryCode: "UA",
        countryName: "Ukraine",
    },
    {
        portCode: "UASVP",
        portName: "Sevastopol",
        state: null,
        countryCode: "UA",
        countryName: "Ukraine",
    },
    {
        portCode: "USACI",
        portName: "Tacoma",
        state: "WA",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USAOT",
        portName: "Anacortes",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USASF",
        portName: "Ashtabula",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USBAL",
        portName: "Baltimore",
        state: "MD",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USBOS",
        portName: "Boston",
        state: "MA",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USBPT",
        portName: "Beaumont",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USBTR",
        portName: "Baton Rouge",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USCHI",
        portName: "Chicago",
        state: "IL",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USCHS",
        portName: "Charleston",
        state: "SC",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USCLE",
        portName: "Cleveland",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USCRP",
        portName: "Corpus Christi",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USDET",
        portName: "Detroit",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USDLH",
        portName: "Duluth-Superior",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USFRP",
        portName: "Freeport",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USHNL",
        portName: "Honolulu",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USHNV",
        portName: "Hampton Roads",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USHOU",
        portName: "Houston",
        state: "TX",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USHTS",
        portName: "Huntington - Tri-State",
        state: "WV",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USILG",
        portName: "Wilmington",
        state: "NC",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USILM",
        portName: "Wilmington",
        state: "DE",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USJAX",
        portName: "Jacksonville",
        state: "FL",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USLAX",
        portName: "Los Angeles",
        state: "CA",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USLCH",
        portName: "Lake Charles",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USLGB",
        portName: "Long Beach",
        state: "CA",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USLUA",
        portName: "South Louisiana",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USMEE",
        portName: "Manatee",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USMEM",
        portName: "Memphis",
        state: "TN",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USMIA",
        portName: "Miami",
        state: "FL",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USMOB",
        portName: "Mobile",
        state: "AL",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USMSY",
        portName: "New Orleans",
        state: "LA",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USNYC",
        portName: "New York",
        state: "NY",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USOAK",
        portName: "Oakland",
        state: "CA",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USORF",
        portName: "Norfolk",
        state: "VA",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USPDX",
        portName: "Portland",
        state: "OR",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USPEF",
        portName: "Port Everglades",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USPGL",
        portName: "Pascagoula",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USPHL",
        portName: "Philadelphia",
        state: "PA",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USPIT",
        portName: "Pittsburgh",
        state: "PA",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USPLQ",
        portName: "Plaquemines (Louisiana)",
        state: "LA",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USPOA",
        portName: "Port Arthur",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USPWM",
        portName: "Portland (Maine)",
        state: "ME",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USRCH",
        portName: "Richmond",
        state: "CA",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USRIC",
        portName: "Richmond",
        state: "VA",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USSAV",
        portName: "Savannah",
        state: "GA",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USSEA",
        portName: "Seattle",
        state: "WA",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USTPA",
        portName: "Tampa",
        state: "FL",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USTXT",
        portName: "Texas City",
        state: "TX",
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USVDZ",
        portName: "Valdez",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "USYAK",
        portName: "Yakutat",
        state: null,
        countryCode: "US",
        countryName: "USA",
    },
    {
        portCode: "UYFZB",
        portName: "Fray Bentos",
        state: null,
        countryCode: "UY",
        countryName: "Uruguay",
    },
    {
        portCode: "UYMVD",
        portName: "Montevideo",
        state: null,
        countryCode: "UY",
        countryName: "Uruguay",
    },
    {
        portCode: "UYNVP",
        portName: "Nueva Palmira",
        state: null,
        countryCode: "UY",
        countryName: "Uruguay",
    },
    {
        portCode: "UYPDU",
        portName: "Paysandu",
        state: null,
        countryCode: "UY",
        countryName: "Uruguay",
    },
    {
        portCode: "VCKTN",
        portName: "Kingstown",
        state: null,
        countryCode: "VC",
        countryName: "Saint Vincent and the Grenadines",
    },
    {
        portCode: "VEBJV",
        portName: "Bajo Grande Refinery",
        state: null,
        countryCode: "VE",
        countryName: "Venezuela (Bolivarian Republic of)",
    },
    {
        portCode: "VECUM",
        portName: "Cumana (Puerto Sucre)",
        state: null,
        countryCode: "VE",
        countryName: "Venezuela (Bolivarian Republic of)",
    },
    {
        portCode: "VELAG",
        portName: "La Guaira",
        state: null,
        countryCode: "VE",
        countryName: "Venezuela (Bolivarian Republic of)",
    },
    {
        portCode: "VELSV",
        portName: "La Salina",
        state: null,
        countryCode: "VE",
        countryName: "Venezuela (Bolivarian Republic of)",
    },
    {
        portCode: "VEMAR",
        portName: "Maracaibo",
        state: null,
        countryCode: "VE",
        countryName: "Venezuela (Bolivarian Republic of)",
    },
    {
        portCode: "VEMTV",
        portName: "Matanzas",
        state: null,
        countryCode: "VE",
        countryName: "Venezuela (Bolivarian Republic of)",
    },
    {
        portCode: "VEPBL",
        portName: "Puerto Cabello",
        state: null,
        countryCode: "VE",
        countryName: "Venezuela (Bolivarian Republic of)",
    },
    {
        portCode: "VEPCN",
        portName: "Punta Cardon",
        state: null,
        countryCode: "VE",
        countryName: "Venezuela (Bolivarian Republic of)",
    },
    {
        portCode: "VEPCZ",
        portName: "Puerto La Cruz",
        state: null,
        countryCode: "VE",
        countryName: "Venezuela (Bolivarian Republic of)",
    },
    {
        portCode: "VEPLA",
        portName: "Palua",
        state: null,
        countryCode: "VE",
        countryName: "Venezuela (Bolivarian Republic of)",
    },
    {
        portCode: "VICTD",
        portName: "Christiansted, St. Croix",
        state: null,
        countryCode: "VI",
        countryName: "Virgin Islands (U.S.)",
    },
    {
        portCode: "VILIB",
        portName: "Limetree Bay",
        state: null,
        countryCode: "VI",
        countryName: "Virgin Islands (U.S.)",
    },
    {
        portCode: "VISTT",
        portName: "Charlotte Amalie, St. Thomas",
        state: null,
        countryCode: "VI",
        countryName: "Virgin Islands (U.S.)",
    },
    {
        portCode: "VNDAD",
        portName: "Danang",
        state: null,
        countryCode: "VN",
        countryName: "Vietnam",
    },
    {
        portCode: "VNHON",
        portName: "Hon Gay",
        state: null,
        countryCode: "VN",
        countryName: "Vietnam",
    },
    {
        portCode: "VNHPH",
        portName: "Haiphong",
        state: null,
        countryCode: "VN",
        countryName: "Vietnam",
    },
    {
        portCode: "VNNHA",
        portName: "Nha Trang",
        state: null,
        countryCode: "VN",
        countryName: "Vietnam",
    },
    {
        portCode: "VNSGN",
        portName: "Ho Chi Minh City",
        state: null,
        countryCode: "VN",
        countryName: "Vietnam",
    },
    {
        portCode: "VUSAN",
        portName: "Santo",
        state: null,
        countryCode: "VU",
        countryName: "Vanuatu",
    },
    {
        portCode: "VUVLI",
        portName: "Port Vila",
        state: null,
        countryCode: "VU",
        countryName: "Vanuatu",
    },
    {
        portCode: "WSAPW",
        portName: "Apia",
        state: null,
        countryCode: "WS",
        countryName: "Samoa",
    },
    {
        portCode: "YEADE",
        portName: "Aden",
        state: null,
        countryCode: "YE",
        countryName: "Yemen",
    },
    {
        portCode: "YEHOD",
        portName: "Hodeidah",
        state: null,
        countryCode: "YE",
        countryName: "Yemen",
    },
    {
        portCode: "YEMKX",
        portName: "Mukalla",
        state: null,
        countryCode: "YE",
        countryName: "Yemen",
    },
    {
        portCode: "YEMOK",
        portName: "Mokha",
        state: null,
        countryCode: "YE",
        countryName: "Yemen",
    },
    {
        portCode: "ZACPT",
        portName: "Cape Town",
        state: null,
        countryCode: "ZA",
        countryName: "South Africa",
    },
    {
        portCode: "ZADUR",
        portName: "Durban",
        state: null,
        countryCode: "ZA",
        countryName: "South Africa",
    },
    {
        portCode: "ZAMZY",
        portName: "Mossel Bay",
        state: null,
        countryCode: "ZA",
        countryName: "South Africa",
    },
    {
        portCode: "ZAPLZ",
        portName: "Port Elizabeth",
        state: null,
        countryCode: "ZA",
        countryName: "South Africa",
    },
    {
        portCode: "ZARCB",
        portName: "Richards Bay",
        state: null,
        countryCode: "ZA",
        countryName: "South Africa",
    },
    {
        portCode: "ZASDB",
        portName: "Saldanha Bay",
        state: null,
        countryCode: "ZA",
        countryName: "South Africa",
    },
];
