import SkeletonLoaderItem from "../../../../../../ui-decorations/SkeletonLoader/SkeletonLoaderItem";
import styles from "./SkeletonShipmentTab.module.scss";

export const SkeletonShipmentTab = () => {
    return (
        <div>
            {Array.from(Array(8).keys()).map((_, index) => (
                <div className={styles.SkeletonShipmentTab} key={index}>
                    <SkeletonLoaderItem className={styles.LeftItem} />
                    <SkeletonLoaderItem className={styles.RightItem} />
                </div>
            ))}
        </div>
    );
};
