import "./AssistanceBlock.scss";
import Block from "../block/Block";
import { UnicargoLogo } from "../../../assets/images";
import { TextButton } from "../../buttons";
import { Dialog } from "@mui/material";
import { Fragment, useState } from "react";
import ContactUs from "../../contactus/ContactUs";
import SkeletonLoaderItem from "../../ui-decorations/SkeletonLoader/SkeletonLoaderItem";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";

interface AssistanceBlockProps {
    shouldShowLoader: boolean;
}
const renderLoader = (
    <div className="LoaderContainer">
        <SkeletonLoaderItem predefinedStyle="Circle" className="LeftCircle" />
        <SkeletonLoaderItem className="TopCenterLine" />
        <SkeletonLoaderItem className="RightLine" />
        <SkeletonLoaderItem className="BottomCenterLine" />
    </div>
);

const AssistanceBlock = ({ shouldShowLoader }: AssistanceBlockProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const letsTalkBtnPress = () => {
        setIsOpen(true);
        tryTrackEvent("[Overview Page]: 'Lets talk' button clicked");
    };

    return (
        <Block className="AssistanceBlock">
            {shouldShowLoader ? (
                renderLoader
            ) : (
                <Fragment>
                    <div className="assistance-content-container">
                        <div className="logo-container">
                            <UnicargoLogo />
                        </div>
                        <div className="assistance-message-container">
                            <div className="title">We are here to help!</div>
                            <div className="content">Our team will assist you in anything</div>
                        </div>
                    </div>
                    <div className="assistance-button-container">
                        <TextButton withArrowRight onClick={() => letsTalkBtnPress()} style={{ color: "#1D4650" }}>
                            Let's Talk
                        </TextButton>
                    </div>
                    <Dialog
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                        PaperProps={{
                            style: {
                                margin: "0",
                            },
                        }}
                    >
                        <ContactUs setIsOpen={setIsOpen} />
                    </Dialog>
                </Fragment>
            )}
        </Block>
    );
};

export default AssistanceBlock;
