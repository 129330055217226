import { EmptyStateGrayLines } from "../../../assets/icons";
import Block from "../block/Block";
import "./NotificationsEmptyState.scss";

export const NotificationsEmptyState = () => {
    return (
        <Block style={{ display: "flex", alignItems: "center", overflow: "hidden" }}>
            <div className="empty-state-container">
                <h2 className="title">Latest Updates</h2>
                <div className="sub-title"> 0 Recent Updates</div>
                <EmptyStateGrayLines className="empty-state-svg" />
            </div>
        </Block>
    );
};
