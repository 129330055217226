import { ETransportModes, IShipment } from "../../../../../../../models";
import classes from "./ShipmentTab.module.scss";
import { Fragment, useEffect, useState } from "react";
import { getShipmentCardIcon } from "../../../../../../cards/shipment-cards/shared/services/shipment-cards.service";
import UnicargoTooltip from "../../../../../../UnicargoTooltip/UnicargoTooltip";
import { ShipmentInfo } from "./sub-components/ShipmentInfo";
import { CargoDetails } from "./sub-components/CargoDetails";
import { DocumentDecorativeTopRightSvg } from "../../../../../../../assets/icons";

export const ShipmentTab = (shipment: IShipment) => {
    const [charLimit, setCharLimit] = useState(13);
    const { packageDetails, shipmentDetails } = shipment;
    const shipmentTypeIcon = getShipmentCardIcon(ETransportModes[shipmentDetails.transportMode]);

    useEffect(() => {
        const updateCharLimit = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth > 1800) {
                setCharLimit(20);
            } else if (screenWidth > 1600) {
                setCharLimit(17);
            } else if (screenWidth > 1400) {
                setCharLimit(14);
            } else {
                setCharLimit(11);
            }
        };

        updateCharLimit();

        window.addEventListener("resize", updateCharLimit);

        return () => {
            window.removeEventListener("resize", updateCharLimit);
        };
    }, []);

    const renderInfoItem = (labelToRender: string, data: any) => {
        const dataAsStr = data?.toString();
        const formattedDataValue = dataAsStr ? `${dataAsStr[0].toUpperCase()}${dataAsStr.slice(1)}` : "...";
        const shouldCellHaveTooltip = formattedDataValue.length > charLimit;
        const content = <span className={classes.FileInfoItemValueContent}>{formattedDataValue}</span>;
        return (
            <Fragment key={labelToRender}>
                <div className={classes.fieldContainer}>
                    <div className={`${classes.FileInfoItemKey}`}>{labelToRender}</div>
                    <div className={classes.FileInfoItemValueContainer}>
                        {shouldCellHaveTooltip ? (
                            <UnicargoTooltip
                                popperChildComponent={formattedDataValue}
                                child={content}
                                childClassName="childClassName"
                                popperClassName="popperClassName"
                            />
                        ) : (
                            content
                        )}
                    </div>
                </div>
            </Fragment>
        );
    };

    return (
        <div className={classes.root}>
            <ShipmentInfo
                shipmentDetails={shipmentDetails}
                renderInfoItem={renderInfoItem}
                charLimit={charLimit}
                shipmentTypeIcon={shipmentTypeIcon}
            />
            <CargoDetails
                packageDetails={packageDetails}
                shipmentDetails={shipmentDetails}
                renderInfoItem={renderInfoItem}
                charLimit={charLimit}
            />
        </div>
    );
};
