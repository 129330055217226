export interface IPostMessage {
    status: "success" | "error" | "cancel" | "pending";
    message: "customMessage";
    messageData: any;
}
export const sendCustomPostMessageToParent = (message: IPostMessage, targetOrigin = "*") => {
    window.parent.postMessage(message, targetOrigin);
};

export const receiveCustomPostMessageFromChild = (callback: (message: IPostMessage) => void) => {
    window.addEventListener("message", (event) => {
        if (event.data.message === "customMessage") {
            callback(event.data);
        }
    });
};
