import { useState } from "react";
import {
    DocumentDecorativeTopRightSvg,
    DownloadIcon,
    NoDocExistIcon,
    NoDocumentsLeftSvg,
    NoDocumentsRightSvg,
} from "../../../../../../../assets/icons";
import { IDoc } from "../../../../../../../models/cards/IDoc";
import {
    useGetShipmentDocsQuery,
    useLazyGetShipmentDocumentQuery,
} from "../../../../../../../store/api-slices/shipmentsSlice.api";
import UnicargoCheckbox from "../../../../../../UnicargoCheckbox/UnicargoCheckbox";
import { SkeletonDocumentTab } from "./SkeletonDocumentTab";
import useStyles from "./useStyles";
import { Document } from "./Document";

interface IDocumentTab {
    insured: string | null | undefined;
    valueForInsurance: number | null | undefined;
    shipmentNumber: string;
    customerCode: string;
}

export const DocumentsTab = ({ insured, valueForInsurance, shipmentNumber, customerCode }: IDocumentTab) => {
    const classes = useStyles();
    const { data, isSuccess, isFetching } = useGetShipmentDocsQuery({ shipmentNumber, customerCode });
    const [getShipmentDocument] = useLazyGetShipmentDocumentQuery();
    const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
    const [selectAllText, setSelectAllText] = useState("Select All");
    const includeInsuranceDocument = insured === "Yes" && valueForInsurance !== null;
    const isAtLeastOneDocumentChecked = selectedDocuments.length > 0;

    const downloadSelectedDocuments = async () => {
        toggleSelectAll();
        for (const securityId of selectedDocuments) {
            const doc = sortedDocs.find((doc) => doc.SecurityId === securityId);
            if (doc) {
                await getShipmentDocument({
                    customerCode,
                    shipmentNumber,
                    documentSecurityId: doc.SecurityId,
                    fileExtension: doc.FileExtension,
                    fileDownload: true,
                    fileName: doc.Name,
                });
            }
        }
    };

    const toggleDocumentSelection = (SecurityId: string) => {
        setSelectedDocuments((prevSelected) => {
            if (prevSelected.includes(SecurityId)) {
                const updatedSelectedDocuments = prevSelected.filter((id) => id !== SecurityId);
                setSelectAllText(updatedSelectedDocuments.length === 0 ? "Select All" : "Clear Selection");
                return updatedSelectedDocuments;
            } else {
                setSelectAllText("Clear Selection");
                return [...prevSelected, SecurityId];
            }
        });
    };

    const toggleSelectAll = () => {
        if (data) {
            if (selectedDocuments.length > 0) {
                setSelectedDocuments([]);
                setSelectAllText("Select All");
            } else {
                const manualSecurityId = "insurancePolicy";
                const docIds = data.docs.map((doc) => doc.SecurityId).filter((id) => id !== manualSecurityId);
                setSelectedDocuments([...docIds, manualSecurityId]);
                setSelectAllText("Clear Selection");
            }
        }
    };

    const customSort = (a: IDoc, b: IDoc): number => {
        const order: { [key: string]: number } = {
            "01 - Customer Quote": 1,
            "Packing List": 2,
            "Commercial Invoice": 3,
            // prettier-ignore
            "MSDS": 4,
            "Bill of Lading (Forwarder)": 5,
            "Bill of Lading": 6,
            "Proof Of Weight": 7,
            "Export Entry": 8,
            "Entry Summary": 9,
            "WHS Monthly Summary": 10,
            "Proof of Delivery": 11,
            "Insurance Policy": 12,
        };

        return order[a.Name] - order[b.Name];
    };

    if (isFetching) return <SkeletonDocumentTab />;

    const filterAndFindLatestQuote = (docs: IDoc[]): IDoc[] => {
        const quotes = docs.filter((doc) => doc.Name === "01 - Customer Quote");
        if (quotes.length <= 1) return docs;

        const latestQuote = quotes.reduce((latest, current) => {
            const latestDate = latest?.ReceivedDate ? new Date(latest.ReceivedDate) : null;
            const currentDate = current?.ReceivedDate ? new Date(current.ReceivedDate) : null;

            if (latestDate && currentDate) {
                return latestDate > currentDate ? latest : current;
            } else if (latestDate) {
                return latest;
            } else if (currentDate) {
                return current;
            } else {
                return latest;
            }
        });

        return docs.filter((doc) => doc.Name !== "01 - Customer Quote" || doc.DocumentId === latestQuote?.DocumentId);
    };

    let sortedDocs: IDoc[] = [];

    if (includeInsuranceDocument) {
        const insuranceDocument = {
            DocumentId: "insurancePolicy",
            Name: "Insurance Policy",
            SecurityId: "insurancePolicy",
            FileExtension: "pdf",
        };
        sortedDocs.push(insuranceDocument);
    }

    if (isSuccess && data && data.docs.length > 0) {
        const filteredDocs = filterAndFindLatestQuote(data.docs);
        const shipmentDocs = filteredDocs.slice().sort(customSort);
        sortedDocs = [...shipmentDocs, ...sortedDocs];
    }

    if (sortedDocs.length > 0) {
        return (
            <section className={classes.root}>
                <div className={classes.docsContainer}>
                    {sortedDocs.map((doc) => (
                        <Document
                            key={doc.DocumentId}
                            shipmentNumber={shipmentNumber}
                            customerCode={customerCode}
                            doc={doc}
                            isSelected={selectedDocuments.includes(doc.SecurityId)}
                            toggleDocumentSelection={toggleDocumentSelection}
                        />
                    ))}
                    <DocumentDecorativeTopRightSvg className={classes.decorativeTopRight} />
                </div>
                <section className={classes.actionButtonsContainer}>
                    <div className={classes.selectContainer} onClick={toggleSelectAll}>
                        <UnicargoCheckbox onClick={toggleSelectAll} isChecked={isAtLeastOneDocumentChecked} />
                        <span>{selectAllText}</span>
                    </div>
                    <div
                        className={`${classes.downloadSelectedContainer} ${
                            isAtLeastOneDocumentChecked ? classes.downloadSelectedContainerVisible : ""
                        }`}
                        onClick={downloadSelectedDocuments}
                    >
                        <div className={classes.greenCircle}></div>
                        <DownloadIcon />
                        <span>Download Selected</span>
                    </div>
                </section>
            </section>
        );
    } else {
        return (
            <section className={classes.root}>
                <NoDocumentsLeftSvg className={classes.noDocumentsLeft} />
                <div className={classes.noDocContainer}>
                    <NoDocExistIcon className={classes.noDocIcon} />
                    No documents found
                </div>
                <NoDocumentsRightSvg className={classes.noDocumentsRight} />
            </section>
        );
    }
};
