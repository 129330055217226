import { ButtonClassKey } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";
import { COLORS } from "../../../data/ui";

export const buttonStyles: Partial<OverridesStyleRules<ButtonClassKey>> = {
    root: {
        textTransform: "none",
        color: "#3B4858",
    },
    contained: {
        borderRadius: "32px",
        backgroundColor: COLORS.success,
        color: COLORS.primaryDark,
        ":hover": {
            backgroundColor: COLORS.successLight,
        }
    },
    outlined: {
        border: "1px solid #BDBFC2",
        ":hover": {
            border: "1px solid #BDBFC2",
            opacity: "0.7",
            ":active": {
                opacity: "0.9",
            },
        },
    },
    text: {
        ":hover": {
            backgroundColor: "transparent",
            opacity: "0.7",
            ":active": {
                opacity: "0.9",
            },
        },
    },
};
