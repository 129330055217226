import { FunctionComponent } from "react";
import styles from "./LineSeparator.module.scss";

interface LineSeparatorProps {
    height?: string;
    width?: string;
    backgroundColor?: string;
    className?: string;
}

export const LineSeparator: FunctionComponent<LineSeparatorProps> = ({
    className = "",
    height,
    width,
    backgroundColor,
}: LineSeparatorProps) => {
    return <div className={`${styles.DefaultLineSeparator} ${className}`} style={{ height, width, backgroundColor }} />;
};
