import { unicargoApiSlice } from "../apiSlice";
import { IInvoiceResponse, IInvoicesDocsResponse } from "../../models/http";
import { IInvoiceParamsForProcessing, buildGetInvoicesBody } from "../../helpers/services/InvoiceService";
import { IShipmentDocsResponse } from "../../models/http/IShipmentDocsResponse";

interface IQueryParams {
    customerCode: string;
    params: IInvoiceParamsForProcessing;
}
export interface GetInvoicesSheetsDTO extends IQueryParams {
    params: IInvoiceParamsForProcessing & { isComplex: boolean };
}

interface IInvoiceDocumentRequest {
    customerCode: string;
    params: {
        invoiceNumber: string;
        shipmentNumber: string;
    };
}

interface IShipmentDocsForInvoice {
    customerCode: string;
    params: { shipmentNumber: string };
}

const endpointPrefix = "invoices";
export const invoicesApiSlice = unicargoApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getInvoicesByCustomerCode: builder.query<IInvoiceResponse, IQueryParams>({
            query: ({ customerCode, params }) => ({
                url: `/${endpointPrefix}/${customerCode}`,
                method: "POST",
                body: buildGetInvoicesBody(params),
            }),
        }),
        getInvoicesDataForDownloadByCustomerCode: builder.query<ReadableStream, GetInvoicesSheetsDTO>({
            query: ({ customerCode, params }) => ({
                url: `/${endpointPrefix}/${customerCode}/sheets`,
                method: "POST",
                body: buildGetInvoicesBody(params),
                responseHandler: async (response) => {
                    if (response.status === 200) {
                        console.log("Success while downloading invoices data: ", response);
                        return;
                    }
                    if (response.status === 201) {
                        const blob = await response.blob();
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        const fileName = params.isComplex ? "Extended.xlsx" : "Summary.xlsx";
                        link.download = fileName;
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                        return;
                    }
                    console.error("Error while downloading invoices data: ", response);
                },
            }),
        }),
        getInvoiceDocuments: builder.query<IInvoicesDocsResponse, IInvoiceDocumentRequest>({
            query: ({ customerCode, params }) => ({
                url: `/${endpointPrefix}/${customerCode}/document`,
                method: "POST",
                body: params,
                responseHandler: async (response) => {
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);

                    // This will open the file in a new tab without a custom name
                    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
                    if (newWindow) {
                        newWindow.opener = null;
                    }
                },
                cache: "no-cache",
            }),
        }),
        getShipmentDocsForInvoice: builder.query<IShipmentDocsResponse, IShipmentDocsForInvoice>({
            query: ({ customerCode, params }) => ({
                url: `/${endpointPrefix}/${customerCode}/shipment-documents-for-invoice`,
                method: "POST",
                body: params,
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetInvoicesByCustomerCodeQuery,
    useLazyGetInvoicesByCustomerCodeQuery,
    useLazyGetInvoiceDocumentsQuery,
    useGetShipmentDocsForInvoiceQuery,
    useLazyGetInvoicesDataForDownloadByCustomerCodeQuery,
} = invoicesApiSlice;
