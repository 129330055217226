import { IChip } from "../../../models";
import { Chip, TextButton } from "../../buttons";
import Block from "../block/Block";
import { STRINGS } from "./resources";
import useStyles from "./useStyles";
import GenericButton, { ButtonTypeStyles } from "../../buttons/GenericButton/GenericButton";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";

interface ILocationSelectBox {
    options: string[];
    onSelect: (event: any) => void; // TODO: update type
}

interface IShipmentsFilter {
    statusChips: IChip[];
    typeChips: IChip[];
    originSelectBox: ILocationSelectBox;
    destinationSelectBox: ILocationSelectBox;
    onConfirmCallback: () => void;
    onResetCallback: () => void;
    onCloseCallback?: () => void;
}

const ShipmentsFilterPopup = ({ statusChips, typeChips, onConfirmCallback, onResetCallback }: IShipmentsFilter) => {
    const classes = useStyles();

    const handleApplyFilters = () => {
        tryTrackEvent("[Shipment Page]: 'Apply filters' button clicked");
        onConfirmCallback();
    };

    return (
        <Block className={classes.root}>
            <div className={classes.FilterPopupHead}>{STRINGS.popupTitle}</div>
            <div className={classes.FilterPopupBody}>
                <div className={classes.StatusContainer}>
                    <div className={classes.FilterSubTitle}>{STRINGS.SHIPMENT_STATUS}</div>
                    <div className={classes.StatusChipsContainer}>
                        {statusChips?.map((chip, index) => (
                            <Chip {...chip} key={`${chip.label}${index}`} />
                        ))}
                    </div>
                </div>
                <div className={classes.TypeContainer}>
                    <div className={classes.FilterSubTitle}>{STRINGS.SHIPMENT_TYPE}</div>
                    <div className={classes.TypeChipsContainer}>
                        {typeChips?.map((chip, index) => {
                            return <Chip {...chip} key={`${chip.label}${index}`} />;
                        })}
                    </div>
                </div>
                {/* Bring back the select boxes to here */}
            </div>
            <div className={classes.FilterPopupFooter}>
                <GenericButton
                    onClick={handleApplyFilters}
                    text={STRINGS.APPLY_FILTERS}
                    initialBtnTypeStyle={ButtonTypeStyles.FilledStyle}
                ></GenericButton>
                <TextButton onClick={() => onResetCallback()} className={classes.ResetAllButton}>
                    {STRINGS.RESET_ALL}
                </TextButton>
            </div>
        </Block>
    );
};

export default ShipmentsFilterPopup;
