import { FunctionComponent } from "react";
import { ReactComponent as SortIconSvg } from "../../assets/icons/sort-arrow.svg";
import styles from "./SortIconComponent.module.scss";
import { SortOrder } from "../../models/filters/filters.interfaces";

interface SortIconComponentProps {
    sortIconState: SortOrder;
    onClick: (sortIconState: SortOrder) => void;
    className?: string;
}

const SortIconComponent: FunctionComponent<SortIconComponentProps> = ({
    sortIconState = SortOrder.NONE,
    onClick,
    className = "",
}: SortIconComponentProps) => {
    return (
        <div className={`${styles.SortIconComponent} ${styles[sortIconState]} ${className}`}>
            <SortIconSvg className={styles.SortIconSvg} onClick={() => onClick(sortIconState)} />
        </div>
    );
};

export default SortIconComponent;
