import uiSchemeV2 from "../styles/v2-styles/UiScheme.module.scss";

// Colors palette
export const COLORS: IColors = {
    white: "#ffffff", // Gray/White
    light: "#bdbfc2", // Gray/Light
    veryLight: "#DCDCDC", // Gray/Very Light
    medium: "#777c83", // Gray/Medium
    main: "#3b4858", // Gray/Dark
    success: "#06CEA8",
    successLight: "#04DBB2",
    error: "#f47a36",
    primaryDark: "#1D4650", // Dark grey (new design)

    system: {
        // system colors
        origin: uiSchemeV2.originLightBlue,
        originMedium: uiSchemeV2.originMedium,
        originDark: uiSchemeV2.originDark,
        inTransit: uiSchemeV2.secondaryGreen,
        inTransitMedium: uiSchemeV2.inTransitMedium,
        inTransitDark: uiSchemeV2.inTransitDark,
        destination: uiSchemeV2.destinationPurple,
        destinationMedium: uiSchemeV2.destinationMedium,
        destinationDark: uiSchemeV2.destinationDark,
        destinationLight: uiSchemeV2.destinationLight,
        completed: uiSchemeV2.grey100,
    },
    general: {
        // general component's colors
        dividers: "#dcdcdc", // Gray/Very light
        dashboardBG: "#f3f3f3",
        componentsBG: "#ffffff",
        lightBlueBG: "#D1E0F2",
        lightOrangeBG: "#FFF9E6",
        lightGreenBG: "#E4FBF6",
        lightDarkBG: "#D8DADE",
        shipmentDetailsBG: "rgba(209, 224, 242, 0.3)",
    },
    interactions: {
        selectedCardBG: "rgba(209, 224, 242, 0.3)",
    },
};

interface IColors {
    white: string;
    light: string;
    medium: string;
    veryLight: string;
    main: string;
    success: string;
    successLight: string;
    error: string;
    primaryDark: string;
    system: ISystemColors;
    general: IGeneralColors;
    interactions: IInteractionColors;
}

interface IGeneralColors {
    dividers: string;
    dashboardBG: string;
    componentsBG: string;
    lightGreenBG: string;
    lightBlueBG: string;
    lightOrangeBG: string;
    lightDarkBG: string;
    shipmentDetailsBG: string;
}
interface ISystemColors {
    [key: string]: string;
    origin: string;
    inTransit: string;
    destination: string;
    destinationLight: string;
    completed: string;
}

interface IInteractionColors {
    selectedCardBG: string;
}

// Typography
export const TYPOGRAPHY = {
    title: "14px",
    titleLarge: "16px",
    titleExtraLarge: "18px",
    text: "12px",
    textSmall: "10px",
};

export const UiSchemeV2 = {
    colors: {
        primaryDark: uiSchemeV2.primaryDark,
        primaryLight: uiSchemeV2.primaryLight,
        secondaryLight: uiSchemeV2.secondaryLight,
        primaryRed: uiSchemeV2.primaryRed,
        secondaryRed: uiSchemeV2.secondaryRed,
        secondaryGreen: uiSchemeV2.secondaryGreen,
        secondaryLime: uiSchemeV2.secondaryLime,
        inTransitMedium: uiSchemeV2.inTransitMedium,
        inTransitDark: uiSchemeV2.inTransitDark,
        destinationPurple: uiSchemeV2.destinationPurple,
        destinationMedium: uiSchemeV2.destinationMedium,
        destinationDark: uiSchemeV2.destinationDark,
        originLightBlue: uiSchemeV2.originLightBlue,
        originMedium: uiSchemeV2.originMedium,
        originDark: uiSchemeV2.originDark,
        primaryBackground: uiSchemeV2.primaryBackground,
        greys: {
            grey100: uiSchemeV2.grey100,
            grey200: uiSchemeV2.grey200,
            grey300: uiSchemeV2.grey300,
            white: uiSchemeV2.white,
            background: uiSchemeV2.greyBackground,
        },
        tags: {
            unpaid: uiSchemeV2.tagsUnpaid,
            partiallyPaid: uiSchemeV2.tagsPartiallyPaid,
            paid: uiSchemeV2.tagsPaid,
            credit: uiSchemeV2.tagsCredit,
            processing: uiSchemeV2.tagsProcessing,
        },
    },
    fontSizes: {
        h2: uiSchemeV2.fontSizesH2,
        p: uiSchemeV2.fontSizesP,
        mainPrices: uiSchemeV2.fontSizesMainPrices,
        secondaryPrices: uiSchemeV2.fontSizesSecondaryPrices,
    },
    breakpoints: {
        mobile: uiSchemeV2.mobileMinWidth,
        tablet: uiSchemeV2.tabletMinWidth,
        desktop: uiSchemeV2.desktopMinWidth,
        desktopLarge: uiSchemeV2.desktopLargeMinWidth,
    },
    zIndices: {
        zIndexBackground: uiSchemeV2.zIndexBackground,
        zIndexBackgroundImage: uiSchemeV2.zIndexBackgroundImage,
        zIndexBase: uiSchemeV2.zIndexBase,
        zIndexHigherOrderElements: uiSchemeV2.zIndexHigherOrderElements,
        zIndexPopups: uiSchemeV2.zIndexPopups,
    },
};

// Breakpoints
export enum ScreenWidthType {
    MOBILE = 900,
    LARGE = 1194,
    SMALL_DESKTOP = 1440,
    EXTRA_LARGE = 1900,
}

export const BREAKPOINTS = {
    xs: 0,
    sm: 600,
    md: ScreenWidthType.MOBILE,
    lg: ScreenWidthType.LARGE,
    xl: ScreenWidthType.EXTRA_LARGE,
};
