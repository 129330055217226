import { IPostMessage } from "../../helpers/services/PostMessageService.service";

export interface IPaymentPostMessage extends IPostMessage {
    status: "success" | "error" | "cancel" | "pending";
    message: "customMessage";
    messageData: string;
}

export enum YaadSarigCurrency {
    // Based on the Yaad Sarig API docs: https://yaadpay.docs.apiary.io/#introduction/pay-protocol/parameters-pay-protocol
    ILS = 1,
    NIS = 1,
    USD = 2,
    EUR = 3,
    GBP = 4,
}
