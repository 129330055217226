import { FunctionComponent } from "react";
import styles from "./NavigationBarTooltip.module.scss";
import { ReactComponent as AnnouncementIcon } from "../../../assets/icons/announcement.svg";
import { ReactComponent as CloseIconV2 } from "../../../assets/icons/closeV2.svg";
import { TooltipText } from "./NavigationBarTooltipUtil";

interface NavigationBarTooltipProps {
    onClose: () => void;
}

export const NavigationBarTooltip: FunctionComponent<NavigationBarTooltipProps> = ({ onClose }) => {
    return (
        <div className={styles.navigationBarTooltip}>
            <AnnouncementIcon className={styles.announcementIcon} />
            <span className={styles.tooltipText}>{TooltipText.mainText}</span>
            <CloseIconV2 className={styles.closeIcon} onClick={onClose} />
        </div>
    );
};
