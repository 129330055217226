import { FunctionComponent } from "react";
import styles from "./DotsLoader.module.scss";

export interface DotsLoaderProps {
    className?: string;
    predefinedStyle?: "Bar" | "Circle";
    isLoading?: boolean;
    customStyle?: React.CSSProperties;
    animationDuration?: number;
    onClick?: () => void;
}

const DotsLoader: FunctionComponent<DotsLoaderProps> = ({
    className = "",
    predefinedStyle = "Circle",
    isLoading = true,
    animationDuration = 1,
    customStyle,
    onClick,
}) => {
    return (
        <div
            className={`${styles.DotsLoader} ${isLoading && styles.Loading} ${styles[predefinedStyle]} ${className}`}
            style={{ ...customStyle, animationDuration: `${animationDuration}s` }}
            onClick={onClick}
        ></div>
    );
};

export default DotsLoader;
