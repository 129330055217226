import dayjs from "dayjs";
import {
    DescriptionAndDateType,
    IFormattedPrimaryDetailStep,
    IFormattedStep,
    ScheduleType,
    ShipmentMoveType,
} from "./types";
import { getFormattedDate } from "../../helpers/services";

export const formatDate = (date?: string | string[] | undefined | null) => {
    if (!date) return [];
    else if (Array.isArray(date)) {
        if (!date.length) return [];
        const formattedDates = date.map((date) => getFormattedDate(date));
        return formattedDates;
    }
    return [getFormattedDate(date)];
};

export const getStepTitle = (steps: IFormattedStep[], primaryDetails?: IFormattedPrimaryDetailStep[], isInland?: boolean) => {
    // If it's an Inland shipment, we are starting to count from pickup date and not from shipment created date
    const firstStep = isInland ? steps[0] : primaryDetails ? primaryDetails[0] : steps[0];
    const lastStep = steps[steps.length - 1] || primaryDetails?.[primaryDetails.length - 1];

    let firstStepDate = null;
    let isFirstStepEstimate = false;
    if (isFormattedPrimaryDetailStep(firstStep)) {
        firstStepDate = firstStep.date;
    } else {
        const actualDate = firstStep?.descriptionsAndDates?.[0]?.actualDates[0];
        const estimateDate = firstStep?.descriptionsAndDates?.[0]?.estimatedDates.slice(-1)[0];

        firstStepDate = actualDate || estimateDate;

        if (estimateDate && !actualDate) isFirstStepEstimate = true;
    }

    let lastStepDate = null;
    let isLastStepEstimate = false;
    if (isFormattedPrimaryDetailStep(lastStep)) {
        lastStepDate = lastStep.date;
    } else {
        const actualDate = lastStep?.descriptionsAndDates.slice(-1)?.[0].actualDates.slice(-1)?.[0];
        const estimateDate = lastStep?.descriptionsAndDates.slice(-1)?.[0].estimatedDates.slice(-1)?.[0];

        lastStepDate = actualDate || estimateDate;

        if (estimateDate && !actualDate) isLastStepEstimate = true;
    }

    if (!lastStepDate && firstStepDate) return `${firstStepDate}`;

    if (!firstStepDate && !lastStepDate) return "";

    if (!firstStepDate) return `${lastStepDate}`;

    if (!lastStepDate) return `${firstStepDate}`;

    // Can happen only in "in transit" card when the mainCarriage.ETD/ATD is in the "origin" card
    if (steps.length === 1) return `${firstStepDate}`;

    const firstDate = `${firstStepDate} ${isFirstStepEstimate ? "(Est.)" : ""}`;
    const lastDate = `${lastStepDate} ${isLastStepEstimate ? "(Est.)" : ""}`;

    const totalDays = dayjs(lastDate).diff(dayjs(firstDate), "day");

    const dayOrDays = totalDays === 1 ? "Day" : "Days";

    return `${firstDate} - ${lastDate} | ${totalDays} ${dayOrDays}`;
};

export function capitalizeFirstLetter(str: string) {
    return str
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join("");
}

export const getShipmentMoveType = (moveTypeEnglishName: string | null | undefined): ShipmentMoveType => {
    if (moveTypeEnglishName) {
        moveTypeEnglishName = capitalizeFirstLetter(moveTypeEnglishName);
    }

    if (
        !moveTypeEnglishName ||
        !["DoorToDoor", "DoorToPort", "PortToPort", "PortToDoor"].includes(
            moveTypeEnglishName.trim().replace(/\s+/g, ""),
        )
    ) {
        // TODO - DELETE THIS AFTER CHEN RESPONSE - FOR NOW, THIS IS THE DEFAULT IF MOVE TYPE NOT PROVIDED/INVALID
        return "DoorToDoor";
    } else {
        return moveTypeEnglishName.replace(/\s+/g, "") as ShipmentMoveType;
    }
};

export const getDescriptionAndDateType = (
    actualDates: string[],
    estimatedDates: string[],
    scheduleType: ScheduleType,
    landTransportModeHideDate?: boolean,
): DescriptionAndDateType => {
    if (scheduleType === "ATDNull" || scheduleType === "ATANull" || landTransportModeHideDate) {
        return null;
    }

    const actualDate = actualDates[0];
    const uniqueEstimatedDates = [...new Set(estimatedDates)];

    const noActualAndEstimateExists = !actualDate && uniqueEstimatedDates.length === 1;
    const actualExistsAndEqualEstimated =
        actualDate && uniqueEstimatedDates.length === 1 && uniqueEstimatedDates[0] === actualDate;
    const actualExistAndNoEstimated = actualDate && uniqueEstimatedDates.length === 0;

    if (noActualAndEstimateExists || actualExistsAndEqualEstimated || actualExistAndNoEstimated) {
        return "ONE_DATE";
    }

    const noActualAndEstimatedLength2 = !actualDate && uniqueEstimatedDates.length === 2;
    const actualExistsAndDiffEstimatedLength1 =
        actualDate && uniqueEstimatedDates.length === 1 && uniqueEstimatedDates[0] !== actualDate;
    if (noActualAndEstimatedLength2 || actualExistsAndDiffEstimatedLength1) {
        return "TWO_DATES";
    }

    const noActualAndEstimatedLengthMultiple = !actualDate && uniqueEstimatedDates.length > 2;
    const actualExistsAndDiffEstimatedMoreThen2 =
        actualDate &&
        uniqueEstimatedDates.length > 1 &&
        uniqueEstimatedDates[uniqueEstimatedDates.length - 1] !== actualDate;
    const actualExistsAndEqualEstimatedMoreThen2 =
        actualDate &&
        uniqueEstimatedDates.length > 1 &&
        uniqueEstimatedDates[uniqueEstimatedDates.length - 1] === actualDate;
    if (
        noActualAndEstimatedLengthMultiple ||
        actualExistsAndDiffEstimatedMoreThen2 ||
        actualExistsAndEqualEstimatedMoreThen2
    ) {
        return "MULTIPLE_DATES";
    }

    return null;
};

export function isFormattedPrimaryDetailStep(step: any): step is IFormattedPrimaryDetailStep {
    return step && (step as IFormattedPrimaryDetailStep).title !== undefined;
}

export const isStepGroupDone = (firstStep: IFormattedStep, lastStep: IFormattedStep) => {
    return firstStep.isDone && lastStep.isDone;
};

export const getStepDate = (step: IFormattedStep) => {
    return step.descriptionsAndDates[0].actualDates[0];
};

export const finishedAllStepsInOneDay = (steps: IFormattedStep[]) => {
    if (steps.length === 0) return false;
    const firstStep = steps[0];
    const lastStep = steps[steps.length - 1];

    if (isStepGroupDone(firstStep, lastStep)) {
        return getStepDate(firstStep) === getStepDate(lastStep);
    }
    return false;
};

/**
 * will check the steps if the first step date and the last are on the same
 * day, if so, it will set the total days taken to 1, and return the correct step title
 * otherwise, it will return the original string
 *
 * @param {string} stepGropName - the step gorup (e.g. inTransit)
 * @param {IFormattedStep[]} steps - the steps array in the step group
 * @param {string} stepTitle -  the title before checking one day steps completion
 * @returns the correct stepTitle string
 */
export const formatStepTitleOnOneDayStepsCompletion = (
    stepGropName: string,
    steps: IFormattedStep[],
    stepTitle: string,
) => {
    // In every step group other then "origin", check the step title and change the number of days to 1 day if needed
    if (stepGropName !== "origin") {
        if (finishedAllStepsInOneDay(steps)) {
            return stepTitle.replace("0 Days", "1 Day");
        }
    }
    return stepTitle;
};
