import { useEffect, useState } from "react";
import AccordionCard from "./AccordionCard";
import formatSteps from "./formatSteps";
import Stepper from "./Stepper";
import { CardName, IAccordionState, IAccordionStepper } from "./types";
import { UiSchemeV2 } from "../../assets/data/ui";
import Pill from "./Pill";
import BookingCard from "./BookingCard";
import "./AccordionStepper.scss";

const initialAccordionState: IAccordionState = {
    origin: false,
    inTransit: false,
    destination: false,
};

const AccordionStepper = ({ steps, shipmentDetails, preDelivery, postDelivery }: IAccordionStepper) => {
    const [accordionState, setAccordionState] = useState<IAccordionState>(initialAccordionState);
    const { dotColor, currentStep, nextStep, primaryDetails, originSteps, inTransitSteps, destinationSteps } =
        formatSteps({
            steps,
            preDelivery,
            postDelivery,
            shipmentDetails,
        });

    useEffect(() => {
        setAccordionState({ ...initialAccordionState, [currentStep.cardName]: true });
    }, [steps]);

    const handleAccordionChange = (name: CardName) => {
        setAccordionState((prev) => ({ ...prev, [name]: !prev[name] }));
    };

    const mainCarriageStep = steps.find((step) => step.stepName === "mainCarriage");
    const bookingStep = primaryDetails.find((step) => step.name === "booking");

    // Check if the ETD array in the mainCarriage step is not empty
    const isETDNotEmpty = mainCarriageStep && mainCarriageStep.ETD && mainCarriageStep.ETD.length > 0;

    const landTransportMode = shipmentDetails.transportMode === "Inland";

    return (
        <div className="my-route-container">
            <Pill
                currentStep={currentStep}
                nextStep={nextStep}
                isInland={landTransportMode}
                shouldShowTooltip={false}
            />
            <div className="accordion-cards-and-stepper-and-icon-container">
                <div className="accordion-cards-and-stepper-container">
                    <Stepper
                        steps={steps}
                        accordionState={accordionState}
                        dotColor={dotColor}
                        shipmentDetails={shipmentDetails}
                    />
                    {!landTransportMode ? (
                        <div className="accordion-stepper-container">
                            <AccordionCard
                                nextStep={nextStep}
                                primaryDetails={primaryDetails}
                                isExpended={accordionState.origin}
                                name="origin"
                                title="Origin"
                                steps={originSteps}
                                handleOnExpand={handleAccordionChange}
                                color={UiSchemeV2.colors.originMedium}
                                originalSteps={steps}
                                dotColor={dotColor}
                                shipmentDetails={shipmentDetails}
                            />
                            <AccordionCard
                                nextStep={nextStep}
                                isExpended={accordionState.inTransit}
                                handleOnExpand={handleAccordionChange}
                                name="inTransit"
                                title="In Transit"
                                steps={inTransitSteps}
                                color={UiSchemeV2.colors.inTransitMedium}
                                originalSteps={steps}
                                dotColor={dotColor}
                                shipmentDetails={shipmentDetails}
                            />
                            {shipmentDetails.moveTypeEnglishName?.toLocaleLowerCase() !== "port to port" &&
                                shipmentDetails.moveTypeEnglishName?.toLocaleLowerCase() !== "door to port" && (
                                    <AccordionCard
                                        nextStep={nextStep}
                                        isExpended={accordionState.destination}
                                        handleOnExpand={handleAccordionChange}
                                        name="destination"
                                        title="Destination"
                                        steps={destinationSteps}
                                        color={UiSchemeV2.colors.destinationPurple}
                                        originalSteps={steps}
                                        dotColor={dotColor}
                                        shipmentDetails={shipmentDetails}
                                    />
                                )}
                        </div>
                    ) : (
                        <div className="accordion-stepper-container">
                            <AccordionCard
                                shipmentDetails={shipmentDetails}
                                primaryDetails={primaryDetails}
                                nextStep={nextStep}
                                isExpended={true}
                                name=""
                                title=""
                                steps={originSteps.concat(inTransitSteps).concat(destinationSteps)}
                                color=""
                                originalSteps={steps}
                                dotColor={dotColor}
                            />
                        </div>
                    )}
                </div>
                <div className="booking-card-container">
                    {accordionState.origin &&
                        primaryDetails &&
                        bookingStep?.isDone &&
                        isETDNotEmpty &&
                        !shipmentDetails.specialServicesEnglishName?.startsWith("Express") && (
                            <BookingCard steps={steps} mainCarriageFinalETA={shipmentDetails.mainCarriageFinalETA} />
                        )}
                </div>
            </div>
        </div>
    );
};
export default AccordionStepper;
