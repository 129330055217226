/**
 * Represents the statusCode field of the INotification model.
 * ! For future developers: add the new field to the end, or else it'll mix up with the data that comes from the server!
 */
export enum ENotificationIcons {
    // Default + Positive
    shipmentBooked,
    shipmentAvailable,
    telexReleasePositive,
    customsCleared,
    podScanned,
    expectedDeparture,
    actualDeparture,
    expectedPickupOrigin,
    expectedPickupDest,
    actualPickupOrigin,
    actualPickupDest,
    expectedCargoReadyDate,
    expectedArrivalToPort,
    actualCargoReadyDate,
    actualArrivalToPort,
    shipmentArrived,
    // negatives
    expPickupFromOriginChanged,
    expDepartureChanged,
    expArrivalToPortChanged,
    exptPickupToDeliveryChanged,
    exptCargoReadyDateChanged,
}
