import { useState, useEffect } from "react";
import { setStorageItem, getStorageItem } from "../services";

/**
 * Custom hook for storage usage.
 * Will be relevant for usage inside function components.
 * @param key the storage's key
 * @param defaultValue initial value
 * @returns [value, setValue] state objects.
 */
export const useLocalStorage = (key: string, defaultValue: any) => {
    const [value, setValue] = useState(() => {
        return getStorageItem(key) || defaultValue;
    });

    useEffect(() => {
        // storing input name
        setStorageItem(key, value);
    }, [key, value]);

    return [value, setValue];
};
