import styles from "./NotificationsBlock.module.scss";
import { INotification } from "../../../models";
import Block from "../block/Block";
import { useCallback, useEffect, useState } from "react";
import getNotificationCardByType from "./shared/NotificationsBlock.service";
import {
    useLazyGetNotificationsByCustomerCodeQuery,
    useUpdateNotificationSeenStatusMutation,
} from "../../../store/api-slices/notificationsSlice.api";
import { useAppSelector } from "../../../store";
import { STRINGS } from "./shared/resources";
import { formatNumberWithDecimal } from "../../../helpers/services/DateFormatsService.service";
import { MarkAllIcon } from "../../../assets/icons";
import { useScreenWidthType } from "../../../helpers/custom-hooks/useScreenWidthType";
import { ScreenWidthType } from "../../../assets/data/ui";
import SkeletonLoaderItem from "../../ui-decorations/SkeletonLoader/SkeletonLoaderItem";
import NotificationLoaderCard from "./NotificationCardLoader";
import { NotificationsEmptyState } from "./NotificationsEmptyState";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";

interface INotificationsBlock {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    notifications?: INotification[];
    shouldShowLoader: boolean;
    onFinishedLoading: () => void;
}

const NotificationsBlockClassName = "NotificationsBlock";

const renderLoader = (
    <div className={styles.LoaderContainer}>
        <div className={styles.TopContainer}>
            <SkeletonLoaderItem className={styles.TopLeftLoader} />
            <SkeletonLoaderItem className={styles.TopRightLoader} />
        </div>
        <NotificationLoaderCard />
        <NotificationLoaderCard />
    </div>
);

const renderErrorView = (
    <div className={styles.ErrorContainer}>
        <div className={styles.ErrorTitle}>Something went wrong...</div>
        <div className={styles.ErrorSubTitle}>Please try again later</div>
    </div>
);

const NotificationsBlock = ({ setIsOpen, shouldShowLoader, onFinishedLoading }: INotificationsBlock) => {
    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;
    const userState = useAppSelector((state) => state.rootReducer.users);
    const [
        getNotifications,
        {
            currentData: { notifications, count } = { notifications: [], count: 0 },
            isError,
            isFetching,
            isLoading,
            isSuccess,
        },
    ] = useLazyGetNotificationsByCustomerCodeQuery();
    const [triggerPut] = useUpdateNotificationSeenStatusMutation();
    const [accumulatedNotifications, setAccumulatedNotifications] = useState<INotification[]>([]);
    const [totalNotificationsCount, setTotalNotificationsCount] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [allNotificationsLoaded, setAllNotificationsLoaded] = useState(false);
    const [localLoading, setLocalLoading] = useState(true);

    const initializeNotifications = async () => {
        setAccumulatedNotifications([]);
        setTotalNotificationsCount(null);
        setCurrentPage(1);
        setAllNotificationsLoaded(false);
        setLocalLoading(true);

        try {
            const { notifications: newNotifications, count } = await getNotifications({
                customerCode: userState.code,
                page: 1,
                limit: 20,
            }).unwrap();
            setAccumulatedNotifications(newNotifications);
            setTotalNotificationsCount(count);
            setAllNotificationsLoaded(newNotifications.length < 20);
        } catch (error) {
            console.error("Error fetching notifications:", error);
        } finally {
            setLocalLoading(false);
        }
    };

    useEffect(() => {
        initializeNotifications();
    }, [userState.code]);

    useEffect(() => {
        if (!allNotificationsLoaded) {
            getNotifications({ customerCode: userState.code, page: currentPage, limit: 20 })
                .unwrap()
                .then(({ notifications: newNotifications, count }) => {
                    if (totalNotificationsCount === null) {
                        setTotalNotificationsCount(count);
                    }

                    // If the number of new notifications is less than the limit, assume all have been loaded
                    setAllNotificationsLoaded(newNotifications.length < 20);

                    // Append new notifications to the accumulated array
                    setAccumulatedNotifications((prevNotifications) => [...prevNotifications, ...newNotifications]);
                });
        }
    }, [currentPage, getNotifications, allNotificationsLoaded]);

    useEffect(() => {
        if (!isFetching && !isLoading) {
            onFinishedLoading();
        }
    }, [isLoading, isFetching, localLoading]);

    const handleScroll = useCallback((event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
            // Fetching next page
            setCurrentPage((prevPage) => prevPage + 1);
        }
    }, []);

    const setNotificationContainerRef = useCallback(
        (node) => {
            if (node) {
                node.addEventListener("scroll", handleScroll);

                return () => {
                    node.removeEventListener("scroll", handleScroll);
                };
            }
        },
        [handleScroll],
    );

    const handleMarkClicked = async (notificationId: number) => {
        await triggerPut(notificationId);
        await getNotifications({ customerCode: userState.code });
        tryTrackEvent(
            "[Overview Page]: 'Dismiss' button clicked on one specific card (in the 'Latest Updates' component)",
        );
    };

    const renderNotifications = () =>
        accumulatedNotifications
            .filter((notification) => !notification.isSeen)
            .map((data, index) => getNotificationCardByType(data, handleMarkClicked, `${data.id}-${index}`));

    if (shouldShowLoader || localLoading) {
        return renderLoader;
    }

    if (isError) {
        return renderErrorView;
    }

    const handleDismissAll = () => {
        tryTrackEvent("[Overview Page]: 'Dismiss all' button clicked (in the latest updates component)");
        setIsOpen(true);
    };

    if (isSuccess && accumulatedNotifications?.length) {
        return (
            <Block className={styles[NotificationsBlockClassName]}>
                <div className={styles.titleContainer}>
                    <div className={styles.titleWrapper}>
                        <h2 className={styles.title}>{STRINGS.title}</h2>
                        <div className={styles.subTitle}>{`${formatNumberWithDecimal(totalNotificationsCount)} ${
                            STRINGS.subTitle
                        }`}</div>
                    </div>

                    <div className={styles.clearNotificationsButton} onClick={handleDismissAll}>
                        <MarkAllIcon />
                        <span>Dismiss all</span>
                    </div>
                </div>
                <div
                    className={`${styles.notificationCardsContainer} ${isMobile && styles.mobile}`}
                    ref={setNotificationContainerRef}
                >
                    {renderNotifications()}
                </div>
            </Block>
        );
    }

    return isMobile ? <></> : <NotificationsEmptyState />;
};

export default NotificationsBlock;
