import { createSlice } from "@reduxjs/toolkit";

interface ISearchBarSlice {
    autoFocus: boolean;
}
const initialState: ISearchBarSlice = {
    autoFocus: false,
};
const searchBarSlice = createSlice({
    name: "searchBar",
    initialState,
    reducers: {
        handleFocus(state) {
            state.autoFocus = !state.autoFocus;
        },
    },
});

export const { handleFocus } = searchBarSlice.actions;
export default searchBarSlice.reducer;
