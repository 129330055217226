import { useEffect, useState } from "react";

export const useSearchDebounce = (value: string, timeoutInMs = 300): string => {
    const [debounceValue, setDebounceValue] = useState(value);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebounceValue(value);
        }, timeoutInMs); // 0.3s default debounce time

        return () => clearTimeout(timeoutId);
    }, [value]);

    return debounceValue;
};
