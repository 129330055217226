import SkeletonLoaderItem from "../../ui-decorations/SkeletonLoader/SkeletonLoaderItem";
import { STRINGS } from "./shared/resources";
import PillContainer from "../../PillContainer/PillContainer";
import { OutlinedButton } from "../../buttons";
import styles from "./ShipmentsDetailsBlockHeaderLoader.module.scss";

const ShipmentsDetailsBlockHeaderLoader = () => {
    return (
        <div className={styles.ShipmentsDetailsBlockHeaderLoader}>
            <div className={styles.FirstRowHeaderContainer}>
                <div className={styles.LeftSideContainer}>
                    <div className={styles.FirstRow}>
                        <SkeletonLoaderItem className={styles.FirstRowLeftItem} />
                        <SkeletonLoaderItem className={styles.FirstRowRightItem} />
                    </div>
                    <div className={styles.SecondRow}>
                        <SkeletonLoaderItem className={styles.SecondRowItem} />
                    </div>
                </div>
                <div className={styles.RightSideContainer}>
                    <OutlinedButton className={styles.contactUsButton}>{STRINGS.buttonText}</OutlinedButton>
                </div>
            </div>
            <PillContainer className={styles.PillContainer} />
        </div>
    );
};

export default ShipmentsDetailsBlockHeaderLoader;
