import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../../assets/data/ui";
import { theme } from "../../../../assets/theme/theme";

export const useStyles = makeStyles({
    radioButton: {
        display: "flex",
        gap: "20px",
        [theme.breakpoints.down("sm")]: {
            gap: "5px",
        },
    },
    smallRadioButton: {
        "& svg": {
            width: "16px",
            height: "16px",
        },
        "& .MuiTypography-root": {
            fontSize: "16px",
            lineHeight: "17px",
        },
        "&.MuiFormControlLabel-root": {
            marginRight: "0px",
            marginLeft: "0px",

            color: COLORS.medium,
            "& .Mui-checked": {
                color: COLORS.main,
                "& + .MuiTypography-root": {
                    color: COLORS.main,
                },
            },
        },
    },
});
