import { DropdownItemtype } from "../../models/rfq/rfq-types";

export const HeaderTexts = {
    mainHeader: "Got questions?",
    subHeader: "We got answers!",
};
export const SuccessModalTextFirst = "Thanks for your message!";
export const SuccessModalTextSecound = "Our team is on it and will get back to you soon.";

export const shipmentDropdownOptions: DropdownItemtype[] = [
    {
        value: "Shipment 1",
    },
];

export const subjectDropdownOptions: DropdownItemtype[] = [
    {
        value: "I want a quote!",
    },
    {
        value: "I have a question",
    },
    {
        value: "I need assistance",
    },
];
