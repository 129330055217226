import { MenuProps } from "@mui/material";

export const menuPropsStyles = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left",
    },
    PaperProps: {
        sx: {
            padding: "0px",
            maxHeight: "150px",
            maxWidth: "186px !important",
            paddingLeft: "0px",
            margin: "10px 0",
            borderRadius: "16px",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
        },
    },
} as MenuProps;
