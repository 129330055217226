import { makeStyles } from "@mui/styles";
import { COLORS, TYPOGRAPHY } from "../../../assets/data/ui";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
    },
    stepperRoot: {},
    stepperContainer: {},
    stepMobile: {
        padding: "0 !important",
    },
    stepLabel: {
        "&.MuiStepLabel-root": {
            padding: "0",
            "& .MuiStepLabel-iconContainer": {
                padding: "0",
            },
        },
    },
    detailsContainer: {
        display: "flex",
        flexDirection: "column",
    },
    nextStepLabel: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        alignCenter: "center",
        color: COLORS.light,
        fontSize: TYPOGRAPHY.text,
    },
    detailsContentContainer: {
        display: "flex",
        justifyContent: "space-between",
        gap: "1rem",
    },
    activeStepDetails: {
        display: "flex",
        flexWrap: "wrap",
    },
    activeStepDate: {},
    detailsContentIcon: {
        "& svg": {
            "& path": {
                fill: COLORS.system.destination,
            },
            "& circle": {
                stroke: COLORS.system.destination,
            },
        },
    },
    nextStepDetails: {
        display: "flex",
        flexWrap: "wrap",
    },
    nextStepDate: {
        fontWeight: "bold",
    },
});

export default useStyles;
