import {
    IThirdPhaseFormInput,
    PreviousStepsTitles,
    RfqStep,
    finalPhaseFormInitialValue,
} from "../../../models/rfq/rfq-types";
import { useAppDispatch, useAppSelector } from "../../../store";
import useStyles from "./useStyles";
import PreviousStepsBanner from "../../../components/RFQ/PreviousStepsBanner/PreviousStepsBanner";
import { Box } from "@mui/material";
import { ButtonText, HeaderTexts, radioOptions, SubmitButtonText } from "./thirdPhaseFormUtils";
import { FormInputRadio } from "../../../components/RFQ/form-elements/radio/FormInputRadio";
import { useForm } from "react-hook-form";
import { ContainedButton } from "../../../components";
import { resetRfqForms, updateThirdPhase } from "../../../store/reducers/rfqSlice.store";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useModal } from "../hooks/useModal";
import { ShipmentMethodType } from "../RfqPageUtils";
import RfqSuccessModal from "../../../components/RFQ/modal/success-modal/RfqSuccessModal";
import { useSendSubmitRfqMutation } from "../../../store/api-slices/rfqSlice.api";
import { useNavigate } from "react-router-dom";
import { getStorageItem } from "../../../helpers/services";
import { ICustomer } from "../../../models";
import { SESSION_STORAGE } from "../../../assets/data/resources";
import { TextFieldContactUs } from "../../../components/RFQ/form-elements/text-field/textField";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";
import { EventMessages, sendGoogleAnalyticsEvent } from "../../../helpers/services/GoogleAnalyticsService.service";

const ThirdPhaseForm: React.FC<{
    updateFormState: React.Dispatch<React.SetStateAction<RfqStep>>;
}> = ({ updateFormState }) => {
    const { firstPhaseForm, secondPhaseForm, thirdPhaseForm, shipmentMethod } = useAppSelector(
        (state) => state.rootReducer.rfq,
    );
    const user = useAppSelector((state) => state.rootReducer.users);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { authStatus } = useAuthenticator();
    const [isOpenSuccessModal, toggleSuccessModal] = useModal(false);
    const [triggerRfq, rfqResult] = useSendSubmitRfqMutation();
    const navigate = useNavigate();
    const methods = useForm<IThirdPhaseFormInput>({
        defaultValues: {
            ...thirdPhaseForm,
        },
    });
    const { handleSubmit, control, watch, getValues } = methods;
    watch("isReadyTime");

    const onSubmit = (data: IThirdPhaseFormInput) => {
        if (authStatus !== "authenticated") {
            tryTrackEvent("Review and Finalize button in step 3 in rfq clicked");

            dispatch(updateThirdPhase(data));
            updateFormState(RfqStep.FinalStep);
        } else {
            const referrer = document.referrer;
            sendGoogleAnalyticsEvent(EventMessages.SubmitButtonClicked, referrer);

            tryTrackEvent("[Rfq - Step 3]: 'Submit' button clicked");

            const customerData = getStorageItem<ICustomer>(SESSION_STORAGE.customerData);

            triggerRfq({
                firstPhaseForm: firstPhaseForm,
                secondPhaseForm: secondPhaseForm,
                thirdPhaseForm: data,
                finalPhaseForm: {
                    ...finalPhaseFormInitialValue,
                    email: user.email,
                    fullName: user.name,
                    customerCode: user.code,
                    companyName: customerData?.customerName ? customerData.customerName : "",
                },
                shipmentMethod: shipmentMethod,
            });
            toggleSuccessModal();
        }
    };
    const handleSuccesModalClose = () => {
        toggleSuccessModal();
        dispatch(resetRfqForms());
        authStatus === "authenticated" ? navigate("/overview") : window.location.replace("https://www.unicargo.com");
        updateFormState(RfqStep.FirstStep);
    };

    const methodsTypes = shipmentMethod.map((method: number) => ShipmentMethodType[method]);
    return (
        <Box className={classes.formWrapper}>
            <PreviousStepsBanner
                currentPhaseNumber={RfqStep.ThirdStep}
                setFormPhase={updateFormState}
                stepNumber={RfqStep.FirstStep}
                stepTitle={PreviousStepsTitles.FirstPhase}
                details={{
                    type: methodsTypes,
                    from: firstPhaseForm.fromBannerData?.label as string,
                    to: firstPhaseForm.destinationBannerData?.label as string,
                    fullFromAddress: firstPhaseForm.fromAddress,
                    fullToAddress: firstPhaseForm.destinationAddress,
                }}
            />
            <PreviousStepsBanner
                currentPhaseNumber={RfqStep.ThirdStep}
                setFormPhase={updateFormState}
                stepNumber={RfqStep.SecondStep}
                stepTitle={PreviousStepsTitles.SecondPhase}
                details={{
                    isTotalShipment: secondPhaseForm.isTotalShipment,
                    totalShipmentCalculation: secondPhaseForm.totalShipmentCalculation || "",
                    quantity: secondPhaseForm.quantity || "",
                    cargoType: secondPhaseForm.cargoType,
                    desc: secondPhaseForm.description,
                    weight: secondPhaseForm.weight,
                    weightUnit: secondPhaseForm.weightUnit,
                    volume: secondPhaseForm.volume,
                    volumeUnit: secondPhaseForm.volumeUnit,
                    fullVsPart: secondPhaseForm.fullVsPart || "",
                    palletType: secondPhaseForm.palletType || "",
                }}
            />
            <Box className={classes.headerWrapper}>
                <span className={classes.header}>{HeaderTexts.stepOne}</span>
            </Box>
            <Box className={classes.inputsRadioWrapper}>
                <FormInputRadio
                    name="isReadyTime"
                    control={control}
                    required={true}
                    label={""}
                    options={radioOptions}
                    isChip
                />
            </Box>
            <Box className={classes.headerWrapper}>
                <span className={classes.header}>{HeaderTexts.additionalTextHeader}</span>
            </Box>
            <Box className={classes.additionalTextareaWrapper}>
                <TextFieldContactUs
                    size="md"
                    type="text"
                    name="additionalToAdd"
                    control={control}
                    required={false}
                    label={""}
                    notes={true}
                />
            </Box>
            <ContainedButton
                disabled={getValues("isReadyTime") === ""}
                className={`${classes.button} ${classes.noBoxShadow}`}
                onClick={handleSubmit(onSubmit)}
            >
                {authStatus !== "authenticated" ? ButtonText : SubmitButtonText}
            </ContainedButton>
            <RfqSuccessModal isOpen={isOpenSuccessModal} setIsOpen={handleSuccesModalClose} />
        </Box>
    );
};

export default ThirdPhaseForm;
