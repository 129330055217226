import { Fragment, ReactNode } from "react";
import { IShipmentDetails, IShipmentPackages } from "../../../../../../../../models/entities/shipment/IShipment";
import { CARGO_DETAILS_LABELS, TEXTS, toTitleCase } from "../resources";
import { formatNumberWithDecimal } from "../../../../../../../../helpers/services/DateFormatsService.service";
import classes from "../ShipmentTab.module.scss";
import UnicargoTooltip from "../../../../../../../UnicargoTooltip/UnicargoTooltip";
import { CargoDetailsIcon } from "../../../../../../../../assets/icons";

interface CargoDetailsProps {
    packageDetails: IShipmentPackages;
    shipmentDetails: IShipmentDetails;
    renderInfoItem: (labelToRender: string, data: any) => ReactNode;
    charLimit: number;
}

export const CargoDetails: React.FC<CargoDetailsProps> = ({
    packageDetails,
    shipmentDetails,
    renderInfoItem,
    charLimit,
}) => {
    const { totalVolumInCBM, grossWeightInKG } = packageDetails;

    const renderCargoDetailsItems = () =>
        Object.keys(CARGO_DETAILS_LABELS).map((labelKey) => {
            if (!labelKey) return null;
            const labelValue = CARGO_DETAILS_LABELS[labelKey];
            let infoItemToRender = null;
            switch (labelValue) {
                case CARGO_DETAILS_LABELS.billOfLading:
                    if (shipmentDetails.transportMode === "Air") {
                        if (shipmentDetails.specialServicesEnglishName === "Express") {
                            const masterWithPrefix = shipmentDetails.masterNumber?.split("-");
                            if (masterWithPrefix?.length && masterWithPrefix[0] === "111") {
                                infoItemToRender = renderInfoItem(
                                    "Airway Bill",
                                    shipmentDetails.houseNumber?.toUpperCase(),
                                );
                            } else if (masterWithPrefix) {
                                infoItemToRender = renderInfoItem("Airway Bill", masterWithPrefix[1].toUpperCase());
                            } else {
                                infoItemToRender = renderInfoItem("Airway Bill", "...");
                            }
                        } else {
                            infoItemToRender = renderInfoItem(
                                "Airway Bill",
                                shipmentDetails.masterNumber?.toUpperCase(),
                            );
                        }
                    } else {
                        infoItemToRender = renderInfoItem(labelValue, shipmentDetails.masterNumber?.toUpperCase());
                    }
                    break;
                // When ISF number is not available, hide the label
                case CARGO_DETAILS_LABELS.isf:
                    if (shipmentDetails.isfNumber && shipmentDetails.transportMode === "Ocean") {
                        infoItemToRender = renderInfoItem(labelValue, shipmentDetails.isfNumber);
                    }
                    break;
                case CARGO_DETAILS_LABELS.volume:
                    if (shipmentDetails.shipmentLoad !== "FCL")
                        infoItemToRender = renderInfoItem(labelValue, formatNumberWithDecimal(totalVolumInCBM, 2));
                    break;
                case CARGO_DETAILS_LABELS.grossWeight:
                    infoItemToRender = renderInfoItem(labelValue, formatNumberWithDecimal(grossWeightInKG, 2));
                    break;
                case CARGO_DETAILS_LABELS.chargeWeight:
                    if (shipmentDetails.transportMode === "Air") {
                        infoItemToRender = renderInfoItem(
                            labelValue,
                            formatNumberWithDecimal(shipmentDetails.chargeWeightInKG, 2),
                        );
                    }
                    break;
                case CARGO_DETAILS_LABELS.shippingLine:
                    {
                        const displayNameRaw =
                            shipmentDetails?.airlineName !== "Not Specified" ? shipmentDetails.airlineName : "...";
                        const displayName = toTitleCase(displayNameRaw as string);
                        if (shipmentDetails.transportMode === "Ocean") {
                            infoItemToRender = renderInfoItem("Shipping Line", displayName.toUpperCase());
                        } else if (shipmentDetails.transportMode === "Air") {
                            infoItemToRender = renderInfoItem("Airline", displayName);
                        } else {
                            infoItemToRender = renderInfoItem("Carrier Name", displayName.toUpperCase());
                        }
                    }
                    break;
                case CARGO_DETAILS_LABELS.vesselName:
                    {
                        const vesselName =
                            shipmentDetails.vesselName === "Not Specified" ? "..." : shipmentDetails.vesselName;
                        const formattedVesselName = vesselName?.toUpperCase() ?? "...";
                        if (shipmentDetails.transportMode === "Ocean")
                            infoItemToRender = renderInfoItem(labelValue, formattedVesselName);
                    }
                    break;
                case CARGO_DETAILS_LABELS.containerNumber:
                    {
                        if (shipmentDetails.transportMode === "Ocean") {
                            const values = shipmentDetails.containerNumberType?.split(",") || [];
                            const displayValues = values.slice(0, 3);
                            const moreCount = values.length > 3 ? values.length - 3 : 0;
                            infoItemToRender = (
                                <Fragment key={labelValue}>
                                    <div className={classes.containerNumber}>
                                        <div className={`${classes.FileInfoItemKey}`}>{labelValue}</div>
                                        <div className={classes.FileInfoItemValueContainer}>
                                            <span className={classes.FileInfoItemValueContent}>
                                                {values?.length > 0 ? (
                                                    <>
                                                        {displayValues.map((value, index) => {
                                                            if (value?.length > charLimit)
                                                                return (
                                                                    <UnicargoTooltip
                                                                        popperChildComponent={value}
                                                                        child={
                                                                            <div
                                                                                className={classes.containerNumberItem}
                                                                                key={index}
                                                                                style={{ marginBottom: "8px" }}
                                                                            >
                                                                                {value.toUpperCase()}
                                                                            </div>
                                                                        }
                                                                        childClassName="childClassName"
                                                                        popperClassName="popperClassName"
                                                                    />
                                                                );
                                                            return (
                                                                <div
                                                                    className={classes.containerNumberItem}
                                                                    key={index}
                                                                    style={{ marginBottom: "8px" }}
                                                                >
                                                                    {value.toUpperCase()}
                                                                </div>
                                                            );
                                                        })}
                                                        {moreCount > 0 && (
                                                            <UnicargoTooltip
                                                                popperChildComponent={values.map((value) => (
                                                                    <div>{value.toUpperCase()}</div>
                                                                ))}
                                                                child={
                                                                    <div
                                                                        className={classes.moreIndicator}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        {`${moreCount} more`}
                                                                    </div>
                                                                }
                                                                childClassName="childClassName"
                                                                popperClassName="popperClassName"
                                                            />
                                                        )}
                                                    </>
                                                ) : (
                                                    <div className={classes.containerNumberItem}>...</div>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </Fragment>
                            );
                        }
                    }
                    break;
            }
            return infoItemToRender;
        });

    return (
        <div className={classes.cargoDetailsContainer}>
            <p className={classes.containerTitle}>
                <CargoDetailsIcon /> {TEXTS.cargoDetails}
            </p>
            <div className={classes.cargoDetailsBoxContainer}>
                <div className={classes.FileInfoContainer}>
                    <div className={classes.FileInfoItemsContainer}>{renderCargoDetailsItems()}</div>
                </div>
            </div>
        </div>
    );
};
