import { CSSProperties, FC } from "react";
import styles from "./SpinnerLoader.module.scss";

interface SpinnerLoaderProps {
    size: CSSProperties["width"];
    color?: CSSProperties["color"];
}

const SpinnerLoader: FC<SpinnerLoaderProps> = ({ size, color }) => {
    return (
        <div
            className={styles.SpinnerLoader}
            style={{
                width: size,
                height: size,
                borderColor: color,
                borderLeftColor: color,
            }}
        />
    );
};

export default SpinnerLoader;
