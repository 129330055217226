import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { ReactComponent as ExpandMoreIcon } from "../../assets/icons/expand-more.svg";
import { ReactComponent as CheckMarkIcon } from "../../assets/icons/check-mark.svg";
import { RenderStep } from "./RenderStep";
import { UiSchemeV2 } from "../../assets/data/ui";
import { IAccordionCard } from "./types";
import { formatStepTitleOnOneDayStepsCompletion, getStepTitle } from "./utils";
import { useEffect, useRef, useState } from "react";
import "./AccordionCard.scss";

const AccordionCard = ({
    title,
    steps,
    color,
    name,
    dotColor,
    isExpended,
    primaryDetails,
    handleOnExpand,
    shipmentDetails,
}: IAccordionCard) => {
    const isDisabled = !primaryDetails?.length && !steps.length;
    const themeColor = isDisabled ? UiSchemeV2.colors.greys.grey300 : color;
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateContainerWidth = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };

        updateContainerWidth();

        window.addEventListener("resize", updateContainerWidth);

        return () => window.removeEventListener("resize", updateContainerWidth);
    }, [containerRef?.current?.offsetWidth]);

    const handleChange = () => {
        handleOnExpand(name);
    };

    const arrivedSteps = steps.filter((step) => step.isDone);
    const comingUpSteps = steps.filter((step) => !step.isDone);

    // Check if this is an Inland shipment
    const isInland = shipmentDetails.transportMode === "Inland";

    const stepTitle = getStepTitle(steps, primaryDetails, isInland);

    // (Est.) should be in different color
    const highlightEstimates = (title: string) => {
        const estRegex = /\(Est.\)/g;
        const parts = title.split(estRegex);

        return parts.map((part, index) => (
            <span key={index}>
                {part}
                {index < parts.length - 1 && <span style={{ color: "#dcdcdc" }}>(Est.)</span>}
            </span>
        ));
    };

    const isOriginAccordionCard = arrivedSteps[0]?.cardName === "origin" || primaryDetails?.length;

    const formattedTitle = highlightEstimates(formatStepTitleOnOneDayStepsCompletion(name, steps, stepTitle));

    const landTransportMode = shipmentDetails.transportMode === "Inland";

    return (
        <Accordion
            TransitionProps={{ timeout: 0 }}
            expanded={isExpended}
            onChange={handleChange}
            disabled={isDisabled}
            className={isDisabled ? "accordion-container-disabled accordion-container" : "accordion-container"}
            ref={containerRef}
        >
            <AccordionSummary
                className={landTransportMode ? "inland-accordion-summary-container" : "accordion-summary-container"}
                expandIcon={!isDisabled && !landTransportMode && <ExpandMoreIcon />}
                style={{ color: themeColor }}
            >
                <div className="title-container">
                    <span className={landTransportMode ? "inland-title" : ""}>
                        {landTransportMode ? formattedTitle : title}
                    </span>
                    <div
                        className={`${!landTransportMode ? "title-dot step-dot" : ""}`}
                        style={{
                            backgroundColor:
                                arrivedSteps.some((step) => step.isDone) || primaryDetails?.some((step) => step.isDone)
                                    ? dotColor
                                    : UiSchemeV2.colors.greys.grey200,
                        }}
                    />
                    {!isDisabled && !isOriginAccordionCard && <span className="sub-title">{formattedTitle}</span>}
                </div>
            </AccordionSummary>
            {isExpended && (
                <AccordionDetails className="accordion-details-container" style={{ padding: 0 }}>
                    <hr className="separator-line"></hr>

                    {primaryDetails?.map((detail, index) => (
                        <div
                            className={`accordion-primary-details ${!detail.date && !detail.isDone && "disable"} ${
                                index === primaryDetails?.length - 1 && "last-primary-detail"
                            }`}
                        >
                            <div
                                className={`step-dot ${detail.isDone && "is-done-dot"}`}
                                style={{ backgroundColor: detail.dotColor }}
                            />
                            <span className="icon-container">{detail.isDone && <CheckMarkIcon />}</span>
                            <span className="detail-title">{detail.title}</span>
                            <span className="detail-date">{detail.date}</span>
                        </div>
                    ))}

                    {arrivedSteps.map((step) => (
                        <div className="step-container">
                            <RenderStep
                                containerWidth={containerWidth}
                                step={step}
                                color={themeColor}
                                shipmentDetails={shipmentDetails}
                            />
                        </div>
                    ))}

                    {comingUpSteps.map((step, index) => (
                        <div className="step-container">
                            <RenderStep
                                isDisabled={true}
                                containerWidth={containerWidth}
                                step={step}
                                color={themeColor}
                            />
                        </div>
                    ))}
                </AccordionDetails>
            )}
        </Accordion>
    );
};

export default AccordionCard;
