import { TypographyOptions } from "@mui/material/styles/createTypography";

export const typography: TypographyOptions = {
    fontFamily: "Lato-Regular",
    fontWeightRegular: 400,
    fontWeightLight: 300,
    fontWeightMedium: 700,
    fontWeightBold: "bold",
    h1: {
        fontSize: "24px",
        margin: "0px",
    },
    h2: {
        fontSize: "18px",
        margin: "0px",
    },
};
