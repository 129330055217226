export enum FilterTypes {
    SINGLE_CHOICE_SELECT,
    MULTI_CHOICE_SELECT,
    DATE_RANGE,
    SEARCH,
}

export enum SortOrder {
    NONE = "NONE",
    ASC = "ASC",
    DESC = "DESC",
}

export enum DateRangeFilterOptions {
    AllDates = "All Dates",
    CurrentMonth = "Current Month",
    LastMonth = "Last Month",
    CurrentYear = "Current Year",
    Custom = "Custom Date Range",
}

export enum CalendarPurpose {
    START_DATE = "startDate",
    END_DATE = "endDate",
}

export type IFilters = IFilter[];

export interface IFilter {
    keyName: InvoicesFilterKeyNames;
    label: InvoicesFilterLabels;
    filterType: FilterTypes;
    includeAllOption: boolean;
    options: IFilterOption[];
}

export interface ISort {
    sortType: InvoiceSortTypes;
    sortOrder: SortOrder;
}
export enum InvoiceSortTypes {
    TOTAL_AMOUNT = "TotalAmount",
    INVOICE_DATE = "InvoiceDate",
    DUE_DATE = "DueDate",
    AMOUNT_DUE = "AmountDue",
    INVOICE_NUMBER = "InvoiceNumber",
    FILE_NUMBER = "FileNumber",
}

export enum InvoicesFilterKeyNames {
    STATUS = "Status",
    CURRENCY = "Currency",
    SERVICE = "Service",
    INVOICE_DATE = "InvoiceDate",
    SEARCH = "Search",
}

export enum InvoicesFilterLabels {
    STATUS = "Status",
    CURRENCY = "Currency",
    SERVICE = "Service",
    INVOICE_DATE = "Invoice Date",
    SEARCH = "search",
}

export interface IDateRangeFilter extends IFilter {
    label: InvoicesFilterLabels;
    filterType: FilterTypes.DATE_RANGE;
    includeAllOption: true;
    options: IDateRangeFilterOption[];
}

export interface IFilterOption {
    value: unknown;
    label: string;
    isSelected: boolean;
}

export interface IDateRangeFilterOption extends IFilterOption {
    label: DateRangeFilterOptions;
    value: IDateRangeFilterOptionValue;
}

export const YYYY_MM_DD = "YYYY-MM-DD";

export type IDateRangeFilterOptionValue =
    | [string, string]
    | DateRangeFilterOptions.Custom
    | DateRangeFilterOptions.AllDates;
