import { StepClasskey } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const stepStyles: Partial<OverridesStyleRules<StepClasskey>> = {
    root: {
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
    },
    horizontal: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    vertical: {},
};
