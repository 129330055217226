import { StepLabelClassKey } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const stepLabelStyles: Partial<OverridesStyleRules<StepLabelClassKey>> = {
    root: {},
    iconContainer: {
        zIndex: 2,
    },
    alternativeLabel: {},
    label: {},
    labelContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    horizontal: {
        "&.MuiStepLabel-horizontal": {
            "&.MuiStepLabel-alternativeLabel": {
                margin: "0px",
                width: "fit-content",
                "& .MuiStepLabel-labelContainer": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: "3px !important", // must
                },
            },
            "& .MuiStepLabel-iconContainer": {
                zIndex: 2,
            },
        },
    },
    vertical: {
        "&.MuiStepLabel-vertical": {
            padding: "0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "&.MuiStepLabel-alternativeLabel": {
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                alignItems: "flex-start",
                textAlign: "initial",
                "& .MuiStepLabel-labelContainer": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    "& .MuiStepLabel-label": {
                        marginTop: "0",
                    },
                },
            },
        },
    },
};
