export const airportsData = [
    {
        airportName: "Rinas",
        city: "Tirana",
        iata: "TIA",
        countryIata: "ALTIA",
        country: "Albania",
        countryCode: "AL",
    },
    {
        airportName: "Andorra La Vella H/P",
        city: "Andorra La Vella",
        iata: "ALV",
        countryIata: "ADALV",
        country: "Andorra",
        countryCode: "AD",
    },
    {
        airportName: "Wallblake",
        city: "Anguilla",
        iata: "AXA",
        countryIata: "AIAXA",
        country: "Anguilla",
        countryCode: "AI",
    },
    {
        airportName: "Teniente R. Marsh",
        city: "Teniente R. Marsh",
        iata: "TNM",
        countryIata: "AQTNM",
        country: "Antarctica",
        countryCode: "AQ",
    },
    {
        airportName: "Jorge Newbery Airport",
        city: "Buenos Aires",
        iata: "AEP",
        countryIata: "ARAEP",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "San Rafael",
        city: "San Rafael",
        iata: "AFA",
        countryIata: "ARAFA",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Paso De Los Libres",
        city: "Paso De Los Libres",
        iata: "AOL",
        countryIata: "ARAOL",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Zapala",
        city: "Zapala",
        iata: "APZ",
        countryIata: "ARAPZ",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Alto Rio Senguerr",
        city: "Alto Rio Senguerr",
        iata: "ARR",
        countryIata: "ARARR",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Comandante",
        city: "Bahia Blanca",
        iata: "BHI",
        countryIata: "ARBHI",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "International",
        city: "San Carlos DeBariloche",
        iata: "BRC",
        countryIata: "ARBRC",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Metropolitan Area",
        city: "Buenos Aires",
        iata: "BUE",
        countryIata: "ARBUE",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Colonia Catriel",
        city: "Colonia Catriel",
        iata: "CCT",
        countryIata: "ARCCT",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Clorinda",
        city: "Clorinda",
        iata: "CLX",
        countryIata: "ARCLX",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Camba Punta",
        city: "Corrientes",
        iata: "CNQ",
        countryIata: "ARCNQ",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Charata",
        city: "Charata",
        iata: "CNT",
        countryIata: "ARCNT",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Concordia",
        city: "Concordia",
        iata: "COC",
        countryIata: "ARCOC",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Pajas Blancas",
        city: "Cordoba",
        iata: "COR",
        countryIata: "ARCOR",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Chapelco",
        city: "San Martin DeLos Andes",
        iata: "CPC",
        countryIata: "ARCPC",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Carmen De Patagones",
        city: "Carmen De Patagones",
        iata: "CPG",
        countryIata: "ARCPG",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Comodoro Rivadavia",
        city: "Comodoro Rivadavia",
        iata: "CRD",
        countryIata: "ARCRD",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Ceres",
        city: "Ceres",
        iata: "CRR",
        countryIata: "ARCRR",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Brigadier Hector Ruiz",
        city: "Coronel Suarez",
        iata: "CSZ",
        countryIata: "ARCSZ",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Catamarca",
        city: "Catamarca",
        iata: "CTC",
        countryIata: "ARCTC",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Cutral",
        city: "Cutral",
        iata: "CUT",
        countryIata: "ARCUT",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Caviahue",
        city: "Caviahue",
        iata: "CVH",
        countryIata: "ARCVH",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Caleta Olivia",
        city: "Caleta Olivia",
        iata: "CVI",
        countryIata: "ARCVI",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "El Bolson",
        city: "El Bolson",
        iata: "EHL",
        countryIata: "AREHL",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Eldorado",
        city: "Eldorado",
        iata: "ELO",
        countryIata: "ARELO",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "El Maiten",
        city: "El Maiten",
        iata: "EMX",
        countryIata: "AREMX",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Esquel",
        city: "Esquel",
        iata: "EQS",
        countryIata: "AREQS",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Ministro Pistarini",
        city: "Buenos Aires",
        iata: "EZE",
        countryIata: "AREZE",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "El Pucu",
        city: "Formosa",
        iata: "FMA",
        countryIata: "ARFMA",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "El Calafate",
        city: "El Calafate",
        iata: "FTE",
        countryIata: "ARFTE",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Gobernador Gregores",
        city: "Gobernador Gregores",
        iata: "GGS",
        countryIata: "ARGGS",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Gualeguaychu",
        city: "Gualeguaychu",
        iata: "GHU",
        countryIata: "ARGHU",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "General Roca",
        city: "General Roca",
        iata: "GNR",
        countryIata: "ARGNR",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "General Pico",
        city: "General Pico",
        iata: "GPO",
        countryIata: "ARGPO",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Oscar Reguera",
        city: "Chos Malal",
        iata: "HOS",
        countryIata: "ARHOS",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Ingeniero Jacobacci",
        city: "Ingeniero Jacobacci",
        iata: "IGB",
        countryIata: "ARIGB",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Cataratas",
        city: "Iguazu",
        iata: "IGR",
        countryIata: "ARIGR",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Lago Argentino",
        city: "Lago Argentino",
        iata: "ING",
        countryIata: "ARING",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "La Rioja",
        city: "La Rioja",
        iata: "IRJ",
        countryIata: "ARIRJ",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Junin",
        city: "Junin",
        iata: "JNI",
        countryIata: "ARJNI",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Jose De San Martin",
        city: "Jose De San Martin",
        iata: "JSM",
        countryIata: "ARJSM",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "El Cadillal",
        city: "Jujuy",
        iata: "JUJ",
        countryIata: "ARJUJ",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "La Cumbre",
        city: "La Cumbre",
        iata: "LCM",
        countryIata: "ARLCM",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Loncopue",
        city: "Loncopue",
        iata: "LCP",
        countryIata: "ARLCP",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Malargue",
        city: "Malargue",
        iata: "LGS",
        countryIata: "ARLGS",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Las Heras",
        city: "Las Heras",
        iata: "LHS",
        countryIata: "ARLHS",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Las Lomitas",
        city: "Las Lomitas",
        iata: "LLS",
        countryIata: "ARLLS",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Los Menucos",
        city: "Los Menucos",
        iata: "LMD",
        countryIata: "ARLMD",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "La Plata",
        city: "La Plata",
        iata: "LPG",
        countryIata: "ARLPG",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "San Luis",
        city: "San Luis",
        iata: "LUQ",
        countryIata: "ARLUQ",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Monte Caseros",
        city: "Monte Caseros",
        iata: "MCS",
        countryIata: "ARMCS",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Mar Del Plata",
        city: "Mar Del Plata",
        iata: "MDQ",
        countryIata: "ARMDQ",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Mercedes",
        city: "Mercedes",
        iata: "MDX",
        countryIata: "ARMDX",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "El Plumerillo",
        city: "Mendoza",
        iata: "MDZ",
        countryIata: "ARMDZ",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Miramar",
        city: "Miramar",
        iata: "MJR",
        countryIata: "ARMJR",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Maquinchao",
        city: "Maquinchao",
        iata: "MQD",
        countryIata: "ARMQD",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Necochea",
        city: "Necochea",
        iata: "NEC",
        countryIata: "ARNEC",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Neuquen",
        city: "Neuquen",
        iata: "NQN",
        countryIata: "ARNQN",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "San Antonio Oeste",
        city: "San Antonio Oeste",
        iata: "OES",
        countryIata: "AROES",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Colonia Sarmiento",
        city: "Colonia Sarmiento",
        iata: "OLN",
        countryIata: "AROLN",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Oran",
        city: "Oran",
        iata: "ORA",
        countryIata: "ARORA",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Olavarria",
        city: "Olavarria",
        iata: "OVR",
        countryIata: "AROVR",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Goya",
        city: "Goya",
        iata: "OYA",
        countryIata: "AROYA",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Tres Arroyos",
        city: "Tres Arroyos",
        iata: "OYO",
        countryIata: "AROYO",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Pehuajo",
        city: "Pehuajo",
        iata: "PEH",
        countryIata: "ARPEH",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Perito Moreno",
        city: "Perito Moreno",
        iata: "PMQ",
        countryIata: "ARPMQ",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "El Tehuelche",
        city: "Puerto Madryn",
        iata: "PMY",
        countryIata: "ARPMY",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Parana",
        city: "Parana",
        iata: "PRA",
        countryIata: "ARPRA",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Pres. Roque Saenz Pena",
        city: "Pres. Roque Saenz Pena",
        iata: "PRQ",
        countryIata: "ARPRQ",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Posadas",
        city: "Posadas",
        iata: "PSS",
        countryIata: "ARPSS",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Puerto Deseado",
        city: "Puerto Deseado",
        iata: "PUD",
        countryIata: "ARPUD",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Reconquista",
        city: "Reconquista",
        iata: "RCQ",
        countryIata: "ARRCQ",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Rio Cuarto",
        city: "Rio Cuarto",
        iata: "RCU",
        countryIata: "ARRCU",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Rincon de Los Sauces",
        city: "Rincon de los Sauces",
        iata: "RDS",
        countryIata: "ARRDS",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Almirante Marcos A. Zar Airport",
        city: "Trelew",
        iata: "REL",
        countryIata: "ARREL",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Resistencia",
        city: "Resistencia",
        iata: "RES",
        countryIata: "ARRES",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Rio Grande",
        city: "Rio Grande",
        iata: "RGA",
        countryIata: "ARRGA",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Internacional",
        city: "Rio Gallegos",
        iata: "RGL",
        countryIata: "ARRGL",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Rio Hondo",
        city: "Rio Hondo",
        iata: "RHD",
        countryIata: "ARRHD",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Fisherton",
        city: "Rosario",
        iata: "ROS",
        countryIata: "ARROS",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Rio Mayo",
        city: "Rio Mayo",
        iata: "ROY",
        countryIata: "ARROY",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Santa Rosa",
        city: "Santa Rosa",
        iata: "RSA",
        countryIata: "ARRSA",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Rio Turbio",
        city: "Rio Turbio",
        iata: "RYO",
        countryIata: "ARRYO",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Santa Cruz",
        city: "Santa Cruz",
        iata: "RZA",
        countryIata: "ARRZA",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Santiago Del Estero",
        city: "Santiago Del Estero",
        iata: "SDE",
        countryIata: "ARSDE",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Santa Fe",
        city: "Santa Fe",
        iata: "SFN",
        countryIata: "ARSFN",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Sierra Grande",
        city: "Sierra Grande",
        iata: "SGV",
        countryIata: "ARSGV",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Gen Belgrano",
        city: "Salta",
        iata: "SLA",
        countryIata: "ARSLA",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Santa Teresita",
        city: "Santa Teresita",
        iata: "SST",
        countryIata: "ARSST",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Saenz Pena",
        city: "Saenz Pena",
        iata: "SZQ",
        countryIata: "ARSZQ",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Tandil",
        city: "Tandil",
        iata: "TDL",
        countryIata: "ARTDL",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Tartagal",
        city: "Tartagal",
        iata: "TTG",
        countryIata: "ARTTG",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Benj Matienzo International",
        city: "Tucuman (Tucumán)",
        iata: "TUC",
        countryIata: "ARTUC",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "San Juan",
        city: "San Juan",
        iata: "UAQ",
        countryIata: "ARUAQ",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "San Julian",
        city: "San Julian",
        iata: "ULA",
        countryIata: "ARULA",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Islas Malvinas",
        city: "Ushuaia",
        iata: "USH",
        countryIata: "ARUSH",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Curuzu Cuatia",
        city: "Curuzu Cuatia",
        iata: "UZU",
        countryIata: "ARUZU",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Valcheta",
        city: "Valcheta",
        iata: "VCF",
        countryIata: "ARVCF",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Viedma",
        city: "Viedma",
        iata: "VDM",
        countryIata: "ARVDM",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Villa Dolores",
        city: "Villa Dolores",
        iata: "VDR",
        countryIata: "ARVDR",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "General Villegas",
        city: "General Villegas",
        iata: "VGS",
        countryIata: "ARVGS",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Villa Gesell",
        city: "Villa Gesell",
        iata: "VLG",
        countryIata: "ARVLG",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Villa Mercedes",
        city: "Villa Mercedes",
        iata: "VME",
        countryIata: "ARVME",
        country: "Argentina",
        countryCode: "AR",
    },
    {
        airportName: "Yerevan",
        city: "Yerevan",
        iata: "EVN",
        countryIata: "AMEVN",
        country: "Armenia",
        countryCode: "AM",
    },
    {
        airportName: "Gyoumri",
        city: "Gyoumri",
        iata: "LWN",
        countryIata: "AMLWN",
        country: "Armenia",
        countryCode: "AM",
    },
    {
        airportName: "Queen Beatrix Intl Airport",
        city: "Aruba",
        iata: "AUA",
        countryIata: "AWAUA",
        country: "Aruba",
        countryCode: "AW",
    },
    {
        airportName: "Arrabury",
        city: "Arrabury",
        iata: "AAB",
        countryIata: "AUAAB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Abingdon",
        city: "Abingdon",
        iata: "ABG",
        countryIata: "AUABG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Alpha",
        city: "Alpha",
        iata: "ABH",
        countryIata: "AUABH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bamaga",
        city: "Bamaga",
        iata: "ABM",
        countryIata: "AUABM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Albury",
        city: "Albury",
        iata: "ABX",
        countryIata: "AUABX",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Adelaide International Airport",
        city: "Adelaide",
        iata: "ADL",
        countryIata: "AUADL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Andamooka",
        city: "Andamooka",
        iata: "ADO",
        countryIata: "AUADO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Agnew Airport",
        city: "Agnew",
        iata: "AGW",
        countryIata: "AUAGW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Argyle Downs Airport",
        city: "Argyle Downs",
        iata: "AGY",
        countryIata: "AUAGY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Albany",
        city: "Albany",
        iata: "ALH",
        countryIata: "AUALH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Amata",
        city: "Amata",
        iata: "AMT",
        countryIata: "AUAMT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Ammaroo",
        city: "Ammaroo",
        iata: "AMX",
        countryIata: "AUAMX",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Angus Downs",
        city: "Angus Downs",
        iata: "ANZ",
        countryIata: "AUANZ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Armidale",
        city: "Armidale",
        iata: "ARM",
        countryIata: "AUARM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Ararat",
        city: "Ararat",
        iata: "ARY",
        countryIata: "AUARY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Alice Springs",
        city: "Alice Springs",
        iata: "ASP",
        countryIata: "AUASP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Augustus Downs",
        city: "Augustus Downs",
        iata: "AUD",
        countryIata: "AUAUD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Aurukun Mission",
        city: "Aurukun Mission",
        iata: "AUU",
        countryIata: "AUAUU",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Auvergne",
        city: "Auvergne",
        iata: "AVG",
        countryIata: "AUAVG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Avalon",
        city: "Avalon",
        iata: "AVV",
        countryIata: "AUAVV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Alton Downs",
        city: "Alton Downs",
        iata: "AWN",
        countryIata: "AUAWN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Austral Downs",
        city: "Austral Downs",
        iata: "AWP",
        countryIata: "AUAWP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Aramac",
        city: "Aramac",
        iata: "AXC",
        countryIata: "AUAXC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Alexandria",
        city: "Alexandria",
        iata: "AXL",
        countryIata: "AUAXL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Alroy Downs",
        city: "Alroy Downs",
        iata: "AYD",
        countryIata: "AUAYD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Anthony Lagoon",
        city: "Anthony Lagoon",
        iata: "AYL",
        countryIata: "AUAYL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Connellan Airport",
        city: "Ayers Rock",
        iata: "AYQ",
        countryIata: "AUAYQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Ayr Airport",
        city: "Ayr",
        iata: "AYR",
        countryIata: "AUAYR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Big Bell",
        city: "Big Bell",
        iata: "BBE",
        countryIata: "AUBBE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Barcaldine",
        city: "Barcaldine",
        iata: "BCI",
        countryIata: "AUBCI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bolwarra",
        city: "Bolwarra",
        iata: "BCK",
        countryIata: "AUBCK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bickerton Island",
        city: "Bickerton Island",
        iata: "BCZ",
        countryIata: "AUBCZ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bundaberg",
        city: "Bundaberg",
        iata: "BDB",
        countryIata: "AUBDB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Badu Island",
        city: "Badu Island",
        iata: "BDD",
        countryIata: "AUBDD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bedford Downs",
        city: "Bedford Downs",
        iata: "BDW",
        countryIata: "AUBDW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Beagle Bay",
        city: "Beagle Bay",
        iata: "BEE",
        countryIata: "AUBEE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Belmont",
        city: "Newcastle",
        iata: "BEO",
        countryIata: "AUBEO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bedourie",
        city: "Bedourie",
        iata: "BEU",
        countryIata: "AUBEU",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bloomfield",
        city: "Bloomfield",
        iata: "BFC",
        countryIata: "AUBFC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Broken Hill",
        city: "Broken Hill",
        iata: "BHQ",
        countryIata: "AUBHQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Raglan",
        city: "Bathurst",
        iata: "BHS",
        countryIata: "AUBHS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Brighton Downs",
        city: "Brighton Downs",
        iata: "BHT",
        countryIata: "AUBHT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bulimba",
        city: "Bulimba",
        iata: "BIP",
        countryIata: "AUBIP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Billiluna",
        city: "Billiluna",
        iata: "BIW",
        countryIata: "AUBIW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Barkly Downs",
        city: "Barkly Downs",
        iata: "BKP",
        countryIata: "AUBKP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Blackall",
        city: "Blackall",
        iata: "BKQ",
        countryIata: "AUBKQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Benalla",
        city: "Benalla",
        iata: "BLN",
        countryIata: "AUBLN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bollon",
        city: "Bollon",
        iata: "BLS",
        countryIata: "AUBLS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Blackwater",
        city: "Blackwater",
        iata: "BLT",
        countryIata: "AUBLT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Broome",
        city: "Broome",
        iata: "BME",
        countryIata: "AUBME",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Brampton Island",
        city: "Brampton Island",
        iata: "BMP",
        countryIata: "AUBMP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "International",
        city: "Brisbane",
        iata: "BNE",
        countryIata: "AUBNE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Ballina",
        city: "Ballina",
        iata: "BNK",
        countryIata: "AUBNK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Borroloola",
        city: "Borroloola",
        iata: "BOX",
        countryIata: "AUBOX",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bussellton Airport",
        city: "Bussellton",
        iata: "BQB",
        countryIata: "AUBQB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Boulia",
        city: "Boulia",
        iata: "BQL",
        countryIata: "AUBQL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Balgo Hills",
        city: "Balgo Hills",
        iata: "BQW",
        countryIata: "AUBQW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bright",
        city: "Bright",
        iata: "BRJ",
        countryIata: "AUBRJ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bourke",
        city: "Bourke",
        iata: "BRK",
        countryIata: "AUBRK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bathurst Island",
        city: "Bathurst Island",
        iata: "BRT",
        countryIata: "AUBRT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bairnsdale",
        city: "Bairnsdale",
        iata: "BSJ",
        countryIata: "AUBSJ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Brunette Downs",
        city: "Brunette Downs",
        iata: "BTD",
        countryIata: "AUBTD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Betoota",
        city: "Betoota",
        iata: "BTX",
        countryIata: "AUBTX",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Burketown",
        city: "Burketown",
        iata: "BUC",
        countryIata: "AUBUC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bunbury",
        city: "Bunbury",
        iata: "BUY",
        countryIata: "AUBUY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Birdsville",
        city: "Birdsville",
        iata: "BVI",
        countryIata: "AUBVI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Batavia Downs",
        city: "Batavia Downs",
        iata: "BVW",
        countryIata: "AUBVW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Beverley Springs",
        city: "Beverley Springs",
        iata: "BVZ",
        countryIata: "AUBVZ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Barrow Island",
        city: "Barrow Island",
        iata: "BWB",
        countryIata: "AUBWB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Brewarrina",
        city: "Brewarrina",
        iata: "BWQ",
        countryIata: "AUBWQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Burnie Wynyard",
        city: "Burnie",
        iata: "BWT",
        countryIata: "AUBWT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bankstown",
        city: "Bankstown",
        iata: "BWU",
        countryIata: "AUBWU",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bendigo",
        city: "Bendigo",
        iata: "BXG",
        countryIata: "AUBXG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Baniyala",
        city: "Baniyala",
        iata: "BYX",
        countryIata: "AUBYX",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Balranald",
        city: "Balranald",
        iata: "BZD",
        countryIata: "AUBZD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bizant",
        city: "Bizant",
        iata: "BZP",
        countryIata: "AUBZP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cobar",
        city: "Cobar",
        iata: "CAZ",
        countryIata: "AUCAZ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cherrabun",
        city: "Cherrabun",
        iata: "CBC",
        countryIata: "AUCBC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cape Barren Island",
        city: "Cape Barren Island",
        iata: "CBI",
        countryIata: "AUCBI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Canberra International Airport",
        city: "Canberra",
        iata: "CBR",
        countryIata: "AUCBR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Condobolin",
        city: "Condobolin",
        iata: "CBX",
        countryIata: "AUCBX",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Canobie",
        city: "Canobie",
        iata: "CBY",
        countryIata: "AUCBY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Chinchilla",
        city: "Chinchilla",
        iata: "CCL",
        countryIata: "AUCCL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cowell",
        city: "Cowell",
        iata: "CCW",
        countryIata: "AUCCW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cooinda",
        city: "Cooinda",
        iata: "CDA",
        countryIata: "AUCDA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Croydon",
        city: "Croydon",
        iata: "CDQ",
        countryIata: "AUCDQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Camden",
        city: "Camden",
        iata: "CDU",
        countryIata: "AUCDU",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Ceduna",
        city: "Ceduna",
        iata: "CED",
        countryIata: "AUCED",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cessnock",
        city: "Cessnock",
        iata: "CES",
        countryIata: "AUCES",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Clifton Hills",
        city: "Clifton Hills",
        iata: "CFH",
        countryIata: "AUCFH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Camfield",
        city: "Camfield",
        iata: "CFI",
        countryIata: "AUCFI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Carpentaria Downs",
        city: "Carpentaria Downs",
        iata: "CFP",
        countryIata: "AUCFP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Coffs Harbour",
        city: "Coffs Harbour",
        iata: "CFS",
        countryIata: "AUCFS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Caiguna",
        city: "Caiguna",
        iata: "CGV",
        countryIata: "AUCGV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Collie",
        city: "Collie",
        iata: "CIE",
        countryIata: "AUCIE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Croker Island",
        city: "Croker Island",
        iata: "CKI",
        countryIata: "AUCKI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Coolah",
        city: "Coolah",
        iata: "CLH",
        countryIata: "AUCLH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cunnamulla",
        city: "Cunnamulla",
        iata: "CMA",
        countryIata: "AUCMA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cootamundra",
        city: "Cootamundra",
        iata: "CMD",
        countryIata: "AUCMD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Camooweal",
        city: "Camooweal",
        iata: "CML",
        countryIata: "AUCML",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Clermont",
        city: "Clermont",
        iata: "CMQ",
        countryIata: "AUCMQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Coonamble",
        city: "Coonamble",
        iata: "CNB",
        countryIata: "AUCNB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Coconut Island",
        city: "Coconut Island",
        iata: "CNC",
        countryIata: "AUCNC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cloncurry",
        city: "Cloncurry",
        iata: "CNJ",
        countryIata: "AUCNJ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cairns",
        city: "Cairns",
        iata: "CNS",
        countryIata: "AUCNS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Coolibah",
        city: "Coolibah",
        iata: "COB",
        countryIata: "AUCOB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Coonabarabran",
        city: "Coonabarabran",
        iata: "COJ",
        countryIata: "AUCOJ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Coolawanyah",
        city: "Coolawanyah",
        iata: "COY",
        countryIata: "AUCOY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Coober Pedy",
        city: "Coober Pedy",
        iata: "CPD",
        countryIata: "AUCPD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cape Flattery",
        city: "Cape Flattery",
        iata: "CQP",
        countryIata: "AUCQP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Collarenebri",
        city: "Collarenebri",
        iata: "CRB",
        countryIata: "AUCRB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cherribah",
        city: "Cherribah",
        iata: "CRH",
        countryIata: "AUCRH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Coorabie",
        city: "Coorabie",
        iata: "CRJ",
        countryIata: "AUCRJ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Carlton Hill",
        city: "Carlton Hill",
        iata: "CRY",
        countryIata: "AUCRY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cresswell Downs",
        city: "Cresswell Downs",
        iata: "CSD",
        countryIata: "AUCSD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Casino",
        city: "Casino",
        iata: "CSI",
        countryIata: "AUCSI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Charleville",
        city: "Charleville",
        iata: "CTL",
        countryIata: "AUCTL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cooktown",
        city: "Cooktown",
        iata: "CTN",
        countryIata: "AUCTN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cattle Creek",
        city: "Cattle Creek",
        iata: "CTR",
        countryIata: "AUCTR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Caloundra",
        city: "Caloundra",
        iata: "CUD",
        countryIata: "AUCUD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cudal",
        city: "Cudal",
        iata: "CUG",
        countryIata: "AUCUG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Coen",
        city: "Coen",
        iata: "CUQ",
        countryIata: "AUCUQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cue",
        city: "Cue",
        iata: "CUY",
        countryIata: "AUCUY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cleve",
        city: "Cleve",
        iata: "CVC",
        countryIata: "AUCVC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Carnarvon",
        city: "Carnarvon",
        iata: "CVQ",
        countryIata: "AUCVQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cowarie",
        city: "Cowarie",
        iata: "CWR",
        countryIata: "AUCWR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cowra",
        city: "Cowra",
        iata: "CWT",
        countryIata: "AUCWT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Corowa",
        city: "Corowa",
        iata: "CWW",
        countryIata: "AUCWW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Christmas Creek",
        city: "Christmas Creek",
        iata: "CXQ",
        countryIata: "AUCXQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Charters Towers",
        city: "Charters Towers",
        iata: "CXT",
        countryIata: "AUCXT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Corryong",
        city: "Corryong",
        iata: "CYG",
        countryIata: "AUCYG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cluny",
        city: "Cluny",
        iata: "CZY",
        countryIata: "AUCZY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Dauan Island",
        city: "Dauan Island",
        iata: "DAJ",
        countryIata: "AUDAJ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Dubbo",
        city: "Dubbo",
        iata: "DBO",
        countryIata: "AUDBO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Dalby",
        city: "Dalby",
        iata: "DBY",
        countryIata: "AUDBY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Daydream Is",
        city: "Daydream Is",
        iata: "DDI",
        countryIata: "AUDDI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Delta Downs",
        city: "Delta Downs",
        iata: "DDN",
        countryIata: "AUDDN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Drumduff",
        city: "Drumduff",
        iata: "DFP",
        countryIata: "AUDFP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Dalgaranga",
        city: "Dalgaranga",
        iata: "DGD",
        countryIata: "AUDGD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mudgee",
        city: "Mudgee",
        iata: "DGE",
        countryIata: "AUDGE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Durham Downs",
        city: "Durham Downs",
        iata: "DHD",
        countryIata: "AUDHD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Dunk Island",
        city: "Dunk Island",
        iata: "DKI",
        countryIata: "AUDKI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Docker River",
        city: "Docker River",
        iata: "DKV",
        countryIata: "AUDKV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Dulkaninna",
        city: "Dulkaninna",
        iata: "DLK",
        countryIata: "AUDLK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Delissaville",
        city: "Delissaville",
        iata: "DLV",
        countryIata: "AUDLV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Doomadgee",
        city: "Doomadgee",
        iata: "DMD",
        countryIata: "AUDMD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Dunbar",
        city: "Dunbar",
        iata: "DNB",
        countryIata: "AUDNB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Doongan",
        city: "Doongan",
        iata: "DNG",
        countryIata: "AUDNG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Denham",
        city: "Denham",
        iata: "DNM",
        countryIata: "AUDNM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Deniliquin",
        city: "Deniliquin",
        iata: "DNQ",
        countryIata: "AUDNQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Dongara",
        city: "Dongara",
        iata: "DOX",
        countryIata: "AUDOX",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Devonport",
        city: "Devonport",
        iata: "DPO",
        countryIata: "AUDPO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Derby",
        city: "Derby",
        iata: "DRB",
        countryIata: "AUDRB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Dorunda Station",
        city: "Dorunda Station",
        iata: "DRD",
        countryIata: "AUDRD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Dirranbandi",
        city: "Dirranbandi",
        iata: "DRN",
        countryIata: "AUDRN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Durrie",
        city: "Durrie",
        iata: "DRR",
        countryIata: "AUDRR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Darwin",
        city: "Darwin",
        iata: "DRW",
        countryIata: "AUDRW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Drysdale River",
        city: "Drysdale River",
        iata: "DRY",
        countryIata: "AUDRY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Davenport Downs",
        city: "Davenport Downs",
        iata: "DVP",
        countryIata: "AUDVP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Daly River",
        city: "Daly River",
        iata: "DVR",
        countryIata: "AUDVR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Dixie",
        city: "Dixie",
        iata: "DXD",
        countryIata: "AUDXD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Dysart",
        city: "Dysart",
        iata: "DYA",
        countryIata: "AUDYA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Diamantina Lakes",
        city: "Diamantina Lakes",
        iata: "DYM",
        countryIata: "AUDYM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Daly Waters",
        city: "Daly Waters",
        iata: "DYW",
        countryIata: "AUDYW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Echuca",
        city: "Echuca",
        iata: "ECH",
        countryIata: "AUECH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Erldunda",
        city: "Erldunda",
        iata: "EDD",
        countryIata: "AUEDD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Edward River",
        city: "Edward River",
        iata: "EDR",
        countryIata: "AUEDR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Einasleigh",
        city: "Einasleigh",
        iata: "EIH",
        countryIata: "AUEIH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Elkedra",
        city: "Elkedra",
        iata: "EKD",
        countryIata: "AUEKD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Elcho Island",
        city: "Elcho Island",
        iata: "ELC",
        countryIata: "AUELC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Emerald",
        city: "Emerald",
        iata: "EMD",
        countryIata: "AUEMD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Eneabba West",
        city: "Eneabba West",
        iata: "ENB",
        countryIata: "AUENB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Esperance",
        city: "Esperance",
        iata: "EPR",
        countryIata: "AUEPR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Ernabella",
        city: "Ernabella",
        iata: "ERB",
        countryIata: "AUERB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Etadunna",
        city: "Etadunna",
        iata: "ETD",
        countryIata: "AUETD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Eucla",
        city: "Eucla",
        iata: "EUC",
        countryIata: "AUEUC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Eva Downs",
        city: "Eva Downs",
        iata: "EVD",
        countryIata: "AUEVD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Evans Head",
        city: "Evans Head",
        iata: "EVH",
        countryIata: "AUEVH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Exmouth Gulf",
        city: "Exmouth Gulf",
        iata: "EXM",
        countryIata: "AUEXM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Finke",
        city: "Finke",
        iata: "FIK",
        countryIata: "AUFIK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Fitzroy Crossing",
        city: "Fitzroy Crossing",
        iata: "FIZ",
        countryIata: "AUFIZ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Falls Creek",
        city: "Falls Creek",
        iata: "FLC",
        countryIata: "AUFLC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Flinders Island",
        city: "Flinders Island",
        iata: "FLS",
        countryIata: "AUFLS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Finley",
        city: "Finley",
        iata: "FLY",
        countryIata: "AUFLY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Forrest",
        city: "Forrest",
        iata: "FOS",
        countryIata: "AUFOS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Forster",
        city: "Forster",
        iata: "FOT",
        countryIata: "AUFOT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Forbes",
        city: "Forbes",
        iata: "FRB",
        countryIata: "AUFRB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Fossil Downs",
        city: "Fossil Downs",
        iata: "FSL",
        countryIata: "AUFSL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Flora Valey",
        city: "Flora Valley",
        iata: "FVL",
        countryIata: "AUFVL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Forrest River Airport",
        city: "Forrest River",
        iata: "FVR",
        countryIata: "AUFVR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Gayndah",
        city: "Gayndah",
        iata: "GAH",
        countryIata: "AUGAH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Goulburn Island",
        city: "Goulburn Island",
        iata: "GBL",
        countryIata: "AUGBL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Gamboola",
        city: "Gamboola",
        iata: "GBP",
        countryIata: "AUGBP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Gibb River",
        city: "Gibb River",
        iata: "GBV",
        countryIata: "AUGBV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Gordon Downs",
        city: "Gordon Downs",
        iata: "GDD",
        countryIata: "AUGDD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "George Town",
        city: "George Town",
        iata: "GEE",
        countryIata: "AUGEE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Geraldton",
        city: "Geraldton",
        iata: "GET",
        countryIata: "AUGET",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Geelong",
        city: "Geelong",
        iata: "GEX",
        countryIata: "AUGEX",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Grenfell",
        city: "Grenfell",
        iata: "GFE",
        countryIata: "AUGFE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Griffith",
        city: "Griffith",
        iata: "GFF",
        countryIata: "AUGFF",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Grafton",
        city: "Grafton",
        iata: "GFN",
        countryIata: "AUGFN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Gregory Downs",
        city: "Gregory Downs",
        iata: "GGD",
        countryIata: "AUGGD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Boigu Island",
        city: "Boigu Island",
        iata: "GIC",
        countryIata: "AUGIC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Great Keppel Island",
        city: "Great Keppel Island",
        iata: "GKL",
        countryIata: "AUGKL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Glengyle",
        city: "Glengyle",
        iata: "GLG",
        countryIata: "AUGLG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Glen Innes",
        city: "Glen Innes",
        iata: "GLI",
        countryIata: "AUGLI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Glenormiston",
        city: "Glenormiston",
        iata: "GLM",
        countryIata: "AUGLM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Gladstone",
        city: "Gladstone",
        iata: "GLT",
        countryIata: "AUGLT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Goldsworthy",
        city: "Goldsworthy",
        iata: "GLY",
        countryIata: "AUGLY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Goondiwindi",
        city: "Goondiwindi",
        iata: "GOO",
        countryIata: "AUGOO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Gosford",
        city: "Gosford",
        iata: "GOS",
        countryIata: "AUGOS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Nhulunbuy",
        city: "Gove",
        iata: "GOV",
        countryIata: "AUGOV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Garden Point",
        city: "Garden Point",
        iata: "GPN",
        countryIata: "AUGPN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Gascoyne Junction",
        city: "Gascoyne Junction",
        iata: "GSC",
        countryIata: "AUGSC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mount Gunson",
        city: "Mount Gunson",
        iata: "GSN",
        countryIata: "AUGSN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Alyangula",
        city: "Groote Eylandt",
        iata: "GTE",
        countryIata: "AUGTE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Granites",
        city: "Granites",
        iata: "GTS",
        countryIata: "AUGTS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Georgetown",
        city: "Georgetown",
        iata: "GTT",
        countryIata: "AUGTT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Gunnedah",
        city: "Gunnedah",
        iata: "GUH",
        countryIata: "AUGUH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Goulburn",
        city: "Goulburn",
        iata: "GUL",
        countryIata: "AUGUL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Greenvale",
        city: "Greenvale",
        iata: "GVP",
        countryIata: "AUGVP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Argyle",
        city: "Argyle",
        iata: "GYL",
        countryIata: "AUGYL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Gympie",
        city: "Gympie",
        iata: "GYP",
        countryIata: "AUGYP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Long Island",
        city: "Long Island",
        iata: "HAP",
        countryIata: "AUHAP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Heathlands",
        city: "Heathlands",
        iata: "HAT",
        countryIata: "AUHAT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Hobart",
        city: "Hobart",
        iata: "HBA",
        countryIata: "AUHBA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Halls Creek",
        city: "Halls Creek",
        iata: "HCQ",
        countryIata: "AUHCQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Hughenden",
        city: "Hughenden",
        iata: "HGD",
        countryIata: "AUHGD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Horn Island",
        city: "Horn Island",
        iata: "HID",
        countryIata: "AUHID",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Highbury",
        city: "Highbury",
        iata: "HIG",
        countryIata: "AUHIG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Hook Island",
        city: "Hook Island",
        iata: "HIH",
        countryIata: "AUHIH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Headingly",
        city: "Headingly",
        iata: "HIP",
        countryIata: "AUHIP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Hayman Island",
        city: "Hayman Island",
        iata: "HIS",
        countryIata: "AUHIS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Hillside",
        city: "Hillside",
        iata: "HLL",
        countryIata: "AUHLL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "St Helens",
        city: "St Helens",
        iata: "HLS",
        countryIata: "AUHLS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Hamilton",
        city: "Hamilton",
        iata: "HLT",
        countryIata: "AUHLT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Helenvale",
        city: "Helenvale",
        iata: "HLV",
        countryIata: "AUHLV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Hermannsburg",
        city: "Hermannsburg",
        iata: "HMG",
        countryIata: "AUHMG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Hinchinbrook Island",
        city: "Hinchinbrook Island",
        iata: "HNK",
        countryIata: "AUHNK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Hooker Creek",
        city: "Hooker Creek",
        iata: "HOK",
        countryIata: "AUHOK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Hope Vale",
        city: "Hope Vale",
        iata: "HPE",
        countryIata: "AUHPE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Heliport",
        city: "Heron Island",
        iata: "HRN",
        countryIata: "AUHRN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Henbury",
        city: "Henbury",
        iata: "HRY",
        countryIata: "AUHRY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Horsham",
        city: "Horsham",
        iata: "HSM",
        countryIata: "AUHSM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Hamilton Island",
        city: "Hamilton Island",
        iata: "HTI",
        countryIata: "AUHTI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Hopetoun",
        city: "Hopetoun",
        iata: "HTU",
        countryIata: "AUHTU",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Humbert River",
        city: "Humbert River",
        iata: "HUB",
        countryIata: "AUHUB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Hervey Bay",
        city: "Hervey Bay",
        iata: "HVB",
        countryIata: "AUHVB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Wilpena Pound",
        city: "Hawker",
        iata: "HWK",
        countryIata: "AUHWK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Hay",
        city: "Hay",
        iata: "HXX",
        countryIata: "AUHXX",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Indulkana",
        city: "Indulkana",
        iata: "IDK",
        countryIata: "AUIDK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Iffley",
        city: "Iffley",
        iata: "IFF",
        countryIata: "AUIFF",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Innisfail",
        city: "Innisfail",
        iata: "IFL",
        countryIata: "AUIFL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Ingham",
        city: "Ingham",
        iata: "IGH",
        countryIata: "AUIGH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Inkerman",
        city: "Inkerman",
        iata: "IKP",
        countryIata: "AUIKP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Injune",
        city: "Injune",
        iata: "INJ",
        countryIata: "AUINJ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Innamincka",
        city: "Innamincka",
        iata: "INM",
        countryIata: "AUINM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lockhart River",
        city: "Lockhart River",
        iata: "IRG",
        countryIata: "AUIRG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mount Isa",
        city: "Mount Isa",
        iata: "ISA",
        countryIata: "AUISA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Isisford",
        city: "Isisford",
        iata: "ISI",
        countryIata: "AUISI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Inverell",
        city: "Inverell",
        iata: "IVR",
        countryIata: "AUIVR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Inverway",
        city: "Inverway",
        iata: "IVW",
        countryIata: "AUIVW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Jabiru",
        city: "Jabiru",
        iata: "JAB",
        countryIata: "AUJAB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Jandakot",
        city: "Jandakot",
        iata: "JAD",
        countryIata: "AUJAD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Julia Creek",
        city: "Julia Creek",
        iata: "JCK",
        countryIata: "AUJCK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Heliport",
        city: "Fremantle",
        iata: "JFM",
        countryIata: "AUJFM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Shute Harbour H/P",
        city: "Shute Harbour",
        iata: "JHQ",
        countryIata: "AUJHQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Jundah",
        city: "Jundah",
        iata: "JUN",
        countryIata: "AUJUN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Jurien Bay",
        city: "Jurien Bay",
        iata: "JUR",
        countryIata: "AUJUR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "City Heliport",
        city: "Melbourne",
        iata: "KAH",
        countryIata: "AUKAH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kalbarri",
        city: "Kalbarri",
        iata: "KAX",
        countryIata: "AUKAX",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kirkimbie",
        city: "Kirkimbie",
        iata: "KBB",
        countryIata: "AUKBB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kimberley Downs",
        city: "Kimberley Downs",
        iata: "KBD",
        countryIata: "AUKBD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "King Canyon",
        city: "Kings Canyon",
        iata: "KBJ",
        countryIata: "AUKBJ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Streaky Bay",
        city: "Streaky Bay",
        iata: "KBY",
        countryIata: "AUKBY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Collinsville",
        city: "Collinsville",
        iata: "KCE",
        countryIata: "AUKCE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kings Creek Station",
        city: "Kings Creek Station",
        iata: "KCS",
        countryIata: "AUKCS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kambalda",
        city: "Kambalda",
        iata: "KDB",
        countryIata: "AUKDB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kamaran Downs",
        city: "Kamaran Downs",
        iata: "KDS",
        countryIata: "AUKDS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kalkurung",
        city: "Kalkurung",
        iata: "KFG",
        countryIata: "AUKFG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kingscote",
        city: "Kingscote",
        iata: "KGC",
        countryIata: "AUKGC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kalgoorlie",
        city: "Kalgoorlie",
        iata: "KGI",
        countryIata: "AUKGI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kulgera",
        city: "Kulgera",
        iata: "KGR",
        countryIata: "AUKGR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kingaroy",
        city: "Kingaroy",
        iata: "KGY",
        countryIata: "AUKGY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Koolburra",
        city: "Koolburra",
        iata: "KKP",
        countryIata: "AUKKP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kamileroi",
        city: "Kamileroi",
        iata: "KML",
        countryIata: "AUKML",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Katanning",
        city: "Katanning",
        iata: "KNI",
        countryIata: "AUKNI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "King Island",
        city: "King Island",
        iata: "KNS",
        countryIata: "AUKNS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kununurra",
        city: "Kununurra",
        iata: "KNX",
        countryIata: "AUKNX",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Koolatah",
        city: "Koolatah",
        iata: "KOH",
        countryIata: "AUKOH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kalpowar",
        city: "Kalpowar",
        iata: "KPP",
        countryIata: "AUKPP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kempsey",
        city: "Kempsey",
        iata: "KPS",
        countryIata: "AUKPS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Koonibba",
        city: "Koonibba",
        iata: "KQB",
        countryIata: "AUKQB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kerang",
        city: "Kerang",
        iata: "KRA",
        countryIata: "AUKRA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Karumba",
        city: "Karumba",
        iata: "KRB",
        countryIata: "AUKRB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kurundi",
        city: "Kurundi",
        iata: "KRD",
        countryIata: "AUKRD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Springvale",
        city: "Springvale",
        iata: "KSV",
        countryIata: "AUKSV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Karratha",
        city: "Karratha",
        iata: "KTA",
        countryIata: "AUKTA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Tindal",
        city: "Katherine",
        iata: "KTR",
        countryIata: "AUKTR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kubin Island",
        city: "Kubin Island",
        iata: "KUG",
        countryIata: "AUKUG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kowanyama",
        city: "Kowanyama",
        iata: "KWM",
        countryIata: "AUKWM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Yangoonabie",
        city: "Yangoonabie",
        iata: "KYB",
        countryIata: "AUKYB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Yeelirrie",
        city: "Yeelirrie",
        iata: "KYF",
        countryIata: "AUKYF",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Yalata Mission Airport",
        city: "Yalata Mission",
        iata: "KYI",
        countryIata: "AUKYI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Palm Beach SPB",
        city: "Sydney",
        iata: "LBH",
        countryIata: "AULBH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Balcanoona",
        city: "Balcanoona",
        iata: "LCN",
        countryIata: "AULCN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lindeman Island",
        city: "Lindeman Island",
        iata: "LDC",
        countryIata: "AULDC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lord Howe Island",
        city: "Lord Howe Island",
        iata: "LDH",
        countryIata: "AULDH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lansdowne",
        city: "Lansdowne",
        iata: "LDW",
        countryIata: "AULDW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Learmonth",
        city: "Learmonth",
        iata: "LEA",
        countryIata: "AULEA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lake Evella",
        city: "Lake Evella",
        iata: "LEL",
        countryIata: "AULEL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Leinster",
        city: "Leinster",
        iata: "LER",
        countryIata: "AULER",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lakefield",
        city: "Lakefield",
        iata: "LFP",
        countryIata: "AULFP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lake Gregory",
        city: "Lake Gregory",
        iata: "LGE",
        countryIata: "AULGE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Leigh Creek",
        city: "Leigh Creek",
        iata: "LGH",
        countryIata: "AULGH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lightning Ridge",
        city: "Lightning Ridge",
        iata: "LHG",
        countryIata: "AULHG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Limbunya",
        city: "Limbunya",
        iata: "LIB",
        countryIata: "AULIB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lakeland Downs",
        city: "Lakeland Downs",
        iata: "LKD",
        countryIata: "AULKD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Chillagoe",
        city: "Chillagoe",
        iata: "LLG",
        countryIata: "AULLG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lissadell",
        city: "Lissadell",
        iata: "LLL",
        countryIata: "AULLL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Linda Downs",
        city: "Linda Downs",
        iata: "LLP",
        countryIata: "AULLP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lake Nash",
        city: "Lake Nash",
        iata: "LNH",
        countryIata: "AULNH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Leonora",
        city: "Leonora",
        iata: "LNO",
        countryIata: "AULNO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lorraine",
        city: "Lorraine",
        iata: "LOA",
        countryIata: "AULOA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lock",
        city: "Lock",
        iata: "LOC",
        countryIata: "AULOC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Longreach",
        city: "Longreach",
        iata: "LRE",
        countryIata: "AULRE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Launceston",
        city: "Launceston",
        iata: "LST",
        countryIata: "AULST",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lismore",
        city: "Lismore",
        iata: "LSY",
        countryIata: "AULSY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Latrobe",
        city: "Latrobe",
        iata: "LTB",
        countryIata: "AULTB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lyndhurst",
        city: "Lyndhurst",
        iata: "LTP",
        countryIata: "AULTP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lotusvale",
        city: "Lotusvale",
        iata: "LTV",
        countryIata: "AULTV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Laura Station",
        city: "Laura Station",
        iata: "LUT",
        countryIata: "AULUT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Laura",
        city: "Laura",
        iata: "LUU",
        countryIata: "AULUU",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Laverton",
        city: "Laverton",
        iata: "LVO",
        countryIata: "AULVO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lawn Hill",
        city: "Lawn Hill",
        iata: "LWH",
        countryIata: "AULWH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lady Elliot Island",
        city: "Lady Elliot Island",
        iata: "LYT",
        countryIata: "AULYT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Lizard Island",
        city: "Lizard Island",
        iata: "LZR",
        countryIata: "AULZR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Marble Bar",
        city: "Marble Bar",
        iata: "MBB",
        countryIata: "AUMBB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mount Buffalo",
        city: "Mount Buffalo",
        iata: "MBF",
        countryIata: "AUMBF",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Maryborough",
        city: "Maryborough",
        iata: "MBH",
        countryIata: "AUMBH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mt Barnett",
        city: "Mt Barnett",
        iata: "MBN",
        countryIata: "AUMBN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Moorabbin",
        city: "Moorabbin",
        iata: "MBW",
        countryIata: "AUMBW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mcarthur River",
        city: "Mcarthur River",
        iata: "MCV",
        countryIata: "AUMCV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Maroochydore",
        city: "Sunshine Coast",
        iata: "MCY",
        countryIata: "AUMCY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Essendon",
        city: "Melbourne",
        iata: "MEB",
        countryIata: "AUMEB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Melbourne Airport Tullamarine",
        city: "Melbourne",
        iata: "MEL",
        countryIata: "AUMEL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Moreton",
        city: "Moreton",
        iata: "MET",
        countryIata: "AUMET",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mount Full Stop",
        city: "Mount Full Stop",
        iata: "MFL",
        countryIata: "AUMFL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Manners Creek",
        city: "Manners Creek",
        iata: "MFP",
        countryIata: "AUMFP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mount Gambier",
        city: "Mount Gambier",
        iata: "MGB",
        countryIata: "AUMGB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Milingimbi",
        city: "Milingimbi",
        iata: "MGT",
        countryIata: "AUMGT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Margaret River",
        city: "Margaret River Station",
        iata: "MGV",
        countryIata: "AUMGV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Macmahon Camp 4",
        city: "Macmahon Camp 4",
        iata: "MHC",
        countryIata: "AUMHC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mount House",
        city: "Mount House",
        iata: "MHO",
        countryIata: "AUMHO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mount Hotham",
        city: "Mount Hotham",
        iata: "MHU",
        countryIata: "AUMHU",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mitchell Plateau",
        city: "Mitchell Plateau",
        iata: "MIH",
        countryIata: "AUMIH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Merimbula",
        city: "Merimbula",
        iata: "MIM",
        countryIata: "AUMIM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Minnipa",
        city: "Minnipa",
        iata: "MIN",
        countryIata: "AUMIN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mittiebah",
        city: "Mittiebah",
        iata: "MIY",
        countryIata: "AUMIY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mainoru",
        city: "Mainoru",
        iata: "MIZ",
        countryIata: "AUMIZ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Shark Bay",
        city: "Monkey Mia",
        iata: "MJK",
        countryIata: "AUMJK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Manjimup",
        city: "Manjimup",
        iata: "MJP",
        countryIata: "AUMJP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Meekatharra",
        city: "Meekatharra",
        iata: "MKR",
        countryIata: "AUMKR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mt Cavenagh",
        city: "Mt Cavenagh",
        iata: "MKV",
        countryIata: "AUMKV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mackay",
        city: "Mackay",
        iata: "MKY",
        countryIata: "AUMKY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Millicent",
        city: "Millicent",
        iata: "MLR",
        countryIata: "AUMLR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Merluna",
        city: "Merluna",
        iata: "MLV",
        countryIata: "AUMLV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mount Magnet",
        city: "Mount Magnet",
        iata: "MMG",
        countryIata: "AUMMG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Middlemount",
        city: "Middlemount",
        iata: "MMM",
        countryIata: "AUMMM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mungeranie",
        city: "Mungeranie",
        iata: "MNE",
        countryIata: "AUMNE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Maningrida",
        city: "Maningrida",
        iata: "MNG",
        countryIata: "AUMNG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Monto",
        city: "Monto",
        iata: "MNQ",
        countryIata: "AUMNQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mountain Valley",
        city: "Mountain Valley",
        iata: "MNV",
        countryIata: "AUMNV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Macdonald Downs",
        city: "Macdonald Downs",
        iata: "MNW",
        countryIata: "AUMNW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Moomba",
        city: "Moomba",
        iata: "MOO",
        countryIata: "AUMOO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Moranbah",
        city: "Moranbah",
        iata: "MOV",
        countryIata: "AUMOV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mandora",
        city: "Mandora",
        iata: "MQA",
        countryIata: "AUMQA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Marqua",
        city: "Marqua",
        iata: "MQE",
        countryIata: "AUMQE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mildura",
        city: "Mildura",
        iata: "MQL",
        countryIata: "AUMQL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Margaret River",
        city: "Margaret River",
        iata: "MQZ",
        countryIata: "AUMQZ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mareeba",
        city: "Mareeba",
        iata: "MRG",
        countryIata: "AUMRG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Miners Lake",
        city: "Miners Lake",
        iata: "MRL",
        countryIata: "AUMRL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Marla",
        city: "Marla",
        iata: "MRP",
        countryIata: "AUMRP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Moroak",
        city: "Moroak",
        iata: "MRT",
        countryIata: "AUMRT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Moree",
        city: "Moree",
        iata: "MRZ",
        countryIata: "AUMRZ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mount Swan",
        city: "Mount Swan",
        iata: "MSF",
        countryIata: "AUMSF",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mt Sandford",
        city: "Mt Sandford",
        iata: "MTD",
        countryIata: "AUMTD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Maitland",
        city: "Maitland",
        iata: "MTL",
        countryIata: "AUMTL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mitchell",
        city: "Mitchell",
        iata: "MTQ",
        countryIata: "AUMTQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mulga Park",
        city: "Mulga Park",
        iata: "MUP",
        countryIata: "AUMUP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Muccan",
        city: "Muccan",
        iata: "MUQ",
        countryIata: "AUMUQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Macksville",
        city: "Macksville",
        iata: "MVH",
        countryIata: "AUMVH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mulka",
        city: "Mulka",
        iata: "MVK",
        countryIata: "AUMVK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Musgrave",
        city: "Musgrave",
        iata: "MVU",
        countryIata: "AUMVU",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Morawa",
        city: "Morawa",
        iata: "MWB",
        countryIata: "AUMWB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Moolawatana",
        city: "Moolawatana",
        iata: "MWT",
        countryIata: "AUMWT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Miranda Downs",
        city: "Miranda Downs",
        iata: "MWY",
        countryIata: "AUMWY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Marion Downs",
        city: "Marion Downs",
        iata: "MXD",
        countryIata: "AUMXD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mitchell River",
        city: "Mitchell River",
        iata: "MXQ",
        countryIata: "AUMXQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mullewa",
        city: "Mullewa",
        iata: "MXU",
        countryIata: "AUMXU",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Moruya",
        city: "Moruya",
        iata: "MYA",
        countryIata: "AUMYA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Murray Island",
        city: "Murray Island",
        iata: "MYI",
        countryIata: "AUMYI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Myroodah",
        city: "Myroodah",
        iata: "MYO",
        countryIata: "AUMYO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Narrabri",
        city: "Narrabri",
        iata: "NAA",
        countryIata: "AUNAA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Naracoorte",
        city: "Naracoorte",
        iata: "NAC",
        countryIata: "AUNAC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Nambucca Heads",
        city: "Nambucca Heads",
        iata: "NBH",
        countryIata: "AUNBH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Nambour",
        city: "Nambour",
        iata: "NBR",
        countryIata: "AUNBR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Sandstone",
        city: "Sandstone",
        iata: "NDS",
        countryIata: "AUNDS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Young",
        city: "Young",
        iata: "NGA",
        countryIata: "AUNGA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Nifty",
        city: "Nifty",
        iata: "NIF",
        countryIata: "AUNIF",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Noonkanbah",
        city: "Noonkanbah",
        iata: "NKB",
        countryIata: "AUNKB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Darnley Island",
        city: "Darnley Island",
        iata: "NLF",
        countryIata: "AUNLF",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Nullagine",
        city: "Nullagine",
        iata: "NLL",
        countryIata: "AUNLL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Nicholson",
        city: "Nicholson",
        iata: "NLS",
        countryIata: "AUNLS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "New Moon",
        city: "New Moon",
        iata: "NMP",
        countryIata: "AUNMP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Nappa Merry",
        city: "Nappa Merry",
        iata: "NMR",
        countryIata: "AUNMR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Nowra",
        city: "Nowra",
        iata: "NOA",
        countryIata: "AUNOA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Napperby",
        city: "Napperby",
        iata: "NPP",
        countryIata: "AUNPP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Narrandera",
        city: "Narrandera",
        iata: "NRA",
        countryIata: "AUNRA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Narrogin",
        city: "Narrogin",
        iata: "NRG",
        countryIata: "AUNRG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Newry",
        city: "Newry",
        iata: "NRY",
        countryIata: "AUNRY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Noosa",
        city: "Noosa",
        iata: "NSA",
        countryIata: "AUNSA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Norseman",
        city: "Norseman",
        iata: "NSM",
        countryIata: "AUNSM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Scone",
        city: "Scone",
        iata: "NSO",
        countryIata: "AUNSO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Noosaville",
        city: "Noosaville",
        iata: "NSV",
        countryIata: "AUNSV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Williamtown",
        city: "Newcastle",
        iata: "NTL",
        countryIata: "AUNTL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Normanton",
        city: "Normanton",
        iata: "NTN",
        countryIata: "AUNTN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Numbulwar",
        city: "Numbulwar",
        iata: "NUB",
        countryIata: "AUNUB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Nullarbor",
        city: "Nullarbor",
        iata: "NUR",
        countryIata: "AUNUR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Nyngan",
        city: "Nyngan",
        iata: "NYN",
        countryIata: "AUNYN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Springhill",
        city: "Orange",
        iata: "OAG",
        countryIata: "AUOAG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Oban",
        city: "Oban",
        iata: "OBA",
        countryIata: "AUOBA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Oodnadatta",
        city: "Oodnadatta",
        iata: "ODD",
        countryIata: "AUODD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cordillo Downs",
        city: "Cordillo Downs",
        iata: "ODL",
        countryIata: "AUODL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Ord River",
        city: "Ord River",
        iata: "ODR",
        countryIata: "AUODR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Fraser Island",
        city: "Orchid Beach",
        iata: "OKB",
        countryIata: "AUOKB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Yorke Island",
        city: "Yorke Island",
        iata: "OKR",
        countryIata: "AUOKR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Oakey",
        city: "Oakey",
        iata: "OKY",
        countryIata: "AUOKY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Olympic Dam",
        city: "Olympic Dam",
        iata: "OLP",
        countryIata: "AUOLP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mornington",
        city: "Mornington",
        iata: "ONG",
        countryIata: "AUONG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Monkira",
        city: "Monkira",
        iata: "ONR",
        countryIata: "AUONR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Onslow",
        city: "Onslow",
        iata: "ONS",
        countryIata: "AUONS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Coolangatta",
        city: "Gold Coast",
        iata: "OOL",
        countryIata: "AUOOL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Cooma",
        city: "Cooma",
        iata: "OOM",
        countryIata: "AUOOM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mooraberree",
        city: "Mooraberree",
        iata: "OOR",
        countryIata: "AUOOR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Oenpelli",
        city: "Oenpelli",
        iata: "OPI",
        countryIata: "AUOPI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Yorketown",
        city: "Yorketown",
        iata: "ORR",
        countryIata: "AUORR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Waterport",
        city: "Orpheus Island Resort",
        iata: "ORS",
        countryIata: "AUORS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Orientos",
        city: "Orientos",
        iata: "OXO",
        countryIata: "AUOXO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Morney",
        city: "Morney",
        iata: "OXY",
        countryIata: "AUOXY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Ouyen",
        city: "Ouyen",
        iata: "OYN",
        countryIata: "AUOYN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Paraburdoo",
        city: "Paraburdoo",
        iata: "PBO",
        countryIata: "AUPBO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Pandie Pandie",
        city: "Pandie Pandie",
        iata: "PDE",
        countryIata: "AUPDE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Parndana",
        city: "Parndana",
        iata: "PDN",
        countryIata: "AUPDN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Penneshaw",
        city: "Penneshaw",
        iata: "PEA",
        countryIata: "AUPEA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Peppimenarti",
        city: "Peppimenarti",
        iata: "PEP",
        countryIata: "AUPEP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Perth",
        city: "Perth",
        iata: "PER",
        countryIata: "AUPER",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Penong",
        city: "Penong",
        iata: "PEY",
        countryIata: "AUPEY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Port Hedland Intl Airport",
        city: "Port Hedland",
        iata: "PHE",
        countryIata: "AUPHE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Port Hunter",
        city: "Port Hunter",
        iata: "PHJ",
        countryIata: "AUPHJ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Parkes",
        city: "Parkes",
        iata: "PKE",
        countryIata: "AUPKE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Port Keats",
        city: "Port Keats",
        iata: "PKT",
        countryIata: "AUPKT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Port Lincoln",
        city: "Port Lincoln",
        iata: "PLO",
        countryIata: "AUPLO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Palm Island",
        city: "Palm Island",
        iata: "PMK",
        countryIata: "AUPMK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Port Pirie",
        city: "Port Pirie",
        iata: "PPI",
        countryIata: "AUPPI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Proserpine",
        city: "Proserpine",
        iata: "PPP",
        countryIata: "AUPPP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Port Macquarie",
        city: "Port Macquarie",
        iata: "PQQ",
        countryIata: "AUPQQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Pardoo",
        city: "Pardoo",
        iata: "PRD",
        countryIata: "AUPRD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Port Stephens",
        city: "Port Stephens",
        iata: "PTE",
        countryIata: "AUPTE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Port Douglas",
        city: "Port Douglas",
        iata: "PTI",
        countryIata: "AUPTI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Portland",
        city: "Portland",
        iata: "PTJ",
        countryIata: "AUPTJ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Port Augusta",
        city: "Port Augusta",
        iata: "PUG",
        countryIata: "AUPUG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Ramingining",
        city: "Ramingining",
        iata: "RAM",
        countryIata: "AURAM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Robinvale",
        city: "Robinvale",
        iata: "RBC",
        countryIata: "AURBC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Orbost",
        city: "Orbost",
        iata: "RBS",
        countryIata: "AURBS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Roebourne",
        city: "Roebourne",
        iata: "RBU",
        countryIata: "AURBU",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Richmond",
        city: "Richmond",
        iata: "RCM",
        countryIata: "AURCM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "American River",
        city: "American River",
        iata: "RCN",
        countryIata: "AURCN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Rockhampton Downs",
        city: "Rockhampton Downs",
        iata: "RDA",
        countryIata: "AURDA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Roy Hill",
        city: "Roy Hill",
        iata: "RHL",
        countryIata: "AURHL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Rokeby",
        city: "Rokeby",
        iata: "RKY",
        countryIata: "AURKY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Rosella Plains",
        city: "Rosella Plains",
        iata: "RLP",
        countryIata: "AURLP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Roma",
        city: "Roma",
        iata: "RMA",
        countryIata: "AURMA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Renmark",
        city: "Renmark",
        iata: "RMK",
        countryIata: "AURMK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Robinhood",
        city: "Robinhood",
        iata: "ROH",
        countryIata: "AUROH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Rockhampton",
        city: "Rockhampton",
        iata: "ROK",
        countryIata: "AUROK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Roper Bar",
        city: "Roper Bar",
        iata: "RPB",
        countryIata: "AURPB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Ngukurr",
        city: "Ngukurr",
        iata: "RPM",
        countryIata: "AURPM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Roper Valley",
        city: "Roper Valley",
        iata: "RPV",
        countryIata: "AURPV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Marree",
        city: "Marree",
        iata: "RRE",
        countryIata: "AURRE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Robinson River",
        city: "Robinson River",
        iata: "RRV",
        countryIata: "AURRV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Roseberth",
        city: "Roseberth",
        iata: "RSB",
        countryIata: "AURSB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Au-Rose Bay",
        city: "Sydney",
        iata: "RSE",
        countryIata: "AURSE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Rutland Plains",
        city: "Rutland Plains",
        iata: "RTP",
        countryIata: "AURTP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Rottnest Island",
        city: "Rottnest Island",
        iata: "RTS",
        countryIata: "AURTS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Merty",
        city: "Merty",
        iata: "RTY",
        countryIata: "AURTY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Saibai Island",
        city: "Saibai Island",
        iata: "SBR",
        countryIata: "AUSBR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Spring Creek",
        city: "Spring Creek",
        iata: "SCG",
        countryIata: "AUSCG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Surfers Paradise",
        city: "Surfers Paradise",
        iata: "SFP",
        countryIata: "AUSFP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "St George",
        city: "St George",
        iata: "SGO",
        countryIata: "AUSGO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Shay Gap",
        city: "Shay Gap",
        iata: "SGP",
        countryIata: "AUSGP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Southport",
        city: "Southport",
        iata: "SHQ",
        countryIata: "AUSHQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Shepparton",
        city: "Shepparton",
        iata: "SHT",
        countryIata: "AUSHT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Smith Point",
        city: "Smith Point",
        iata: "SHU",
        countryIata: "AUSHU",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Smithton",
        city: "Smithton",
        iata: "SIO",
        countryIata: "AUSIO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Singleton",
        city: "Singleton",
        iata: "SIX",
        countryIata: "AUSIX",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Snake Bay",
        city: "Snake Bay",
        iata: "SNB",
        countryIata: "AUSNB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Stanthorpe",
        city: "Stanthorpe",
        iata: "SNH",
        countryIata: "AUSNH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "South Molle Island",
        city: "South Molle Island",
        iata: "SOI",
        countryIata: "AUSOI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Southern Cross",
        city: "Southern Cross",
        iata: "SQC",
        countryIata: "AUSQC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Starcke",
        city: "Starcke",
        iata: "SQP",
        countryIata: "AUSQP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Sandringham",
        city: "Sandringham",
        iata: "SRM",
        countryIata: "AUSRM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Strahan",
        city: "Strahan",
        iata: "SRN",
        countryIata: "AUSRN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Dunwich",
        city: "Stradbroke Island",
        iata: "SRR",
        countryIata: "AUSRR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Sturt Creek",
        city: "Sturt Creek",
        iata: "SSK",
        countryIata: "AUSSK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Silver Plains",
        city: "Silver Plains",
        iata: "SSP",
        countryIata: "AUSSP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Stephen Island",
        city: "Stephen Island",
        iata: "STF",
        countryIata: "AUSTF",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Strathmore",
        city: "Strathmore",
        iata: "STH",
        countryIata: "AUSTH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "St Paul's Mission",
        city: "St Paul's Mission",
        iata: "SVM",
        countryIata: "AUSVM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Shaw River",
        city: "Shaw River",
        iata: "SWB",
        countryIata: "AUSWB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Stawell",
        city: "Stawell",
        iata: "SWC",
        countryIata: "AUSWC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Swan Hill",
        city: "Swan Hill",
        iata: "SWH",
        countryIata: "AUSWH",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Sydney West",
        city: "Sydney",
        iata: "SWZ",
        countryIata: "AUSWZ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Sale",
        city: "Sale",
        iata: "SXE",
        countryIata: "AUSXE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kingsford Smith",
        city: "Sydney",
        iata: "SYD",
        countryIata: "AUSYD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Warraber Island",
        city: "Sue Island",
        iata: "SYU",
        countryIata: "AUSYU",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Tangalooma",
        city: "Tangalooma",
        iata: "TAN",
        countryIata: "AUTAN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Tarcoola",
        city: "Tarcoola",
        iata: "TAQ",
        countryIata: "AUTAQ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Timber Creek",
        city: "Timber Creek",
        iata: "TBK",
        countryIata: "AUTBK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Tableland",
        city: "Tableland",
        iata: "TBL",
        countryIata: "AUTBL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Tennant Creek",
        city: "Tennant Creek",
        iata: "TCA",
        countryIata: "AUTCA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Tocumwal",
        city: "Tocumwal",
        iata: "TCW",
        countryIata: "AUTCW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Theda Station",
        city: "Theda",
        iata: "TDN",
        countryIata: "AUTDN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Theodore",
        city: "Theodore",
        iata: "TDR",
        countryIata: "AUTDR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Telfer",
        city: "Telfer",
        iata: "TEF",
        countryIata: "AUTEF",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Temora",
        city: "Temora",
        iata: "TEM",
        countryIata: "AUTEM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "La Trobe Regional",
        city: "Traralgon",
        iata: "TGN",
        countryIata: "AUTGN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Thangool",
        city: "Thangool",
        iata: "THG",
        countryIata: "AUTHG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Thursday Island",
        city: "Thursday Island",
        iata: "TIS",
        countryIata: "AUTIS",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Turkey Creek",
        city: "Turkey Creek",
        iata: "TKY",
        countryIata: "AUTKY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Tamworth",
        city: "Tamworth",
        iata: "TMW",
        countryIata: "AUTMW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Tom Price",
        city: "Tom Price",
        iata: "TPR",
        countryIata: "AUTPR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Taree",
        city: "Taree",
        iata: "TRO",
        countryIata: "AUTRO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Townsville",
        city: "Townsville",
        iata: "TSV",
        countryIata: "AUTSV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Tumut",
        city: "Tumut",
        iata: "TUM",
        countryIata: "AUTUM",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Toowoomba",
        city: "Toowoomba",
        iata: "TWB",
        countryIata: "AUTWB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Tewantin",
        city: "Tewantin",
        iata: "TWN",
        countryIata: "AUTWN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Torwood",
        city: "Torwood",
        iata: "TWP",
        countryIata: "AUTWP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Tanbar",
        city: "Tanbar",
        iata: "TXR",
        countryIata: "AUTXR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Tibooburra",
        city: "Tibooburra",
        iata: "TYB",
        countryIata: "AUTYB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Thylungra",
        city: "Thylungra",
        iata: "TYG",
        countryIata: "AUTYG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Tobermorey",
        city: "Tobermorey",
        iata: "TYP",
        countryIata: "AUTYP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mabuiag Island",
        city: "Mabuiag Island",
        iata: "UBB",
        countryIata: "AUUBB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kalumburu",
        city: "Kalumburu",
        iata: "UBU",
        countryIata: "AUUBU",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Undarra",
        city: "Undarra",
        iata: "UDA",
        countryIata: "AUUDA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Queenstown",
        city: "Queenstown",
        iata: "UEE",
        countryIata: "AUUEE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Quirindi",
        city: "Quirindi",
        iata: "UIR",
        countryIata: "AUUIR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Quilpie",
        city: "Quilpie",
        iata: "ULP",
        countryIata: "AUULP",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Woomera",
        city: "Woomera",
        iata: "UMR",
        countryIata: "AUUMR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Useless Loop",
        city: "Useless Loop",
        iata: "USL",
        countryIata: "AUUSL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Muttaburra",
        city: "Muttaburra",
        iata: "UTB",
        countryIata: "AUUTB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Nutwood Downs",
        city: "Nutwood Downs",
        iata: "UTD",
        countryIata: "AUUTD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Victoria River Downs",
        city: "Victoria River Downs",
        iata: "VCD",
        countryIata: "AUVCD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Vanrook",
        city: "Vanrook",
        iata: "VNR",
        countryIata: "AUVNR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Waverney",
        city: "Waverney",
        iata: "WAN",
        countryIata: "AUWAN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Wauchope",
        city: "Wauchope",
        iata: "WAU",
        countryIata: "AUWAU",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Kalkgurung",
        city: "Wave Hill",
        iata: "WAV",
        countryIata: "AUWAV",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Warwick",
        city: "Warwick",
        iata: "WAZ",
        countryIata: "AUWAZ",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Wondai",
        city: "Wondai",
        iata: "WDI",
        countryIata: "AUWDI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Weipa",
        city: "Weipa",
        iata: "WEI",
        countryIata: "AUWEI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Wee Waa",
        city: "Wee Waa",
        iata: "WEW",
        countryIata: "AUWEW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Forrest Hill",
        city: "Wagga Wagga",
        iata: "WGA",
        countryIata: "AUWGA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Walgett",
        city: "Walgett",
        iata: "WGE",
        countryIata: "AUWGE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Wangaratta",
        city: "Wangaratta",
        iata: "WGT",
        countryIata: "AUWGT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Welshpool",
        city: "Welshpool",
        iata: "WHL",
        countryIata: "AUWHL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Winton",
        city: "Winton",
        iata: "WIN",
        countryIata: "AUWIN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Wilcannia",
        city: "Wilcannia",
        iata: "WIO",
        countryIata: "AUWIO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Wittenoom",
        city: "Wittenoom",
        iata: "WIT",
        countryIata: "AUWIT",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Warracknabeal",
        city: "Warracknabeal",
        iata: "WKB",
        countryIata: "AUWKB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Wallal",
        city: "Wallal",
        iata: "WLA",
        countryIata: "AUWLA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Walcha",
        city: "Walcha",
        iata: "WLC",
        countryIata: "AUWLC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Wollogorang airfield",
        city: "Wollogorang",
        iata: "WLL",
        countryIata: "AUWLL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Waterloo",
        city: "Waterloo",
        iata: "WLO",
        countryIata: "AUWLO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Warrnambool",
        city: "Warrnambool",
        iata: "WMB",
        countryIata: "AUWMB",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mount Keith",
        city: "Mount Keith",
        iata: "WME",
        countryIata: "AUWME",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Windarra",
        city: "Windarra",
        iata: "WND",
        countryIata: "AUWND",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Windorah",
        city: "Windorah",
        iata: "WNR",
        countryIata: "AUWNR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Woodgreen",
        city: "Woodgreen",
        iata: "WOG",
        countryIata: "AUWOG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Wollongong",
        city: "Wollongong",
        iata: "WOL",
        countryIata: "AUWOL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Wondoola",
        city: "Wondoola",
        iata: "WON",
        countryIata: "AUWON",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Wrotham Park",
        city: "Wrotham Park",
        iata: "WPK",
        countryIata: "AUWPK",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Warrawagine",
        city: "Warrawagine",
        iata: "WRW",
        countryIata: "AUWRW",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Whitsunday Airstrip",
        city: "Airlie Beach",
        iata: "WSY",
        countryIata: "AUWSY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Wudinna",
        city: "Wudinna",
        iata: "WUD",
        countryIata: "AUWUD",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Wiluna",
        city: "Wiluna",
        iata: "WUN",
        countryIata: "AUWUN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Woodie Woodie",
        city: "Woodie Woodie",
        iata: "WWI",
        countryIata: "AUWWI",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "West Wyalong",
        city: "West Wyalong",
        iata: "WWY",
        countryIata: "AUWWY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Whyalla",
        city: "Whyalla",
        iata: "WYA",
        countryIata: "AUWYA",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Wyndham",
        city: "Wyndham",
        iata: "WYN",
        countryIata: "AUWYN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Colac",
        city: "Colac",
        iata: "XCO",
        countryIata: "AUXCO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Mallacoota",
        city: "Mallacoota",
        iata: "XMC",
        countryIata: "AUXMC",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Minlaton",
        city: "Minlaton",
        iata: "XML",
        countryIata: "AUXML",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Yam Island",
        city: "Yam Island",
        iata: "XMY",
        countryIata: "AUXMY",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Thargomindah",
        city: "Thargomindah",
        iata: "XTG",
        countryIata: "AUXTG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Taroom",
        city: "Taroom",
        iata: "XTO",
        countryIata: "AUXTO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Tara",
        city: "Tara",
        iata: "XTR",
        countryIata: "AUXTR",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Yalgoo",
        city: "Yalgoo",
        iata: "YLG",
        countryIata: "AUYLG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Yandi",
        city: "Yandicoogina",
        iata: "YNN",
        countryIata: "AUYNN",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Yuendumu",
        city: "Yuendumu",
        iata: "YUE",
        countryIata: "AUYUE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Biloela",
        city: "Biloela",
        iata: "ZBL",
        countryIata: "AUZBL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Bowen",
        city: "Bowen",
        iata: "ZBO",
        countryIata: "AUZBO",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "South Galway",
        city: "South Galway",
        iata: "ZGL",
        countryIata: "AUZGL",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Newman",
        city: "Newman",
        iata: "ZNE",
        countryIata: "AUZNE",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "Springvale",
        city: "Springvale",
        iata: "ZVG",
        countryIata: "AUZVG",
        country: "Australia",
        countryCode: "AU",
    },
    {
        airportName: "St. Anton",
        city: "St. Anton",
        iata: "ANT",
        countryIata: "ATANT",
        country: "Austria",
        countryCode: "AT",
    },
    {
        airportName: "Thalerhof",
        city: "Graz",
        iata: "GRZ",
        countryIata: "ATGRZ",
        country: "Austria",
        countryCode: "AT",
    },
    {
        airportName: "Hohenems",
        city: "Hohenems",
        iata: "HOH",
        countryIata: "ATHOH",
        country: "Austria",
        countryCode: "AT",
    },
    {
        airportName: "Kranebitten",
        city: "Innsbruck",
        iata: "INN",
        countryIata: "ATINN",
        country: "Austria",
        countryCode: "AT",
    },
    {
        airportName: "Klagenfurt",
        city: "Klagenfurt",
        iata: "KLU",
        countryIata: "ATKLU",
        country: "Austria",
        countryCode: "AT",
    },
    {
        airportName: "Hoersching",
        city: "Linz",
        iata: "LNZ",
        countryIata: "ATLNZ",
        country: "Austria",
        countryCode: "AT",
    },
    {
        airportName: "Lauterach",
        city: "Lauterach",
        iata: "QLX",
        countryIata: "ATQLX",
        country: "Austria",
        countryCode: "AT",
    },
    {
        airportName: "W.A. Mozart",
        city: "Salzburg",
        iata: "SZG",
        countryIata: "ATSZG",
        country: "Austria",
        countryCode: "AT",
    },
    {
        airportName: "Vienna Danubepier Hov",
        city: "Vienna",
        iata: "VDD",
        countryIata: "ATVDD",
        country: "Austria",
        countryCode: "AT",
    },
    {
        airportName: "Wien-Schwechat International",
        city: "Vienna (Wien)",
        iata: "VIE",
        countryIata: "ATVIE",
        country: "Austria",
        countryCode: "AT",
    },
    {
        airportName: "Flexenpass Heliport",
        city: "Zurs/Lech",
        iata: "ZRS",
        countryIata: "ATZRS",
        country: "Austria",
        countryCode: "AT",
    },
    {
        airportName: "Baku",
        city: "Baku",
        iata: "BAK",
        countryIata: "AZBAK",
        country: "Azerbaijan",
        countryCode: "AZ",
    },
    {
        airportName: "Gyandzha",
        city: "Gyandzha",
        iata: "KVD",
        countryIata: "AZKVD",
        country: "Azerbaijan",
        countryCode: "AZ",
    },
    {
        airportName: "Nakhichevan",
        city: "Nakhichevan",
        iata: "NAJ",
        countryIata: "AZNAJ",
        country: "Azerbaijan",
        countryCode: "AZ",
    },
    {
        airportName: "Andros Town International Airport",
        city: "Andros Town",
        iata: "ASD",
        countryIata: "BSASD",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Arthur's Town",
        city: "Arthur's Town",
        iata: "ATC",
        countryIata: "BSATC",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Springpoint Airport",
        city: "Spring Point",
        iata: "AXP",
        countryIata: "BSAXP",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "South Bimini Airport",
        city: "Bimini",
        iata: "BIM",
        countryIata: "BSBIM",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Cat Island",
        city: "Cat Island",
        iata: "CAT",
        countryIata: "BSCAT",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Chub Cay",
        city: "Chub Cay",
        iata: "CCZ",
        countryIata: "BSCCZ",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Cape Eleuthera",
        city: "Cape Eleuthera",
        iata: "CEL",
        countryIata: "BSCEL",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Congo Town",
        city: "Congo Town",
        iata: "COX",
        countryIata: "BSCOX",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Crooked Island",
        city: "Crooked Island",
        iata: "CRI",
        countryIata: "BSCRI",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Cat Cays",
        city: "Cat Cays",
        iata: "CXY",
        countryIata: "BSCXY",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Duncan Town",
        city: "Duncan Town",
        iata: "DCT",
        countryIata: "BSDCT",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "International",
        city: "North Eleuthera",
        iata: "ELH",
        countryIata: "BSELH",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Grand Bahama International",
        city: "Freeport",
        iata: "FPO",
        countryIata: "BSFPO",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Exuma International",
        city: "George Town",
        iata: "GGT",
        countryIata: "BSGGT",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Governors Harbour",
        city: "Governors Harbour",
        iata: "GHB",
        countryIata: "BSGHB",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Great Harbour",
        city: "Great Harbour",
        iata: "GHC",
        countryIata: "BSGHC",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Green Turtle",
        city: "Green Turtle",
        iata: "GTC",
        countryIata: "BSGTC",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Harbour Island",
        city: "Harbour Island",
        iata: "HBI",
        countryIata: "BSHBI",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Inagua",
        city: "Inagua",
        iata: "IGA",
        countryIata: "BSIGA",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Deadmans Cay",
        city: "Long Island",
        iata: "LGI",
        countryIata: "BSLGI",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Mangrove Cay",
        city: "Mangrove Cay",
        iata: "MAY",
        countryIata: "BSMAY",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "International",
        city: "Marsh Harbour",
        iata: "MHH",
        countryIata: "BSMHH",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Mastic Point",
        city: "Mastic Point",
        iata: "MSK",
        countryIata: "BSMSK",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Mayaguana",
        city: "Mayaguana",
        iata: "MYG",
        countryIata: "BSMYG",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Nassau International",
        city: "Nassau",
        iata: "NAS",
        countryIata: "BSNAS",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "New Bight",
        city: "New Bight",
        iata: "NET",
        countryIata: "BSNET",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Norman's Cay",
        city: "Norman's Cay",
        iata: "NMC",
        countryIata: "BSNMC",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "North SPB",
        city: "Bimini",
        iata: "NSB",
        countryIata: "BSNSB",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Paradise Island",
        city: "Nassau",
        iata: "PID",
        countryIata: "BSPID",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Powell Point",
        city: "Powell Point",
        iata: "PPO",
        countryIata: "BSPPO",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Pitts Town",
        city: "Pitts Town",
        iata: "PWN",
        countryIata: "BSPWN",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Rum Cay",
        city: "Rum Cay",
        iata: "RCY",
        countryIata: "BSRCY",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "S Eleuthera",
        city: "Rock Sound",
        iata: "RSD",
        countryIata: "BSRSD",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "San Andros",
        city: "San Andros",
        iata: "SAQ",
        countryIata: "BSSAQ",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Estate Airstrip",
        city: "Stella Maris",
        iata: "SML",
        countryIata: "BSSML",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Spanish Wells",
        city: "Spanish Wells",
        iata: "SWL",
        countryIata: "BSSWL",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "The Bight",
        city: "The Bight",
        iata: "TBI",
        countryIata: "BSTBI",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Treasure Cay",
        city: "Treasure Cay",
        iata: "TCB",
        countryIata: "BSTCB",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Staniel Cay",
        city: "Staniel Cay",
        iata: "TYM",
        countryIata: "BSTYM",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "South Andros",
        city: "South Andros",
        iata: "TZN",
        countryIata: "BSTZN",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Walker's Cay",
        city: "Walker's Cay",
        iata: "WKR",
        countryIata: "BSWKR",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "West End",
        city: "West End",
        iata: "WTD",
        countryIata: "BSWTD",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Seaplane Base",
        city: "Nassau",
        iata: "WZY",
        countryIata: "BSWZY",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "San Salvador",
        city: "San Salvador",
        iata: "ZSA",
        countryIata: "BSZSA",
        country: "Bahamas",
        countryCode: "BS",
    },
    {
        airportName: "Bahrain International",
        city: "Bahrain",
        iata: "BAH",
        countryIata: "BHBAH",
        country: "Bahrain",
        countryCode: "BH",
    },
    {
        airportName: "Ibiza",
        city: "Ibiza",
        iata: "IBZ",
        countryIata: "ESIBZ",
        country: "Balearic Islands, Spain",
        countryCode: "ES",
    },
    {
        airportName: "Menorca",
        city: "Menorca",
        iata: "MAH",
        countryIata: "ESMAH",
        country: "Balearic Islands, Spain",
        countryCode: "ES",
    },
    {
        airportName: "Palma Mallorca",
        city: "Palma Mallorca",
        iata: "PMI",
        countryIata: "ESPMI",
        country: "Balearic Islands, Spain",
        countryCode: "ES",
    },
    {
        airportName: "Barisal",
        city: "Barisal",
        iata: "BZL",
        countryIata: "BDBZL",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Patenga",
        city: "Chittagong",
        iata: "CGP",
        countryIata: "BDCGP",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Comilla",
        city: "Comilla",
        iata: "CLA",
        countryIata: "BDCLA",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Cox's Bazar",
        city: "Cox's Bazar",
        iata: "CXB",
        countryIata: "BDCXB",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Shahjalal International Airport",
        city: "Dhaka",
        iata: "DAC",
        countryIata: "BDDAC",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Ishurdi",
        city: "Ishurdi",
        iata: "IRD",
        countryIata: "BDIRD",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Jessore Airport",
        city: "Jessore",
        iata: "JSR",
        countryIata: "BDJSR",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Khulna",
        city: "Khulna",
        iata: "KHL",
        countryIata: "BDKHL",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Rangpur",
        city: "Rangpur",
        iata: "RAU",
        countryIata: "BDRAU",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Rajshahi",
        city: "Rajshahi",
        iata: "RJH",
        countryIata: "BDRJH",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Sirajganj Airport (closed)",
        city: "Sirajganj",
        iata: "SAJ",
        countryIata: "BDSAJ",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Sandwip",
        city: "Sandwip",
        iata: "SDW",
        countryIata: "BDSDW",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Saidpur",
        city: "Saidpur",
        iata: "SPD",
        countryIata: "BDSPD",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Thakurgaon",
        city: "Thakurgaon",
        iata: "TKR",
        countryIata: "BDTKR",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Shamshernagar",
        city: "Shamshernagar",
        iata: "ZHM",
        countryIata: "BDZHM",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Osmany International Airport",
        city: "Sylhet",
        iata: "ZYL",
        countryIata: "BDZYL",
        country: "Bangladesh",
        countryCode: "BD",
    },
    {
        airportName: "Grantley Adams International",
        city: "Bridgetown",
        iata: "BGI",
        countryIata: "BBBGI",
        country: "Barbados",
        countryCode: "BB",
    },
    {
        airportName: "Nuremberg Airport",
        city: "Nürnberg",
        iata: "NUE",
        countryIata: "DENUE",
        country: "Bavaria, Germany",
        countryCode: "DE",
    },
    {
        airportName: "Brest",
        city: "Brest",
        iata: "BQT",
        countryIata: "BYBQT",
        country: "Belarus",
        countryCode: "BY",
    },
    {
        airportName: "Gomel",
        city: "Gomel",
        iata: "GME",
        countryIata: "BYGME",
        country: "Belarus",
        countryCode: "BY",
    },
    {
        airportName: "Grodna",
        city: "Grodna",
        iata: "GNA",
        countryIata: "BYGNA",
        country: "Belarus",
        countryCode: "BY",
    },
    {
        airportName: "Minsk International 1",
        city: "Minsk",
        iata: "MHP",
        countryIata: "BYMHP",
        country: "Belarus",
        countryCode: "BY",
    },
    {
        airportName: "Minsk International 2",
        city: "Minsk",
        iata: "MSQ",
        countryIata: "BYMSQ",
        country: "Belarus",
        countryCode: "BY",
    },
    {
        airportName: "Mogilev",
        city: "Mogilev",
        iata: "MVQ",
        countryIata: "BYMVQ",
        country: "Belarus",
        countryCode: "BY",
    },
    {
        airportName: "Vitebsk",
        city: "Vitebsk",
        iata: "VTB",
        countryIata: "BYVTB",
        country: "Belarus",
        countryCode: "BY",
    },
    {
        airportName: "Antwerpen International Airport (Deurne)",
        city: "Antwerp",
        iata: "ANR",
        countryIata: "BEANR",
        country: "Belgium",
        countryCode: "BE",
    },
    {
        airportName: "National",
        city: "Brussels",
        iata: "BRU",
        countryIata: "BEBRU",
        country: "Belgium",
        countryCode: "BE",
    },
    {
        airportName: "Charleroi",
        city: "Brussels",
        iata: "CRL",
        countryIata: "BECRL",
        country: "Belgium",
        countryCode: "BE",
    },
    {
        airportName: "Ghent",
        city: "Ghent",
        iata: "GNE",
        countryIata: "BEGNE",
        country: "Belgium",
        countryCode: "BE",
    },
    {
        airportName: "Kortrijk",
        city: "Kortrijk",
        iata: "KJK",
        countryIata: "BEKJK",
        country: "Belgium",
        countryCode: "BE",
    },
    {
        airportName: "Bierset",
        city: "Liege",
        iata: "LGG",
        countryIata: "BELGG",
        country: "Belgium",
        countryCode: "BE",
    },
    {
        airportName: "Zoersel",
        city: "Zoersel",
        iata: "OBL",
        countryIata: "BEOBL",
        country: "Belgium",
        countryCode: "BE",
    },
    {
        airportName: "Ostend",
        city: "Ostend",
        iata: "OST",
        countryIata: "BEOST",
        country: "Belgium",
        countryCode: "BE",
    },
    {
        airportName: "Belmopan",
        city: "Belmopan",
        iata: "BCV",
        countryIata: "BZBCV",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Big Creek",
        city: "Big Creek",
        iata: "BGK",
        countryIata: "BZBGK",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Philip S.W.Goldson International",
        city: "Belize City",
        iata: "BZE",
        countryIata: "BZBZE",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Caye Caulker",
        city: "Caye Caulker",
        iata: "CUK",
        countryIata: "BZCUK",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Caye Chapel",
        city: "Caye Chapel",
        iata: "CYC",
        countryIata: "BZCYC",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Corozal",
        city: "Corozal",
        iata: "CZH",
        countryIata: "BZCZH",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Dangriga",
        city: "Dangriga",
        iata: "DGA",
        countryIata: "BZDGA",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Independence",
        city: "Independence",
        iata: "INB",
        countryIata: "BZINB",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Melinda",
        city: "Melinda",
        iata: "MDB",
        countryIata: "BZMDB",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Manatee",
        city: "Manatee",
        iata: "MZE",
        countryIata: "BZMZE",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Orange Walk",
        city: "Orange Walk",
        iata: "ORZ",
        countryIata: "BZORZ",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Placencia",
        city: "Placencia",
        iata: "PLJ",
        countryIata: "BZPLJ",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Punta Gorda",
        city: "Punta Gorda",
        iata: "PND",
        countryIata: "BZPND",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Sartaneja",
        city: "Sartaneja",
        iata: "SJX",
        countryIata: "BZSJX",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "San Pedro",
        city: "San Pedro",
        iata: "SPR",
        countryIata: "BZSPR",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Matthew Spain",
        city: "San Ignacio",
        iata: "SQS",
        countryIata: "BZSQS",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Santa Cruz",
        city: "Santa Cruz",
        iata: "STU",
        countryIata: "BZSTU",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Silver Creek",
        city: "Silver Creek",
        iata: "SVK",
        countryIata: "BZSVK",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Municipal",
        city: "Belize City",
        iata: "TZA",
        countryIata: "BZTZA",
        country: "Belize",
        countryCode: "BZ",
    },
    {
        airportName: "Cotonou",
        city: "Cotonou",
        iata: "COO",
        countryIata: "BJCOO",
        country: "Benin",
        countryCode: "BJ",
    },
    {
        airportName: "Djougou",
        city: "Djougou",
        iata: "DJA",
        countryIata: "BJDJA",
        country: "Benin",
        countryCode: "BJ",
    },
    {
        airportName: "Kandi",
        city: "Kandi",
        iata: "KDC",
        countryIata: "BJKDC",
        country: "Benin",
        countryCode: "BJ",
    },
    {
        airportName: "Natitingou",
        city: "Natitingou",
        iata: "NAE",
        countryIata: "BJNAE",
        country: "Benin",
        countryCode: "BJ",
    },
    {
        airportName: "Parakou",
        city: "Parakou",
        iata: "PKO",
        countryIata: "BJPKO",
        country: "Benin",
        countryCode: "BJ",
    },
    {
        airportName: "Save",
        city: "Save",
        iata: "SVF",
        countryIata: "BJSVF",
        country: "Benin",
        countryCode: "BJ",
    },
    {
        airportName: "Bermuda International",
        city: "Bermuda",
        iata: "BDA",
        countryIata: "BMBDA",
        country: "Bermuda",
        countryCode: "BM",
    },
    {
        airportName: "Bermuda NAS",
        city: "Hamilton",
        iata: "NWU",
        countryIata: "BMNWU",
        country: "Bermuda",
        countryCode: "BM",
    },
    {
        airportName: "Paro",
        city: "Paro",
        iata: "PBH",
        countryIata: "BTPBH",
        country: "Bhutan",
        countryCode: "BT",
    },
    {
        airportName: "Apolo",
        city: "Apolo",
        iata: "APB",
        countryIata: "BOAPB",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Ascension",
        city: "Ascension",
        iata: "ASC",
        countryIata: "BOASC",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Bermejo",
        city: "Bermejo",
        iata: "BJO",
        countryIata: "BOBJO",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Huacaraje",
        city: "Huacaraje",
        iata: "BVK",
        countryIata: "BOBVK",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Baures",
        city: "Baures",
        iata: "BVL",
        countryIata: "BOBVL",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Yacuiba",
        city: "Yacuiba",
        iata: "BYC",
        countryIata: "BOBYC",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Camiri",
        city: "Camiri",
        iata: "CAM",
        countryIata: "BOCAM",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "J Wilsterman",
        city: "Cochabamba",
        iata: "CBB",
        countryIata: "BOCBB",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Concepcion",
        city: "Concepción",
        iata: "CEP",
        countryIata: "BOCEP",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "E. Beltram",
        city: "Cobija",
        iata: "CIJ",
        countryIata: "BOCIJ",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Guayaramerin",
        city: "Guayaramerin",
        iata: "GYA",
        countryIata: "BOGYA",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "El Alto",
        city: "La Paz",
        iata: "LPB",
        countryIata: "BOLPB",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Magdalena",
        city: "Magdalena",
        iata: "MGD",
        countryIata: "BOMGD",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Monteagudo",
        city: "Monteagudo",
        iata: "MHW",
        countryIata: "BOMHW",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "San Matias",
        city: "San Matias",
        iata: "MQK",
        countryIata: "BOMQK",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Oruro",
        city: "Oruro",
        iata: "ORU",
        countryIata: "BOORU",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Potosi",
        city: "Potosi",
        iata: "POI",
        countryIata: "BOPOI",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Puerto Suarez",
        city: "Puerto Suarez",
        iata: "PSZ",
        countryIata: "BOPSZ",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Puerto Rico",
        city: "Puerto Rico",
        iata: "PUR",
        countryIata: "BOPUR",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Robore",
        city: "Robore",
        iata: "RBO",
        countryIata: "BORBO",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Rurrenabaque",
        city: "Rurrenabaque",
        iata: "RBQ",
        countryIata: "BORBQ",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Reyes",
        city: "Reyes",
        iata: "REY",
        countryIata: "BOREY",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Gen Buech",
        city: "Riberalta",
        iata: "RIB",
        countryIata: "BORIB",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Yacuma",
        city: "Santa Ana",
        iata: "SBL",
        countryIata: "BOSBL",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "San Joaquin",
        city: "San Joaquin",
        iata: "SJB",
        countryIata: "BOSJB",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "San Jose",
        city: "San Jose",
        iata: "SJS",
        countryIata: "BOSJS",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "San Javier",
        city: "San Javier",
        iata: "SJV",
        countryIata: "BOSJV",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "San Ignacio De Velasco",
        city: "San Ignacio De Velasco",
        iata: "SNG",
        countryIata: "BOSNG",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "San Ignacio De M",
        city: "San Ignacio De M",
        iata: "SNM",
        countryIata: "BOSNM",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Santa Rosa",
        city: "Santa Rosa",
        iata: "SRB",
        countryIata: "BOSRB",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "San Ramon",
        city: "San Ramon",
        iata: "SRD",
        countryIata: "BOSRD",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Sucre",
        city: "Sucre",
        iata: "SRE",
        countryIata: "BOSRE",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Capitan G Q Guardia",
        city: "San Borja",
        iata: "SRJ",
        countryIata: "BOSRJ",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "El Trompillo",
        city: "Santa Cruz",
        iata: "SRZ",
        countryIata: "BOSRZ",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Trinidad",
        city: "Trinidad",
        iata: "TDD",
        countryIata: "BOTDD",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Tarija",
        city: "Tarija",
        iata: "TJA",
        countryIata: "BOTJA",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Vallegrande",
        city: "Vallegrande",
        iata: "VAH",
        countryIata: "BOVAH",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Villamontes",
        city: "Villamontes",
        iata: "VLM",
        countryIata: "BOVLM",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Viru Viru International",
        city: "Santa Cruz",
        iata: "VVI",
        countryIata: "BOVVI",
        country: "Bolivia",
        countryCode: "BO",
    },
    {
        airportName: "Banja Luka",
        city: "Banja Luka",
        iata: "BNX",
        countryIata: "BABNX",
        country: "Bosnia and Herzegovina",
        countryCode: "BA",
    },
    {
        airportName: "Mostar",
        city: "Mostar",
        iata: "OMO",
        countryIata: "BAOMO",
        country: "Bosnia and Herzegovina",
        countryCode: "BA",
    },
    {
        airportName: "Butmir",
        city: "Sarajevo",
        iata: "SJJ",
        countryIata: "BASJJ",
        country: "Bosnia and Herzegovina",
        countryCode: "BA",
    },
    {
        airportName: "Tuzla International",
        city: "Tuzla",
        iata: "TZL",
        countryIata: "BATZL",
        country: "Bosnia and Herzegovina",
        countryCode: "BA",
    },
    {
        airportName: "Kasane",
        city: "Kasane",
        iata: "BBK",
        countryIata: "BWBBK",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Francistown",
        city: "Francistown",
        iata: "FRW",
        countryIata: "BWFRW",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Sir Seretse Khama Int",
        city: "Gaborone",
        iata: "GBE",
        countryIata: "BWGBE",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Ghanzi",
        city: "Ghanzi",
        iata: "GNZ",
        countryIata: "BWGNZ",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Hukuntsi",
        city: "Hukuntsi",
        iata: "HUK",
        countryIata: "BWHUK",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Jwaneng Airport",
        city: "Jwaneng",
        iata: "JWA",
        countryIata: "BWJWA",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Khwai River Lodge",
        city: "Khwai River Lodge",
        iata: "KHW",
        countryIata: "BWKHW",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Lobatse",
        city: "Lobatse",
        iata: "LOQ",
        countryIata: "BWLOQ",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Maun",
        city: "Maun",
        iata: "MUB",
        countryIata: "BWMUB",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Orapa",
        city: "Orapa",
        iata: "ORP",
        countryIata: "BWORP",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Selebi-Phikwe",
        city: "Selebi-Phikwe",
        iata: "PKW",
        countryIata: "BWPKW",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Savuti",
        city: "Savuti",
        iata: "SVT",
        countryIata: "BWSVT",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Shakawe",
        city: "Shakawe",
        iata: "SWX",
        countryIata: "BWSWX",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Suapan",
        city: "Suapan",
        iata: "SXN",
        countryIata: "BWSXN",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Tsabong",
        city: "Tsabong",
        iata: "TBY",
        countryIata: "BWTBY",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Tuli Lodge",
        city: "Tuli Lodge",
        iata: "TLD",
        countryIata: "BWTLD",
        country: "Botswana",
        countryCode: "BW",
    },
    {
        airportName: "Arapoti",
        city: "Arapoti",
        iata: "AAG",
        countryIata: "BRAAG",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Arraias",
        city: "Arraias",
        iata: "AAI",
        countryIata: "BRAAI",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Araxa",
        city: "Araxa",
        iata: "AAX",
        countryIata: "BRAAX",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Alta Floresta",
        city: "Alta Floresta",
        iata: "AFL",
        countryIata: "BRAFL",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Assis",
        city: "Assis",
        iata: "AIF",
        countryIata: "BRAIF",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Aripuana",
        city: "Aripuana",
        iata: "AIR",
        countryIata: "BRAIR",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Aracaju - Santa Maria Airport",
        city: "Aracaju",
        iata: "AJU",
        countryIata: "BRAJU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Federal",
        city: "Alegrete",
        iata: "ALQ",
        countryIata: "BRALQ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Alenquer",
        city: "Alenquer",
        iata: "ALT",
        countryIata: "BRALT",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Almenara",
        city: "Almenara",
        iata: "AMJ",
        countryIata: "BRAMJ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Arapiraca",
        city: "Arapiraca",
        iata: "APQ",
        countryIata: "BRAPQ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Anapolis",
        city: "Anapolis",
        iata: "APS",
        countryIata: "BRAPS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Apucarana",
        city: "Apucarana",
        iata: "APU",
        countryIata: "BRAPU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Arapongas",
        city: "Arapongas",
        iata: "APX",
        countryIata: "BRAPX",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Alto Parnaiba",
        city: "Alto Parnaiba",
        iata: "APY",
        countryIata: "BRAPY",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Araraquara",
        city: "Araraquara",
        iata: "AQA",
        countryIata: "BRAQA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Ariquemes",
        city: "Ariquemes",
        iata: "AQM",
        countryIata: "BRAQM",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Aragarcas",
        city: "Aragarcas",
        iata: "ARS",
        countryIata: "BRARS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Aracatuba",
        city: "Aracatuba",
        iata: "ARU",
        countryIata: "BRARU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Dragao do Mar",
        city: "Aracati",
        iata: "ARX",
        countryIata: "BRARX",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Altamira",
        city: "Altamira",
        iata: "ATM",
        countryIata: "BRATM",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Araguaina",
        city: "Araguaina",
        iata: "AUX",
        countryIata: "BRAUX",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Xanxere",
        city: "Xanxere",
        iata: "AXE",
        countryIata: "BRAXE",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Barretos",
        city: "Barretos",
        iata: "BAT",
        countryIata: "BRBAT",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Bauru",
        city: "Bauru",
        iata: "BAU",
        countryIata: "BRBAU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Barbelos",
        city: "Barbelos",
        iata: "BAZ",
        countryIata: "BRBAZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Boca Do Acre",
        city: "Boca Do Acre",
        iata: "BCR",
        countryIata: "BRBCR",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Barra Do Corda",
        city: "Barra Do Corda",
        iata: "BDC",
        countryIata: "BRBDC",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Val de Cans International Airport",
        city: "Belem",
        iata: "BEL",
        countryIata: "BRBEL",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Bacacheri",
        city: "Curitiba",
        iata: "BFH",
        countryIata: "BRBFH",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Bento Goncalves",
        city: "Bento Goncalves",
        iata: "BGV",
        countryIata: "BRBGV",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Bage",
        city: "Bage",
        iata: "BGX",
        countryIata: "BRBGX",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Metropolitan Area",
        city: "Belo Horizonte",
        iata: "BHZ",
        countryIata: "BRBHZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Braganca Paulista",
        city: "Braganca Paulista",
        iata: "BJP",
        countryIata: "BRBJP",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Brumado",
        city: "Brumado",
        iata: "BMS",
        countryIata: "BRBMS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Blumenau",
        city: "Blumenau",
        iata: "BNU",
        countryIata: "BRBNU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Barra Do Garcas",
        city: "Barra Do Garcas",
        iata: "BPG",
        countryIata: "BRBPG",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Porto Seguro",
        city: "Porto Seguro",
        iata: "BPS",
        countryIata: "BRBPS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Barra",
        city: "Barra",
        iata: "BQQ",
        countryIata: "BRBQQ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Barreiras",
        city: "Barreiras",
        iata: "BRA",
        countryIata: "BRBRA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Barreirinhas",
        city: "Barreirinhas",
        iata: "BRB",
        countryIata: "BRBRB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "International",
        city: "Brasilia",
        iata: "BSB",
        countryIata: "BRBSB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Balsas",
        city: "Balsas",
        iata: "BSS",
        countryIata: "BRBSS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Boa Vista",
        city: "Boa Vista",
        iata: "BVB",
        countryIata: "BRBVB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Vilhena",
        city: "Vilhena",
        iata: "BVH",
        countryIata: "BRBVH",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Belmonte",
        city: "Belmonte",
        iata: "BVM",
        countryIata: "BRBVM",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Breves",
        city: "Breves",
        iata: "BVS",
        countryIata: "BRBVS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Buzios",
        city: "Buzios",
        iata: "BZC",
        countryIata: "BRBZC",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Cascavel",
        city: "Cascavel",
        iata: "CAC",
        countryIata: "BRCAC",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Carauari",
        city: "Carauari",
        iata: "CAF",
        countryIata: "BRCAF",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Caruaru",
        city: "Caruaru",
        iata: "CAU",
        countryIata: "BRCAU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Bartolomeu Lisandro",
        city: "Campos",
        iata: "CAW",
        countryIata: "BRCAW",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Campo Mourao",
        city: "Campo Mourao",
        iata: "CBW",
        countryIata: "BRCBW",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Concordia",
        city: "Concordia",
        iata: "CCI",
        countryIata: "BRCCI",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Criciuma",
        city: "Criciuma",
        iata: "CCM",
        countryIata: "BRCCM",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Cachoeira",
        city: "Cachoeira",
        iata: "CCQ",
        countryIata: "BRCCQ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Caceres",
        city: "Caceres",
        iata: "CCX",
        countryIata: "BRCCX",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Cachoeiro Itapemirim",
        city: "Cachoeiro Itapemirim",
        iata: "CDI",
        countryIata: "BRCDI",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Conceicao Do Araguaia",
        city: "Conceicao Do Araguaia",
        iata: "CDJ",
        countryIata: "BRCDJ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Cabo Frio",
        city: "Cabo Frio",
        iata: "CFB",
        countryIata: "BRCFB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Cacador",
        city: "Cacador",
        iata: "CFC",
        countryIata: "BRCFC",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Confreza",
        city: "Confreza",
        iata: "CFO",
        countryIata: "BRCFO",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Marechal Rondon International Airport",
        city: "Cuiaba",
        iata: "CGB",
        countryIata: "BRCGB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Congonhas",
        city: "Sao Paulo",
        iata: "CGH",
        countryIata: "BRCGH",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Campo Grande International Airport",
        city: "Campo Grande",
        iata: "CGR",
        countryIata: "BRCGR",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Coari",
        city: "Coari",
        iata: "CIZ",
        countryIata: "BRCIZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Cornelio Procopio",
        city: "Cornelio Procopio",
        iata: "CKO",
        countryIata: "BRCKO",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Carajas",
        city: "Carajas",
        iata: "CKS",
        countryIata: "BRCKS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Carolina",
        city: "Carolina",
        iata: "CLN",
        countryIata: "BRCLN",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Caldas Novas",
        city: "Caldas Novas",
        iata: "CLV",
        countryIata: "BRCLV",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Camocim",
        city: "Camocim",
        iata: "CMC",
        countryIata: "BRCMC",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Internacional",
        city: "Corumba",
        iata: "CMG",
        countryIata: "BRCMG",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Campo Alegre",
        city: "Santana Do Araguaia",
        iata: "CMP",
        countryIata: "BRCMP",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Cameta",
        city: "Cameta",
        iata: "CMT",
        countryIata: "BRCMT",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Tancredo Neves International Airport",
        city: "Belo Horizonte",
        iata: "CNF",
        countryIata: "BRCNF",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Canavieiras",
        city: "Canavieiras",
        iata: "CNV",
        countryIata: "BRCNV",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Campinas International",
        city: "Campinas",
        iata: "CPQ",
        countryIata: "BRCPQ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Cururupu",
        city: "Cururupu",
        iata: "CPU",
        countryIata: "BRCPU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Joao Suassuna",
        city: "Campina Grande",
        iata: "CPV",
        countryIata: "BRCPV",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Canarana Airport",
        city: "Canarana",
        iata: "CQA",
        countryIata: "BRCQA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Costa Marques",
        city: "Costa Marques",
        iata: "CQS",
        countryIata: "BRCQS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Caravelas",
        city: "Caravelas",
        iata: "CRQ",
        countryIata: "BRCRQ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Cassilandia",
        city: "Cassilandia",
        iata: "CSS",
        countryIata: "BRCSS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Santa Cruz Do Sul",
        city: "Santa Cruz Do Sul",
        iata: "CSU",
        countryIata: "BRCSU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Colorado do Oeste",
        city: "Colorado do Oeste",
        iata: "CSW",
        countryIata: "BRCSW",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Carutapera",
        city: "Carutapera",
        iata: "CTP",
        countryIata: "BRCTP",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Do Palmar",
        city: "Santa Vitoria",
        iata: "CTQ",
        countryIata: "BRCTQ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Afonso Pena International Airport",
        city: "Curitiba",
        iata: "CWB",
        countryIata: "BRCWB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Campo Dos Bugres",
        city: "Caxias Do Sul",
        iata: "CXJ",
        countryIata: "BRCXJ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Carlos Ruhl",
        city: "Cruz Alta",
        iata: "CZB",
        countryIata: "BRCZB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Campo Internacional",
        city: "Cruzeiro Do Sul",
        iata: "CZS",
        countryIata: "BRCZS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Divinopolis",
        city: "Divinopolis",
        iata: "DIQ",
        countryIata: "BRDIQ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Diamantino",
        city: "Diamantino",
        iata: "DMT",
        countryIata: "BRDMT",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Dianopolis",
        city: "Dianopolis",
        iata: "DNO",
        countryIata: "BRDNO",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Dourados",
        city: "Dourados",
        iata: "DOU",
        countryIata: "BRDOU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Comandante Kraemer",
        city: "Erechim",
        iata: "ERM",
        countryIata: "BRERM",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Eirunepe",
        city: "Eirunepe",
        iata: "ERN",
        countryIata: "BRERN",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Espinosa",
        city: "Espinosa",
        iata: "ESI",
        countryIata: "BRESI",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Francisco Beltrao",
        city: "Francisco Beltrao",
        iata: "FBE",
        countryIata: "BRFBE",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Feira De Santana",
        city: "Feira De Santana",
        iata: "FEC",
        countryIata: "BRFEC",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Feijo",
        city: "Feijo",
        iata: "FEJ",
        countryIata: "BRFEJ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Fernando De Noronha",
        city: "Fernando De Noronha",
        iata: "FEN",
        countryIata: "BRFEN",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Cangapara",
        city: "Floriano",
        iata: "FLB",
        countryIata: "BRFLB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Hercílio Luz International",
        city: "Florianopolis",
        iata: "FLN",
        countryIata: "BRFLN",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Pinto Martins Airport",
        city: "Fortaleza",
        iata: "FOR",
        countryIata: "BRFOR",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Franca",
        city: "Franca",
        iata: "FRC",
        countryIata: "BRFRC",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Gravatai",
        city: "Gravatai",
        iata: "GCV",
        countryIata: "BRGCV",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Guadalupe",
        city: "Guadalupe",
        iata: "GDP",
        countryIata: "BRGDP",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Sepe Tiaraju",
        city: "Santo Angelo",
        iata: "GEL",
        countryIata: "BRGEL",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Internacional",
        city: "Rio De Janeiro",
        iata: "GIG",
        countryIata: "BRGIG",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Guajara-Mirim",
        city: "Guajara-Mirim",
        iata: "GJM",
        countryIata: "BRGJM",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Guimaraes",
        city: "Guimaraes",
        iata: "GMS",
        countryIata: "BRGMS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Guanambi",
        city: "Guanambi",
        iata: "GNM",
        countryIata: "BRGNM",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Tancredo Thomaz Faria",
        city: "Guarapuava",
        iata: "GPB",
        countryIata: "BRGPB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Gurupi",
        city: "Gurupi",
        iata: "GRP",
        countryIata: "BRGRP",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Guarulhos International",
        city: "Sao Paulo",
        iata: "GRU",
        countryIata: "BRGRU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Guaratingueta",
        city: "Guaratingueta",
        iata: "GUJ",
        countryIata: "BRGUJ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Guarapari",
        city: "Guarapari",
        iata: "GUZ",
        countryIata: "BRGUZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Governador Valadares",
        city: "Governador Valadares",
        iata: "GVR",
        countryIata: "BRGVR",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Santa Genoveva Airport",
        city: "Goiania",
        iata: "GYN",
        countryIata: "BRGYN",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Horizontina",
        city: "Horizontina",
        iata: "HRZ",
        countryIata: "BRHRZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Santa Isabel Do Morro",
        city: "Santa Isabel do Morro",
        iata: "IDO",
        countryIata: "BRIDO",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Cataratas",
        city: "Iguassu Falls",
        iata: "IGU",
        countryIata: "BRIGU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "J.Batista Bos Filho",
        city: "Ijui",
        iata: "IJU",
        countryIata: "BRIJU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Ilha Solteira",
        city: "Ilha Solteira",
        iata: "ILB",
        countryIata: "BRILB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Imperatriz",
        city: "Imperatriz",
        iata: "IMP",
        countryIata: "BRIMP",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Eduardo Gomes",
        city: "Ilheus",
        iata: "IOS",
        countryIata: "BRIOS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Ipiranga",
        city: "Ipiranga",
        iata: "IPG",
        countryIata: "BRIPG",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Usiminas",
        city: "Ipatinga",
        iata: "IPN",
        countryIata: "BRIPN",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Ipiau",
        city: "Ipiau",
        iata: "IPU",
        countryIata: "BRIPU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Irece",
        city: "Irece",
        iata: "IRE",
        countryIata: "BRIRE",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Itacoatiara",
        city: "Itacoatiara",
        iata: "ITA",
        countryIata: "BRITA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Itaituba",
        city: "Itaituba",
        iata: "ITB",
        countryIata: "BRITB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Itubera",
        city: "Itubera",
        iata: "ITE",
        countryIata: "BRITE",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Itambacuri",
        city: "Itambacuri",
        iata: "ITI",
        countryIata: "BRITI",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Itabuna",
        city: "Itabuna",
        iata: "ITN",
        countryIata: "BRITN",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Itaperuna",
        city: "Itaperuna",
        iata: "ITP",
        countryIata: "BRITP",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Itaqui",
        city: "Itaqui",
        iata: "ITQ",
        countryIata: "BRITQ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Itumbiara",
        city: "Itumbiara",
        iata: "ITR",
        countryIata: "BRITR",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Joacaba",
        city: "Joacaba",
        iata: "JCB",
        countryIata: "BRJCB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Jacobina",
        city: "Jacobina",
        iata: "JCM",
        countryIata: "BRJCM",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Jacareacanga",
        city: "Jacareacanga",
        iata: "JCR",
        countryIata: "BRJCR",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Francisco De Assis",
        city: "Juiz De Fora",
        iata: "JDF",
        countryIata: "BRJDF",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Regional Do Cariri",
        city: "Juazeiro Do Norte",
        iata: "JDO",
        countryIata: "BRJDO",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Jequie",
        city: "Jequie",
        iata: "JEQ",
        countryIata: "BRJEQ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Juina",
        city: "Juina",
        iata: "JIA",
        countryIata: "BRJIA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Jales",
        city: "Jales",
        iata: "JLS",
        countryIata: "BRJLS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Januaria",
        city: "Januaria",
        iata: "JNA",
        countryIata: "BRJNA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Cubatao",
        city: "Joinville",
        iata: "JOI",
        countryIata: "BRJOI",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Castro Pinto Intl.",
        city: "Joao Pessoa",
        iata: "JPA",
        countryIata: "BRJPA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Ji-Parana",
        city: "Ji-Parana",
        iata: "JPR",
        countryIata: "BRJPR",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Juruena",
        city: "Juruena",
        iata: "JRN",
        countryIata: "BRJRN",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Bauru-Arealva Airport",
        city: "Bauru",
        iata: "JTC",
        countryIata: "BRJTC",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Jatai",
        city: "Jatai",
        iata: "JTI",
        countryIata: "BRJTI",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Juara",
        city: "Juara",
        iata: "JUA",
        countryIata: "BRJUA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Lages",
        city: "Lages",
        iata: "LAJ",
        countryIata: "BRLAJ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Bom Jesus Da Lapa",
        city: "Bom Jesus Da Lapa",
        iata: "LAZ",
        countryIata: "BRLAZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Labrea",
        city: "Labrea",
        iata: "LBR",
        countryIata: "BRLBR",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Pontes e Lacerda",
        city: "Pontes e Lacerda",
        iata: "LCB",
        countryIata: "BRLCB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Londrina",
        city: "Londrina",
        iata: "LDB",
        countryIata: "BRLDB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Chapada Diamantina",
        city: "Lencois",
        iata: "LEC",
        countryIata: "BRLEC",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Leopoldina",
        city: "Leopoldina",
        iata: "LEP",
        countryIata: "BRLEP",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Lins",
        city: "Lins",
        iata: "LIP",
        countryIata: "BRLIP",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Helmuth Baungartem",
        city: "Lontras",
        iata: "LOI",
        countryIata: "BRLOI",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Dos Galpoes",
        city: "Livramento",
        iata: "LVB",
        countryIata: "BRLVB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Marabá Airport",
        city: "Maraba",
        iata: "MAB",
        countryIata: "BRMAB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Eduardo Gomes International Airport",
        city: "Manaus",
        iata: "MAO",
        countryIata: "BRMAO",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Matupa",
        city: "Matupa",
        iata: "MBK",
        countryIata: "BRMBK",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Maues",
        city: "Maues",
        iata: "MBZ",
        countryIata: "BRMBZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Alberto Alcolumbre International Airport",
        city: "Macapa",
        iata: "MCP",
        countryIata: "BRMCP",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Zumbi dos Palmares Intl Airport",
        city: "Maceio (Maceió)",
        iata: "MCZ",
        countryIata: "BRMCZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Macae",
        city: "Macae",
        iata: "MEA",
        countryIata: "BRMEA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Monte Dourado",
        city: "Monte Dourado",
        iata: "MEU",
        countryIata: "BRMEU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Maringa",
        city: "Maringa",
        iata: "MGF",
        countryIata: "BRMGF",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Dr Gastao Vidigal",
        city: "Marilia",
        iata: "MII",
        countryIata: "BRMII",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Manicore",
        city: "Manicore",
        iata: "MNX",
        countryIata: "BRMNX",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Montes Claros",
        city: "Montes Claros",
        iata: "MOC",
        countryIata: "BRMOC",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Municipal",
        city: "Minacu",
        iata: "MQH",
        countryIata: "BRMQH",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Monte Alegre",
        city: "Monte Alegre",
        iata: "MTE",
        countryIata: "BRMTE",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Mato Grosso",
        city: "Mato Grosso",
        iata: "MTG",
        countryIata: "BRMTG",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Dixsept Rosado",
        city: "Mossoro",
        iata: "MVF",
        countryIata: "BRMVF",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Mucuri",
        city: "Mucuri",
        iata: "MVS",
        countryIata: "BRMVS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Augusto Severo International Airport",
        city: "Natal",
        iata: "NAT",
        countryIata: "BRNAT",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Cana Brava",
        city: "Cana Brava",
        iata: "NBV",
        countryIata: "BRNBV",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Nanuque",
        city: "Nanuque",
        iata: "NNU",
        countryIata: "BRNNU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Nova Xavantina",
        city: "Nova Xavantina",
        iata: "NOK",
        countryIata: "BRNOK",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Niquelandia",
        city: "Niquelandia",
        iata: "NQL",
        countryIata: "BRNQL",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Miracema Do Norte",
        city: "Miracema Do Norte",
        iata: "NTM",
        countryIata: "BRNTM",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Novo Aripuana",
        city: "Novo Aripuana",
        iata: "NVP",
        countryIata: "BRNVP",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Navegantes",
        city: "Navegantes",
        iata: "NVT",
        countryIata: "BRNVT",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Cacoal",
        city: "Cacoal",
        iata: "OAL",
        countryIata: "BROAL",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Obidos",
        city: "Obidos",
        iata: "OBI",
        countryIata: "BROBI",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Ourilandia",
        city: "Ourilandia",
        iata: "OIA",
        countryIata: "BROIA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Sinop",
        city: "Sinop",
        iata: "OPS",
        countryIata: "BROPS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Oriximina",
        city: "Oriximina",
        iata: "ORX",
        countryIata: "BRORX",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Ourinhos",
        city: "Ourinhos",
        iata: "OUS",
        countryIata: "BROUS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Oiapoque",
        city: "Oiapoque",
        iata: "OYK",
        countryIata: "BROYK",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Paulo Afonso",
        city: "Paulo Afonso",
        iata: "PAV",
        countryIata: "BRPAV",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Paranaiba",
        city: "Paranaiba",
        iata: "PBB",
        countryIata: "BRPBB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Pimenta Bueno",
        city: "Pimenta Bueno",
        iata: "PBQ",
        countryIata: "BRPBQ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Porto Dos Gauchos",
        city: "Porto Dos Gauchos",
        iata: "PBV",
        countryIata: "BRPBV",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Porto Alegre Do Norte",
        city: "Porto Alegre Do Norte",
        iata: "PBX",
        countryIata: "BRPBX",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Picos",
        city: "Picos",
        iata: "PCS",
        countryIata: "BRPCS",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Prado",
        city: "Prado",
        iata: "PDF",
        countryIata: "BRPDF",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Municipal",
        city: "Presidente Dutra",
        iata: "PDR",
        countryIata: "BRPDR",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Federal",
        city: "Pelotas",
        iata: "PET",
        countryIata: "BRPET",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Passo Fundo",
        city: "Passo Fundo",
        iata: "PFB",
        countryIata: "BRPFB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Progresso",
        city: "Progresso",
        iata: "PGG",
        countryIata: "BRPGG",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Sant'Ana",
        city: "Ponta Grossa",
        iata: "PGZ",
        countryIata: "BRPGZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Pinheiro",
        city: "Pinheiro",
        iata: "PHI",
        countryIata: "BRPHI",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Pitinga",
        city: "Pitinga",
        iata: "PIG",
        countryIata: "BRPIG",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Parintins",
        city: "Parintins",
        iata: "PIN",
        countryIata: "BRPIN",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Pirapora",
        city: "Pirapora",
        iata: "PIV",
        countryIata: "BRPIV",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Ponta Pelada",
        city: "Ponta Pelada",
        iata: "PLL",
        countryIata: "BRPLL",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Pampulha Airport",
        city: "Belo Horizonte",
        iata: "PLU",
        countryIata: "BRPLU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "International",
        city: "Ponta Pora",
        iata: "PMG",
        countryIata: "BRPMG",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Palmas",
        city: "Palmas",
        iata: "PMW",
        countryIata: "BRPMW",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Porto Nacional",
        city: "Porto Nacional",
        iata: "PNB",
        countryIata: "BRPNB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Municipal",
        city: "Paranagua",
        iata: "PNG",
        countryIata: "BRPNG",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Internacional",
        city: "Petrolina",
        iata: "PNZ",
        countryIata: "BRPNZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Salgado Filho International Airport",
        city: "Porto Alegre",
        iata: "POA",
        countryIata: "BRPOA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Patos De Minas",
        city: "Patos De Minas",
        iata: "POJ",
        countryIata: "BRPOJ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Pocos De Caldas",
        city: "Pocos De Caldas",
        iata: "POO",
        countryIata: "BRPOO",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "A. De Barros",
        city: "Presidente Prudente",
        iata: "PPB",
        countryIata: "BRPPB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Pouso Alegre",
        city: "Pouso Alegre",
        iata: "PPY",
        countryIata: "BRPPY",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Passos",
        city: "Passos",
        iata: "PSW",
        countryIata: "BRPSW",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Municipal",
        city: "Pato Branco",
        iata: "PTO",
        countryIata: "BRPTO",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Porto de Moz",
        city: "Porto de Moz",
        iata: "PTQ",
        countryIata: "BRPTQ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Porto Velho International",
        city: "Porto Velho",
        iata: "PVH",
        countryIata: "BRPVH",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Paranavai",
        city: "Paranavai",
        iata: "PVI",
        countryIata: "BRPVI",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "São Carlos Airport",
        city: "São Carlos",
        iata: "QSC",
        countryIata: "BRQSC",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Leite Lopes",
        city: "Ribeirao Preto",
        iata: "RAO",
        countryIata: "BRRAO",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Borba",
        city: "Borba",
        iata: "RBB",
        countryIata: "BRRBB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Plácido de Castro International Airport",
        city: "Rio Branco",
        iata: "RBR",
        countryIata: "BRRBR",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Redencao",
        city: "Redencao",
        iata: "RDC",
        countryIata: "BRRDC",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Guararapes-Gilberto Freyre International Airport",
        city: "Recife",
        iata: "REC",
        countryIata: "BRREC",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Resende",
        city: "Resende",
        iata: "REZ",
        countryIata: "BRREZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Base Aerea",
        city: "Santa Maria",
        iata: "RIA",
        countryIata: "BRRIA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Rio Grande",
        city: "Rio Grande",
        iata: "RIG",
        countryIata: "BRRIG",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Metropolitan Area",
        city: "Rio De Janeiro",
        iata: "RIO",
        countryIata: "BRRIO",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Rondonopolis",
        city: "Rondonopolis",
        iata: "ROO",
        countryIata: "BRROO",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Serra Norte",
        city: "Serra Norte",
        iata: "RRN",
        countryIata: "BRRRN",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Serra Pelada",
        city: "Serra Pelada",
        iata: "RSG",
        countryIata: "BRRSG",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Rio Verde",
        city: "Rio Verde",
        iata: "RVD",
        countryIata: "BRRVD",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Metropolitan Area",
        city: "Sao Paulo",
        iata: "SAO",
        countryIata: "BRSAO",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Sao Mateus",
        city: "Sao Mateus",
        iata: "SBJ",
        countryIata: "BRSBJ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Santos Dumont",
        city: "Rio De Janeiro",
        iata: "SDU",
        countryIata: "BRSDU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Senhor Do Bonfim",
        city: "Senhor Do Bonfim",
        iata: "SEI",
        countryIata: "BRSEI",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Soure",
        city: "Soure",
        iata: "SFK",
        countryIata: "BRSFK",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Santa Fe Do Sul",
        city: "Santa Fe Do Sul",
        iata: "SFV",
        countryIata: "BRSFV",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Sao Jose Dos Campos",
        city: "Sao Jose Dos Campos",
        iata: "SJK",
        countryIata: "BRSJK",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Da Cachoeira",
        city: "Sao Gabriel",
        iata: "SJL",
        countryIata: "BRSJL",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Sao Jose Do Rio Preto",
        city: "Sao Jose Do Rio Preto",
        iata: "SJP",
        countryIata: "BRSJP",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Mal. Cunha Machado Intl Airport",
        city: "Sao Luis",
        iata: "SLZ",
        countryIata: "BRSLZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Santa Cruz",
        city: "Santa Cruz",
        iata: "SNZ",
        countryIata: "BRSNZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Sorocaba",
        city: "Sorocaba",
        iata: "SOD",
        countryIata: "BRSOD",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Sao Miguel Araguaia",
        city: "Sao Miguel Araguaia",
        iata: "SQM",
        countryIata: "BRSQM",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Sao Miguel Do Oeste",
        city: "Sao Miguel Do Oeste",
        iata: "SQX",
        countryIata: "BRSQX",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Sao Lourenco Do Sul",
        city: "Sao Lourenco Do Sul",
        iata: "SQY",
        countryIata: "BRSQY",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Santa Rosa",
        city: "Santa Rosa",
        iata: "SRA",
        countryIata: "BRSRA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Luis Eduardo Magalhaes International",
        city: "Salvador",
        iata: "SSA",
        countryIata: "BRSSA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Sao Lourenco",
        city: "Sao Lourenco",
        iata: "SSO",
        countryIata: "BRSSO",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Santos",
        city: "Santos",
        iata: "SSZ",
        countryIata: "BRSSZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Maestro Wilson Fonseca Airport",
        city: "Santarem (Santarém)",
        iata: "STM",
        countryIata: "BRSTM",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Confresa",
        city: "Santa Terezinha",
        iata: "STZ",
        countryIata: "BRSTZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Suia-Missu",
        city: "Suia-Missu",
        iata: "SWM",
        countryIata: "BRSWM",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Sao Felix Do Araguaia",
        city: "Sao Felix Do Araguaia",
        iata: "SXO",
        countryIata: "BRSXO",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Sao Felix Do Xingu",
        city: "Sao Felix Do Xingu",
        iata: "SXX",
        countryIata: "BRSXX",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Internacional",
        city: "Tabatinga",
        iata: "TBT",
        countryIata: "BRTBT",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Telemaco Borba",
        city: "Telemaco Borba",
        iata: "TEC",
        countryIata: "BRTEC",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Tefe",
        city: "Tefe",
        iata: "TFF",
        countryIata: "BRTFF",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Teofilo Otoni",
        city: "Teofilo Otoni",
        iata: "TFL",
        countryIata: "BRTFL",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Tangara da Serra",
        city: "Tangara da Serra",
        iata: "TGQ",
        countryIata: "BRTGQ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Senador Petronio Portella Airport",
        city: "Teresina",
        iata: "THE",
        countryIata: "BRTHE",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Catalao",
        city: "Catalao",
        iata: "TLZ",
        countryIata: "BRTLZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Trombetas",
        city: "Trombetas",
        iata: "TMT",
        countryIata: "BRTMT",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Toledo",
        city: "Toledo",
        iata: "TOW",
        countryIata: "BRTOW",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Tarauaca",
        city: "Tarauaca",
        iata: "TRQ",
        countryIata: "BRTRQ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Torres",
        city: "Torres",
        iata: "TSQ",
        countryIata: "BRTSQ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Tucurui",
        city: "Tucurui",
        iata: "TUR",
        countryIata: "BRTUR",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Tucuma",
        city: "Tucuma",
        iata: "TUZ",
        countryIata: "BRTUZ",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Teixeira de Freitas",
        city: "Teixeira de Freitas",
        iata: "TXF",
        countryIata: "BRTXF",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Uberaba",
        city: "Uberaba",
        iata: "UBA",
        countryIata: "BRUBA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Ubatuba",
        city: "Ubatuba",
        iata: "UBT",
        countryIata: "BRUBT",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Uberlandia Bombonato Airport",
        city: "Uberlandia",
        iata: "UDI",
        countryIata: "BRUDI",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Ernesto Geisel",
        city: "Umuarama",
        iata: "UMU",
        countryIata: "BRUMU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Una",
        city: "Una",
        iata: "UNA",
        countryIata: "BRUNA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Ernesto Pochler",
        city: "Urubupunga",
        iata: "URB",
        countryIata: "BRURB",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Ruben Berta",
        city: "Uruguaiana",
        iata: "URG",
        countryIata: "BRURG",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Maj. Brig. Trompowsky",
        city: "Varginha",
        iata: "VAG",
        countryIata: "BRVAG",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Valenca",
        city: "Valenca",
        iata: "VAL",
        countryIata: "BRVAL",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Viracopos",
        city: "Sao Paulo",
        iata: "VCP",
        countryIata: "BRVCP",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Vitoria Da Conquista",
        city: "Vitoria Da Conquista",
        iata: "VDC",
        countryIata: "BRVDC",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Videira",
        city: "Videira",
        iata: "VIA",
        countryIata: "BRVIA",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Eurico de Aguiar Salles Airport",
        city: "Vitoria",
        iata: "VIX",
        countryIata: "BRVIX",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Municipal",
        city: "Vila Rica",
        iata: "VLP",
        countryIata: "BRVLP",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Votuporanga",
        city: "Votuporanga",
        iata: "VOT",
        countryIata: "BRVOT",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Serafin Enoss Bertaso Airport",
        city: "Chapeco",
        iata: "XAP",
        countryIata: "BRXAP",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Municipal",
        city: "Xinguara",
        iata: "XIG",
        countryIata: "BRXIG",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Aruja",
        city: "Aruja",
        iata: "ZFU",
        countryIata: "BRZFU",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Sena Madureira",
        city: "Sena Madureira",
        iata: "ZMD",
        countryIata: "BRZMD",
        country: "Brazil",
        countryCode: "BR",
    },
    {
        airportName: "Beef Island",
        city: "Beef Island",
        iata: "EIS",
        countryIata: "VGEIS",
        country: "British Virgin Islands",
        countryCode: "VG",
    },
    {
        airportName: "Anegada",
        city: "Anegada",
        iata: "NGD",
        countryIata: "VGNGD",
        country: "British Virgin Islands",
        countryCode: "VG",
    },
    {
        airportName: "North Sound Water Aerodrome",
        city: "Spanishtown, Virgin Gorda",
        iata: "NSX",
        countryIata: "VGNSX",
        country: "British Virgin Islands",
        countryCode: "VG",
    },
    {
        airportName: "Road Town",
        city: "Tortola",
        iata: "RAD",
        countryIata: "VGRAD",
        country: "British Virgin Islands",
        countryCode: "VG",
    },
    {
        airportName: "West End SPB",
        city: "Tortola",
        iata: "TOV",
        countryIata: "VGTOV",
        country: "British Virgin Islands",
        countryCode: "VG",
    },
    {
        airportName: "Virgin Gorda",
        city: "Virgin Gorda",
        iata: "VIJ",
        countryIata: "VGVIJ",
        country: "British Virgin Islands",
        countryCode: "VG",
    },
    {
        airportName: "Brunei International",
        city: "Bandar Seri Begawan",
        iata: "BWN",
        countryIata: "BNBWN",
        country: "Brunei Darussalam",
        countryCode: "BN",
    },
    {
        airportName: "Bourgas",
        city: "Bourgas",
        iata: "BOJ",
        countryIata: "BGBOJ",
        country: "Bulgaria",
        countryCode: "BG",
    },
    {
        airportName: "Gorna Orechovitsa",
        city: "Gorna Orechovitsa",
        iata: "GOZ",
        countryIata: "BGGOZ",
        country: "Bulgaria",
        countryCode: "BG",
    },
    {
        airportName: "Haskovo",
        city: "Haskovo",
        iata: "HKV",
        countryIata: "BGHKV",
        country: "Bulgaria",
        countryCode: "BG",
    },
    {
        airportName: "Jambol",
        city: "Jambol",
        iata: "JAM",
        countryIata: "BGJAM",
        country: "Bulgaria",
        countryCode: "BG",
    },
    {
        airportName: "Kardjali",
        city: "Kardjali",
        iata: "KDG",
        countryIata: "BGKDG",
        country: "Bulgaria",
        countryCode: "BG",
    },
    {
        airportName: "Plovdiv",
        city: "Plovdiv",
        iata: "PDV",
        countryIata: "BGPDV",
        country: "Bulgaria",
        countryCode: "BG",
    },
    {
        airportName: "Pleven",
        city: "Pleven",
        iata: "PVN",
        countryIata: "BGPVN",
        country: "Bulgaria",
        countryCode: "BG",
    },
    {
        airportName: "Rousse",
        city: "Rousse",
        iata: "ROU",
        countryIata: "BGROU",
        country: "Bulgaria",
        countryCode: "BG",
    },
    {
        airportName: "Silistra",
        city: "Silistra",
        iata: "SLS",
        countryIata: "BGSLS",
        country: "Bulgaria",
        countryCode: "BG",
    },
    {
        airportName: "Vrazhdebna",
        city: "Sofia",
        iata: "SOF",
        countryIata: "BGSOF",
        country: "Bulgaria",
        countryCode: "BG",
    },
    {
        airportName: "Stara Zagora",
        city: "Stara Zagora",
        iata: "SZR",
        countryIata: "BGSZR",
        country: "Bulgaria",
        countryCode: "BG",
    },
    {
        airportName: "Targovishte",
        city: "Targovishte",
        iata: "TGV",
        countryIata: "BGTGV",
        country: "Bulgaria",
        countryCode: "BG",
    },
    {
        airportName: "Varna",
        city: "Varna",
        iata: "VAR",
        countryIata: "BGVAR",
        country: "Bulgaria",
        countryCode: "BG",
    },
    {
        airportName: "Vidin",
        city: "Vidin",
        iata: "VID",
        countryIata: "BGVID",
        country: "Bulgaria",
        countryCode: "BG",
    },
    {
        airportName: "Battambang",
        city: "Battambang",
        iata: "BBM",
        countryIata: "KHBBM",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Koh Kong",
        city: "Koh Kong",
        iata: "KKZ",
        countryIata: "KHKKZ",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Kampot",
        city: "Kampot",
        iata: "KMT",
        countryIata: "KHKMT",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Sihanoukville",
        city: "Sihanoukville",
        iata: "KOS",
        countryIata: "KHKOS",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Kratie",
        city: "Kratie",
        iata: "KTI",
        countryIata: "KHKTI",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Kampong Chhnang Airport",
        city: "Kampong Chhnang",
        iata: "KZC",
        countryIata: "KHKZC",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Krakor",
        city: "Krakor",
        iata: "KZD",
        countryIata: "KHKZD",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Kompong Thom",
        city: "Kompong Thom",
        iata: "KZK",
        countryIata: "KHKZK",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Mundulkiri",
        city: "Mundulkiri",
        iata: "MWV",
        countryIata: "KHMWV",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Oddor Meanche",
        city: "Oddor Meanche",
        iata: "OMY",
        countryIata: "KHOMY",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Pailin",
        city: "Pailin",
        iata: "PAI",
        countryIata: "KHPAI",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Pochentong",
        city: "Phnom Penh",
        iata: "PNH",
        countryIata: "KHPNH",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Ratanakiri",
        city: "Ratanakiri",
        iata: "RBE",
        countryIata: "KHRBE",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Siem Reap-Angkor International Airport",
        city: "Siem Reap",
        iata: "REP",
        countryIata: "KHREP",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Svay Rieng",
        city: "Svay Rieng",
        iata: "SVR",
        countryIata: "KHSVR",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Stung Treng",
        city: "Stung Treng",
        iata: "TNX",
        countryIata: "KHTNX",
        country: "Cambodia",
        countryCode: "KH",
    },
    {
        airportName: "Bafoussam",
        city: "Bafoussam",
        iata: "BFX",
        countryIata: "CMBFX",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Bali",
        city: "Bali",
        iata: "BLC",
        countryIata: "CMBLC",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Bamenda",
        city: "Bamenda",
        iata: "BPC",
        countryIata: "CMBPC",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Bertoua",
        city: "Bertoua",
        iata: "BTA",
        countryIata: "CMBTA",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Douala",
        city: "Douala",
        iata: "DLA",
        countryIata: "CMDLA",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Dschang",
        city: "Dschang",
        iata: "DSC",
        countryIata: "CMDSC",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Ebolowa",
        city: "Ebolowa",
        iata: "EBW",
        countryIata: "CMEBW",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Foumban",
        city: "Foumban",
        iata: "FOM",
        countryIata: "CMFOM",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Garoua",
        city: "Garoua",
        iata: "GOU",
        countryIata: "CMGOU",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Yagoua",
        city: "Yagoua",
        iata: "GXX",
        countryIata: "CMGXX",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Kribi",
        city: "Kribi",
        iata: "KBI",
        countryIata: "CMKBI",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Kaele",
        city: "Kaele",
        iata: "KLE",
        countryIata: "CMKLE",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Koutaba",
        city: "Koutaba",
        iata: "KOB",
        countryIata: "CMKOB",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Mamfe",
        city: "Mamfe",
        iata: "MMF",
        countryIata: "CMMMF",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Salam",
        city: "Maroua",
        iata: "MVR",
        countryIata: "CMMVR",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Ngaoundere",
        city: "Ngaoundere",
        iata: "NGE",
        countryIata: "CMNGE",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Nkongsamba",
        city: "Nkongsamba",
        iata: "NKS",
        countryIata: "CMNKS",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Nsimalen",
        city: "Yaounde",
        iata: "NSI",
        countryIata: "CMNSI",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Batouri",
        city: "Batouri",
        iata: "OUR",
        countryIata: "CMOUR",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Tiko",
        city: "Tiko",
        iata: "TKC",
        countryIata: "CMTKC",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Limbe",
        city: "Limbe",
        iata: "VCC",
        countryIata: "CMVCC",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Yaounde Airport",
        city: "Yaounde",
        iata: "YAO",
        countryIata: "CMYAO",
        country: "Cameroon",
        countryCode: "CM",
    },
    {
        airportName: "Akulivik",
        city: "Akulivik",
        iata: "AKV",
        countryIata: "CAAKV",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Creston",
        city: "Creston",
        iata: "CFQ",
        countryIata: "CACFQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Chilko Lake",
        city: "Chilko Lake",
        iata: "CJH",
        countryIata: "CACJH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Coal Harbour",
        city: "Vancouver",
        iata: "CXH",
        countryIata: "CACXH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Great Bear Lake",
        city: "Great Bear Lake",
        iata: "DAS",
        countryIata: "CADAS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Douglas Lake",
        city: "Douglas Lake",
        iata: "DGF",
        countryIata: "CADGF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Duncan/Quam",
        city: "Duncan/Quam",
        iata: "DUQ",
        countryIata: "CADUQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Taltheilei Narrows",
        city: "Taltheilei Narrows",
        iata: "GSL",
        countryIata: "CAGSL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Ilford",
        city: "Ilford",
        iata: "ILF",
        countryIata: "CAILF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Summit Lake",
        city: "Summit Lake",
        iata: "IUM",
        countryIata: "CAIUM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kelsey",
        city: "Kelsey",
        iata: "KES",
        countryIata: "CAKES",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Keewaywin",
        city: "Keewaywin",
        iata: "KEW",
        countryIata: "CAKEW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kingfisher Lake",
        city: "Kingfisher Lake",
        iata: "KIF",
        countryIata: "CAKIF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Knights Inlet",
        city: "Knights Inlet",
        iata: "KNV",
        countryIata: "CAKNV",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kinoosao",
        city: "Kinoosao",
        iata: "KNY",
        countryIata: "CAKNY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Aklavik",
        city: "Aklavik",
        iata: "LAK",
        countryIata: "CALAK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Laurie River",
        city: "Laurie River",
        iata: "LRQ",
        countryIata: "CALRQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Muskrat Dam",
        city: "Muskrat Dam",
        iata: "MSA",
        countryIata: "CAMSA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Great Circle Airport NS",
        city: "Argentia",
        iata: "NWP",
        countryIata: "CANWP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Pikwitonei",
        city: "Pikwitonei",
        iata: "PIW",
        countryIata: "CAPIW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Bella Coola",
        city: "Bella Coola",
        iata: "QBC",
        countryIata: "CAQBC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "La Sarre",
        city: "La Sarre",
        iata: "SSQ",
        countryIata: "CASSQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Summer Beaver",
        city: "Summer Beaver",
        iata: "SUR",
        countryIata: "CASUR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Silva Bay",
        city: "Silva Bay",
        iata: "SYF",
        countryIata: "CASYF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Inverlake",
        city: "Inverlake",
        iata: "TIL",
        countryIata: "CATIL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Tungsten",
        city: "Tungsten",
        iata: "TNS",
        countryIata: "CATNS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Tumbler Ridge",
        city: "Tumbler Ridge",
        iata: "TUX",
        countryIata: "CATUX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Wunnummin Lake",
        city: "Wunnummin Lake",
        iata: "WNN",
        countryIata: "CAWNN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Pincher Creek",
        city: "Pincher Creek",
        iata: "WPC",
        countryIata: "CAWPC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Powell Lake",
        city: "Powell Lake",
        iata: "WPL",
        countryIata: "CAWPL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Blubber Bay",
        city: "Blubber Bay",
        iata: "XBB",
        countryIata: "CAXBB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Bearskin Lake",
        city: "Bearskin Lake",
        iata: "XBE",
        countryIata: "CAXBE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Brockville",
        city: "Brockville",
        iata: "XBR",
        countryIata: "CAXBR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Killineq",
        city: "Killineq",
        iata: "XBW",
        countryIata: "CAXBW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Cluff Lake",
        city: "Cluff Lake",
        iata: "XCL",
        countryIata: "CAXCL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Chatham",
        city: "Chatham",
        iata: "XCM",
        countryIata: "CAXCM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Charny",
        city: "Quebec",
        iata: "XFZ",
        countryIata: "CAXFZ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kangiqsualujjuaq",
        city: "Kangiqsualujjuaq",
        iata: "XGR",
        countryIata: "CAXGR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kemano",
        city: "Kemano",
        iata: "XKO",
        countryIata: "CAXKO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kasabonika",
        city: "Kasabonika",
        iata: "XKS",
        countryIata: "CAXKS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Lac Brochet",
        city: "Lac Brochet",
        iata: "XLB",
        countryIata: "CAXLB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Leaf Bay",
        city: "Leaf Bay",
        iata: "XLF",
        countryIata: "CAXLF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Macmillan Pass",
        city: "Macmillan Pass",
        iata: "XMP",
        countryIata: "CAXMP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Pukatawagan",
        city: "Pukatawagan",
        iata: "XPK",
        countryIata: "CAXPK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Poplar River",
        city: "Poplar River",
        iata: "XPP",
        countryIata: "CAXPP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Qualicum",
        city: "Qualicum",
        iata: "XQU",
        countryIata: "CAXQU",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Ross River",
        city: "Ross River",
        iata: "XRR",
        countryIata: "CAXRR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "South Indian Lake",
        city: "South Indian Lake",
        iata: "XSI",
        countryIata: "CAXSI",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Tadoule Lake",
        city: "Tadoule Lake",
        iata: "XTL",
        countryIata: "CAXTL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Anahim Lake",
        city: "Anahim Lake",
        iata: "YAA",
        countryIata: "CAYAA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Arctic Bay",
        city: "Arctic Bay",
        iata: "YAB",
        countryIata: "CAYAB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Cat Lake",
        city: "Cat Lake",
        iata: "YAC",
        countryIata: "CAYAC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Moose Lake",
        city: "Moose Lake",
        iata: "YAD",
        countryIata: "CAYAD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Alta Lake",
        city: "Alta Lake",
        iata: "YAE",
        countryIata: "CAYAE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Asbestos Hill",
        city: "Asbestos Hill",
        iata: "YAF",
        countryIata: "CAYAF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Municipal",
        city: "Fort Frances",
        iata: "YAG",
        countryIata: "CAYAG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Lagrande 4",
        city: "Lagrande 4",
        iata: "YAH",
        countryIata: "CAYAH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Lyall Harbour",
        city: "Lyall Harbour",
        iata: "YAJ",
        countryIata: "CAYAJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Alert Bay",
        city: "Alert Bay",
        iata: "YAL",
        countryIata: "CAYAL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Sault Ste Marie",
        city: "Sault Ste Marie",
        iata: "YAM",
        countryIata: "CAYAM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Maple Bay",
        city: "Maple Bay",
        iata: "YAQ",
        countryIata: "CAYAQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Lagrande 3",
        city: "Lagrande 3",
        iata: "YAR",
        countryIata: "CAYAR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Attawapiskat",
        city: "Attawapiskat",
        iata: "YAT",
        countryIata: "CAYAT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kattiniq/Donaldson Lak",
        city: "Kattiniq/Donaldson Lak",
        iata: "YAU",
        countryIata: "CAYAU",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Miners Bay",
        city: "Miners Bay",
        iata: "YAV",
        countryIata: "CAYAV",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Shearwater",
        city: "Halifax",
        iata: "YAW",
        countryIata: "CAYAW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Angling Lake",
        city: "Angling Lake",
        iata: "YAX",
        countryIata: "CAYAX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "St Anthony",
        city: "St Anthony",
        iata: "YAY",
        countryIata: "CAYAY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Tofino Airport",
        city: "Tofino",
        iata: "YAZ",
        countryIata: "CAYAZ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Banff",
        city: "Banff",
        iata: "YBA",
        countryIata: "CAYBA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kugaaruk",
        city: "Kugaaruk",
        iata: "YBB",
        countryIata: "CAYBB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Baie Comeau",
        city: "Baie Comeau",
        iata: "YBC",
        countryIata: "CAYBC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "New Westminster",
        city: "New Westminster",
        iata: "YBD",
        countryIata: "CAYBD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Uranium City",
        city: "Uranium City",
        iata: "YBE",
        countryIata: "CAYBE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Bamfield",
        city: "Bamfield",
        iata: "YBF",
        countryIata: "CAYBF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Bagotville",
        city: "Bagotville",
        iata: "YBG",
        countryIata: "CAYBG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Bull Harbour",
        city: "Bull Harbour",
        iata: "YBH",
        countryIata: "CAYBH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Black Tickle",
        city: "Black Tickle",
        iata: "YBI",
        countryIata: "CAYBI",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Baie Johan Beetz",
        city: "Baie Johan Beetz",
        iata: "YBJ",
        countryIata: "CAYBJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Baker Lake",
        city: "Baker Lake",
        iata: "YBK",
        countryIata: "CAYBK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Campbell River",
        city: "Campbell River",
        iata: "YBL",
        countryIata: "CAYBL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Bronson Creek",
        city: "Bronson Creek",
        iata: "YBM",
        countryIata: "CAYBM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Borden",
        city: "Borden",
        iata: "YBN",
        countryIata: "CAYBN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Bobquinn Lake",
        city: "Bobquinn Lake",
        iata: "YBO",
        countryIata: "CAYBO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Telegraph Harbour",
        city: "Telegraph Harbour",
        iata: "YBQ",
        countryIata: "CAYBQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Brandon",
        city: "Brandon",
        iata: "YBR",
        countryIata: "CAYBR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Musselwhite",
        city: "Opapamiska Lake",
        iata: "YBS",
        countryIata: "CAYBS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Brochet",
        city: "Brochet",
        iata: "YBT",
        countryIata: "CAYBT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Berens River",
        city: "Berens River",
        iata: "YBV",
        countryIata: "CAYBV",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Bedwell Harbor",
        city: "Bedwell Harbor",
        iata: "YBW",
        countryIata: "CAYBW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Blanc Sablon",
        city: "Blanc Sablon",
        iata: "YBX",
        countryIata: "CAYBX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Bonnyville",
        city: "Bonnyville",
        iata: "YBY",
        countryIata: "CAYBY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Downtown Rail Station",
        city: "Toronto",
        iata: "YBZ",
        countryIata: "CAYBZ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Courtenay",
        city: "Courtenay",
        iata: "YCA",
        countryIata: "CAYCA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Cambridge Bay",
        city: "Cambridge Bay",
        iata: "YCB",
        countryIata: "CAYCB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Regional",
        city: "Cornwall",
        iata: "YCC",
        countryIata: "CAYCC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Nanaimo Airport",
        city: "Nanaimo",
        iata: "YCD",
        countryIata: "CAYCD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Centralia",
        city: "Centralia",
        iata: "YCE",
        countryIata: "CAYCE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Cortes Bay",
        city: "Cortes Bay",
        iata: "YCF",
        countryIata: "CAYCF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Castlegar",
        city: "Castlegar",
        iata: "YCG",
        countryIata: "CAYCG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Miramichi",
        city: "Miramichi",
        iata: "YCH",
        countryIata: "CAYCH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Caribou Island",
        city: "Caribou Island",
        iata: "YCI",
        countryIata: "CAYCI",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Cape St James",
        city: "Cape St James",
        iata: "YCJ",
        countryIata: "CAYCJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Colville Lake",
        city: "Colville Lake",
        iata: "YCK",
        countryIata: "CAYCK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Charlo",
        city: "Charlo",
        iata: "YCL",
        countryIata: "CAYCL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "St Catharines",
        city: "St Catharines",
        iata: "YCM",
        countryIata: "CAYCM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Cochrane",
        city: "Cochrane",
        iata: "YCN",
        countryIata: "CAYCN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kugluktuk",
        city: "Kugluktuk/Coppermine",
        iata: "YCO",
        countryIata: "CAYCO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Co-op Point",
        city: "Co-op Point",
        iata: "YCP",
        countryIata: "CAYCP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Chetwynd",
        city: "Chetwynd",
        iata: "YCQ",
        countryIata: "CAYCQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Cross Lake",
        city: "Cross Lake",
        iata: "YCR",
        countryIata: "CAYCR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Chesterfield Inlet",
        city: "Chesterfield Inlet",
        iata: "YCS",
        countryIata: "CAYCS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Coronation",
        city: "Coronation",
        iata: "YCT",
        countryIata: "CAYCT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Cartierville",
        city: "Cartierville",
        iata: "YCV",
        countryIata: "CAYCV",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Chilliwack",
        city: "Chilliwack",
        iata: "YCW",
        countryIata: "CAYCW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Gagetown",
        city: "Gagetown",
        iata: "YCX",
        countryIata: "CAYCX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Clyde River",
        city: "Clyde River",
        iata: "YCY",
        countryIata: "CAYCY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fairmount Springs",
        city: "Fairmount Springs",
        iata: "YCZ",
        countryIata: "CAYCZ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Dawson City",
        city: "Dawson City",
        iata: "YDA",
        countryIata: "CAYDA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Burwash Landings",
        city: "Burwash Landings",
        iata: "YDB",
        countryIata: "CAYDB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Industrial Airport",
        city: "Drayton Valley",
        iata: "YDC",
        countryIata: "CAYDC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Paradise River",
        city: "Paradise River",
        iata: "YDE",
        countryIata: "CAYDE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Deer Lake",
        city: "Deer Lake",
        iata: "YDF",
        countryIata: "CAYDF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Digby",
        city: "Digby",
        iata: "YDG",
        countryIata: "CAYDG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Daniels Harbour",
        city: "Daniels Harbour",
        iata: "YDH",
        countryIata: "CAYDH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Davis Inlet",
        city: "Davis Inlet",
        iata: "YDI",
        countryIata: "CAYDI",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Hatchet Lake",
        city: "Hatchet Lake",
        iata: "YDJ",
        countryIata: "CAYDJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Main Duck Island",
        city: "Main Duck Island",
        iata: "YDK",
        countryIata: "CAYDK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Dease Lake",
        city: "Dease Lake",
        iata: "YDL",
        countryIata: "CAYDL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Dauphin",
        city: "Dauphin",
        iata: "YDN",
        countryIata: "CAYDN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "St Methode",
        city: "Dolbeau",
        iata: "YDO",
        countryIata: "CAYDO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Nain",
        city: "Nain",
        iata: "YDP",
        countryIata: "CAYDP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Dawson Creek",
        city: "Dawson Creek",
        iata: "YDQ",
        countryIata: "CAYDQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Broadview",
        city: "Broadview",
        iata: "YDR",
        countryIata: "CAYDR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Desolation Sound",
        city: "Desolation Sound",
        iata: "YDS",
        countryIata: "CAYDS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Boundary Bay",
        city: "Vancouver",
        iata: "YDT",
        countryIata: "CAYDT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kasba Lake",
        city: "Kasba Lake",
        iata: "YDU",
        countryIata: "CAYDU",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Bloodvein",
        city: "Bloodvein",
        iata: "YDV",
        countryIata: "CAYDV",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Obre Lake",
        city: "Obre Lake",
        iata: "YDW",
        countryIata: "CAYDW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Doc Creek",
        city: "Doc Creek",
        iata: "YDX",
        countryIata: "CAYDX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Metropolitan Area",
        city: "Edmonton",
        iata: "YEA",
        countryIata: "CAYEA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Namao Fld",
        city: "Edmonton",
        iata: "YED",
        countryIata: "CAYED",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "International",
        city: "Edmonton",
        iata: "YEG",
        countryIata: "CAYEG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Arviat",
        city: "Arviat",
        iata: "YEK",
        countryIata: "CAYEK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Elliot Lake",
        city: "Elliot Lake",
        iata: "YEL",
        countryIata: "CAYEL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "East Manitoulin",
        city: "Manitowaning",
        iata: "YEM",
        countryIata: "CAYEM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Estevan",
        city: "Estevan",
        iata: "YEN",
        countryIata: "CAYEN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Estevan Point",
        city: "Estevan Point",
        iata: "YEP",
        countryIata: "CAYEP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fort Severn",
        city: "Fort Severn",
        iata: "YER",
        countryIata: "CAYER",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Edson",
        city: "Edson",
        iata: "YET",
        countryIata: "CAYET",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Eureka",
        city: "Eureka",
        iata: "YEU",
        countryIata: "CAYEU",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Inuvik/Mike Zubko",
        city: "Inuvik",
        iata: "YEV",
        countryIata: "CAYEV",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Amos",
        city: "Amos",
        iata: "YEY",
        countryIata: "CAYEY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fort Albany",
        city: "Fort Albany",
        iata: "YFA",
        countryIata: "CAYFA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Iqaluit",
        city: "Iqaluit",
        iata: "YFB",
        countryIata: "CAYFB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fredericton",
        city: "Fredericton",
        iata: "YFC",
        countryIata: "CAYFC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Forestville",
        city: "Forestville",
        iata: "YFE",
        countryIata: "CAYFE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fontanges",
        city: "Fontanges",
        iata: "YFG",
        countryIata: "CAYFG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fort Hope",
        city: "Fort Hope",
        iata: "YFH",
        countryIata: "CAYFH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Snare Lake",
        city: "Snare Lake",
        iata: "YFJ",
        countryIata: "CAYFJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fort Reliance",
        city: "Fort Reliance",
        iata: "YFL",
        countryIata: "CAYFL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Flin Flon",
        city: "Flin Flon",
        iata: "YFO",
        countryIata: "CAYFO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fort Resolution",
        city: "Fort Resolution",
        iata: "YFR",
        countryIata: "CAYFR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fort Simpson",
        city: "Fort Simpson",
        iata: "YFS",
        countryIata: "CAYFS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "St Lewis",
        city: "Fox Harbour",
        iata: "YFX",
        countryIata: "CAYFX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Gagnon",
        city: "Gagnon",
        iata: "YGA",
        countryIata: "CAYGA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Gillies Bay",
        city: "Gillies Bay",
        iata: "YGB",
        countryIata: "CAYGB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Grande Cache",
        city: "Grande Cache",
        iata: "YGC",
        countryIata: "CAYGC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Gorge Harbor",
        city: "Gorge Harbor",
        iata: "YGE",
        countryIata: "CAYGE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Ganges Harbor",
        city: "Ganges Harbor",
        iata: "YGG",
        countryIata: "CAYGG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fort Good Hope",
        city: "Fort Good Hope",
        iata: "YGH",
        countryIata: "CAYGH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kingston",
        city: "Kingston",
        iata: "YGK",
        countryIata: "CAYGK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "La Grande",
        city: "La Grande",
        iata: "YGL",
        countryIata: "CAYGL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Gimli",
        city: "Gimli",
        iata: "YGM",
        countryIata: "CAYGM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Greenway Sound",
        city: "Greenway Sound",
        iata: "YGN",
        countryIata: "CAYGN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Gods Narrows",
        city: "Gods Narrows",
        iata: "YGO",
        countryIata: "CAYGO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Metropolitan Area",
        city: "Gaspe",
        iata: "YGP",
        countryIata: "CAYGP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Geraldton",
        city: "Geraldton",
        iata: "YGQ",
        countryIata: "CAYGQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Iles De La Madeleine",
        city: "Iles De La Madeleine",
        iata: "YGR",
        countryIata: "CAYGR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Germansen",
        city: "Germansen",
        iata: "YGS",
        countryIata: "CAYGS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Igloolik",
        city: "Igloolik",
        iata: "YGT",
        countryIata: "CAYGT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Havre St Pierre",
        city: "Havre St Pierre",
        iata: "YGV",
        countryIata: "CAYGV",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kuujjuarapik",
        city: "Kuujjuarapik",
        iata: "YGW",
        countryIata: "CAYGW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Gillam",
        city: "Gillam",
        iata: "YGX",
        countryIata: "CAYGX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Deception",
        city: "Deception",
        iata: "YGY",
        countryIata: "CAYGY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Grise Fiord",
        city: "Grise Fiord",
        iata: "YGZ",
        countryIata: "CAYGZ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Port Hope Simpson",
        city: "Port Hope Simpson",
        iata: "YHA",
        countryIata: "CAYHA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Hudson Bay",
        city: "Hudson Bay",
        iata: "YHB",
        countryIata: "CAYHB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Hakai Pass",
        city: "Hakai Pass",
        iata: "YHC",
        countryIata: "CAYHC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Municipal",
        city: "Dryden",
        iata: "YHD",
        countryIata: "CAYHD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Hope",
        city: "Hope",
        iata: "YHE",
        countryIata: "CAYHE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Hearst",
        city: "Hearst",
        iata: "YHF",
        countryIata: "CAYHF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Charlottetown",
        city: "Charlottetown",
        iata: "YHG",
        countryIata: "CAYHG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Holman",
        city: "Holman",
        iata: "YHI",
        countryIata: "CAYHI",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Gjoa Haven",
        city: "Gjoa Haven",
        iata: "YHK",
        countryIata: "CAYHK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Hamilton",
        city: "Hamilton",
        iata: "YHM",
        countryIata: "CAYHM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Hornepayne",
        city: "Hornepayne",
        iata: "YHN",
        countryIata: "CAYHN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Hopedale",
        city: "Hopedale",
        iata: "YHO",
        countryIata: "CAYHO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Poplar Hill",
        city: "Poplar Hill",
        iata: "YHP",
        countryIata: "CAYHP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Chevery",
        city: "Chevery",
        iata: "YHR",
        countryIata: "CAYHR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Sechelt",
        city: "Sechelt",
        iata: "YHS",
        countryIata: "CAYHS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Haines Junction",
        city: "Haines Junction",
        iata: "YHT",
        countryIata: "CAYHT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "St Hubert",
        city: "Montreal",
        iata: "YHU",
        countryIata: "CAYHU",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Hay River",
        city: "Hay River",
        iata: "YHY",
        countryIata: "CAYHY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Halifax International",
        city: "Halifax",
        iata: "YHZ",
        countryIata: "CAYHZ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Atikokan",
        city: "Atikokan",
        iata: "YIB",
        countryIata: "CAYIB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Pakuashipi",
        city: "Pakuashipi",
        iata: "YIF",
        countryIata: "CAYIF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Big Bay Marina",
        city: "Big Bay Marina",
        iata: "YIG",
        countryIata: "CAYIG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Ivujivik",
        city: "Ivujivik",
        iata: "YIK",
        countryIata: "CAYIK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Pond Inlet",
        city: "Pond Inlet",
        iata: "YIO",
        countryIata: "CAYIO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Island Lake Airport",
        city: "Island Lake /Garden Hill",
        iata: "YIV",
        countryIata: "CAYIV",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Metropolitan Area",
        city: "Jasper",
        iata: "YJA",
        countryIata: "CAYJA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fort Liard",
        city: "Fort Liard",
        iata: "YJF",
        countryIata: "CAYJF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "St Jean",
        city: "St Jean",
        iata: "YJN",
        countryIata: "CAYJN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Johnny Mountain",
        city: "Johnny Mountain",
        iata: "YJO",
        countryIata: "CAYJO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Jasper-Hinton",
        city: "Jasper-Hinton",
        iata: "YJP",
        countryIata: "CAYJP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Stephenville",
        city: "Stephenville",
        iata: "YJT",
        countryIata: "CAYJT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kamloops",
        city: "Kamloops",
        iata: "YKA",
        countryIata: "CAYKA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Collins Bay",
        city: "Collins Bay",
        iata: "YKC",
        countryIata: "CAYKC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Township Airport",
        city: "Kincardine",
        iata: "YKD",
        countryIata: "CAYKD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Knee Lake",
        city: "Knee Lake",
        iata: "YKE",
        countryIata: "CAYKE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Region of Waterloo International Airport",
        city: "Woolwich",
        iata: "YKF",
        countryIata: "CAYKF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kangirsuk",
        city: "Kangirsuk",
        iata: "YKG",
        countryIata: "CAYKG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kennosao Lake",
        city: "Kennosao Lake",
        iata: "YKI",
        countryIata: "CAYKI",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Key Lake",
        city: "Key Lake",
        iata: "YKJ",
        countryIata: "CAYKJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kitkatla",
        city: "Kitkatla",
        iata: "YKK",
        countryIata: "CAYKK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Schefferville",
        city: "Schefferville",
        iata: "YKL",
        countryIata: "CAYKL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Waskaganish",
        city: "Waskaganish",
        iata: "YKQ",
        countryIata: "CAYKQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Klemtu",
        city: "Klemtu",
        iata: "YKT",
        countryIata: "CAYKT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Chisasibi",
        city: "Chisasibi",
        iata: "YKU",
        countryIata: "CAYKU",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kirkland Lake",
        city: "Kirkland Lake",
        iata: "YKX",
        countryIata: "CAYKX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kindersley",
        city: "Kindersley",
        iata: "YKY",
        countryIata: "CAYKY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Buttonville Municipal Airport",
        city: "Toronto (Buttonville)",
        iata: "YKZ",
        countryIata: "CAYKZ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Langara",
        city: "Langara",
        iata: "YLA",
        countryIata: "CAYLA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Lac Biche",
        city: "Lac Biche",
        iata: "YLB",
        countryIata: "CAYLB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kimmirut",
        city: "Kimmirut/Lake Harbour",
        iata: "YLC",
        countryIata: "CAYLC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Chapleau",
        city: "Chapleau",
        iata: "YLD",
        countryIata: "CAYLD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Wha Ti",
        city: "Wha Ti/Lac La Martre",
        iata: "YLE",
        countryIata: "CAYLE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "LaForges",
        city: "LaForges",
        iata: "YLF",
        countryIata: "CAYLF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Lansdowne House",
        city: "Lansdowne House",
        iata: "YLH",
        countryIata: "CAYLH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Meadow Lake",
        city: "Meadow Lake",
        iata: "YLJ",
        countryIata: "CAYLJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Lloydminster",
        city: "Lloydminster",
        iata: "YLL",
        countryIata: "CAYLL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Clinton Creek",
        city: "Clinton Creek",
        iata: "YLM",
        countryIata: "CAYLM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Shilo",
        city: "Shilo",
        iata: "YLO",
        countryIata: "CAYLO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Mingan",
        city: "Mingan",
        iata: "YLP",
        countryIata: "CAYLP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "La Tuque",
        city: "La Tuque",
        iata: "YLQ",
        countryIata: "CAYLQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Leaf Rapids",
        city: "Leaf Rapids",
        iata: "YLR",
        countryIata: "CAYLR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Lebel-Sur-Quevillon",
        city: "Lebel-Sur-Quevillon",
        iata: "YLS",
        countryIata: "CAYLS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Alert",
        city: "Alert",
        iata: "YLT",
        countryIata: "CAYLT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kelowna",
        city: "Kelowna",
        iata: "YLW",
        countryIata: "CAYLW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Long Point",
        city: "Long Point",
        iata: "YLX",
        countryIata: "CAYLX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Mayo",
        city: "Mayo",
        iata: "YMA",
        countryIata: "CAYMA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Merritt",
        city: "Merritt",
        iata: "YMB",
        countryIata: "CAYMB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Maricourt Airstrip",
        city: "Maricourt Airstrip",
        iata: "YMC",
        countryIata: "CAYMC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Mould Bay",
        city: "Mould Bay",
        iata: "YMD",
        countryIata: "CAYMD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Matane",
        city: "Matane",
        iata: "YME",
        countryIata: "CAYME",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Montagne Harbor",
        city: "Montagne Harbor",
        iata: "YMF",
        countryIata: "CAYMF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Manitouwadge",
        city: "Manitouwadge",
        iata: "YMG",
        countryIata: "CAYMG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Mary's Harbour",
        city: "Mary's Harbour",
        iata: "YMH",
        countryIata: "CAYMH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Minaki",
        city: "Minaki",
        iata: "YMI",
        countryIata: "CAYMI",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Moose Jaw",
        city: "Moose Jaw",
        iata: "YMJ",
        countryIata: "CAYMJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Charlevoix",
        city: "Murray Bay",
        iata: "YML",
        countryIata: "CAYML",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fort Mcmurray",
        city: "Fort Mcmurray",
        iata: "YMM",
        countryIata: "CAYMM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Makkovik",
        city: "Makkovik",
        iata: "YMN",
        countryIata: "CAYMN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Moosonee",
        city: "Moosonee",
        iata: "YMO",
        countryIata: "CAYMO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Port McNeil",
        city: "Port McNeil",
        iata: "YMP",
        countryIata: "CAYMP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Metropolitan Area",
        city: "Montreal",
        iata: "YMQ",
        countryIata: "CAYMQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Merry Island",
        city: "Merry Island",
        iata: "YMR",
        countryIata: "CAYMR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Chibougamau",
        city: "Chibougamau",
        iata: "YMT",
        countryIata: "CAYMT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Maniwaki",
        city: "Maniwaki",
        iata: "YMW",
        countryIata: "CAYMW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Mirabel",
        city: "Montreal",
        iata: "YMX",
        countryIata: "CAYMX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Downtown Rail Station",
        city: "Montreal",
        iata: "YMY",
        countryIata: "CAYMY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Natashquan",
        city: "Natashquan",
        iata: "YNA",
        countryIata: "CAYNA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Wemindji",
        city: "Wemindji",
        iata: "YNC",
        countryIata: "CAYNC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Gatineau",
        city: "Ottawa",
        iata: "YND",
        countryIata: "CAYND",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Norway House",
        city: "Norway House",
        iata: "YNE",
        countryIata: "CAYNE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Deer Lake/Stephenville",
        city: "Corner Brook",
        iata: "YNF",
        countryIata: "CAYNF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Hudson Hope",
        city: "Hudson's Hope",
        iata: "YNH",
        countryIata: "CAYNH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Nitchequon",
        city: "Nitchequon",
        iata: "YNI",
        countryIata: "CAYNI",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Nootka Sound",
        city: "Nootka Sound",
        iata: "YNK",
        countryIata: "CAYNK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Points North Landing",
        city: "Points North Landing",
        iata: "YNL",
        countryIata: "CAYNL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Matagami",
        city: "Matagami",
        iata: "YNM",
        countryIata: "CAYNM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "North Spirit Lake",
        city: "North Spirit Lake",
        iata: "YNO",
        countryIata: "CAYNO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Arnes",
        city: "Arnes",
        iata: "YNR",
        countryIata: "CAYNR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Nemiscau",
        city: "Nemiscau",
        iata: "YNS",
        countryIata: "CAYNS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Ekati",
        city: "Ekati",
        iata: "YOA",
        countryIata: "CAYOA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Old Crow",
        city: "Old Crow",
        iata: "YOC",
        countryIata: "CAYOC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Cold Lake",
        city: "Cold Lake",
        iata: "YOD",
        countryIata: "CAYOD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Falher",
        city: "Falher",
        iata: "YOE",
        countryIata: "CAYOE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Ogoki",
        city: "Ogoki",
        iata: "YOG",
        countryIata: "CAYOG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Oxford House",
        city: "Oxford House",
        iata: "YOH",
        countryIata: "CAYOH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Footner Lake",
        city: "High Level",
        iata: "YOJ",
        countryIata: "CAYOJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Oshawa",
        city: "Oshawa",
        iata: "YOO",
        countryIata: "CAYOO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Rainbow Lake",
        city: "Rainbow Lake",
        iata: "YOP",
        countryIata: "CAYOP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Billy Bishop Regional",
        city: "Owen Sound",
        iata: "YOS",
        countryIata: "CAYOS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Ottawa Macdonald–Cartier International Airport",
        city: "Ottawa",
        iata: "YOW",
        countryIata: "CAYOW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Valcartier",
        city: "Valcartier",
        iata: "YOY",
        countryIata: "CAYOY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Prince Albert",
        city: "Prince Albert",
        iata: "YPA",
        countryIata: "CAYPA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Port Alberni",
        city: "Port Alberni",
        iata: "YPB",
        countryIata: "CAYPB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Paulatuk",
        city: "Paulatuk",
        iata: "YPC",
        countryIata: "CAYPC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Parry Sound",
        city: "Parry Sound",
        iata: "YPD",
        countryIata: "CAYPD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Peace River",
        city: "Peace River",
        iata: "YPE",
        countryIata: "CAYPE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Esquimalt",
        city: "Esquimalt",
        iata: "YPF",
        countryIata: "CAYPF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Portage La Prairie",
        city: "Portage La Prairie",
        iata: "YPG",
        countryIata: "CAYPG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Inukjuak",
        city: "Inukjuak",
        iata: "YPH",
        countryIata: "CAYPH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Port Simpson",
        city: "Port Simpson",
        iata: "YPI",
        countryIata: "CAYPI",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Aupaluk",
        city: "Aupaluk",
        iata: "YPJ",
        countryIata: "CAYPJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Pickle Lake",
        city: "Pickle Lake",
        iata: "YPL",
        countryIata: "CAYPL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Pikangikum",
        city: "Pikangikum",
        iata: "YPM",
        countryIata: "CAYPM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Port Menier",
        city: "Port Menier",
        iata: "YPN",
        countryIata: "CAYPN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Peawanuck",
        city: "Peawanuck",
        iata: "YPO",
        countryIata: "CAYPO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Pine Point",
        city: "Pine Point",
        iata: "YPP",
        countryIata: "CAYPP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Peterborough",
        city: "Peterborough",
        iata: "YPQ",
        countryIata: "CAYPQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Digby Island",
        city: "Prince Rupert",
        iata: "YPR",
        countryIata: "CAYPR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Port Hawkesbury",
        city: "Port Hawkesbury",
        iata: "YPS",
        countryIata: "CAYPS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Pender Harbor",
        city: "Pender Harbor",
        iata: "YPT",
        countryIata: "CAYPT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Powell River",
        city: "Powell River",
        iata: "YPW",
        countryIata: "CAYPW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Puvirnituq",
        city: "Povungnituk",
        iata: "YPX",
        countryIata: "CAYPX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fort Chipewyan",
        city: "Fort Chipewyan",
        iata: "YPY",
        countryIata: "CAYPY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Burns Lake",
        city: "Burns Lake",
        iata: "YPZ",
        countryIata: "CAYPZ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Muskoka",
        city: "Muskoka",
        iata: "YQA",
        countryIata: "CAYQA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Quebec",
        city: "Quebec",
        iata: "YQB",
        countryIata: "CAYQB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Quaqtaq",
        city: "Quaqtaq",
        iata: "YQC",
        countryIata: "CAYQC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "The Pas",
        city: "The Pas",
        iata: "YQD",
        countryIata: "CAYQD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kimberley",
        city: "Kimberley",
        iata: "YQE",
        countryIata: "CAYQE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Red Deer",
        city: "Red Deer",
        iata: "YQF",
        countryIata: "CAYQF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Windsor",
        city: "Windsor",
        iata: "YQG",
        countryIata: "CAYQG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Watson Lake",
        city: "Watson Lake",
        iata: "YQH",
        countryIata: "CAYQH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Yarmouth",
        city: "Yarmouth",
        iata: "YQI",
        countryIata: "CAYQI",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kenora",
        city: "Kenora",
        iata: "YQK",
        countryIata: "CAYQK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Lethbridge",
        city: "Lethbridge",
        iata: "YQL",
        countryIata: "CAYQL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Metropolitan Area",
        city: "Moncton",
        iata: "YQM",
        countryIata: "CAYQM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Nakina",
        city: "Nakina",
        iata: "YQN",
        countryIata: "CAYQN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Comox",
        city: "Comox",
        iata: "YQQ",
        countryIata: "CAYQQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Regina",
        city: "Regina",
        iata: "YQR",
        countryIata: "CAYQR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Pembroke Area Mncpl",
        city: "St Thomas",
        iata: "YQS",
        countryIata: "CAYQS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Thunder Bay",
        city: "Thunder Bay",
        iata: "YQT",
        countryIata: "CAYQT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Grande Prairie",
        city: "Grande Prairie",
        iata: "YQU",
        countryIata: "CAYQU",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Yorkton",
        city: "Yorkton",
        iata: "YQV",
        countryIata: "CAYQV",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "North Battleford",
        city: "North Battleford",
        iata: "YQW",
        countryIata: "CAYQW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Gander",
        city: "Gander",
        iata: "YQX",
        countryIata: "CAYQX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Sydney",
        city: "Sydney",
        iata: "YQY",
        countryIata: "CAYQY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Quesnel",
        city: "Quesnel",
        iata: "YQZ",
        countryIata: "CAYQZ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Rae Lakes",
        city: "Rae Lakes",
        iata: "YRA",
        countryIata: "CAYRA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Resolute",
        city: "Resolute",
        iata: "YRB",
        countryIata: "CAYRB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Dean River",
        city: "Dean River",
        iata: "YRD",
        countryIata: "CAYRD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Resolution Island",
        city: "Resolution Island",
        iata: "YRE",
        countryIata: "CAYRE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Cartwright",
        city: "Cartwright",
        iata: "YRF",
        countryIata: "CAYRF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Rigolet",
        city: "Rigolet",
        iata: "YRG",
        countryIata: "CAYRG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Riviere Du Loup",
        city: "Riviere Du Loup",
        iata: "YRI",
        countryIata: "CAYRI",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Roberval",
        city: "Roberval",
        iata: "YRJ",
        countryIata: "CAYRJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Red Lake",
        city: "Red Lake",
        iata: "YRL",
        countryIata: "CAYRL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Rocky Mountain House",
        city: "Rocky Mountain House",
        iata: "YRM",
        countryIata: "CAYRM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Rivers Inlet",
        city: "Rivers Inlet",
        iata: "YRN",
        countryIata: "CAYRN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Rockcliffe",
        city: "Ottawa",
        iata: "YRO",
        countryIata: "CAYRO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Trois-Rivieres",
        city: "Trois-Rivieres",
        iata: "YRQ",
        countryIata: "CAYRQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Stuart Island",
        city: "Stuart Island",
        iata: "YRR",
        countryIata: "CAYRR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Red Sucker Lake",
        city: "Red Sucker Lake",
        iata: "YRS",
        countryIata: "CAYRS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Rankin Inlet",
        city: "Rankin Inlet",
        iata: "YRT",
        countryIata: "CAYRT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Revelstoke",
        city: "Revelstoke",
        iata: "YRV",
        countryIata: "CAYRV",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Sable Island",
        city: "Sable Island",
        iata: "YSA",
        countryIata: "CAYSA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Sudbury",
        city: "Sudbury",
        iata: "YSB",
        countryIata: "CAYSB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Sherbrooke",
        city: "Sherbrooke",
        iata: "YSC",
        countryIata: "CAYSC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Suffield",
        city: "Suffield",
        iata: "YSD",
        countryIata: "CAYSD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Squamish",
        city: "Squamish",
        iata: "YSE",
        countryIata: "CAYSE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Stony Rapids",
        city: "Stony Rapids",
        iata: "YSF",
        countryIata: "CAYSF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Lutselke",
        city: "Lutselke/Snowdrift",
        iata: "YSG",
        countryIata: "CAYSG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Smith Falls",
        city: "Smith Falls",
        iata: "YSH",
        countryIata: "CAYSH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Sans Souci",
        city: "Sans Souci",
        iata: "YSI",
        countryIata: "CAYSI",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Saint John",
        city: "Saint John",
        iata: "YSJ",
        countryIata: "CAYSJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Sanikiluaq",
        city: "Sanikiluaq",
        iata: "YSK",
        countryIata: "CAYSK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Edmunston",
        city: "St Leonard",
        iata: "YSL",
        countryIata: "CAYSL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fort Smith",
        city: "Fort Smith",
        iata: "YSM",
        countryIata: "CAYSM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Salmon Arm",
        city: "Salmon Arm",
        iata: "YSN",
        countryIata: "CAYSN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Postville",
        city: "Postville",
        iata: "YSO",
        countryIata: "CAYSO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Marathon",
        city: "Marathon",
        iata: "YSP",
        countryIata: "CAYSP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Spring Island",
        city: "Spring Island",
        iata: "YSQ",
        countryIata: "CAYSQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Nanisivik",
        city: "Nanisivik",
        iata: "YSR",
        countryIata: "CAYSR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Slate Island",
        city: "Slate Island",
        iata: "YSS",
        countryIata: "CAYSS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Ste Therese Point",
        city: "Ste Therese Point",
        iata: "YST",
        countryIata: "CAYST",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Summerside",
        city: "Summerside",
        iata: "YSU",
        countryIata: "CAYSU",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Saglek",
        city: "Saglek",
        iata: "YSV",
        countryIata: "CAYSV",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Shearwater",
        city: "Shearwater",
        iata: "YSX",
        countryIata: "CAYSX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Sachs Harbour",
        city: "Sachs Harbour",
        iata: "YSY",
        countryIata: "CAYSY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Squirrel Cove",
        city: "Squirrel Cove",
        iata: "YSZ",
        countryIata: "CAYSZ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Pembroke and Area Airport",
        city: "Pembroke",
        iata: "YTA",
        countryIata: "CAYTA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Hartley Bay",
        city: "Hartley Bay",
        iata: "YTB",
        countryIata: "CAYTB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Sturdee",
        city: "Sturdee",
        iata: "YTC",
        countryIata: "CAYTC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Thicket Portage",
        city: "Thicket Portage",
        iata: "YTD",
        countryIata: "CAYTD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Cape Dorset",
        city: "Cape Dorset",
        iata: "YTE",
        countryIata: "CAYTE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Alma",
        city: "Alma",
        iata: "YTF",
        countryIata: "CAYTF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Sullivan Bay",
        city: "Sullivan Bay",
        iata: "YTG",
        countryIata: "CAYTG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Thompson",
        city: "Thompson",
        iata: "YTH",
        countryIata: "CAYTH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Triple Island",
        city: "Triple Island",
        iata: "YTI",
        countryIata: "CAYTI",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Terrace Bay",
        city: "Terrace Bay",
        iata: "YTJ",
        countryIata: "CAYTJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Tulugak",
        city: "Tulugak",
        iata: "YTK",
        countryIata: "CAYTK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Big Trout",
        city: "Big Trout",
        iata: "YTL",
        countryIata: "CAYTL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Riviere Au Tonnerre",
        city: "Riviere Au Tonnerre",
        iata: "YTN",
        countryIata: "CAYTN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Metropolitan Area",
        city: "Toronto",
        iata: "YTO",
        countryIata: "CAYTO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Seaplane Base",
        city: "Tofino",
        iata: "YTP",
        countryIata: "CAYTP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Tasiujuaq",
        city: "Tasiujuaq",
        iata: "YTQ",
        countryIata: "CAYTQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Trenton",
        city: "Trenton",
        iata: "YTR",
        countryIata: "CAYTR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Timmins",
        city: "Timmins",
        iata: "YTS",
        countryIata: "CAYTS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Tisdale",
        city: "Tisdale",
        iata: "YTT",
        countryIata: "CAYTT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Tasu",
        city: "Tasu",
        iata: "YTU",
        countryIata: "CAYTU",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Telegraph Creek",
        city: "Telegraph Creek",
        iata: "YTX",
        countryIata: "CAYTX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Billy Bishop Toronto City Airport",
        city: "Toronto",
        iata: "YTZ",
        countryIata: "CAYTZ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Tuktoyaktuk",
        city: "Tuktoyaktuk",
        iata: "YUB",
        countryIata: "CAYUB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Umiujaq",
        city: "Umiujaq",
        iata: "YUD",
        countryIata: "CAYUD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Dorval",
        city: "Montreal",
        iata: "YUL",
        countryIata: "CAYUL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Repulse Bay",
        city: "Repulse Bay",
        iata: "YUT",
        countryIata: "CAYUT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Hall Beach",
        city: "Hall Beach",
        iata: "YUX",
        countryIata: "CAYUX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Rouyn-Noranda Airport",
        city: "Rouyn",
        iata: "YUY",
        countryIata: "CAYUY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Bonaventure",
        city: "Bonaventure",
        iata: "YVB",
        countryIata: "CAYVB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "La Ronge Airport (Barber Field)",
        city: "La Ronge",
        iata: "YVC",
        countryIata: "CAYVC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Vernon Regional Airport",
        city: "Vernon",
        iata: "YVE",
        countryIata: "CAYVE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Vermilion",
        city: "Vermilion",
        iata: "YVG",
        countryIata: "CAYVG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Qikiqtarjuaq",
        city: "Qikiqtarjuaq",
        iata: "YVM",
        countryIata: "CAYVM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Val-d'Or Airport",
        city: "Val D'Or",
        iata: "YVO",
        countryIata: "CAYVO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kuujjuaq",
        city: "Kuujjuaq",
        iata: "YVP",
        countryIata: "CAYVP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Norman Wells",
        city: "Norman Wells",
        iata: "YVQ",
        countryIata: "CAYVQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Vancouver International Airport",
        city: "Vancouver",
        iata: "YVR",
        countryIata: "CAYVR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Buffalo Narrows Airport",
        city: "Buffalo Narrows",
        iata: "YVT",
        countryIata: "CAYVT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Wiarton",
        city: "Wiarton",
        iata: "YVV",
        countryIata: "CAYVV",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Deer Lake",
        city: "Deer Lake",
        iata: "YVZ",
        countryIata: "CAYVZ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Petawawa",
        city: "Petawawa",
        iata: "YWA",
        countryIata: "CAYWA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kangiqsujuaq",
        city: "Kangiqsujuaq",
        iata: "YWB",
        countryIata: "CAYWB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Dwtown Waterfront H/P",
        city: "Halifax",
        iata: "YWF",
        countryIata: "CAYWF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Winnipeg",
        city: "Winnipeg",
        iata: "YWG",
        countryIata: "CAYWG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Victoria Inner Harbor",
        city: "Victoria",
        iata: "YWH",
        countryIata: "CAYWH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Deline",
        city: "Deline",
        iata: "YWJ",
        countryIata: "CAYWJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Wabush",
        city: "Wabush",
        iata: "YWK",
        countryIata: "CAYWK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Williams Lake",
        city: "Williams Lake",
        iata: "YWL",
        countryIata: "CAYWL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Williams Harbour",
        city: "Williams Harbour",
        iata: "YWM",
        countryIata: "CAYWM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Winisk",
        city: "Winisk",
        iata: "YWN",
        countryIata: "CAYWN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Lupin",
        city: "Lupin",
        iata: "YWO",
        countryIata: "CAYWO",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Webequie",
        city: "Webequie",
        iata: "YWP",
        countryIata: "CAYWP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Chute-Des-Passes",
        city: "Chute-Des-Passes",
        iata: "YWQ",
        countryIata: "CAYWQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "White River",
        city: "White River",
        iata: "YWR",
        countryIata: "CAYWR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Whistler",
        city: "Whistler",
        iata: "YWS",
        countryIata: "CAYWS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Wrigley",
        city: "Wrigley",
        iata: "YWY",
        countryIata: "CAYWY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Cranbrook",
        city: "Cranbrook",
        iata: "YXC",
        countryIata: "CAYXC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Municipal",
        city: "Edmonton",
        iata: "YXD",
        countryIata: "CAYXD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Saskatoon",
        city: "Saskatoon",
        iata: "YXE",
        countryIata: "CAYXE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Snake River",
        city: "Snake River",
        iata: "YXF",
        countryIata: "CAYXF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Medicine Hat Airport",
        city: "Medicine Hat",
        iata: "YXH",
        countryIata: "CAYXH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Killaloe/Bonnechere Airport (ceased operations)",
        city: "Killaloe",
        iata: "YXI",
        countryIata: "CAYXI",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fort St John Airport",
        city: "Fort St John",
        iata: "YXJ",
        countryIata: "CAYXJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Rimouski",
        city: "Rimouski",
        iata: "YXK",
        countryIata: "CAYXK",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Sioux Lookout Airport",
        city: "Sioux Lookout",
        iata: "YXL",
        countryIata: "CAYXL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Whale Cove",
        city: "Whale Cove",
        iata: "YXN",
        countryIata: "CAYXN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Pangnirtung",
        city: "Pangnirtung",
        iata: "YXP",
        countryIata: "CAYXP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Beaver Creek",
        city: "Beaver Creek",
        iata: "YXQ",
        countryIata: "CAYXQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Earlton Airport (Timiskaming Regional)",
        city: "Earlton",
        iata: "YXR",
        countryIata: "CAYXR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Prince George",
        city: "Prince George",
        iata: "YXS",
        countryIata: "CAYXS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Northwest Regional Airport",
        city: "Terrace",
        iata: "YXT",
        countryIata: "CAYXT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "London International Airport",
        city: "London",
        iata: "YXU",
        countryIata: "CAYXU",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Abbotsford",
        city: "Abbotsford",
        iata: "YXX",
        countryIata: "CAYXX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Whitehorse",
        city: "Whitehorse",
        iata: "YXY",
        countryIata: "CAYXY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Wawa",
        city: "Wawa",
        iata: "YXZ",
        countryIata: "CAYXZ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Big Bay Yacht Club",
        city: "Big Bay Yacht Club",
        iata: "YYA",
        countryIata: "CAYYA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "North Bay - Jack Garland Airport",
        city: "North Bay",
        iata: "YYB",
        countryIata: "CAYYB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Calgary International Airport",
        city: "Calgary",
        iata: "YYC",
        countryIata: "CAYYC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Smithers",
        city: "Smithers",
        iata: "YYD",
        countryIata: "CAYYD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Northern Rockies Regional Airport",
        city: "Fort Nelson",
        iata: "YYE",
        countryIata: "CAYYE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Penticton",
        city: "Penticton",
        iata: "YYF",
        countryIata: "CAYYF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Charlottetown",
        city: "Charlottetown",
        iata: "YYG",
        countryIata: "CAYYG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Taloyoak",
        city: "Taloyoak",
        iata: "YYH",
        countryIata: "CAYYH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Rivers",
        city: "Rivers",
        iata: "YYI",
        countryIata: "CAYYI",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Victoria International Airport",
        city: "Victoria",
        iata: "YYJ",
        countryIata: "CAYYJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Lynn Lake",
        city: "Lynn Lake",
        iata: "YYL",
        countryIata: "CAYYL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Cowley",
        city: "Cowley",
        iata: "YYM",
        countryIata: "CAYYM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Swift Current",
        city: "Swift Current",
        iata: "YYN",
        countryIata: "CAYYN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Metropolitan Area",
        city: "Churchill",
        iata: "YYQ",
        countryIata: "CAYYQ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Goose Bay",
        city: "Goose Bay",
        iata: "YYR",
        countryIata: "CAYYR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "St Johns",
        city: "St Johns",
        iata: "YYT",
        countryIata: "CAYYT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kapuskasing",
        city: "Kapuskasing",
        iata: "YYU",
        countryIata: "CAYYU",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Armstrong",
        city: "Armstrong",
        iata: "YYW",
        countryIata: "CAYYW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Mont Joli",
        city: "Mont Joli",
        iata: "YYY",
        countryIata: "CAYYY",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Toronto Pearson International Airport",
        city: "Toronto",
        iata: "YYZ",
        countryIata: "CAYYZ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Ashcroft",
        city: "Ashcroft",
        iata: "YZA",
        countryIata: "CAYZA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Beatton River",
        city: "Beatton River",
        iata: "YZC",
        countryIata: "CAYZC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Downsview Airport (Bombardier Aerospace)",
        city: "Toronto",
        iata: "YZD",
        countryIata: "CAYZD",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Gore Bay",
        city: "Gore Bay",
        iata: "YZE",
        countryIata: "CAYZE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Yellowknife",
        city: "Yellowknife",
        iata: "YZF",
        countryIata: "CAYZF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Salluit",
        city: "Salluit",
        iata: "YZG",
        countryIata: "CAYZG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Slave Lake",
        city: "Slave Lake",
        iata: "YZH",
        countryIata: "CAYZH",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Buchans",
        city: "Buchans",
        iata: "YZM",
        countryIata: "CAYZM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Sandspit",
        city: "Sandspit",
        iata: "YZP",
        countryIata: "CAYZP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Sarnia",
        city: "Sarnia",
        iata: "YZR",
        countryIata: "CAYZR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Coral Harbour",
        city: "Coral Harbour",
        iata: "YZS",
        countryIata: "CAYZS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Port Hardy",
        city: "Port Hardy",
        iata: "YZT",
        countryIata: "CAYZT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Whitecourt",
        city: "Whitecourt",
        iata: "YZU",
        countryIata: "CAYZU",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Sept-Iles",
        city: "Sept-Iles",
        iata: "YZV",
        countryIata: "CAYZV",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Teslin",
        city: "Teslin",
        iata: "YZW",
        countryIata: "CAYZW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Greenwood",
        city: "Greenwood",
        iata: "YZX",
        countryIata: "CAYZX",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Trail Airport",
        city: "Trail",
        iata: "YZZ",
        countryIata: "CAYZZ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Alice Arm",
        city: "Alice Arm",
        iata: "ZAA",
        countryIata: "CAZAA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "York Landing",
        city: "York Landing",
        iata: "ZAC",
        countryIata: "CAZAC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Bathurst",
        city: "Bathurst",
        iata: "ZBF",
        countryIata: "CAZBF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Bromont",
        city: "Bromont",
        iata: "ZBM",
        countryIata: "CAZBM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Bella Bella",
        city: "Bella Bella",
        iata: "ZEL",
        countryIata: "CAZEL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "East Main",
        city: "East Main",
        iata: "ZEM",
        countryIata: "CAZEM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Faro",
        city: "Faro",
        iata: "ZFA",
        countryIata: "CAZFA",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Old Fort Bay",
        city: "Old Fort Bay",
        iata: "ZFB",
        countryIata: "CAZFB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "South Trout Lake",
        city: "South Trout Lake",
        iata: "ZFL",
        countryIata: "CAZFL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fort Mcpherson",
        city: "Fort Mcpherson",
        iata: "ZFM",
        countryIata: "CAZFM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Tulita",
        city: "Tulita/Fort Norman",
        iata: "ZFN",
        countryIata: "CAZFN",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Fairview",
        city: "Fairview",
        iata: "ZFW",
        countryIata: "CAZFW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Grand Forks",
        city: "Grand Forks",
        iata: "ZGF",
        countryIata: "CAZGF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Little Grand Rapids",
        city: "Little Grand Rapids",
        iata: "ZGR",
        countryIata: "CAZGR",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Gethsemani",
        city: "Gethsemani",
        iata: "ZGS",
        countryIata: "CAZGS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "High Prairie",
        city: "High Prairie",
        iata: "ZHP",
        countryIata: "CAZHP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Jenpeg",
        city: "Jenpeg",
        iata: "ZJG",
        countryIata: "CAZJG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kaschechewan",
        city: "Kaschechewan",
        iata: "ZKE",
        countryIata: "CAZKE",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Kegaska",
        city: "Kegaska",
        iata: "ZKG",
        countryIata: "CAZKG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "La Tabatiere",
        city: "La Tabatiere",
        iata: "ZLT",
        countryIata: "CAZLT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Masset",
        city: "Masset",
        iata: "ZMT",
        countryIata: "CAZMT",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Negginan",
        city: "Negginan",
        iata: "ZNG",
        countryIata: "CAZNG",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Namu",
        city: "Namu",
        iata: "ZNU",
        countryIata: "CAZNU",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Ocean Falls",
        city: "Ocean Falls",
        iata: "ZOF",
        countryIata: "CAZOF",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Queen Charlotte Is",
        city: "Queen Charlotte Is",
        iata: "ZQS",
        countryIata: "CAZQS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Round Lake",
        city: "Round Lake",
        iata: "ZRJ",
        countryIata: "CAZRJ",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "St Paul",
        city: "St Paul",
        iata: "ZSP",
        countryIata: "CAZSP",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Seal Cove",
        city: "Prince Rupert",
        iata: "ZSW",
        countryIata: "CAZSW",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Tete-a-La Baleine",
        city: "Tete-a-La Baleine",
        iata: "ZTB",
        countryIata: "CAZTB",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Shamattawa",
        city: "Shamattawa",
        iata: "ZTM",
        countryIata: "CAZTM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Tahsis",
        city: "Tahsis",
        iata: "ZTS",
        countryIata: "CAZTS",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Ignace",
        city: "Ignace",
        iata: "ZUC",
        countryIata: "CAZUC",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Churchill Falls",
        city: "Churchill Falls",
        iata: "ZUM",
        countryIata: "CAZUM",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Wollaston Lake",
        city: "Wollaston Lake",
        iata: "ZWL",
        countryIata: "CAZWL",
        country: "Canada",
        countryCode: "CA",
    },
    {
        airportName: "Lanzarote",
        city: "Lanzarote",
        iata: "ACE",
        countryIata: "ESACE",
        country: "Canary Islands, Spain",
        countryCode: "ES",
    },
    {
        airportName: "Fuerteventura",
        city: "Fuerteventura",
        iata: "FUE",
        countryIata: "ESFUE",
        country: "Canary Islands, Spain",
        countryCode: "ES",
    },
    {
        airportName: "La Gomera",
        city: "San Sebas de la Gomera",
        iata: "GMZ",
        countryIata: "ESGMZ",
        country: "Canary Islands, Spain",
        countryCode: "ES",
    },
    {
        airportName: "Airport de Gran Canaria",
        city: "Las Palmas",
        iata: "LPA",
        countryIata: "ESLPA",
        country: "Canary Islands, Spain",
        countryCode: "ES",
    },
    {
        airportName: "La Palma",
        city: "Santa Cruz De La Palma",
        iata: "SPC",
        countryIata: "ESSPC",
        country: "Canary Islands, Spain",
        countryCode: "ES",
    },
    {
        airportName: "Metropolitan Area",
        city: "Tenerife",
        iata: "TCI",
        countryIata: "ESTCI",
        country: "Canary Islands, Spain",
        countryCode: "ES",
    },
    {
        airportName: "Norte Los Rodeos",
        city: "Tenerife",
        iata: "TFN",
        countryIata: "ESTFN",
        country: "Canary Islands, Spain",
        countryCode: "ES",
    },
    {
        airportName: "Sur Reina Sofia",
        city: "Tenerife",
        iata: "TFS",
        countryIata: "ESTFS",
        country: "Canary Islands, Spain",
        countryCode: "ES",
    },
    {
        airportName: "Hierro",
        city: "Valverde",
        iata: "VDE",
        countryIata: "ESVDE",
        country: "Canary Islands, Spain",
        countryCode: "ES",
    },
    {
        airportName: "Rabil",
        city: "Boa Vista",
        iata: "BVC",
        countryIata: "CVBVC",
        country: "Cape Verde",
        countryCode: "CV",
    },
    {
        airportName: "Esperadinha",
        city: "Brava",
        iata: "BVR",
        countryIata: "CVBVR",
        country: "Cape Verde",
        countryCode: "CV",
    },
    {
        airportName: "Vila Do Maio",
        city: "Maio",
        iata: "MMO",
        countryIata: "CVMMO",
        country: "Cape Verde",
        countryCode: "CV",
    },
    {
        airportName: "Mosteiros",
        city: "Mosteiros",
        iata: "MTI",
        countryIata: "CVMTI",
        country: "Cape Verde",
        countryCode: "CV",
    },
    {
        airportName: "Santo Antao",
        city: "Santo Antao",
        iata: "NTO",
        countryIata: "CVNTO",
        country: "Cape Verde",
        countryCode: "CV",
    },
    {
        airportName: "Francisco Mendes Airport",
        city: "Praia",
        iata: "RAI",
        countryIata: "CVRAI",
        country: "Cape Verde",
        countryCode: "CV",
    },
    {
        airportName: "Sao Filipe",
        city: "Sao Filipe",
        iata: "SFL",
        countryIata: "CVSFL",
        country: "Cape Verde",
        countryCode: "CV",
    },
    {
        airportName: "Amilcar Cabral International",
        city: "Sal",
        iata: "SID",
        countryIata: "CVSID",
        country: "Cape Verde",
        countryCode: "CV",
    },
    {
        airportName: "Preguica",
        city: "Sao Nicolau",
        iata: "SNE",
        countryIata: "CVSNE",
        country: "Cape Verde",
        countryCode: "CV",
    },
    {
        airportName: "San Pedro",
        city: "Sao Vicente",
        iata: "VXE",
        countryIata: "CVVXE",
        country: "Cape Verde",
        countryCode: "CV",
    },
    {
        airportName: "Yalinga",
        city: "Yalinga",
        iata: "AIG",
        countryIata: "CFAIG",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Berberati",
        city: "Berberati",
        iata: "BBT",
        countryIata: "CFBBT",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Bambari",
        city: "Bambari",
        iata: "BBY",
        countryIata: "CFBBY",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Bouca",
        city: "Bouca",
        iata: "BCF",
        countryIata: "CFBCF",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Bossembele",
        city: "Bossembele",
        iata: "BEM",
        countryIata: "CFBEM",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Bangui",
        city: "Bangui",
        iata: "BGF",
        countryIata: "CFBGF",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Bangassou",
        city: "Bangassou",
        iata: "BGU",
        countryIata: "CFBGU",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Bria",
        city: "Bria",
        iata: "BIV",
        countryIata: "CFBIV",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Bakouma",
        city: "Bakouma",
        iata: "BMF",
        countryIata: "CFBMF",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Bouar",
        city: "Bouar",
        iata: "BOP",
        countryIata: "CFBOP",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Bozoum",
        city: "Bozoum",
        iata: "BOZ",
        countryIata: "CFBOZ",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Bossangoa",
        city: "Bossangoa",
        iata: "BSN",
        countryIata: "CFBSN",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Batangafo",
        city: "Batangafo",
        iata: "BTG",
        countryIata: "CFBTG",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Carnot",
        city: "Carnot",
        iata: "CRF",
        countryIata: "CFCRF",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Gounda",
        city: "Gounda",
        iata: "GDA",
        countryIata: "CFGDA",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Gordil",
        city: "Gordil",
        iata: "GDI",
        countryIata: "CFGDI",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Zemio",
        city: "Zemio",
        iata: "IMO",
        countryIata: "CFIMO",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Birao",
        city: "Birao",
        iata: "IRO",
        countryIata: "CFIRO",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Koumala",
        city: "Koumala",
        iata: "KOL",
        countryIata: "CFKOL",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Kawadjia",
        city: "Kawadjia",
        iata: "KWD",
        countryIata: "CFKWD",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "M'Boki",
        city: "M'Boki",
        iata: "MKI",
        countryIata: "CFMKI",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Ndele",
        city: "Ndele",
        iata: "NDL",
        countryIata: "CFNDL",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Ouadda",
        city: "Ouadda",
        iata: "ODA",
        countryIata: "CFODA",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Ouanda Djalle",
        city: "Ouanda Djalle",
        iata: "ODJ",
        countryIata: "CFODJ",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Rafai",
        city: "Rafai",
        iata: "RFA",
        countryIata: "CFRFA",
        country: "Central African Republic",
        countryCode: "CF",
    },
    {
        airportName: "Abecher",
        city: "Abecher",
        iata: "AEH",
        countryIata: "TDAEH",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Zakouma",
        city: "Zakouma",
        iata: "AKM",
        countryIata: "TDAKM",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Am Timan",
        city: "Am Timan",
        iata: "AMC",
        countryIata: "TDAMC",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Mao",
        city: "Mao",
        iata: "AMO",
        countryIata: "TDAMO",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Abou Deia",
        city: "Abou Deia",
        iata: "AOD",
        countryIata: "TDAOD",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Ati",
        city: "Ati",
        iata: "ATV",
        countryIata: "TDATV",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Bokoro",
        city: "Bokoro",
        iata: "BKR",
        countryIata: "TDBKR",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Faya",
        city: "Faya",
        iata: "FYT",
        countryIata: "TDFYT",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Lai",
        city: "Lai",
        iata: "LTC",
        countryIata: "TDLTC",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Melfi",
        city: "Melfi",
        iata: "MEF",
        countryIata: "TDMEF",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Moundou",
        city: "Moundou",
        iata: "MQQ",
        countryIata: "TDMQQ",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Mongo",
        city: "Mongo",
        iata: "MVO",
        countryIata: "TDMVO",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Ndjamena",
        city: "Ndjamena",
        iata: "NDJ",
        countryIata: "TDNDJ",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Bongor",
        city: "Bongor",
        iata: "OGR",
        countryIata: "TDOGR",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Bol",
        city: "Bol",
        iata: "OTC",
        countryIata: "TDOTC",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Oum Hadjer",
        city: "Oum Hadjer",
        iata: "OUM",
        countryIata: "TDOUM",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Bousso",
        city: "Bousso",
        iata: "OUT",
        countryIata: "TDOUT",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Pala",
        city: "Pala",
        iata: "PLF",
        countryIata: "TDPLF",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Sarh",
        city: "Sarh",
        iata: "SRH",
        countryIata: "TDSRH",
        country: "Chad",
        countryCode: "TD",
    },
    {
        airportName: "Cerro Moreno International Airport",
        city: "Antofagasta",
        iata: "ANF",
        countryIata: "CLANF",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Chacalluta International Airport",
        city: "Arica",
        iata: "ARI",
        countryIata: "CLARI",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Teniente Vidal",
        city: "Balmaceda",
        iata: "BBA",
        countryIata: "CLBBA",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Chile Chico",
        city: "Chile Chico",
        iata: "CCH",
        countryIata: "CLCCH",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Carriel Sur",
        city: "Concepcion",
        iata: "CCP",
        countryIata: "CLCCP",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "El Loa",
        city: "Calama",
        iata: "CJC",
        countryIata: "CLCJC",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Chanaral",
        city: "Chanaral",
        iata: "CNR",
        countryIata: "CLCNR",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Coquimbo",
        city: "Coquimbo",
        iata: "COW",
        countryIata: "CLCOW",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Chamonate",
        city: "Copiapo",
        iata: "CPO",
        countryIata: "CLCPO",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "El Salvador",
        city: "El Salvador",
        iata: "ESR",
        countryIata: "CLESR",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Futaleufu",
        city: "Futaleufu",
        iata: "FFU",
        countryIata: "CLFFU",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Frutillar",
        city: "Frutillar",
        iata: "FRT",
        countryIata: "CLFRT",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Teniente Vidal",
        city: "Coyhaique (Balmaceda)",
        iata: "GXQ",
        countryIata: "CLGXQ",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Mataveri International",
        city: "Easter Island",
        iata: "IPC",
        countryIata: "CLIPC",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Cavancha",
        city: "Iquique",
        iata: "IQQ",
        countryIata: "CLIQQ",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Vina del Mar",
        city: "Vina del Mar",
        iata: "KNA",
        countryIata: "CLKNA",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Cochrane",
        city: "Cochrane",
        iata: "LGR",
        countryIata: "CLLGR",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Los Andes",
        city: "Los Andes",
        iata: "LOB",
        countryIata: "CLLOB",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "La Florida",
        city: "La Serena",
        iata: "LSC",
        countryIata: "CLLSC",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Los Angeles",
        city: "Los Angeles",
        iata: "LSQ",
        countryIata: "CLLSQ",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Ovalle",
        city: "Ovalle",
        iata: "OVL",
        countryIata: "CLOVL",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Tepual",
        city: "Puerto Montt",
        iata: "PMC",
        countryIata: "CLPMC",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Teniente J. Gallardo",
        city: "Puerto Natales",
        iata: "PNT",
        countryIata: "CLPNT",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Pres Ibanez",
        city: "Punta Arenas",
        iata: "PUQ",
        countryIata: "CLPUQ",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Puerto Varas",
        city: "Puerto Varas",
        iata: "PUX",
        countryIata: "CLPUX",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Arturo Merino Benitez",
        city: "Santiago",
        iata: "SCL",
        countryIata: "CLSCL",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Cerro Sombrero",
        city: "Cerro Sombrero",
        iata: "SMB",
        countryIata: "CLSMB",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Talca",
        city: "Talca",
        iata: "TLX",
        countryIata: "CLTLX",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Barriles",
        city: "Tocopilla",
        iata: "TOQ",
        countryIata: "CLTOQ",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Taltal",
        city: "Taltal",
        iata: "TTC",
        countryIata: "CLTTC",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Los Cerrillos",
        city: "Santiago",
        iata: "ULC",
        countryIata: "CLULC",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Valparaiso",
        city: "Valparaiso",
        iata: "VAP",
        countryIata: "CLVAP",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Vallenar",
        city: "Vallenar",
        iata: "VLR",
        countryIata: "CLVLR",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Alto Palena",
        city: "Alto Palena",
        iata: "WAP",
        countryIata: "CLWAP",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Gamboa",
        city: "Castro",
        iata: "WCA",
        countryIata: "CLWCA",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Chaiten",
        city: "Chaiten",
        iata: "WCH",
        countryIata: "CLWCH",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Puerto Aisen",
        city: "Puerto Aisen",
        iata: "WPA",
        countryIata: "CLWPA",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Porvenir",
        city: "Porvenir",
        iata: "WPR",
        countryIata: "CLWPR",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Puerto Williams",
        city: "Puerto Williams",
        iata: "WPU",
        countryIata: "CLWPU",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Chillan",
        city: "Chillan",
        iata: "YAI",
        countryIata: "CLYAI",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Pichoy",
        city: "Valdivia",
        iata: "ZAL",
        countryIata: "CLZAL",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Maquehue",
        city: "Temuco",
        iata: "ZCO",
        countryIata: "CLZCO",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Victoria",
        city: "Victoria",
        iata: "ZIC",
        countryIata: "CLZIC",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Canal Balo",
        city: "Osorno",
        iata: "ZOS",
        countryIata: "CLZOS",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Pucon",
        city: "Pucon",
        iata: "ZPC",
        countryIata: "CLZPC",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Ancud",
        city: "Ancud",
        iata: "ZUD",
        countryIata: "CLZUD",
        country: "Chile",
        countryCode: "CL",
    },
    {
        airportName: "Christmas Island",
        city: "Christmas Island",
        iata: "XCH",
        countryIata: "CXXCH",
        country: "Christmas Island",
        countryCode: "CX",
    },
    {
        airportName: "Acandi",
        city: "Acandi",
        iata: "ACD",
        countryIata: "COACD",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Aguaclara",
        city: "Aguaclara",
        iata: "ACL",
        countryIata: "COACL",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Arica",
        city: "Arica",
        iata: "ACM",
        countryIata: "COACM",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Araracuara",
        city: "Araracuara",
        iata: "ACR",
        countryIata: "COACR",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Andes",
        city: "Andes",
        iata: "ADN",
        countryIata: "COADN",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "San Andrés Airport",
        city: "San Andres Island",
        iata: "ADZ",
        countryIata: "COADZ",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Amalfi",
        city: "Amalfi",
        iata: "AFI",
        countryIata: "COAFI",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Apiay",
        city: "Apiay",
        iata: "API",
        countryIata: "COAPI",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Apartado",
        city: "Apartado",
        iata: "APO",
        countryIata: "COAPO",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Acaricuara",
        city: "Acaricuara",
        iata: "ARF",
        countryIata: "COARF",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Arboletas",
        city: "Arboletas",
        iata: "ARO",
        countryIata: "COARO",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Arauquita",
        city: "Arauquita",
        iata: "ARQ",
        countryIata: "COARQ",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Arauca",
        city: "Arauca",
        iata: "AUC",
        countryIata: "COAUC",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "El Eden",
        city: "Armenia",
        iata: "AXM",
        countryIata: "COAXM",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Ayapel",
        city: "Ayapel",
        iata: "AYA",
        countryIata: "COAYA",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Ayacucho",
        city: "Ayacucho",
        iata: "AYC",
        countryIata: "COAYC",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Yaguara",
        city: "Yaguara",
        iata: "AYG",
        countryIata: "COAYG",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Yari",
        city: "Yari",
        iata: "AYI",
        countryIata: "COAYI",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Zapatoca",
        city: "Zapatoca",
        iata: "AZT",
        countryIata: "COAZT",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Barranca De Upia",
        city: "Barranca De Upia",
        iata: "BAC",
        countryIata: "COBAC",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "E Cortissoz",
        city: "Barranquilla",
        iata: "BAQ",
        countryIata: "COBAQ",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Palo Negro",
        city: "Bucaramanga",
        iata: "BGA",
        countryIata: "COBGA",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Bahia Cupica",
        city: "Bahia Cupica",
        iata: "BHF",
        countryIata: "COBHF",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Eldorado",
        city: "Bogota",
        iata: "BOG",
        countryIata: "COBOG",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Bahia Solano",
        city: "Bahia Solano",
        iata: "BSC",
        countryIata: "COBSC",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Buenaventura",
        city: "Buenaventura",
        iata: "BUN",
        countryIata: "COBUN",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Caucasia",
        city: "Caucasia",
        iata: "CAQ",
        countryIata: "COCAQ",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Carimagua",
        city: "Carimagua",
        iata: "CCO",
        countryIata: "COCCO",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Cimitarra",
        city: "Cimitarra",
        iata: "CIM",
        countryIata: "COCIM",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Candilejas",
        city: "Candilejas",
        iata: "CJD",
        countryIata: "COCJD",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Alfonso B. Aragon",
        city: "Cali",
        iata: "CLO",
        countryIata: "COCLO",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Mandinga",
        city: "Condoto",
        iata: "COG",
        countryIata: "COCOG",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Capurgana",
        city: "Capurgana",
        iata: "CPB",
        countryIata: "COCPB",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Chaparral",
        city: "Chaparral",
        iata: "CPL",
        countryIata: "COCPL",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Caquetania",
        city: "Caquetania",
        iata: "CQT",
        countryIata: "COCQT",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Cartago",
        city: "Cartago",
        iata: "CRC",
        countryIata: "COCRC",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Casuarito",
        city: "Casuarito",
        iata: "CSR",
        countryIata: "COCSR",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Rafael Núñez International Airport",
        city: "Cartagena",
        iata: "CTG",
        countryIata: "COCTG",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Camilo Dazo",
        city: "Cucuta",
        iata: "CUC",
        countryIata: "COCUC",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Currillo",
        city: "Currillo",
        iata: "CUI",
        countryIata: "COCUI",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Caruru",
        city: "Caruru",
        iata: "CUO",
        countryIata: "COCUO",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Covenas",
        city: "Covenas",
        iata: "CVE",
        countryIata: "COCVE",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Corozal",
        city: "Corozal",
        iata: "CZU",
        countryIata: "COCZU",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Codazzi",
        city: "Codazzi",
        iata: "DZI",
        countryIata: "CODZI",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "El Bagre",
        city: "El Bagre",
        iata: "EBG",
        countryIata: "COEBG",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "El Encanto",
        city: "El Encanto",
        iata: "ECO",
        countryIata: "COECO",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "El Charco",
        city: "El Charco",
        iata: "ECR",
        countryIata: "COECR",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Variguies",
        city: "Barrancabermeja",
        iata: "EJA",
        countryIata: "COEJA",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "San Bernado",
        city: "El Banco",
        iata: "ELB",
        countryIata: "COELB",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "El Recreo",
        city: "El Recreo",
        iata: "ELJ",
        countryIata: "COELJ",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Enrique Olaya Herrera",
        city: "Medellin",
        iata: "EOH",
        countryIata: "COEOH",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Paratebueno",
        city: "Paratebueno",
        iata: "EUO",
        countryIata: "COEUO",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "El Yopal",
        city: "El Yopal",
        iata: "EYP",
        countryIata: "COEYP",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Capitolio",
        city: "Florencia",
        iata: "FLA",
        countryIata: "COFLA",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Guamal",
        city: "Guamal",
        iata: "GAA",
        countryIata: "COGAA",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Guacamaya",
        city: "Guacamaya",
        iata: "GCA",
        countryIata: "COGCA",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Gilgal",
        city: "Gilgal",
        iata: "GGL",
        countryIata: "COGGL",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Girardot",
        city: "Girardot",
        iata: "GIR",
        countryIata: "COGIR",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Guerima",
        city: "Guerima",
        iata: "GMC",
        countryIata: "COGMC",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Guapi",
        city: "Guapi",
        iata: "GPI",
        countryIata: "COGPI",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Gamarra",
        city: "Gamarra",
        iata: "GRA",
        countryIata: "COGRA",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "La Herrera Airport",
        city: "Herrera",
        iata: "HRR",
        countryIata: "COHRR",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Hato Corozal",
        city: "Hato Corozal",
        iata: "HTZ",
        countryIata: "COHTZ",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Ibague",
        city: "Ibague",
        iata: "IBE",
        countryIata: "COIBE",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Chigorodo",
        city: "Chigorodo",
        iata: "IGO",
        countryIata: "COIGO",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "San Luis",
        city: "Ipiales",
        iata: "IPI",
        countryIata: "COIPI",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Iscuande",
        city: "Iscuande",
        iata: "ISD",
        countryIata: "COISD",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Chivolo",
        city: "Chivolo",
        iata: "IVO",
        countryIata: "COIVO",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Jurado",
        city: "Jurado",
        iata: "JUO",
        countryIata: "COJUO",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "La Uribe",
        city: "La Uribe",
        iata: "LAT",
        countryIata: "COLAT",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "La Chorrera",
        city: "La Chorrera",
        iata: "LCR",
        countryIata: "COLCR",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Gen. A.V. Cobo",
        city: "Leticia",
        iata: "LET",
        countryIata: "COLET",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Las Gaviotas",
        city: "Las Gaviotas",
        iata: "LGT",
        countryIata: "COLGT",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Leguizamo",
        city: "Leguizamo",
        iata: "LGZ",
        countryIata: "COLGZ",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Lamacarena",
        city: "Lamacarena",
        iata: "LMC",
        countryIata: "COLMC",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Lopez De Micay",
        city: "Lopez De Micay",
        iata: "LMX",
        countryIata: "COLMX",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "La Pedrera",
        city: "La Pedrera",
        iata: "LPD",
        countryIata: "COLPD",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "La Primavera",
        city: "La Primavera",
        iata: "LPE",
        countryIata: "COLPE",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Puerto Leguizamo",
        city: "Puerto Leguizamo",
        iata: "LQM",
        countryIata: "COLQM",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Lorica",
        city: "Lorica",
        iata: "LRI",
        countryIata: "COLRI",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Maicao",
        city: "Maicao",
        iata: "MCJ",
        countryIata: "COMCJ",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Jose Marie Cordova",
        city: "Medellin",
        iata: "MDE",
        countryIata: "COMDE",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Monfort",
        city: "Monfort",
        iata: "MFB",
        countryIata: "COMFB",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Miraflores",
        city: "Miraflores",
        iata: "MFS",
        countryIata: "COMFS",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Baracoa",
        city: "Magangue",
        iata: "MGN",
        countryIata: "COMGN",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Morichal",
        city: "Morichal",
        iata: "MHF",
        countryIata: "COMHF",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Miriti",
        city: "Miriti",
        iata: "MIX",
        countryIata: "COMIX",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Mompos",
        city: "Mompos",
        iata: "MMP",
        countryIata: "COMMP",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Medina",
        city: "Medina",
        iata: "MND",
        countryIata: "COMND",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Monterrey",
        city: "Monterrey",
        iata: "MOY",
        countryIata: "COMOY",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Mosquera",
        city: "Mosquera",
        iata: "MQR",
        countryIata: "COMQR",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Mariquita",
        city: "Mariquita",
        iata: "MQU",
        countryIata: "COMQU",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Monte Libano",
        city: "Monte Libano",
        iata: "MTB",
        countryIata: "COMTB",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "S. Jeronimo",
        city: "Monteria",
        iata: "MTR",
        countryIata: "COMTR",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Mitu",
        city: "Mitu",
        iata: "MVP",
        countryIata: "COMVP",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Santaguida",
        city: "Manizales",
        iata: "MZL",
        countryIata: "COMZL",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Macanal",
        city: "Macanal",
        iata: "NAD",
        countryIata: "CONAD",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Nare",
        city: "Nare",
        iata: "NAR",
        countryIata: "CONAR",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Barrancominas",
        city: "Barrancominas",
        iata: "NBB",
        countryIata: "CONBB",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Necocli",
        city: "Necocli",
        iata: "NCI",
        countryIata: "CONCI",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "San Pedro Uraba",
        city: "San Pedro Uraba",
        iata: "NPU",
        countryIata: "CONPU",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Nuqui",
        city: "Nuqui",
        iata: "NQU",
        countryIata: "CONQU",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Nunchia",
        city: "Nunchia",
        iata: "NUH",
        countryIata: "CONUH",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "La Marguita",
        city: "Neiva",
        iata: "NVA",
        countryIata: "CONVA",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Aguasclaras",
        city: "Ocana",
        iata: "OCV",
        countryIata: "COOCV",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Orocue",
        city: "Orocue",
        iata: "ORC",
        countryIata: "COORC",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Otu",
        city: "Otu",
        iata: "OTU",
        countryIata: "COOTU",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Palanquero",
        city: "Palanquero",
        iata: "PAL",
        countryIata: "COPAL",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Puerto Berrio",
        city: "Puerto Berrio",
        iata: "PBE",
        countryIata: "COPBE",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Puerto Rico",
        city: "Puerto Rico",
        iata: "PCC",
        countryIata: "COPCC",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Puerto Carreno",
        city: "Puerto Carreno",
        iata: "PCR",
        countryIata: "COPCR",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Puerto Inirida",
        city: "Puerto Inirida",
        iata: "PDA",
        countryIata: "COPDA",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Matecana",
        city: "Pereira",
        iata: "PEI",
        countryIata: "COPEI",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Planadas",
        city: "Planadas",
        iata: "PLA",
        countryIata: "COPLA",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Planeta Rica",
        city: "Planeta Rica",
        iata: "PLC",
        countryIata: "COPLC",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Plato",
        city: "Plato",
        iata: "PLT",
        countryIata: "COPLT",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Machangara",
        city: "Popayan",
        iata: "PPN",
        countryIata: "COPPN",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Pore",
        city: "Pore",
        iata: "PRE",
        countryIata: "COPRE",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Cano",
        city: "Pasto",
        iata: "PSO",
        countryIata: "COPSO",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Pitalito",
        city: "Pitalito",
        iata: "PTX",
        countryIata: "COPTX",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Puerto Asis",
        city: "Puerto Asis",
        iata: "PUU",
        countryIata: "COPUU",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Providencia",
        city: "Providencia",
        iata: "PVA",
        countryIata: "COPVA",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Puerto Boyaca",
        city: "Puerto Boyaca",
        iata: "PYA",
        countryIata: "COPYA",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Payan",
        city: "Payan",
        iata: "PYN",
        countryIata: "COPYN",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Casanare",
        city: "Paz De Ariporo",
        iata: "PZA",
        countryIata: "COPZA",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Cravo Norte",
        city: "Cravo Norte",
        iata: "RAV",
        countryIata: "CORAV",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Riohacha",
        city: "Riohacha",
        iata: "RCH",
        countryIata: "CORCH",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Rondon",
        city: "Rondon",
        iata: "RON",
        countryIata: "CORON",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Saravena",
        city: "Saravena",
        iata: "RVE",
        countryIata: "CORVE",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Santa Catalina",
        city: "Santa Catalina",
        iata: "SCA",
        countryIata: "COSCA",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "San Jose Del Gua",
        city: "San Jose Del Gua",
        iata: "SJE",
        countryIata: "COSJE",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "San Pedro Jagua",
        city: "San Pedro Jagua",
        iata: "SJG",
        countryIata: "COSJG",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "San Juan Del Cesar",
        city: "San Juan Del Cesar",
        iata: "SJH",
        countryIata: "COSJH",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "San Juan D Ur",
        city: "San Juan D Ur",
        iata: "SJR",
        countryIata: "COSJR",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Santa Maria",
        city: "Santa Maria",
        iata: "SMC",
        countryIata: "COSMC",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Simon Bolivar",
        city: "Santa Marta",
        iata: "SMR",
        countryIata: "COSMR",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Sabana De Torres",
        city: "Sabana De Torres",
        iata: "SNT",
        countryIata: "COSNT",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Solita",
        city: "Solita",
        iata: "SOH",
        countryIata: "COSOH",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Sogamoso",
        city: "Sogamoso",
        iata: "SOX",
        countryIata: "COSOX",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Santa Ana",
        city: "Santa Ana",
        iata: "SQB",
        countryIata: "COSQB",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "San Luis De Pale",
        city: "San Luis De Pale",
        iata: "SQE",
        countryIata: "COSQE",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Solano",
        city: "Solano",
        iata: "SQF",
        countryIata: "COSQF",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Santana Ramos",
        city: "Santana Ramos",
        iata: "SRO",
        countryIata: "COSRO",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "San Marcos",
        city: "San Marcos",
        iata: "SRS",
        countryIata: "COSRS",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "San Felipe",
        city: "San Felipe",
        iata: "SSD",
        countryIata: "COSSD",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Santa Rosalia",
        city: "Santa Rosalia",
        iata: "SSL",
        countryIata: "COSSL",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "San Vicente",
        city: "San Vicente",
        iata: "SVI",
        countryIata: "COSVI",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Tauramena",
        city: "Tauramena",
        iata: "TAU",
        countryIata: "COTAU",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Timbiqui",
        city: "Timbiqui",
        iata: "TBD",
        countryIata: "COTBD",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Tarapaca",
        city: "Tarapaca",
        iata: "TCD",
        countryIata: "COTCD",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "La Florida",
        city: "Tumaco",
        iata: "TCO",
        countryIata: "COTCO",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Trinidad",
        city: "Trinidad",
        iata: "TDA",
        countryIata: "COTDA",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Tibu",
        city: "Tibu",
        iata: "TIB",
        countryIata: "COTIB",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Tolu",
        city: "Tolu",
        iata: "TLU",
        countryIata: "COTLU",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Tame",
        city: "Tame",
        iata: "TME",
        countryIata: "COTME",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Tres Esquinas",
        city: "Tres Esquinas",
        iata: "TQS",
        countryIata: "COTQS",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Gonzalo",
        city: "Turbo",
        iata: "TRB",
        countryIata: "COTRB",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Tablon De Tamara",
        city: "Tablon De Tamara",
        iata: "TTM",
        countryIata: "COTTM",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "El Caraño Airport",
        city: "Quibdo",
        iata: "UIB",
        countryIata: "COUIB",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Farfan",
        city: "Tulua",
        iata: "ULQ",
        countryIata: "COULQ",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Mulatos",
        city: "Mulatos",
        iata: "ULS",
        countryIata: "COULS",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Unguia",
        city: "Unguia",
        iata: "UNC",
        countryIata: "COUNC",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Uribe",
        city: "Uribe",
        iata: "URI",
        countryIata: "COURI",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Urrao",
        city: "Urrao",
        iata: "URR",
        countryIata: "COURR",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Yavarate",
        city: "Yavarate",
        iata: "VAB",
        countryIata: "COVAB",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Villagarzon",
        city: "Villagarzon",
        iata: "VGZ",
        countryIata: "COVGZ",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Valledupar",
        city: "Valledupar",
        iata: "VUP",
        countryIata: "COVUP",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "La Vanguardia",
        city: "Villavicencio",
        iata: "VVC",
        countryIata: "COVVC",
        country: "Colombia",
        countryCode: "CO",
    },
    {
        airportName: "Basongo",
        city: "Basongo",
        iata: "BAN",
        countryIata: "CDBAN",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Gbadolite",
        city: "Gbadolite",
        iata: "BDT",
        countryIata: "CDBDT",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Moba",
        city: "Moba",
        iata: "BDV",
        countryIata: "CDBDV",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kamenbe",
        city: "Bukavu",
        iata: "BKY",
        countryIata: "CDBKY",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Bumba",
        city: "Bumba",
        iata: "BMB",
        countryIata: "CDBMB",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Boende",
        city: "Boende",
        iata: "BNB",
        countryIata: "CDBNB",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Beni",
        city: "Beni",
        iata: "BNC",
        countryIata: "CDBNC",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Boma",
        city: "Boma",
        iata: "BOA",
        countryIata: "CDBOA",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Basankusu",
        city: "Basankusu",
        iata: "BSU",
        countryIata: "CDBSU",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Bunia",
        city: "Bunia",
        iata: "BUX",
        countryIata: "CDBUX",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Buta",
        city: "Buta",
        iata: "BZU",
        countryIata: "CDBZU",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Dili",
        city: "Dili",
        iata: "DIC",
        countryIata: "CDDIC",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Luano",
        city: "Lubumbashi",
        iata: "FBM",
        countryIata: "CDFBM",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Bandundu",
        city: "Bandundu",
        iata: "FDU",
        countryIata: "CDFDU",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "N'Djili",
        city: "Kinshasa",
        iata: "FIH",
        countryIata: "CDFIH",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kisangani",
        city: "Kisangani",
        iata: "FKI",
        countryIata: "CDFKI",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kalemie",
        city: "Kalemie",
        iata: "FMI",
        countryIata: "CDFMI",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Gandajika",
        city: "Gandajika",
        iata: "GDJ",
        countryIata: "CDGDJ",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Gemena",
        city: "Gemena",
        iata: "GMA",
        countryIata: "CDGMA",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Goma",
        city: "Goma",
        iata: "GOM",
        countryIata: "CDGOM",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Idiofa",
        city: "Idiofa",
        iata: "IDF",
        countryIata: "CDIDF",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Ikela",
        city: "Ikela",
        iata: "IKL",
        countryIata: "CDIKL",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Inongo",
        city: "Inongo",
        iata: "INO",
        countryIata: "CDINO",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Matari",
        city: "Isiro",
        iata: "IRP",
        countryIata: "CDIRP",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kapanga",
        city: "Kapanga",
        iata: "KAP",
        countryIata: "CDKAP",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kabinda",
        city: "Kabinda",
        iata: "KBN",
        countryIata: "CDKBN",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kabalo",
        city: "Kabalo",
        iata: "KBO",
        countryIata: "CDKBO",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kasenga",
        city: "Kasenga",
        iata: "KEC",
        countryIata: "CDKEC",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kananga",
        city: "Kananga",
        iata: "KGA",
        countryIata: "CDKGA",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kasongo Lunda",
        city: "Kasongo Lunda",
        iata: "KGN",
        countryIata: "CDKGN",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kilwa",
        city: "Kilwa",
        iata: "KIL",
        countryIata: "CDKIL",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kikwit",
        city: "Kikwit",
        iata: "KKW",
        countryIata: "CDKKW",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kota Koli",
        city: "Kota Koli",
        iata: "KLI",
        countryIata: "CDKLI",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kalima",
        city: "Kalima",
        iata: "KLY",
        countryIata: "CDKLY",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kamina",
        city: "Kamina",
        iata: "KMN",
        countryIata: "CDKMN",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kindu",
        city: "Kindu",
        iata: "KND",
        countryIata: "CDKND",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kaniama",
        city: "Kaniama",
        iata: "KNM",
        countryIata: "CDKNM",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kongolo",
        city: "Kongolo",
        iata: "KOO",
        countryIata: "CDKOO",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kiri",
        city: "Kiri",
        iata: "KRZ",
        countryIata: "CDKRZ",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Kolwezi",
        city: "Kolwezi",
        iata: "KWZ",
        countryIata: "CDKWZ",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Lusambo",
        city: "Lusambo",
        iata: "LBO",
        countryIata: "CDLBO",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Libenge",
        city: "Libenge",
        iata: "LIE",
        countryIata: "CDLIE",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Lisala",
        city: "Lisala",
        iata: "LIQ",
        countryIata: "CDLIQ",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Lodja",
        city: "Lodja",
        iata: "LJA",
        countryIata: "CDLJA",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Lusanga",
        city: "Lusanga",
        iata: "LUS",
        countryIata: "CDLUS",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Luiza",
        city: "Luiza",
        iata: "LZA",
        countryIata: "CDLZA",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Luozi",
        city: "Luozi",
        iata: "LZI",
        countryIata: "CDLZI",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Matadi",
        city: "Matadi",
        iata: "MAT",
        countryIata: "CDMAT",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Mbandaka",
        city: "Mbandaka",
        iata: "MDK",
        countryIata: "CDMDK",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Mweka",
        city: "Mweka",
        iata: "MEW",
        countryIata: "CDMEW",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Mbuji Mayi",
        city: "Mbuji Mayi",
        iata: "MJM",
        countryIata: "CDMJM",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Moanda",
        city: "Moanda",
        iata: "MNB",
        countryIata: "CDMNB",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Manono",
        city: "Manono",
        iata: "MNO",
        countryIata: "CDMNO",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Masi Manimba",
        city: "Masi Manimba",
        iata: "MSM",
        countryIata: "CDMSM",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Nioki",
        city: "Nioki",
        iata: "NIO",
        countryIata: "CDNIO",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Nkolo",
        city: "Nkolo",
        iata: "NKL",
        countryIata: "CDNKL",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "N'Dolo Airport",
        city: "Kinshasa",
        iata: "NLO",
        countryIata: "CDNLO",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Ilebo",
        city: "Ilebo",
        iata: "PFR",
        countryIata: "CDPFR",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Punia",
        city: "Punia",
        iata: "PUN",
        countryIata: "CDPUN",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Pweto",
        city: "Pweto",
        iata: "PWO",
        countryIata: "CDPWO",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Tshikapa",
        city: "Tshikapa",
        iata: "TSH",
        countryIata: "CDTSH",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Yangambi",
        city: "Yangambi",
        iata: "YAN",
        countryIata: "CDYAN",
        country: "Congo (DRC)",
        countryCode: "CD",
    },
    {
        airportName: "Zanaga",
        city: "Zanaga",
        iata: "ANJ",
        countryIata: "CGANJ",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Boundji",
        city: "Boundji",
        iata: "BOE",
        countryIata: "CGBOE",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Betou",
        city: "Betou",
        iata: "BTB",
        countryIata: "CGBTB",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Maya Maya",
        city: "Brazzaville",
        iata: "BZV",
        countryIata: "CGBZV",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Loubomo",
        city: "Loubomo",
        iata: "DIS",
        countryIata: "CGDIS",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Djambala",
        city: "Djambala",
        iata: "DJM",
        countryIata: "CGDJM",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Epena",
        city: "Epena",
        iata: "EPN",
        countryIata: "CGEPN",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Ewo",
        city: "Ewo",
        iata: "EWO",
        countryIata: "CGEWO",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Owando",
        city: "Owando",
        iata: "FTX",
        countryIata: "CGFTX",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Gamboma",
        city: "Gamboma",
        iata: "GMM",
        countryIata: "CGGMM",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Impfondo",
        city: "Impfondo",
        iata: "ION",
        countryIata: "CGION",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Kelle",
        city: "Kelle",
        iata: "KEE",
        countryIata: "CGKEE",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Makabana",
        city: "Makabana",
        iata: "KMK",
        countryIata: "CGKMK",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Kindamba",
        city: "Kindamba",
        iata: "KNJ",
        countryIata: "CGKNJ",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Lague",
        city: "Lague",
        iata: "LCO",
        countryIata: "CGLCO",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Lekana",
        city: "Lekana",
        iata: "LKC",
        countryIata: "CGLKC",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Makoua",
        city: "Makoua",
        iata: "MKJ",
        countryIata: "CGMKJ",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Mossendjo",
        city: "Mossendjo",
        iata: "MSX",
        countryIata: "CGMSX",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Mouyondzi",
        city: "Mouyondzi",
        iata: "MUY",
        countryIata: "CGMUY",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Nkayi",
        city: "Nkayi",
        iata: "NKY",
        countryIata: "CGNKY",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Okoyo",
        city: "Okoyo",
        iata: "OKG",
        countryIata: "CGOKG",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Ouesso",
        city: "Ouesso",
        iata: "OUE",
        countryIata: "CGOUE",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Pointe Noire",
        city: "Pointe Noire",
        iata: "PNR",
        countryIata: "CGPNR",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Sibiti",
        city: "Sibiti",
        iata: "SIB",
        countryIata: "CGSIB",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Souanke",
        city: "Souanke",
        iata: "SOE",
        countryIata: "CGSOE",
        country: "Congo (ROC)",
        countryCode: "CG",
    },
    {
        airportName: "Aitutaki",
        city: "Aitutaki",
        iata: "AIT",
        countryIata: "CKAIT",
        country: "Cook Islands",
        countryCode: "CK",
    },
    {
        airportName: "Atiu Island",
        city: "Atiu Island",
        iata: "AIU",
        countryIata: "CKAIU",
        country: "Cook Islands",
        countryCode: "CK",
    },
    {
        airportName: "Mangaia Island",
        city: "Mangaia Island",
        iata: "MGS",
        countryIata: "CKMGS",
        country: "Cook Islands",
        countryCode: "CK",
    },
    {
        airportName: "Manihiki Island",
        city: "Manihiki Island",
        iata: "MHX",
        countryIata: "CKMHX",
        country: "Cook Islands",
        countryCode: "CK",
    },
    {
        airportName: "Mitiaro Island",
        city: "Mitiaro Island",
        iata: "MOI",
        countryIata: "CKMOI",
        country: "Cook Islands",
        countryCode: "CK",
    },
    {
        airportName: "Mauke Island",
        city: "Mauke Island",
        iata: "MUK",
        countryIata: "CKMUK",
        country: "Cook Islands",
        countryCode: "CK",
    },
    {
        airportName: "Penrhyn Island",
        city: "Penrhyn Island",
        iata: "PYE",
        countryIata: "CKPYE",
        country: "Cook Islands",
        countryCode: "CK",
    },
    {
        airportName: "Puka Puka Island/Attol",
        city: "Puka Puka Island",
        iata: "PZK",
        countryIata: "CKPZK",
        country: "Cook Islands",
        countryCode: "CK",
    },
    {
        airportName: "Rarotonga",
        city: "Rarotonga",
        iata: "RAR",
        countryIata: "CKRAR",
        country: "Cook Islands",
        countryCode: "CK",
    },
    {
        airportName: "Buenos Aires",
        city: "Buenos Aires",
        iata: "BAI",
        countryIata: "CRBAI",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Barra Colorado",
        city: "Barra Colorado",
        iata: "BCL",
        countryIata: "CRBCL",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Canas",
        city: "Canas",
        iata: "CSC",
        countryIata: "CRCSC",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Flamingo",
        city: "Flamingo",
        iata: "FMG",
        countryIata: "CRFMG",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Fortuna",
        city: "Fortuna",
        iata: "FON",
        countryIata: "CRFON",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Golfito",
        city: "Golfito",
        iata: "GLF",
        countryIata: "CRGLF",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Guapiles",
        city: "Guapiles",
        iata: "GPL",
        countryIata: "CRGPL",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Punta Renes",
        city: "Punta Renes",
        iata: "JAP",
        countryIata: "CRJAP",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Las Canas",
        city: "Las Canas",
        iata: "LCS",
        countryIata: "CRLCS",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Limon",
        city: "Limon",
        iata: "LIO",
        countryIata: "CRLIO",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Liberia",
        city: "Liberia",
        iata: "LIR",
        countryIata: "CRLIR",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Los Chiles",
        city: "Los Chiles",
        iata: "LSL",
        countryIata: "CRLSL",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Guanacaste",
        city: "Nicoya",
        iata: "NCT",
        countryIata: "CRNCT",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Nosara Beach",
        city: "Nosara Beach",
        iata: "NOB",
        countryIata: "CRNOB",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Coto 47",
        city: "Coto 47",
        iata: "OTR",
        countryIata: "CROTR",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Punta Islita",
        city: "Punta Islita",
        iata: "PBP",
        countryIata: "CRPBP",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Puerto Jimenez",
        city: "Puerto Jimenez",
        iata: "PJM",
        countryIata: "CRPJM",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Playa Samara",
        city: "Playa Samara",
        iata: "PLD",
        countryIata: "CRPLD",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Palmar Sur",
        city: "Palmar",
        iata: "PMZ",
        countryIata: "CRPMZ",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Rio Frio",
        city: "Rio Frio",
        iata: "RFR",
        countryIata: "CRRFR",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Carrillo",
        city: "Carrillo",
        iata: "RIK",
        countryIata: "CRRIK",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Juan Santamaria International",
        city: "San Jose",
        iata: "SJO",
        countryIata: "CRSJO",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Tobias Bolanos International",
        city: "San Jose",
        iata: "SYQ",
        countryIata: "CRSYQ",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Guanacaste",
        city: "Santa Cruz",
        iata: "SZC",
        countryIata: "CRSZC",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Tambor",
        city: "Tambor",
        iata: "TMU",
        countryIata: "CRTMU",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Tamarindo",
        city: "Tamarindo",
        iata: "TNO",
        countryIata: "CRTNO",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "San Vito",
        city: "San Vito",
        iata: "TOO",
        countryIata: "CRTOO",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Tortuquero",
        city: "Tortuquero",
        iata: "TTQ",
        countryIata: "CRTTQ",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Upala",
        city: "Upala",
        iata: "UPL",
        countryIata: "CRUPL",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Quepos",
        city: "Quepos",
        iata: "XQP",
        countryIata: "CRXQP",
        country: "Costa Rica",
        countryCode: "CR",
    },
    {
        airportName: "Heraklion Intl “Nikos Kazantzakis” (Crete)",
        city: "Heraklion",
        iata: "HER",
        countryIata: "GRHER",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Sitia Airport (Crete)",
        city: "Sitia (Siteia)",
        iata: "JSH",
        countryIata: "GRJSH",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Bol",
        city: "Bol",
        iata: "BWK",
        countryIata: "HRBWK",
        country: "Croatia",
        countryCode: "HR",
    },
    {
        airportName: "Dubrovnik",
        city: "Dubrovnik",
        iata: "DBV",
        countryIata: "HRDBV",
        country: "Croatia",
        countryCode: "HR",
    },
    {
        airportName: "Losinj Airport",
        city: "Mali Losinj",
        iata: "LSZ",
        countryIata: "HRLSZ",
        country: "Croatia",
        countryCode: "HR",
    },
    {
        airportName: "Osijek",
        city: "Osijek",
        iata: "OSI",
        countryIata: "HROSI",
        country: "Croatia",
        countryCode: "HR",
    },
    {
        airportName: "Pula",
        city: "Pula",
        iata: "PUY",
        countryIata: "HRPUY",
        country: "Croatia",
        countryCode: "HR",
    },
    {
        airportName: "Rijeka",
        city: "Rijeka",
        iata: "RJK",
        countryIata: "HRRJK",
        country: "Croatia",
        countryCode: "HR",
    },
    {
        airportName: "Split",
        city: "Split",
        iata: "SPU",
        countryIata: "HRSPU",
        country: "Croatia",
        countryCode: "HR",
    },
    {
        airportName: "Zadar",
        city: "Zadar",
        iata: "ZAD",
        countryIata: "HRZAD",
        country: "Croatia",
        countryCode: "HR",
    },
    {
        airportName: "Pleso",
        city: "Zagreb",
        iata: "ZAG",
        countryIata: "HRZAG",
        country: "Croatia",
        countryCode: "HR",
    },
    {
        airportName: "Maximo Gomez",
        city: "Ciego De Avila",
        iata: "AVI",
        countryIata: "CUAVI",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Baracoa",
        city: "Baracoa",
        iata: "BCA",
        countryIata: "CUBCA",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "C.M. de Cespedes",
        city: "Bayamo",
        iata: "BYM",
        countryIata: "CUBYM",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Cayo Coco",
        city: "Cayo Coco",
        iata: "CCC",
        countryIata: "CUCCC",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Jaime González Airport",
        city: "Cienfuegos",
        iata: "CFG",
        countryIata: "CUCFG",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Ign Agramonte International",
        city: "Camaguey",
        iata: "CMW",
        countryIata: "CUCMW",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Cayo Largo Del Sur",
        city: "Cayo Largo Del Sur",
        iata: "CYO",
        countryIata: "CUCYO",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Los Canos",
        city: "Guantanamo",
        iata: "GAO",
        countryIata: "CUGAO",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Rafael Cabrera",
        city: "Nueva Gerona",
        iata: "GER",
        countryIata: "CUGER",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Jose Marti International",
        city: "Havana",
        iata: "HAV",
        countryIata: "CUHAV",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Frank Pais",
        city: "Holguin",
        iata: "HOG",
        countryIata: "CUHOG",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Nicaro",
        city: "Nicaro",
        iata: "ICR",
        countryIata: "CUICR",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "La Coloma",
        city: "La Coloma",
        iata: "LCL",
        countryIata: "CULCL",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Mayajigua",
        city: "Mayajigua",
        iata: "MJG",
        countryIata: "CUMJG",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Orestes Acosta",
        city: "Moa",
        iata: "MOA",
        countryIata: "CUMOA",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Sierra Maestra",
        city: "Manzanillo",
        iata: "MZO",
        countryIata: "CUMZO",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Guantanamo NAS",
        city: "Guantanamo",
        iata: "NBW",
        countryIata: "CUNBW",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Preston",
        city: "Preston",
        iata: "PST",
        countryIata: "CUPST",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Antonio Maceo",
        city: "Santiago",
        iata: "SCU",
        countryIata: "CUSCU",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "San Julian",
        city: "San Julian",
        iata: "SNJ",
        countryIata: "CUSNJ",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Santa Clara",
        city: "Santa Clara",
        iata: "SNU",
        countryIata: "CUSNU",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Siguanea",
        city: "Siguanea",
        iata: "SZJ",
        countryIata: "CUSZJ",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Trinidad",
        city: "Trinidad",
        iata: "TND",
        countryIata: "CUTND",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Punta De Maisi",
        city: "Punta De Maisi",
        iata: "UMA",
        countryIata: "CUUMA",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Punta Alegre",
        city: "Punta Alegre",
        iata: "UPA",
        countryIata: "CUUPA",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Sancti Spiritus",
        city: "Sancti Spiritus",
        iata: "USS",
        countryIata: "CUUSS",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Juan Gualberto Gomez",
        city: "Varadero",
        iata: "VRA",
        countryIata: "CUVRA",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Kawama",
        city: "Matanzas",
        iata: "VRO",
        countryIata: "CUVRO",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Las Tunas",
        city: "Las Tunas",
        iata: "VTU",
        countryIata: "CUVTU",
        country: "Cuba",
        countryCode: "CU",
    },
    {
        airportName: "Akrotiri RAF",
        city: "Akrotiri",
        iata: "AKT",
        countryIata: "CYAKT",
        country: "Cyprus",
        countryCode: "CY",
    },
    {
        airportName: "Ercan",
        city: "Ercan",
        iata: "ECN",
        countryIata: "CYECN",
        country: "Cyprus",
        countryCode: "CY",
    },
    {
        airportName: "Episkopi",
        city: "Episkopi",
        iata: "EPK",
        countryIata: "CYEPK",
        country: "Cyprus",
        countryCode: "CY",
    },
    {
        airportName: "Gecitkale",
        city: "Gecitkale",
        iata: "GEC",
        countryIata: "CYGEC",
        country: "Cyprus",
        countryCode: "CY",
    },
    {
        airportName: "Larnaca",
        city: "Larnaca",
        iata: "LCA",
        countryIata: "CYLCA",
        country: "Cyprus",
        countryCode: "CY",
    },
    {
        airportName: "Nicosia International Airport (disused)",
        city: "Nicosia",
        iata: "NIC",
        countryIata: "CYNIC",
        country: "Cyprus",
        countryCode: "CY",
    },
    {
        airportName: "International",
        city: "Paphos",
        iata: "PFO",
        countryIata: "CYPFO",
        country: "Cyprus",
        countryCode: "CY",
    },
    {
        airportName: "Turany",
        city: "Brno",
        iata: "BRQ",
        countryIata: "CZBRQ",
        country: "Czech Republic",
        countryCode: "CZ",
    },
    {
        airportName: "Holesov",
        city: "Zlin",
        iata: "GTW",
        countryIata: "CZGTW",
        country: "Czech Republic",
        countryCode: "CZ",
    },
    {
        airportName: "Karlovy Vary",
        city: "Karlovy Vary",
        iata: "KLV",
        countryIata: "CZKLV",
        country: "Czech Republic",
        countryCode: "CZ",
    },
    {
        airportName: "Marianske Lazne",
        city: "Marianske Lazne",
        iata: "MKA",
        countryIata: "CZMKA",
        country: "Czech Republic",
        countryCode: "CZ",
    },
    {
        airportName: "Olomouc",
        city: "Olomouc",
        iata: "OLO",
        countryIata: "CZOLO",
        country: "Czech Republic",
        countryCode: "CZ",
    },
    {
        airportName: "Mosnov",
        city: "Ostrava",
        iata: "OSR",
        countryIata: "CZOSR",
        country: "Czech Republic",
        countryCode: "CZ",
    },
    {
        airportName: "Pardubice",
        city: "Pardubice",
        iata: "PED",
        countryIata: "CZPED",
        country: "Czech Republic",
        countryCode: "CZ",
    },
    {
        airportName: "Ruzyne",
        city: "Prague",
        iata: "PRG",
        countryIata: "CZPRG",
        country: "Czech Republic",
        countryCode: "CZ",
    },
    {
        airportName: "Prerov",
        city: "Prerov",
        iata: "PRV",
        countryIata: "CZPRV",
        country: "Czech Republic",
        countryCode: "CZ",
    },
    {
        airportName: "Uherske Hradiste",
        city: "Uherske Hradiste",
        iata: "UHE",
        countryIata: "CZUHE",
        country: "Czech Republic",
        countryCode: "CZ",
    },
    {
        airportName: "Dolni Benesov",
        city: "Zabreh",
        iata: "ZBE",
        countryIata: "CZZBE",
        country: "Czech Republic",
        countryCode: "CZ",
    },
    {
        airportName: "Aalborg Airport (Aalborg Lufthavn)",
        city: "Aalborg",
        iata: "AAL",
        countryIata: "DKAAL",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Tirstrup",
        city: "Aarhus",
        iata: "AAR",
        countryIata: "DKAAR",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Billund",
        city: "Billund",
        iata: "BLL",
        countryIata: "DKBLL",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Laeso Airport",
        city: "Laeso Island",
        iata: "BYR",
        countryIata: "DKBYR",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Sindal",
        city: "Sindal",
        iata: "CNL",
        countryIata: "DKCNL",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Copenhagen Airport",
        city: "Copenhagen",
        iata: "CPH",
        countryIata: "DKCPH",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Esbjerg",
        city: "Esbjerg",
        iata: "EBJ",
        countryIata: "DKEBJ",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Karup",
        city: "Karup",
        iata: "KRP",
        countryIata: "DKKRP",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Maribo",
        city: "Maribo",
        iata: "MRW",
        countryIata: "DKMRW",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Beldringe",
        city: "Odense",
        iata: "ODE",
        countryIata: "DKODE",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Sonderborg",
        city: "Sonderborg",
        iata: "QSG",
        countryIata: "DKQSG",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Roskilde Airport",
        city: "Copenhagen",
        iata: "RKE",
        countryIata: "DKRKE",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Bornholm Airport",
        city: "Bornholm island",
        iata: "RNN",
        countryIata: "DKRNN",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Sonderborg",
        city: "Sonderborg",
        iata: "SGD",
        countryIata: "DKSGD",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Vojens",
        city: "Vojens",
        iata: "SKS",
        countryIata: "DKSKS",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Skive Airport",
        city: "Skive",
        iata: "SQW",
        countryIata: "DKSQW",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Stauning",
        city: "Stauning",
        iata: "STA",
        countryIata: "DKSTA",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Thisted",
        city: "Thisted",
        iata: "TED",
        countryIata: "DKTED",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "vejle",
        city: "Vejle",
        iata: "VEJ",
        countryIata: "DKVEJ",
        country: "Denmark",
        countryCode: "DK",
    },
    {
        airportName: "Alisabieh",
        city: "Alisabieh",
        iata: "AII",
        countryIata: "DJAII",
        country: "Djibouti",
        countryCode: "DJ",
    },
    {
        airportName: "Ambouli",
        city: "Djibouti",
        iata: "JIB",
        countryIata: "DJJIB",
        country: "Djibouti",
        countryCode: "DJ",
    },
    {
        airportName: "Musha",
        city: "Musha",
        iata: "MHI",
        countryIata: "DJMHI",
        country: "Djibouti",
        countryCode: "DJ",
    },
    {
        airportName: "Obock",
        city: "Obock",
        iata: "OBC",
        countryIata: "DJOBC",
        country: "Djibouti",
        countryCode: "DJ",
    },
    {
        airportName: "Tadjoura",
        city: "Tadjoura",
        iata: "TDJ",
        countryIata: "DJTDJ",
        country: "Djibouti",
        countryCode: "DJ",
    },
    {
        airportName: "Cane Field",
        city: "Roseau",
        iata: "DCF",
        countryIata: "DMDCF",
        country: "Dominica",
        countryCode: "DM",
    },
    {
        airportName: "Melville Hall",
        city: "Dominica",
        iata: "DOM",
        countryIata: "DMDOM",
        country: "Dominica",
        countryCode: "DM",
    },
    {
        airportName: "Samaná El Catey International Airport",
        city: "El Catey",
        iata: "AZS",
        countryIata: "DOAZS",
        country: "Dominican Republic",
        countryCode: "DO",
    },
    {
        airportName: "Barahona",
        city: "Barahona",
        iata: "BRX",
        countryIata: "DOBRX",
        country: "Dominican Republic",
        countryCode: "DO",
    },
    {
        airportName: "Cabo Rojo",
        city: "Cabo Rojo",
        iata: "CBJ",
        countryIata: "DOCBJ",
        country: "Dominican Republic",
        countryCode: "DO",
    },
    {
        airportName: "Constanza Airport",
        city: "Constanza",
        iata: "COZ",
        countryIata: "DOCOZ",
        country: "Dominican Republic",
        countryCode: "DO",
    },
    {
        airportName: "El Portillo",
        city: "El Portillo/Samana",
        iata: "EPS",
        countryIata: "DOEPS",
        country: "Dominican Republic",
        countryCode: "DO",
    },
    {
        airportName: "Herrera Intl Airport (closed in 2006)",
        city: "Santo Domingo",
        iata: "HEX",
        countryIata: "DOHEX",
        country: "Dominican Republic",
        countryCode: "DO",
    },
    {
        airportName: "La Isabela International Airport",
        city: "Santo Domingo",
        iata: "JBQ",
        countryIata: "DOJBQ",
        country: "Dominican Republic",
        countryCode: "DO",
    },
    {
        airportName: "La Romana International Airport",
        city: "La Romana",
        iata: "LRM",
        countryIata: "DOLRM",
        country: "Dominican Republic",
        countryCode: "DO",
    },
    {
        airportName: "La Union",
        city: "Puerto Plata",
        iata: "POP",
        countryIata: "DOPOP",
        country: "Dominican Republic",
        countryCode: "DO",
    },
    {
        airportName: "Punta Cana",
        city: "Punta Cana",
        iata: "PUJ",
        countryIata: "DOPUJ",
        country: "Dominican Republic",
        countryCode: "DO",
    },
    {
        airportName: "Las Americas",
        city: "Santo Domingo",
        iata: "SDQ",
        countryIata: "DOSDQ",
        country: "Dominican Republic",
        countryCode: "DO",
    },
    {
        airportName: "San Juan",
        city: "San Juan",
        iata: "SJM",
        countryIata: "DOSJM",
        country: "Dominican Republic",
        countryCode: "DO",
    },
    {
        airportName: "Sabana De Mar",
        city: "Sabana De Mar",
        iata: "SNX",
        countryIata: "DOSNX",
        country: "Dominican Republic",
        countryCode: "DO",
    },
    {
        airportName: "Municipal",
        city: "Santiago",
        iata: "STI",
        countryIata: "DOSTI",
        country: "Dominican Republic",
        countryCode: "DO",
    },
    {
        airportName: "Chachoan",
        city: "Ambato",
        iata: "ATF",
        countryIata: "ECATF",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Bahia De Caraquez",
        city: "Bahia De Caraquez",
        iata: "BHA",
        countryIata: "ECBHA",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Cuenca",
        city: "Cuenca",
        iata: "CUE",
        countryIata: "ECCUE",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Esmeraldas",
        city: "Esmeraldas",
        iata: "ESM",
        countryIata: "ECESM",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Baltra",
        city: "Galapagos Is",
        iata: "GPS",
        countryIata: "ECGPS",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Simon Bolivar",
        city: "Guayaquil",
        iata: "GYE",
        countryIata: "ECGYE",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Jipijapa",
        city: "Jipijapa",
        iata: "JIP",
        countryIata: "ECJIP",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Lago Agrio",
        city: "Lago Agrio",
        iata: "LGQ",
        countryIata: "ECLGQ",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Loja",
        city: "Loja",
        iata: "LOH",
        countryIata: "ECLOH",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Machala",
        city: "Machala",
        iata: "MCH",
        countryIata: "ECMCH",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Manta",
        city: "Manta",
        iata: "MEC",
        countryIata: "ECMEC",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "José María Velasco Ibarra Airport",
        city: "Macará",
        iata: "MRR",
        countryIata: "ECMRR",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Mendez",
        city: "Mendez",
        iata: "MZD",
        countryIata: "ECMZD",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Coca",
        city: "Coca",
        iata: "OCC",
        countryIata: "ECOCC",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Pastaza",
        city: "Pastaza",
        iata: "PTZ",
        countryIata: "ECPTZ",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Portoviejo",
        city: "Portoviejo",
        iata: "PVO",
        countryIata: "ECPVO",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Putumayo",
        city: "Putumayo",
        iata: "PYO",
        countryIata: "ECPYO",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "San Cristobal Airport",
        city: "San Cristobal",
        iata: "SCY",
        countryIata: "ECSCY",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Salinas",
        city: "Salinas",
        iata: "SNC",
        countryIata: "ECSNC",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Sucua",
        city: "Sucua",
        iata: "SUQ",
        countryIata: "ECSUQ",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Tarapoa",
        city: "Tarapoa",
        iata: "TPC",
        countryIata: "ECTPC",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Tiputini",
        city: "Tiputini",
        iata: "TPN",
        countryIata: "ECTPN",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Taisha",
        city: "Taisha",
        iata: "TSC",
        countryIata: "ECTSC",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Tulcan",
        city: "Tulcan",
        iata: "TUA",
        countryIata: "ECTUA",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Mariscal Sucre International Airport",
        city: "Quito",
        iata: "UIO",
        countryIata: "ECUIO",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "Macas",
        city: "Macas",
        iata: "XMS",
        countryIata: "ECXMS",
        country: "Ecuador",
        countryCode: "EC",
    },
    {
        airportName: "El Arish International Airport",
        city: "Arish",
        iata: "AAC",
        countryIata: "EGAAC",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Abu Simbel Airport",
        city: "Abu Simbel",
        iata: "ABS",
        countryIata: "EGABS",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "El Nouzha Airport (Alexandria International Airport)",
        city: "Alexandria",
        iata: "ALY",
        countryIata: "EGALY",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Aswan International Airport",
        city: "Aswan",
        iata: "ASW",
        countryIata: "EGASW",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Assiut",
        city: "Assiut",
        iata: "ATZ",
        countryIata: "EGATZ",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Abu Rudeis",
        city: "Abu Rudeis",
        iata: "AUE",
        countryIata: "EGAUE",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "International",
        city: "Cairo",
        iata: "CAI",
        countryIata: "EGCAI",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Dakhla",
        city: "Dakhla Oasis",
        iata: "DAK",
        countryIata: "EGDAK",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "El Tor/Tour Sinai Airport",
        city: "Tour Sinai City",
        iata: "ELT",
        countryIata: "EGELT",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "El Minya",
        city: "El Minya",
        iata: "EMY",
        countryIata: "EGEMY",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Shark Elowainat",
        city: "Shark Elowainat",
        iata: "GSQ",
        countryIata: "EGGSQ",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Borg El Arab Airport",
        city: "Borg El Arab",
        iata: "HBE",
        countryIata: "EGHBE",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Hurghada",
        city: "Hurghada",
        iata: "HRG",
        countryIata: "EGHRG",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Luxor",
        city: "Luxor",
        iata: "LXR",
        countryIata: "EGLXR",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Mersa Matruh",
        city: "Mersa Matruh",
        iata: "MUH",
        countryIata: "EGMUH",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Port Said",
        city: "Port Said",
        iata: "PSD",
        countryIata: "EGPSD",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Siwa",
        city: "Siwa",
        iata: "SEW",
        countryIata: "EGSEW",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Mount Sinai",
        city: "Santa Katarina",
        iata: "SKV",
        countryIata: "EGSKV",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Sidi Barani",
        city: "Sidi Barani",
        iata: "SQK",
        countryIata: "EGSQK",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Ophira",
        city: "Sharm El Sheikh",
        iata: "SSH",
        countryIata: "EGSSH",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Taba International",
        city: "Taba",
        iata: "TCP",
        countryIata: "EGTCP",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Ramadan",
        city: "Ramadan",
        iata: "TFR",
        countryIata: "EGTFR",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "Kharga",
        city: "Kharga",
        iata: "UVL",
        countryIata: "EGUVL",
        country: "Egypt",
        countryCode: "EG",
    },
    {
        airportName: "El Salvador International Airport",
        city: "San Salvador",
        iata: "SAL",
        countryIata: "SVSAL",
        country: "El Salvador",
        countryCode: "SV",
    },
    {
        airportName: "Bata",
        city: "Bata",
        iata: "BSG",
        countryIata: "GQBSG",
        country: "Equatorial Guinea",
        countryCode: "GQ",
    },
    {
        airportName: "Santa Isabel",
        city: "Malabo",
        iata: "SSG",
        countryIata: "GQSSG",
        country: "Equatorial Guinea",
        countryCode: "GQ",
    },
    {
        airportName: "Assab International Airport",
        city: "Assab",
        iata: "ASA",
        countryIata: "ERASA",
        country: "Eritrea",
        countryCode: "ER",
    },
    {
        airportName: "Asmara International Airport",
        city: "Asmara",
        iata: "ASM",
        countryIata: "ERASM",
        country: "Eritrea",
        countryCode: "ER",
    },
    {
        airportName: "Massawa",
        city: "Massawa",
        iata: "MSW",
        countryIata: "ERMSW",
        country: "Eritrea",
        countryCode: "ER",
    },
    {
        airportName: "Tessenei",
        city: "Tessenei",
        iata: "TES",
        countryIata: "ERTES",
        country: "Eritrea",
        countryCode: "ER",
    },
    {
        airportName: "Kardla",
        city: "Kardla",
        iata: "KDL",
        countryIata: "EEKDL",
        country: "Estonia",
        countryCode: "EE",
    },
    {
        airportName: "Pirita Harbour",
        city: "Tallinn",
        iata: "QUF",
        countryIata: "EEQUF",
        country: "Estonia",
        countryCode: "EE",
    },
    {
        airportName: "Tartu",
        city: "Tartu",
        iata: "TAY",
        countryIata: "EETAY",
        country: "Estonia",
        countryCode: "EE",
    },
    {
        airportName: "Ulemiste",
        city: "Tallinn",
        iata: "TLL",
        countryIata: "EETLL",
        country: "Estonia",
        countryCode: "EE",
    },
    {
        airportName: "Kabri Dar",
        city: "Kabri Dar",
        iata: "ABK",
        countryIata: "ETABK",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Bole International Airport",
        city: "Addis Ababa",
        iata: "ADD",
        countryIata: "ETADD",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Asela",
        city: "Asela",
        iata: "ALK",
        countryIata: "ETALK",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Arba Mintch",
        city: "Arba Mintch",
        iata: "AMH",
        countryIata: "ETAMH",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Asosa",
        city: "Asosa",
        iata: "ASO",
        countryIata: "ETASO",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Awassa",
        city: "Awassa",
        iata: "AWA",
        countryIata: "ETAWA",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Awareh",
        city: "Awareh",
        iata: "AWH",
        countryIata: "ETAWH",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Axum",
        city: "Axum",
        iata: "AXU",
        countryIata: "ETAXU",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Jinka",
        city: "Jinka",
        iata: "BCO",
        countryIata: "ETBCO",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Bulchi",
        city: "Bulchi",
        iata: "BCY",
        countryIata: "ETBCY",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Beica",
        city: "Beica",
        iata: "BEI",
        countryIata: "ETBEI",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Bahar Dar",
        city: "Bahar Dar",
        iata: "BJR",
        countryIata: "ETBJR",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Debra Marcos",
        city: "Debra Marcos",
        iata: "DBM",
        countryIata: "ETDBM",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Debra Tabor",
        city: "Debra Tabor",
        iata: "DBT",
        countryIata: "ETDBT",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Dembidollo",
        city: "Dembidollo",
        iata: "DEM",
        countryIata: "ETDEM",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Degahbur",
        city: "Degahbur",
        iata: "DGC",
        countryIata: "ETDGC",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Aba Tenna D Yilma",
        city: "Dire Dawa",
        iata: "DIR",
        countryIata: "ETDIR",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Combolcha",
        city: "Dessie",
        iata: "DSE",
        countryIata: "ETDSE",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Neghelli",
        city: "Neghelli",
        iata: "EGL",
        countryIata: "ETEGL",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Genda Wuha",
        city: "Genda Wuha",
        iata: "ETE",
        countryIata: "ETETE",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Fincha",
        city: "Fincha",
        iata: "FNH",
        countryIata: "ETFNH",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Gode/Iddidole",
        city: "Gode/Iddidole",
        iata: "GDE",
        countryIata: "ETGDE",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Gondar",
        city: "Gondar",
        iata: "GDQ",
        countryIata: "ETGDQ",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Ghimbi",
        city: "Ghimbi",
        iata: "GHD",
        countryIata: "ETGHD",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Geladi",
        city: "Geladi",
        iata: "GLC",
        countryIata: "ETGLC",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Gambela",
        city: "Gambela",
        iata: "GMB",
        countryIata: "ETGMB",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Ghinnir",
        city: "Ghinnir",
        iata: "GNN",
        countryIata: "ETGNN",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Goba",
        city: "Goba",
        iata: "GOB",
        countryIata: "ETGOB",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Gore",
        city: "Gore",
        iata: "GOR",
        countryIata: "ETGOR",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Shillavo",
        city: "Shillavo",
        iata: "HIL",
        countryIata: "ETHIL",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Humera",
        city: "Humera",
        iata: "HUE",
        countryIata: "ETHUE",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Jigiga",
        city: "Jijiga",
        iata: "JIJ",
        countryIata: "ETJIJ",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Jimma",
        city: "Jimma",
        iata: "JIM",
        countryIata: "ETJIM",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Kelafo",
        city: "Kelafo/Callaf",
        iata: "LFO",
        countryIata: "ETLFO",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Lalibela",
        city: "Lalibela",
        iata: "LLI",
        countryIata: "ETLLI",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Misrak Gashamo",
        city: "Misrak Gashamo",
        iata: "MHJ",
        countryIata: "ETMHJ",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Chagni",
        city: "Chagni",
        iata: "MKD",
        countryIata: "ETMKD",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Mekane Selam",
        city: "Mekane Selam",
        iata: "MKS",
        countryIata: "ETMKS",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Makale",
        city: "Makale",
        iata: "MQX",
        countryIata: "ETMQX",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Mizan Teferi",
        city: "Mizan Teferi",
        iata: "MTF",
        countryIata: "ETMTF",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Mui",
        city: "Mui",
        iata: "MUJ",
        countryIata: "ETMUJ",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Moyale",
        city: "Moyale",
        iata: "MYS",
        countryIata: "ETMYS",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Mena",
        city: "Mena",
        iata: "MZX",
        countryIata: "ETMZX",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Mendi",
        city: "Mendi",
        iata: "NDM",
        countryIata: "ETNDM",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Nejjo",
        city: "Nejjo",
        iata: "NEJ",
        countryIata: "ETNEJ",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Nekemt",
        city: "Nekemt",
        iata: "NEK",
        countryIata: "ETNEK",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Mota",
        city: "Mota",
        iata: "OTA",
        countryIata: "ETOTA",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Beles",
        city: "Pawi",
        iata: "PWI",
        countryIata: "ETPWI",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Indaselassie",
        city: "Indaselassie",
        iata: "SHC",
        countryIata: "ETSHC",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Shakiso",
        city: "Shakiso",
        iata: "SKR",
        countryIata: "ETSKR",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Shehdi",
        city: "Shehdi",
        iata: "SQJ",
        countryIata: "ETSQJ",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Soddu",
        city: "Soddu",
        iata: "SXU",
        countryIata: "ETSXU",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Semera Airport",
        city: "Semera",
        iata: "SZE",
        countryIata: "ETSZE",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Tippi",
        city: "Tippi",
        iata: "TIE",
        countryIata: "ETTIE",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Tum",
        city: "Tum",
        iata: "TUJ",
        countryIata: "ETTUJ",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Kuressaare",
        city: "Kuressaare",
        iata: "URE",
        countryIata: "ETURE",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Waca",
        city: "Waca",
        iata: "WAC",
        countryIata: "ETWAC",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Warder",
        city: "Warder",
        iata: "WRA",
        countryIata: "ETWRA",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Buno Bedele",
        city: "Buno Bedele",
        iata: "XBL",
        countryIata: "ETXBL",
        country: "Ethiopia",
        countryCode: "ET",
    },
    {
        airportName: "Saqani",
        city: "Saqani",
        iata: "AQS",
        countryIata: "FJAQS",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Ba",
        city: "Ba",
        iata: "BFJ",
        countryIata: "FJBFJ",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Dama",
        city: "Bua",
        iata: "BVF",
        countryIata: "FJBVF",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Blue Lagoon",
        city: "Blue Lagoon",
        iata: "BXL",
        countryIata: "FJBXL",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Castaway",
        city: "Castaway",
        iata: "CST",
        countryIata: "FJCST",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Cicia",
        city: "Cicia",
        iata: "ICI",
        countryIata: "FJICI",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Wakaya Island",
        city: "Wakaya Island",
        iata: "KAY",
        countryIata: "FJKAY",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Kandavu",
        city: "Kandavu",
        iata: "KDV",
        countryIata: "FJKDV",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Korolevu",
        city: "Korolevu",
        iata: "KVU",
        countryIata: "FJKVU",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Koro Island",
        city: "Koro Island",
        iata: "KXF",
        countryIata: "FJKXF",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Labasa",
        city: "Labasa",
        iata: "LBS",
        countryIata: "FJLBS",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Levuka Airfield",
        city: "Bureta",
        iata: "LEV",
        countryIata: "FJLEV",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Lakeba",
        city: "Lakeba",
        iata: "LKB",
        countryIata: "FJLKB",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Laucala Is",
        city: "Laucala Is",
        iata: "LUC",
        countryIata: "FJLUC",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Moala",
        city: "Moala",
        iata: "MFJ",
        countryIata: "FJMFJ",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Mana Island Airstrip",
        city: "Mana Island",
        iata: "MNF",
        countryIata: "FJMNF",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "International",
        city: "Nadi, Suva",
        iata: "NAN",
        countryIata: "FJNAN",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Ngau Island",
        city: "Ngau Island",
        iata: "NGI",
        countryIata: "FJNGI",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Natadola",
        city: "Natadola",
        iata: "NTA",
        countryIata: "FJNTA",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Ono I Lau",
        city: "Ono I Lau",
        iata: "ONU",
        countryIata: "FJONU",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Pacific Harbor",
        city: "Pacific Harbor",
        iata: "PHR",
        countryIata: "FJPHR",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Malololailai",
        city: "Malololailai",
        iata: "PTF",
        countryIata: "FJPTF",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Rabi",
        city: "Rabi",
        iata: "RBI",
        countryIata: "FJRBI",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Rotuma Island",
        city: "Rotuma Island",
        iata: "RTA",
        countryIata: "FJRTA",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Nausori",
        city: "Suva",
        iata: "SUV",
        countryIata: "FJSUV",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Savusavu",
        city: "Savusavu",
        iata: "SVU",
        countryIata: "FJSVU",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Turtle Island",
        city: "Turtle Island",
        iata: "TTL",
        countryIata: "FJTTL",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Matei",
        city: "Taveuni",
        iata: "TVU",
        countryIata: "FJTVU",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Vatukoula",
        city: "Vatukoula",
        iata: "VAU",
        countryIata: "FJVAU",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Vanuabalavu",
        city: "Vanuabalavu",
        iata: "VBV",
        countryIata: "FJVBV",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Vatulele",
        city: "Vatulele",
        iata: "VTF",
        countryIata: "FJVTF",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Yasawa Island",
        city: "Yasawa Island",
        iata: "YAS",
        countryIata: "FJYAS",
        country: "Fiji",
        countryCode: "FJ",
    },
    {
        airportName: "Enontekio",
        city: "Enontekio",
        iata: "ENF",
        countryIata: "FIENF",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Helsinki-Vantaa",
        city: "Helsinki",
        iata: "HEL",
        countryIata: "FIHEL",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Helsinki-Malmi",
        city: "Helsinki",
        iata: "HEM",
        countryIata: "FIHEM",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Hyvinkaa",
        city: "Hyvinkaa",
        iata: "HYV",
        countryIata: "FIHYV",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Ivalo",
        city: "Ivalo",
        iata: "IVL",
        countryIata: "FIIVL",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Joensuu",
        city: "Joensuu",
        iata: "JOE",
        countryIata: "FIJOE",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Jyväskylä Airport",
        city: "Jyvaskyla",
        iata: "JYV",
        countryIata: "FIJYV",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Kajaani",
        city: "Kajaani",
        iata: "KAJ",
        countryIata: "FIKAJ",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Kuusamo",
        city: "Kuusamo",
        iata: "KAO",
        countryIata: "FIKAO",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Kauhava",
        city: "Kauhava",
        iata: "KAU",
        countryIata: "FIKAU",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Kemi/Tornio",
        city: "Kemi/Tornio",
        iata: "KEM",
        countryIata: "FIKEM",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Halli",
        city: "Kuorevesi",
        iata: "KEV",
        countryIata: "FIKEV",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Kauhajoki",
        city: "Kauhajoki",
        iata: "KHJ",
        countryIata: "FIKHJ",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Kruunupyy",
        city: "Kokkola/Pietarsaari",
        iata: "KOK",
        countryIata: "FIKOK",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Kitee",
        city: "Kitee",
        iata: "KTQ",
        countryIata: "FIKTQ",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Kittila",
        city: "Kittila",
        iata: "KTT",
        countryIata: "FIKTT",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Kuopio",
        city: "Kuopio",
        iata: "KUO",
        countryIata: "FIKUO",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Lappeenranta",
        city: "Lappeenranta",
        iata: "LPP",
        countryIata: "FILPP",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Mariehamn",
        city: "Mariehamn",
        iata: "MHQ",
        countryIata: "FIMHQ",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Mikkeli",
        city: "Mikkeli",
        iata: "MIK",
        countryIata: "FIMIK",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Oulu",
        city: "Oulu",
        iata: "OUL",
        countryIata: "FIOUL",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Pori",
        city: "Pori",
        iata: "POR",
        countryIata: "FIPOR",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Rovaniemi",
        city: "Rovaniemi",
        iata: "RVN",
        countryIata: "FIRVN",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Ilmajoki",
        city: "Seinajoki",
        iata: "SJY",
        countryIata: "FISJY",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Sodankyla",
        city: "Sodankyla",
        iata: "SOT",
        countryIata: "FISOT",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Savonlinna",
        city: "Savonlinna",
        iata: "SVL",
        countryIata: "FISVL",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Turku",
        city: "Turku",
        iata: "TKU",
        countryIata: "FITKU",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Tampere-Pirkkala",
        city: "Tampere",
        iata: "TMP",
        countryIata: "FITMP",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Utti",
        city: "Kouvola",
        iata: "UTI",
        countryIata: "FIUTI",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Vaasa",
        city: "Vaasa",
        iata: "VAA",
        countryIata: "FIVAA",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Varkaus",
        city: "Varkaus",
        iata: "VRK",
        countryIata: "FIVRK",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Ylivieska",
        city: "Ylivieska",
        iata: "YLI",
        countryIata: "FIYLI",
        country: "Finland",
        countryCode: "FI",
    },
    {
        airportName: "Agen La Garenne Airport",
        city: "Agen",
        iata: "AGF",
        countryIata: "FRAGF",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Alpe D Huez",
        city: "Alpe D Huez",
        iata: "AHZ",
        countryIata: "FRAHZ",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Campo Dell Oro",
        city: "Ajaccio",
        iata: "AJA",
        countryIata: "FRAJA",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Marce",
        city: "Angers",
        iata: "ANE",
        countryIata: "FRANE",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Brie-Champniers",
        city: "Angouleme",
        iata: "ANG",
        countryIata: "FRANG",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Auxerre Branches",
        city: "Auxerre",
        iata: "AUF",
        countryIata: "FRAUF",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Aurillac",
        city: "Aurillac",
        iata: "AUR",
        countryIata: "FRAUR",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Avoriaz",
        city: "Avoriaz",
        iata: "AVF",
        countryIata: "FRAVF",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Avignon-Caum",
        city: "Avignon",
        iata: "AVN",
        countryIata: "FRAVN",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Barcelonnette",
        city: "Barcelonnette",
        iata: "BAE",
        countryIata: "FRBAE",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Guipavas",
        city: "Brest",
        iata: "BES",
        countryIata: "FRBES",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Poretta",
        city: "Bastia",
        iata: "BIA",
        countryIata: "FRBIA",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Biarritz Parme",
        city: "Biarritz",
        iata: "BIQ",
        countryIata: "FRBIQ",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Bordeaux Airport",
        city: "Bordeaux",
        iata: "BOD",
        countryIata: "FRBOD",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Fontaine",
        city: "Belfort",
        iata: "BOR",
        countryIata: "FRBOR",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Bourges",
        city: "Bourges",
        iata: "BOU",
        countryIata: "FRBOU",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Beauvais-Tille Airport",
        city: "Paris",
        iata: "BVA",
        countryIata: "FRBVA",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Laroche",
        city: "Brive-La-Gaillarde",
        iata: "BVE",
        countryIata: "FRBVE",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Beziers Vias",
        city: "Beziers",
        iata: "BZR",
        countryIata: "FRBZR",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Salvaza",
        city: "Carcassonne",
        iata: "CCF",
        countryIata: "FRCCF",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Charles De Gaulle",
        city: "Paris",
        iata: "CDG",
        countryIata: "FRCDG",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Cannes – Mandelieu",
        city: "Cannes",
        iata: "CEQ",
        countryIata: "FRCEQ",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Maupertus",
        city: "Cherbourg",
        iata: "CER",
        countryIata: "FRCER",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Le Pontreau",
        city: "Cholet",
        iata: "CET",
        countryIata: "FRCET",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Aulnat",
        city: "Clermont-Ferrand",
        iata: "CFE",
        countryIata: "FRCFE",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Carpiquet",
        city: "Caen",
        iata: "CFR",
        countryIata: "FRCFR",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Chateauroux",
        city: "Chateauroux",
        iata: "CHR",
        countryIata: "FRCHR",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Ste Catherine",
        city: "Calvi",
        iata: "CLY",
        countryIata: "FRCLY",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Chambery",
        city: "Chambery",
        iata: "CMF",
        countryIata: "FRCMF",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Colmar-Houssen",
        city: "Colmar",
        iata: "CMR",
        countryIata: "FRCMR",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Parvaud",
        city: "Cognac",
        iata: "CNG",
        countryIata: "FRCNG",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Calais",
        city: "Calais",
        iata: "CQF",
        countryIata: "FRCQF",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Creil",
        city: "Creil",
        iata: "CSF",
        countryIata: "FRCSF",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Le Castellet",
        city: "Le Castellet",
        iata: "CTT",
        countryIata: "FRCTT",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Courchevel",
        city: "Courchevel",
        iata: "CVF",
        countryIata: "FRCVF",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Mazamet",
        city: "Castres",
        iata: "DCM",
        countryIata: "FRDCM",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Dijon",
        city: "Dijon",
        iata: "DIJ",
        countryIata: "FRDIJ",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Tavaux",
        city: "Dole",
        iata: "DLE",
        countryIata: "FRDLE",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Disneyland Paris",
        city: "Disneyland Paris",
        iata: "DLP",
        countryIata: "FRDLP",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Pleurtuit",
        city: "Dinard",
        iata: "DNR",
        countryIata: "FRDNR",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "St Gatien",
        city: "Deauville",
        iata: "DOL",
        countryIata: "FRDOL",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Dieppe",
        city: "Dieppe",
        iata: "DPE",
        countryIata: "FRDPE",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Deux Alpes",
        city: "Deux Alpes",
        iata: "DXA",
        countryIata: "FRDXA",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Boutheon",
        city: "St Etienne",
        iata: "EBU",
        countryIata: "FREBU",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Les Ajoncs",
        city: "La Roche",
        iata: "EDM",
        countryIata: "FREDM",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Roumanieres",
        city: "Bergerac",
        iata: "EGC",
        countryIata: "FREGC",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Essey",
        city: "Nancy",
        iata: "ENC",
        countryIata: "FRENC",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Mirecourt",
        city: "Epinal",
        iata: "EPL",
        countryIata: "FREPL",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Metz-Nancy-Lorraine",
        city: "Metz/Nancy",
        iata: "ETZ",
        countryIata: "FRETZ",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Evreux",
        city: "Evreux",
        iata: "EVX",
        countryIata: "FREVX",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Garons",
        city: "Nimes",
        iata: "FNI",
        countryIata: "FRFNI",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Frejus",
        city: "Frejus",
        iata: "FRJ",
        countryIata: "FRFRJ",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Sud Corse",
        city: "Figari",
        iata: "FSC",
        countryIata: "FRFSC",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Tallard",
        city: "Gap",
        iata: "GAT",
        countryIata: "FRGAT",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Granville",
        city: "Granville",
        iata: "GFR",
        countryIata: "FRGFR",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Saint Geoirs",
        city: "Grenoble",
        iata: "GNB",
        countryIata: "FRGNB",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Merville/Calonne",
        city: "Hazebrouck",
        iata: "HZB",
        countryIata: "FRHZB",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Ile d'Yeu",
        city: "Ile d'Yeu",
        iata: "IDY",
        countryIata: "FRIDY",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Agora Helipad",
        city: "Aubagne",
        iata: "JAH",
        countryIata: "FRJAH",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Croisette Heliport",
        city: "Cannes",
        iata: "JCA",
        countryIata: "FRJCA",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Heliport De Paris",
        city: "Paris",
        iata: "JDP",
        countryIata: "FRJDP",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Heliport",
        city: "Evry",
        iata: "JEV",
        countryIata: "FRJEV",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Juan Les Pins",
        city: "Juan Les Pins",
        iata: "JLP",
        countryIata: "FRJLP",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "La Defense Heliport",
        city: "Paris",
        iata: "JPU",
        countryIata: "FRJPU",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Heliport (closed)",
        city: "Saint Tropez",
        iata: "JSZ",
        countryIata: "FRJSZ",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Servel",
        city: "Lannion",
        iata: "LAI",
        countryIata: "FRLAI",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Le Bourget",
        city: "Paris",
        iata: "LBG",
        countryIata: "FRLBG",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Le Sequestre",
        city: "Albi",
        iata: "LBI",
        countryIata: "FRLBI",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Montoir",
        city: "La Baule",
        iata: "LBY",
        countryIata: "FRLBY",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Tarbes Ossun Lourdes",
        city: "Lourdes/Tarbes",
        iata: "LDE",
        countryIata: "FRLDE",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Landivisiau",
        city: "Landivisiau",
        iata: "LDV",
        countryIata: "FRLDV",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Octeville",
        city: "Le Havre",
        iata: "LEH",
        countryIata: "FRLEH",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Bellegarde",
        city: "Limoges",
        iata: "LIG",
        countryIata: "FRLIG",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Lesquin",
        city: "Lille",
        iata: "LIL",
        countryIata: "FRLIL",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Arnage",
        city: "Le Mans",
        iata: "LME",
        countryIata: "FRLME",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Loudes",
        city: "Le Puy",
        iata: "LPY",
        countryIata: "FRLPY",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Laleu",
        city: "La Rochelle",
        iata: "LRH",
        countryIata: "FRLRH",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Lann Bihoue",
        city: "Lorient",
        iata: "LRT",
        countryIata: "FRLRT",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Talmont",
        city: "Les Sables",
        iata: "LSO",
        countryIata: "FRLSO",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Le Touquet",
        city: "Le Touquet",
        iata: "LTQ",
        countryIata: "FRLTQ",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "La Mole",
        city: "Saint Tropez",
        iata: "LTT",
        countryIata: "FRLTT",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Entrammes",
        city: "Laval",
        iata: "LVA",
        countryIata: "FRLVA",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Bron (general aviation)",
        city: "Lyon",
        iata: "LYN",
        countryIata: "FRLYN",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Lyon-Saint Exupéry Airport",
        city: "Lyon",
        iata: "LYS",
        countryIata: "FRLYS",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Gueret (Lepaud)",
        city: "Montlucon",
        iata: "MCU",
        countryIata: "FRMCU",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Brenoux",
        city: "Mende",
        iata: "MEN",
        countryIata: "FRMEN",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Meribel",
        city: "Meribel",
        iata: "MFX",
        countryIata: "FRMFX",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Montpellier–Méditerranée Airport (Fréjorgues Airport)",
        city: "Montpellier",
        iata: "MPL",
        countryIata: "FRMPL",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Marseille Provence Airport",
        city: "Marseille",
        iata: "MRS",
        countryIata: "FRMRS",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Megeve",
        city: "Megeve",
        iata: "MVV",
        countryIata: "FRMVV",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Morlaix",
        city: "Morlaix",
        iata: "MXN",
        countryIata: "FRMXN",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Frescaty",
        city: "Metz",
        iata: "MZM",
        countryIata: "FRMZM",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Cote D'Azur",
        city: "Nice",
        iata: "NCE",
        countryIata: "FRNCE",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Annecy-Meythe",
        city: "Annecy",
        iata: "NCY",
        countryIata: "FRNCY",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Niort",
        city: "Niort",
        iata: "NIT",
        countryIata: "FRNIT",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Nantes Atlantique Airport",
        city: "Nantes",
        iata: "NTE",
        countryIata: "FRNTE",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Nevers",
        city: "Nevers",
        iata: "NVS",
        countryIata: "FRNVS",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Vals-Lanas",
        city: "Aubenas",
        iata: "OBS",
        countryIata: "FROBS",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Orleans",
        city: "Orleans",
        iata: "ORE",
        countryIata: "FRORE",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Orly",
        city: "Paris",
        iata: "ORY",
        countryIata: "FRORY",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Metropolitan Area",
        city: "Paris",
        iata: "PAR",
        countryIata: "FRPAR",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Llabanere",
        city: "Perpignan",
        iata: "PGF",
        countryIata: "FRPGF",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Perigueux",
        city: "Perigueux",
        iata: "PGX",
        countryIata: "FRPGX",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Biard",
        city: "Poitiers",
        iata: "PIS",
        countryIata: "FRPIS",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "La Plagne",
        city: "La Plagne",
        iata: "PLG",
        countryIata: "FRPLG",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Paris Cergy Pontoise",
        city: "Paris",
        iata: "POX",
        countryIata: "FRPOX",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Propriano",
        city: "Propriano",
        iata: "PRP",
        countryIata: "FRPRP",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Uzein",
        city: "Pau",
        iata: "PUF",
        countryIata: "FRPUF",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Glisy Aerodrome",
        city: "Amiens",
        iata: "QAM",
        countryIata: "FRQAM",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Besançon-La Vèze Airport",
        city: "Besançon",
        iata: "QBQ",
        countryIata: "FRQBQ",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Aix-en-Provence Aerodrome",
        city: "Aix-en-Provence",
        iata: "QXB",
        countryIata: "FRQXB",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Vieux Port",
        city: "Cannes",
        iata: "QYW",
        countryIata: "FRQYW",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Saint Agnant",
        city: "Rochefort",
        iata: "RCO",
        countryIata: "FRRCO",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Marcillac",
        city: "Rodez",
        iata: "RDZ",
        countryIata: "FRRDZ",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Reims",
        city: "Reims",
        iata: "RHE",
        countryIata: "FRRHE",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Renaison",
        city: "Roanne",
        iata: "RNE",
        countryIata: "FRRNE",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "St Jacques",
        city: "Rennes",
        iata: "RNS",
        countryIata: "FRRNS",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Medis",
        city: "Royan",
        iata: "RYN",
        countryIata: "FRRYN",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Saint-Brieuc - Trémuson Airport",
        city: "St Brieuc",
        iata: "SBK",
        countryIata: "FRSBK",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "St Crepin",
        city: "St Crepin",
        iata: "SCP",
        countryIata: "FRSCP",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Serre Chevalier",
        city: "Serre Chevalier",
        iata: "SEC",
        countryIata: "FRSEC",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Montoir",
        city: "St Nazaire",
        iata: "SNR",
        countryIata: "FRSNR",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Solenzara",
        city: "Solenzara",
        iata: "SOZ",
        countryIata: "FRSOZ",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Entzheim",
        city: "Strasbourg",
        iata: "SXB",
        countryIata: "FRSXB",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Sophia Antipolis",
        city: "Sophia Antipolis",
        iata: "SXD",
        countryIata: "FRSXD",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Charolais Bourgogne S.",
        city: "Saint Yan",
        iata: "SYT",
        countryIata: "FRSYT",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Tignes",
        city: "Tignes",
        iata: "TGF",
        countryIata: "FRTGF",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Hyeres",
        city: "Toulon",
        iata: "TLN",
        countryIata: "FRTLN",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Blagnac Airport",
        city: "Toulouse",
        iata: "TLS",
        countryIata: "FRTLS",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Toussus-le-Noble",
        city: "Toussus-le-Noble",
        iata: "TNF",
        countryIata: "FRTNF",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "St Symphorien",
        city: "Tours",
        iata: "TUF",
        countryIata: "FRTUF",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Pluguffan",
        city: "Quimper",
        iata: "UIP",
        countryIata: "FRUIP",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Boos",
        city: "Rouen",
        iata: "URO",
        countryIata: "FRURO",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Chabeuil",
        city: "Valence",
        iata: "VAF",
        countryIata: "FRVAF",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Val D'Isere",
        city: "Val D'Isere",
        iata: "VAZ",
        countryIata: "FRVAZ",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Charmeil",
        city: "Vichy",
        iata: "VHY",
        countryIata: "FRVHY",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Meucon",
        city: "Vannes",
        iata: "VNE",
        countryIata: "FRVNE",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Vittel",
        city: "Vittel",
        iata: "VTL",
        countryIata: "FRVTL",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Abbeville",
        city: "Abbeville",
        iata: "XAB",
        countryIata: "FRXAB",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "La Teste-de-Buch",
        city: "Arcachon",
        iata: "XAC",
        countryIata: "FRXAC",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Paris Vatry Airport",
        city: "Vatry",
        iata: "XCR",
        countryIata: "FRXCR",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Aerogare des Invalides",
        city: "Paris",
        iata: "XEX",
        countryIata: "FRXEX",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Etoile",
        city: "Paris",
        iata: "XTT",
        countryIata: "FRXTT",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Montaudran",
        city: "Toulouse (disused)",
        iata: "XYT",
        countryIata: "FRXYT",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Laberandie",
        city: "Cahors",
        iata: "ZAO",
        countryIata: "FRZAO",
        country: "France",
        countryCode: "FR",
    },
    {
        airportName: "Rochambeau",
        city: "Cayenne",
        iata: "CAY",
        countryIata: "GFCAY",
        country: "French Guiana",
        countryCode: "GF",
    },
    {
        airportName: "St-Laurent du Maroni",
        city: "St Laurent du Maroni",
        iata: "LDX",
        countryIata: "GFLDX",
        country: "French Guiana",
        countryCode: "GF",
    },
    {
        airportName: "Maripasoula",
        city: "Maripasoula",
        iata: "MPY",
        countryIata: "GFMPY",
        country: "French Guiana",
        countryCode: "GF",
    },
    {
        airportName: "St-Georges de Loyapock",
        city: "St Georges de Loyapock",
        iata: "OYP",
        countryIata: "GFOYP",
        country: "French Guiana",
        countryCode: "GF",
    },
    {
        airportName: "Regina",
        city: "Regina",
        iata: "REI",
        countryIata: "GFREI",
        country: "French Guiana",
        countryCode: "GF",
    },
    {
        airportName: "Saul",
        city: "Saul",
        iata: "XAU",
        countryIata: "GFXAU",
        country: "French Guiana",
        countryCode: "GF",
    },
    {
        airportName: "Anaa",
        city: "Anaa",
        iata: "AAA",
        countryIata: "PFAAA",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Ahe Airport",
        city: "Ahe",
        iata: "AHE",
        countryIata: "PFAHE",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Apataki",
        city: "Apataki",
        iata: "APK",
        countryIata: "PFAPK",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Atuona",
        city: "Atuona",
        iata: "AUQ",
        countryIata: "PFAUQ",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Arutua",
        city: "Arutua",
        iata: "AXR",
        countryIata: "PFAXR",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Motu-mute",
        city: "Bora Bora",
        iata: "BOB",
        countryIata: "PFBOB",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Faaite",
        city: "Faaite",
        iata: "FAC",
        countryIata: "PFFAC",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Fakarava",
        city: "Fakarava",
        iata: "FAV",
        countryIata: "PFFAV",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Fangatau",
        city: "Fangatau",
        iata: "FGU",
        countryIata: "PFFGU",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Fakahina",
        city: "Fakahina",
        iata: "FHZ",
        countryIata: "PFFHZ",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Gambier Is",
        city: "Gambier Is",
        iata: "GMR",
        countryIata: "PFGMR",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Hikueru",
        city: "Hikueru",
        iata: "HHZ",
        countryIata: "PFHHZ",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Hiva Oa",
        city: "Hiva Oa",
        iata: "HIX",
        countryIata: "PFHIX",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Hao Island",
        city: "Hao Island",
        iata: "HOI",
        countryIata: "PFHOI",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Huahine",
        city: "Huahine",
        iata: "HUH",
        countryIata: "PFHUH",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Kauehi",
        city: "Kauehi",
        iata: "KHZ",
        countryIata: "PFKHZ",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Kaukura Atoll",
        city: "Kaukura Atoll",
        iata: "KKR",
        countryIata: "PFKKR",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Maupiti Airport",
        city: "Maupiti",
        iata: "MAU",
        countryIata: "PFMAU",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Makemo",
        city: "Makemo",
        iata: "MKP",
        countryIata: "PFMKP",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Temae",
        city: "Moorea",
        iata: "MOZ",
        countryIata: "PFMOZ",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Mataiva",
        city: "Mataiva",
        iata: "MVT",
        countryIata: "PFMVT",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Napuka Island",
        city: "Napuka Island",
        iata: "NAU",
        countryIata: "PFNAU",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Nuku Hiva",
        city: "Nuku Hiva",
        iata: "NHV",
        countryIata: "PFNHV",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Nukutavake",
        city: "Nukutavake",
        iata: "NUK",
        countryIata: "PFNUK",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Puka Puka",
        city: "Puka Puka",
        iata: "PKP",
        countryIata: "PFPKP",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Faaa",
        city: "Papeete",
        iata: "PPT",
        countryIata: "PFPPT",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Pukarua",
        city: "Pukarua",
        iata: "PUK",
        countryIata: "PFPUK",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Reao",
        city: "Reao",
        iata: "REA",
        countryIata: "PFREA",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Raiatea",
        city: "Raiatea",
        iata: "RFP",
        countryIata: "PFRFP",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Rangiroa",
        city: "Rangiroa",
        iata: "RGI",
        countryIata: "PFRGI",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Rurutu",
        city: "Rurutu",
        iata: "RUR",
        countryIata: "PFRUR",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Tikehau Atoll",
        city: "Tikehau Atoll",
        iata: "TIH",
        countryIata: "PFTIH",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Takume",
        city: "Takume",
        iata: "TJN",
        countryIata: "PFTJN",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Takapoto",
        city: "Takapoto",
        iata: "TKP",
        countryIata: "PFTKP",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Tatakoto",
        city: "Tatakoto",
        iata: "TKV",
        countryIata: "PFTKV",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Takaroa",
        city: "Takaroa",
        iata: "TKX",
        countryIata: "PFTKX",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Tetiaroa Is",
        city: "Tetiaroa Is",
        iata: "TTI",
        countryIata: "PFTTI",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Tubuai",
        city: "Tubuai",
        iata: "TUB",
        countryIata: "PFTUB",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Ua Huka",
        city: "Ua Huka",
        iata: "UAH",
        countryIata: "PFUAH",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Ua Pou",
        city: "Ua Pou",
        iata: "UAP",
        countryIata: "PFUAP",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Vahitahi",
        city: "Vahitahi",
        iata: "VHZ",
        countryIata: "PFVHZ",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Manihi",
        city: "Manihi",
        iata: "XMH",
        countryIata: "PFXMH",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Tureira",
        city: "Tureira",
        iata: "ZTA",
        countryIata: "PFZTA",
        country: "French Polynesia",
        countryCode: "PF",
    },
    {
        airportName: "Akieni",
        city: "Akieni",
        iata: "AKE",
        countryIata: "GAAKE",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Alowe",
        city: "Alowe",
        iata: "AWE",
        countryIata: "GAAWE",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Biawonque",
        city: "Biawonque",
        iata: "BAW",
        countryIata: "GABAW",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Booue",
        city: "Booue",
        iata: "BGB",
        countryIata: "GABGB",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Bongo",
        city: "Bongo",
        iata: "BGP",
        countryIata: "GABGP",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Bitam",
        city: "Bitam",
        iata: "BMM",
        countryIata: "GABMM",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Fougamou",
        city: "Fougamou",
        iata: "FOU",
        countryIata: "GAFOU",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Gamba",
        city: "Gamba",
        iata: "GAX",
        countryIata: "GAGAX",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Miele Mimbale",
        city: "Miele Mimbale",
        iata: "GIM",
        countryIata: "GAGIM",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Kongoboumba",
        city: "Kongoboumba",
        iata: "GKO",
        countryIata: "GAGKO",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Iguela",
        city: "Iguela",
        iata: "IGE",
        countryIata: "GAIGE",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "N'Djole",
        city: "N'Djole",
        iata: "KDJ",
        countryIata: "GAKDJ",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Ndende",
        city: "Ndende",
        iata: "KDN",
        countryIata: "GAKDN",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Mandji",
        city: "Mandji",
        iata: "KMD",
        countryIata: "GAKMD",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Koulamoutou",
        city: "Koulamoutou",
        iata: "KOU",
        countryIata: "GAKOU",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Lambarene",
        city: "Lambarene",
        iata: "LBQ",
        countryIata: "GALBQ",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Libreville",
        city: "Libreville",
        iata: "LBV",
        countryIata: "GALBV",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Leconi",
        city: "Leconi",
        iata: "LEO",
        countryIata: "GALEO",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Lastourville",
        city: "Lastourville",
        iata: "LTL",
        countryIata: "GALTL",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Mbigou",
        city: "Mbigou",
        iata: "MBC",
        countryIata: "GAMBC",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Medouneu",
        city: "Medouneu",
        iata: "MDV",
        countryIata: "GAMDV",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Moanda",
        city: "Moanda",
        iata: "MFF",
        countryIata: "GAMFF",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Manega",
        city: "Manega",
        iata: "MGO",
        countryIata: "GAMGO",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Moabi",
        city: "Moabi",
        iata: "MGX",
        countryIata: "GAMGX",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Mouila",
        city: "Mouila",
        iata: "MJL",
        countryIata: "GAMJL",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Mekambo",
        city: "Mekambo",
        iata: "MKB",
        countryIata: "GAMKB",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Makokou",
        city: "Makokou",
        iata: "MKU",
        countryIata: "GAMKU",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Franceville/Mvengue",
        city: "Franceville",
        iata: "MVB",
        countryIata: "GAMVB",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Mevang",
        city: "Mevang",
        iata: "MVG",
        countryIata: "GAMVG",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Minvoul",
        city: "Minvoul",
        iata: "MVX",
        countryIata: "GAMVX",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Mayoumba",
        city: "Mayoumba",
        iata: "MYB",
        countryIata: "GAMYB",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Mitzic",
        city: "Mitzic",
        iata: "MZC",
        countryIata: "GAMZC",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Nkan",
        city: "Nkan",
        iata: "NKA",
        countryIata: "GANKA",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Okondja",
        city: "Okondja",
        iata: "OKN",
        countryIata: "GAOKN",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Omboue",
        city: "Omboue",
        iata: "OMB",
        countryIata: "GAOMB",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Ouanga",
        city: "Ouanga",
        iata: "OUU",
        countryIata: "GAOUU",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Owendo",
        city: "Owendo",
        iata: "OWE",
        countryIata: "GAOWE",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Oyem",
        city: "Oyem",
        iata: "OYE",
        countryIata: "GAOYE",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Port Gentil",
        city: "Port Gentil",
        iata: "POG",
        countryIata: "GAPOG",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Tchibanga",
        city: "Tchibanga",
        iata: "TCH",
        countryIata: "GATCH",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Wagny",
        city: "Wagny",
        iata: "WGY",
        countryIata: "GAWGY",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Wora Na Ye",
        city: "Wora Na Ye",
        iata: "WNE",
        countryIata: "GAWNE",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Sette Cama",
        city: "Sette Cama",
        iata: "ZKM",
        countryIata: "GAZKM",
        country: "Gabon",
        countryCode: "GA",
    },
    {
        airportName: "Batumi",
        city: "Batumi",
        iata: "BUS",
        countryIata: "GEBUS",
        country: "Georgia",
        countryCode: "GE",
    },
    {
        airportName: "Kutaisi",
        city: "Kutaisi",
        iata: "KUT",
        countryIata: "GEKUT",
        country: "Georgia",
        countryCode: "GE",
    },
    {
        airportName: "Babusheri",
        city: "Sukhumi",
        iata: "SUI",
        countryIata: "GESUI",
        country: "Georgia",
        countryCode: "GE",
    },
    {
        airportName: "Novo Alexeyevka",
        city: "Tbilisi",
        iata: "TBS",
        countryIata: "GETBS",
        country: "Georgia",
        countryCode: "GE",
    },
    {
        airportName: "Aachen/Merzbrück",
        city: "Aachen",
        iata: "AAH",
        countryIata: "DEAAH",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Augsburg Airport (Flughafen Augsburg)",
        city: "Augsburg",
        iata: "AGB",
        countryIata: "DEAGB",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Flugplatz Wangerooge",
        city: "Wangerooge",
        iata: "AGE",
        countryIata: "DEAGE",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Altenburg Nobitz",
        city: "Altenburg",
        iata: "AOC",
        countryIata: "DEAOC",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Barth",
        city: "Barth",
        iata: "BBH",
        countryIata: "DEBBH",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Bitburg Air Base",
        city: "Bitburg",
        iata: "BBJ",
        countryIata: "DEBBJ",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Metropolitan Area",
        city: "Berlin",
        iata: "BER",
        countryIata: "DEBER",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Bielefeld",
        city: "Bielefeld",
        iata: "BFE",
        countryIata: "DEBFE",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "R.A.F.",
        city: "Brueggen",
        iata: "BGN",
        countryIata: "DEBGN",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Borkum",
        city: "Borkum",
        iata: "BMK",
        countryIata: "DEBMK",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Baltrum",
        city: "Baltrum",
        iata: "BMR",
        countryIata: "DEBMR",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Bremen",
        city: "Bremen",
        iata: "BRE",
        countryIata: "DEBRE",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Bremerhaven",
        city: "Bremerhaven",
        iata: "BRV",
        countryIata: "DEBRV",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Braunschweig",
        city: "Braunschweig",
        iata: "BWE",
        countryIata: "DEBWE",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Bindlacher-Berg",
        city: "Bayreuth",
        iata: "BYU",
        countryIata: "DEBYU",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Cottbus-Drewitz Airport",
        city: "Cottbus",
        iata: "CBU",
        countryIata: "DECBU",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Cologne/Bonn",
        city: "Cologne",
        iata: "CGN",
        countryIata: "DECGN",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Magdeburg-Cochstedt Airport",
        city: "Magdeburg",
        iata: "CSO",
        countryIata: "DECSO",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Dresden Airport (Dresden-Klotzsche Airport)",
        city: "Dresden",
        iata: "DRS",
        countryIata: "DEDRS",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Dortmund",
        city: "Dortmund",
        iata: "DTM",
        countryIata: "DEDTM",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Flughafen Düsseldorf International",
        city: "Duesseldorf",
        iata: "DUS",
        countryIata: "DEDUS",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Eisenach",
        city: "Eisenach",
        iata: "EIB",
        countryIata: "DEEIB",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Emden Airport",
        city: "Emden",
        iata: "EME",
        countryIata: "DEEME",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Erfurt-Weimar Airport",
        city: "Erfurt",
        iata: "ERF",
        countryIata: "DEERF",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Essen",
        city: "Essen",
        iata: "ESS",
        countryIata: "DEESS",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Neumuenster",
        city: "Neumuenster",
        iata: "EUM",
        countryIata: "DEEUM",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Friedrichshafen Airport (Bodensee Airport)",
        city: "Friedrichshafen",
        iata: "FDH",
        countryIata: "DEFDH",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Fuerstenfeldbruck",
        city: "Fürstenfeldbruck",
        iata: "FEL",
        countryIata: "DEFEL",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Soellingen",
        city: "Karlsruhe/Baden Baden",
        iata: "FKB",
        countryIata: "DEFKB",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Schaferhaus",
        city: "Flensburg",
        iata: "FLF",
        countryIata: "DEFLF",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Memmingen Airport",
        city: "Memmingen",
        iata: "FMM",
        countryIata: "DEFMM",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Münster Osnabrück International Airport",
        city: "Muenster/Osnabrueck",
        iata: "FMO",
        countryIata: "DEFMO",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Neubrandenburg Airport",
        city: "Neubrandenburg",
        iata: "FNB",
        countryIata: "DEFNB",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "International",
        city: "Frankfurt/Main",
        iata: "FRA",
        countryIata: "DEFRA",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Rhein-Main AFB",
        city: "Frankfurt/Main",
        iata: "FRF",
        countryIata: "DEFRF",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Fritzlar Airbase",
        city: "Fritzlar",
        iata: "FRZ",
        countryIata: "DEFRZ",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Giebelstadt",
        city: "Giebelstadt",
        iata: "GHF",
        countryIata: "DEGHF",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Geilenkirchen",
        city: "Geilenkirchen",
        iata: "GKE",
        countryIata: "DEGKE",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Rheindahlen",
        city: "Rheindahlen",
        iata: "GMY",
        countryIata: "DEGMY",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Guettin",
        city: "Guettin",
        iata: "GTI",
        countryIata: "DEGTI",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Guetersloh",
        city: "Guetersloh",
        iata: "GUT",
        countryIata: "DEGUT",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Sylt Airport",
        city: "Westerland",
        iata: "GWT",
        countryIata: "DEGWT",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "RAF Gatow (airport no longer in operation)",
        city: "Berlin",
        iata: "GWW",
        countryIata: "DEGWW",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Hannover-Langenhagen Airport",
        city: "Hanover",
        iata: "HAJ",
        countryIata: "DEHAJ",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Fuhlsbuettel",
        city: "Hamburg",
        iata: "HAM",
        countryIata: "DEHAM",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Heidelberg",
        city: "Heidelberg",
        iata: "HDB",
        countryIata: "DEHDB",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Heringsdorf Airport",
        city: "Heringsdorf",
        iata: "HDF",
        countryIata: "DEHDF",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Heide-Büsum Airport",
        city: "Heide/Buesum",
        iata: "HEI",
        countryIata: "DEHEI",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Hahn",
        city: "Frankfurt/Main",
        iata: "HHN",
        countryIata: "DEHHN",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Hof",
        city: "Hof",
        iata: "HOQ",
        countryIata: "DEHOQ",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Riesa",
        city: "Riesa",
        iata: "IES",
        countryIata: "DEIES",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Illis Airbase",
        city: "Illisheim",
        iata: "ILH",
        countryIata: "DEILH",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Juist",
        city: "Juist Island",
        iata: "JUI",
        countryIata: "DEJUI",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Kaiserslautern",
        city: "Kaiserslautern",
        iata: "KLT",
        countryIata: "DEKLT",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Koethen",
        city: "Koethen",
        iata: "KOQ",
        countryIata: "DEKOQ",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Kassel–Calden Airport",
        city: "Kassel",
        iata: "KSF",
        countryIata: "DEKSF",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Kitzingen",
        city: "Kitzingen",
        iata: "KZG",
        countryIata: "DEKZG",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Lübeck Airport",
        city: "Hamburg",
        iata: "LBC",
        countryIata: "DELBC",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Leipzig/Halle Airport",
        city: "Leipzig",
        iata: "LEJ",
        countryIata: "DELEJ",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Langeoog",
        city: "Langeoog",
        iata: "LGO",
        countryIata: "DELGO",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Black Forest Airport",
        city: "Lahr",
        iata: "LHA",
        countryIata: "DELHA",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "R.A.F.",
        city: "Laarbruch",
        iata: "LRC",
        countryIata: "DELRC",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Moenchen-Gl. - DUS Exp",
        city: "Dusseldorf",
        iata: "MGL",
        countryIata: "DEMGL",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Mannheim Airport",
        city: "Mannheim",
        iata: "MHG",
        countryIata: "DEMHG",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Metropolitan Area",
        city: "Munich",
        iata: "MUC",
        countryIata: "DEMUC",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Cuxhaven",
        city: "Nordholz-Spieka",
        iata: "NDZ",
        countryIata: "DENDZ",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Norden",
        city: "Norden",
        iata: "NOD",
        countryIata: "DENOD",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Norddeich",
        city: "Norddeich",
        iata: "NOE",
        countryIata: "DENOE",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Norderney",
        city: "Norderney",
        iata: "NRD",
        countryIata: "DENRD",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Oberpfaffenhofen",
        city: "Oberpfaffenhofen",
        iata: "OBF",
        countryIata: "DEOBF",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Wyk auf Foehr",
        city: "Wyk auf Foehr",
        iata: "OHR",
        countryIata: "DEOHR",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Paderborn Lippstadt Airport",
        city: "Paderborn",
        iata: "PAD",
        countryIata: "DEPAD",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Peenemuende",
        city: "Peenemuende",
        iata: "PEF",
        countryIata: "DEPEF",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Boeblingen",
        city: "Boeblingen",
        iata: "PHM",
        countryIata: "DEPHM",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "St Peter",
        city: "St Peter",
        iata: "PSH",
        countryIata: "DEPSH",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Neu Isenburg",
        city: "Frankfurt",
        iata: "QGV",
        countryIata: "DEQGV",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Husum Schwesing Airport",
        city: "Husum",
        iata: "QHU",
        countryIata: "DEQHU",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Wallmuhle",
        city: "Straubing",
        iata: "RBM",
        countryIata: "DERBM",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Rechlin",
        city: "Rechlin",
        iata: "REB",
        countryIata: "DEREB",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Rostock-Laage Airport",
        city: "Rostock",
        iata: "RLG",
        countryIata: "DERLG",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Ramstein Air Base",
        city: "Ramstein",
        iata: "RMS",
        countryIata: "DERMS",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Saarbrücken Airport, Ensheim",
        city: "Saarbruecken",
        iata: "SCN",
        countryIata: "DESCN",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Sembach",
        city: "Sembach",
        iata: "SEX",
        countryIata: "DESEX",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Siegerland Airport",
        city: "Siegen",
        iata: "SGE",
        countryIata: "DESGE",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Spangdahlem",
        city: "Spangdahlem",
        iata: "SPM",
        countryIata: "DESPM",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Echterdingen",
        city: "Stuttgart",
        iata: "STR",
        countryIata: "DESTR",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Schoenefeld",
        city: "Berlin",
        iata: "SXF",
        countryIata: "DESXF",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Parchim Airport",
        city: "Schwerin",
        iata: "SZW",
        countryIata: "DESZW",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Tempelhof (ceased operating in 2008)",
        city: "Berlin",
        iata: "THF",
        countryIata: "DETHF",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Tegel",
        city: "Berlin",
        iata: "TXL",
        countryIata: "DETXL",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Pforzheim",
        city: "Pforzheim",
        iata: "UPF",
        countryIata: "DEUPF",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Metropolitan Area",
        city: "Wiesbaden",
        iata: "UWE",
        countryIata: "DEUWE",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Varrelbusch",
        city: "Varrelbusch",
        iata: "VAC",
        countryIata: "DEVAC",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Schleswig-Jagel",
        city: "Schleswig-Jagel",
        iata: "WBG",
        countryIata: "DEWBG",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Wildenrath",
        city: "Wildenrath",
        iata: "WID",
        countryIata: "DEWID",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Air Base",
        city: "Wiesbaden",
        iata: "WIE",
        countryIata: "DEWIE",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Wilhelmshaven",
        city: "Wilhelmshaven",
        iata: "WVN",
        countryIata: "DEWVN",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Hamburg Finkenwerder Airport (Airbus)",
        city: "Hamburg/Finkenwerder",
        iata: "XFW",
        countryIata: "DEXFW",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Lemwerder",
        city: "Lemwerder",
        iata: "XLW",
        countryIata: "DEXLW",
        country: "Germany",
        countryCode: "DE",
    },
    {
        airportName: "Kotoka International Airport",
        city: "Accra",
        iata: "ACC",
        countryIata: "GHACC",
        country: "Ghana",
        countryCode: "GH",
    },
    {
        airportName: "Kumasi",
        city: "Kumasi",
        iata: "KMS",
        countryIata: "GHKMS",
        country: "Ghana",
        countryCode: "GH",
    },
    {
        airportName: "Sunyani",
        city: "Sunyani",
        iata: "NYI",
        countryIata: "GHNYI",
        country: "Ghana",
        countryCode: "GH",
    },
    {
        airportName: "Takoradi",
        city: "Takoradi",
        iata: "TKD",
        countryIata: "GHTKD",
        country: "Ghana",
        countryCode: "GH",
    },
    {
        airportName: "Tamale",
        city: "Tamale",
        iata: "TML",
        countryIata: "GHTML",
        country: "Ghana",
        countryCode: "GH",
    },
    {
        airportName: "North Front",
        city: "Gibraltar",
        iata: "GIB",
        countryIata: "GIGIB",
        country: "Gibraltar",
        countryCode: "GI",
    },
    {
        airportName: "Agrinion",
        city: "Agrinion",
        iata: "AGQ",
        countryIata: "GRAGQ",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Karpathos",
        city: "Karpathos",
        iata: "AOK",
        countryIata: "GRAOK",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Athens International Airport",
        city: "Athens",
        iata: "ATH",
        countryIata: "GRATH",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Demokritos Airport",
        city: "Alexandroupolis",
        iata: "AXD",
        countryIata: "GRAXD",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "I. Kapodistrias",
        city: "Kerkyra",
        iata: "CFU",
        countryIata: "GRCFU",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Souda",
        city: "Chania",
        iata: "CHQ",
        countryIata: "GRCHQ",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Drama",
        city: "Drama",
        iata: "DRM",
        countryIata: "GRDRM",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Argostolion",
        city: "Kefallinia",
        iata: "EFL",
        countryIata: "GREFL",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Araxos Airport",
        city: "Patras",
        iata: "GPA",
        countryIata: "GRGPA",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Hellinikon Airport",
        city: "Athens",
        iata: "HEW",
        countryIata: "GRHEW",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Ioannina",
        city: "Ioannina",
        iata: "IOA",
        countryIata: "GRIOA",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Ikaria",
        city: "Ikaria Island",
        iata: "JIK",
        countryIata: "GRJIK",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Chios",
        city: "Chios",
        iata: "JKH",
        countryIata: "GRJKH",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Mikonos",
        city: "Mikonos",
        iata: "JMK",
        countryIata: "GRJMK",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Naxos Airport",
        city: "Naxos",
        iata: "JNX",
        countryIata: "GRJNX",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Skiathos Airport",
        city: "Skiathos (island)",
        iata: "JSI",
        countryIata: "GRJSI",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Spetsai Island",
        city: "Spetsai Island",
        iata: "JSS",
        countryIata: "GRJSS",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Syros Island",
        city: "Syros Island",
        iata: "JSY",
        countryIata: "GRJSY",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Thira",
        city: "Thira",
        iata: "JTR",
        countryIata: "GRJTR",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Astypalaia",
        city: "Astypalaia Island",
        iata: "JTY",
        countryIata: "GRJTY",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Kos",
        city: "Kos",
        iata: "KGS",
        countryIata: "GRKGS",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Kithira",
        city: "Kithira",
        iata: "KIT",
        countryIata: "GRKIT",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Kalamata",
        city: "Kalamata",
        iata: "KLX",
        countryIata: "GRKLX",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Kasos Island",
        city: "Kasos Island",
        iata: "KSJ",
        countryIata: "GRKSJ",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Aristoteles Airport",
        city: "Kastoria",
        iata: "KSO",
        countryIata: "GRKSO",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Megas Alexandros Airport",
        city: "Kavala",
        iata: "KVA",
        countryIata: "GRKVA",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Philippos Airport",
        city: "Kozani",
        iata: "KZI",
        countryIata: "GRKZI",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Kastelorizo Island Public Airport",
        city: "Kastelorizo",
        iata: "KZS",
        countryIata: "GRKZS",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Larisa",
        city: "Larisa",
        iata: "LRA",
        countryIata: "GRLRA",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Leros",
        city: "Leros",
        iata: "LRS",
        countryIata: "GRLRS",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Lemnos",
        city: "Lemnos",
        iata: "LXS",
        countryIata: "GRLXS",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Mytilene",
        city: "Mytilene",
        iata: "MJT",
        countryIata: "GRMJT",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Milos",
        city: "Milos",
        iata: "MLO",
        countryIata: "GRMLO",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Paros",
        city: "Paros",
        iata: "PAS",
        countryIata: "GRPAS",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Alexion",
        city: "Porto Kheli",
        iata: "PKH",
        countryIata: "GRPKH",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Aktion",
        city: "Preveza/Lefkas",
        iata: "PVK",
        countryIata: "GRPVK",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Andravida",
        city: "Pyrgos",
        iata: "PYR",
        countryIata: "GRPYR",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Diagoras Airport",
        city: "Rhodes",
        iata: "RHO",
        countryIata: "GRRHO",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Makedonia Airport",
        city: "Thessaloniki",
        iata: "SKG",
        countryIata: "GRSKG",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Skiros",
        city: "Skiros",
        iata: "SKU",
        countryIata: "GRSKU",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Samos",
        city: "Samos",
        iata: "SMI",
        countryIata: "GRSMI",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Sparta",
        city: "Sparta",
        iata: "SPJ",
        countryIata: "GRSPJ",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Nea Anchialos National Airport",
        city: "Volos",
        iata: "VOL",
        countryIata: "GRVOL",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Zakynthos International Airport, Dionysios Solomos",
        city: "Zakinthos Island",
        iata: "ZTH",
        countryIata: "GRZTH",
        country: "Greece",
        countryCode: "GR",
    },
    {
        airportName: "Tasiilaq",
        city: "Tasiilaq",
        iata: "AGM",
        countryIata: "GLAGM",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Neerlerit Inaat",
        city: "Neerlerit Inaat",
        iata: "CNP",
        countryIata: "GLCNP",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Dundas",
        city: "Dundas",
        iata: "DUN",
        countryIata: "GLDUN",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Nuuk",
        city: "Nuuk",
        iata: "GOH",
        countryIata: "GLGOH",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Ilulissat",
        city: "Ilulissat",
        iata: "JAV",
        countryIata: "GLJAV",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Qasigiannguit",
        city: "Qasigiannguit",
        iata: "JCH",
        countryIata: "GLJCH",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Aasiaat",
        city: "Aasiaat",
        iata: "JEG",
        countryIata: "GLJEG",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Paamiut",
        city: "Paamiut",
        iata: "JFR",
        countryIata: "GLJFR",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Qeqertarsuaq",
        city: "Qeqertarsuaq",
        iata: "JGO",
        countryIata: "GLJGO",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Heliport",
        city: "Groennedal",
        iata: "JGR",
        countryIata: "GLJGR",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Sisimiut",
        city: "Sisimiut",
        iata: "JHS",
        countryIata: "GLJHS",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Heliport",
        city: "Qaqortoq",
        iata: "JJU",
        countryIata: "GLJJU",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Nanortalik",
        city: "Nanortalik",
        iata: "JNN",
        countryIata: "GLJNN",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Heliport",
        city: "Narsaq",
        iata: "JNS",
        countryIata: "GLJNS",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Qaarsut",
        city: "Qaarsut",
        iata: "JQA",
        countryIata: "GLJQA",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Arsuk",
        city: "Arsuk",
        iata: "JRK",
        countryIata: "GLJRK",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Heliport",
        city: "Maniitsoq",
        iata: "JSU",
        countryIata: "GLJSU",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Upernavik Airport, Heliport",
        city: "Upernavik",
        iata: "JUV",
        countryIata: "GLJUV",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Kulusuk",
        city: "Kulusuk",
        iata: "KUS",
        countryIata: "GLKUS",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Alluitsup Paa",
        city: "Alluitsup Paa",
        iata: "LLU",
        countryIata: "GLLLU",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Qaanaaq",
        city: "Qaanaaq",
        iata: "NAQ",
        countryIata: "GLNAQ",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Ittoqqortoormiit",
        city: "Ittoqqortoormiit",
        iata: "OBY",
        countryIata: "GLOBY",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Kangerlussuaq",
        city: "Kangerlussuaq",
        iata: "SFJ",
        countryIata: "GLSFJ",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "St John Island",
        city: "Kangerlussuaq",
        iata: "SJF",
        countryIata: "GLSJF",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Pituffik",
        city: "Pituffik",
        iata: "THU",
        countryIata: "GLTHU",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Narsarsuaq",
        city: "Narsarsuaq",
        iata: "UAK",
        countryIata: "GLUAK",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Uummannaq",
        city: "Uummannaq",
        iata: "UMD",
        countryIata: "GLUMD",
        country: "Greenland",
        countryCode: "GL",
    },
    {
        airportName: "Carriacou Island",
        city: "Carriacou Island",
        iata: "CRU",
        countryIata: "GDCRU",
        country: "Grenada",
        countryCode: "GD",
    },
    {
        airportName: "Point Saline International",
        city: "Grenada",
        iata: "GND",
        countryIata: "GDGND",
        country: "Grenada",
        countryCode: "GD",
    },
    {
        airportName: "Baillif",
        city: "Basse Terre",
        iata: "BBR",
        countryIata: "GPBBR",
        country: "Guadeloupe",
        countryCode: "GP",
    },
    {
        airportName: "Grand Case",
        city: "St. Martin",
        iata: "CCE",
        countryIata: "GPCCE",
        country: "Guadeloupe",
        countryCode: "GP",
    },
    {
        airportName: "La Desirade",
        city: "La Desirade",
        iata: "DSD",
        countryIata: "GPDSD",
        country: "Guadeloupe",
        countryCode: "GP",
    },
    {
        airportName: "Les Bases",
        city: "Marie Galante",
        iata: "GBJ",
        countryIata: "GPGBJ",
        country: "Guadeloupe",
        countryCode: "GP",
    },
    {
        airportName: "Terre-de-Bas",
        city: "Terre-de-Bas",
        iata: "HTB",
        countryIata: "GPHTB",
        country: "Guadeloupe",
        countryCode: "GP",
    },
    {
        airportName: "Terre-de-Haut",
        city: "Terre-de-Haut",
        iata: "LSS",
        countryIata: "GPLSS",
        country: "Guadeloupe",
        countryCode: "GP",
    },
    {
        airportName: "Marigot SPB",
        city: "St Martin",
        iata: "MSB",
        countryIata: "GPMSB",
        country: "Guadeloupe",
        countryCode: "GP",
    },
    {
        airportName: "Le Raizet",
        city: "Pointe-a-Pitre",
        iata: "PTP",
        countryIata: "GPPTP",
        country: "Guadeloupe",
        countryCode: "GP",
    },
    {
        airportName: "Saint Barthélemy Airport",
        city: "St Barthelemy",
        iata: "SBH",
        countryIata: "GPSBH",
        country: "Guadeloupe",
        countryCode: "GP",
    },
    {
        airportName: "St Francois",
        city: "St Francois",
        iata: "SFC",
        countryIata: "GPSFC",
        country: "Guadeloupe",
        countryCode: "GP",
    },
    {
        airportName: "Esperance",
        city: "St Martin",
        iata: "SFG",
        countryIata: "GPSFG",
        country: "Guadeloupe",
        countryCode: "GP",
    },
    {
        airportName: "A.B. Won Pat International",
        city: "Guam",
        iata: "GUM",
        countryIata: "GUGUM",
        country: "Guam",
        countryCode: "GU",
    },
    {
        airportName: "Agana NAS",
        city: "Guam",
        iata: "NGM",
        countryIata: "GUNGM",
        country: "Guam",
        countryCode: "GU",
    },
    {
        airportName: "Uscg Shore St",
        city: "Guam",
        iata: "NRV",
        countryIata: "GUNRV",
        country: "Guam",
        countryCode: "GU",
    },
    {
        airportName: "Anderson AFB",
        city: "Guam",
        iata: "UAM",
        countryIata: "GUUAM",
        country: "Guam",
        countryCode: "GU",
    },
    {
        airportName: "Quetzaltenango Airport",
        city: "Quetzaltenango",
        iata: "AAZ",
        countryIata: "GTAAZ",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Quiche Airport",
        city: "Quiche",
        iata: "AQB",
        countryIata: "GTAQB",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Coban",
        city: "Coban",
        iata: "CBV",
        countryIata: "GTCBV",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Chiquimula",
        city: "Chiquimula",
        iata: "CIQ",
        countryIata: "GTCIQ",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Carmelita",
        city: "Carmelita",
        iata: "CMM",
        countryIata: "GTCMM",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Coatepeque Airport",
        city: "Coatepeque",
        iata: "CTF",
        countryIata: "GTCTF",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Dos Lagunas",
        city: "Dos Lagunas",
        iata: "DON",
        countryIata: "GTDON",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "El Naranjo",
        city: "El Naranjo",
        iata: "ENJ",
        countryIata: "GTENJ",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Santa Elena",
        city: "Flores",
        iata: "FRS",
        countryIata: "GTFRS",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "La Aurora",
        city: "Guatemala City",
        iata: "GUA",
        countryIata: "GTGUA",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Huehuetenango",
        city: "Huehuetenango",
        iata: "HUG",
        countryIata: "GTHUG",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Las Vegas",
        city: "Rio Dulce",
        iata: "LCF",
        countryIata: "GTLCF",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Los Tablones Airport",
        city: "Los Tablones",
        iata: "LOX",
        countryIata: "GTLOX",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Melchor De Menco",
        city: "Melchor De Menco",
        iata: "MCR",
        countryIata: "GTMCR",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Puerto Barrios",
        city: "Puerto Barrios",
        iata: "PBR",
        countryIata: "GTPBR",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Paso Caballos",
        city: "Paso Caballos",
        iata: "PCG",
        countryIata: "GTPCG",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Playa Grande",
        city: "Playa Grande",
        iata: "PKJ",
        countryIata: "GTPKJ",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Poptun",
        city: "Poptun",
        iata: "PON",
        countryIata: "GTPON",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Base Aerea Del Sur",
        city: "Retalhuleu",
        iata: "RER",
        countryIata: "GTRER",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Rubelsanto",
        city: "Rubelsanto",
        iata: "RUV",
        countryIata: "GTRUV",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "El Peten",
        city: "Tikal",
        iata: "TKM",
        countryIata: "GTTKM",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Uaxactun",
        city: "Uaxactun",
        iata: "UAX",
        countryIata: "GTUAX",
        country: "Guatemala",
        countryCode: "GT",
    },
    {
        airportName: "Boke",
        city: "Boke",
        iata: "BKJ",
        countryIata: "GNBKJ",
        country: "Guinea",
        countryCode: "GN",
    },
    {
        airportName: "Conakry",
        city: "Conakry",
        iata: "CKY",
        countryIata: "GNCKY",
        country: "Guinea",
        countryCode: "GN",
    },
    {
        airportName: "Faranah",
        city: "Faranah",
        iata: "FAA",
        countryIata: "GNFAA",
        country: "Guinea",
        countryCode: "GN",
    },
    {
        airportName: "Fria",
        city: "Fria",
        iata: "FIG",
        countryIata: "GNFIG",
        country: "Guinea",
        countryCode: "GN",
    },
    {
        airportName: "Siguiri",
        city: "Siguiri",
        iata: "GII",
        countryIata: "GNGII",
        country: "Guinea",
        countryCode: "GN",
    },
    {
        airportName: "Kankan",
        city: "Kankan",
        iata: "KNN",
        countryIata: "GNKNN",
        country: "Guinea",
        countryCode: "GN",
    },
    {
        airportName: "Kissidougou",
        city: "Kissidougou",
        iata: "KSI",
        countryIata: "GNKSI",
        country: "Guinea",
        countryCode: "GN",
    },
    {
        airportName: "Labe",
        city: "Labe",
        iata: "LEK",
        countryIata: "GNLEK",
        country: "Guinea",
        countryCode: "GN",
    },
    {
        airportName: "Macenta",
        city: "Macenta",
        iata: "MCA",
        countryIata: "GNMCA",
        country: "Guinea",
        countryCode: "GN",
    },
    {
        airportName: "Nzerekore",
        city: "Nzerekore",
        iata: "NZE",
        countryIata: "GNNZE",
        country: "Guinea",
        countryCode: "GN",
    },
    {
        airportName: "Sambailo",
        city: "Koundara",
        iata: "SBI",
        countryIata: "GNSBI",
        country: "Guinea",
        countryCode: "GN",
    },
    {
        airportName: "Bubaque",
        city: "Bubaque",
        iata: "BQE",
        countryIata: "GWBQE",
        country: "Guinea-Bissau",
        countryCode: "GW",
    },
    {
        airportName: "Osvaldo Vieira",
        city: "Bissau",
        iata: "OXB",
        countryIata: "GWOXB",
        country: "Guinea-Bissau",
        countryCode: "GW",
    },
    {
        airportName: "Aishalton",
        city: "Aishalton",
        iata: "AHL",
        countryIata: "GYAHL",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Bemichi",
        city: "Bemichi",
        iata: "BCG",
        countryIata: "GYBCG",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Baramita",
        city: "Baramita",
        iata: "BMJ",
        countryIata: "GYBMJ",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Ekereku",
        city: "Ekereku",
        iata: "EKE",
        countryIata: "GYEKE",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Cheddi Jagan International",
        city: "Georgetown",
        iata: "GEO",
        countryIata: "GYGEO",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Bartica",
        city: "Bartica",
        iata: "GFO",
        countryIata: "GYGFO",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Imbaimadai",
        city: "Imbaimadai",
        iata: "IMB",
        countryIata: "GYIMB",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Kaieteur",
        city: "Kaieteur",
        iata: "KAI",
        countryIata: "GYKAI",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Kamarang",
        city: "Kamarang",
        iata: "KAR",
        countryIata: "GYKAR",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Konawaruk",
        city: "Konawaruk",
        iata: "KKG",
        countryIata: "GYKKG",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Kurupung",
        city: "Kurupung",
        iata: "KPG",
        countryIata: "GYKPG",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Karasabai",
        city: "Karasabai",
        iata: "KRG",
        countryIata: "GYKRG",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Karanambo",
        city: "Karanambo",
        iata: "KRM",
        countryIata: "GYKRM",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Kato",
        city: "Kato",
        iata: "KTO",
        countryIata: "GYKTO",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Lethem",
        city: "Lethem",
        iata: "LTM",
        countryIata: "GYLTM",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Lumid Pau",
        city: "Lumid Pau",
        iata: "LUB",
        countryIata: "GYLUB",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Mahdia",
        city: "Mahdia",
        iata: "MHA",
        countryIata: "GYMHA",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Matthews Ridge",
        city: "Matthews Ridge",
        iata: "MWJ",
        countryIata: "GYMWJ",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Monkey Mountain",
        city: "Monkey Mountain",
        iata: "MYM",
        countryIata: "GYMYM",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Annai",
        city: "Annai",
        iata: "NAI",
        countryIata: "GYNAI",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Ogle",
        city: "Ogle",
        iata: "OGL",
        countryIata: "GYOGL",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Orinduik",
        city: "Orinduik",
        iata: "ORJ",
        countryIata: "GYORJ",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Pipillipai",
        city: "Pipillipai",
        iata: "PIQ",
        countryIata: "GYPIQ",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Port Kaituma",
        city: "Port Kaituma",
        iata: "PKM",
        countryIata: "GYPKM",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Paramakotoi",
        city: "Paramakotoi",
        iata: "PMT",
        countryIata: "GYPMT",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Paruima",
        city: "Paruima",
        iata: "PRR",
        countryIata: "GYPRR",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Sandcreek",
        city: "Sandcreek",
        iata: "SDC",
        countryIata: "GYSDC",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Skeldon",
        city: "Skeldon",
        iata: "SKM",
        countryIata: "GYSKM",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Mabaruma",
        city: "Mabaruma",
        iata: "USI",
        countryIata: "GYUSI",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Maikwak",
        city: "Maikwak",
        iata: "VEG",
        countryIata: "GYVEG",
        country: "Guyana",
        countryCode: "GY",
    },
    {
        airportName: "Cap Haitien",
        city: "Cap Haitien",
        iata: "CAP",
        countryIata: "HTCAP",
        country: "Haiti",
        countryCode: "HT",
    },
    {
        airportName: "Les Cayes",
        city: "Les Cayes",
        iata: "CYA",
        countryIata: "HTCYA",
        country: "Haiti",
        countryCode: "HT",
    },
    {
        airportName: "Jacmel",
        city: "Jacmel",
        iata: "JAK",
        countryIata: "HTJAK",
        country: "Haiti",
        countryCode: "HT",
    },
    {
        airportName: "Jeremie",
        city: "Jeremie",
        iata: "JEE",
        countryIata: "HTJEE",
        country: "Haiti",
        countryCode: "HT",
    },
    {
        airportName: "Mais Gate",
        city: "Port Au Prince",
        iata: "PAP",
        countryIata: "HTPAP",
        country: "Haiti",
        countryCode: "HT",
    },
    {
        airportName: "Port De Paix",
        city: "Port De Paix",
        iata: "PAX",
        countryIata: "HTPAX",
        country: "Haiti",
        countryCode: "HT",
    },
    {
        airportName: "Kullu Manali Airport (Himachal Pradesh)",
        city: "Bhuntar",
        iata: "KUU",
        countryIata: "INKUU",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Ahuas",
        city: "Ahuas",
        iata: "AHS",
        countryIata: "HNAHS",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Brus Laguna",
        city: "Brus Laguna",
        iata: "BHG",
        countryIata: "HNBHG",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Catacamas",
        city: "Catacamas",
        iata: "CAA",
        countryIata: "HNCAA",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Cauquira",
        city: "Cauquira",
        iata: "CDD",
        countryIata: "HNCDD",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Coyoles",
        city: "Coyoles",
        iata: "CYL",
        countryIata: "HNCYL",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Erandique",
        city: "Erandique",
        iata: "EDQ",
        countryIata: "HNEDQ",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Coronel E Soto Cano AB",
        city: "Coronel E Soto Cano AB",
        iata: "ENQ",
        countryIata: "HNENQ",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Gracias",
        city: "Gracias",
        iata: "GAC",
        countryIata: "HNGAC",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Guanaja",
        city: "Guanaja",
        iata: "GJA",
        countryIata: "HNGJA",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Gualaco",
        city: "Gualaco",
        iata: "GUO",
        countryIata: "HNGUO",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Iriona",
        city: "Iriona",
        iata: "IRN",
        countryIata: "HNIRN",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Juticalpa",
        city: "Juticalpa",
        iata: "JUT",
        countryIata: "HNJUT",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Goloson International",
        city: "La Ceiba",
        iata: "LCE",
        countryIata: "HNLCE",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "La Esperanza",
        city: "La Esperanza",
        iata: "LEZ",
        countryIata: "HNLEZ",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Las Limas",
        city: "Las Limas",
        iata: "LLH",
        countryIata: "HNLLH",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Limon",
        city: "Limon",
        iata: "LMH",
        countryIata: "HNLMH",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "La Union",
        city: "La Union",
        iata: "LUI",
        countryIata: "HNLUI",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Marcala",
        city: "Marcala",
        iata: "MRJ",
        countryIata: "HNMRJ",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Olanchito",
        city: "Olanchito",
        iata: "OAN",
        countryIata: "HNOAN",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Yoro",
        city: "Yoro",
        iata: "ORO",
        countryIata: "HNORO",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Palacios",
        city: "Palacios",
        iata: "PCH",
        countryIata: "HNPCH",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Puerto Lempira",
        city: "Puerto Lempira",
        iata: "PEU",
        countryIata: "HNPEU",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Roatan",
        city: "Roatan",
        iata: "RTB",
        countryIata: "HNRTB",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Copan",
        city: "Copan",
        iata: "RUY",
        countryIata: "HNRUY",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Ramon Villeda Morales Intl",
        city: "San Pedro Sula",
        iata: "SAP",
        countryIata: "HNSAP",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Sulaco",
        city: "Sulaco",
        iata: "SCD",
        countryIata: "HNSCD",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Santa Rosa Copan",
        city: "Santa Rosa Copan",
        iata: "SDH",
        countryIata: "HNSDH",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "San Esteban",
        city: "San Esteban",
        iata: "SET",
        countryIata: "HNSET",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Tocoa",
        city: "Tocoa",
        iata: "TCF",
        countryIata: "HNTCF",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Tela",
        city: "Tela",
        iata: "TEA",
        countryIata: "HNTEA",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Toncontin",
        city: "Tegucigalpa",
        iata: "TGU",
        countryIata: "HNTGU",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Capiro",
        city: "Trujillo",
        iata: "TJI",
        countryIata: "HNTJI",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Utila",
        city: "Utila",
        iata: "UII",
        countryIata: "HNUII",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Victoria",
        city: "Victoria",
        iata: "VTA",
        countryIata: "HNVTA",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Palmerola Air Base",
        city: "Comayagua",
        iata: "XPL",
        countryIata: "HNXPL",
        country: "Honduras",
        countryCode: "HN",
    },
    {
        airportName: "Ferihegy",
        city: "Budapest",
        iata: "BUD",
        countryIata: "HUBUD",
        country: "Hungary",
        countryCode: "HU",
    },
    {
        airportName: "Debrecen",
        city: "Debrecen",
        iata: "DEB",
        countryIata: "HUDEB",
        country: "Hungary",
        countryCode: "HU",
    },
    {
        airportName: "Miskolc",
        city: "Miskolc",
        iata: "MCQ",
        countryIata: "HUMCQ",
        country: "Hungary",
        countryCode: "HU",
    },
    {
        airportName: "Saarmelleek/Balaton",
        city: "Saarmelleek",
        iata: "SOB",
        countryIata: "HUSOB",
        country: "Hungary",
        countryCode: "HU",
    },
    {
        airportName: "Akureyri",
        city: "Akureyri",
        iata: "AEY",
        countryIata: "ISAEY",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Borgarfjordur Eystri",
        city: "Borgarfjordur Eystri",
        iata: "BGJ",
        countryIata: "ISBGJ",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Bildudalur",
        city: "Bildudalur",
        iata: "BIU",
        countryIata: "ISBIU",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Bakkafjordur",
        city: "Bakkafjordur",
        iata: "BJD",
        countryIata: "ISBJD",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Blonduos",
        city: "Blonduos",
        iata: "BLO",
        countryIata: "ISBLO",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Breiddalsvik",
        city: "Breiddalsvik",
        iata: "BXV",
        countryIata: "ISBXV",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Djupivogur",
        city: "Djupivogur",
        iata: "DJU",
        countryIata: "ISDJU",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Egilsstadir",
        city: "Egilsstadir",
        iata: "EGS",
        countryIata: "ISEGS",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Fagurholsmyri",
        city: "Fagurholsmyri",
        iata: "FAG",
        countryIata: "ISFAG",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Faskrudsfjordur",
        city: "Faskrudsfjordur",
        iata: "FAS",
        countryIata: "ISFAS",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Flateyri",
        city: "Flateyri",
        iata: "FLI",
        countryIata: "ISFLI",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Gjogur",
        city: "Gjogur",
        iata: "GJR",
        countryIata: "ISGJR",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Grimsey",
        city: "Grimsey",
        iata: "GRY",
        countryIata: "ISGRY",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Grundarfjordur",
        city: "Grundarfjordur",
        iata: "GUU",
        countryIata: "ISGUU",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Hornafjordur",
        city: "Hornafjordur",
        iata: "HFN",
        countryIata: "ISHFN",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Holmavik",
        city: "Holmavik",
        iata: "HVK",
        countryIata: "ISHVK",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Hvammstangi",
        city: "Hvammstangi",
        iata: "HVM",
        countryIata: "ISHVM",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Husavik",
        city: "Husavik",
        iata: "HZK",
        countryIata: "ISHZK",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Isafjordur",
        city: "Isafjordur",
        iata: "IFJ",
        countryIata: "ISIFJ",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Keflavik International",
        city: "Reykjavik",
        iata: "KEF",
        countryIata: "ISKEF",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Reykiahlid",
        city: "Myvatn",
        iata: "MVA",
        countryIata: "ISMVA",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Nordfjordur",
        city: "Nordfjordur",
        iata: "NOR",
        countryIata: "ISNOR",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Olafsfjordur",
        city: "Olafsfjordur",
        iata: "OFJ",
        countryIata: "ISOFJ",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Rif",
        city: "Olafsvik",
        iata: "OLI",
        countryIata: "ISOLI",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Kopasker",
        city: "Kopasker",
        iata: "OPA",
        countryIata: "ISOPA",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Patreksfjordur",
        city: "Patreksfjordur",
        iata: "PFJ",
        countryIata: "ISPFJ",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Metropolitan Area",
        city: "Reykjavik",
        iata: "REK",
        countryIata: "ISREK",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Raufarhofn",
        city: "Raufarhofn",
        iata: "RFN",
        countryIata: "ISRFN",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Reykholar",
        city: "Reykholar",
        iata: "RHA",
        countryIata: "ISRHA",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Reykjavik Domestic",
        city: "Reykjavik",
        iata: "RKV",
        countryIata: "ISRKV",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Saudarkrokur",
        city: "Saudarkrokur",
        iata: "SAK",
        countryIata: "ISSAK",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Seydisfjordur",
        city: "Seydisfjordur",
        iata: "SEJ",
        countryIata: "ISSEJ",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Siglufjordur",
        city: "Siglufjordur",
        iata: "SIJ",
        countryIata: "ISSIJ",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Sudureyri",
        city: "Sudureyri",
        iata: "SUY",
        countryIata: "ISSUY",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Stykkisholmur",
        city: "Stykkisholmur",
        iata: "SYK",
        countryIata: "ISSYK",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Thingeyri",
        city: "Thingeyri",
        iata: "TEY",
        countryIata: "ISTEY",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Thorshofn",
        city: "Thorshofn",
        iata: "THO",
        countryIata: "ISTHO",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Talknafjordur",
        city: "Talknafjordur",
        iata: "TLK",
        countryIata: "ISTLK",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Vestmannaeyjar",
        city: "Vestmannaeyjar",
        iata: "VEY",
        countryIata: "ISVEY",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Vopnafjordur",
        city: "Vopnafjordur",
        iata: "VPN",
        countryIata: "ISVPN",
        country: "Iceland",
        countryCode: "IS",
    },
    {
        airportName: "Kheria",
        city: "Agra",
        iata: "AGR",
        countryIata: "INAGR",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Agatti Island",
        city: "Agatti Island",
        iata: "AGX",
        countryIata: "INAGX",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Aizawl",
        city: "Aizawl",
        iata: "AJL",
        countryIata: "INAJL",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Akola",
        city: "Akola",
        iata: "AKD",
        countryIata: "INAKD",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Sardar Vallabhbhai Patel International Airport",
        city: "Ahmedabad",
        iata: "AMD",
        countryIata: "INAMD",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Bhubaneswar",
        city: "Bhubaneswar",
        iata: "BBI",
        countryIata: "INBBI",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Vadodara",
        city: "Vadodara",
        iata: "BDQ",
        countryIata: "INBDQ",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Bareli",
        city: "Bareli",
        iata: "BEK",
        countryIata: "INBEK",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Bellary",
        city: "Bellary",
        iata: "BEP",
        countryIata: "INBEP",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Rudra Mata",
        city: "Bhuj",
        iata: "BHJ",
        countryIata: "INBHJ",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Bhopal",
        city: "Bhopal",
        iata: "BHO",
        countryIata: "INBHO",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Bhavnagar",
        city: "Bhavnagar",
        iata: "BHU",
        countryIata: "INBHU",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Bikaner",
        city: "Bikaner",
        iata: "BKB",
        countryIata: "INBKB",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Bengaluru International Airport",
        city: "Bangalore",
        iata: "BLR",
        countryIata: "INBLR",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Chhatrapati Shivaji",
        city: "Mumbai",
        iata: "BOM",
        countryIata: "INBOM",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Bhatinda",
        city: "Bhatinda",
        iata: "BUP",
        countryIata: "INBUP",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Car Nicobar",
        city: "Car Nicobar",
        iata: "CBD",
        countryIata: "INCBD",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Kozhikode",
        city: "Kozhikode",
        iata: "CCJ",
        countryIata: "INCCJ",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Netaji Subhas Chandra",
        city: "Kolkata (Calcutta)",
        iata: "CCU",
        countryIata: "INCCU",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Cuddapah",
        city: "Cuddapah",
        iata: "CDP",
        countryIata: "INCDP",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Peelamedu",
        city: "Coimbatore",
        iata: "CJB",
        countryIata: "INCJB",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Cooch Behar",
        city: "Cooch Behar",
        iata: "COH",
        countryIata: "INCOH",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Cochin",
        city: "Cochin",
        iata: "COK",
        countryIata: "INCOK",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Daparizo",
        city: "Daparizo",
        iata: "DAE",
        countryIata: "INDAE",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Darjeeling",
        city: "Darjeeling",
        iata: "DAI",
        countryIata: "INDAI",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Dhanbad",
        city: "Dhanbad",
        iata: "DBD",
        countryIata: "INDBD",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Dehra Dun",
        city: "Dehra Dun",
        iata: "DED",
        countryIata: "INDED",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Indira Gandhi International",
        city: "Delhi",
        iata: "DEL",
        countryIata: "INDEL",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Deparizo",
        city: "Deparizo",
        iata: "DEP",
        countryIata: "INDEP",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Gaggal Airport",
        city: "Dharamsala",
        iata: "DHM",
        countryIata: "INDHM",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Chabua",
        city: "Dibrugarh",
        iata: "DIB",
        countryIata: "INDIB",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Diu",
        city: "Diu",
        iata: "DIU",
        countryIata: "INDIU",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Dimapur",
        city: "Dimapur",
        iata: "DMU",
        countryIata: "INDMU",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Borjhar",
        city: "Gawahati",
        iata: "GAU",
        countryIata: "INGAU",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Gaya",
        city: "Gaya",
        iata: "GAY",
        countryIata: "INGAY",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Dabolim Airport",
        city: "Goa",
        iata: "GOI",
        countryIata: "INGOI",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Gorakhpur",
        city: "Gorakhpur",
        iata: "GOP",
        countryIata: "INGOP",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Guna",
        city: "Guna",
        iata: "GUX",
        countryIata: "INGUX",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Gwalior",
        city: "Gwalior",
        iata: "GWL",
        countryIata: "INGWL",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Hubli",
        city: "Hubli",
        iata: "HBX",
        countryIata: "INHBX",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Khajuraho",
        city: "Khajuraho",
        iata: "HJR",
        countryIata: "INHJR",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Hissar",
        city: "Hissar",
        iata: "HSS",
        countryIata: "INHSS",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Indore",
        city: "Indore",
        iata: "IDR",
        countryIata: "INIDR",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Municipal",
        city: "Imphal",
        iata: "IMF",
        countryIata: "INIMF",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Gandhinagar Airport",
        city: "Nasik",
        iata: "ISK",
        countryIata: "INISK",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Singerbhil",
        city: "Agartala",
        iata: "IXA",
        countryIata: "INIXA",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Bagdogra",
        city: "Bagdogra",
        iata: "IXB",
        countryIata: "INIXB",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Chandigarh",
        city: "Chandigarh",
        iata: "IXC",
        countryIata: "INIXC",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Bamrauli",
        city: "Allahabad",
        iata: "IXD",
        countryIata: "INIXD",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Bajpe",
        city: "Mangalore",
        iata: "IXE",
        countryIata: "INIXE",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Sambre",
        city: "Belgaum",
        iata: "IXG",
        countryIata: "INIXG",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Kailashahar",
        city: "Kailashahar",
        iata: "IXH",
        countryIata: "INIXH",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Lilabari",
        city: "Lilabari",
        iata: "IXI",
        countryIata: "INIXI",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Satwari Airport",
        city: "Jammu",
        iata: "IXJ",
        countryIata: "INIXJ",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Keshod",
        city: "Keshod",
        iata: "IXK",
        countryIata: "INIXK",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Leh",
        city: "Leh",
        iata: "IXL",
        countryIata: "INIXL",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Madurai",
        city: "Madurai",
        iata: "IXM",
        countryIata: "INIXM",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Khowai",
        city: "Khowai",
        iata: "IXN",
        countryIata: "INIXN",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Pathankot",
        city: "Pathankot",
        iata: "IXP",
        countryIata: "INIXP",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Kamalpur",
        city: "Kamalpur",
        iata: "IXQ",
        countryIata: "INIXQ",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Ranchi",
        city: "Ranchi",
        iata: "IXR",
        countryIata: "INIXR",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Kumbhirgram",
        city: "Silchar",
        iata: "IXS",
        countryIata: "INIXS",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Pasighat",
        city: "Pasighat",
        iata: "IXT",
        countryIata: "INIXT",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Chikkalthana",
        city: "Aurangabad",
        iata: "IXU",
        countryIata: "INIXU",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Along",
        city: "Along",
        iata: "IXV",
        countryIata: "INIXV",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Sonari Airport",
        city: "Jamshedpur",
        iata: "IXW",
        countryIata: "INIXW",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Kandla",
        city: "Kandla",
        iata: "IXY",
        countryIata: "INIXY",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Port Blair",
        city: "Port Blair",
        iata: "IXZ",
        countryIata: "INIXZ",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Sanganeer",
        city: "Jaipur",
        iata: "JAI",
        countryIata: "INJAI",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Jodhpur",
        city: "Jodhpur",
        iata: "JDH",
        countryIata: "INJDH",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Govardhanpur",
        city: "Jamnagar",
        iata: "JGA",
        countryIata: "INJGA",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Jagdalpur",
        city: "Jagdalpur",
        iata: "JGB",
        countryIata: "INJGB",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Jabalpur",
        city: "Jabalpur",
        iata: "JLR",
        countryIata: "INJLR",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Rowriah",
        city: "Jorhat",
        iata: "JRH",
        countryIata: "INJRH",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Jaisalmer",
        city: "Jaisalmer",
        iata: "JSA",
        countryIata: "INJSA",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Kolhapur",
        city: "Kolhapur",
        iata: "KLH",
        countryIata: "INKLH",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Kota",
        city: "Kota",
        iata: "KTU",
        countryIata: "INKTU",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Malda",
        city: "Malda",
        iata: "LDA",
        countryIata: "INLDA",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Amausi",
        city: "Lucknow",
        iata: "LKO",
        countryIata: "INLKO",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Ludhiana",
        city: "Ludhiana",
        iata: "LUH",
        countryIata: "INLUH",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Mohanbari",
        city: "Mohanbari",
        iata: "MOH",
        countryIata: "INMOH",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Mysore",
        city: "Mysore",
        iata: "MYQ",
        countryIata: "INMYQ",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Muzaffarnagar",
        city: "Muzaffarnagar",
        iata: "MZA",
        countryIata: "INMZA",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Muzaffarpur",
        city: "Muzaffarpur",
        iata: "MZU",
        countryIata: "INMZU",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Sonegaon",
        city: "Nagpur",
        iata: "NAG",
        countryIata: "INNAG",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Nanded",
        city: "Nanded",
        iata: "NDC",
        countryIata: "INNDC",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Daman",
        city: "Daman",
        iata: "NMB",
        countryIata: "INNMB",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Neyveli",
        city: "Neyveli",
        iata: "NVY",
        countryIata: "INNVY",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Osmanabad",
        city: "Osmanabad",
        iata: "OMN",
        countryIata: "INOMN",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Bilaspur",
        city: "Bilaspur",
        iata: "PAB",
        countryIata: "INPAB",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Patna",
        city: "Patna",
        iata: "PAT",
        countryIata: "INPAT",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Porbandar",
        city: "Porbandar",
        iata: "PBD",
        countryIata: "INPBD",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Pantnagar",
        city: "Pantnagar",
        iata: "PGH",
        countryIata: "INPGH",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Lohegaon",
        city: "Pune (Poona)",
        iata: "PNQ",
        countryIata: "INPNQ",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Pondicherry",
        city: "Pondicherry (see: Puducherry)",
        iata: "PNY",
        countryIata: "INPNY",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Puttaprathe",
        city: "Puttaparthi",
        iata: "PUT",
        countryIata: "INPUT",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Jeypore",
        city: "Jeypore",
        iata: "PYB",
        countryIata: "INPYB",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Rajkot Civil Airport",
        city: "Rajkot",
        iata: "RAJ",
        countryIata: "INRAJ",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Rewa",
        city: "Rewa",
        iata: "REW",
        countryIata: "INREW",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Balurghat",
        city: "Balurghat",
        iata: "RGH",
        countryIata: "INRGH",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Rajahmundry",
        city: "Rajahmundry",
        iata: "RJA",
        countryIata: "INRJA",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Rajouri",
        city: "Rajouri",
        iata: "RJI",
        countryIata: "INRJI",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Ramagundam",
        city: "Ramagundam",
        iata: "RMD",
        countryIata: "INRMD",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Raipur",
        city: "Raipur",
        iata: "RPR",
        countryIata: "INRPR",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Rourkela",
        city: "Rourkela",
        iata: "RRK",
        countryIata: "INRRK",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Ratnagiri",
        city: "Ratnagiri",
        iata: "RTC",
        countryIata: "INRTC",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Rupsi",
        city: "Rupsi",
        iata: "RUP",
        countryIata: "INRUP",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Shillong",
        city: "Shillong",
        iata: "SHL",
        countryIata: "INSHL",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Simla",
        city: "Simla",
        iata: "SLV",
        countryIata: "INSLV",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Solapur Airport",
        city: "Sholapur",
        iata: "SSE",
        countryIata: "INSSE",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Surat",
        city: "Surat",
        iata: "STV",
        countryIata: "INSTV",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Srinagar",
        city: "Srinagar",
        iata: "SXR",
        countryIata: "INSXR",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Salem",
        city: "Salem",
        iata: "SXV",
        countryIata: "INSXV",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Tuticorin",
        city: "Tuticorin",
        iata: "TCR",
        countryIata: "INTCR",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Tezu",
        city: "Tezu",
        iata: "TEI",
        countryIata: "INTEI",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Salonibari",
        city: "Tezpur",
        iata: "TEZ",
        countryIata: "INTEZ",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Tirupati",
        city: "Tirupati",
        iata: "TIR",
        countryIata: "INTIR",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Thanjavur",
        city: "Thanjavur",
        iata: "TJV",
        countryIata: "INTJV",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Satna",
        city: "Satna",
        iata: "TNI",
        countryIata: "INTNI",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "International",
        city: "Thiruvananthapuram",
        iata: "TRV",
        countryIata: "INTRV",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Civil",
        city: "Tiruchirapally",
        iata: "TRZ",
        countryIata: "INTRZ",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Dabok",
        city: "Udaipur",
        iata: "UDR",
        countryIata: "INUDR",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Vijayawada",
        city: "Vijayawada",
        iata: "VGA",
        countryIata: "INVGA",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Varanasi",
        city: "Varanasi",
        iata: "VNS",
        countryIata: "INVNS",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Vishakhapatnam",
        city: "Vishakhapatnam",
        iata: "VTZ",
        countryIata: "INVTZ",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Warrangal",
        city: "Warrangal",
        iata: "WGC",
        countryIata: "INWGC",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Zero",
        city: "Zero",
        iata: "ZER",
        countryIata: "INZER",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "APT Pranoto Airport",
        city: "Samarinda",
        iata: "AAP",
        countryIata: "IDAAP",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Apalapsili",
        city: "Apalapsili",
        iata: "AAS",
        countryIata: "IDAAS",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Atambua",
        city: "Atambua",
        iata: "ABU",
        countryIata: "IDABU",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Aek Godang",
        city: "Aek Godang",
        iata: "AEG",
        countryIata: "IDAEG",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Anggi",
        city: "Anggi",
        iata: "AGD",
        countryIata: "IDAGD",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Amahai",
        city: "Amahai",
        iata: "AHI",
        countryIata: "IDAHI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Gunung Batin",
        city: "Astraksetra",
        iata: "AKQ",
        countryIata: "IDAKQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Selaparang",
        city: "Mataram",
        iata: "AMI",
        countryIata: "IDAMI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Pattimura",
        city: "Ambon",
        iata: "AMQ",
        countryIata: "IDAMQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Alor Island",
        city: "Alor Island",
        iata: "ARD",
        countryIata: "IDARD",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Arso",
        city: "Arso",
        iata: "ARJ",
        countryIata: "IDARJ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Atauro",
        city: "Atauro",
        iata: "AUT",
        countryIata: "IDAUT",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Ayawasi",
        city: "Ayawasi",
        iata: "AYW",
        countryIata: "IDAYW",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "English Madeira",
        city: "Baucau",
        iata: "BCH",
        countryIata: "IDBCH",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sjamsudin Noor",
        city: "Banjarmasin",
        iata: "BDJ",
        countryIata: "IDBDJ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Husein Sastranegara",
        city: "Bandung",
        iata: "BDO",
        countryIata: "IDBDO",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Berau",
        city: "Berau",
        iata: "BEJ",
        countryIata: "IDBEJ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Mokmer",
        city: "Biak",
        iata: "BIK",
        countryIata: "IDBIK",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Bolaang",
        city: "Bolaang",
        iata: "BJG",
        countryIata: "IDBJG",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Benjina",
        city: "Benjina",
        iata: "BJK",
        countryIata: "IDBJK",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Bajawa",
        city: "Bajawa",
        iata: "BJW",
        countryIata: "IDBJW",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Padangkemiling",
        city: "Bengkulu",
        iata: "BKS",
        countryIata: "IDBKS",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Bima",
        city: "Bima",
        iata: "BMU",
        countryIata: "IDBMU",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sepingan",
        city: "Balikpapan",
        iata: "BPN",
        countryIata: "IDBPN",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Hang Nadim",
        city: "Batam",
        iata: "BTH",
        countryIata: "IDBTH",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Blang Bintang",
        city: "Banda Aceh",
        iata: "BTJ",
        countryIata: "IDBTJ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Batu Licin",
        city: "Batu Licin",
        iata: "BTW",
        countryIata: "IDBTW",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Bokondini",
        city: "Bokondini",
        iata: "BUI",
        countryIata: "IDBUI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Baubau",
        city: "Baubau",
        iata: "BUW",
        countryIata: "IDBUW",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Babo",
        city: "Babo",
        iata: "BXB",
        countryIata: "IDBXB",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Bade",
        city: "Bade",
        iata: "BXD",
        countryIata: "IDBXD",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Batom",
        city: "Batom",
        iata: "BXM",
        countryIata: "IDBXM",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Bontang",
        city: "Bontang",
        iata: "BXT",
        countryIata: "IDBXT",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Bunyu",
        city: "Bunyu",
        iata: "BYQ",
        countryIata: "IDBYQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Penggung",
        city: "Cirebon",
        iata: "CBN",
        countryIata: "IDCBN",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Soekarno-Hatta International",
        city: "Jakarta",
        iata: "CGK",
        countryIata: "IDCGK",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Cepu",
        city: "Cepu",
        iata: "CPF",
        countryIata: "IDCPF",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tunggul Wulung",
        city: "Cilacap",
        iata: "CXP",
        countryIata: "IDCXP",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sultan Taha Syarifudn",
        city: "Jambi",
        iata: "DJB",
        countryIata: "IDDJB",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sentani",
        city: "Jayapura",
        iata: "DJJ",
        countryIata: "IDDJJ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Dobo Airport",
        city: "Dobo",
        iata: "DOB",
        countryIata: "IDDOB",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Ngurah Rai",
        city: "Denpasar Bali",
        iata: "DPS",
        countryIata: "IDDPS",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Dabra",
        city: "Dabra",
        iata: "DRH",
        countryIata: "IDDRH",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Datadawai Airport",
        city: "Datadawai, Long Lunuk",
        iata: "DTD",
        countryIata: "IDDTD",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Pinang Kampai",
        city: "Dumai",
        iata: "DUM",
        countryIata: "IDDUM",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Elelim",
        city: "Elelim",
        iata: "ELR",
        countryIata: "IDELR",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Ende",
        city: "Ende",
        iata: "ENE",
        countryIata: "IDENE",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Ewer",
        city: "Ewer",
        iata: "EWE",
        countryIata: "IDEWE",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Enarotali",
        city: "Enarotali",
        iata: "EWI",
        countryIata: "IDEWI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Fak Fak",
        city: "Fak Fak",
        iata: "FKQ",
        countryIata: "IDFKQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Numfoor",
        city: "Numfoor",
        iata: "FOO",
        countryIata: "IDFOO",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Gag Island",
        city: "Gag Island",
        iata: "GAV",
        countryIata: "IDGAV",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Gebe",
        city: "Gebe",
        iata: "GEB",
        countryIata: "IDGEB",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Galela",
        city: "Galela",
        iata: "GLX",
        countryIata: "IDGLX",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Gunungsitoli",
        city: "Gunungsitoli",
        iata: "GNS",
        countryIata: "IDGNS",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tolotio",
        city: "Gorontalo",
        iata: "GTO",
        countryIata: "IDGTO",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Halim Perdana Kusuma",
        city: "Jakarta",
        iata: "HLP",
        countryIata: "IDHLP",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Illaga",
        city: "Illaga",
        iata: "ILA",
        countryIata: "IDILA",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Inanwatan",
        city: "Inanwatan",
        iata: "INX",
        countryIata: "IDINX",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Ilu",
        city: "Ilu",
        iata: "IUL",
        countryIata: "IDIUL",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Metropolitan Area",
        city: "Jakarta",
        iata: "JKT",
        countryIata: "IDJKT",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Adisutjipto",
        city: "Yogyakarta",
        iata: "JOG",
        countryIata: "IDJOG",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Kau",
        city: "Kau",
        iata: "KAZ",
        countryIata: "IDKAZ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Karubaga",
        city: "Karubaga",
        iata: "KBF",
        countryIata: "IDKBF",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Kotabaru",
        city: "Kotabaru",
        iata: "KBU",
        countryIata: "IDKBU",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Kambuaya",
        city: "Kambuaya",
        iata: "KBX",
        countryIata: "IDKBX",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Kamur",
        city: "Kamur",
        iata: "KCD",
        countryIata: "IDKCD",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Kon",
        city: "Kon",
        iata: "KCI",
        countryIata: "IDKCI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Wolter Monginsidi",
        city: "Kendari",
        iata: "KDI",
        countryIata: "IDKDI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Keisah",
        city: "Keisah",
        iata: "KEA",
        countryIata: "IDKEA",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Kepi",
        city: "Kepi",
        iata: "KEI",
        countryIata: "IDKEI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Kebar",
        city: "Kebar",
        iata: "KEQ",
        countryIata: "IDKEQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Keluang",
        city: "Keluang",
        iata: "KLQ",
        countryIata: "IDKLQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Kimam",
        city: "Kimam",
        iata: "KMM",
        countryIata: "IDKMM",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Kaimana",
        city: "Kaimana",
        iata: "KNG",
        countryIata: "IDKNG",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Kualanamu International Airport",
        city: "Medan",
        iata: "KNO",
        countryIata: "IDKNO",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Kotabangun",
        city: "Kotabangun",
        iata: "KOD",
        countryIata: "IDKOD",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Eltari",
        city: "Kupang",
        iata: "KOE",
        countryIata: "IDKOE",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Kokonao",
        city: "Kokonao",
        iata: "KOX",
        countryIata: "IDKOX",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Kerinci",
        city: "Kerinci",
        iata: "KRC",
        countryIata: "IDKRC",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Ketapang",
        city: "Ketapang",
        iata: "KTG",
        countryIata: "IDKTG",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Karimunjawa",
        city: "Karimunjawa",
        iata: "KWB",
        countryIata: "IDKWB",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Labuha",
        city: "Labuha",
        iata: "LAH",
        countryIata: "IDLAH",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Mutiara",
        city: "Labuan Bajo",
        iata: "LBJ",
        countryIata: "IDLBJ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Long Bawan",
        city: "Long Bawan",
        iata: "LBW",
        countryIata: "IDLBW",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Lereh",
        city: "Lereh",
        iata: "LHI",
        countryIata: "IDLHI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Mulia",
        city: "Mulia",
        iata: "LII",
        countryIata: "IDLII",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Larantuka",
        city: "Larantuka",
        iata: "LKA",
        countryIata: "IDLKA",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Kelila",
        city: "Kelila",
        iata: "LLN",
        countryIata: "IDLLN",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Long Apung",
        city: "Long Apung",
        iata: "LPU",
        countryIata: "IDLPU",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Lhoksumawe",
        city: "Lhoksumawe",
        iata: "LSW",
        countryIata: "IDLSW",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Lhok Sukon",
        city: "Lhok Sukon",
        iata: "LSX",
        countryIata: "IDLSX",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Langgur",
        city: "Langgur",
        iata: "LUV",
        countryIata: "IDLUV",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Luwuk",
        city: "Luwuk",
        iata: "LUW",
        countryIata: "IDLUW",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Lewoleba",
        city: "Lewoleba",
        iata: "LWE",
        countryIata: "IDLWE",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Lunyuk",
        city: "Lunyuk",
        iata: "LYK",
        countryIata: "IDLYK",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Mangole",
        city: "Mangole",
        iata: "MAL",
        countryIata: "IDMAL",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Samratulangi",
        city: "Manado",
        iata: "MDC",
        countryIata: "IDMDC",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Mindiptana",
        city: "Mindiptana",
        iata: "MDP",
        countryIata: "IDMDP",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Seunagan",
        city: "Meulaboh",
        iata: "MEQ",
        countryIata: "IDMEQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Polania Int'l, since 25 July 2013 Soewondo AFB",
        city: "Medan",
        iata: "MES",
        countryIata: "IDMES",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Mamuju",
        city: "Mamuju",
        iata: "MJU",
        countryIata: "IDMJU",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Mangunjaya",
        city: "Mangunjaya",
        iata: "MJY",
        countryIata: "IDMJY",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Mopah",
        city: "Merauke",
        iata: "MKQ",
        countryIata: "IDMKQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Rendani",
        city: "Manokwari",
        iata: "MKW",
        countryIata: "IDMKW",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Abdul Rachman Saleh",
        city: "Malang",
        iata: "MLG",
        countryIata: "IDMLG",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Melangguane",
        city: "Melangguane",
        iata: "MNA",
        countryIata: "IDMNA",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Waioti",
        city: "Maumere",
        iata: "MOF",
        countryIata: "IDMOF",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Muko-Muko Airport, Southwest Sumatra",
        city: "Muko-Muko",
        iata: "MPC",
        countryIata: "IDMPC",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Maliana",
        city: "Maliana",
        iata: "MPT",
        countryIata: "IDMPT",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Masalembo",
        city: "Masalembo",
        iata: "MSI",
        countryIata: "IDMSI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Muting",
        city: "Muting",
        iata: "MUF",
        countryIata: "IDMUF",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Matak",
        city: "Matak",
        iata: "MWK",
        countryIata: "IDMWK",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Masamba",
        city: "Masamba",
        iata: "MXB",
        countryIata: "IDMXB",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Banaina",
        city: "Banaina",
        iata: "NAF",
        countryIata: "IDNAF",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Naha",
        city: "Naha",
        iata: "NAH",
        countryIata: "IDNAH",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Namlea",
        city: "Namlea",
        iata: "NAM",
        countryIata: "IDNAM",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Nabire",
        city: "Nabire",
        iata: "NBX",
        countryIata: "IDNBX",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Bandanaira",
        city: "Bandanaira",
        iata: "NDA",
        countryIata: "IDNDA",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sinak",
        city: "Sinak",
        iata: "NKD",
        countryIata: "IDNKD",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Nunukan",
        city: "Nunukan",
        iata: "NNX",
        countryIata: "IDNNX",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Nangapinoh",
        city: "Nangapinoh",
        iata: "NPO",
        countryIata: "IDNPO",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Namrole",
        city: "Namrole",
        iata: "NRE",
        countryIata: "IDNRE",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Bintuni",
        city: "Bintuni",
        iata: "NTI",
        countryIata: "IDNTI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Natuna Ranai",
        city: "Natuna Ranai",
        iata: "NTX",
        countryIata: "IDNTX",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Obano",
        city: "Obano",
        iata: "OBD",
        countryIata: "IDOBD",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Ocussi",
        city: "Ocussi",
        iata: "OEC",
        countryIata: "IDOEC",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Oksibil",
        city: "Oksibil",
        iata: "OKL",
        countryIata: "IDOKL",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Okaba",
        city: "Okaba",
        iata: "OKQ",
        countryIata: "IDOKQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Moanamani",
        city: "Moanamani",
        iata: "ONI",
        countryIata: "IDONI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Morotai Island",
        city: "Morotai Island",
        iata: "OTI",
        countryIata: "IDOTI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Palibelo",
        city: "Palibelo",
        iata: "PBW",
        countryIata: "IDPBW",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Pondok Cabe",
        city: "Pondok Cabe",
        iata: "PCB",
        countryIata: "IDPCB",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Pendopo",
        city: "Pendopo",
        iata: "PDO",
        countryIata: "IDPDO",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Pangkalpinang",
        city: "Pangkalpinang",
        iata: "PGK",
        countryIata: "IDPGK",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Pangkalanbuun",
        city: "Pangkalanbuun",
        iata: "PKN",
        countryIata: "IDPKN",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Simpang Tiga",
        city: "Pekanbaru",
        iata: "PKU",
        countryIata: "IDPKU",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Palangkaraya",
        city: "Palangkaraya",
        iata: "PKY",
        countryIata: "IDPKY",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Mahmud Badaruddin Ii",
        city: "Palembang",
        iata: "PLM",
        countryIata: "IDPLM",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Mutiara",
        city: "Palu",
        iata: "PLW",
        countryIata: "IDPLW",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Supadio",
        city: "Pontianak",
        iata: "PNK",
        countryIata: "IDPNK",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Pulau Panjang",
        city: "Pulau Panjang",
        iata: "PPJ",
        countryIata: "IDPPJ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Pasir Pangarayan",
        city: "Pasir Pangarayan",
        iata: "PPR",
        countryIata: "IDPPR",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Poso",
        city: "Poso",
        iata: "PSJ",
        countryIata: "IDPSJ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Putussibau",
        city: "Putussibau",
        iata: "PSU",
        countryIata: "IDPSU",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Pomala",
        city: "Pomala",
        iata: "PUM",
        countryIata: "IDPUM",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Purwokerto",
        city: "Purwokerto",
        iata: "PWL",
        countryIata: "IDPWL",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sugimanuru",
        city: "Raha",
        iata: "RAQ",
        countryIata: "IDRAQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Merdey",
        city: "Merdey",
        iata: "RDE",
        countryIata: "IDRDE",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Japura",
        city: "Rengat",
        iata: "RGT",
        countryIata: "IDRGT",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Rokot",
        city: "Rokot",
        iata: "RKI",
        countryIata: "IDRKI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sipora",
        city: "Sipora",
        iata: "RKO",
        countryIata: "IDRKO",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Ransiki",
        city: "Ransiki",
        iata: "RSK",
        countryIata: "IDRSK",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Ruteng",
        city: "Ruteng",
        iata: "RTG",
        countryIata: "IDRTG",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Roti",
        city: "Roti",
        iata: "RTI",
        countryIata: "IDRTI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Yuruf",
        city: "Yuruf",
        iata: "RUF",
        countryIata: "IDRUF",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sangir",
        city: "Sangir",
        iata: "SAE",
        countryIata: "IDSAE",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sawu",
        city: "Sawu",
        iata: "SAU",
        countryIata: "IDSAU",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sabang",
        city: "Sabang",
        iata: "SBG",
        countryIata: "IDSBG",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Senggeh",
        city: "Senggeh",
        iata: "SEH",
        countryIata: "IDSEH",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sungai Pakning",
        city: "Sungai Pakning",
        iata: "SEQ",
        countryIata: "IDSEQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sanggata",
        city: "Sanggata",
        iata: "SGQ",
        countryIata: "IDSGQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Dabo",
        city: "Singkep",
        iata: "SIQ",
        countryIata: "IDSIQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sibisa",
        city: "Sibisa",
        iata: "SIW",
        countryIata: "IDSIW",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sampit",
        city: "Sampit",
        iata: "SMQ",
        countryIata: "IDSMQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Adi Sumarmo",
        city: "Solo City",
        iata: "SOC",
        countryIata: "IDSOC",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sintang",
        city: "Sintang",
        iata: "SQG",
        countryIata: "IDSQG",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sanana",
        city: "Sanana",
        iata: "SQN",
        countryIata: "IDSQN",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Soroako",
        city: "Soroako",
        iata: "SQR",
        countryIata: "IDSQR",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Achmad Uani",
        city: "Semarang",
        iata: "SRG",
        countryIata: "IDSRG",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Samarinda",
        city: "Samarinda",
        iata: "SRI",
        countryIata: "IDSRI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Juanda",
        city: "Surabaya",
        iata: "SUB",
        countryIata: "IDSUB",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Trunojoyo",
        city: "Sumenep",
        iata: "SUP",
        countryIata: "IDSUP",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Brang Bidji",
        city: "Sumbawa",
        iata: "SWQ",
        countryIata: "IDSWQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Saumlaki",
        city: "Saumlaki",
        iata: "SXK",
        countryIata: "IDSXK",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Senipah",
        city: "Senipah",
        iata: "SZH",
        countryIata: "IDSZH",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Taliabu",
        city: "Taliabu",
        iata: "TAX",
        countryIata: "IDTAX",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tumbang Samba",
        city: "Tumbang Samba",
        iata: "TBM",
        countryIata: "IDTBM",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Timika",
        city: "Tembagapura",
        iata: "TIM",
        countryIata: "IDTIM",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tanjung Balai",
        city: "Tanjung Balai",
        iata: "TJB",
        countryIata: "IDTJB",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tanjung Warukin",
        city: "Tanjung Warukin",
        iata: "TJG",
        countryIata: "IDTJG",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Bulutumbang",
        city: "Tanjung Pandan",
        iata: "TJQ",
        countryIata: "IDTJQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tanjung Selor",
        city: "Tanjung Selor",
        iata: "TJS",
        countryIata: "IDTJS",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Branti",
        city: "Bandar Lampung",
        iata: "TKG",
        countryIata: "IDTKG",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tolitoli",
        city: "Tolitoli",
        iata: "TLI",
        countryIata: "IDTLI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tambolaka",
        city: "Tambolaka",
        iata: "TMC",
        countryIata: "IDTMC",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tanahmerah",
        city: "Tanahmerah",
        iata: "TMH",
        countryIata: "IDTMH",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tiom",
        city: "Tiom",
        iata: "TMY",
        countryIata: "IDTMY",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tanah Grogot",
        city: "Tanah Grogot",
        iata: "TNB",
        countryIata: "IDTNB",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Kidjang",
        city: "Tanjung Pinang",
        iata: "TNJ",
        countryIata: "IDTNJ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tapaktuan",
        city: "Tapaktuan",
        iata: "TPK",
        countryIata: "IDTPK",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tarakan",
        city: "Tarakan",
        iata: "TRK",
        countryIata: "IDTRK",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tanjung Santan",
        city: "Tanjung Santan",
        iata: "TSX",
        countryIata: "IDTSX",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tasikmalaya",
        city: "Tasikmalaya",
        iata: "TSY",
        countryIata: "IDTSY",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Babullah",
        city: "Ternate",
        iata: "TTE",
        countryIata: "IDTTE",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Tana Toraja",
        city: "Tana Toraja",
        iata: "TTR",
        countryIata: "IDTTR",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Teminabuan",
        city: "Teminabuan",
        iata: "TXM",
        countryIata: "IDTXM",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Suai",
        city: "Suai",
        iata: "UAI",
        countryIata: "IDUAI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Ubrub",
        city: "Ubrub",
        iata: "UBR",
        countryIata: "IDUBR",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Zugapa",
        city: "Zugapa",
        iata: "UGU",
        countryIata: "IDUGU",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Buol",
        city: "Buol",
        iata: "UOL",
        countryIata: "IDUOL",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Hasanudin",
        city: "Ujung Pandang",
        iata: "UPG",
        countryIata: "IDUPG",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Viqueque",
        city: "Viqueque",
        iata: "VIQ",
        countryIata: "IDVIQ",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Waris",
        city: "Waris",
        iata: "WAR",
        countryIata: "IDWAR",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Wahai",
        city: "Wahai",
        iata: "WBA",
        countryIata: "IDWBA",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Wagethe",
        city: "Wagethe",
        iata: "WET",
        countryIata: "IDWET",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Waingapu",
        city: "Waingapu",
        iata: "WGP",
        countryIata: "IDWGP",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Wamena",
        city: "Wamena",
        iata: "WMX",
        countryIata: "IDWMX",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Wasior",
        city: "Wasior",
        iata: "WSR",
        countryIata: "IDWSR",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Senggo",
        city: "Senggo",
        iata: "ZEG",
        countryIata: "IDZEG",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Steenkool",
        city: "Steenkool",
        iata: "ZKL",
        countryIata: "IDZKL",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Serui",
        city: "Serui",
        iata: "ZRI",
        countryIata: "IDZRI",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Sarmi",
        city: "Sarmi",
        iata: "ZRM",
        countryIata: "IDZRM",
        country: "Indonesia",
        countryCode: "ID",
    },
    {
        airportName: "Abadan",
        city: "Abadan",
        iata: "ABD",
        countryIata: "IRABD",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Zabol A/P",
        city: "Zabol",
        iata: "ACZ",
        countryIata: "IRACZ",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Ardabil",
        city: "Ardabil",
        iata: "ADU",
        countryIata: "IRADU",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Ahwaz",
        city: "Ahwaz",
        iata: "AWZ",
        countryIata: "IRAWZ",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Yazd",
        city: "Yazd",
        iata: "AZD",
        countryIata: "IRAZD",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Babolsar",
        city: "Babolsar",
        iata: "BBL",
        countryIata: "IRBBL",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Bandar Lengeh",
        city: "Bandar Lengeh",
        iata: "BDH",
        countryIata: "IRBDH",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Bojnord",
        city: "Bojnord",
        iata: "BJB",
        countryIata: "IRBJB",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Bandar Abbas",
        city: "Bandar Abbas",
        iata: "BND",
        countryIata: "IRBND",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Bushehr",
        city: "Bushehr",
        iata: "BUZ",
        countryIata: "IRBUZ",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Bam",
        city: "Bam",
        iata: "BXR",
        countryIata: "IRBXR",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Sarakhs",
        city: "Sarakhs",
        iata: "CKT",
        countryIata: "IRCKT",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Shahre-Kord",
        city: "Shahre-Kord",
        iata: "CQD",
        countryIata: "IRCQD",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Gheshm",
        city: "Gheshm",
        iata: "GSM",
        countryIata: "IRGSM",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Hamadan",
        city: "Hamadan",
        iata: "HDM",
        countryIata: "IRHDM",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Bahregan",
        city: "Bahregan",
        iata: "IAQ",
        countryIata: "IRIAQ",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Isfahan",
        city: "Isfahan",
        iata: "IFN",
        countryIata: "IRIFN",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Imam Khomeini International Airport",
        city: "Tehran",
        iata: "IKA",
        countryIata: "IRIKA",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Zanjan",
        city: "Zanjan",
        iata: "JWN",
        countryIata: "IRJWN",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Jiroft Airport",
        city: "Jiroft",
        iata: "JYR",
        countryIata: "IRJYR",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Kerman",
        city: "Kerman",
        iata: "KER",
        countryIata: "IRKER",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Khaneh",
        city: "Khaneh",
        iata: "KHA",
        countryIata: "IRKHA",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Khorramabad",
        city: "Khorramabad",
        iata: "KHD",
        countryIata: "IRKHD",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Khark Island",
        city: "Khark Island",
        iata: "KHK",
        countryIata: "IRKHK",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Kish Island",
        city: "Kish Island",
        iata: "KIH",
        countryIata: "IRKIH",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Jam",
        city: "Kangan",
        iata: "KNR",
        countryIata: "IRKNR",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Kermanshah",
        city: "Kermanshah",
        iata: "KSH",
        countryIata: "IRKSH",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Lar A/P",
        city: "Lar",
        iata: "LRR",
        countryIata: "IRLRR",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Lavan",
        city: "Lavan",
        iata: "LVP",
        countryIata: "IRLVP",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Shahid Hashemi Nejad Airport",
        city: "Mashad",
        iata: "MHD",
        countryIata: "IRMHD",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Mahshahr",
        city: "Bandar Mahshahr",
        iata: "MRX",
        countryIata: "IRMRX",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Now Shahr",
        city: "Now Shahr",
        iata: "NSH",
        countryIata: "IRNSH",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Urmieh",
        city: "Urmieh",
        iata: "OMH",
        countryIata: "IROMH",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Rasht",
        city: "Rasht",
        iata: "RAS",
        countryIata: "IRRAS",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Rafsanjan",
        city: "Rafsanjan",
        iata: "RJN",
        countryIata: "IRRJN",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Ramsar",
        city: "Ramsar",
        iata: "RZR",
        countryIata: "IRRZR",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Sanandaj",
        city: "Sanandaj",
        iata: "SDG",
        countryIata: "IRSDG",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Dashte Naz",
        city: "Sary",
        iata: "SRY",
        countryIata: "IRSRY",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Sirri Island",
        city: "Sirri Island",
        iata: "SXI",
        countryIata: "IRSXI",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Sirjan",
        city: "Sirjan",
        iata: "SYJ",
        countryIata: "IRSYJ",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Shiraz",
        city: "Shiraz",
        iata: "SYZ",
        countryIata: "IRSYZ",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Tabriz",
        city: "Tabriz",
        iata: "TBZ",
        countryIata: "IRTBZ",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Tabas",
        city: "Tabas",
        iata: "TCX",
        countryIata: "IRTCX",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Mehrabad",
        city: "Tehran",
        iata: "THR",
        countryIata: "IRTHR",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Birjand International Airport",
        city: "Birjand",
        iata: "XBJ",
        countryIata: "IRXBJ",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Zahedan",
        city: "Zahedan",
        iata: "ZAH",
        countryIata: "IRZAH",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Chah-bahar",
        city: "Chah-Bahar",
        iata: "ZBR",
        countryIata: "IRZBR",
        country: "Iran (Islamic Republic of)",
        countryCode: "IR",
    },
    {
        airportName: "Baghdad International Airport",
        city: "Baghdad",
        iata: "BGW",
        countryIata: "IQBGW",
        country: "Iraq",
        countryCode: "IQ",
    },
    {
        airportName: "Bamerny",
        city: "Bamerny",
        iata: "BMN",
        countryIata: "IQBMN",
        country: "Iraq",
        countryCode: "IQ",
    },
    {
        airportName: "International",
        city: "Basra",
        iata: "BSR",
        countryIata: "IQBSR",
        country: "Iraq",
        countryCode: "IQ",
    },
    {
        airportName: "Erbil International Airport",
        city: "Erbil",
        iata: "EBL",
        countryIata: "IQEBL",
        country: "Iraq",
        countryCode: "IQ",
    },
    {
        airportName: "Kirkuk",
        city: "Kirkuk",
        iata: "KIK",
        countryIata: "IQKIK",
        country: "Iraq",
        countryCode: "IQ",
    },
    {
        airportName: "Al-Ashraf Intl.",
        city: "Al Najaf",
        iata: "NJF",
        countryIata: "IQNJF",
        country: "Iraq",
        countryCode: "IQ",
    },
    {
        airportName: "Mosul",
        city: "Mosul",
        iata: "OSM",
        countryIata: "IQOSM",
        country: "Iraq",
        countryCode: "IQ",
    },
    {
        airportName: "Saddam International",
        city: "Baghdad",
        iata: "SDA",
        countryIata: "IQSDA",
        country: "Iraq",
        countryCode: "IQ",
    },
    {
        airportName: "Belmullet",
        city: "Belmullet",
        iata: "BLY",
        countryIata: "IEBLY",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Bantry",
        city: "Bantry",
        iata: "BYT",
        countryIata: "IEBYT",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Donegal",
        city: "Donegal",
        iata: "CFN",
        countryIata: "IECFN",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Reenroe",
        city: "Caherciveen",
        iata: "CHE",
        countryIata: "IECHE",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Castlebar",
        city: "Castlebar",
        iata: "CLB",
        countryIata: "IECLB",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Dublin",
        city: "Dublin",
        iata: "DUB",
        countryIata: "IEDUB",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Carnmore",
        city: "Galway",
        iata: "GWY",
        countryIata: "IEGWY",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Inishmaan",
        city: "Inishmaan",
        iata: "IIA",
        countryIata: "IEIIA",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Inisheer",
        city: "Inisheer",
        iata: "INQ",
        countryIata: "IEINQ",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Kilronan",
        city: "Inishmore",
        iata: "IOR",
        countryIata: "IEIOR",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Kerry County Airport",
        city: "Kerry County",
        iata: "KIR",
        countryIata: "IEKIR",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Kilkenny",
        city: "Kilkenny",
        iata: "KKY",
        countryIata: "IEKKY",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Letterkenny",
        city: "Letterkenny",
        iata: "LTR",
        countryIata: "IELTR",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Connemara",
        city: "Spiddal",
        iata: "NNR",
        countryIata: "IENNR",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Knock International",
        city: "Knock",
        iata: "NOC",
        countryIata: "IENOC",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Cork",
        city: "Cork",
        iata: "ORK",
        countryIata: "IEORK",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Shannon",
        city: "Shannon",
        iata: "SNN",
        countryIata: "IESNN",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Collooney",
        city: "Sligo",
        iata: "SXL",
        countryIata: "IESXL",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Waterford",
        city: "Waterford",
        iata: "WAT",
        countryIata: "IEWAT",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Castlebridge",
        city: "Wexford",
        iata: "WEX",
        countryIata: "IEWEX",
        country: "Ireland",
        countryCode: "IE",
    },
    {
        airportName: "Ben Gurion International",
        city: "Tel Aviv - Yafo",
        iata: "TLV",
        countryIata: "ILTLV",
        country: "Israel",
        countryCode: "IL",
    },
    {
        airportName: "Fertilia",
        city: "Alghero",
        iata: "AHO",
        countryIata: "ITAHO",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Albenga",
        city: "Albenga",
        iata: "ALL",
        countryIata: "ITALL",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Ancona Falconara Airport",
        city: "Ancona",
        iata: "AOI",
        countryIata: "ITAOI",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Corrado Gex",
        city: "Aosta",
        iata: "AOT",
        countryIata: "ITAOT",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Aviano",
        city: "Aviano",
        iata: "AVB",
        countryIata: "ITAVB",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Papola Casale",
        city: "Brindisi",
        iata: "BDS",
        countryIata: "ITBDS",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Orio Al Serio",
        city: "Milan",
        iata: "BGY",
        countryIata: "ITBGY",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Guglielmo Marconi",
        city: "Bologna",
        iata: "BLQ",
        countryIata: "ITBLQ",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Belluno",
        city: "Belluno",
        iata: "BLX",
        countryIata: "ITBLX",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Palese",
        city: "Bari",
        iata: "BRI",
        countryIata: "ITBRI",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Bolzano",
        city: "Bolzano",
        iata: "BZO",
        countryIata: "ITBZO",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Elmas",
        city: "Cagliari",
        iata: "CAG",
        countryIata: "ITCAG",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Fiames",
        city: "Cortina d'Ampezzo",
        iata: "CDF",
        countryIata: "ITCDF",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Ciampino",
        city: "Rome",
        iata: "CIA",
        countryIata: "ITCIA",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Comiso",
        city: "Comiso",
        iata: "CIY",
        countryIata: "ITCIY",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Crotone",
        city: "Crotone",
        iata: "CRV",
        countryIata: "ITCRV",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Fontanarossa",
        city: "Catania",
        iata: "CTA",
        countryIata: "ITCTA",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Levaldigi",
        city: "Cuneo",
        iata: "CUF",
        countryIata: "ITCUF",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Rafsu Decimomannu",
        city: "Decimomannu",
        iata: "DCI",
        countryIata: "ITDCI",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Marina di Campo",
        city: "Elba Island",
        iata: "EBA",
        countryIata: "ITEBA",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Fiumicino",
        city: "Rome",
        iata: "FCO",
        countryIata: "ITFCO",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Peretola",
        city: "Florence",
        iata: "FLR",
        countryIata: "ITFLR",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Gino Lisa",
        city: "Foggia",
        iata: "FOG",
        countryIata: "ITFOG",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Luigi Ridolfi",
        city: "Forli",
        iata: "FRL",
        countryIata: "ITFRL",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "San Giovanni Rotondo",
        city: "San Giovanni Rotondo",
        iata: "GBN",
        countryIata: "ITGBN",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Cristoforo Colombo",
        city: "Genoa",
        iata: "GOA",
        countryIata: "ITGOA",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Baccarini",
        city: "Grosseto",
        iata: "GRS",
        countryIata: "ITGRS",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Elisuperficie (Giovanni Paolo 2°) Heliport",
        city: "Ischia",
        iata: "ISH",
        countryIata: "ITISH",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Jesolo",
        city: "Jesolo",
        iata: "JLO",
        countryIata: "ITJLO",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Galatina",
        city: "Lecce",
        iata: "LCC",
        countryIata: "ITLCC",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Lucca",
        city: "Lucca",
        iata: "LCV",
        countryIata: "ITLCV",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Linate",
        city: "Milan",
        iata: "LIN",
        countryIata: "ITLIN",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Linosa Heliport",
        city: "Linosa",
        iata: "LIU",
        countryIata: "ITLIU",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Lampedusa",
        city: "Lampedusa",
        iata: "LMP",
        countryIata: "ITLMP",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Malpensa",
        city: "Milan",
        iata: "MXP",
        countryIata: "ITMXP",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Naples International Airport",
        city: "Naples",
        iata: "NAP",
        countryIata: "ITNAP",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Sigonella NAF",
        city: "Sigonella",
        iata: "NSY",
        countryIata: "ITNSY",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Costa Smeralda",
        city: "Olbia",
        iata: "OLB",
        countryIata: "ITOLB",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Sant Egidio",
        city: "Perugia",
        iata: "PEG",
        countryIata: "ITPEG",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Peschiei",
        city: "Peschiei",
        iata: "PEJ",
        countryIata: "ITPEJ",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Parma",
        city: "Milan",
        iata: "PMF",
        countryIata: "ITPMF",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Punta Raisi",
        city: "Palermo",
        iata: "PMO",
        countryIata: "ITPMO",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Pantelleria",
        city: "Pantelleria",
        iata: "PNL",
        countryIata: "ITPNL",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Capri",
        city: "Capri",
        iata: "PRJ",
        countryIata: "ITPRJ",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Galileo Galilei",
        city: "Florence",
        iata: "PSA",
        countryIata: "ITPSA",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Liberi",
        city: "Pescara",
        iata: "PSR",
        countryIata: "ITPSR",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "L'Aquila–Preturo Airport",
        city: "L'Aquila",
        iata: "QAQ",
        countryIata: "ITQAQ",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Marina Di Massa",
        city: "Marina Di Massa",
        iata: "QMM",
        countryIata: "ITQMM",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Salerno Airport",
        city: "Salerno",
        iata: "QSR",
        countryIata: "ITQSR",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "La Spreta Airport",
        city: "Ravenna",
        iata: "RAN",
        countryIata: "ITRAN",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Tito Menniti",
        city: "Reggio Calabria",
        iata: "REG",
        countryIata: "ITREG",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Miramare",
        city: "Rimini",
        iata: "RMI",
        countryIata: "ITRMI",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Metropolitan Area",
        city: "Rome",
        iata: "ROM",
        countryIata: "ITROM",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Sorrento",
        city: "Sorrento",
        iata: "RRO",
        countryIata: "ITRRO",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Siena-Ampugnano Airport",
        city: "Siena",
        iata: "SAY",
        countryIata: "ITSAY",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "S Eufemia",
        city: "Lamezia-Terme",
        iata: "SUF",
        countryIata: "ITSUF",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Segrate",
        city: "Milan",
        iata: "SWK",
        countryIata: "ITSWK",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "M. A. Grottag",
        city: "Taranto",
        iata: "TAR",
        countryIata: "ITTAR",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Birgi",
        city: "Trapani",
        iata: "TPS",
        countryIata: "ITTPS",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "San Domino Island",
        city: "San Domino Island",
        iata: "TQR",
        countryIata: "ITTQR",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Citta Di Torino",
        city: "Turin",
        iata: "TRN",
        countryIata: "ITTRN",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Dei Legionari",
        city: "Trieste",
        iata: "TRS",
        countryIata: "ITTRS",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Treviso",
        city: "Venice",
        iata: "TSF",
        countryIata: "ITTSF",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Arbatax",
        city: "Tortoli",
        iata: "TTB",
        countryIata: "ITTTB",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Udine-Campoformido",
        city: "Udine",
        iata: "UDN",
        countryIata: "ITUDN",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Montichiari",
        city: "Verona",
        iata: "VBS",
        countryIata: "ITVBS",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Venice Marco Polo Airport",
        city: "Venice",
        iata: "VCE",
        countryIata: "ITVCE",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Vicenza",
        city: "Vicenza",
        iata: "VIC",
        countryIata: "ITVIC",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Vieste",
        city: "Vieste",
        iata: "VIF",
        countryIata: "ITVIF",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Verona",
        city: "Verona",
        iata: "VRN",
        countryIata: "ITVRN",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Bus Station",
        city: "Chiusa/Klausen",
        iata: "ZAK",
        countryIata: "ITZAK",
        country: "Italy",
        countryCode: "IT",
    },
    {
        airportName: "Norman Manley International",
        city: "Kingston",
        iata: "KIN",
        countryIata: "JMKIN",
        country: "Jamaica",
        countryCode: "JM",
    },
    {
        airportName: "Tinson Pen Aerodrome",
        city: "Kingston",
        iata: "KTP",
        countryIata: "JMKTP",
        country: "Jamaica",
        countryCode: "JM",
    },
    {
        airportName: "Sangster International Airport",
        city: "Montego Bay",
        iata: "MBJ",
        countryIata: "JMMBJ",
        country: "Jamaica",
        countryCode: "JM",
    },
    {
        airportName: "Marlboro",
        city: "Mandeville",
        iata: "MVJ",
        countryIata: "JMMVJ",
        country: "Jamaica",
        countryCode: "JM",
    },
    {
        airportName: "Negril",
        city: "Negril",
        iata: "NEG",
        countryIata: "JMNEG",
        country: "Jamaica",
        countryCode: "JM",
    },
    {
        airportName: "Boscobel",
        city: "Ocho Rios",
        iata: "OCJ",
        countryIata: "JMOCJ",
        country: "Jamaica",
        countryCode: "JM",
    },
    {
        airportName: "Ken Jones",
        city: "Port Antonio",
        iata: "POT",
        countryIata: "JMPOT",
        country: "Jamaica",
        countryCode: "JM",
    },
    {
        airportName: "Aguni",
        city: "Aguni",
        iata: "AGJ",
        countryIata: "JPAGJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Asahikawa",
        city: "Asahikawa",
        iata: "AKJ",
        countryIata: "JPAKJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Aomori",
        city: "Aomori",
        iata: "AOJ",
        countryIata: "JPAOJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Amami-Oshima Airport",
        city: "Amami Oshima Is",
        iata: "ASJ",
        countryIata: "JPASJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Akita",
        city: "Akita",
        iata: "AXT",
        countryIata: "JPAXT",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Beppu",
        city: "Beppu",
        iata: "BPU",
        countryIata: "JPBPU",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "New Chitose International Airport",
        city: "Sapporo",
        iata: "CTS",
        countryIata: "JPCTS",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Kadena AFB",
        city: "Okinawa",
        iata: "DNA",
        countryIata: "JPDNA",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Fukui",
        city: "Fukui",
        iata: "FKJ",
        countryIata: "JPFKJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Fukushima Airport",
        city: "Fukushima",
        iata: "FKS",
        countryIata: "JPFKS",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Fukue",
        city: "Fukue",
        iata: "FUJ",
        countryIata: "JPFUJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Fukuoka Airport",
        city: "Fukuoka",
        iata: "FUK",
        countryIata: "JPFUK",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Junmachi",
        city: "Yamagata",
        iata: "GAJ",
        countryIata: "JPGAJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Hachijo Jima",
        city: "Hachijo Jima",
        iata: "HAC",
        countryIata: "JPHAC",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Hachinohe",
        city: "Hachinohe",
        iata: "HHE",
        countryIata: "JPHHE",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "International",
        city: "Hiroshima",
        iata: "HIJ",
        countryIata: "JPHIJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Hiroshima West",
        city: "Hiroshima",
        iata: "HIW",
        countryIata: "JPHIW",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Hakodate",
        city: "Hakodate",
        iata: "HKD",
        countryIata: "JPHKD",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Hanamaki",
        city: "Morioka",
        iata: "HNA",
        countryIata: "JPHNA",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Haneda",
        city: "Tokyo",
        iata: "HND",
        countryIata: "JPHND",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Saga",
        city: "Saga",
        iata: "HSG",
        countryIata: "JPHSG",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Hateruma",
        city: "Hateruma",
        iata: "HTR",
        countryIata: "JPHTR",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Iejima",
        city: "Iejima",
        iata: "IEJ",
        countryIata: "JPIEJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Niihama",
        city: "Niihama",
        iata: "IHA",
        countryIata: "JPIHA",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Nishinoomote",
        city: "Nishinoomote",
        iata: "IIN",
        countryIata: "JPIIN",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Iki",
        city: "Iki",
        iata: "IKI",
        countryIata: "JPIKI",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "New Ishigaki Airport",
        city: "Ishigaki",
        iata: "ISG",
        countryIata: "JPISG",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Osaka-Itami International Airport",
        city: "Osaka",
        iata: "ITM",
        countryIata: "JPITM",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Iwami",
        city: "Iwami",
        iata: "IWJ",
        countryIata: "JPIWJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Iwo Jima Air Base",
        city: "Iwo Jima",
        iata: "IWO",
        countryIata: "JPIWO",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Izumo",
        city: "Izumo",
        iata: "IZO",
        countryIata: "JPIZO",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Kochi",
        city: "Kochi",
        iata: "KCZ",
        countryIata: "JPKCZ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Niigata",
        city: "Niigata",
        iata: "KIJ",
        countryIata: "JPKIJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Kansai International",
        city: "Osaka",
        iata: "KIX",
        countryIata: "JPKIX",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Kerama",
        city: "Kerama",
        iata: "KJP",
        countryIata: "JPKJP",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Kitakyushu Airport",
        city: "Fukuoka, Kitakyushu",
        iata: "KKJ",
        countryIata: "JPKKJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Kikaiga Shima",
        city: "Kikaiga Shima",
        iata: "KKX",
        countryIata: "JPKKX",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Miyazaki",
        city: "Miyazaki",
        iata: "KMI",
        countryIata: "JPKMI",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Kumamoto",
        city: "Kumamoto",
        iata: "KMJ",
        countryIata: "JPKMJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Komatsu",
        city: "Komatsu",
        iata: "KMQ",
        countryIata: "JPKMQ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Kagoshima Airport",
        city: "Kagoshima",
        iata: "KOJ",
        countryIata: "JPKOJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Kitadaito",
        city: "Kitadaito",
        iata: "KTD",
        countryIata: "JPKTD",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Kushiro",
        city: "Kushiro",
        iata: "KUH",
        countryIata: "JPKUH",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Kushimoto",
        city: "Kushimoto",
        iata: "KUJ",
        countryIata: "JPKUJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Yakushima",
        city: "Yakushima",
        iata: "KUM",
        countryIata: "JPKUM",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Monbetsu",
        city: "Monbetsu",
        iata: "MBE",
        countryIata: "JPMBE",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Memanbetsu",
        city: "Memanbetsu",
        iata: "MMB",
        countryIata: "JPMMB",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Maridor",
        city: "Minami Daito",
        iata: "MMD",
        countryIata: "JPMMD",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Matsumoto",
        city: "Matsumoto",
        iata: "MMJ",
        countryIata: "JPMMJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Hirara",
        city: "Miyako Jima",
        iata: "MMY",
        countryIata: "JPMMY",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Misawa",
        city: "Misawa",
        iata: "MSJ",
        countryIata: "JPMSJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Marcus Island",
        city: "Marcus Island",
        iata: "MUS",
        countryIata: "JPMUS",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Miyake Jima",
        city: "Miyake Jima",
        iata: "MYE",
        countryIata: "JPMYE",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Matsuyama",
        city: "Matsuyama",
        iata: "MYJ",
        countryIata: "JPMYJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Komaki AFB",
        city: "Nagoya",
        iata: "NGO",
        countryIata: "JPNGO",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Nagasaki",
        city: "Nagasaki",
        iata: "NGS",
        countryIata: "JPNGS",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "NAS",
        city: "Atsugi",
        iata: "NJA",
        countryIata: "JPNJA",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Tokyo Narita Airport",
        city: "Tokyo",
        iata: "NRT",
        countryIata: "JPNRT",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Obihiro",
        city: "Obihiro",
        iata: "OBO",
        countryIata: "JPOBO",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Yonaguni Jima",
        city: "Yonaguni Jima",
        iata: "OGN",
        countryIata: "JPOGN",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Oshima",
        city: "Oshima",
        iata: "OIM",
        countryIata: "JPOIM",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Okushiri",
        city: "Okushiri",
        iata: "OIR",
        countryIata: "JPOIR",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Oita",
        city: "Oita",
        iata: "OIT",
        countryIata: "JPOIT",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Naha Airport",
        city: "Okinawa",
        iata: "OKA",
        countryIata: "JPOKA",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Okadama",
        city: "Sapporo",
        iata: "OKD",
        countryIata: "JPOKD",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Okino Erabu",
        city: "Okino Erabu",
        iata: "OKE",
        countryIata: "JPOKE",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Oki Island",
        city: "Oki Island",
        iata: "OKI",
        countryIata: "JPOKI",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Okayama",
        city: "Okayama",
        iata: "OKJ",
        countryIata: "JPOKJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Yokota AFB",
        city: "Tokyo",
        iata: "OKO",
        countryIata: "JPOKO",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Omura",
        city: "Omura",
        iata: "OMJ",
        countryIata: "JPOMJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Odate Noshiro",
        city: "Odate Noshiro",
        iata: "ONJ",
        countryIata: "JPONJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Metropolitan Area",
        city: "Osaka",
        iata: "OSA",
        countryIata: "JPOSA",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Rebun",
        city: "Rebun",
        iata: "RBJ",
        countryIata: "JPRBJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Rishiri",
        city: "Rishiri",
        iata: "RIS",
        countryIata: "JPRIS",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Yoronjima",
        city: "Yoronjima",
        iata: "RNJ",
        countryIata: "JPRNJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Sendai",
        city: "Sendai",
        iata: "SDJ",
        countryIata: "JPSDJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Ryotsu Sado",
        city: "Ryotsu Sado Island",
        iata: "SDO",
        countryIata: "JPSDO",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Sado Shima",
        city: "Sado Shima",
        iata: "SDS",
        countryIata: "JPSDS",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Nakashibetsu",
        city: "Nakashibetsu",
        iata: "SHB",
        countryIata: "JPSHB",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Shimojishima",
        city: "Shimojishima",
        iata: "SHI",
        countryIata: "JPSHI",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Shirahama",
        city: "Shirahama",
        iata: "SHM",
        countryIata: "JPSHM",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Metropolitan Area",
        city: "Sapporo",
        iata: "SPK",
        countryIata: "JPSPK",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Shonai",
        city: "Shonai",
        iata: "SYO",
        countryIata: "JPSYO",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Takamatsu",
        city: "Takamatsu",
        iata: "TAK",
        countryIata: "JPTAK",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Tajima",
        city: "Toyooka",
        iata: "TJH",
        countryIata: "JPTJH",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Tokunoshima",
        city: "Tokunoshima",
        iata: "TKN",
        countryIata: "JPTKN",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Tokushima",
        city: "Tokushima",
        iata: "TKS",
        countryIata: "JPTKS",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "New Tanegashima Airport",
        city: "Nakatane",
        iata: "TNE",
        countryIata: "JPTNE",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Toyama",
        city: "Toyama",
        iata: "TOY",
        countryIata: "JPTOY",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Tarama",
        city: "Taramajima",
        iata: "TRA",
        countryIata: "JPTRA",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Tsushima",
        city: "Tsushima",
        iata: "TSJ",
        countryIata: "JPTSJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Tottori",
        city: "Tottori",
        iata: "TTJ",
        countryIata: "JPTTJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Metropolitan Area",
        city: "Tokyo",
        iata: "TYO",
        countryIata: "JPTYO",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Yamaguchi Ube Airport",
        city: "Ube",
        iata: "UBJ",
        countryIata: "JPUBJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Kumejima",
        city: "Kumejima",
        iata: "UEO",
        countryIata: "JPUEO",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Kobe",
        city: "Kobe",
        iata: "UKB",
        countryIata: "JPUKB",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Kyoto",
        city: "Kyoto",
        iata: "UKY",
        countryIata: "JPUKY",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Hokkaido",
        city: "Wakkanai",
        iata: "WKJ",
        countryIata: "JPWKJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Miho",
        city: "Yonago",
        iata: "YGJ",
        countryIata: "JPYGJ",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Yokohama",
        city: "Yokohama",
        iata: "YOK",
        countryIata: "JPYOK",
        country: "Japan",
        countryCode: "JP",
    },
    {
        airportName: "Marka International Airport",
        city: "Amman",
        iata: "ADJ",
        countryIata: "JOADJ",
        country: "Jordan",
        countryCode: "JO",
    },
    {
        airportName: "Queen Alia International Airport",
        city: "Amman",
        iata: "AMM",
        countryIata: "JOAMM",
        country: "Jordan",
        countryCode: "JO",
    },
    {
        airportName: "King Hussein International Airport",
        city: "Aqaba",
        iata: "AQJ",
        countryIata: "JOAQJ",
        country: "Jordan",
        countryCode: "JO",
    },
    {
        airportName: "Maan",
        city: "Maan",
        iata: "MPQ",
        countryIata: "JOMPQ",
        country: "Jordan",
        countryCode: "JO",
    },
    {
        airportName: "King Hussein",
        city: "Mafraq",
        iata: "OMF",
        countryIata: "JOOMF",
        country: "Jordan",
        countryCode: "JO",
    },
    {
        airportName: "Aktyubinsk",
        city: "Aktyubinsk",
        iata: "AKX",
        countryIata: "KZAKX",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Almaty International Airport",
        city: "Almaty",
        iata: "ALA",
        countryIata: "KZALA",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Atbasar",
        city: "Atbasar",
        iata: "ATX",
        countryIata: "KZATX",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Arkalyk",
        city: "Arkalyk",
        iata: "AYK",
        countryIata: "KZAYK",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Balhash",
        city: "Balhash",
        iata: "BXH",
        countryIata: "KZBXH",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Burundai",
        city: "Burundai",
        iata: "BXJ",
        countryIata: "KZBXJ",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Shimkent",
        city: "Shimkent",
        iata: "CIT",
        countryIata: "KZCIT",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Zhambyl",
        city: "Zhambyl",
        iata: "DMB",
        countryIata: "KZDMB",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Zhezhazgan",
        city: "Zhezkazgan",
        iata: "DZN",
        countryIata: "KZDZN",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Ekibastuz",
        city: "Ekibastuz",
        iata: "EKB",
        countryIata: "KZEKB",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Atyrau",
        city: "Atyrau",
        iata: "GUW",
        countryIata: "KZGUW",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Zhairem",
        city: "Zhairem",
        iata: "HRC",
        countryIata: "KZHRC",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Karaganda",
        city: "Karaganda",
        iata: "KGF",
        countryIata: "KZKGF",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Kokshetau",
        city: "Kokshetau",
        iata: "KOV",
        countryIata: "KZKOV",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Kostanay",
        city: "Kostanay",
        iata: "KSN",
        countryIata: "KZKSN",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Kzyl-Orda",
        city: "Kzyl-Orda",
        iata: "KZO",
        countryIata: "KZKZO",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Semipalatinsk",
        city: "Semipalatinsk",
        iata: "PLX",
        countryIata: "KZPLX",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Petropavlovsk",
        city: "Petropavlovsk",
        iata: "PPK",
        countryIata: "KZPPK",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Pavlodar",
        city: "Pavlodar",
        iata: "PWQ",
        countryIata: "KZPWQ",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Aktau",
        city: "Aktau",
        iata: "SCO",
        countryIata: "KZSCO",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Zaisan",
        city: "Zaisan",
        iata: "SZI",
        countryIata: "KZSZI",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Taldy-Kurgan",
        city: "Taldy-Kurgan",
        iata: "TDK",
        countryIata: "KZTDK",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Astana",
        city: "Astana",
        iata: "TSE",
        countryIata: "KZTSE",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Ust-Kamenogorsk",
        city: "Ust-Kamenogorsk",
        iata: "UKK",
        countryIata: "KZUKK",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Uralsk",
        city: "Uralsk",
        iata: "URA",
        countryIata: "KZURA",
        country: "Kazakhstan",
        countryCode: "KZ",
    },
    {
        airportName: "Amboseli",
        city: "Amboseli",
        iata: "ASV",
        countryIata: "KEASV",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Bamburi",
        city: "Bamburi",
        iata: "BMQ",
        countryIata: "KEBMQ",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Eldoret International Airport",
        city: "Eldoret",
        iata: "EDL",
        countryIata: "KEEDL",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Eliye Springs",
        city: "Eliye Springs",
        iata: "EYS",
        countryIata: "KEEYS",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Fergusons Gulf",
        city: "Fergusons Gulf",
        iata: "FER",
        countryIata: "KEFER",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Garissa",
        city: "Garissa",
        iata: "GAS",
        countryIata: "KEGAS",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Hola",
        city: "Hola",
        iata: "HOA",
        countryIata: "KEHOA",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Kilaguni",
        city: "Kilaguni",
        iata: "ILU",
        countryIata: "KEILU",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Kericho",
        city: "Kericho",
        iata: "KEY",
        countryIata: "KEKEY",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Kisumu",
        city: "Kisumu",
        iata: "KIS",
        countryIata: "KEKIS",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Kiunga",
        city: "Kiunga",
        iata: "KIU",
        countryIata: "KEKIU",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Kalokol",
        city: "Kalokol",
        iata: "KLK",
        countryIata: "KEKLK",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Kerio Valley",
        city: "Kerio Valley",
        iata: "KRV",
        countryIata: "KEKRV",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Kitale",
        city: "Kitale",
        iata: "KTL",
        countryIata: "KEKTL",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Kiwayu",
        city: "Kiwayu",
        iata: "KWY",
        countryIata: "KEKWY",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Lamu",
        city: "Lamu",
        iata: "LAU",
        countryIata: "KELAU",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Liboi",
        city: "Liboi",
        iata: "LBK",
        countryIata: "KELBK",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Lake Baringo",
        city: "Lake Baringo",
        iata: "LBN",
        countryIata: "KELBN",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Lokichoggio",
        city: "Lokichoggio",
        iata: "LKG",
        countryIata: "KELKG",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Lake Rudolf",
        city: "Lake Rudolf",
        iata: "LKU",
        countryIata: "KELKU",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Lodwar",
        city: "Lodwar",
        iata: "LOK",
        countryIata: "KELOK",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Loyangalani",
        city: "Loyangalani",
        iata: "LOY",
        countryIata: "KELOY",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Moi International Airport",
        city: "Mombasa",
        iata: "MBA",
        countryIata: "KEMBA",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Mara Lodges",
        city: "Mara Lodges",
        iata: "MRE",
        countryIata: "KEMRE",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Mumias",
        city: "Mumias",
        iata: "MUM",
        countryIata: "KEMUM",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Malindi",
        city: "Malindi",
        iata: "MYD",
        countryIata: "KEMYD",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Jomo Kenyatta International",
        city: "Nairobi",
        iata: "NBO",
        countryIata: "KENBO",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Mandera",
        city: "Mandera",
        iata: "NDE",
        countryIata: "KENDE",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Nakuru",
        city: "Nakuru",
        iata: "NUU",
        countryIata: "KENUU",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Nyeri",
        city: "Nyeri",
        iata: "NYE",
        countryIata: "KENYE",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Nanyuki",
        city: "Nanyuki",
        iata: "NYK",
        countryIata: "KENYK",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Nzoia",
        city: "Nzoia",
        iata: "NZO",
        countryIata: "KENZO",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Moyale",
        city: "Moyale",
        iata: "OYL",
        countryIata: "KEOYL",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Marsabit",
        city: "Marsabit",
        iata: "RBT",
        countryIata: "KERBT",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Samburu",
        city: "Samburu",
        iata: "UAS",
        countryIata: "KEUAS",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Ukunda",
        city: "Ukunda",
        iata: "UKA",
        countryIata: "KEUKA",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Wilson",
        city: "Nairobi",
        iata: "WIL",
        countryIata: "KEWIL",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Wajir",
        city: "Wajir",
        iata: "WJR",
        countryIata: "KEWJR",
        country: "Kenya",
        countryCode: "KE",
    },
    {
        airportName: "Aranuka",
        city: "Aranuka",
        iata: "AAK",
        countryIata: "KIAAK",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Abaiang",
        city: "Abaiang",
        iata: "ABF",
        countryIata: "KIABF",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Abemama Atoll",
        city: "Abemama Atoll",
        iata: "AEA",
        countryIata: "KIAEA",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Arorae Island",
        city: "Arorae Island",
        iata: "AIS",
        countryIata: "KIAIS",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Butaritari",
        city: "Butaritari",
        iata: "BBG",
        countryIata: "KIBBG",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Beru",
        city: "Beru",
        iata: "BEZ",
        countryIata: "KIBEZ",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Canton Island",
        city: "Canton Island",
        iata: "CIS",
        countryIata: "KICIS",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Cassidy International Airport",
        city: "Kiritimati (island)",
        iata: "CXI",
        countryIata: "KICXI",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Kuria",
        city: "Kuria",
        iata: "KUC",
        countryIata: "KIKUC",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Maiana",
        city: "Maiana",
        iata: "MNK",
        countryIata: "KIMNK",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Makin Island",
        city: "Makin Island",
        iata: "MTK",
        countryIata: "KIMTK",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Marakei",
        city: "Marakei",
        iata: "MZK",
        countryIata: "KIMZK",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Nikunau",
        city: "Nikunau",
        iata: "NIG",
        countryIata: "KINIG",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Nonouti",
        city: "Nonouti",
        iata: "NON",
        countryIata: "KINON",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Onotoa",
        city: "Onotoa",
        iata: "OOT",
        countryIata: "KIOOT",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Tabiteuea North",
        city: "Tabiteuea North",
        iata: "TBF",
        countryIata: "KITBF",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Tamana Island",
        city: "Tamana Island",
        iata: "TMN",
        countryIata: "KITMN",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Teraina",
        city: "Teraina",
        iata: "TNQ",
        countryIata: "KITNQ",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Tabuaeran",
        city: "Tabuaeran",
        iata: "TNV",
        countryIata: "KITNV",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Bonriki",
        city: "Tarawa",
        iata: "TRW",
        countryIata: "KITRW",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "Tabiteuea South",
        city: "Tabiteuea South",
        iata: "TSU",
        countryIata: "KITSU",
        country: "Kiribati",
        countryCode: "KI",
    },
    {
        airportName: "International",
        city: "Kuwait",
        iata: "KWI",
        countryIata: "KWKWI",
        country: "Kuwait",
        countryCode: "KW",
    },
    {
        airportName: "Manas International",
        city: "Bishkek",
        iata: "FRU",
        countryIata: "KGFRU",
        country: "Kyrgyzstan",
        countryCode: "KG",
    },
    {
        airportName: "Osh",
        city: "Osh",
        iata: "OSS",
        countryIata: "KGOSS",
        country: "Kyrgyzstan",
        countryCode: "KG",
    },
    {
        airportName: "Daugavpils",
        city: "Daugavpils",
        iata: "DGP",
        countryIata: "LVDGP",
        country: "Latvia",
        countryCode: "LV",
    },
    {
        airportName: "Liepaya",
        city: "Liepaya",
        iata: "LPX",
        countryIata: "LVLPX",
        country: "Latvia",
        countryCode: "LV",
    },
    {
        airportName: "Riga",
        city: "Riga",
        iata: "RIX",
        countryIata: "LVRIX",
        country: "Latvia",
        countryCode: "LV",
    },
    {
        airportName: "International",
        city: "Beirut",
        iata: "BEY",
        countryIata: "LBBEY",
        country: "Lebanon",
        countryCode: "LB",
    },
    {
        airportName: "Kleyate Airport (AFB?)",
        city: "Tripoli",
        iata: "KYE",
        countryIata: "LBKYE",
        country: "Lebanon",
        countryCode: "LB",
    },
    {
        airportName: "Lebakeng",
        city: "Lebakeng",
        iata: "LEF",
        countryIata: "LSLEF",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Lesobeng",
        city: "Lesobeng",
        iata: "LES",
        countryIata: "LSLES",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Leribe",
        city: "Leribe",
        iata: "LRB",
        countryIata: "LSLRB",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Mafeteng",
        city: "Mafeteng",
        iata: "MFC",
        countryIata: "LSMFC",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Mokhotlong",
        city: "Mokhotlong",
        iata: "MKH",
        countryIata: "LSMKH",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Matsaile",
        city: "Matsaile",
        iata: "MSG",
        countryIata: "LSMSG",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Moshoeshoe International",
        city: "Maseru",
        iata: "MSU",
        countryIata: "LSMSU",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Nkaus",
        city: "Nkaus",
        iata: "NKU",
        countryIata: "LSNKU",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Pelaneng",
        city: "Pelaneng",
        iata: "PEL",
        countryIata: "LSPEL",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Sehonghong",
        city: "Sehonghong",
        iata: "SHK",
        countryIata: "LSSHK",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Seshutes",
        city: "Seshutes",
        iata: "SHZ",
        countryIata: "LSSHZ",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Sekakes",
        city: "Sekakes",
        iata: "SKQ",
        countryIata: "LSSKQ",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Semongkong",
        city: "Semongkong",
        iata: "SOK",
        countryIata: "LSSOK",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Thaba-Tseka",
        city: "Thaba-Tseka",
        iata: "THB",
        countryIata: "LSTHB",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Tlokoeng",
        city: "Tlokoeng",
        iata: "TKO",
        countryIata: "LSTKO",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Qachas Nek",
        city: "Qachas Nek",
        iata: "UNE",
        countryIata: "LSUNE",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Quthing",
        city: "Quthing",
        iata: "UTG",
        countryIata: "LSUTG",
        country: "Lesotho",
        countryCode: "LS",
    },
    {
        airportName: "Bella Yella",
        city: "Bella Yella",
        iata: "BYL",
        countryIata: "LRBYL",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "A. Tubman",
        city: "Cape Palmas",
        iata: "CPA",
        countryIata: "LRCPA",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "Foya",
        city: "Foya",
        iata: "FOY",
        countryIata: "LRFOY",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "Grand Cess",
        city: "Grand Cess",
        iata: "GRC",
        countryIata: "LRGRC",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "Sprigg Payne",
        city: "Monrovia",
        iata: "MLW",
        countryIata: "LRMLW",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "Nimba",
        city: "Nimba",
        iata: "NIA",
        countryIata: "LRNIA",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "Roberts International Airport",
        city: "Monrovia",
        iata: "ROB",
        countryIata: "LRROB",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "Rivercess",
        city: "Rivercess",
        iata: "RVC",
        countryIata: "LRRVC",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "Sasstown",
        city: "Sasstown",
        iata: "SAZ",
        countryIata: "LRSAZ",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "R.E. Murray",
        city: "Sinoe",
        iata: "SNI",
        countryIata: "LRSNI",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "Tchien",
        city: "Tchien",
        iata: "THC",
        countryIata: "LRTHC",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "Tapeta",
        city: "Tapeta",
        iata: "TPT",
        countryIata: "LRTPT",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "Buchanan",
        city: "Buchanan",
        iata: "UCN",
        countryIata: "LRUCN",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "Voinjama",
        city: "Voinjama",
        iata: "VOI",
        countryIata: "LRVOI",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "Weasua",
        city: "Weasua",
        iata: "WES",
        countryIata: "LRWES",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "Wologissi",
        city: "Wologissi",
        iata: "WOI",
        countryIata: "LRWOI",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "AFC",
        city: "Sinoe",
        iata: "XSA",
        countryIata: "LRXSA",
        country: "Liberia",
        countryCode: "LR",
    },
    {
        airportName: "Kufrah",
        city: "Kufrah",
        iata: "AKF",
        countryIata: "LYAKF",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Brack",
        city: "Brack",
        iata: "BCQ",
        countryIata: "LYBCQ",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Benina International",
        city: "Benghazi",
        iata: "BEN",
        countryIata: "LYBEN",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Martuba Airbase",
        city: "Derna",
        iata: "DNF",
        countryIata: "LYDNF",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Ghat",
        city: "Ghat",
        iata: "GHT",
        countryIata: "LYGHT",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Houn",
        city: "Houn",
        iata: "HUQ",
        countryIata: "LYHUQ",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "La Braq",
        city: "Beida",
        iata: "LAQ",
        countryIata: "LYLAQ",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Marsa Brega",
        city: "Marsa Brega",
        iata: "LMQ",
        countryIata: "LYLMQ",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Ghadames",
        city: "Ghadames",
        iata: "LTD",
        countryIata: "LYLTD",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Mitiga",
        city: "Mitiga",
        iata: "MJI",
        countryIata: "LYMJI",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Misurata",
        city: "Misurata",
        iata: "MRA",
        countryIata: "LYMRA",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Nafoora",
        city: "Nafoora",
        iata: "NFR",
        countryIata: "LYNFR",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Murzuq",
        city: "Murzuq",
        iata: "QMQ",
        countryIata: "LYQMQ",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Ubari",
        city: "Ubari",
        iata: "QUB",
        countryIata: "LYQUB",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Sebha",
        city: "Sebha",
        iata: "SEB",
        countryIata: "LYSEB",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Sert",
        city: "Sert",
        iata: "SRX",
        countryIata: "LYSRX",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "International",
        city: "Tripoli",
        iata: "TIP",
        countryIata: "LYTIP",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Tobruk",
        city: "Tobruk",
        iata: "TOB",
        countryIata: "LYTOB",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Zwara",
        city: "Zwara",
        iata: "WAX",
        countryIata: "LYWAX",
        country: "Libya",
        countryCode: "LY",
    },
    {
        airportName: "Shauliaj",
        city: "Shauliaj",
        iata: "HLJ",
        countryIata: "LTHLJ",
        country: "Lithuania",
        countryCode: "LT",
    },
    {
        airportName: "Klaipeda",
        city: "Klaipeda",
        iata: "KLJ",
        countryIata: "LTKLJ",
        country: "Lithuania",
        countryCode: "LT",
    },
    {
        airportName: "Kaunas",
        city: "Kaunas",
        iata: "KUN",
        countryIata: "LTKUN",
        country: "Lithuania",
        countryCode: "LT",
    },
    {
        airportName: "Palanga",
        city: "Palanga",
        iata: "PLQ",
        countryIata: "LTPLQ",
        country: "Lithuania",
        countryCode: "LT",
    },
    {
        airportName: "Panevezys",
        city: "Panevezys",
        iata: "PNV",
        countryIata: "LTPNV",
        country: "Lithuania",
        countryCode: "LT",
    },
    {
        airportName: "Siauliai",
        city: "Siauliai",
        iata: "SQQ",
        countryIata: "LTSQQ",
        country: "Lithuania",
        countryCode: "LT",
    },
    {
        airportName: "Vilnius",
        city: "Vilnius",
        iata: "VNO",
        countryIata: "LTVNO",
        country: "Lithuania",
        countryCode: "LT",
    },
    {
        airportName: "Luxembourg",
        city: "Luxembourg",
        iata: "LUX",
        countryIata: "LULUX",
        country: "Luxembourg",
        countryCode: "LU",
    },
    {
        airportName: "Ambatolahy",
        city: "Ambatolahy",
        iata: "AHY",
        countryIata: "MGAHY",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Ambilobe",
        city: "Ambilobe",
        iata: "AMB",
        countryIata: "MGAMB",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Ampanihy",
        city: "Ampanihy",
        iata: "AMP",
        countryIata: "MGAMP",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Ambatomainty",
        city: "Ambatomainty",
        iata: "AMY",
        countryIata: "MGAMY",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Antsirabato",
        city: "Antalaha",
        iata: "ANM",
        countryIata: "MGANM",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Antsirabe",
        city: "Antsirabe",
        iata: "ATJ",
        countryIata: "MGATJ",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Betioky",
        city: "Betioky",
        iata: "BKU",
        countryIata: "MGBKU",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Belo",
        city: "Belo",
        iata: "BMD",
        countryIata: "MGBMD",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Besalampy",
        city: "Besalampy",
        iata: "BPY",
        countryIata: "MGBPY",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Besakoa",
        city: "Besakoa",
        iata: "BSV",
        countryIata: "MGBSV",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Antsiranana/Arrachart",
        city: "Antsiranana",
        iata: "DIE",
        countryIata: "MGDIE",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Doany",
        city: "Doany",
        iata: "DOA",
        countryIata: "MGDOA",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Soalala",
        city: "Soalala",
        iata: "DWB",
        countryIata: "MGDWB",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Marillac",
        city: "Fort Dauphin",
        iata: "FTU",
        countryIata: "MGFTU",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Analalava",
        city: "Analalava",
        iata: "HVA",
        countryIata: "MGHVA",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Ihosy",
        city: "Ihosy",
        iata: "IHO",
        countryIata: "MGIHO",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Ilaka",
        city: "Ilaka",
        iata: "ILK",
        countryIata: "MGILK",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Ambanja",
        city: "Ambanja",
        iata: "IVA",
        countryIata: "MGIVA",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Ankavandra Airport",
        city: "Ankavandra",
        iata: "JVA",
        countryIata: "MGJVA",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Manja",
        city: "Manja",
        iata: "MJA",
        countryIata: "MGMJA",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Amborovy",
        city: "Majunga",
        iata: "MJN",
        countryIata: "MGMJN",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Mananjary",
        city: "Mananjary",
        iata: "MNJ",
        countryIata: "MGMNJ",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Morondava",
        city: "Morondava",
        iata: "MOQ",
        countryIata: "MGMOQ",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Morombe",
        city: "Morombe",
        iata: "MXM",
        countryIata: "MGMXM",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Maintirano",
        city: "Maintirano",
        iata: "MXT",
        countryIata: "MGMXT",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Fascene",
        city: "Nossi-be",
        iata: "NOS",
        countryIata: "MGNOS",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Bekily",
        city: "Bekily",
        iata: "OVA",
        countryIata: "MGOVA",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Farafangana",
        city: "Farafangana",
        iata: "RVA",
        countryIata: "MGRVA",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Sainte Marie",
        city: "Sainte Marie",
        iata: "SMS",
        countryIata: "MGSMS",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Sambava",
        city: "Sambava",
        iata: "SVB",
        countryIata: "MGSVB",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Tanandava",
        city: "Tanandava",
        iata: "TDV",
        countryIata: "MGTDV",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Tulear",
        city: "Tulear",
        iata: "TLE",
        countryIata: "MGTLE",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Tamatave",
        city: "Tamatave",
        iata: "TMM",
        countryIata: "MGTMM",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Antananarivo",
        city: "Antananarivo",
        iata: "TNR",
        countryIata: "MGTNR",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Tsaratanana",
        city: "Tsaratanana",
        iata: "TTS",
        countryIata: "MGTTS",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Morafenobe",
        city: "Morafenobe",
        iata: "TVA",
        countryIata: "MGTVA",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Vatomandry",
        city: "Vatomandry",
        iata: "VAT",
        countryIata: "MGVAT",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Vangaindrano",
        city: "Vangaindrano",
        iata: "VND",
        countryIata: "MGVND",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Vohemar",
        city: "Vohemar",
        iata: "VOH",
        countryIata: "MGVOH",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Mahanoro",
        city: "Mahanoro",
        iata: "VVB",
        countryIata: "MGVVB",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Andriamena",
        city: "Andriamena",
        iata: "WAD",
        countryIata: "MGWAD",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Antsohihy",
        city: "Antsohihy",
        iata: "WAI",
        countryIata: "MGWAI",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Ankazoabo",
        city: "Ankazoabo",
        iata: "WAK",
        countryIata: "MGWAK",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Ambatondrazaka",
        city: "Ambatondrazaka",
        iata: "WAM",
        countryIata: "MGWAM",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Antsalova",
        city: "Antsalova",
        iata: "WAQ",
        countryIata: "MGWAQ",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Befandriana",
        city: "Befandriana",
        iata: "WBD",
        countryIata: "MGWBD",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Bealanana",
        city: "Bealanana",
        iata: "WBE",
        countryIata: "MGWBE",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Beroroha",
        city: "Beroroha",
        iata: "WBO",
        countryIata: "MGWBO",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Fianarantsoa",
        city: "Fianarantsoa",
        iata: "WFI",
        countryIata: "MGWFI",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Mandritsara",
        city: "Mandritsara",
        iata: "WMA",
        countryIata: "MGWMA",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Mandabe",
        city: "Mandabe",
        iata: "WMD",
        countryIata: "MGWMD",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Malaimbandy",
        city: "Malaimbandy",
        iata: "WML",
        countryIata: "MGWML",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Maroantsetra",
        city: "Maroantsetra",
        iata: "WMN",
        countryIata: "MGWMN",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Mampikony",
        city: "Mampikony",
        iata: "WMP",
        countryIata: "MGWMP",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Mananara",
        city: "Mananara",
        iata: "WMR",
        countryIata: "MGWMR",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Madirovalo",
        city: "Madirovalo",
        iata: "WMV",
        countryIata: "MGWMV",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Moramba",
        city: "Ankorefo",
        iata: "WOR",
        countryIata: "MGWOR",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Port Berge",
        city: "Port Berge",
        iata: "WPB",
        countryIata: "MGWPB",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Tambohorano",
        city: "Tambohorano",
        iata: "WTA",
        countryIata: "MGWTA",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Tsiroanomandidy",
        city: "Tsiroanomandidy",
        iata: "WTS",
        countryIata: "MGWTS",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Manakara",
        city: "Manakara",
        iata: "WVK",
        countryIata: "MGWVK",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Miandrivazo",
        city: "Miandrivazo",
        iata: "ZVA",
        countryIata: "MGZVA",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Andapa",
        city: "Andapa",
        iata: "ZWA",
        countryIata: "MGZWA",
        country: "Madagascar",
        countryCode: "MG",
    },
    {
        airportName: "Shirdi Airport (Maharashtra)",
        city: "Shirdi",
        iata: "SAG",
        countryIata: "INSAG",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Chileka",
        city: "Blantyre",
        iata: "BLZ",
        countryIata: "MWBLZ",
        country: "Malawi",
        countryCode: "MW",
    },
    {
        airportName: "Chelinda",
        city: "Chelinda",
        iata: "CEH",
        countryIata: "MWCEH",
        country: "Malawi",
        countryCode: "MW",
    },
    {
        airportName: "Club Makokola",
        city: "Club Makokola",
        iata: "CMK",
        countryIata: "MWCMK",
        country: "Malawi",
        countryCode: "MW",
    },
    {
        airportName: "Dwangwa",
        city: "Dwangwa",
        iata: "DWA",
        countryIata: "MWDWA",
        country: "Malawi",
        countryCode: "MW",
    },
    {
        airportName: "Kasungu",
        city: "Kasungu",
        iata: "KBQ",
        countryIata: "MWKBQ",
        country: "Malawi",
        countryCode: "MW",
    },
    {
        airportName: "Karonga",
        city: "Karonga",
        iata: "KGJ",
        countryIata: "MWKGJ",
        country: "Malawi",
        countryCode: "MW",
    },
    {
        airportName: "Likoma",
        city: "Likoma Island",
        iata: "LIX",
        countryIata: "MWLIX",
        country: "Malawi",
        countryCode: "MW",
    },
    {
        airportName: "Lilongwe International",
        city: "Lilongwe",
        iata: "LLW",
        countryIata: "MWLLW",
        country: "Malawi",
        countryCode: "MW",
    },
    {
        airportName: "Salima",
        city: "Salima",
        iata: "LMB",
        countryIata: "MWLMB",
        country: "Malawi",
        countryCode: "MW",
    },
    {
        airportName: "Mangochi",
        city: "Mangochi",
        iata: "MAI",
        countryIata: "MWMAI",
        country: "Malawi",
        countryCode: "MW",
    },
    {
        airportName: "Monkey Bay",
        city: "Monkey Bay",
        iata: "MYZ",
        countryIata: "MWMYZ",
        country: "Malawi",
        countryCode: "MW",
    },
    {
        airportName: "Alor Setar",
        city: "Alor Setar",
        iata: "AOR",
        countryIata: "MYAOR",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Bario",
        city: "Bario",
        iata: "BBN",
        countryIata: "MYBBN",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Kota Kinabalu",
        city: "Kota Kinabalu",
        iata: "BKI",
        countryIata: "MYBKI",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Bakalalan",
        city: "Bakalalan",
        iata: "BKM",
        countryIata: "MYBKM",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Belaga",
        city: "Belaga",
        iata: "BLG",
        countryIata: "MYBLG",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Sematan",
        city: "Sematan",
        iata: "BSE",
        countryIata: "MYBSE",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Bintulu",
        city: "Bintulu",
        iata: "BTU",
        countryIata: "MYBTU",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Butterworth",
        city: "Butterworth",
        iata: "BWH",
        countryIata: "MYBWH",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Long Pasia",
        city: "Long Pasia",
        iata: "GSA",
        countryIata: "MYGSA",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Genting",
        city: "Genting",
        iata: "GTB",
        countryIata: "MYGTB",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Sungei Tekai",
        city: "Sungei Tekai",
        iata: "GTK",
        countryIata: "MYGTK",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Ipoh",
        city: "Ipoh",
        iata: "IPH",
        countryIata: "MYIPH",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Sultan Ismail International",
        city: "Johor Bahru",
        iata: "JHB",
        countryIata: "MYJHB",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Pengkalan Chepa",
        city: "Kota Bharu",
        iata: "KBR",
        countryIata: "MYKBR",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Kuching",
        city: "Kuching",
        iata: "KCH",
        countryIata: "MYKCH",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Keningau",
        city: "Keningau",
        iata: "KGU",
        countryIata: "MYKGU",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Kapit",
        city: "Kapit",
        iata: "KPI",
        countryIata: "MYKPI",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Kerteh",
        city: "Kerteh",
        iata: "KTE",
        countryIata: "MYKTE",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Kuantan",
        city: "Kuantan",
        iata: "KUA",
        countryIata: "MYKUA",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Kudat",
        city: "Kudat",
        iata: "KUD",
        countryIata: "MYKUD",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Kuala Lumpur International",
        city: "Kuala Lumpur",
        iata: "KUL",
        countryIata: "MYKUL",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Layang-Layang Airstrip",
        city: "Pulau Layang-Layang Island",
        iata: "LAC",
        countryIata: "MYLAC",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Long Banga Airfield",
        city: "Long Banga",
        iata: "LBP",
        countryIata: "MYLBP",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Labuan",
        city: "Labuan",
        iata: "LBU",
        countryIata: "MYLBU",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Lahad Datu",
        city: "Lahad Datu",
        iata: "LDU",
        countryIata: "MYLDU",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Langkawi",
        city: "Langkawi",
        iata: "LGK",
        countryIata: "MYLGK",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Long Lellang",
        city: "Long Lellang",
        iata: "LGL",
        countryIata: "MYLGL",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Long Lama",
        city: "Long Lama",
        iata: "LLM",
        countryIata: "MYLLM",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Limbang",
        city: "Limbang",
        iata: "LMN",
        countryIata: "MYLMN",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Lawas",
        city: "Long Semado",
        iata: "LSM",
        countryIata: "MYLSM",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Long Sukang",
        city: "Long Sukang",
        iata: "LSU",
        countryIata: "MYLSU",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Lawas",
        city: "Lawas",
        iata: "LWY",
        countryIata: "MYLWY",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Mersing",
        city: "Mersing",
        iata: "MEP",
        countryIata: "MYMEP",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Mukah Airport",
        city: "Mukah",
        iata: "MKM",
        countryIata: "MYMKM",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Batu Berendum",
        city: "Malacca",
        iata: "MKZ",
        countryIata: "MYMKZ",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Marudi",
        city: "Marudi",
        iata: "MUR",
        countryIata: "MYMUR",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Miri",
        city: "Miri",
        iata: "MYY",
        countryIata: "MYMYY",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Mostyn",
        city: "Mostyn",
        iata: "MZS",
        countryIata: "MYMZS",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Mulu",
        city: "Mulu",
        iata: "MZV",
        countryIata: "MYMZV",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Long Seridan",
        city: "Long Seridan",
        iata: "ODN",
        countryIata: "MYODN",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Pamol",
        city: "Pamol",
        iata: "PAY",
        countryIata: "MYPAY",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Penang International",
        city: "Penang",
        iata: "PEN",
        countryIata: "MYPEN",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Pangkor Airport",
        city: "Pangkor",
        iata: "PKG",
        countryIata: "MYPKG",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Ranau",
        city: "Ranau",
        iata: "RNU",
        countryIata: "MYRNU",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Sibu",
        city: "Sibu",
        iata: "SBW",
        countryIata: "MYSBW",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Sandakan",
        city: "Sandakan",
        iata: "SDK",
        countryIata: "MYSDK",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Simanggang",
        city: "Simanggang",
        iata: "SGG",
        countryIata: "MYSGG",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Semporna",
        city: "Semporna",
        iata: "SMM",
        countryIata: "MYSMM",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Sepulot",
        city: "Sepulot",
        iata: "SPE",
        countryIata: "MYSPE",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Sipitang",
        city: "Sipitang",
        iata: "SPT",
        countryIata: "MYSPT",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Sitiawan",
        city: "Sitiawan",
        iata: "SWY",
        countryIata: "MYSWY",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Sahabat 16",
        city: "Sahabat 16",
        iata: "SXS",
        countryIata: "MYSXS",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Taman Negara",
        city: "Taman Negara",
        iata: "SXT",
        countryIata: "MYSXT",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Sultan Abdul Aziz Shah",
        city: "Kuala Lumpur",
        iata: "SZB",
        countryIata: "MYSZB",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Telupid",
        city: "Telupid",
        iata: "TEL",
        countryIata: "MYTEL",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Sultan Mahmood",
        city: "Kuala Terengganu",
        iata: "TGG",
        countryIata: "MYTGG",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Tomanggong",
        city: "Tomanggong",
        iata: "TMG",
        countryIata: "MYTMG",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Tioman",
        city: "Tioman",
        iata: "TOD",
        countryIata: "MYTOD",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Taiping",
        city: "Taiping",
        iata: "TPG",
        countryIata: "MYTPG",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Tawau",
        city: "Tawau",
        iata: "TWU",
        countryIata: "MYTWU",
        country: "Malaysia",
        countryCode: "MY",
    },
    {
        airportName: "Gan/Seenu",
        city: "Gan Island",
        iata: "GAN",
        countryIata: "MVGAN",
        country: "Maldives",
        countryCode: "MV",
    },
    {
        airportName: "Hanimaadhoo",
        city: "Hanimaadhoo",
        iata: "HAQ",
        countryIata: "MVHAQ",
        country: "Maldives",
        countryCode: "MV",
    },
    {
        airportName: "Kaadedhdhoo",
        city: "Kaadedhdhoo",
        iata: "KDM",
        countryIata: "MVKDM",
        country: "Maldives",
        countryCode: "MV",
    },
    {
        airportName: "Kadhdhoo",
        city: "Kadhdhoo",
        iata: "KDO",
        countryIata: "MVKDO",
        country: "Maldives",
        countryCode: "MV",
    },
    {
        airportName: "International",
        city: "Male",
        iata: "MLE",
        countryIata: "MVMLE",
        country: "Maldives",
        countryCode: "MV",
    },
    {
        airportName: "Bamako",
        city: "Bamako",
        iata: "BKO",
        countryIata: "MLBKO",
        country: "Mali",
        countryCode: "ML",
    },
    {
        airportName: "Yelimane",
        city: "Yelimane",
        iata: "EYL",
        countryIata: "MLEYL",
        country: "Mali",
        countryCode: "ML",
    },
    {
        airportName: "Gao",
        city: "Gao",
        iata: "GAQ",
        countryIata: "MLGAQ",
        country: "Mali",
        countryCode: "ML",
    },
    {
        airportName: "Goundam",
        city: "Goundam",
        iata: "GUD",
        countryIata: "MLGUD",
        country: "Mali",
        countryCode: "ML",
    },
    {
        airportName: "Kenieba",
        city: "Kenieba",
        iata: "KNZ",
        countryIata: "MLKNZ",
        country: "Mali",
        countryCode: "ML",
    },
    {
        airportName: "Sikasso",
        city: "Sikasso",
        iata: "KSS",
        countryIata: "MLKSS",
        country: "Mali",
        countryCode: "ML",
    },
    {
        airportName: "Koutiala",
        city: "Koutiala",
        iata: "KTX",
        countryIata: "MLKTX",
        country: "Mali",
        countryCode: "ML",
    },
    {
        airportName: "Kayes Airport (Dag Dag Airport)",
        city: "Kayes",
        iata: "KYS",
        countryIata: "MLKYS",
        country: "Mali",
        countryCode: "ML",
    },
    {
        airportName: "Mopti",
        city: "Mopti",
        iata: "MZI",
        countryIata: "MLMZI",
        country: "Mali",
        countryCode: "ML",
    },
    {
        airportName: "Nioro",
        city: "Nioro",
        iata: "NIX",
        countryIata: "MLNIX",
        country: "Mali",
        countryCode: "ML",
    },
    {
        airportName: "Nara",
        city: "Nara",
        iata: "NRM",
        countryIata: "MLNRM",
        country: "Mali",
        countryCode: "ML",
    },
    {
        airportName: "Segou",
        city: "Segou",
        iata: "SZU",
        countryIata: "MLSZU",
        country: "Mali",
        countryCode: "ML",
    },
    {
        airportName: "Tombouctou",
        city: "Tombouctou",
        iata: "TOM",
        countryIata: "MLTOM",
        country: "Mali",
        countryCode: "ML",
    },
    {
        airportName: "Gozo",
        city: "Gozo",
        iata: "GZM",
        countryIata: "MTGZM",
        country: "Malta",
        countryCode: "MT",
    },
    {
        airportName: "Heliport",
        city: "Comino",
        iata: "JCO",
        countryIata: "MTJCO",
        country: "Malta",
        countryCode: "MT",
    },
    {
        airportName: "Luqa",
        city: "Malta, Valletta",
        iata: "MLA",
        countryIata: "MTMLA",
        country: "Malta",
        countryCode: "MT",
    },
    {
        airportName: "Airok",
        city: "Airok",
        iata: "AIC",
        countryIata: "MHAIC",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Ailuk Island",
        city: "Ailuk Island",
        iata: "AIM",
        countryIata: "MHAIM",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Ailinglapalap Island",
        city: "Ailinglapalap Island",
        iata: "AIP",
        countryIata: "MHAIP",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Arno",
        city: "Arno",
        iata: "AMR",
        countryIata: "MHAMR",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Aur Island",
        city: "Aur Island",
        iata: "AUL",
        countryIata: "MHAUL",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Enyu Airfield",
        city: "Bikini Atoll",
        iata: "BII",
        countryIata: "MHBII",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Ebadon",
        city: "Ebadon",
        iata: "EBN",
        countryIata: "MHEBN",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Ebon Airport",
        city: "Ebon",
        iata: "EBO",
        countryIata: "MHEBO",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Enewetak Island",
        city: "Enewetak Island",
        iata: "ENT",
        countryIata: "MHENT",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Ine Island",
        city: "Ine Island",
        iata: "IMI",
        countryIata: "MHIMI",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Jabot",
        city: "Jabot",
        iata: "JAT",
        countryIata: "MHJAT",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Jeh",
        city: "Jeh",
        iata: "JEJ",
        countryIata: "MHJEJ",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Kaben",
        city: "Kaben",
        iata: "KBT",
        countryIata: "MHKBT",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Kili Island",
        city: "Kili Island",
        iata: "KIO",
        countryIata: "MHKIO",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Kwajalein",
        city: "Kwajalein",
        iata: "KWA",
        countryIata: "MHKWA",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Likiep Island",
        city: "Likiep Island",
        iata: "LIK",
        countryIata: "MHLIK",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Lae Island",
        city: "Lae Island",
        iata: "LML",
        countryIata: "MHLML",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Loen",
        city: "Loen",
        iata: "LOF",
        countryIata: "MHLOF",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Amata Kabua International",
        city: "Majuro",
        iata: "MAJ",
        countryIata: "MHMAJ",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Maloelap airstrip",
        city: "Maloelap Island",
        iata: "MAV",
        countryIata: "MHMAV",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Mili Island",
        city: "Mili Island",
        iata: "MIJ",
        countryIata: "MHMIJ",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Mejit Island",
        city: "Mejit Island",
        iata: "MJB",
        countryIata: "MHMJB",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Majkin",
        city: "Majkin",
        iata: "MJE",
        countryIata: "MHMJE",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Namdrik Island",
        city: "Namdrik Island",
        iata: "NDK",
        countryIata: "MHNDK",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Namu",
        city: "Namu",
        iata: "NMU",
        countryIata: "MHNMU",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Rongelap Island",
        city: "Rongelap Island",
        iata: "RNP",
        countryIata: "MHRNP",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Tabal",
        city: "Tabal",
        iata: "TBV",
        countryIata: "MHTBV",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Tinak Island",
        city: "Tinak Island",
        iata: "TIC",
        countryIata: "MHTIC",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Jaluit Island",
        city: "Jaluit Island",
        iata: "UIT",
        countryIata: "MHUIT",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Ujae Island",
        city: "Ujae Island",
        iata: "UJE",
        countryIata: "MHUJE",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Utirik Island",
        city: "Utirik Island",
        iata: "UTK",
        countryIata: "MHUTK",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Woja",
        city: "Woja",
        iata: "WJA",
        countryIata: "MHWJA",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Wotje Island",
        city: "Wotje Island",
        iata: "WTE",
        countryIata: "MHWTE",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Wotho Island",
        city: "Wotho Island",
        iata: "WTO",
        countryIata: "MHWTO",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Lamentin",
        city: "Fort De France",
        iata: "FDF",
        countryIata: "MQFDF",
        country: "Martinique",
        countryCode: "MQ",
    },
    {
        airportName: "Aioun El Atrouss",
        city: "Aioun El Atrouss",
        iata: "AEO",
        countryIata: "MRAEO",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Akjoujt",
        city: "Akjoujt",
        iata: "AJJ",
        countryIata: "MRAJJ",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Atar Mouakchott",
        city: "Atar",
        iata: "ATR",
        countryIata: "MRATR",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Abbaye",
        city: "Boghe",
        iata: "BGH",
        countryIata: "MRBGH",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Chinguitti",
        city: "Chinguitti",
        iata: "CGT",
        countryIata: "MRCGT",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Nema",
        city: "Nema",
        iata: "EMN",
        countryIata: "MREMN",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Fderik",
        city: "Fderik",
        iata: "FGD",
        countryIata: "MRFGD",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Kaedi",
        city: "Kaedi",
        iata: "KED",
        countryIata: "MRKED",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Kiffa",
        city: "Kiffa",
        iata: "KFA",
        countryIata: "MRKFA",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Aleg",
        city: "Aleg",
        iata: "LEG",
        countryIata: "MRLEG",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Mbout",
        city: "Mbout",
        iata: "MBR",
        countryIata: "MRMBR",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Moudjeria",
        city: "Moudjeria",
        iata: "MOM",
        countryIata: "MRMOM",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Nouadhibou",
        city: "Nouadhibou",
        iata: "NDB",
        countryIata: "MRNDB",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Nouakchott–Oumtounsy International Airport",
        city: "Nouakchott",
        iata: "NKC",
        countryIata: "MRNKC",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Boutilimit",
        city: "Boutilimit",
        iata: "OTL",
        countryIata: "MROTL",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Zouerate",
        city: "Zouerate",
        iata: "OUZ",
        countryIata: "MROUZ",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Selibaby",
        city: "Selibaby",
        iata: "SEY",
        countryIata: "MRSEY",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Tichitt",
        city: "Tichitt",
        iata: "THI",
        countryIata: "MRTHI",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Tamchakett",
        city: "Tamchakett",
        iata: "THT",
        countryIata: "MRTHT",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Tidjikja",
        city: "Tidjikja",
        iata: "TIY",
        countryIata: "MRTIY",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Timbedra",
        city: "Timbedra",
        iata: "TMD",
        countryIata: "MRTMD",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "El Gouera",
        city: "El Gouera",
        iata: "ZLG",
        countryIata: "MRZLG",
        country: "Mauritania",
        countryCode: "MR",
    },
    {
        airportName: "Seewoosagur Ramgoolam International",
        city: "Mauritius",
        iata: "MRU",
        countryIata: "MUMRU",
        country: "Mauritius",
        countryCode: "MU",
    },
    {
        airportName: "Rodrigues Island",
        city: "Rodrigues Island",
        iata: "RRG",
        countryIata: "MURRG",
        country: "Mauritius",
        countryCode: "MU",
    },
    {
        airportName: "Acapulco International Airport",
        city: "Acapulco",
        iata: "ACA",
        countryIata: "MXACA",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "International",
        city: "Ciudad Acuna",
        iata: "ACN",
        countryIata: "MXACN",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Jesús Terán Peredo International Airport",
        city: "Aguascalientes",
        iata: "AGU",
        countryIata: "MXAGU",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Abreojos",
        city: "Abreojos",
        iata: "AJS",
        countryIata: "MXAJS",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Apatzingan",
        city: "Apatzingan",
        iata: "AZG",
        countryIata: "MXAZG",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Atizapan",
        city: "Mexico City",
        iata: "AZP",
        countryIata: "MXAZP",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Bahia Angeles",
        city: "Bahia Angeles",
        iata: "BHL",
        countryIata: "MXBHL",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Del Bajio",
        city: "Leon/Guanajuato",
        iata: "BJX",
        countryIata: "MXBJX",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Ciudad Obregon",
        city: "Ciudad Obregon",
        iata: "CEN",
        countryIata: "MXCEN",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "International Abraham Gonzalez",
        city: "Ciudad Juarez",
        iata: "CJS",
        countryIata: "MXCJS",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Copalar",
        city: "Comitan",
        iata: "CJT",
        countryIata: "MXCJT",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Colima",
        city: "Colima",
        iata: "CLQ",
        countryIata: "MXCLQ",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Ciudad Del Carmen",
        city: "Ciudad Del Carmen",
        iata: "CME",
        countryIata: "MXCME",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Cananea",
        city: "Cananea",
        iata: "CNA",
        countryIata: "MXCNA",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Campeche International",
        city: "Campeche",
        iata: "CPE",
        countryIata: "MXCPE",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Chetumal",
        city: "Chetumal",
        iata: "CTM",
        countryIata: "MXCTM",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Ciudad Constitucion",
        city: "Ciudad Constitucion",
        iata: "CUA",
        countryIata: "MXCUA",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Fedl De Bachigualato",
        city: "Culiacan",
        iata: "CUL",
        countryIata: "MXCUL",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Cancun International Airport",
        city: "Cancun",
        iata: "CUN",
        countryIata: "MXCUN",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Gen Fierro Villalobos",
        city: "Chihuahua",
        iata: "CUU",
        countryIata: "MXCUU",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Cuernavaca",
        city: "Cuernavaca",
        iata: "CVJ",
        countryIata: "MXCVJ",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Ciudad Victoria",
        city: "Ciudad Victoria",
        iata: "CVM",
        countryIata: "MXCVM",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Chichen Itza",
        city: "Chichen Itza",
        iata: "CZA",
        countryIata: "MXCZA",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Cozumel",
        city: "Cozumel",
        iata: "CZM",
        countryIata: "MXCZM",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Guadalupe Victoria",
        city: "Durango",
        iata: "DGO",
        countryIata: "MXDGO",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Ensenada",
        city: "Ensenada",
        iata: "ESE",
        countryIata: "MXESE",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Miguel Hidal",
        city: "Guadalajara",
        iata: "GDL",
        countryIata: "MXGDL",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Guerrero Negro",
        city: "Guerrero Negro",
        iata: "GUB",
        countryIata: "MXGUB",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Gen Jose M Yanez",
        city: "Guaymas",
        iata: "GYM",
        countryIata: "MXGYM",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Gen Pesqueira Garcia",
        city: "Hermosillo",
        iata: "HMO",
        countryIata: "MXHMO",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Huatulco",
        city: "Huatulco",
        iata: "HUX",
        countryIata: "MXHUX",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Isla Mujeres",
        city: "Isla Mujeres",
        iata: "ISJ",
        countryIata: "MXISJ",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Ixtepec",
        city: "Ixtepec",
        iata: "IZT",
        countryIata: "MXIZT",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Jalapa",
        city: "Jalapa",
        iata: "JAL",
        countryIata: "MXJAL",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Leon",
        city: "La Paz",
        iata: "LAP",
        countryIata: "MXLAP",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Federal",
        city: "Los Mochis",
        iata: "LMM",
        countryIata: "MXLMM",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Francisco P. V. y R.",
        city: "Lagos de Moreno",
        iata: "LOM",
        countryIata: "MXLOM",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Monclova",
        city: "Monclova",
        iata: "LOV",
        countryIata: "MXLOV",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Loreto",
        city: "Loreto",
        iata: "LTO",
        countryIata: "MXLTO",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Lazaro Cardenas",
        city: "Lazaro Cardenas",
        iata: "LZC",
        countryIata: "MXLZC",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Matamoros",
        city: "Matamoros",
        iata: "MAM",
        countryIata: "MXMAM",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Juarez International",
        city: "Mexico City",
        iata: "MEX",
        countryIata: "MXMEX",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Rejon",
        city: "Merida",
        iata: "MID",
        countryIata: "MXMID",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Morelia",
        city: "Morelia",
        iata: "MLM",
        countryIata: "MXMLM",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Ciudad Mante",
        city: "Ciudad Mante",
        iata: "MMC",
        countryIata: "MXMMC",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Minatitlan",
        city: "Minatitlan",
        iata: "MTT",
        countryIata: "MXMTT",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Gen Mariano Escobedo",
        city: "Monterrey",
        iata: "MTY",
        countryIata: "MXMTY",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Mulege",
        city: "Mulege",
        iata: "MUG",
        countryIata: "MXMUG",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Mexicali",
        city: "Mexicali",
        iata: "MXL",
        countryIata: "MXMXL",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Gen. Rafael Buelna",
        city: "Mazatlan",
        iata: "MZT",
        countryIata: "MXMZT",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Nueva Casas Grandes",
        city: "Nueva Casas Grandes",
        iata: "NCG",
        countryIata: "MXNCG",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Quetzalcóatl International Airport",
        city: "Nuevo Laredo",
        iata: "NLD",
        countryIata: "MXNLD",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Santa Lucia Airport at Santa María Ajoloapan",
        city: "Mexico City",
        iata: "NLU",
        countryIata: "MXNLU",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Nogales",
        city: "Nogales",
        iata: "NOG",
        countryIata: "MXNOG",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Aeropuerto Del Norte",
        city: "Monterrey",
        iata: "NTR",
        countryIata: "MXNTR",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Xoxocotlan",
        city: "Oaxaca",
        iata: "OAX",
        countryIata: "MXOAX",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Tajin",
        city: "Poza Rica",
        iata: "PAZ",
        countryIata: "MXPAZ",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Huejotsingo",
        city: "Puebla",
        iata: "PBC",
        countryIata: "MXPBC",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Playa del Carmen",
        city: "Playa del Carmen",
        iata: "PCM",
        countryIata: "MXPCM",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Punta Colorada",
        city: "Punta Colorada",
        iata: "PCO",
        countryIata: "MXPCO",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Punta Chivato",
        city: "Punta Chivato",
        iata: "PCV",
        countryIata: "MXPCV",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Piedras Negras",
        city: "Piedras Negras",
        iata: "PDS",
        countryIata: "MXPDS",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Puerto Juarez",
        city: "Puerto Juarez",
        iata: "PJZ",
        countryIata: "MXPJZ",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Pinotepa Nacional",
        city: "Pinotepa Nacional",
        iata: "PNO",
        countryIata: "MXPNO",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Puerto Penasco",
        city: "Puerto Penasco",
        iata: "PPE",
        countryIata: "MXPPE",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Palenque",
        city: "Palenque",
        iata: "PQM",
        countryIata: "MXPQM",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Pochutla",
        city: "Pochutla",
        iata: "PUH",
        countryIata: "MXPUH",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Ordaz",
        city: "Puerto Vallarta",
        iata: "PVR",
        countryIata: "MXPVR",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Puerto Escondido",
        city: "Puerto Escondido",
        iata: "PXM",
        countryIata: "MXPXM",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Querétaro Intercontinental Airport",
        city: "Queretaro",
        iata: "QRO",
        countryIata: "MXQRO",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Gen. Lucio Blanco",
        city: "Reynosa",
        iata: "REX",
        countryIata: "MXREX",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Salina Cruz",
        city: "Salina Cruz",
        iata: "SCX",
        countryIata: "MXSCX",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "San Felipe",
        city: "San Felipe",
        iata: "SFH",
        countryIata: "MXSFH",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "San Ignacio",
        city: "San Ignacio",
        iata: "SGM",
        countryIata: "MXSGM",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Los Cabos",
        city: "San Jose Cabo",
        iata: "SJD",
        countryIata: "MXSJD",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "San Luis Potosi",
        city: "San Luis Potosi",
        iata: "SLP",
        countryIata: "MXSLP",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Saltillo",
        city: "Saltillo",
        iata: "SLW",
        countryIata: "MXSLW",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "San Quintin",
        city: "San Quintin",
        iata: "SNQ",
        countryIata: "MXSNQ",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Santa Rosalia",
        city: "Santa Rosalia",
        iata: "SRL",
        countryIata: "MXSRL",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "San Cristobal Airport",
        city: "S.Cristobal del Casas",
        iata: "SZT",
        countryIata: "MXSZT",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Gen F Javier Mina",
        city: "Tampico",
        iata: "TAM",
        countryIata: "MXTAM",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "International",
        city: "Tapachula",
        iata: "TAP",
        countryIata: "MXTAP",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Tehuacan",
        city: "Tehuacan",
        iata: "TCN",
        countryIata: "MXTCN",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Llano San Juan",
        city: "Tuxtla Gutierrez",
        iata: "TGZ",
        countryIata: "MXTGZ",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Rodriguez",
        city: "Tijuana",
        iata: "TIJ",
        countryIata: "MXTIJ",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Toluca",
        city: "Toluca",
        iata: "TLC",
        countryIata: "MXTLC",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Tepic",
        city: "Tepic",
        iata: "TPQ",
        countryIata: "MXTPQ",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Torreon",
        city: "Torreon",
        iata: "TRC",
        countryIata: "MXTRC",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Tamuin",
        city: "Tamuin",
        iata: "TSL",
        countryIata: "MXTSL",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Tulum",
        city: "Tulum",
        iata: "TUY",
        countryIata: "MXTUY",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Tizimin",
        city: "Tizimin",
        iata: "TZM",
        countryIata: "MXTZM",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "San Luis Rio Colorado",
        city: "San Luis Rio Colorado",
        iata: "UAC",
        countryIata: "MXUAC",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Uruapan",
        city: "Uruapan",
        iata: "UPN",
        countryIata: "MXUPN",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Las Bajadas",
        city: "Veracruz",
        iata: "VER",
        countryIata: "MXVER",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Villa Constitucion",
        city: "Villa Constitucion",
        iata: "VIB",
        countryIata: "MXVIB",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Capitan Carlos Perez",
        city: "Villahermosa",
        iata: "VSA",
        countryIata: "MXVSA",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Alamos",
        city: "Alamos",
        iata: "XAL",
        countryIata: "MXXAL",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Zacatecas International Airport (La Calera)",
        city: "Zacatecas",
        iata: "ZCL",
        countryIata: "MXZCL",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Ixtapa-Zihuatanejo International Airport",
        city: "Ixtapa/Zihuatanejo",
        iata: "ZIH",
        countryIata: "MXZIH",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Playa de Oro International",
        city: "Manzanillo",
        iata: "ZLO",
        countryIata: "MXZLO",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Zamora",
        city: "Zamora",
        iata: "ZMM",
        countryIata: "MXZMM",
        country: "Mexico",
        countryCode: "MX",
    },
    {
        airportName: "Kosrae",
        city: "Kosrae",
        iata: "KSA",
        countryIata: "MHKSA",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Pohnpei",
        city: "Pohnpei",
        iata: "PNI",
        countryIata: "MHPNI",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Truk",
        city: "Truk",
        iata: "TKK",
        countryIata: "MHTKK",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Ulithi",
        city: "Ulithi",
        iata: "ULI",
        countryIata: "MHULI",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Yap",
        city: "Yap",
        iata: "YAP",
        countryIata: "MHYAP",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Beltsy",
        city: "Beltsy",
        iata: "BZY",
        countryIata: "MHBZY",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Chisinau",
        city: "Chisinau",
        iata: "KIV",
        countryIata: "MHKIV",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Heliport",
        city: "Monaco",
        iata: "MCM",
        countryIata: "MCMCM",
        country: "Monaco",
        countryCode: "MC",
    },
    {
        airportName: "Arvaikheer",
        city: "Arvaikheer",
        iata: "AVK",
        countryIata: "MNAVK",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Bayankhongor",
        city: "Bayankhongor",
        iata: "BYN",
        countryIata: "MNBYN",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Choibalsan",
        city: "Choibalsan",
        iata: "COQ",
        countryIata: "MNCOQ",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Dalanzadgad",
        city: "Dalanzadgad",
        iata: "DLZ",
        countryIata: "MNDLZ",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Erdenet",
        city: "Erdenet",
        iata: "ERT",
        countryIata: "MNERT",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Khujirt",
        city: "Khujirt",
        iata: "HJT",
        countryIata: "MNHJT",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Khovd",
        city: "Khovd",
        iata: "HVD",
        countryIata: "MNHVD",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Kharkhorin",
        city: "Kharkhorin",
        iata: "KHR",
        countryIata: "MNKHR",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Altai",
        city: "Altai",
        iata: "LTI",
        countryIata: "MNLTI",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Moron",
        city: "Moron",
        iata: "MXV",
        countryIata: "MNMXV",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Mandalgobi",
        city: "Mandalgobi",
        iata: "MXW",
        countryIata: "MNMXW",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Tsetserleg",
        city: "Tsetserleg",
        iata: "TSZ",
        countryIata: "MNTSZ",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Bulgan",
        city: "Bulgan",
        iata: "UGA",
        countryIata: "MNUGA",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Ulgit",
        city: "Ulgit",
        iata: "ULG",
        countryIata: "MNULG",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Buyant Uhaa",
        city: "Ulaanbaatar",
        iata: "ULN",
        countryIata: "MNULN",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Ulaangom",
        city: "Ulaangom",
        iata: "ULO",
        countryIata: "MNULO",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Uliastai",
        city: "Uliastai",
        iata: "ULZ",
        countryIata: "MNULZ",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Underkhaan",
        city: "Underkhaan",
        iata: "UNR",
        countryIata: "MNUNR",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Baruun-Urt",
        city: "Baruun-Urt",
        iata: "UUN",
        countryIata: "MNUUN",
        country: "Mongolia",
        countryCode: "MN",
    },
    {
        airportName: "Herceg Novi Airport",
        city: "Herceg Novi",
        iata: "HNO",
        countryIata: "MEHNO",
        country: "Montenegro",
        countryCode: "ME",
    },
    {
        airportName: "Dolac Airport",
        city: "Ivangrad (Berane)",
        iata: "IVG",
        countryIata: "MEIVG",
        country: "Montenegro",
        countryCode: "ME",
    },
    {
        airportName: "Golubovci",
        city: "Podgorica",
        iata: "TGD",
        countryIata: "METGD",
        country: "Montenegro",
        countryCode: "ME",
    },
    {
        airportName: "Tivat Airport",
        city: "Tivat",
        iata: "TIV",
        countryIata: "METIV",
        country: "Montenegro",
        countryCode: "ME",
    },
    {
        airportName: "Zabljak",
        city: "Zabljak",
        iata: "ZBK",
        countryIata: "MEZBK",
        country: "Montenegro",
        countryCode: "ME",
    },
    {
        airportName: "Bramble",
        city: "Montserrat",
        iata: "MNI",
        countryIata: "MSMNI",
        country: "Montserrat",
        countryCode: "MS",
    },
    {
        airportName: "Agadir–Al Massira Airport",
        city: "Agadir",
        iata: "AGA",
        countryIata: "MAAGA",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Charif Al Idrissi",
        city: "Al Hoceima",
        iata: "AHU",
        countryIata: "MAAHU",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Anfa",
        city: "Casablanca",
        iata: "CAS",
        countryIata: "MACAS",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Mohamed V",
        city: "Casablanca",
        iata: "CMN",
        countryIata: "MACMN",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Errachidia",
        city: "Errachidia",
        iata: "ERH",
        countryIata: "MAERH",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Essaouira",
        city: "Essaouira",
        iata: "ESU",
        countryIata: "MAESU",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Hassan I",
        city: "Laayoune",
        iata: "EUN",
        countryIata: "MAEUN",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Sais",
        city: "Fez",
        iata: "FEZ",
        countryIata: "MAFEZ",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Goulimime",
        city: "Goulimime",
        iata: "GLN",
        countryIata: "MAGLN",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Meknes",
        city: "Meknes",
        iata: "MEK",
        countryIata: "MAMEK",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Nador",
        city: "Nador",
        iata: "NDR",
        countryIata: "MANDR",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "NAF",
        city: "Kenitra",
        iata: "NNA",
        countryIata: "MANNA",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Les Angades",
        city: "Oujda",
        iata: "OUD",
        countryIata: "MAOUD",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Ouarzazate",
        city: "Ouarzazate",
        iata: "OZZ",
        countryIata: "MAOZZ",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Menara Airport",
        city: "Marrakesh",
        iata: "RAK",
        countryIata: "MARAK",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Sale Airport",
        city: "Rabat",
        iata: "RBA",
        countryIata: "MARBA",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Ksar Es Souk",
        city: "Ksar Es Souk",
        iata: "SEK",
        countryIata: "MASEK",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Safi",
        city: "Safi",
        iata: "SFI",
        countryIata: "MASFI",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Sidi Ifni",
        city: "Sidi Ifni",
        iata: "SII",
        countryIata: "MASII",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Smara",
        city: "Smara",
        iata: "SMW",
        countryIata: "MASMW",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Tarfaya",
        city: "Tarfaya",
        iata: "TFY",
        countryIata: "MATFY",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Boukhalef",
        city: "Tangier",
        iata: "TNG",
        countryIata: "MATNG",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Tan Tan",
        city: "Tan Tan",
        iata: "TTA",
        countryIata: "MATTA",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Sania Ramel",
        city: "Tetuan",
        iata: "TTU",
        countryIata: "MATTU",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Dakhla",
        city: "Dakhla",
        iata: "VIL",
        countryIata: "MAVIL",
        country: "Morocco",
        countryCode: "MA",
    },
    {
        airportName: "Alto Molocue",
        city: "Alto Molocue",
        iata: "AME",
        countryIata: "MZAME",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Angoche",
        city: "Angoche",
        iata: "ANO",
        countryIata: "MZANO",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Nampula",
        city: "Nampula",
        iata: "APL",
        countryIata: "MZAPL",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Benguera Island",
        city: "Benguera Island",
        iata: "BCW",
        countryIata: "MZBCW",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Beira",
        city: "Beira",
        iata: "BEW",
        countryIata: "MZBEW",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Bajone",
        city: "Bajone",
        iata: "BJN",
        countryIata: "MZBJN",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Bazaruto Island",
        city: "Bazaruto Island",
        iata: "BZB",
        countryIata: "MZBZB",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Caia",
        city: "Caia",
        iata: "CMZ",
        countryIata: "MZCMZ",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Cuamba",
        city: "Cuamba",
        iata: "FXO",
        countryIata: "MZFXO",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Ibo",
        city: "Ibo",
        iata: "IBO",
        countryIata: "MZIBO",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Inhaminga",
        city: "Inhaminga",
        iata: "IMG",
        countryIata: "MZIMG",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Chinde",
        city: "Chinde",
        iata: "INE",
        countryIata: "MZINE",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Inhambane",
        city: "Inhambane",
        iata: "INH",
        countryIata: "MZINH",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Luabo",
        city: "Luabo",
        iata: "LBM",
        countryIata: "MZLBM",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Palma",
        city: "Palma",
        iata: "LMZ",
        countryIata: "MZLMZ",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Magaruque",
        city: "Magaruque",
        iata: "MFW",
        countryIata: "MZMFW",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Maganja Da Costa",
        city: "Maganja Da Costa",
        iata: "MJS",
        countryIata: "MZMJS",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Moma",
        city: "Moma",
        iata: "MMW",
        countryIata: "MZMMW",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Nacala",
        city: "Nacala",
        iata: "MNC",
        countryIata: "MZMNC",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Maputo International",
        city: "Maputo",
        iata: "MPM",
        countryIata: "MZMPM",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Montepuez",
        city: "Montepuez",
        iata: "MTU",
        countryIata: "MZMTU",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Mueda",
        city: "Mueda",
        iata: "MUD",
        countryIata: "MZMUD",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Mocimboa Praia",
        city: "Mocimboa Praia",
        iata: "MZB",
        countryIata: "MZMZB",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Nangade",
        city: "Nangade",
        iata: "NND",
        countryIata: "MZNND",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Santa Carolina",
        city: "Santa Carolina",
        iata: "NTC",
        countryIata: "MZNTC",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Pebane",
        city: "Pebane",
        iata: "PEB",
        countryIata: "MZPEB",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Pemba",
        city: "Pemba",
        iata: "POL",
        countryIata: "MZPOL",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Marromeu",
        city: "Marromeu",
        iata: "RRM",
        countryIata: "MZRRM",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Tete",
        city: "Tete",
        iata: "TCV",
        countryIata: "MZTCV",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Matunda",
        city: "Tete",
        iata: "TET",
        countryIata: "MZTET",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Chokwe",
        city: "Chokwe",
        iata: "TGS",
        countryIata: "MZTGS",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Quelimane",
        city: "Quelimane",
        iata: "UEL",
        countryIata: "MZUEL",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Xai Xai",
        city: "Xai Xai",
        iata: "VJB",
        countryIata: "MZVJB",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Gurue",
        city: "Gurue",
        iata: "VJQ",
        countryIata: "MZVJQ",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Vilanculos",
        city: "Vilanculos",
        iata: "VNX",
        countryIata: "MZVNX",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Chimoio",
        city: "Chimoio",
        iata: "VPY",
        countryIata: "MZVPY",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Lichinga",
        city: "Lichinga",
        iata: "VXC",
        countryIata: "MZVXC",
        country: "Mozambique",
        countryCode: "MZ",
    },
    {
        airportName: "Sittwe Airport",
        city: "Sittwe",
        iata: "AKY",
        countryIata: "MMAKY",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Bhamo",
        city: "Bhamo",
        iata: "BMO",
        countryIata: "MMBMO",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Bagan",
        city: "Bagan",
        iata: "BPE",
        countryIata: "MMBPE",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Bassein",
        city: "Bassein",
        iata: "BSX",
        countryIata: "MMBSX",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Gangaw",
        city: "Gangaw",
        iata: "GAW",
        countryIata: "MMGAW",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Gwa",
        city: "Gwa",
        iata: "GWA",
        countryIata: "MMGWA",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Henzada",
        city: "Henzada",
        iata: "HEB",
        countryIata: "MMHEB",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Heho",
        city: "Heho",
        iata: "HEH",
        countryIata: "MMHEH",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Homalin",
        city: "Homalin",
        iata: "HOX",
        countryIata: "MMHOX",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Kawthaung",
        city: "Kawthaung",
        iata: "KAW",
        countryIata: "MMKAW",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Keng Tung",
        city: "Keng Tung",
        iata: "KET",
        countryIata: "MMKET",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Khamti",
        city: "Khamti",
        iata: "KHM",
        countryIata: "MMKHM",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Kalemyo",
        city: "Kalemyo",
        iata: "KMV",
        countryIata: "MMKMV",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Kyaukpyu Airport",
        city: "Kyaukpyu",
        iata: "KYP",
        countryIata: "MMKYP",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Kyauktaw (next airport in Sittwe - AKY)",
        city: "Kyauktaw",
        iata: "KYT",
        countryIata: "MMKYT",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Loikaw",
        city: "Loikaw",
        iata: "LIW",
        countryIata: "MMLIW",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Lashio",
        city: "Lashio",
        iata: "LSH",
        countryIata: "MMLSH",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Mandalay International Airport (Annisaton)",
        city: "Mandalay",
        iata: "MDL",
        countryIata: "MMMDL",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Mong Ton",
        city: "Mong Ton",
        iata: "MGK",
        countryIata: "MMMGK",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Manaung",
        city: "Manaung",
        iata: "MGU",
        countryIata: "MMMGU",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Myeik Airport",
        city: "Myeik",
        iata: "MGZ",
        countryIata: "MMMGZ",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Mawlamyine Airport",
        city: "Mawlamyine",
        iata: "MNU",
        countryIata: "MMMNU",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Momeik",
        city: "Momeik",
        iata: "MOE",
        countryIata: "MMMOE",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Mong Hsat",
        city: "Mong Hsat",
        iata: "MOG",
        countryIata: "MMMOG",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Magwe",
        city: "Magwe",
        iata: "MWQ",
        countryIata: "MMMWQ",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Myitkyina",
        city: "Myitkyina",
        iata: "MYT",
        countryIata: "MMMYT",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Namsang",
        city: "Namsang",
        iata: "NMS",
        countryIata: "MMNMS",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Namtu",
        city: "Namtu",
        iata: "NMT",
        countryIata: "MMNMT",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "NyaungU",
        city: "Nyaung-u",
        iata: "NYU",
        countryIata: "MMNYU",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Pa-an",
        city: "Pa-an",
        iata: "PAA",
        countryIata: "MMPAA",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Pauk",
        city: "Pauk",
        iata: "PAU",
        countryIata: "MMPAU",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Putao",
        city: "Putao",
        iata: "PBU",
        countryIata: "MMPBU",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Pakokku",
        city: "Pakokku",
        iata: "PKK",
        countryIata: "MMPKK",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Papun",
        city: "Papun",
        iata: "PPU",
        countryIata: "MMPPU",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Prome",
        city: "Prome",
        iata: "PRU",
        countryIata: "MMPRU",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Mingaladon",
        city: "Yangon",
        iata: "RGN",
        countryIata: "MMRGN",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Thandwe",
        city: "Thandwe",
        iata: "SNW",
        countryIata: "MMSNW",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Tachilek",
        city: "Tachilek",
        iata: "THL",
        countryIata: "MMTHL",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Tilin",
        city: "Tilin",
        iata: "TIO",
        countryIata: "MMTIO",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Dawe",
        city: "Dawe",
        iata: "TVY",
        countryIata: "MMTVY",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Ye Airport",
        city: "Ye",
        iata: "XYE",
        countryIata: "MMXYE",
        country: "Myanmar",
        countryCode: "MM",
    },
    {
        airportName: "Arandis",
        city: "Arandis",
        iata: "ADI",
        countryIata: "NAADI",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Ai-Ais",
        city: "Ai-Ais",
        iata: "AIW",
        countryIata: "NAAIW",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Bagani",
        city: "Bagani",
        iata: "BQI",
        countryIata: "NABQI",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Eros Airport",
        city: "Windhoek",
        iata: "ERS",
        countryIata: "NAERS",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Grootfontein",
        city: "Grootfontein",
        iata: "GFY",
        countryIata: "NAGFY",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Gobabis",
        city: "Gobabis",
        iata: "GOG",
        countryIata: "NAGOG",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Halali",
        city: "Halali",
        iata: "HAL",
        countryIata: "NAHAL",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Karasburg",
        city: "Karasburg",
        iata: "KAS",
        countryIata: "NAKAS",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "J.G.H. Van Der Wath",
        city: "Keetmanshoop",
        iata: "KMP",
        countryIata: "NAKMP",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Luderitz",
        city: "Luderitz",
        iata: "LUD",
        countryIata: "NALUD",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Mount Etjo Lodge",
        city: "Mount Etjo Lodge",
        iata: "MJO",
        countryIata: "NAMJO",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Mpacha",
        city: "Mpacha",
        iata: "MPA",
        countryIata: "NAMPA",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Midgard",
        city: "Midgard",
        iata: "MQG",
        countryIata: "NAMQG",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Rundu",
        city: "Rundu",
        iata: "NDU",
        countryIata: "NANDU",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Namutoni",
        city: "Namutoni",
        iata: "NNI",
        countryIata: "NANNI",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Ongava Game Reserve",
        city: "Ongava Game Reserve",
        iata: "OGV",
        countryIata: "NAOGV",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Oshakati",
        city: "Oshakati",
        iata: "OHI",
        countryIata: "NAOHI",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Okaukuejo",
        city: "Okaukuejo",
        iata: "OKF",
        countryIata: "NAOKF",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Mokuti Lodge",
        city: "Mokuti Lodge",
        iata: "OKU",
        countryIata: "NAOKU",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Oranjemund",
        city: "Oranjemund",
        iata: "OMD",
        countryIata: "NAOMD",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Omega",
        city: "Omega",
        iata: "OMG",
        countryIata: "NAOMG",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Ondangwa",
        city: "Ondangwa",
        iata: "OND",
        countryIata: "NAOND",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Opuwa",
        city: "Opuwa",
        iata: "OPW",
        countryIata: "NAOPW",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Otjiwarongo",
        city: "Otjiwarongo",
        iata: "OTJ",
        countryIata: "NAOTJ",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Swakopmund",
        city: "Swakopmund",
        iata: "SWP",
        countryIata: "NASWP",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Sesriem",
        city: "Sesriem",
        iata: "SZM",
        countryIata: "NASZM",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Terrace Bay",
        city: "Terrace Bay",
        iata: "TCY",
        countryIata: "NATCY",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Tsumeb",
        city: "Tsumeb",
        iata: "TSB",
        countryIata: "NATSB",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Hosea Kutako International Airport",
        city: "Windhoek",
        iata: "WDH",
        countryIata: "NAWDH",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Rooikop",
        city: "Walvis Bay",
        iata: "WVB",
        countryIata: "NAWVB",
        country: "Namibia",
        countryCode: null,
    },
    {
        airportName: "Nauru International Airport",
        city: "Nauru Island",
        iata: "INU",
        countryIata: "NRINU",
        country: "Nauru",
        countryCode: "NR",
    },
    {
        airportName: "Bacolod–Silay International Airport",
        city: "Bacolod City",
        iata: "BCD",
        countryIata: "MHBCD",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Bhadrapur",
        city: "Bhadrapur",
        iata: "BDP",
        countryIata: "NPBDP",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Baglung",
        city: "Baglung",
        iata: "BGL",
        countryIata: "NPBGL",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Bhojpur",
        city: "Bhojpur",
        iata: "BHP",
        countryIata: "NPBHP",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Bharatpur",
        city: "Bharatpur",
        iata: "BHR",
        countryIata: "NPBHR",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Biratnagar",
        city: "Biratnagar",
        iata: "BIR",
        countryIata: "NPBIR",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Baitadi",
        city: "Baitadi",
        iata: "BIT",
        countryIata: "NPBIT",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Bajhang",
        city: "Bajhang",
        iata: "BJH",
        countryIata: "NPBJH",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Bajura Airport",
        city: "Bajura",
        iata: "BJU",
        countryIata: "NPBJU",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Bhairawa",
        city: "Bhairawa",
        iata: "BWA",
        countryIata: "NPBWA",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Darchula",
        city: "Darchula",
        iata: "DAP",
        countryIata: "NPDAP",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Dhangarhi",
        city: "Dhangarhi",
        iata: "DHI",
        countryIata: "NPDHI",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Dang",
        city: "Dang",
        iata: "DNP",
        countryIata: "NPDNP",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Dolpa",
        city: "Dolpa",
        iata: "DOP",
        countryIata: "NPDOP",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Sanfebagar",
        city: "Sanfebagar",
        iata: "FEB",
        countryIata: "NPFEB",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Gorkha",
        city: "Gorkha",
        iata: "GKH",
        countryIata: "NPGKH",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Chaurjhari",
        city: "Chaurjhari",
        iata: "HRJ",
        countryIata: "NPHRJ",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Simikot",
        city: "Simikot",
        iata: "IMK",
        countryIata: "NPIMK",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Jiri",
        city: "Jiri",
        iata: "JIR",
        countryIata: "NPJIR",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Janakpur",
        city: "Janakpur",
        iata: "JKR",
        countryIata: "NPJKR",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Jomsom",
        city: "Jomsom",
        iata: "JMO",
        countryIata: "NPJMO",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Jumla",
        city: "Jumla",
        iata: "JUM",
        countryIata: "NPJUM",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Nepalganj",
        city: "Nepalganj",
        iata: "KEP",
        countryIata: "NPKEP",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Tribhuvan",
        city: "Kathmandu",
        iata: "KTM",
        countryIata: "NPKTM",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Lamidanda",
        city: "Lamidanda",
        iata: "LDN",
        countryIata: "NPLDN",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Langtang",
        city: "Langtang",
        iata: "LTG",
        countryIata: "NPLTG",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Lukla",
        city: "Lukla",
        iata: "LUA",
        countryIata: "NPLUA",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Meghauli",
        city: "Meghauli",
        iata: "MEY",
        countryIata: "NPMEY",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Mountain",
        city: "Mountain",
        iata: "MWP",
        countryIata: "NPMWP",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Manang",
        city: "Manang",
        iata: "NGX",
        countryIata: "NPNGX",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Pokhara",
        city: "Pokhara",
        iata: "PKR",
        countryIata: "NPPKR",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Phaplu",
        city: "Phaplu",
        iata: "PPL",
        countryIata: "NPPPL",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Ramechhap",
        city: "Ramechhap",
        iata: "RHP",
        countryIata: "NPRHP",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Rajbiraj",
        city: "Rajbiraj",
        iata: "RJB",
        countryIata: "NPRJB",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Rolpa",
        city: "Rolpa",
        iata: "RPA",
        countryIata: "NPRPA",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Rukumkot",
        city: "Rukumkot",
        iata: "RUK",
        countryIata: "NPRUK",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Rumjatar",
        city: "Rumjatar",
        iata: "RUM",
        countryIata: "NPRUM",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Simara",
        city: "Simara",
        iata: "SIF",
        countryIata: "NPSIF",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Silgadi Doti",
        city: "Silgadi Doti",
        iata: "SIH",
        countryIata: "NPSIH",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Surkhet",
        city: "Surkhet",
        iata: "SKH",
        countryIata: "NPSKH",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Syangboche",
        city: "Syangboche",
        iata: "SYH",
        countryIata: "NPSYH",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Tumling Tar",
        city: "Tumling Tar",
        iata: "TMI",
        countryIata: "NPTMI",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Taplejung",
        city: "Taplejung",
        iata: "TPJ",
        countryIata: "NPTPJ",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Tikapur",
        city: "Tikapur",
        iata: "TPU",
        countryIata: "NPTPU",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Mahendranagar",
        city: "Mahendranagar",
        iata: "XMG",
        countryIata: "NPXMG",
        country: "Nepal",
        countryCode: "NP",
    },
    {
        airportName: "Amsterdam-Schiphol",
        city: "Amsterdam",
        iata: "AMS",
        countryIata: "MHAMS",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Woensdrecht",
        city: "Bergen Op Zoom",
        iata: "BZM",
        countryIata: "MHBZM",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "De Kooy",
        city: "Den Helder",
        iata: "DHR",
        countryIata: "MHDHR",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Eindhoven",
        city: "Eindhoven",
        iata: "EIN",
        countryIata: "MHEIN",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Twente",
        city: "Enschede",
        iata: "ENS",
        countryIata: "MHENS",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Gilze-Rijen",
        city: "Breda",
        iata: "GLZ",
        countryIata: "MHGLZ",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Eelde",
        city: "Groningen",
        iata: "GRQ",
        countryIata: "MHGRQ",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Metropolitan Area",
        city: "The Hague",
        iata: "HAG",
        countryIata: "MHHAG",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Lelystad",
        city: "Lelystad",
        iata: "LEY",
        countryIata: "MHLEY",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Valkenburg Naval Air Base (closed)",
        city: "Leiden",
        iata: "LID",
        countryIata: "MHLID",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Leeuwarden",
        city: "Leeuwarden",
        iata: "LWR",
        countryIata: "MHLWR",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Maastricht/Aachen",
        city: "Maastricht",
        iata: "MST",
        countryIata: "MHMST",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Metropolitan Area",
        city: "Rotterdam",
        iata: "RTM",
        countryIata: "MHRTM",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Off-line Pt",
        city: "Schiphol",
        iata: "SPL",
        countryIata: "MHSPL",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Volkel AB",
        city: "Uden",
        iata: "UDE",
        countryIata: "MHUDE",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Soesterberg",
        city: "Utrecht",
        iata: "UTC",
        countryIata: "MHUTC",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Woensdrecht AB",
        city: "Woensdrecht AB",
        iata: "WOE",
        countryIata: "MHWOE",
        country: "Marshall Islands (the)",
        countryCode: "MH",
    },
    {
        airportName: "Belep Island",
        city: "Belep Island",
        iata: "BMY",
        countryIata: "NCBMY",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Magenta",
        city: "Noumea",
        iata: "GEA",
        countryIata: "NCGEA",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Houailou",
        city: "Houailou",
        iata: "HLU",
        countryIata: "NCHLU",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Hienghene",
        city: "Hienghene",
        iata: "HNG",
        countryIata: "NCHNG",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Ile Des Pins",
        city: "Ile Des Pins",
        iata: "ILP",
        countryIata: "NCILP",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Ile Ouen",
        city: "Ile Ouen",
        iata: "IOU",
        countryIata: "NCIOU",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Kone",
        city: "Kone",
        iata: "KNQ",
        countryIata: "NCKNQ",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Koumac",
        city: "Koumac",
        iata: "KOC",
        countryIata: "NCKOC",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Lifou",
        city: "Lifou",
        iata: "LIF",
        countryIata: "NCLIF",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Mare",
        city: "Mare",
        iata: "MEE",
        countryIata: "NCMEE",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Tontouta",
        city: "Noumea",
        iata: "NOU",
        countryIata: "NCNOU",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Mueo",
        city: "Mueo",
        iata: "PDC",
        countryIata: "NCPDC",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Poum",
        city: "Poum",
        iata: "PUV",
        countryIata: "NCPUV",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Tiga",
        city: "Tiga",
        iata: "TGJ",
        countryIata: "NCTGJ",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Touho",
        city: "Touho",
        iata: "TOU",
        countryIata: "NCTOU",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Ouvea",
        city: "Ouvea",
        iata: "UVE",
        countryIata: "NCUVE",
        country: "New Caledonia",
        countryCode: "NC",
    },
    {
        airportName: "Metropolitan Area",
        city: "Auckland",
        iata: "AKL",
        countryIata: "NZAKL",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Alexandra",
        city: "Alexandra",
        iata: "ALR",
        countryIata: "NZALR",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Ardmore",
        city: "Ardmore",
        iata: "AMZ",
        countryIata: "NZAMZ",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Ashburton",
        city: "Ashburton",
        iata: "ASG",
        countryIata: "NZASG",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Blenheim",
        city: "Blenheim",
        iata: "BHE",
        countryIata: "NZBHE",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "International",
        city: "Christchurch",
        iata: "CHC",
        countryIata: "NZCHC",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Karewa",
        city: "Chatham Island",
        iata: "CHT",
        countryIata: "NZCHT",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Coromandel",
        city: "Coromandel",
        iata: "CMV",
        countryIata: "NZCMV",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Dargaville",
        city: "Dargaville",
        iata: "DGR",
        countryIata: "NZDGR",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Dunedin",
        city: "Dunedin",
        iata: "DUD",
        countryIata: "NZDUD",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Fox Glacier",
        city: "Fox Glacier",
        iata: "FGL",
        countryIata: "NZFGL",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Port Fitzroy",
        city: "Port Fitzroy",
        iata: "GBS",
        countryIata: "NZGBS",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Great Barrier Island",
        city: "Great Barrier Island",
        iata: "GBZ",
        countryIata: "NZGBZ",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Gisborne",
        city: "Gisborne",
        iata: "GIS",
        countryIata: "NZGIS",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Greymouth",
        city: "Greymouth",
        iata: "GMN",
        countryIata: "NZGMN",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Glentanner",
        city: "Mount Cook",
        iata: "GTN",
        countryIata: "NZGTN",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Hokitika Airport",
        city: "Hokitika",
        iata: "HKK",
        countryIata: "NZHKK",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Hamilton",
        city: "Hamilton",
        iata: "HLZ",
        countryIata: "NZHLZ",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Invercargill",
        city: "Invercargill",
        iata: "IVC",
        countryIata: "NZIVC",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Kaitaia",
        city: "Kaitaia",
        iata: "KAT",
        countryIata: "NZKAT",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Kaikoura",
        city: "Kaikoura",
        iata: "KBZ",
        countryIata: "NZKBZ",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Kerikeri",
        city: "Kerikeri",
        iata: "KKE",
        countryIata: "NZKKE",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Kaikohe",
        city: "Kaikohe",
        iata: "KKO",
        countryIata: "NZKKO",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Takaka",
        city: "Takaka",
        iata: "KTF",
        countryIata: "NZKTF",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Kawau Island",
        city: "Kawau Island",
        iata: "KUI",
        countryIata: "NZKUI",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Mansion House",
        city: "Mansion House",
        iata: "KWU",
        countryIata: "NZKWU",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Milford Sound",
        city: "Milford Sound",
        iata: "MFN",
        countryIata: "NZMFN",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Mechanics Bay",
        city: "Auckland",
        iata: "MHB",
        countryIata: "NZMHB",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Mount Cook Airport",
        city: "Mount Cook",
        iata: "MON",
        countryIata: "NZMON",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Masterton",
        city: "Masterton",
        iata: "MRO",
        countryIata: "NZMRO",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Matamata",
        city: "Matamata",
        iata: "MTA",
        countryIata: "NZMTA",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Motueka",
        city: "Motueka",
        iata: "MZP",
        countryIata: "NZMZP",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Hawkes Bay",
        city: "Napier-Hastings",
        iata: "NPE",
        countryIata: "NZNPE",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "New Plymouth Airport",
        city: "New Plymouth",
        iata: "NPL",
        countryIata: "NZNPL",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Nelson",
        city: "Nelson",
        iata: "NSN",
        countryIata: "NZNSN",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Oamaru",
        city: "Oamaru",
        iata: "OAM",
        countryIata: "NZOAM",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Royal Air Force Base",
        city: "Ohakea",
        iata: "OHA",
        countryIata: "NZOHA",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Koromiko",
        city: "Picton",
        iata: "PCN",
        countryIata: "NZPCN",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Pakatoa Island",
        city: "Pakatoa Island",
        iata: "PKL",
        countryIata: "NZPKL",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Palmerston North",
        city: "Palmerston North",
        iata: "PMR",
        countryIata: "NZPMR",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Paraparaumu",
        city: "Paraparaumu",
        iata: "PPQ",
        countryIata: "NZPPQ",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Raglan",
        city: "Raglan",
        iata: "RAG",
        countryIata: "NZRAG",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Rotorua",
        city: "Rotorua",
        iata: "ROT",
        countryIata: "NZROT",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Stewart Island",
        city: "Stewart Island",
        iata: "SZS",
        countryIata: "NZSZS",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Manapouri",
        city: "Te Anau",
        iata: "TEU",
        countryIata: "NZTEU",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Taharoa",
        city: "Taharoa",
        iata: "THH",
        countryIata: "NZTHH",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Timaru",
        city: "Timaru",
        iata: "TIU",
        countryIata: "NZTIU",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Tokoroa",
        city: "Tokoroa",
        iata: "TKZ",
        countryIata: "NZTKZ",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Thames",
        city: "Thames",
        iata: "TMZ",
        countryIata: "NZTMZ",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Tauranga",
        city: "Tauranga",
        iata: "TRG",
        countryIata: "NZTRG",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Taupo",
        city: "Taupo",
        iata: "TUO",
        countryIata: "NZTUO",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Pukaki/Twizel",
        city: "Mount Cook",
        iata: "TWZ",
        countryIata: "NZTWZ",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Wanganui",
        city: "Wanganui",
        iata: "WAG",
        countryIata: "NZWAG",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Waitangi",
        city: "Waitangi",
        iata: "WGN",
        countryIata: "NZWGN",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Whakatane",
        city: "Whakatane",
        iata: "WHK",
        countryIata: "NZWHK",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Franz Josef",
        city: "Franz Josef",
        iata: "WHO",
        countryIata: "NZWHO",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Surfdale",
        city: "Surfdale",
        iata: "WIK",
        countryIata: "NZWIK",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Wairoa",
        city: "Wairoa",
        iata: "WIR",
        countryIata: "NZWIR",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Wanaka",
        city: "Wanaka",
        iata: "WKA",
        countryIata: "NZWKA",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Wellington International Airport",
        city: "Wellington",
        iata: "WLG",
        countryIata: "NZWLG",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Whangarei",
        city: "Whangarei",
        iata: "WRE",
        countryIata: "NZWRE",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Westport",
        city: "Westport",
        iata: "WSZ",
        countryIata: "NZWSZ",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Whitianga",
        city: "Whitianga",
        iata: "WTZ",
        countryIata: "NZWTZ",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Frankton",
        city: "Queenstown",
        iata: "ZQN",
        countryIata: "NZZQN",
        country: "New Zealand",
        countryCode: "NZ",
    },
    {
        airportName: "Bluefields",
        city: "Bluefields",
        iata: "BEF",
        countryIata: "NIBEF",
        country: "Nicaragua",
        countryCode: "NI",
    },
    {
        airportName: "San Pedro",
        city: "Bonanza",
        iata: "BZA",
        countryIata: "NIBZA",
        country: "Nicaragua",
        countryCode: "NI",
    },
    {
        airportName: "Augusto C Sandino",
        city: "Managua",
        iata: "MGA",
        countryIata: "NIMGA",
        country: "Nicaragua",
        countryCode: "NI",
    },
    {
        airportName: "San Carlos",
        city: "San Carlos",
        iata: "NCR",
        countryIata: "NINCR",
        country: "Nicaragua",
        countryCode: "NI",
    },
    {
        airportName: "Nueva Guinea",
        city: "Nueva Guinea",
        iata: "NVG",
        countryIata: "NINVG",
        country: "Nicaragua",
        countryCode: "NI",
    },
    {
        airportName: "Puerto Cabezas",
        city: "Puerto Cabezas",
        iata: "PUZ",
        countryIata: "NIPUZ",
        country: "Nicaragua",
        countryCode: "NI",
    },
    {
        airportName: "Rosita",
        city: "Rosita",
        iata: "RFS",
        countryIata: "NIRFS",
        country: "Nicaragua",
        countryCode: "NI",
    },
    {
        airportName: "Corn Island",
        city: "Corn Island",
        iata: "RNI",
        countryIata: "NIRNI",
        country: "Nicaragua",
        countryCode: "NI",
    },
    {
        airportName: "Siuna",
        city: "Siuna",
        iata: "SIU",
        countryIata: "NISIU",
        country: "Nicaragua",
        countryCode: "NI",
    },
    {
        airportName: "Waspam",
        city: "Waspam",
        iata: "WSP",
        countryIata: "NIWSP",
        country: "Nicaragua",
        countryCode: "NI",
    },
    {
        airportName: "Mano Dayak International Airport",
        city: "Agades",
        iata: "AJY",
        countryIata: "NEAJY",
        country: "Niger",
        countryCode: "NE",
    },
    {
        airportName: "Birni Nkoni",
        city: "Birni Nkoni",
        iata: "BKN",
        countryIata: "NEBKN",
        country: "Niger",
        countryCode: "NE",
    },
    {
        airportName: "Maradi",
        city: "Maradi",
        iata: "MFQ",
        countryIata: "NEMFQ",
        country: "Niger",
        countryCode: "NE",
    },
    {
        airportName: "Diori Hamani International Airport",
        city: "Niamey",
        iata: "NIM",
        countryIata: "NENIM",
        country: "Niger",
        countryCode: "NE",
    },
    {
        airportName: "Arlit",
        city: "Arlit",
        iata: "RLT",
        countryIata: "NERLT",
        country: "Niger",
        countryCode: "NE",
    },
    {
        airportName: "Tahoua",
        city: "Tahoua",
        iata: "THZ",
        countryIata: "NETHZ",
        country: "Niger",
        countryCode: "NE",
    },
    {
        airportName: "Zinder",
        city: "Zinder",
        iata: "ZND",
        countryIata: "NEZND",
        country: "Niger",
        countryCode: "NE",
    },
    {
        airportName: "Asaba International",
        city: "Asaba",
        iata: "ABB",
        countryIata: "NGABB",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Nnamdi Azikiwe International Airport",
        city: "Abuja",
        iata: "ABV",
        countryIata: "NGABV",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Akure",
        city: "Akure",
        iata: "AKR",
        countryIata: "NGAKR",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Bauchi",
        city: "Bauchi",
        iata: "BCU",
        countryIata: "NGBCU",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Benin City",
        city: "Benin City",
        iata: "BNI",
        countryIata: "NGBNI",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Calabar",
        city: "Calabar",
        iata: "CBQ",
        countryIata: "NGCBQ",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Enugu",
        city: "Enugu",
        iata: "ENU",
        countryIata: "NGENU",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Ibadan",
        city: "Ibadan",
        iata: "IBA",
        countryIata: "NGIBA",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Ilorin",
        city: "Ilorin",
        iata: "ILR",
        countryIata: "NGILR",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Jos",
        city: "Jos",
        iata: "JOS",
        countryIata: "NGJOS",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Kaduna",
        city: "Kaduna",
        iata: "KAD",
        countryIata: "NGKAD",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Aminu Kano International Apt",
        city: "Kano",
        iata: "KAN",
        countryIata: "NGKAN",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Murtala Muhammed",
        city: "Lagos",
        iata: "LOS",
        countryIata: "NGLOS",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Makurdi",
        city: "Makurdi",
        iata: "MDI",
        countryIata: "NGMDI",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Maiduguri",
        city: "Maiduguri",
        iata: "MIU",
        countryIata: "NGMIU",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Minna",
        city: "Minna",
        iata: "MXJ",
        countryIata: "NGMXJ",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Port Harcourt International Airport",
        city: "Port Harcourt",
        iata: "PHC",
        countryIata: "NGPHC",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Sam Mbakwe Airport",
        city: "Owerri",
        iata: "QOW",
        countryIata: "NGQOW",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Warri Airport",
        city: "Warri",
        iata: "QRW",
        countryIata: "NGQRW",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Sokoto",
        city: "Sokoto",
        iata: "SKO",
        countryIata: "NGSKO",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Yola",
        city: "Yola",
        iata: "YOL",
        countryIata: "NGYOL",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Zaria",
        city: "Zaria",
        iata: "ZAR",
        countryIata: "NGZAR",
        country: "Nigeria",
        countryCode: "NG",
    },
    {
        airportName: "Hanan",
        city: "Niue Island",
        iata: "IUE",
        countryIata: "NUIUE",
        country: "Niue",
        countryCode: "NU",
    },
    {
        airportName: "Norfolk Island Airport",
        city: "Norfolk Island",
        iata: "NLK",
        countryIata: "NFNLK",
        country: "Norfolk Island",
        countryCode: "NF",
    },
    {
        airportName: "Vigra",
        city: "Aalesund",
        iata: "AES",
        countryIata: "NOAES",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Alta",
        city: "Alta",
        iata: "ALF",
        countryIata: "NOALF",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Andenes",
        city: "Andenes",
        iata: "ANX",
        countryIata: "NOANX",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Bardufoss",
        city: "Bardufoss",
        iata: "BDU",
        countryIata: "NOBDU",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Metropolitan Area",
        city: "Bergen",
        iata: "BGO",
        countryIata: "NOBGO",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Båtsfjord Airport",
        city: "Batsfjord",
        iata: "BJF",
        countryIata: "NOBJF",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Bronnoy",
        city: "Bronnoysund",
        iata: "BNN",
        countryIata: "NOBNN",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Bodo",
        city: "Bodo",
        iata: "BOO",
        countryIata: "NOBOO",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Berlevag",
        city: "Berlevag",
        iata: "BVG",
        countryIata: "NOBVG",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Dagali Airport",
        city: "Geilo",
        iata: "DLD",
        countryIata: "NODLD",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Evenes",
        city: "Harstad-Narvik",
        iata: "EVE",
        countryIata: "NOEVE",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Lista",
        city: "Farsund",
        iata: "FAN",
        countryIata: "NOFAN",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Bringeland",
        city: "Forde",
        iata: "FDE",
        countryIata: "NOFDE",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Flora",
        city: "Floro",
        iata: "FRO",
        countryIata: "NOFRO",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Klanten Airport",
        city: "Gol",
        iata: "GLL",
        countryIata: "NOGLL",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Hasvik Airport",
        city: "Hasvik",
        iata: "HAA",
        countryIata: "NOHAA",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Haugesund",
        city: "Haugesund",
        iata: "HAU",
        countryIata: "NOHAU",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Hammerfest",
        city: "Hammerfest",
        iata: "HFT",
        countryIata: "NOHFT",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Hamar Airport",
        city: "Hamar",
        iata: "HMR",
        countryIata: "NOHMR",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Hovden",
        city: "Orsta-Volda",
        iata: "HOV",
        countryIata: "NOHOV",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Valan",
        city: "Honningsvag",
        iata: "HVG",
        countryIata: "NOHVG",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Hoeybuktmoen",
        city: "Kirkenes",
        iata: "KKN",
        countryIata: "NOKKN",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Kjevik",
        city: "Kristiansand",
        iata: "KRS",
        countryIata: "NOKRS",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Kvernberget",
        city: "Kristiansund",
        iata: "KSU",
        countryIata: "NOKSU",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Banak",
        city: "Lakselv",
        iata: "LKL",
        countryIata: "NOLKL",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Leknes",
        city: "Leknes",
        iata: "LKN",
        countryIata: "NOLKN",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Mehamn",
        city: "Mehamn",
        iata: "MEH",
        countryIata: "NOMEH",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Kjaerstad",
        city: "Mosjoen",
        iata: "MJF",
        countryIata: "NOMJF",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Aro",
        city: "Molde",
        iata: "MOL",
        countryIata: "NOMOL",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Mo I Rana",
        city: "Mo I Rana",
        iata: "MQN",
        countryIata: "NOMQN",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Notodden",
        city: "Notodden",
        iata: "NTB",
        countryIata: "NONTB",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Framnes",
        city: "Narvik",
        iata: "NVK",
        countryIata: "NONVK",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Orland",
        city: "Orland",
        iata: "OLA",
        countryIata: "NOOLA",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Oslo Airport, Gardermoen",
        city: "Oslo",
        iata: "OSL",
        countryIata: "NOOSL",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Namsos",
        city: "Namsos",
        iata: "OSY",
        countryIata: "NOOSY",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Stolport",
        city: "Rost",
        iata: "RET",
        countryIata: "NORET",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Roros",
        city: "Roros",
        iata: "RRS",
        countryIata: "NORRS",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Ryumsjoen Airport",
        city: "Roervik",
        iata: "RVK",
        countryIata: "NORVK",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Rygge Civilian Airport",
        city: "Moss",
        iata: "RYG",
        countryIata: "NORYG",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Sandane",
        city: "Sandane",
        iata: "SDN",
        countryIata: "NOSDN",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Skien",
        city: "Skien",
        iata: "SKE",
        countryIata: "NOSKE",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Skagen",
        city: "Stokmarknes",
        iata: "SKN",
        countryIata: "NOSKN",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Haukasen",
        city: "Sogndal",
        iata: "SOG",
        countryIata: "NOSOG",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Sorkjosen",
        city: "Sorkjosen",
        iata: "SOJ",
        countryIata: "NOSOJ",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Stord Airport",
        city: "Stord",
        iata: "SRP",
        countryIata: "NOSRP",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Stokka",
        city: "Sandnessjoen",
        iata: "SSJ",
        countryIata: "NOSSJ",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Sola",
        city: "Stavanger",
        iata: "SVG",
        countryIata: "NOSVG",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Helle",
        city: "Svolvaer",
        iata: "SVJ",
        countryIata: "NOSVJ",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Spitsberg",
        city: "Svalbard",
        iata: "SYG",
        countryIata: "NOSYG",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Tromso/Langnes",
        city: "Tromso",
        iata: "TOS",
        countryIata: "NOTOS",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Vaernes",
        city: "Trondheim",
        iata: "TRD",
        countryIata: "NOTRD",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Sandefjord",
        city: "Oslo",
        iata: "TRF",
        countryIata: "NOTRF",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Vardoe",
        city: "Vardoe",
        iata: "VAW",
        countryIata: "NOVAW",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Valdres",
        city: "Fagernes",
        iata: "VDB",
        countryIata: "NOVDB",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Vadso",
        city: "Vadso",
        iata: "VDS",
        countryIata: "NOVDS",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Stolport",
        city: "Vaeroy",
        iata: "VRY",
        countryIata: "NOVRY",
        country: "Norway",
        countryCode: "NO",
    },
    {
        airportName: "Dibaa",
        city: "Dibaa",
        iata: "BYB",
        countryIata: "OMBYB",
        country: "Oman",
        countryCode: "OM",
    },
    {
        airportName: "Khasab",
        city: "Khasab",
        iata: "KHS",
        countryIata: "OMKHS",
        country: "Oman",
        countryCode: "OM",
    },
    {
        airportName: "Muscat International Airport (Seeb)",
        city: "Muscat",
        iata: "MCT",
        countryIata: "OMMCT",
        country: "Oman",
        countryCode: "OM",
    },
    {
        airportName: "Masirah",
        city: "Masirah",
        iata: "MSH",
        countryIata: "OMMSH",
        country: "Oman",
        countryCode: "OM",
    },
    {
        airportName: "Marmul",
        city: "Marmul",
        iata: "OMM",
        countryIata: "OMOMM",
        country: "Oman",
        countryCode: "OM",
    },
    {
        airportName: "Buraimi",
        city: "Buraimi",
        iata: "RMB",
        countryIata: "OMRMB",
        country: "Oman",
        countryCode: "OM",
    },
    {
        airportName: "Salalah",
        city: "Salalah",
        iata: "SLL",
        countryIata: "OMSLL",
        country: "Oman",
        countryCode: "OM",
    },
    {
        airportName: "Sur",
        city: "Sur",
        iata: "SUH",
        countryIata: "OMSUH",
        country: "Oman",
        countryCode: "OM",
    },
    {
        airportName: "Thumrait",
        city: "Thumrait",
        iata: "TTH",
        countryIata: "OMTTH",
        country: "Oman",
        countryCode: "OM",
    },
    {
        airportName: "Airai",
        city: "Koror",
        iata: "ROR",
        countryIata: "PWROR",
        country: "Palau",
        countryCode: "PW",
    },
    {
        airportName: "Achutupo",
        city: "Achutupo",
        iata: "ACU",
        countryIata: "PAACU",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Ailigandi",
        city: "Ailigandi",
        iata: "AIL",
        countryIata: "PAAIL",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Puerto Armuellas",
        city: "Puerto Armuellas",
        iata: "AML",
        countryIata: "PAAML",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Bahia Pinas",
        city: "Bahia Pinas",
        iata: "BFQ",
        countryIata: "PABFQ",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Balboa",
        city: "Balboa",
        iata: "BLB",
        countryIata: "PABLB",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Bocas Del Toro",
        city: "Bocas Del Toro",
        iata: "BOC",
        countryIata: "PABOC",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Caledonia",
        city: "Caledonia",
        iata: "CDE",
        countryIata: "PACDE",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Changuinola",
        city: "Changuinola",
        iata: "CHX",
        countryIata: "PACHX",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Chitre",
        city: "Chitre",
        iata: "CTD",
        countryIata: "PACTD",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Carti",
        city: "Carti",
        iata: "CTE",
        countryIata: "PACTE",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Corazon De Jesus",
        city: "Corazon De Jesus",
        iata: "CZJ",
        countryIata: "PACZJ",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Enrique Malek",
        city: "David",
        iata: "DAV",
        countryIata: "PADAV",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "El Real",
        city: "El Real",
        iata: "ELE",
        countryIata: "PAELE",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Garachine",
        city: "Garachine",
        iata: "GHE",
        countryIata: "PAGHE",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Howard AFB",
        city: "Fort Kobbe",
        iata: "HOW",
        countryIata: "PAHOW",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Jaque",
        city: "Jaque",
        iata: "JQE",
        countryIata: "PAJQE",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Mamitupo",
        city: "Mamitupo",
        iata: "MPI",
        countryIata: "PAMPI",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Mulatupo",
        city: "Mulatupo",
        iata: "MPP",
        countryIata: "PAMPP",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "San Blas",
        city: "San Blas",
        iata: "NBL",
        countryIata: "PANBL",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Nargana",
        city: "Nargana",
        iata: "NGN",
        countryIata: "PANGN",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "San Miguel",
        city: "San Miguel",
        iata: "NMG",
        countryIata: "PANMG",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Colon",
        city: "Colon",
        iata: "ONX",
        countryIata: "PAONX",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Contadora",
        city: "Contadora",
        iata: "OTD",
        countryIata: "PAOTD",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Paitilla",
        city: "Panama City",
        iata: "PAC",
        countryIata: "PAPAC",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "La Palma",
        city: "La Palma",
        iata: "PLP",
        countryIata: "PAPLP",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Tocumen Intl Airport",
        city: "Panama City",
        iata: "PTY",
        countryIata: "PAPTY",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Puerto Obaldia",
        city: "Puerto Obaldia",
        iata: "PUE",
        countryIata: "PAPUE",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "El Porvenir",
        city: "El Porvenir",
        iata: "PVE",
        countryIata: "PAPVE",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Playon Chico",
        city: "Playon Chico",
        iata: "PYC",
        countryIata: "PAPYC",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Yaviza",
        city: "Yaviza",
        iata: "PYV",
        countryIata: "PAPYV",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Rio Tigre",
        city: "Rio Tigre",
        iata: "RIT",
        countryIata: "PARIT",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Rio Alzucar",
        city: "Rio Alzucar",
        iata: "RIZ",
        countryIata: "PARIZ",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Rio Sidra",
        city: "Rio Sidra",
        iata: "RSI",
        countryIata: "PARSI",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Sambu Airport",
        city: "Sambu",
        iata: "SAX",
        countryIata: "PASAX",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Santa Fe",
        city: "Santa Fe",
        iata: "SFW",
        countryIata: "PASFW",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Santiago",
        city: "Santiago",
        iata: "SYP",
        countryIata: "PASYP",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Ticantiki",
        city: "Ticantiki",
        iata: "TJC",
        countryIata: "PATJC",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Tupile",
        city: "Tupile",
        iata: "TUE",
        countryIata: "PATUE",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Tubala",
        city: "Tubala",
        iata: "TUW",
        countryIata: "PATUW",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Ustupo",
        city: "Ustupo",
        iata: "UTU",
        countryIata: "PAUTU",
        country: "Panama",
        countryCode: "PA",
    },
    {
        airportName: "Atkamba",
        city: "Atkamba",
        iata: "ABP",
        countryIata: "PGABP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Abau",
        city: "Abau",
        iata: "ABW",
        countryIata: "PGABW",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Andakombe",
        city: "Andakombe",
        iata: "ADC",
        countryIata: "PGADC",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Aseki",
        city: "Aseki",
        iata: "AEK",
        countryIata: "PGAEK",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Afore",
        city: "Afore",
        iata: "AFR",
        countryIata: "PGAFR",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Angoram",
        city: "Angoram",
        iata: "AGG",
        countryIata: "PGAGG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kagua",
        city: "Kagua",
        iata: "AGK",
        countryIata: "PGAGK",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wanigela",
        city: "Wanigela",
        iata: "AGL",
        countryIata: "PGAGL",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Aiome",
        city: "Aiome",
        iata: "AIE",
        countryIata: "PGAIE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Aiambak",
        city: "Aiambak",
        iata: "AIH",
        countryIata: "PGAIH",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Anguganak",
        city: "Anguganak",
        iata: "AKG",
        countryIata: "PGAKG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Ama",
        city: "Ama",
        iata: "AMF",
        countryIata: "PGAMF",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Amboin",
        city: "Amboin",
        iata: "AMG",
        countryIata: "PGAMG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Amanab",
        city: "Amanab",
        iata: "AMU",
        countryIata: "PGAMU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Aroa",
        city: "Aroa",
        iata: "AOA",
        countryIata: "PGAOA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Annanberg",
        city: "Annanberg",
        iata: "AOB",
        countryIata: "PGAOB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Arona",
        city: "Arona",
        iata: "AON",
        countryIata: "PGAON",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Asapa",
        city: "Asapa",
        iata: "APP",
        countryIata: "PGAPP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "April River",
        city: "April River",
        iata: "APR",
        countryIata: "PGAPR",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Aragip",
        city: "Aragip",
        iata: "ARP",
        countryIata: "PGARP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Asirim",
        city: "Asirim",
        iata: "ASZ",
        countryIata: "PGASZ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Namatanai",
        city: "Namatanai",
        iata: "ATN",
        countryIata: "PGATN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Aitape Airstrip",
        city: "Aitape",
        iata: "ATP",
        countryIata: "PGATP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Aua Island",
        city: "Aua Island",
        iata: "AUI",
        countryIata: "PGAUI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Ambunti",
        city: "Ambunti",
        iata: "AUJ",
        countryIata: "PGAUJ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Agaun",
        city: "Agaun",
        iata: "AUP",
        countryIata: "PGAUP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Aumo",
        city: "Aumo",
        iata: "AUV",
        countryIata: "PGAUV",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Awaba",
        city: "Awaba",
        iata: "AWB",
        countryIata: "PGAWB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Awar",
        city: "Awar",
        iata: "AWR",
        countryIata: "PGAWR",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Aiyura",
        city: "Aiyura",
        iata: "AYU",
        countryIata: "PGAYU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Amazon Bay",
        city: "Amazon Bay",
        iata: "AZB",
        countryIata: "PGAZB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bialla",
        city: "Bialla",
        iata: "BAA",
        countryIata: "PGBAA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bali",
        city: "Bali",
        iata: "BAJ",
        countryIata: "PGBAJ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Baibara",
        city: "Baibara",
        iata: "BAP",
        countryIata: "PGBAP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bambu",
        city: "Bambu",
        iata: "BCP",
        countryIata: "PGBCP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Baindoung",
        city: "Baindoung",
        iata: "BDZ",
        countryIata: "PGBDZ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bereina",
        city: "Bereina",
        iata: "BEA",
        countryIata: "PGBEA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Biliau",
        city: "Biliau",
        iata: "BIJ",
        countryIata: "PGBIJ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bimin",
        city: "Bimin",
        iata: "BIZ",
        countryIata: "PGBIZ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bomai",
        city: "Bomai",
        iata: "BMH",
        countryIata: "PGBMH",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bamu",
        city: "Bamu",
        iata: "BMZ",
        countryIata: "PGBMZ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bodinumu",
        city: "Bodinumu",
        iata: "BNM",
        countryIata: "PGBNM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bundi",
        city: "Bundi",
        iata: "BNT",
        countryIata: "PGBNT",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Boana",
        city: "Boana",
        iata: "BNV",
        countryIata: "PGBNV",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Banz",
        city: "Banz",
        iata: "BNZ",
        countryIata: "PGBNZ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Boku",
        city: "Boku",
        iata: "BOQ",
        countryIata: "PGBOQ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Boset",
        city: "Boset",
        iata: "BOT",
        countryIata: "PGBOT",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Boang",
        city: "Boang",
        iata: "BOV",
        countryIata: "PGBOV",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Boridi",
        city: "Boridi",
        iata: "BPB",
        countryIata: "PGBPB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bapi",
        city: "Bapi",
        iata: "BPD",
        countryIata: "PGBPD",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Biangabip",
        city: "Biangabip",
        iata: "BPK",
        countryIata: "PGBPK",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Brahman",
        city: "Brahman",
        iata: "BRH",
        countryIata: "PGBRH",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Biaru",
        city: "Biaru",
        iata: "BRP",
        countryIata: "PGBRP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bensbach",
        city: "Bensbach",
        iata: "BSP",
        countryIata: "PGBSP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Buka",
        city: "Buka",
        iata: "BUA",
        countryIata: "PGBUA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bulolo",
        city: "Bulolo",
        iata: "BUL",
        countryIata: "PGBUL",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bolovip",
        city: "Bolovip",
        iata: "BVP",
        countryIata: "PGBVP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bawan",
        city: "Bawan",
        iata: "BWJ",
        countryIata: "PGBWJ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bewani",
        city: "Bewani",
        iata: "BWP",
        countryIata: "PGBWP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Bunsil",
        city: "Bunsil",
        iata: "BXZ",
        countryIata: "PGBXZ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Cape Gloucester",
        city: "Cape Gloucester",
        iata: "CGC",
        countryIata: "PGCGC",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Chimbu",
        city: "Kundiawa",
        iata: "CMU",
        countryIata: "PGCMU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Cape Orford",
        city: "Cape Orford",
        iata: "CPI",
        countryIata: "PGCPI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Cape Rodney",
        city: "Cape Rodney",
        iata: "CPN",
        countryIata: "PGCPN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Chungribu",
        city: "Chungribu",
        iata: "CVB",
        countryIata: "PGCVB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Cape Vogel",
        city: "Cape Vogel",
        iata: "CVL",
        countryIata: "PGCVL",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Daup",
        city: "Daup",
        iata: "DAF",
        countryIata: "PGDAF",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Dabo",
        city: "Dabo",
        iata: "DAO",
        countryIata: "PGDAO",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Daru",
        city: "Daru",
        iata: "DAU",
        countryIata: "PGDAU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Debepare",
        city: "Debepare",
        iata: "DBP",
        countryIata: "PGDBP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Dodoima",
        city: "Dodoima",
        iata: "DDM",
        countryIata: "PGDDM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Derim",
        city: "Derim",
        iata: "DER",
        countryIata: "PGDER",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Daugo",
        city: "Daugo",
        iata: "DGG",
        countryIata: "PGDGG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Dalbertis",
        city: "Dalbertis",
        iata: "DLB",
        countryIata: "PGDLB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Dinangat",
        city: "Dinangat",
        iata: "DNU",
        countryIata: "PGDNU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Doini",
        city: "Doini",
        iata: "DOI",
        countryIata: "PGDOI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Dorobisoro",
        city: "Dorobisoro",
        iata: "DOO",
        countryIata: "PGDOO",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Dios",
        city: "Dios",
        iata: "DOS",
        countryIata: "PGDOS",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Dumpu",
        city: "Dumpu",
        iata: "DPU",
        countryIata: "PGDPU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Efogi",
        city: "Efogi",
        iata: "EFG",
        countryIata: "PGEFG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Engati",
        city: "Engati",
        iata: "EGA",
        countryIata: "PGEGA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Popondetta",
        city: "Eia",
        iata: "EIA",
        countryIata: "PGEIA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Emirau",
        city: "Emirau",
        iata: "EMI",
        countryIata: "PGEMI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Emo",
        city: "Emo",
        iata: "EMO",
        countryIata: "PGEMO",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Embessa",
        city: "Embessa",
        iata: "EMS",
        countryIata: "PGEMS",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Eliptamin",
        city: "Eliptamin",
        iata: "EPT",
        countryIata: "PGEPT",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Erave",
        city: "Erave",
        iata: "ERE",
        countryIata: "PGERE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Erume",
        city: "Erume",
        iata: "ERU",
        countryIata: "PGERU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Esa'Ala",
        city: "Esa'Ala",
        iata: "ESA",
        countryIata: "PGESA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Freida River",
        city: "Freida River",
        iata: "FAQ",
        countryIata: "PGFAQ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Finschhafen",
        city: "Finschhafen",
        iata: "FIN",
        countryIata: "PGFIN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Fane",
        city: "Fane",
        iata: "FNE",
        countryIata: "PGFNE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Feramin",
        city: "Feramin",
        iata: "FRQ",
        countryIata: "PGFRQ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Fulleborn",
        city: "Fulleborn",
        iata: "FUB",
        countryIata: "PGFUB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Fuma",
        city: "Fuma",
        iata: "FUM",
        countryIata: "PGFUM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Gusap",
        city: "Gusap",
        iata: "GAP",
        countryIata: "PGGAP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Garaina",
        city: "Garaina",
        iata: "GAR",
        countryIata: "PGGAR",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Guasopa",
        city: "Guasopa",
        iata: "GAZ",
        countryIata: "PGGAZ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Gasuke",
        city: "Gasuke",
        iata: "GBC",
        countryIata: "PGGBC",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Negarbo",
        city: "Negarbo",
        iata: "GBF",
        countryIata: "PGGBF",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Green Islands",
        city: "Green Islands",
        iata: "GEI",
        countryIata: "PGGEI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Gewoia",
        city: "Gewoia",
        iata: "GEW",
        countryIata: "PGGEW",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Goroka",
        city: "Goroka",
        iata: "GKA",
        countryIata: "PGGKA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Gulgubip",
        city: "Gulgubip",
        iata: "GLP",
        countryIata: "PGGLP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Gasmata Island",
        city: "Gasmata Island",
        iata: "GMI",
        countryIata: "PGGMI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Gora",
        city: "Gora",
        iata: "GOC",
        countryIata: "PGGOC",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Gonalia",
        city: "Gonalia",
        iata: "GOE",
        countryIata: "PGGOE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Garuahi",
        city: "Garuahi",
        iata: "GRH",
        countryIata: "PGGRH",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Garasa",
        city: "Garasa",
        iata: "GRL",
        countryIata: "PGGRL",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Guriaso",
        city: "Guriaso",
        iata: "GUE",
        countryIata: "PGGUE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Guari",
        city: "Guari",
        iata: "GUG",
        countryIata: "PGGUG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Gurney",
        city: "Alotau",
        iata: "GUR",
        countryIata: "PGGUR",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Mougulu",
        city: "Mougulu",
        iata: "GUV",
        countryIata: "PGGUV",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Green River",
        city: "Green River",
        iata: "GVI",
        countryIata: "PGGVI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Gnarowein",
        city: "Gnarowein",
        iata: "GWN",
        countryIata: "PGGWN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Hatzfeldthaven",
        city: "Hatzfeldthaven",
        iata: "HAZ",
        countryIata: "PGHAZ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Habi",
        city: "Habi",
        iata: "HBD",
        countryIata: "PGHBD",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Haelogo",
        city: "Haelogo",
        iata: "HEO",
        countryIata: "PGHEO",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kagamuga",
        city: "Mount Hagen",
        iata: "HGU",
        countryIata: "PGHGU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Hivaro",
        city: "Hivaro",
        iata: "HIT",
        countryIata: "PGHIT",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Hoskins",
        city: "Hoskins",
        iata: "HKN",
        countryIata: "PGHKN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Heiweni",
        city: "Heiweni",
        iata: "HNI",
        countryIata: "PGHNI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Honinabi",
        city: "Honinabi",
        iata: "HNN",
        countryIata: "PGHNN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Komako",
        city: "Komako",
        iata: "HOC",
        countryIata: "PGHOC",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Hawabango",
        city: "Hawabango",
        iata: "HWA",
        countryIata: "PGHWA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Hayfields",
        city: "Hayfields",
        iata: "HYF",
        countryIata: "PGHYF",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Iaura",
        city: "Iaura",
        iata: "IAU",
        countryIata: "PGIAU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Iboki",
        city: "Iboki",
        iata: "IBI",
        countryIata: "PGIBI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Indagen",
        city: "Indagen",
        iata: "IDN",
        countryIata: "PGIDN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Ihu",
        city: "Ihu",
        iata: "IHU",
        countryIata: "PGIHU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Nissan Island",
        city: "Nissan Island",
        iata: "IIS",
        countryIata: "PGIIS",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Ileg",
        city: "Ileg",
        iata: "ILX",
        countryIata: "PGILX",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Iamalele",
        city: "Iamalele",
        iata: "IMA",
        countryIata: "PGIMA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Imonda",
        city: "Imonda",
        iata: "IMD",
        countryIata: "PGIMD",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Imane",
        city: "Imane",
        iata: "IMN",
        countryIata: "PGIMN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Iokea",
        city: "Iokea",
        iata: "IOK",
        countryIata: "PGIOK",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Ioma",
        city: "Ioma",
        iata: "IOP",
        countryIata: "PGIOP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Itokama",
        city: "Itokama",
        iata: "ITK",
        countryIata: "PGITK",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Inus",
        city: "Inus",
        iata: "IUS",
        countryIata: "PGIUS",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Jacquinot Bay",
        city: "Jacquinot Bay",
        iata: "JAQ",
        countryIata: "PGJAQ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Josephstaal",
        city: "Josephstaal",
        iata: "JOP",
        countryIata: "PGJOP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Karato",
        city: "Karato",
        iata: "KAF",
        countryIata: "PGKAF",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kar",
        city: "Kar",
        iata: "KAK",
        countryIata: "PGKAK",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kamulai",
        city: "Kamulai",
        iata: "KAQ",
        countryIata: "PGKAQ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kabwum",
        city: "Kabwum",
        iata: "KBM",
        countryIata: "PGKBM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Komaio",
        city: "Komaio",
        iata: "KCJ",
        countryIata: "PGKCJ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Koroba",
        city: "Koroba",
        iata: "KDE",
        countryIata: "PGKDE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kandep",
        city: "Kandep",
        iata: "KDP",
        countryIata: "PGKDP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kamberatoro",
        city: "Kamberatoro",
        iata: "KDQ",
        countryIata: "PGKDQ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kandrian",
        city: "Kandrian",
        iata: "KDR",
        countryIata: "PGKDR",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Keglsugl",
        city: "Keglsugl",
        iata: "KEG",
        countryIata: "PGKEG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kanabea",
        city: "Kanabea",
        iata: "KEX",
        countryIata: "PGKEX",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Konge",
        city: "Konge",
        iata: "KGB",
        countryIata: "PGKGB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Yongai",
        city: "Yongai",
        iata: "KGH",
        countryIata: "PGKGH",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kungum",
        city: "Kungum",
        iata: "KGM",
        countryIata: "PGKGM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kagi",
        city: "Kagi",
        iata: "KGW",
        countryIata: "PGKGW",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kaiapit",
        city: "Kaiapit",
        iata: "KIA",
        countryIata: "PGKIA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Aropa",
        city: "Kieta",
        iata: "KIE",
        countryIata: "PGKIE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kibuli",
        city: "Kibuli",
        iata: "KII",
        countryIata: "PGKII",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kira",
        city: "Kira",
        iata: "KIQ",
        countryIata: "PGKIQ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kikinonda",
        city: "Kikinonda",
        iata: "KIZ",
        countryIata: "PGKIZ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kamiraba",
        city: "Kamiraba",
        iata: "KJU",
        countryIata: "PGKJU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kokoda",
        city: "Kokoda",
        iata: "KKD",
        countryIata: "PGKKD",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kerema",
        city: "Kerema",
        iata: "KMA",
        countryIata: "PGKMA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Koinambe",
        city: "Koinambe",
        iata: "KMB",
        countryIata: "PGKMB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kamina",
        city: "Kamina",
        iata: "KMF",
        countryIata: "PGKMF",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Karimui",
        city: "Karimui",
        iata: "KMR",
        countryIata: "PGKMR",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kanainj",
        city: "Kanainj",
        iata: "KNE",
        countryIata: "PGKNE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kelanoa",
        city: "Kelanoa",
        iata: "KNL",
        countryIata: "PGKNL",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Komo-Manda",
        city: "Komo-Manda",
        iata: "KOM",
        countryIata: "PGKOM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kokoro",
        city: "Kokoro",
        iata: "KOR",
        countryIata: "PGKOR",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kopiago",
        city: "Kopiago",
        iata: "KPA",
        countryIata: "PGKPA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Yapsiei",
        city: "Yapsiei",
        iata: "KPE",
        countryIata: "PGKPE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kondubol",
        city: "Kondubol",
        iata: "KPF",
        countryIata: "PGKPF",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kapal",
        city: "Kapal",
        iata: "KPL",
        countryIata: "PGKPL",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kompiam",
        city: "Kompiam",
        iata: "KPM",
        countryIata: "PGKPM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kol",
        city: "Kol",
        iata: "KQL",
        countryIata: "PGKQL",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kikori",
        city: "Kikori",
        iata: "KRI",
        countryIata: "PGKRI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Karawari",
        city: "Karawari",
        iata: "KRJ",
        countryIata: "PGKRJ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kerau",
        city: "Kerau",
        iata: "KRU",
        countryIata: "PGKRU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kar Kar",
        city: "Kar Kar",
        iata: "KRX",
        countryIata: "PGKRX",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kasanombe",
        city: "Kasanombe",
        iata: "KSB",
        countryIata: "PGKSB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kisengan",
        city: "Kisengan",
        iata: "KSG",
        countryIata: "PGKSG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kosipe",
        city: "Kosipe",
        iata: "KSP",
        countryIata: "PGKSP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Yasuru",
        city: "Yasuru",
        iata: "KSX",
        countryIata: "PGKSX",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kanua",
        city: "Kanua",
        iata: "KTK",
        countryIata: "PGKTK",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kupiano",
        city: "Kupiano",
        iata: "KUP",
        countryIata: "PGKUP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kuri",
        city: "Kuri",
        iata: "KUQ",
        countryIata: "PGKUQ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kuyol",
        city: "Kuyol",
        iata: "KUX",
        countryIata: "PGKUX",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kamusi Airport",
        city: "Kamusi",
        iata: "KUY",
        countryIata: "PGKUY",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kitava",
        city: "Kitava",
        iata: "KVE",
        countryIata: "PGKVE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kavieng",
        city: "Kavieng",
        iata: "KVG",
        countryIata: "PGKVG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kawito",
        city: "Kawito",
        iata: "KWO",
        countryIata: "PGKWO",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kurwina",
        city: "Kurwina",
        iata: "KWV",
        countryIata: "PGKWV",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kiwai Island",
        city: "Kiwai Island",
        iata: "KWX",
        countryIata: "PGKWX",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Karoola",
        city: "Karoola",
        iata: "KXR",
        countryIata: "PGKXR",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Yalumet",
        city: "Yalumet",
        iata: "KYX",
        countryIata: "PGKYX",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kaintiba",
        city: "Kaintiba",
        iata: "KZF",
        countryIata: "PGKZF",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Lablab",
        city: "Lablab",
        iata: "LAB",
        countryIata: "PGLAB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Lae Nadzab Airport",
        city: "Lae",
        iata: "LAE",
        countryIata: "PGLAE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Laiagam",
        city: "Laiagam",
        iata: "LGM",
        countryIata: "PGLGM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Linga Linga",
        city: "Linga Linga",
        iata: "LGN",
        countryIata: "PGLGN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Lehu",
        city: "Lehu",
        iata: "LHP",
        countryIata: "PGLHP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Lamassa",
        city: "Lamassa",
        iata: "LMG",
        countryIata: "PGLMG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Lumi",
        city: "Lumi",
        iata: "LMI",
        countryIata: "PGLMI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Lake Murray",
        city: "Lake Murray",
        iata: "LMY",
        countryIata: "PGLMY",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Lengbati",
        city: "Lengbati",
        iata: "LNC",
        countryIata: "PGLNC",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Munbil",
        city: "Munbil",
        iata: "LNF",
        countryIata: "PGLNF",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Lese",
        city: "Lese",
        iata: "LNG",
        countryIata: "PGLNG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Langimar",
        city: "Langimar",
        iata: "LNM",
        countryIata: "PGLNM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Loani",
        city: "Loani",
        iata: "LNQ",
        countryIata: "PGLNQ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Lihir Island",
        city: "Lihir Island",
        iata: "LNV",
        countryIata: "PGLNV",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Leron Plains",
        city: "Leron Plains",
        iata: "LPN",
        countryIata: "PGLPN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Losuia",
        city: "Losuia",
        iata: "LSA",
        countryIata: "PGLSA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Long Island",
        city: "Long Island",
        iata: "LSJ",
        countryIata: "PGLSJ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Leitre",
        city: "Leitre",
        iata: "LTF",
        countryIata: "PGLTF",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Lowai",
        city: "Lowai",
        iata: "LWI",
        countryIata: "PGLWI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Madang",
        city: "Madang",
        iata: "MAG",
        countryIata: "PGMAG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Mamai",
        city: "Mamai",
        iata: "MAP",
        countryIata: "PGMAP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Momote",
        city: "Manus Island",
        iata: "MAS",
        countryIata: "PGMAS",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Masa",
        city: "Masa",
        iata: "MBV",
        countryIata: "PGMBV",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Munduku",
        city: "Munduku",
        iata: "MDM",
        countryIata: "PGMDM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Mendi",
        city: "Mendi",
        iata: "MDU",
        countryIata: "PGMDU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Manguna",
        city: "Manguna",
        iata: "MFO",
        countryIata: "PGMFO",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Mesalia",
        city: "Mesalia",
        iata: "MFZ",
        countryIata: "PGMFZ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Margarima",
        city: "Margarima",
        iata: "MGG",
        countryIata: "PGMGG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Manga",
        city: "Manga",
        iata: "MGP",
        countryIata: "PGMGP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Morehead",
        city: "Morehead",
        iata: "MHY",
        countryIata: "PGMHY",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Misima Island",
        city: "Misima Island",
        iata: "MIS",
        countryIata: "PGMIS",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Moki",
        city: "Moki",
        iata: "MJJ",
        countryIata: "PGMJJ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Malekolon",
        city: "Malekolon",
        iata: "MKN",
        countryIata: "PGMKN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Malalaua",
        city: "Malalaua",
        iata: "MLQ",
        countryIata: "PGMLQ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Mal",
        city: "Mal",
        iata: "MMV",
        countryIata: "PGMMV",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Maron",
        city: "Maron",
        iata: "MNP",
        countryIata: "PGMNP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Mapoda",
        city: "Mapoda",
        iata: "MPF",
        countryIata: "PGMPF",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Makini",
        city: "Makini",
        iata: "MPG",
        countryIata: "PGMPG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Mapua",
        city: "Mapua",
        iata: "MPU",
        countryIata: "PGMPU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Miyanmin",
        city: "Miyanmin",
        iata: "MPX",
        countryIata: "PGMPX",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Malam",
        city: "Malam",
        iata: "MQO",
        countryIata: "PGMQO",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "May River",
        city: "May River",
        iata: "MRH",
        countryIata: "PGMRH",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Manare",
        city: "Manare",
        iata: "MRM",
        countryIata: "PGMRM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Manetai",
        city: "Manetai",
        iata: "MVI",
        countryIata: "PGMVI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Marawaka",
        city: "Marawaka",
        iata: "MWG",
        countryIata: "PGMWG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Maramuni",
        city: "Maramuni",
        iata: "MWI",
        countryIata: "PGMWI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Mussau",
        city: "Mussau",
        iata: "MWU",
        countryIata: "PGMWU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Moro",
        city: "Moro",
        iata: "MXH",
        countryIata: "PGMXH",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Mindik",
        city: "Mindik",
        iata: "MXK",
        countryIata: "PGMXK",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Menyamya",
        city: "Menyamya",
        iata: "MYX",
        countryIata: "PGMYX",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Minj",
        city: "Minj",
        iata: "MZN",
        countryIata: "PGMZN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Nambaiyufa",
        city: "Nambaiyufa",
        iata: "NBA",
        countryIata: "PGNBA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Namudi",
        city: "Namudi",
        iata: "NDI",
        countryIata: "PGNDI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Nadunumu",
        city: "Nadunumu",
        iata: "NDN",
        countryIata: "PGNDN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Ningerum",
        city: "Ningerum",
        iata: "NGR",
        countryIata: "PGNGR",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Simberi",
        city: "Simberi Island",
        iata: "NIS",
        countryIata: "PGNIS",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Nankina",
        city: "Nankina",
        iata: "NKN",
        countryIata: "PGNKN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Nomane",
        city: "Nomane",
        iata: "NMN",
        countryIata: "PGNMN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Nomad River",
        city: "Nomad River",
        iata: "NOM",
        countryIata: "PGNOM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Naoro",
        city: "Naoro",
        iata: "NOO",
        countryIata: "PGNOO",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Nipa",
        city: "Nipa",
        iata: "NPG",
        countryIata: "PGNPG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Nuguria",
        city: "Nuguria",
        iata: "NUG",
        countryIata: "PGNUG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Nutuve",
        city: "Nutuve",
        iata: "NUT",
        countryIata: "PGNUT",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Nowata",
        city: "Nowata",
        iata: "NWT",
        countryIata: "PGNWT",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Morobe",
        city: "Morobe",
        iata: "OBM",
        countryIata: "PGOBM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Obo",
        city: "Obo",
        iata: "OBX",
        countryIata: "PGOBX",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Ogeranang",
        city: "Ogeranang",
        iata: "OGE",
        countryIata: "PGOGE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Oksapmin",
        city: "Oksapmin",
        iata: "OKP",
        countryIata: "PGOKP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Okao",
        city: "Okao",
        iata: "OKV",
        countryIata: "PGOKV",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Olsobip",
        city: "Olsobip",
        iata: "OLQ",
        countryIata: "PGOLQ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Omkalai",
        city: "Omkalai",
        iata: "OML",
        countryIata: "PGOML",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Ononge",
        city: "Ononge",
        iata: "ONB",
        countryIata: "PGONB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Open Bay",
        city: "Open Bay",
        iata: "OPB",
        countryIata: "PGOPB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Balimo",
        city: "Balimo",
        iata: "OPU",
        countryIata: "PGOPU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Omora",
        city: "Omora",
        iata: "OSE",
        countryIata: "PGOSE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Ossima",
        city: "Ossima",
        iata: "OSG",
        countryIata: "PGOSG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Oria",
        city: "Oria",
        iata: "OTY",
        countryIata: "PGOTY",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Pambwa",
        city: "Pambwa",
        iata: "PAW",
        countryIata: "PGPAW",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Pindiu",
        city: "Pindiu",
        iata: "PDI",
        countryIata: "PGPDI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Pangoa",
        city: "Pangoa",
        iata: "PGB",
        countryIata: "PGPGB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Yegepa",
        city: "Yegepa",
        iata: "PGE",
        countryIata: "PGPGE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Pangia",
        city: "Pangia",
        iata: "PGN",
        countryIata: "PGPGN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Paiela",
        city: "Paiela",
        iata: "PLE",
        countryIata: "PGPLE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Pumani",
        city: "Pumani",
        iata: "PMN",
        countryIata: "PGPMN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Pimaga",
        city: "Pimaga",
        iata: "PMP",
        countryIata: "PGPMP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Girua",
        city: "Popondetta",
        iata: "PNP",
        countryIata: "PGPNP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Jackson Field",
        city: "Port Moresby",
        iata: "POM",
        countryIata: "PGPOM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Param",
        city: "Param",
        iata: "PPX",
        countryIata: "PGPPX",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Puas",
        city: "Puas",
        iata: "PUA",
        countryIata: "PGPUA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Pureni",
        city: "Pureni",
        iata: "PUI",
        countryIata: "PGPUI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Rakanda",
        city: "Rakanda",
        iata: "RAA",
        countryIata: "PGRAA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tokua Airport",
        city: "Rabaul",
        iata: "RAB",
        countryIata: "PGRAB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Arawa",
        city: "Arawa",
        iata: "RAW",
        countryIata: "PGRAW",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Oram",
        city: "Oram",
        iata: "RAX",
        countryIata: "PGRAX",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Rabaraba",
        city: "Rabaraba",
        iata: "RBP",
        countryIata: "PGRBP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Porgera",
        city: "Porgera",
        iata: "RGE",
        countryIata: "PGRGE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kairuku",
        city: "Yule Island",
        iata: "RKU",
        countryIata: "PGRKU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Rumginae",
        city: "Rumginae",
        iata: "RMN",
        countryIata: "PGRMN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Robinson River",
        city: "Robinson River",
        iata: "RNR",
        countryIata: "PGRNR",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Ruti",
        city: "Ruti",
        iata: "RUU",
        countryIata: "PGRUU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Salamo",
        city: "Salamo",
        iata: "SAM",
        countryIata: "PGSAM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Selbang",
        city: "Selbang",
        iata: "SBC",
        countryIata: "PGSBC",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Suabi Airport",
        city: "Suabi",
        iata: "SBE",
        countryIata: "PGSBE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Sabah",
        city: "Sabah",
        iata: "SBV",
        countryIata: "PGSBV",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Saidor",
        city: "Saidor",
        iata: "SDI",
        countryIata: "PGSDI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Safia",
        city: "Safia",
        iata: "SFU",
        countryIata: "PGSFU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Singaua",
        city: "Singaua",
        iata: "SGB",
        countryIata: "PGSGB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Sagarai",
        city: "Sagarai",
        iata: "SGJ",
        countryIata: "PGSGJ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Sangapi",
        city: "Sangapi",
        iata: "SGK",
        countryIata: "PGSGK",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Sila",
        city: "Sila",
        iata: "SIL",
        countryIata: "PGSIL",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Simbai",
        city: "Simbai",
        iata: "SIM",
        countryIata: "PGSIM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Sissano",
        city: "Sissano",
        iata: "SIZ",
        countryIata: "PGSIZ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Suki",
        city: "Suki",
        iata: "SKC",
        countryIata: "PGSKC",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Sapmanga",
        city: "Sapmanga",
        iata: "SMH",
        countryIata: "PGSMH",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Sim",
        city: "Sim",
        iata: "SMJ",
        countryIata: "PGSMJ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Stockholm",
        city: "Stockholm",
        iata: "SMP",
        countryIata: "PGSMP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Sopu",
        city: "Sopu",
        iata: "SPH",
        countryIata: "PGSPH",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Sepik Plains",
        city: "Sepik Plains",
        iata: "SPV",
        countryIata: "PGSPV",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "China Straits Airstrip",
        city: "Samarai Island",
        iata: "SQT",
        countryIata: "PGSQT",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Siassi",
        city: "Siassi",
        iata: "SSS",
        countryIata: "PGSSS",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Suria",
        city: "Suria",
        iata: "SUZ",
        countryIata: "PGSUZ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Siwea",
        city: "Siwea",
        iata: "SWE",
        countryIata: "PGSWE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Satwag",
        city: "Satwag",
        iata: "SWG",
        countryIata: "PGSWG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Silur",
        city: "Silur",
        iata: "SWR",
        countryIata: "PGSWR",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Sialum",
        city: "Sialum",
        iata: "SXA",
        countryIata: "PGSXA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Sehulea",
        city: "Sehulea",
        iata: "SXH",
        countryIata: "PGSXH",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Sauren",
        city: "Sauren",
        iata: "SXW",
        countryIata: "PGSXW",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tadji",
        city: "Aitape",
        iata: "TAJ",
        countryIata: "PGTAJ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tabibuga",
        city: "Tabibuga",
        iata: "TBA",
        countryIata: "PGTBA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Timbunke",
        city: "Timbunke",
        iata: "TBE",
        countryIata: "PGTBE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tabubil",
        city: "Tabubil",
        iata: "TBG",
        countryIata: "PGTBG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tarabo",
        city: "Tarabo",
        iata: "TBQ",
        countryIata: "PGTBQ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Torembi Airport",
        city: "Torembi",
        iata: "TCJ",
        countryIata: "PGTCJ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tinboli Airport",
        city: "Tinboli",
        iata: "TCK",
        countryIata: "PGTCK",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tetabedi",
        city: "Tetabedi",
        iata: "TDB",
        countryIata: "PGTDB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Terapo",
        city: "Terapo",
        iata: "TEO",
        countryIata: "PGTEO",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Teptep",
        city: "Teptep",
        iata: "TEP",
        countryIata: "PGTEP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tifalmin",
        city: "Tifalmin",
        iata: "TFB",
        countryIata: "PGTFB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tufi",
        city: "Tufi",
        iata: "TFI",
        countryIata: "PGTFI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Telefomin",
        city: "Telefomin",
        iata: "TFM",
        countryIata: "PGTFM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tagula",
        city: "Tagula",
        iata: "TGL",
        countryIata: "PGTGL",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tingwon",
        city: "Tingwon",
        iata: "TIG",
        countryIata: "PGTIG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tari",
        city: "Tari",
        iata: "TIZ",
        countryIata: "PGTIZ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tekadu",
        city: "Tekadu",
        iata: "TKB",
        countryIata: "PGTKB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tekin",
        city: "Tekin",
        iata: "TKW",
        countryIata: "PGTKW",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tol",
        city: "Tol",
        iata: "TLO",
        countryIata: "PGTLO",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tumolbil",
        city: "Tumolbil",
        iata: "TLP",
        countryIata: "PGTLP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Talasea",
        city: "Talasea",
        iata: "TLW",
        countryIata: "PGTLW",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Torokina",
        city: "Torokina",
        iata: "TOK",
        countryIata: "PGTOK",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tonu",
        city: "Tonu",
        iata: "TON",
        countryIata: "PGTON",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tapini",
        city: "Tapini",
        iata: "TPI",
        countryIata: "PGTPI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tarakbits",
        city: "Tarakbits",
        iata: "TRJ",
        countryIata: "PGTRJ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tsili Tsili",
        city: "Tsili Tsili",
        iata: "TSI",
        countryIata: "PGTSI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Taskul",
        city: "Taskul",
        iata: "TSK",
        countryIata: "PGTSK",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tsewi",
        city: "Tsewi",
        iata: "TSW",
        countryIata: "PGTSW",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tauta",
        city: "Tauta",
        iata: "TUT",
        countryIata: "PGTUT",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Tawa",
        city: "Tawa",
        iata: "TWY",
        countryIata: "PGTWY",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Mount Aue",
        city: "Mount Aue",
        iata: "UAE",
        countryIata: "PGUAE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Buin",
        city: "Buin",
        iata: "UBI",
        countryIata: "PGUBI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Nuku",
        city: "Nuku",
        iata: "UKU",
        countryIata: "PGUKU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Sule",
        city: "Sule",
        iata: "ULE",
        countryIata: "PGULE",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Umba",
        city: "Umba",
        iata: "UMC",
        countryIata: "PGUMC",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Kiunga",
        city: "Kiunga",
        iata: "UNG",
        countryIata: "PGUNG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Upiara",
        city: "Upiara",
        iata: "UPR",
        countryIata: "PGUPR",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Uroubi",
        city: "Uroubi",
        iata: "URU",
        countryIata: "PGURU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Usino",
        city: "Usino",
        iata: "USO",
        countryIata: "PGUSO",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Manumu",
        city: "Manumu",
        iata: "UUU",
        countryIata: "PGUUU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Uvol",
        city: "Uvol",
        iata: "UVO",
        countryIata: "PGUVO",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Vanimo",
        city: "Vanimo",
        iata: "VAI",
        countryIata: "PGVAI",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Vivigani",
        city: "Vivigani",
        iata: "VIV",
        countryIata: "PGVIV",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Baimuru",
        city: "Baimuru",
        iata: "VMU",
        countryIata: "PGVMU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wabag",
        city: "Wabag",
        iata: "WAB",
        countryIata: "PGWAB",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wawoi Falls",
        city: "Wawoi Falls",
        iata: "WAJ",
        countryIata: "PGWAJ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wabo",
        city: "Wabo",
        iata: "WAO",
        countryIata: "PGWAO",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wapolu",
        city: "Wapolu",
        iata: "WBC",
        countryIata: "PGWBC",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wapenamanda",
        city: "Wapenamanda",
        iata: "WBM",
        countryIata: "PGWBM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wedau",
        city: "Wedau",
        iata: "WED",
        countryIata: "PGWED",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Weam",
        city: "Weam",
        iata: "WEP",
        countryIata: "PGWEP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wagau",
        city: "Wagau",
        iata: "WGU",
        countryIata: "PGWGU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Witu",
        city: "Witu",
        iata: "WIU",
        countryIata: "PGWIU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wakunai",
        city: "Wakunai",
        iata: "WKN",
        countryIata: "PGWKN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wanuma",
        city: "Wanuma",
        iata: "WNU",
        countryIata: "PGWNU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wonenara",
        city: "Wonenara",
        iata: "WOA",
        countryIata: "PGWOA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wipim",
        city: "Wipim",
        iata: "WPM",
        countryIata: "PGWPM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wasua",
        city: "Wasua",
        iata: "WSA",
        countryIata: "PGWSA",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wasu",
        city: "Wasu",
        iata: "WSU",
        countryIata: "PGWSU",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Woitape",
        city: "Woitape",
        iata: "WTP",
        countryIata: "PGWTP",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wantoat",
        city: "Wantoat",
        iata: "WTT",
        countryIata: "PGWTT",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wau",
        city: "Wau",
        iata: "WUG",
        countryIata: "PGWUG",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wasum",
        city: "Wasum",
        iata: "WUM",
        countryIata: "PGWUM",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Wuvulu Island",
        city: "Wuvulu Island",
        iata: "WUV",
        countryIata: "PGWUV",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Boram",
        city: "Wewak",
        iata: "WWK",
        countryIata: "PGWWK",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Biniguni",
        city: "Biniguni",
        iata: "XBN",
        countryIata: "PGXBN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Edwaki",
        city: "Yellow River",
        iata: "XYR",
        countryIata: "PGXYR",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Yenkis",
        city: "Yenkis",
        iata: "YEQ",
        countryIata: "PGYEQ",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Yeva",
        city: "Yeva",
        iata: "YVD",
        countryIata: "PGYVD",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Zenag",
        city: "Zenag",
        iata: "ZEN",
        countryIata: "PGZEN",
        country: "Papua New Guinea",
        countryCode: "PG",
    },
    {
        airportName: "Guarani International Airport (Alejo Garcia)",
        city: "Ciudad del Este",
        iata: "AGT",
        countryIata: "PYAGT",
        country: "Paraguay",
        countryCode: "PY",
    },
    {
        airportName: "Silvio Pettirossi International",
        city: "Asuncion",
        iata: "ASU",
        countryIata: "PYASU",
        country: "Paraguay",
        countryCode: "PY",
    },
    {
        airportName: "Ayolas",
        city: "Ayolas",
        iata: "AYO",
        countryIata: "PYAYO",
        country: "Paraguay",
        countryCode: "PY",
    },
    {
        airportName: "MCAL Lopez",
        city: "Concepcion",
        iata: "CIO",
        countryIata: "PYCIO",
        country: "Paraguay",
        countryCode: "PY",
    },
    {
        airportName: "Teniente Prim Alarcon",
        city: "Encarnacion",
        iata: "ENO",
        countryIata: "PYENO",
        country: "Paraguay",
        countryCode: "PY",
    },
    {
        airportName: "Mariscal Estigarribia",
        city: "Mariscal Estigarribia",
        iata: "ESG",
        countryIata: "PYESG",
        country: "Paraguay",
        countryCode: "PY",
    },
    {
        airportName: "Filadelfia",
        city: "Filadelfia",
        iata: "FLM",
        countryIata: "PYFLM",
        country: "Paraguay",
        countryCode: "PY",
    },
    {
        airportName: "Pilar",
        city: "Pilar",
        iata: "PIL",
        countryIata: "PYPIL",
        country: "Paraguay",
        countryCode: "PY",
    },
    {
        airportName: "Pedro Juan Caballero",
        city: "Pedro Juan Caballero",
        iata: "PJC",
        countryIata: "PYPJC",
        country: "Paraguay",
        countryCode: "PY",
    },
    {
        airportName: "INC",
        city: "Vallemi",
        iata: "VMI",
        countryIata: "PYVMI",
        country: "Paraguay",
        countryCode: "PY",
    },
    {
        airportName: "Alerta",
        city: "Alerta",
        iata: "ALD",
        countryIata: "PEALD",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Andahuaylas",
        city: "Andahuaylas",
        iata: "ANS",
        countryIata: "PEANS",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "San Juan Aposento",
        city: "San Juan Aposento",
        iata: "APE",
        countryIata: "PEAPE",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Rodriguez Ballon International Airport",
        city: "Arequipa",
        iata: "AQP",
        countryIata: "PEAQP",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Anta",
        city: "Anta",
        iata: "ATA",
        countryIata: "PEATA",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Yanamilla",
        city: "Ayacucho",
        iata: "AYP",
        countryIata: "PEAYP",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Bellavista",
        city: "Bellavista",
        iata: "BLP",
        countryIata: "PEBLP",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Chachapoyas",
        city: "Chachapoyas",
        iata: "CHH",
        countryIata: "PECHH",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Chimbote",
        city: "Chimbote",
        iata: "CHM",
        countryIata: "PECHM",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Cornel Ruiz",
        city: "Chiclayo",
        iata: "CIX",
        countryIata: "PECIX",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Cajamarca",
        city: "Cajamarca",
        iata: "CJA",
        countryIata: "PECJA",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Velazco Astete",
        city: "Cuzco",
        iata: "CUZ",
        countryIata: "PECUZ",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Huanuco",
        city: "Huanuco",
        iata: "HUU",
        countryIata: "PEHUU",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Iberia",
        city: "Iberia",
        iata: "IBP",
        countryIata: "PEIBP",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Ilo",
        city: "Ilo",
        iata: "ILQ",
        countryIata: "PEILQ",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "C.F. Secada",
        city: "Iquitos",
        iata: "IQT",
        countryIata: "PEIQT",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Jauja",
        city: "Jauja",
        iata: "JAU",
        countryIata: "PEJAU",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Juanjui",
        city: "Juanjui",
        iata: "JJI",
        countryIata: "PEJJI",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Juliaca",
        city: "Juliaca",
        iata: "JUL",
        countryIata: "PEJUL",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "J Chavez International",
        city: "Lima",
        iata: "LIM",
        countryIata: "PELIM",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Moyobamba",
        city: "Moyobamba",
        iata: "MBP",
        countryIata: "PEMBP",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Machu Picchu",
        city: "Machu Picchu",
        iata: "MFT",
        countryIata: "PEMFT",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Capitan Rolden",
        city: "Pucallpa",
        iata: "PCL",
        countryIata: "PEPCL",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Puerto Maldonado",
        city: "Puerto Maldonado",
        iata: "PEM",
        countryIata: "PEPEM",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Pisco",
        city: "Pisco",
        iata: "PIO",
        countryIata: "PEPIO",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Piura",
        city: "Piura",
        iata: "PIU",
        countryIata: "PEPIU",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Rioja",
        city: "Rioja",
        iata: "RIJ",
        countryIata: "PERIJ",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Rodriguez De Men",
        city: "Rodriguez De Men",
        iata: "RIM",
        countryIata: "PERIM",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "San Juan",
        city: "San Juan",
        iata: "SJA",
        countryIata: "PESJA",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Santa Maria",
        city: "Santa Maria",
        iata: "SMG",
        countryIata: "PESMG",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Saposoa",
        city: "Saposoa",
        iata: "SQU",
        countryIata: "PESQU",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Shiringayoc",
        city: "Shiringayoc",
        iata: "SYC",
        countryIata: "PESYC",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Tumbes",
        city: "Tumbes",
        iata: "TBP",
        countryIata: "PETBP",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Tacna",
        city: "Tacna",
        iata: "TCQ",
        countryIata: "PETCQ",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Tingo Maria",
        city: "Tingo Maria",
        iata: "TGI",
        countryIata: "PETGI",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Tarapoto",
        city: "Tarapoto",
        iata: "TPP",
        countryIata: "PETPP",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Trujillo",
        city: "Trujillo",
        iata: "TRU",
        countryIata: "PETRU",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Talara",
        city: "Talara",
        iata: "TYL",
        countryIata: "PETYL",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Quincemil",
        city: "Quincemil",
        iata: "UMI",
        countryIata: "PEUMI",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Yurimaguas",
        city: "Yurimaguas",
        iata: "YMS",
        countryIata: "PEYMS",
        country: "Peru",
        countryCode: "PE",
    },
    {
        airportName: "Alah",
        city: "Alah",
        iata: "AAV",
        countryIata: "PHAAV",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Loakan",
        city: "Baguio",
        iata: "BAG",
        countryIata: "PHBAG",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Baganga",
        city: "Baganga",
        iata: "BNQ",
        countryIata: "PHBNQ",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Bislig",
        city: "Bislig",
        iata: "BPH",
        countryIata: "PHBPH",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Baler",
        city: "Baler",
        iata: "BQA",
        countryIata: "PHBQA",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Basco",
        city: "Basco",
        iata: "BSO",
        countryIata: "PHBSO",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Butuan",
        city: "Butuan",
        iata: "BXU",
        countryIata: "PHBXU",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Awang",
        city: "Cotabato",
        iata: "CBO",
        countryIata: "PHCBO",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Cagayan De Sulu",
        city: "Cagayan De Sulu",
        iata: "CDY",
        countryIata: "PHCDY",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Mactan-Cebu International Airport official website",
        city: "Cebu",
        iata: "CEB",
        countryIata: "PHCEB",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Casiguran",
        city: "Casiguran",
        iata: "CGG",
        countryIata: "PHCGG",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Mambajao",
        city: "Camiguin",
        iata: "CGM",
        countryIata: "PHCGM",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Lumbia",
        city: "Cagayan De Oro",
        iata: "CGY",
        countryIata: "PHCGY",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Clark Field",
        city: "Luzon Island",
        iata: "CRK",
        countryIata: "PHCRK",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "National",
        city: "Catarman",
        iata: "CRM",
        countryIata: "PHCRM",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Culion",
        city: "Culion",
        iata: "CUJ",
        countryIata: "PHCUJ",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Calbayog",
        city: "Calbayog",
        iata: "CYP",
        countryIata: "PHCYP",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Cuyo",
        city: "Cuyo",
        iata: "CYU",
        countryIata: "PHCYU",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Cauayan",
        city: "Cauayan",
        iata: "CYZ",
        countryIata: "PHCYZ",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Dumaguete",
        city: "Dumaguete",
        iata: "DGT",
        countryIata: "PHDGT",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Dipolog",
        city: "Dipolog",
        iata: "DPL",
        countryIata: "PHDPL",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Dilasag",
        city: "Dilasag",
        iata: "DSG",
        countryIata: "PHDSG",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Bagasbas Airport",
        city: "Daet, Camarines Norte province",
        iata: "DTE",
        countryIata: "PHDTE",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Mati",
        city: "Davao",
        iata: "DVO",
        countryIata: "PHDVO",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "El Nido",
        city: "El Nido",
        iata: "ENI",
        countryIata: "PHENI",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Buayan",
        city: "General Santos",
        iata: "GES",
        countryIata: "PHGES",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Sicogon Island",
        city: "Sicogon Island",
        iata: "ICO",
        countryIata: "PHICO",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Maria Cristina Airport",
        city: "Iligan",
        iata: "IGN",
        countryIata: "PHIGN",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Iloilo International Airport",
        city: "Iloilo",
        iata: "ILO",
        countryIata: "PHILO",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Ipil",
        city: "Ipil",
        iata: "IPE",
        countryIata: "PHIPE",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Jolo",
        city: "Jolo",
        iata: "JOL",
        countryIata: "PHJOL",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Kalibo",
        city: "Kalibo",
        iata: "KLO",
        countryIata: "PHKLO",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Laoag",
        city: "Laoag",
        iata: "LAO",
        countryIata: "PHLAO",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Lubang",
        city: "Lubang",
        iata: "LBX",
        countryIata: "PHLBX",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Legaspi",
        city: "Legaspi",
        iata: "LGP",
        countryIata: "PHLGP",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Lwbak",
        city: "Lwbak",
        iata: "LWA",
        countryIata: "PHLWA",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Mamburao",
        city: "Mamburao",
        iata: "MBO",
        countryIata: "PHMBO",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Masbate",
        city: "Masbate",
        iata: "MBT",
        countryIata: "PHMBT",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Malabang",
        city: "Malabang",
        iata: "MLP",
        countryIata: "PHMLP",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Ninoy Aquino International",
        city: "Manila",
        iata: "MNL",
        countryIata: "PHMNL",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Godofredo P. Ramos Airport, Malay, Western Visayas",
        city: "Caticlan",
        iata: "MPH",
        countryIata: "PHMPH",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Marinduque",
        city: "Marinduque",
        iata: "MRQ",
        countryIata: "PHMRQ",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Mati",
        city: "Mati",
        iata: "MXI",
        countryIata: "PHMXI",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Cubi Pt NAS",
        city: "Luzon Island",
        iata: "NCP",
        countryIata: "PHNCP",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Nab",
        city: "Mactan Island",
        iata: "NOP",
        countryIata: "PHNOP",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "NAF",
        city: "Sangley Point",
        iata: "NSP",
        countryIata: "PHNSP",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Ormoc",
        city: "Ormoc",
        iata: "OMC",
        countryIata: "PHOMC",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Labo",
        city: "Ozamis City",
        iata: "OZC",
        countryIata: "PHOZC",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Pagadian",
        city: "Pagadian",
        iata: "PAG",
        countryIata: "PHPAG",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Puerto Princesa",
        city: "Puerto Princesa",
        iata: "PPS",
        countryIata: "PHPPS",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Roxas City",
        city: "Roxas City",
        iata: "RXS",
        countryIata: "PHRXS",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "San Fernando",
        city: "San Fernando",
        iata: "SFE",
        countryIata: "PHSFE",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Subic Bay International Airportt",
        city: "Subic Bay",
        iata: "SFS",
        countryIata: "PHSFS",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Sangley Point NAS",
        city: "Manila",
        iata: "SGL",
        countryIata: "PHSGL",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Sanga Sanga",
        city: "Sanga Sanga",
        iata: "SGS",
        countryIata: "PHSGS",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Mcguire Fld",
        city: "San Jose",
        iata: "SJI",
        countryIata: "PHSJI",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Siasi",
        city: "Siasi",
        iata: "SSV",
        countryIata: "PHSSV",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Surigao",
        city: "Surigao",
        iata: "SUG",
        countryIata: "PHSUG",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "D.Z. Romualdez",
        city: "Tacloban",
        iata: "TAC",
        countryIata: "PHTAC",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Bohol International Airport",
        city: "Panglao",
        iata: "TAG",
        countryIata: "PHTAG",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Tablas",
        city: "Tablas",
        iata: "TBH",
        countryIata: "PHTBH",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Tandag",
        city: "Tandag",
        iata: "TDG",
        countryIata: "PHTDG",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Tagbita",
        city: "Tagbita",
        iata: "TGB",
        countryIata: "PHTGB",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Tuguegarao",
        city: "Tuguegarao",
        iata: "TUG",
        countryIata: "PHTUG",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Tawitawi",
        city: "Tawitawi",
        iata: "TWT",
        countryIata: "PHTWT",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Busuanga",
        city: "Busuanga",
        iata: "USU",
        countryIata: "PHUSU",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Virac",
        city: "Virac",
        iata: "VRC",
        countryIata: "PHVRC",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Naga",
        city: "Naga",
        iata: "WNP",
        countryIata: "PHWNP",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Coron",
        city: "Coron",
        iata: "XCN",
        countryIata: "PHXCN",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Maramag",
        city: "Maramag",
        iata: "XMA",
        countryIata: "PHXMA",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Siocon",
        city: "Siocon",
        iata: "XSO",
        countryIata: "PHXSO",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Zamboanga International Airport",
        city: "Zamboanga",
        iata: "ZAM",
        countryIata: "PHZAM",
        country: "Philippines",
        countryCode: "PH",
    },
    {
        airportName: "Bydgoszcz",
        city: "Bydgoszcz",
        iata: "BZG",
        countryIata: "PLBZG",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "Czestochowa",
        city: "Czestochowa",
        iata: "CZW",
        countryIata: "PLCZW",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "Rebiechowo",
        city: "Gdansk",
        iata: "GDN",
        countryIata: "PLGDN",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "Babimost",
        city: "Zielona Gora",
        iata: "IEG",
        countryIata: "PLIEG",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "J. Paul II Balice International",
        city: "Krakow",
        iata: "KRK",
        countryIata: "PLKRK",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "Pyrzowice",
        city: "Katowice",
        iata: "KTW",
        countryIata: "PLKTW",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "Lodz Lublinek",
        city: "Lodz",
        iata: "LCJ",
        countryIata: "PLLCJ",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "Lublin Airport",
        city: "Lublin",
        iata: "LUZ",
        countryIata: "PLLUZ",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "Redzikowo",
        city: "Slupsk",
        iata: "OSP",
        countryIata: "PLOSP",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "Koszalin",
        city: "Koszalin",
        iata: "OSZ",
        countryIata: "PLOSZ",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "Lawica",
        city: "Poznan",
        iata: "POZ",
        countryIata: "PLPOZ",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "Jasionka",
        city: "Rzeszow",
        iata: "RZE",
        countryIata: "PLRZE",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "Mazury",
        city: "Szymany",
        iata: "SZY",
        countryIata: "PLSZY",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "Goleniow",
        city: "Szczecin",
        iata: "SZZ",
        countryIata: "PLSZZ",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "Okecie",
        city: "Warsaw",
        iata: "WAW",
        countryIata: "PLWAW",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "Strachowice",
        city: "Wroclaw",
        iata: "WRO",
        countryIata: "PLWRO",
        country: "Poland",
        countryCode: "PL",
    },
    {
        airportName: "Braganca",
        city: "Braganca",
        iata: "BGC",
        countryIata: "PTBGC",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Braga",
        city: "Braga",
        iata: "BGZ",
        countryIata: "PTBGZ",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Coimbra",
        city: "Coimbra",
        iata: "CBP",
        countryIata: "PTCBP",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Chaves",
        city: "Chaves",
        iata: "CHV",
        countryIata: "PTCHV",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Covilha",
        city: "Covilha",
        iata: "COV",
        countryIata: "PTCOV",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Corvo Island",
        city: "Corvo Island",
        iata: "CVU",
        countryIata: "PTCVU",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Aeroporto Internacional de Faro",
        city: "Faro",
        iata: "FAO",
        countryIata: "PTFAO",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Santa Cruz",
        city: "Flores Island",
        iata: "FLW",
        countryIata: "PTFLW",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Funchal",
        city: "Funchal",
        iata: "FNC",
        countryIata: "PTFNC",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Graciosa Island",
        city: "Graciosa Island",
        iata: "GRW",
        countryIata: "PTGRW",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Horta",
        city: "Horta",
        iata: "HOR",
        countryIata: "PTHOR",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Lisboa",
        city: "Lisbon",
        iata: "LIS",
        countryIata: "PTLIS",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Porto",
        city: "Porto",
        iata: "OPO",
        countryIata: "PTOPO",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Nordela",
        city: "Ponta Delgada",
        iata: "PDL",
        countryIata: "PTPDL",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Pico Island",
        city: "Pico Island",
        iata: "PIX",
        countryIata: "PTPIX",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Portimao",
        city: "Portimao",
        iata: "PRM",
        countryIata: "PTPRM",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Porto Santo",
        city: "Porto Santo",
        iata: "PXO",
        countryIata: "PTPXO",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Monte Real AB",
        city: "Monte Real",
        iata: "QLR",
        countryIata: "PTQLR",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Sines",
        city: "Sines",
        iata: "SIE",
        countryIata: "PTSIE",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Sao Jorge Island",
        city: "Sao Jorge Island",
        iata: "SJZ",
        countryIata: "PTSJZ",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Vila Do Porto",
        city: "Santa Maria",
        iata: "SMA",
        countryIata: "PTSMA",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Lajes",
        city: "Terceira Island",
        iata: "TER",
        countryIata: "PTTER",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Vila Real",
        city: "Vila Real",
        iata: "VRL",
        countryIata: "PTVRL",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Viseu",
        city: "Viseu",
        iata: "VSE",
        countryIata: "PTVSE",
        country: "Portugal",
        countryCode: "PT",
    },
    {
        airportName: "Altay Airport",
        city: "Altay",
        iata: "AAT",
        countryIata: "CNAAT",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Ankang",
        city: "Ankang",
        iata: "AKA",
        countryIata: "CNAKA",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Aksu",
        city: "Aksu",
        iata: "AKU",
        countryIata: "CNAKU",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Anshan",
        city: "Anshan",
        iata: "AOG",
        countryIata: "CNAOG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Allen County",
        city: "Liangping",
        iata: "AOH",
        countryIata: "CNAOH",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Anqing",
        city: "Anqing",
        iata: "AQG",
        countryIata: "CNAQG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Anyang",
        city: "Anyang",
        iata: "AYN",
        countryIata: "CNAYN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Baotou",
        city: "Baotou",
        iata: "BAV",
        countryIata: "CNBAV",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Bengbu",
        city: "Bengbu",
        iata: "BFU",
        countryIata: "CNBFU",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Beihai",
        city: "Beihai",
        iata: "BHY",
        countryIata: "CNBHY",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Metropolitan Area",
        city: "Beijing",
        iata: "BJS",
        countryIata: "CNBJS",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Bangda",
        city: "Bangda",
        iata: "BPX",
        countryIata: "CNBPX",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Baoshan",
        city: "Baoshan",
        iata: "BSD",
        countryIata: "CNBSD",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Baiyun",
        city: "Guangzhou",
        iata: "CAN",
        countryIata: "CNCAN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Changde",
        city: "Changde",
        iata: "CGD",
        countryIata: "CNCGD",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Zhengzhou",
        city: "Zhengzhou",
        iata: "CGO",
        countryIata: "CNCGO",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Changchun",
        city: "Changchun",
        iata: "CGQ",
        countryIata: "CNCGQ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Chaoyang Airport",
        city: "Chaoyang",
        iata: "CHG",
        countryIata: "CNCHG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Chifeng",
        city: "Chifeng",
        iata: "CIF",
        countryIata: "CNCIF",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Changzhi",
        city: "Changzhi",
        iata: "CIH",
        countryIata: "CNCIH",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Jiangbei International",
        city: "Chongqing",
        iata: "CKG",
        countryIata: "CNCKG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Changhai",
        city: "Changhai",
        iata: "CNI",
        countryIata: "CNCNI",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Changsha",
        city: "Changsha",
        iata: "CSX",
        countryIata: "CNCSX",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Chengdu Shuangliu International Airport",
        city: "Chengdu",
        iata: "CTU",
        countryIata: "CNCTU",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Changzhou",
        city: "Changzhou",
        iata: "CZX",
        countryIata: "CNCZX",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Datong",
        city: "Datong",
        iata: "DAT",
        countryIata: "CNDAT",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Daxian",
        city: "Daxian",
        iata: "DAX",
        countryIata: "CNDAX",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Dandong Langtou Airport",
        city: "Dandong",
        iata: "DDG",
        countryIata: "CNDDG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Diqing",
        city: "Diqing",
        iata: "DIG",
        countryIata: "CNDIG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Dalian",
        city: "Dalian",
        iata: "DLC",
        countryIata: "CNDLC",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Dali",
        city: "Dali City",
        iata: "DLU",
        countryIata: "CNDLU",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Dunhuang",
        city: "Dunhuang",
        iata: "DNH",
        countryIata: "CNDNH",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Ordos Ejin Horo Airport",
        city: "Ordos",
        iata: "DSN",
        countryIata: "CNDSN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Dayong",
        city: "Dayong",
        iata: "DYG",
        countryIata: "CNDYG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Dazu Air Base",
        city: "Dazu",
        iata: "DZU",
        countryIata: "CNDZU",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Enshi",
        city: "Enshi",
        iata: "ENH",
        countryIata: "CNENH",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Yan'an",
        city: "Yan'an",
        iata: "ENY",
        countryIata: "CNENY",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Fuzhou",
        city: "Fuzhou",
        iata: "FOC",
        countryIata: "CNFOC",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Fuyang",
        city: "Fuyang",
        iata: "FUG",
        countryIata: "CNFUG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Fuoshan",
        city: "Fuoshan",
        iata: "FUO",
        countryIata: "CNFUO",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Fuyun",
        city: "Fuyun",
        iata: "FYN",
        countryIata: "CNFYN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Guanghan",
        city: "Guanghan",
        iata: "GHN",
        countryIata: "CNGHN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Golmud",
        city: "Golmud",
        iata: "GOQ",
        countryIata: "CNGOQ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Guang Yuan",
        city: "Guang Yuan",
        iata: "GYS",
        countryIata: "CNGYS",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Haikou",
        city: "Haikou",
        iata: "HAK",
        countryIata: "CNHAK",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Heihe",
        city: "Heihe",
        iata: "HEK",
        countryIata: "CNHEK",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Hohhot",
        city: "Hohhot",
        iata: "HET",
        countryIata: "CNHET",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Hefei",
        city: "Hefei",
        iata: "HFE",
        countryIata: "CNHFE",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Hangzhou",
        city: "Hangzhou",
        iata: "HGH",
        countryIata: "CNHGH",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Changsha Huanghua",
        city: "Huanghua",
        iata: "HHA",
        countryIata: "CNHHA",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "H K Heliport",
        city: "Hong Kong",
        iata: "HHP",
        countryIata: "CNHHP",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Hong Kong International",
        city: "Hong Kong",
        iata: "HKG",
        countryIata: "CNHKG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Hailar",
        city: "Hailar",
        iata: "HLD",
        countryIata: "CNHLD",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Ulanhot",
        city: "Ulanhot",
        iata: "HLH",
        countryIata: "CNHLH",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Hami",
        city: "Hami",
        iata: "HMI",
        countryIata: "CNHMI",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Hengyang",
        city: "Hengyang",
        iata: "HNY",
        countryIata: "CNHNY",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Harbin",
        city: "Harbin",
        iata: "HRB",
        countryIata: "CNHRB",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Shaoguan",
        city: "Shaoguan",
        iata: "HSC",
        countryIata: "CNHSC",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Zhoushan",
        city: "Zhoushan",
        iata: "HSN",
        countryIata: "CNHSN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Hotan",
        city: "Hotan",
        iata: "HTN",
        countryIata: "CNHTN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Huizhou",
        city: "Huizhou",
        iata: "HUZ",
        countryIata: "CNHUZ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Taizhou Luqiao Airport",
        city: "Taizhou",
        iata: "HYN",
        countryIata: "CNHYN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Hanzhong Chenggu Airport",
        city: "Hanzhong",
        iata: "HZG",
        countryIata: "CNHZG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Yinchuan",
        city: "Yinchuan",
        iata: "INC",
        countryIata: "CNINC",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Qiemo",
        city: "Qiemo",
        iata: "IQM",
        countryIata: "CNIQM",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Qingyang",
        city: "Qingyang",
        iata: "IQN",
        countryIata: "CNIQN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Jingdezhen",
        city: "Jingdezhen",
        iata: "JDZ",
        countryIata: "CNJDZ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Jiayuguan Airport",
        city: "Jiayuguan",
        iata: "JGN",
        countryIata: "CNJGN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Gasa",
        city: "Jinghong",
        iata: "JHG",
        countryIata: "CNJHG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Jilin",
        city: "Jilin",
        iata: "JIL",
        countryIata: "CNJIL",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Jiujiang Lushan Airport",
        city: "Jiujiang",
        iata: "JIU",
        countryIata: "CNJIU",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Jinjiang",
        city: "Jinjiang",
        iata: "JJN",
        countryIata: "CNJJN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Jiamusi",
        city: "Jiamusi",
        iata: "JMU",
        countryIata: "CNJMU",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Jining",
        city: "Jining",
        iata: "JNG",
        countryIata: "CNJNG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Jinzhou",
        city: "Jinzhou",
        iata: "JNZ",
        countryIata: "CNJNZ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Quzhou Airport",
        city: "Juzhou (Quzhou)",
        iata: "JUZ",
        countryIata: "CNJUZ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Xingkaihu Airport",
        city: "Jixi",
        iata: "JXA",
        countryIata: "CNJXA",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Jiuzhai Huanglong Airport",
        city: "Jiuzhaigou NP",
        iata: "JZH",
        countryIata: "CNJZH",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Kuqa Qiuci Airport",
        city: "Kuqa",
        iata: "KCA",
        countryIata: "CNKCA",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Kashi",
        city: "Kashi",
        iata: "KHG",
        countryIata: "CNKHG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Nanchang",
        city: "Nanchang",
        iata: "KHN",
        countryIata: "CNKHN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Kunming Changshui International Airport",
        city: "Kunming",
        iata: "KMG",
        countryIata: "CNKMG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Ji'an",
        city: "Ji'an",
        iata: "JGS",
        countryIata: "CNJGS",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Ganzhou",
        city: "Ganzhou",
        iata: "KOW",
        countryIata: "CNKOW",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Korla",
        city: "Korla",
        iata: "KRL",
        countryIata: "CNKRL",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Karamay",
        city: "Karamay",
        iata: "KRY",
        countryIata: "CNKRY",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Kwun Tong",
        city: "Kwun Tong",
        iata: "KTZ",
        countryIata: "CNKTZ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Guiyang",
        city: "Guiyang",
        iata: "KWE",
        countryIata: "CNKWE",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Guilin",
        city: "Guilin",
        iata: "KWL",
        countryIata: "CNKWL",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Linfen Qiaoli Airport",
        city: "Linfen",
        iata: "LFQ",
        countryIata: "CNLFQ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Guanghua",
        city: "Guanghua",
        iata: "LHK",
        countryIata: "CNLHK",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Lanzhou Airport",
        city: "Lanzhou",
        iata: "LHW",
        countryIata: "CNLHW",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Liangping",
        city: "Liangping",
        iata: "LIA",
        countryIata: "CNLIA",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Lijiang",
        city: "Lijiang City",
        iata: "LJG",
        countryIata: "CNLJG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Mangshi",
        city: "Luxi",
        iata: "LUM",
        countryIata: "CNLUM",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Lhasa",
        city: "Lhasa",
        iata: "LXA",
        countryIata: "CNLXA",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Linxi",
        city: "Linxi",
        iata: "LXI",
        countryIata: "CNLXI",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Luoyang",
        city: "Luoyang",
        iata: "LYA",
        countryIata: "CNLYA",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Lianyungang",
        city: "Lianyungang",
        iata: "LYG",
        countryIata: "CNLYG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Linyi",
        city: "Linyi",
        iata: "LYI",
        countryIata: "CNLYI",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Lanzhoudong",
        city: "Lanzhou",
        iata: "LZD",
        countryIata: "CNLZD",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Liuzhou",
        city: "Liuzhou",
        iata: "LZH",
        countryIata: "CNLZH",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Luzhou",
        city: "Luzhou",
        iata: "LZO",
        countryIata: "CNLZO",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Mudanjiang",
        city: "Mudanjiang",
        iata: "MDG",
        countryIata: "CNMDG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Macau",
        city: "Macau",
        iata: "MFM",
        countryIata: "CNMFM",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Mian Yang",
        city: "Mian Yang",
        iata: "MIG",
        countryIata: "CNMIG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Meixian",
        city: "Meixian",
        iata: "MXZ",
        countryIata: "CNMXZ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Nanchong",
        city: "Nanchong",
        iata: "NAO",
        countryIata: "CNNAO",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Nanyuan Airport",
        city: "Beijing",
        iata: "NAY",
        countryIata: "CNNAY",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Qiqihar",
        city: "Qiqihar",
        iata: "NDG",
        countryIata: "CNNDG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Ningbo",
        city: "Ningbo",
        iata: "NGB",
        countryIata: "CNNGB",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Nanjing Lukou International Airport",
        city: "Nanking/Nanjing",
        iata: "NKG",
        countryIata: "CNNKG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Nanning",
        city: "Nanning",
        iata: "NNG",
        countryIata: "CNNNG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Nanyang",
        city: "Nanyang",
        iata: "NNY",
        countryIata: "CNNNY",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Nantong",
        city: "Nantong",
        iata: "NTG",
        countryIata: "CNNTG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Beijing Capital International",
        city: "Beijing",
        iata: "PEK",
        countryIata: "CNPEK",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Pu Dong",
        city: "Shanghai",
        iata: "PVG",
        countryIata: "CNPVG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Shek Mun Station",
        city: "Shek Mun",
        iata: "QDM",
        countryIata: "CNQDM",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Shache Airport",
        city: "Yarkant County",
        iata: "QSZ",
        countryIata: "CNQSZ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Rugao",
        city: "Rugao",
        iata: "RUG",
        countryIata: "CNRUG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Hongqiao",
        city: "Shanghai",
        iata: "SHA",
        countryIata: "CNSHA",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Shenyang",
        city: "Shenyang",
        iata: "SHE",
        countryIata: "CNSHE",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Shanhaiguan",
        city: "Shanhaiguan",
        iata: "SHF",
        countryIata: "CNSHF",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Qinhuangdao",
        city: "Qinhuangdao",
        iata: "SHP",
        countryIata: "CNSHP",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Shashi",
        city: "Shashi",
        iata: "SHS",
        countryIata: "CNSHS",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Xiguan",
        city: "Xi An",
        iata: "SIA",
        countryIata: "CNSIA",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Daguocun",
        city: "Shijiazhuang",
        iata: "SJW",
        countryIata: "CNSJW",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Shantou",
        city: "Shantou",
        iata: "SWA",
        countryIata: "CNSWA",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Shanshan",
        city: "Shanshan",
        iata: "SXJ",
        countryIata: "CNSXJ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Simao",
        city: "Simao",
        iata: "SYM",
        countryIata: "CNSYM",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Sanya",
        city: "Sanya",
        iata: "SYX",
        countryIata: "CNSYX",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Shanzhou",
        city: "Shanzhou",
        iata: "SZO",
        countryIata: "CNSZO",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Suzhou",
        city: "Suzhou",
        iata: "SZV",
        countryIata: "CNSZV",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Shenzhen",
        city: "Shenzhen",
        iata: "SZX",
        countryIata: "CNSZX",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Qingdao",
        city: "Qingdao",
        iata: "TAO",
        countryIata: "CNTAO",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Tacheng",
        city: "Tacheng",
        iata: "TCG",
        countryIata: "CNTCG",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Tongren",
        city: "Tongren",
        iata: "TEN",
        countryIata: "CNTEN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Tongliao",
        city: "Tongliao",
        iata: "TGO",
        countryIata: "CNTGO",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Jinan",
        city: "Jinan",
        iata: "TNA",
        countryIata: "CNTNA",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Tonghua Liuhe",
        city: "Tonghua",
        iata: "TNH",
        countryIata: "CNTNH",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Tianjin",
        city: "Tianjin",
        iata: "TSN",
        countryIata: "CNTSN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Tunxi",
        city: "Tunxi",
        iata: "TXN",
        countryIata: "CNTXN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Taiyuan",
        city: "Taiyuan",
        iata: "TYN",
        countryIata: "CNTYN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Urumqi",
        city: "Urumqi",
        iata: "URC",
        countryIata: "CNURC",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Yulin Yuyang Airport",
        city: "Yulin",
        iata: "UYN",
        countryIata: "CNUYN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Weifang",
        city: "Weifang",
        iata: "WEF",
        countryIata: "CNWEF",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Weihai",
        city: "Weihai",
        iata: "WEH",
        countryIata: "CNWEH",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Wuhu",
        city: "Wuhu",
        iata: "WHU",
        countryIata: "CNWHU",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Wenzhou Longwan Intl Airport",
        city: "Wenzhou",
        iata: "WNZ",
        countryIata: "CNWNZ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Wuhan Tianhe International Airport",
        city: "Wuhan",
        iata: "WUH",
        countryIata: "CNWUH",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Wuyishan",
        city: "Wuyishan",
        iata: "WUS",
        countryIata: "CNWUS",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Sunan Shuofang International Airport",
        city: "Wuxi",
        iata: "WUX",
        countryIata: "CNWUX",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Wuzhou Changzhoudao Airport",
        city: "Wuzhou",
        iata: "WUZ",
        countryIata: "CNWUZ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Wanxian",
        city: "Wanxian",
        iata: "WXN",
        countryIata: "CNWXN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Xingcheng",
        city: "Xingcheng",
        iata: "XEN",
        countryIata: "CNXEN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Xiangfan",
        city: "Xiangfan",
        iata: "XFN",
        countryIata: "CNXFN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Xichang",
        city: "Xichang",
        iata: "XIC",
        countryIata: "CNXIC",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Xilinhot",
        city: "Xilinhot",
        iata: "XIL",
        countryIata: "CNXIL",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Xingning",
        city: "Xingning",
        iata: "XIN",
        countryIata: "CNXIN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Xi'an Xianyang International Airport",
        city: "Xi'an",
        iata: "XIY",
        countryIata: "CNXIY",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Off-line Point",
        city: "Kennedy Town",
        iata: "XKT",
        countryIata: "CNXKT",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Xiamen Gaoqi International Airport",
        city: "Xiamen",
        iata: "XMN",
        countryIata: "CNXMN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Xining",
        city: "Xining",
        iata: "XNN",
        countryIata: "CNXNN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Xingtai",
        city: "Xingtai",
        iata: "XNT",
        countryIata: "CNXNT",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Xuzhou Guanyin Intl Airport",
        city: "Xuzhou",
        iata: "XUZ",
        countryIata: "CNXUZ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Yibin Caiba Airport",
        city: "Yibin",
        iata: "YBP",
        countryIata: "CNYBP",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Yichang",
        city: "Yichang",
        iata: "YIH",
        countryIata: "CNYIH",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Yining",
        city: "Yining",
        iata: "YIN",
        countryIata: "CNYIN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Yiwu",
        city: "Yiwu",
        iata: "YIW",
        countryIata: "CNYIW",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Yilan",
        city: "Yilan",
        iata: "YLN",
        countryIata: "CNYLN",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Yanji",
        city: "Yanji",
        iata: "YNJ",
        countryIata: "CNYNJ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Yantai Laishan International Airport",
        city: "Yantai",
        iata: "YNT",
        countryIata: "CNYNT",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Yancheng",
        city: "Yancheng",
        iata: "YNZ",
        countryIata: "CNYNZ",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Yuanmou",
        city: "Yuanmou",
        iata: "YUA",
        countryIata: "CNYUA",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Zhaotong",
        city: "Zhaotong",
        iata: "ZAT",
        countryIata: "CNZAT",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Zhongchuan",
        city: "Lanzhou",
        iata: "ZGC",
        countryIata: "CNZGC",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Zhanjiang",
        city: "Zhanjiang",
        iata: "ZHA",
        countryIata: "CNZHA",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Zhuhai Airport",
        city: "Zhuhai",
        iata: "ZUH",
        countryIata: "CNZUH",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Zunyi",
        city: "Zunyi",
        iata: "ZYI",
        countryIata: "CNZYI",
        country: "PR China",
        countryCode: "CN",
    },
    {
        airportName: "Antonio/Nery/Juarbe Pol",
        city: "Arecibo",
        iata: "ARE",
        countryIata: "PRARE",
        country: "Puerto Rico",
        countryCode: "PR",
    },
    {
        airportName: "Borinquen",
        city: "Aguadilla",
        iata: "BQN",
        countryIata: "PRBQN",
        country: "Puerto Rico",
        countryCode: "PR",
    },
    {
        airportName: "Culebra",
        city: "Culebra",
        iata: "CPX",
        countryIata: "PRCPX",
        country: "Puerto Rico",
        countryCode: "PR",
    },
    {
        airportName: "Dorado Beach",
        city: "Dorado",
        iata: "DDP",
        countryIata: "PRDDP",
        country: "Puerto Rico",
        countryCode: "PR",
    },
    {
        airportName: "Fajardo",
        city: "Fajardo",
        iata: "FAJ",
        countryIata: "PRFAJ",
        country: "Puerto Rico",
        countryCode: "PR",
    },
    {
        airportName: "Humacao Airport",
        city: "Humacao",
        iata: "HUC",
        countryIata: "PRHUC",
        country: "Puerto Rico",
        countryCode: "PR",
    },
    {
        airportName: "Eugenio María de Hostos Airport",
        city: "Mayaguez",
        iata: "MAZ",
        countryIata: "PRMAZ",
        country: "Puerto Rico",
        countryCode: "PR",
    },
    {
        airportName: "Roosevelt NAS",
        city: "Roosevelt Roads",
        iata: "NRR",
        countryIata: "PRNRR",
        country: "Puerto Rico",
        countryCode: "PR",
    },
    {
        airportName: "Palmas Del Mar",
        city: "Humacao",
        iata: "PPD",
        countryIata: "PRPPD",
        country: "Puerto Rico",
        countryCode: "PR",
    },
    {
        airportName: "Mercedita",
        city: "Ponce",
        iata: "PSE",
        countryIata: "PRPSE",
        country: "Puerto Rico",
        countryCode: "PR",
    },
    {
        airportName: "Isla Grande",
        city: "San Juan",
        iata: "SIG",
        countryIata: "PRSIG",
        country: "Puerto Rico",
        countryCode: "PR",
    },
    {
        airportName: "Luis Munoz Marin International",
        city: "San Juan",
        iata: "SJU",
        countryIata: "PRSJU",
        country: "Puerto Rico",
        countryCode: "PR",
    },
    {
        airportName: "Vieques",
        city: "Vieques",
        iata: "VQS",
        countryIata: "PRVQS",
        country: "Puerto Rico",
        countryCode: "PR",
    },
    {
        airportName: "Sri Guru Ram Dass Jee International (Punjab)",
        city: "Amritsar",
        iata: "ATQ",
        countryIata: "INATQ",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Doha",
        city: "Doha",
        iata: "DOH",
        countryIata: "QADOH",
        country: "Qatar",
        countryCode: "QA",
    },
    {
        airportName: "Zweibrücken Airport",
        city: "Zweibrücken",
        iata: "ZQW",
        countryIata: "DEZQW",
        country: "Rhineland-Palatinate, Germany",
        countryCode: "DE",
    },
    {
        airportName: "Arad",
        city: "Arad",
        iata: "ARW",
        countryIata: "ROARW",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Baia Mare",
        city: "Baia Mare",
        iata: "BAY",
        countryIata: "ROBAY",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Baneasa",
        city: "Bucharest",
        iata: "BBU",
        countryIata: "ROBBU",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Bacau",
        city: "Bacau",
        iata: "BCM",
        countryIata: "ROBCM",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Metropolitan Area",
        city: "Bucharest",
        iata: "BUH",
        countryIata: "ROBUH",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Napoca",
        city: "Cluj",
        iata: "CLJ",
        countryIata: "ROCLJ",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Mihail Kogălniceanu",
        city: "Constanta",
        iata: "CND",
        countryIata: "ROCND",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Craiova",
        city: "Craiova",
        iata: "CRA",
        countryIata: "ROCRA",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Caransebes",
        city: "Caransebes",
        iata: "CSB",
        countryIata: "ROCSB",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Deva",
        city: "Deva",
        iata: "DVA",
        countryIata: "RODVA",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Iasi",
        city: "Iasi",
        iata: "IAS",
        countryIata: "ROIAS",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Oradea",
        city: "Oradea",
        iata: "OMR",
        countryIata: "ROOMR",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Otopeni International",
        city: "Bucharest",
        iata: "OTP",
        countryIata: "ROOTP",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Sibiu",
        city: "Sibiu",
        iata: "SBZ",
        countryIata: "ROSBZ",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Salcea",
        city: "Suceava",
        iata: "SCV",
        countryIata: "ROSCV",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Satu Mare",
        city: "Satu Mare",
        iata: "SUJ",
        countryIata: "ROSUJ",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Tulcea",
        city: "Tulcea",
        iata: "TCE",
        countryIata: "ROTCE",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Tirgu Mures",
        city: "Tirgu Mures",
        iata: "TGM",
        countryIata: "ROTGM",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Timisoara",
        city: "Timisoara",
        iata: "TSR",
        countryIata: "ROTSR",
        country: "Romania",
        countryCode: "RO",
    },
    {
        airportName: "Vityazevo Airport",
        city: "Anapa",
        iata: "AAQ",
        countryIata: "RUAAQ",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Abakan International Airport",
        city: "Abakan",
        iata: "ABA",
        countryIata: "RUABA",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Achinsk",
        city: "Achinsk",
        iata: "ACS",
        countryIata: "RUACS",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Aldan",
        city: "Aldan",
        iata: "ADH",
        countryIata: "RUADH",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Adler-Sochi International Airport",
        city: "Adler/Sochi",
        iata: "AER",
        countryIata: "RUAER",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Amderma",
        city: "Amderma",
        iata: "AMV",
        countryIata: "RUAMV",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Talagi Airport",
        city: "Arkhangelsk",
        iata: "ARH",
        countryIata: "RUARH",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Astrakhan",
        city: "Astrakhan",
        iata: "ASF",
        countryIata: "RUASF",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Barnaul",
        city: "Barnaul",
        iata: "BAX",
        countryIata: "RUBAX",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Beloreck",
        city: "Beloreck",
        iata: "BCX",
        countryIata: "RUBCX",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Bykovo",
        city: "Moscow",
        iata: "BKA",
        countryIata: "RUBKA",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Blagoveschensk",
        city: "Blagoveschensk",
        iata: "BQS",
        countryIata: "RUBQS",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Bratsk",
        city: "Bratsk",
        iata: "BTK",
        countryIata: "RUBTK",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Balakovo",
        city: "Balakovo",
        iata: "BWO",
        countryIata: "RUBWO",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Briansk",
        city: "Briansk",
        iata: "BZK",
        countryIata: "RUBZK",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Cherepovets",
        city: "Cherepovets",
        iata: "CEE",
        countryIata: "RUCEE",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Chelyabinsk",
        city: "Chelyabinsk",
        iata: "CEK",
        countryIata: "RUCEK",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Chokurdah",
        city: "Chokurdah",
        iata: "CKH",
        countryIata: "RUCKH",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Chkalovsky",
        city: "Chkalovsky",
        iata: "CKL",
        countryIata: "RUCKL",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Chulman",
        city: "Chulman",
        iata: "CNN",
        countryIata: "RUCNN",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Cheboksary",
        city: "Cheboksary",
        iata: "CSY",
        countryIata: "RUCSY",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Cherskiy",
        city: "Cherskiy",
        iata: "CYX",
        countryIata: "RUCYX",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Dikson",
        city: "Dikson",
        iata: "DKS",
        countryIata: "RUDKS",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Domodedovo",
        city: "Moscow",
        iata: "DME",
        countryIata: "RUDME",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Anadyr",
        city: "Anadyr",
        iata: "DYR",
        countryIata: "RUDYR",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Belgorod",
        city: "Belgorod",
        iata: "EGO",
        countryIata: "RUEGO",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Eniseysk",
        city: "Eniseysk",
        iata: "EIE",
        countryIata: "RUEIE",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Elista",
        city: "Elista",
        iata: "ESL",
        countryIata: "RUESL",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Magdagachi",
        city: "Magdagachi",
        iata: "GDG",
        countryIata: "RUGDG",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Magadan",
        city: "Magadan",
        iata: "GDX",
        countryIata: "RUGDX",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Gelendzik",
        city: "Gelendzik",
        iata: "GDZ",
        countryIata: "RUGDZ",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Strigino International Airport",
        city: "Nizhniy Novgorod",
        iata: "GOJ",
        countryIata: "RUGOJ",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Groznyj",
        city: "Groznyj",
        iata: "GRV",
        countryIata: "RUGRV",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Chita",
        city: "Chita",
        iata: "HTA",
        countryIata: "RUHTA",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Hatanga",
        city: "Hatanga",
        iata: "HTG",
        countryIata: "RUHTG",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Igarka",
        city: "Igarka",
        iata: "IAA",
        countryIata: "RUIAA",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Yaroslavl",
        city: "Yaroslavl",
        iata: "IAR",
        countryIata: "RUIAR",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Izhevsk",
        city: "Izhevsk",
        iata: "IJK",
        countryIata: "RUIJK",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Tiksi",
        city: "Tiksi",
        iata: "IKS",
        countryIata: "RUIKS",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Irkutsk",
        city: "Irkutsk",
        iata: "IKT",
        countryIata: "RUIKT",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Inta",
        city: "Inta",
        iata: "INA",
        countryIata: "RUINA",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Ivanova",
        city: "Ivanova",
        iata: "IWA",
        countryIata: "RUIWA",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Joshkar-Ola",
        city: "Joshkar-Ola",
        iata: "JOK",
        countryIata: "RUJOK",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Kemerovo",
        city: "Kemerovo",
        iata: "KEJ",
        countryIata: "RUKEJ",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Khrabrovo Airport",
        city: "Kaliningrad",
        iata: "KGD",
        countryIata: "RUKGD",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Kogalym International",
        city: "Kogalym",
        iata: "KGP",
        countryIata: "RUKGP",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Novyy",
        city: "Khabarovsk",
        iata: "KHV",
        countryIata: "RUKHV",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Krasnojarsk",
        city: "Krasnojarsk",
        iata: "KJA",
        countryIata: "RUKJA",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Migalovo",
        city: "Kalinin",
        iata: "KLD",
        countryIata: "RUKLD",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Kaluga",
        city: "Kaluga",
        iata: "KLF",
        countryIata: "RUKLF",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Kostroma",
        city: "Kostroma",
        iata: "KMW",
        countryIata: "RUKMW",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Kurgan",
        city: "Kurgan",
        iata: "KRO",
        countryIata: "RUKRO",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Krasnodar",
        city: "Krasnodar",
        iata: "KRR",
        countryIata: "RUKRR",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Kotlas",
        city: "Kotlas",
        iata: "KSZ",
        countryIata: "RUKSZ",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Kurumoch International Airport",
        city: "Samara",
        iata: "KUF",
        countryIata: "RUKUF",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Kirovsk",
        city: "Kirovsk",
        iata: "KVK",
        countryIata: "RUKVK",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Kirov",
        city: "Kirov",
        iata: "KVX",
        countryIata: "RUKVX",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Komsomolsk Na Amure",
        city: "Komsomolsk Na Amure",
        iata: "KXK",
        countryIata: "RUKXK",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Kyzyl",
        city: "Kyzyl",
        iata: "KYZ",
        countryIata: "RUKYZ",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Kazan International Airport",
        city: "Kazan",
        iata: "KZN",
        countryIata: "RUKZN",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Pulkovo",
        city: "St Petersburg",
        iata: "LED",
        countryIata: "RULED",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Smolensk",
        city: "Smolensk",
        iata: "LNX",
        countryIata: "RULNX",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Lipetsk",
        city: "Lipetsk",
        iata: "LPK",
        countryIata: "RULPK",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Makhachkala",
        city: "Makhachkala",
        iata: "MCX",
        countryIata: "RUMCX",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Mirnyj",
        city: "Mirnyj",
        iata: "MJZ",
        countryIata: "RUMJZ",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Murmansk",
        city: "Murmansk",
        iata: "MMK",
        countryIata: "RUMMK",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Metropolitan Area",
        city: "Moscow",
        iata: "MOW",
        countryIata: "RUMOW",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Magnitogorsk",
        city: "Magnitogorsk",
        iata: "MQF",
        countryIata: "RUMQF",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Mineralnye Vody",
        city: "Mineralnye Vody",
        iata: "MRV",
        countryIata: "RUMRV",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Nalchik",
        city: "Nalchik",
        iata: "NAL",
        countryIata: "RUNAL",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Naberevnye Chelny",
        city: "Naberevnye Chelny",
        iata: "NBC",
        countryIata: "RUNBC",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Neftekamsk",
        city: "Neftekamsk",
        iata: "NEF",
        countryIata: "RUNEF",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Neryungri",
        city: "Neryungri",
        iata: "NER",
        countryIata: "RUNER",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Nefteyugansk",
        city: "Nefteyugansk",
        iata: "NFG",
        countryIata: "RUNFG",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Nizhnevartovsk",
        city: "Nizhnevartovsk",
        iata: "NJC",
        countryIata: "RUNJC",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Naryan-Mar",
        city: "Naryan-Mar",
        iata: "NNM",
        countryIata: "RUNNM",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Novorossijsk",
        city: "Novorossijsk",
        iata: "NOI",
        countryIata: "RUNOI",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Nojabrxsk",
        city: "Nojabrxsk",
        iata: "NOJ",
        countryIata: "RUNOJ",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Novokuznetsk",
        city: "Novokuznetsk",
        iata: "NOZ",
        countryIata: "RUNOZ",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Noril'sk",
        city: "Noril'sk",
        iata: "NSK",
        countryIata: "RUNSK",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Novy Urengoy",
        city: "Novy Urengoy",
        iata: "NUX",
        countryIata: "RUNUX",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Novgorod",
        city: "Novgorod",
        iata: "NVR",
        countryIata: "RUNVR",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Nadym",
        city: "Nadym",
        iata: "NYM",
        countryIata: "RUNYM",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Orel",
        city: "Orel",
        iata: "OEL",
        countryIata: "RUOEL",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Vladikavkaz",
        city: "Vladikavkaz",
        iata: "OGZ",
        countryIata: "RUOGZ",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Okhotsk",
        city: "Okhotsk",
        iata: "OHO",
        countryIata: "RUOHO",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Oktiabrskij",
        city: "Oktiabrskij",
        iata: "OKT",
        countryIata: "RUOKT",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Omsk Tsentralny Airport",
        city: "Omsk",
        iata: "OMS",
        countryIata: "RUOMS",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Orsk",
        city: "Orsk",
        iata: "OSW",
        countryIata: "RUOSW",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Tolmachevo",
        city: "Novosibirsk",
        iata: "OVB",
        countryIata: "RUOVB",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Perm",
        city: "Perm",
        iata: "PEE",
        countryIata: "RUPEE",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Petrozavodsk",
        city: "Petrozavodsk",
        iata: "PES",
        countryIata: "RUPES",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Pechora",
        city: "Pechora",
        iata: "PEX",
        countryIata: "RUPEX",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Penza",
        city: "Penza",
        iata: "PEZ",
        countryIata: "RUPEZ",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Petropavlovsk-Kamchats",
        city: "Petropavlovsk-Kamchats",
        iata: "PKC",
        countryIata: "RUPKC",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Pskov",
        city: "Pskov",
        iata: "PKV",
        countryIata: "RUPKV",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Provideniya",
        city: "Provideniya",
        iata: "PVS",
        countryIata: "RUPVS",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Provedenia",
        city: "Provedenia",
        iata: "PVX",
        countryIata: "RUPVX",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Pevek",
        city: "Pevek",
        iata: "PWE",
        countryIata: "RUPWE",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Polyarnyj",
        city: "Polyarnyj",
        iata: "PYJ",
        countryIata: "RUPYJ",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Raduzhnyi",
        city: "Raduzhnyi",
        iata: "RAT",
        countryIata: "RURAT",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Orenburg",
        city: "Orenburg",
        iata: "REN",
        countryIata: "RUREN",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Platov International Airport",
        city: "Rostov-on-Don",
        iata: "ROV",
        countryIata: "RUROV",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Saratov",
        city: "Saratov",
        iata: "RTW",
        countryIata: "RURTW",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Rzhevka",
        city: "St Petersburg",
        iata: "RVH",
        countryIata: "RURVH",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Rostov-on-Don Airport",
        city: "Rostov-on-Don",
        iata: "RVI",
        countryIata: "RURVI",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Rybinsk",
        city: "Rybinsk",
        iata: "RYB",
        countryIata: "RURYB",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Ryazan",
        city: "Ryazan",
        iata: "RZN",
        countryIata: "RURZN",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Syktyvkar",
        city: "Syktyvkar",
        iata: "SCW",
        countryIata: "RUSCW",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Surgut",
        city: "Surgut",
        iata: "SGC",
        countryIata: "RUSGC",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Saransk Airport",
        city: "Saransk",
        iata: "SKX",
        countryIata: "RUSKX",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Salehard",
        city: "Salehard",
        iata: "SLY",
        countryIata: "RUSLY",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Stavropol",
        city: "Stavropol",
        iata: "STW",
        countryIata: "RUSTW",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Sheremetyevo",
        city: "Moscow",
        iata: "SVO",
        countryIata: "RUSVO",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Koltsovo Airport",
        city: "Ekaterinburg (Yekaterinburg)",
        iata: "SVX",
        countryIata: "RUSVX",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Strzhewoi",
        city: "Strzhewoi",
        iata: "SWT",
        countryIata: "RUSWT",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Tambov",
        city: "Tambov",
        iata: "TBW",
        countryIata: "RUTBW",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Tyumen",
        city: "Tyumen",
        iata: "TJM",
        countryIata: "RUTJM",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Bogashevo Airport",
        city: "Tomsk",
        iata: "TOF",
        countryIata: "RUTOF",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Tobolsk",
        city: "Tobolsk",
        iata: "TOX",
        countryIata: "RUTOX",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Tula",
        city: "Tula",
        iata: "TYA",
        countryIata: "RUTYA",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Tynda",
        city: "Tynda",
        iata: "TYD",
        countryIata: "RUTYD",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Ukhta",
        city: "Ukhta",
        iata: "UCT",
        countryIata: "RUUCT",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Ufa International Airport",
        city: "Ufa",
        iata: "UFA",
        countryIata: "RUUFA",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Ust-Ilimsk",
        city: "Ust-Ilimsk",
        iata: "UIK",
        countryIata: "RUUIK",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Ust-Kut",
        city: "Ust-Kut",
        iata: "UKX",
        countryIata: "RUUKX",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Ulyanovsk",
        city: "Ulyanovsk",
        iata: "ULY",
        countryIata: "RUULY",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Uraj",
        city: "Uraj",
        iata: "URJ",
        countryIata: "RUURJ",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Kursk",
        city: "Kursk",
        iata: "URS",
        countryIata: "RUURS",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Usinsk",
        city: "Usinsk",
        iata: "USK",
        countryIata: "RUUSK",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Bugulma",
        city: "Bugulma",
        iata: "UUA",
        countryIata: "RUUUA",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Ulan-Ude",
        city: "Ulan-Ude",
        iata: "UUD",
        countryIata: "RUUUD",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Yuzhno-Sakhalinsk",
        city: "Yuzhno-Sakhalinsk",
        iata: "UUS",
        countryIata: "RUUUS",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Vologda",
        city: "Vologda",
        iata: "VGD",
        countryIata: "RUVGD",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Vnukovo",
        city: "Moscow",
        iata: "VKO",
        countryIata: "RUVKO",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Vorkuta",
        city: "Vorkuta",
        iata: "VKT",
        countryIata: "RUVKT",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Volgodonsk",
        city: "Volgodonsk",
        iata: "VLK",
        countryIata: "RUVLK",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Velikiye Luki",
        city: "Velikiye Luki",
        iata: "VLU",
        countryIata: "RUVLU",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Volgograd International Airport",
        city: "Volgograd",
        iata: "VOG",
        countryIata: "RUVOG",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Voronezh",
        city: "Voronezh",
        iata: "VOZ",
        countryIata: "RUVOZ",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Velikij Ustyug",
        city: "Velikij Ustyug",
        iata: "VUS",
        countryIata: "RUVUS",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Vladivostok",
        city: "Vladivostok",
        iata: "VVO",
        countryIata: "RUVVO",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Yakutsk",
        city: "Yakutsk",
        iata: "YKS",
        countryIata: "RUYKS",
        country: "Russia",
        countryCode: "RU",
    },
    {
        airportName: "Butare",
        city: "Butare",
        iata: "BTQ",
        countryIata: "RWBTQ",
        country: "Rwanda",
        countryCode: "RW",
    },
    {
        airportName: "Gisenyi",
        city: "Gisenyi",
        iata: "GYI",
        countryIata: "RWGYI",
        country: "Rwanda",
        countryCode: "RW",
    },
    {
        airportName: "Kigali International Airport",
        city: "Kigali",
        iata: "KGL",
        countryIata: "RWKGL",
        country: "Rwanda",
        countryCode: "RW",
    },
    {
        airportName: "Kamembe",
        city: "Kamembe",
        iata: "KME",
        countryIata: "RWKME",
        country: "Rwanda",
        countryCode: "RW",
    },
    {
        airportName: "Ruhengeri",
        city: "Ruhengeri",
        iata: "RHG",
        countryIata: "RWRHG",
        country: "Rwanda",
        countryCode: "RW",
    },
    {
        airportName: "Vigie",
        city: "Saint Lucia",
        iata: "SLU",
        countryIata: "LCSLU",
        country: "Saint Lucia",
        countryCode: "LC",
    },
    {
        airportName: "Hewanorra",
        city: "Saint Lucia",
        iata: "UVF",
        countryIata: "LCUVF",
        country: "Saint Lucia",
        countryCode: "LC",
    },
    {
        airportName: "Bequia Airport",
        city: "Port Elizabeth",
        iata: "BQU",
        countryIata: "VCBQU",
        country: "Saint Vincent and the Grenadines",
        countryCode: "VC",
    },
    {
        airportName: "Canouan Island",
        city: "Canouan Island",
        iata: "CIW",
        countryIata: "VCCIW",
        country: "Saint Vincent and the Grenadines",
        countryCode: "VC",
    },
    {
        airportName: "Mustique",
        city: "Mustique Island",
        iata: "MQS",
        countryIata: "VCMQS",
        country: "Saint Vincent and the Grenadines",
        countryCode: "VC",
    },
    {
        airportName: "Palm Island",
        city: "Palm Island",
        iata: "PLI",
        countryIata: "VCPLI",
        country: "Saint Vincent and the Grenadines",
        countryCode: "VC",
    },
    {
        airportName: "E.T. Joshua",
        city: "St Vincent",
        iata: "SVD",
        countryIata: "VCSVD",
        country: "Saint Vincent and the Grenadines",
        countryCode: "VC",
    },
    {
        airportName: "Union Island",
        city: "Union Island",
        iata: "UNI",
        countryIata: "VCUNI",
        country: "Saint Vincent and the Grenadines",
        countryCode: "VC",
    },
    {
        airportName: "Asau",
        city: "Asau",
        iata: "AAU",
        countryIata: "WSAAU",
        country: "Samoa",
        countryCode: "WS",
    },
    {
        airportName: "Faleolo International Airport",
        city: "Apia",
        iata: "APW",
        countryIata: "WSAPW",
        country: "Samoa",
        countryCode: "WS",
    },
    {
        airportName: "Fagali I",
        city: "Apia",
        iata: "FGI",
        countryIata: "WSFGI",
        country: "Samoa",
        countryCode: "WS",
    },
    {
        airportName: "Lalomalava",
        city: "Lalomalava",
        iata: "LAV",
        countryIata: "WSLAV",
        country: "Samoa",
        countryCode: "WS",
    },
    {
        airportName: "Maota Savaii Is",
        city: "Maota Savaii Is",
        iata: "MXS",
        countryIata: "WSMXS",
        country: "Samoa",
        countryCode: "WS",
    },
    {
        airportName: "San Marino",
        city: "San Marino",
        iata: "SAI",
        countryIata: "SMSAI",
        country: "San Marino",
        countryCode: "SM",
    },
    {
        airportName: "Principe",
        city: "Principe",
        iata: "PCP",
        countryIata: "STPCP",
        country: "Sao Tome and Principe",
        countryCode: "ST",
    },
    {
        airportName: "Porto Alegre",
        city: "Porto Alegre",
        iata: "PGP",
        countryIata: "STPGP",
        country: "Sao Tome and Principe",
        countryCode: "ST",
    },
    {
        airportName: "Sao Tome",
        city: "Sao Tome Island",
        iata: "TMS",
        countryIata: "STTMS",
        country: "Sao Tome and Principe",
        countryCode: "ST",
    },
    {
        airportName: "Al-Aqiq",
        city: "Al-Baha",
        iata: "ABT",
        countryIata: "SAABT",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Abha Regional Airport",
        city: "Abha",
        iata: "AHB",
        countryIata: "SAAHB",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Jouf",
        city: "Jouf",
        iata: "AJF",
        countryIata: "SAAJF",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Qaisumah",
        city: "Qaisumah",
        iata: "AQI",
        countryIata: "SAAQI",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Bisha",
        city: "Bisha",
        iata: "BHH",
        countryIata: "SABHH",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Dhahran",
        city: "Dhahran",
        iata: "DHA",
        countryIata: "SADHA",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "King Fahd International",
        city: "Dammam",
        iata: "DMM",
        countryIata: "SADMM",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Nejran",
        city: "Nejran",
        iata: "EAM",
        countryIata: "SAEAM",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Wedjh",
        city: "Wedjh",
        iata: "EJH",
        countryIata: "SAEJH",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Gassim",
        city: "Gassim",
        iata: "ELQ",
        countryIata: "SAELQ",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Gizan",
        city: "Gizan",
        iata: "GIZ",
        countryIata: "SAGIZ",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Hail",
        city: "Hail",
        iata: "HAS",
        countryIata: "SAHAS",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Hafr Albatin",
        city: "Hafr Albatin",
        iata: "HBT",
        countryIata: "SAHBT",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Al Hasa",
        city: "Hofuf",
        iata: "HOF",
        countryIata: "SAHOF",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "King Abdulaziz International",
        city: "Jeddah",
        iata: "JED",
        countryIata: "SAJED",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "King Khalid Military",
        city: "King Khalid Mil. City",
        iata: "KMC",
        countryIata: "SAKMC",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Khamis Mushait",
        city: "Khamis Mushait",
        iata: "KMX",
        countryIata: "SAKMX",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Mohammad Bin Abdulaziz Airport",
        city: "Medina (Madinah)",
        iata: "MED",
        countryIata: "SAMED",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Majma",
        city: "Majma",
        iata: "MJH",
        countryIata: "SAMJH",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Arar",
        city: "Arar",
        iata: "RAE",
        countryIata: "SARAE",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Rafha",
        city: "Rafha",
        iata: "RAH",
        countryIata: "SARAH",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "King Khaled International Airport",
        city: "Riyadh",
        iata: "RUH",
        countryIata: "SARUH",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Sharurah",
        city: "Sharurah",
        iata: "SHW",
        countryIata: "SASHW",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Sulayel",
        city: "Sulayel",
        iata: "SLF",
        countryIata: "SASLF",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Taif",
        city: "Taif",
        iata: "TIF",
        countryIata: "SATIF",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Turaif",
        city: "Turaif",
        iata: "TUI",
        countryIata: "SATUI",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Tabuk",
        city: "Tabuk",
        iata: "TUU",
        countryIata: "SATUU",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Gurayat",
        city: "Gurayat",
        iata: "URY",
        countryIata: "SAURY",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Unayzah",
        city: "Unayzah",
        iata: "UZH",
        countryIata: "SAUZH",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Wadi Ad Dawasir Airport",
        city: "Wadi Ad Dawasir",
        iata: "WAE",
        countryIata: "SAWAE",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Yanbu",
        city: "Yanbu",
        iata: "YNB",
        countryIata: "SAYNB",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Zilfi",
        city: "Zilfi",
        iata: "ZUL",
        countryIata: "SAZUL",
        country: "Saudi Arabia",
        countryCode: "SA",
    },
    {
        airportName: "Heligoland Airport (Helgoland-Düne Airport)",
        city: "Helgoland",
        iata: "HGL",
        countryIata: "DEHGL",
        country: "Schleswig-Holstein, Germany",
        countryCode: "DE",
    },
    {
        airportName: "Kiel Airport (Kiel Holtenau Airport)",
        city: "Kiel",
        iata: "KEL",
        countryIata: "DEKEL",
        country: "Schleswig-Holstein, Germany",
        countryCode: "DE",
    },
    {
        airportName: "Bakel",
        city: "Bakel",
        iata: "BXE",
        countryIata: "SNBXE",
        country: "Senegal",
        countryCode: "SN",
    },
    {
        airportName: "Cap Skirring",
        city: "Cap Skirring",
        iata: "CSK",
        countryIata: "SNCSK",
        country: "Senegal",
        countryCode: "SN",
    },
    {
        airportName: "Yoff",
        city: "Dakar",
        iata: "DKR",
        countryIata: "SNDKR",
        country: "Senegal",
        countryCode: "SN",
    },
    {
        airportName: "Kolda",
        city: "Kolda",
        iata: "KDA",
        countryIata: "SNKDA",
        country: "Senegal",
        countryCode: "SN",
    },
    {
        airportName: "Kedougou",
        city: "Kedougou",
        iata: "KGG",
        countryIata: "SNKGG",
        country: "Senegal",
        countryCode: "SN",
    },
    {
        airportName: "Kaolack",
        city: "Kaolack",
        iata: "KLC",
        countryIata: "SNKLC",
        country: "Senegal",
        countryCode: "SN",
    },
    {
        airportName: "Ouro Sogui airfield",
        city: "Matam",
        iata: "MAX",
        countryIata: "SNMAX",
        country: "Senegal",
        countryCode: "SN",
    },
    {
        airportName: "Niokolo Koba",
        city: "Niokolo Koba",
        iata: "NIK",
        countryIata: "SNNIK",
        country: "Senegal",
        countryCode: "SN",
    },
    {
        airportName: "Podor",
        city: "Podor",
        iata: "POD",
        countryIata: "SNPOD",
        country: "Senegal",
        countryCode: "SN",
    },
    {
        airportName: "Richard Toll",
        city: "Richard Toll",
        iata: "RDT",
        countryIata: "SNRDT",
        country: "Senegal",
        countryCode: "SN",
    },
    {
        airportName: "Simenti",
        city: "Simenti",
        iata: "SMY",
        countryIata: "SNSMY",
        country: "Senegal",
        countryCode: "SN",
    },
    {
        airportName: "Tambacounda",
        city: "Tambacounda",
        iata: "TUD",
        countryIata: "SNTUD",
        country: "Senegal",
        countryCode: "SN",
    },
    {
        airportName: "St Louis",
        city: "St Louis",
        iata: "XLS",
        countryIata: "SNXLS",
        country: "Senegal",
        countryCode: "SN",
    },
    {
        airportName: "Ziguinchor",
        city: "Ziguinchor",
        iata: "ZIG",
        countryIata: "SNZIG",
        country: "Senegal",
        countryCode: "SN",
    },
    {
        airportName: "Aerodrom Beograd",
        city: "Belgrade",
        iata: "BEG",
        countryIata: "RSBEG",
        country: "Serbia",
        countryCode: "RS",
    },
    {
        airportName: "Batajnica",
        city: "Belgrade",
        iata: "BJY",
        countryIata: "RSBJY",
        country: "Serbia",
        countryCode: "RS",
    },
    {
        airportName: "Nis",
        city: "Nis",
        iata: "INI",
        countryIata: "RSINI",
        country: "Serbia",
        countryCode: "RS",
    },
    {
        airportName: "Pristina",
        city: "Pristina",
        iata: "PRN",
        countryIata: "RSPRN",
        country: "Serbia",
        countryCode: "RS",
    },
    {
        airportName: "Bird Island",
        city: "Bird Island",
        iata: "BDI",
        countryIata: "SCBDI",
        country: "Seychelles",
        countryCode: "SC",
    },
    {
        airportName: "Denis Island",
        city: "Denis Island",
        iata: "DEI",
        countryIata: "SCDEI",
        country: "Seychelles",
        countryCode: "SC",
    },
    {
        airportName: "Desroches",
        city: "Desroches",
        iata: "DES",
        countryIata: "SCDES",
        country: "Seychelles",
        countryCode: "SC",
    },
    {
        airportName: "Fregate Island",
        city: "Fregate Island",
        iata: "FRK",
        countryIata: "SCFRK",
        country: "Seychelles",
        countryCode: "SC",
    },
    {
        airportName: "Praslin Island",
        city: "Praslin Island",
        iata: "PRI",
        countryIata: "SCPRI",
        country: "Seychelles",
        countryCode: "SC",
    },
    {
        airportName: "Seychelles International",
        city: "Mahe Island",
        iata: "SEZ",
        countryIata: "SCSEZ",
        country: "Seychelles",
        countryCode: "SC",
    },
    {
        airportName: "Bonthe",
        city: "Bonthe",
        iata: "BTE",
        countryIata: "SLBTE",
        country: "Sierra Leone",
        countryCode: "SL",
    },
    {
        airportName: "Daru",
        city: "Daru",
        iata: "DSL",
        countryIata: "SLDSL",
        country: "Sierra Leone",
        countryCode: "SL",
    },
    {
        airportName: "Lungi International",
        city: "Freetown",
        iata: "FNA",
        countryIata: "SLFNA",
        country: "Sierra Leone",
        countryCode: "SL",
    },
    {
        airportName: "Gbangbatok",
        city: "Gbangbatok",
        iata: "GBK",
        countryIata: "SLGBK",
        country: "Sierra Leone",
        countryCode: "SL",
    },
    {
        airportName: "Hastings",
        city: "Freetown",
        iata: "HGS",
        countryIata: "SLHGS",
        country: "Sierra Leone",
        countryCode: "SL",
    },
    {
        airportName: "Mammy Yoko Heliport",
        city: "Freetown",
        iata: "JMY",
        countryIata: "SLJMY",
        country: "Sierra Leone",
        countryCode: "SL",
    },
    {
        airportName: "Kabala",
        city: "Kabala",
        iata: "KBA",
        countryIata: "SLKBA",
        country: "Sierra Leone",
        countryCode: "SL",
    },
    {
        airportName: "Bo",
        city: "Bo",
        iata: "KBS",
        countryIata: "SLKBS",
        country: "Sierra Leone",
        countryCode: "SL",
    },
    {
        airportName: "Kenema",
        city: "Kenema",
        iata: "KEN",
        countryIata: "SLKEN",
        country: "Sierra Leone",
        countryCode: "SL",
    },
    {
        airportName: "Sierra Leone",
        city: "Sierra Leone",
        iata: "SRK",
        countryIata: "SLSRK",
        country: "Sierra Leone",
        countryCode: "SL",
    },
    {
        airportName: "Yengema",
        city: "Yengema",
        iata: "WYE",
        countryIata: "SLWYE",
        country: "Sierra Leone",
        countryCode: "SL",
    },
    {
        airportName: "Paya Lebar AB",
        city: "Singapore",
        iata: "QPG",
        countryIata: "SGQPG",
        country: "Singapore",
        countryCode: "SG",
    },
    {
        airportName: "Changi Airport",
        city: "Singapore",
        iata: "SIN",
        countryIata: "SGSIN",
        country: "Singapore",
        countryCode: "SG",
    },
    {
        airportName: "Seletar",
        city: "Singapore",
        iata: "XSP",
        countryIata: "SGXSP",
        country: "Singapore",
        countryCode: "SG",
    },
    {
        airportName: "Ivanka",
        city: "Bratislava",
        iata: "BTS",
        countryIata: "SKBTS",
        country: "Slovakia",
        countryCode: "SK",
    },
    {
        airportName: "Zilina",
        city: "Zilina",
        iata: "ILZ",
        countryIata: "SKILZ",
        country: "Slovakia",
        countryCode: "SK",
    },
    {
        airportName: "Barca",
        city: "Kosice",
        iata: "KSC",
        countryIata: "SKKSC",
        country: "Slovakia",
        countryCode: "SK",
    },
    {
        airportName: "Lucenec",
        city: "Lucenec",
        iata: "LUE",
        countryIata: "SKLUE",
        country: "Slovakia",
        countryCode: "SK",
    },
    {
        airportName: "Presov",
        city: "Presov",
        iata: "POV",
        countryIata: "SKPOV",
        country: "Slovakia",
        countryCode: "SK",
    },
    {
        airportName: "Piestany",
        city: "Piestany",
        iata: "PZY",
        countryIata: "SKPZY",
        country: "Slovakia",
        countryCode: "SK",
    },
    {
        airportName: "Sliac",
        city: "Sliac",
        iata: "SLD",
        countryIata: "SKSLD",
        country: "Slovakia",
        countryCode: "SK",
    },
    {
        airportName: "Poprad/Tatry",
        city: "Poprad/Tatry",
        iata: "TAT",
        countryIata: "SKTAT",
        country: "Slovakia",
        countryCode: "SK",
    },
    {
        airportName: "Brnik",
        city: "Ljubljana",
        iata: "LJU",
        countryIata: "SILJU",
        country: "Slovenia",
        countryCode: "SI",
    },
    {
        airportName: "Maribor",
        city: "Maribor",
        iata: "MBX",
        countryIata: "SIMBX",
        country: "Slovenia",
        countryCode: "SI",
    },
    {
        airportName: "Portoroz",
        city: "Portoroz",
        iata: "POW",
        countryIata: "SIPOW",
        country: "Slovenia",
        countryCode: "SI",
    },
    {
        airportName: "Afutara Aerodrome",
        city: "Afutara",
        iata: "AFT",
        countryIata: "SBAFT",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Gwaunaru'u",
        city: "Auki",
        iata: "AKS",
        countryIata: "SBAKS",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Anuha Island Resort",
        city: "Anuha Island Resort",
        iata: "ANH",
        countryIata: "SBANH",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Atoifi",
        city: "Atoifi",
        iata: "ATD",
        countryIata: "SBATD",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Avu Avu",
        city: "Avu Avu",
        iata: "AVU",
        countryIata: "SBAVU",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Balalae",
        city: "Balalae",
        iata: "BAS",
        countryIata: "SBBAS",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Bellona",
        city: "Bellona",
        iata: "BNY",
        countryIata: "SBBNY",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Batuna Aerodrome",
        city: "Batuna",
        iata: "BPF",
        countryIata: "SBBPF",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Choiseul Bay",
        city: "Choiseul Bay",
        iata: "CHY",
        countryIata: "SBCHY",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Sege",
        city: "Sege",
        iata: "EGM",
        countryIata: "SBEGM",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Fera Island",
        city: "Fera Island",
        iata: "FRE",
        countryIata: "SBFRE",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Geva Airstrip",
        city: "Geva",
        iata: "GEF",
        countryIata: "SBGEF",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Guadalcanal",
        city: "Guadalcanal",
        iata: "GSI",
        countryIata: "SBGSI",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Gatokae Aerodrom",
        city: "Gatokae",
        iata: "GTA",
        countryIata: "SBGTA",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Gizo",
        city: "Gizo",
        iata: "GZO",
        countryIata: "SBGZO",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Henderson International",
        city: "Honiara",
        iata: "HIR",
        countryIata: "SBHIR",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Kirakira",
        city: "Kirakira",
        iata: "IRA",
        countryIata: "SBIRA",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Kagau",
        city: "Kagau",
        iata: "KGE",
        countryIata: "SBKGE",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Kukundu",
        city: "Kukundu",
        iata: "KUE",
        countryIata: "SBKUE",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Kwai Harbour",
        city: "Kwai Harbour",
        iata: "KWR",
        countryIata: "SBKWR",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Kwailabesi Aerodrom",
        city: "Kwailabesi",
        iata: "KWS",
        countryIata: "SBKWS",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Mbambanakira",
        city: "Mbambanakira",
        iata: "MBU",
        countryIata: "SBMBU",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Mono",
        city: "Mono",
        iata: "MNY",
        countryIata: "SBMNY",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Munda",
        city: "Munda",
        iata: "MUA",
        countryIata: "SBMUA",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Santa Ana",
        city: "Santa Ana",
        iata: "NNB",
        countryIata: "SBNNB",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Onepusu",
        city: "Onepusu",
        iata: "ONE",
        countryIata: "SBONE",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Ontong Java",
        city: "Ontong Java",
        iata: "OTV",
        countryIata: "SBOTV",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Parasi",
        city: "Parasi",
        iata: "PRS",
        countryIata: "SBPRS",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Ramata",
        city: "Ramata",
        iata: "RBV",
        countryIata: "SBRBV",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Ringi Cove",
        city: "Ringi Cove",
        iata: "RIN",
        countryIata: "SBRIN",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Ulawa Airport",
        city: "Arona",
        iata: "RNA",
        countryIata: "SBRNA",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Rennell",
        city: "Rennell",
        iata: "RNL",
        countryIata: "SBRNL",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Barora",
        city: "Barora",
        iata: "RRI",
        countryIata: "SBRRI",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Marau Sound",
        city: "Marau Sound",
        iata: "RUS",
        countryIata: "SBRUS",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Santa Cruz Island",
        city: "Santa Cruz Island",
        iata: "SCZ",
        countryIata: "SBSCZ",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Savo",
        city: "Savo",
        iata: "SVY",
        countryIata: "SBSVY",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Tarapaina",
        city: "Tarapaina",
        iata: "TAA",
        countryIata: "SBTAA",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Tulagi Island",
        city: "Tulagi Island",
        iata: "TLG",
        countryIata: "SBTLG",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Suavanao Airstrip",
        city: "Suavanao",
        iata: "VAO",
        countryIata: "SBVAO",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Barakoma",
        city: "Barakoma",
        iata: "VEV",
        countryIata: "SBVEV",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Viru Harbour Airstrip",
        city: "Viru",
        iata: "VIU",
        countryIata: "SBVIU",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Yandina Airport",
        city: "Yandina",
        iata: "XYA",
        countryIata: "SBXYA",
        country: "Solomon Islands",
        countryCode: "SB",
    },
    {
        airportName: "Alula",
        city: "Alula",
        iata: "ALU",
        countryIata: "SOALU",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Berbera",
        city: "Berbera",
        iata: "BBO",
        countryIata: "SOBBO",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Baidoa",
        city: "Baidoa",
        iata: "BIB",
        countryIata: "SOBIB",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Bossaso",
        city: "Bossaso",
        iata: "BSA",
        countryIata: "SOBSA",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Bardera",
        city: "Bardera",
        iata: "BSY",
        countryIata: "SOBSY",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Burao",
        city: "Burao",
        iata: "BUO",
        countryIata: "SOBUO",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Borama",
        city: "Borama",
        iata: "BXX",
        countryIata: "SOBXX",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Obbia",
        city: "Obbia",
        iata: "CMO",
        countryIata: "SOCMO",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Scusciuban",
        city: "Scusciuban",
        iata: "CMS",
        countryIata: "SOCMS",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Candala",
        city: "Candala",
        iata: "CXN",
        countryIata: "SOCXN",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Erigavo",
        city: "Erigavo",
        iata: "ERA",
        countryIata: "SOERA",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Garbaharey",
        city: "Garbaharey",
        iata: "GBM",
        countryIata: "SOGBM",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Garoe",
        city: "Garoe",
        iata: "GGR",
        countryIata: "SOGGR",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Galcaio",
        city: "Galcaio",
        iata: "GLK",
        countryIata: "SOGLK",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Gardo",
        city: "Gardo",
        iata: "GSR",
        countryIata: "SOGSR",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Eil",
        city: "Eil",
        iata: "HCM",
        countryIata: "SOHCM",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Hargeisa",
        city: "Hargeisa",
        iata: "HGA",
        countryIata: "SOHGA",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Kismayu",
        city: "Kismayu",
        iata: "KMU",
        countryIata: "SOKMU",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Lugh Ganane",
        city: "Lugh Ganane",
        iata: "LGX",
        countryIata: "SOLGX",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Las Khoreh",
        city: "Las Khoreh",
        iata: "LKR",
        countryIata: "SOLKR",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Aden Adde International Airport",
        city: "Mogadishu",
        iata: "MGQ",
        countryIata: "SOMGQ",
        country: "Somalia",
        countryCode: "SO",
    },
    {
        airportName: "Mala Mala",
        city: "Mala Mala",
        iata: "AAM",
        countryIata: "ZAAAM",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Alldays",
        city: "Alldays",
        iata: "ADY",
        countryIata: "ZAADY",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Port Alfred",
        city: "Port Alfred",
        iata: "AFD",
        countryIata: "ZAAFD",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Aggeneys Airport",
        city: "Aggeneys",
        iata: "AGZ",
        countryIata: "ZAAGZ",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Kortdoorn",
        city: "Alexander Bay",
        iata: "ALJ",
        countryIata: "ZAALJ",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Bloemfontein International",
        city: "Bloemfontein",
        iata: "BFN",
        countryIata: "ZABFN",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Bisho",
        city: "Bisho",
        iata: "BIY",
        countryIata: "ZABIY",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Cradock",
        city: "Cradock",
        iata: "CDO",
        countryIata: "ZACDO",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Cape Town International",
        city: "Cape Town",
        iata: "CPT",
        countryIata: "ZACPT",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Dukuduk",
        city: "Dukuduk",
        iata: "DUK",
        countryIata: "ZADUK",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Durban International",
        city: "Durban",
        iata: "DUR",
        countryIata: "ZADUR",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Ellisras",
        city: "Ellisras",
        iata: "ELL",
        countryIata: "ZAELL",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "East London",
        city: "East London",
        iata: "ELS",
        countryIata: "ZAELS",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Empangeni",
        city: "Empangeni",
        iata: "EMG",
        countryIata: "ZAEMG",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Ficksburg Sentra Oes",
        city: "Ficksburg",
        iata: "FCB",
        countryIata: "ZAFCB",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Grand Central",
        city: "Johannesburg",
        iata: "GCJ",
        countryIata: "ZAGCJ",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Giyani",
        city: "Giyani",
        iata: "GIY",
        countryIata: "ZAGIY",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "George",
        city: "George",
        iata: "GRJ",
        countryIata: "ZAGRJ",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Sabi Sabi",
        city: "Sabi Sabi",
        iata: "GSS",
        countryIata: "ZAGSS",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Heliport",
        city: "Babelegi",
        iata: "HBL",
        countryIata: "ZAHBL",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Randburg Heliport",
        city: "Johannesburg",
        iata: "HCS",
        countryIata: "ZAHCS",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Hoedspruit Airport",
        city: "Hoedspruit",
        iata: "HDS",
        countryIata: "ZAHDS",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Iscor Heliport",
        city: "Pretoria",
        iata: "HIC",
        countryIata: "ZAHIC",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Lanseria",
        city: "Lanseria",
        iata: "HLA",
        countryIata: "ZAHLA",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Hluhluwe",
        city: "Hluhluwe",
        iata: "HLW",
        countryIata: "ZAHLW",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Central Hpr",
        city: "Pretoria",
        iata: "HPR",
        countryIata: "ZAHPR",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Harrismith Airport",
        city: "Harrismith",
        iata: "HRS",
        countryIata: "ZAHRS",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Hazyview",
        city: "Hazyview",
        iata: "HZV",
        countryIata: "ZAHZV",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Inyati",
        city: "Inyati",
        iata: "INY",
        countryIata: "ZAINY",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Johannesburg International",
        city: "Johannesburg",
        iata: "JNB",
        countryIata: "ZAJNB",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Port Saint Johns",
        city: "Port Saint Johns",
        iata: "JOH",
        countryIata: "ZAJOH",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Khoka Moya",
        city: "Khoka Moya",
        iata: "KHO",
        countryIata: "ZAKHO",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Koinghaas",
        city: "Koinghaas",
        iata: "KIG",
        countryIata: "ZAKIG",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Kimberley",
        city: "Kimberley",
        iata: "KIM",
        countryIata: "ZAKIM",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Kleinzee",
        city: "Kleinzee",
        iata: "KLZ",
        countryIata: "ZAKLZ",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Kuruman",
        city: "Kuruman",
        iata: "KMH",
        countryIata: "ZAKMH",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Komatipoort",
        city: "Komatipoort",
        iata: "KOF",
        countryIata: "ZAKOF",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Klerksdorp",
        city: "Klerksdorp",
        iata: "KXE",
        countryIata: "ZAKXE",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Ladysmith",
        city: "Ladysmith",
        iata: "LAY",
        countryIata: "ZALAY",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Louis Trichardt",
        city: "Louis Trichardt",
        iata: "LCD",
        countryIata: "ZALCD",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Londolozi",
        city: "Londolozi",
        iata: "LDZ",
        countryIata: "ZALDZ",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Malelane",
        city: "Malelane",
        iata: "LLE",
        countryIata: "ZALLE",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Lime Acres",
        city: "Lime Acres",
        iata: "LMR",
        countryIata: "ZALMR",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Letaba",
        city: "Tzaneen",
        iata: "LTA",
        countryIata: "ZALTA",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Lusikisiki",
        city: "Lusikisiki",
        iata: "LUJ",
        countryIata: "ZALUJ",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "International",
        city: "Mmabatho",
        iata: "MBD",
        countryIata: "ZAMBD",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Mkambati",
        city: "Mkambati",
        iata: "MBM",
        countryIata: "ZAMBM",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Messina",
        city: "Messina",
        iata: "MEZ",
        countryIata: "ZAMEZ",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Margate",
        city: "Margate",
        iata: "MGH",
        countryIata: "ZAMGH",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Kruger Mpumalanga International",
        city: "Nelspruit",
        iata: "MQP",
        countryIata: "ZAMQP",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Motswari Airfield",
        city: "Motswari",
        iata: "MWR",
        countryIata: "ZAMWR",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Mzamba, Wild Coast Sun",
        city: "Mzamba",
        iata: "MZF",
        countryIata: "ZAMZF",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Mkuze",
        city: "Mkuze",
        iata: "MZQ",
        countryIata: "ZAMZQ",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Mossel Bay",
        city: "Mossel Bay",
        iata: "MZY",
        countryIata: "ZAMZY",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Newcastle",
        city: "Newcastle",
        iata: "NCS",
        countryIata: "ZANCS",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Ngala Airfield",
        city: "Ngala",
        iata: "NGL",
        countryIata: "ZANGL",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Nelspruit Airport (replaced by the Kruger Mpumalanga International)",
        city: "Nelspruit (Mbombela)",
        iata: "NLP",
        countryIata: "ZANLP",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Pilansberg",
        city: "Sun City",
        iata: "NTY",
        countryIata: "ZANTY",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Oudtshoorn",
        city: "Oudtshoorn",
        iata: "OUH",
        countryIata: "ZAOUH",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Plettenberg Bay",
        city: "Plettenberg Bay",
        iata: "PBZ",
        countryIata: "ZAPBZ",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Phalaborwa",
        city: "Phalaborwa",
        iata: "PHW",
        countryIata: "ZAPHW",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Port Elizabeth",
        city: "Port Elizabeth",
        iata: "PLZ",
        countryIata: "ZAPLZ",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Prieska",
        city: "Prieska",
        iata: "PRK",
        countryIata: "ZAPRK",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Wonderboom Airport",
        city: "Pretoria",
        iata: "PRY",
        countryIata: "ZAPRY",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Pietersburg",
        city: "Pietersburg",
        iata: "PTG",
        countryIata: "ZAPTG",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Pietermaritzburg",
        city: "Pietermaritzburg",
        iata: "PZB",
        countryIata: "ZAPZB",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Zulu Inyala",
        city: "Phinda",
        iata: "PZL",
        countryIata: "ZAPZL",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Rand Germiston Airport",
        city: "Johannesburg",
        iata: "QRA",
        countryIata: "ZAQRA",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Richards Bay",
        city: "Richards Bay",
        iata: "RCB",
        countryIata: "ZARCB",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Robertson",
        city: "Robertson",
        iata: "ROD",
        countryIata: "ZAROD",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Reivilo",
        city: "Reivilo",
        iata: "RVO",
        countryIata: "ZARVO",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Springbok",
        city: "Springbok",
        iata: "SBU",
        countryIata: "ZASBU",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Langebaanweg",
        city: "Saldanha Bay",
        iata: "SDB",
        countryIata: "ZASDB",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Sishen",
        city: "Sishen",
        iata: "SIS",
        countryIata: "ZASIS",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Skukuza",
        city: "Skukuza",
        iata: "SZK",
        countryIata: "ZASZK",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Thaba Nchu",
        city: "Thaba Nchu",
        iata: "TCU",
        countryIata: "ZATCU",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Tanda Tula",
        city: "Tanda Tula",
        iata: "TDT",
        countryIata: "ZATDT",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Thohoyandou",
        city: "Thohoyandou",
        iata: "THY",
        countryIata: "ZATHY",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Tshipise",
        city: "Tshipise",
        iata: "TSD",
        countryIata: "ZATSD",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Ulundi",
        city: "Ulundi",
        iata: "ULD",
        countryIata: "ZAULD",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Ulusaba",
        city: "Ulusaba",
        iata: "ULX",
        countryIata: "ZAULX",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Butterworth",
        city: "Butterworth",
        iata: "UTE",
        countryIata: "ZAUTE",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Upington",
        city: "Upington",
        iata: "UTN",
        countryIata: "ZAUTN",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Umtata",
        city: "Umtata",
        iata: "UTT",
        countryIata: "ZAUTT",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Queenstown",
        city: "Queenstown",
        iata: "UTW",
        countryIata: "ZAUTW",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Virginia",
        city: "Durban",
        iata: "VIR",
        countryIata: "ZAVIR",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Vredendal",
        city: "Vredendal",
        iata: "VRE",
        countryIata: "ZAVRE",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Vryburg",
        city: "Vryburg",
        iata: "VRU",
        countryIata: "ZAVRU",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Vryheid",
        city: "Vryheid",
        iata: "VYD",
        countryIata: "ZAVYD",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Welkom",
        city: "Welkom",
        iata: "WEL",
        countryIata: "ZAWEL",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Secunda",
        city: "Secunda",
        iata: "ZEC",
        countryIata: "ZAZEC",
        country: "South Africa",
        countryCode: "ZA",
    },
    {
        airportName: "Chinhae",
        city: "Chinhae",
        iata: "CHF",
        countryIata: "KRCHF",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Chonju",
        city: "Chonju",
        iata: "CHN",
        countryIata: "KRCHN",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Cheong Ju Airport",
        city: "Cheong Ju City",
        iata: "CJJ",
        countryIata: "KRCJJ",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Cheju Airport",
        city: "Cheju",
        iata: "CJU",
        countryIata: "KRCJU",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Gimpo International",
        city: "Seoul",
        iata: "GMP",
        countryIata: "KRGMP",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Sacheon",
        city: "Chinju",
        iata: "HIN",
        countryIata: "KRHIN",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Incheon International Airport",
        city: "Seoul",
        iata: "ICN",
        countryIata: "KRICN",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Chu Ja Heliport",
        city: "Cheju",
        iata: "JCJ",
        countryIata: "KRJCJ",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Heliport",
        city: "Incheon",
        iata: "JCN",
        countryIata: "KRJCN",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Heliport",
        city: "Geoje",
        iata: "JGE",
        countryIata: "KRJGE",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Sogwipo Heliport",
        city: "Cheju",
        iata: "JSP",
        countryIata: "KRJSP",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Jungwon AB",
        city: "Chungju",
        iata: "JWO",
        countryIata: "KRJWO",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Kangnung",
        city: "Kangnung",
        iata: "KAG",
        countryIata: "KRKAG",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Pohang",
        city: "Pohang",
        iata: "KPO",
        countryIata: "KRKPO",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Kunsan",
        city: "Kunsan",
        iata: "KUV",
        countryIata: "KRKUV",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Airbase",
        city: "Kusan",
        iata: "KUZ",
        countryIata: "KRKUZ",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Kwangju",
        city: "Kwangju",
        iata: "KWJ",
        countryIata: "KRKWJ",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Mokpo",
        city: "Mokpo",
        iata: "MPK",
        countryIata: "KRMPK",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Ab",
        city: "Osan",
        iata: "OSN",
        countryIata: "KROSN",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Kimhae",
        city: "Pusan",
        iata: "PUS",
        countryIata: "KRPUS",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Yeosu",
        city: "Yeosu",
        iata: "RSU",
        countryIata: "KRRSU",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Metropolitan Area",
        city: "Seoul",
        iata: "SEL",
        countryIata: "KRSEL",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Solak",
        city: "Sokcho",
        iata: "SHO",
        countryIata: "KRSHO",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Seoul Ab",
        city: "Seoul",
        iata: "SSN",
        countryIata: "KRSSN",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Samchok",
        city: "Samchok",
        iata: "SUK",
        countryIata: "KRSUK",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Su Won Airport",
        city: "Su Won City",
        iata: "SWU",
        countryIata: "KRSWU",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Yosu",
        city: "Sunchon",
        iata: "SYS",
        countryIata: "KRSYS",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Daegu International Airport",
        city: "Daegu",
        iata: "TAE",
        countryIata: "KRTAE",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Ulsan",
        city: "Ulsan",
        iata: "USN",
        countryIata: "KRUSN",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "WonJu",
        city: "WonJu",
        iata: "WJU",
        countryIata: "KRWJU",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Yechon",
        city: "Yechon",
        iata: "YEC",
        countryIata: "KRYEC",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Yangyang International Airport",
        city: "Gangneung",
        iata: "YNY",
        countryIata: "KRYNY",
        country: "South Korea (the Republic of)",
        countryCode: "KR",
    },
    {
        airportName: "Juba International Airport",
        city: "Juba",
        iata: "JUB",
        countryIata: "SSJUB",
        country: "South Sudan",
        countryCode: "SS",
    },
    {
        airportName: "Los Llanos",
        city: "Albacete",
        iata: "ABC",
        countryIata: "ESABC",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Málaga–Costa del Sol Airport[",
        city: "Málaga",
        iata: "AGP",
        countryIata: "ESAGP",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "El Altet Airport",
        city: "Alicante",
        iata: "ALC",
        countryIata: "ESALC",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Barcelona–El Prat Airport",
        city: "Barcelona",
        iata: "BCN",
        countryIata: "ESBCN",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Bilbao",
        city: "Bilbao",
        iata: "BIO",
        countryIata: "ESBIO",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Talaveral La Real",
        city: "Badajoz",
        iata: "BJZ",
        countryIata: "ESBJZ",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Ciudad Real International Airport S. L.",
        city: "Ciudad Real",
        iata: "CQM",
        countryIata: "ESCQM",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "San Sebastián Airport",
        city: "San Sebastián",
        iata: "EAS",
        countryIata: "ESEAS",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Costa Brava",
        city: "Gerona",
        iata: "GRO",
        countryIata: "ESGRO",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Granada",
        city: "Granada",
        iata: "GRX",
        countryIata: "ESGRX",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Ceuta Heliport",
        city: "Ceuta",
        iata: "JCU",
        countryIata: "ESJCU",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "La Coruna",
        city: "La Coruna",
        iata: "LCG",
        countryIata: "ESLCG",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Almeria",
        city: "Almeria",
        iata: "LEI",
        countryIata: "ESLEI",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Leon",
        city: "Leon",
        iata: "LEN",
        countryIata: "ESLEN",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Aeroport De La Seu",
        city: "Seo De Urgel",
        iata: "LEU",
        countryIata: "ESLEU",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Adolfo Suárez Madrid–Barajas Airport",
        city: "Madrid",
        iata: "MAD",
        countryIata: "ESMAD",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "San Javier",
        city: "Murcia",
        iata: "MJV",
        countryIata: "ESMJV",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Melilla",
        city: "Melilla",
        iata: "MLN",
        countryIata: "ESMLN",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Cordoba",
        city: "Cordoba",
        iata: "ODB",
        countryIata: "ESODB",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Oviedo",
        city: "Asturias",
        iata: "OVD",
        countryIata: "ESOVD",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Morón Air Base",
        city: "Morón",
        iata: "OZP",
        countryIata: "ESOZP",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Pamplona",
        city: "Pamplona",
        iata: "PNA",
        countryIata: "ESPNA",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Reus",
        city: "Reus",
        iata: "REU",
        countryIata: "ESREU",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Logroño–Agoncillo Airport",
        city: "Logroño",
        iata: "RJL",
        countryIata: "ESRJL",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Santiago De Compostela",
        city: "Santiago De Compostela",
        iata: "SCQ",
        countryIata: "ESSCQ",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Santander",
        city: "Santander",
        iata: "SDR",
        countryIata: "ESSDR",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Matacan",
        city: "Salamanca",
        iata: "SLM",
        countryIata: "ESSLM",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Sevilla",
        city: "Sevilla",
        iata: "SVQ",
        countryIata: "ESSVQ",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Torrejon AFB",
        city: "Madrid",
        iata: "TOJ",
        countryIata: "ESTOJ",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Puerto La Cruz",
        city: "Puerto La Cruz",
        iata: "UPC",
        countryIata: "ESUPC",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Torremolinos",
        city: "Torremolinos",
        iata: "UTL",
        countryIata: "ESUTL",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Vigo",
        city: "Vigo",
        iata: "VGO",
        countryIata: "ESVGO",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Vitoria",
        city: "Vitoria",
        iata: "VIT",
        countryIata: "ESVIT",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Valencia",
        city: "Valencia",
        iata: "VLC",
        countryIata: "ESVLC",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Valladolid Airport",
        city: "Valladolid",
        iata: "VLL",
        countryIata: "ESVLL",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "La Parra",
        city: "Jerez de la Frontera",
        iata: "XRY",
        countryIata: "ESXRY",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Zaragoza",
        city: "Zaragoza",
        iata: "ZAZ",
        countryIata: "ESZAZ",
        country: "Spain",
        countryCode: "ES",
    },
    {
        airportName: "Anuradhapura",
        city: "Anuradhapura",
        iata: "ADP",
        countryIata: "LKADP",
        country: "Sri Lanka",
        countryCode: "LK",
    },
    {
        airportName: "Batticaloa",
        city: "Batticaloa",
        iata: "BTC",
        countryIata: "LKBTC",
        country: "Sri Lanka",
        countryCode: "LK",
    },
    {
        airportName: "Bandaranayake International",
        city: "Colombo",
        iata: "CMB",
        countryIata: "LKCMB",
        country: "Sri Lanka",
        countryCode: "LK",
    },
    {
        airportName: "Dandugama Water Aerodrome",
        city: "Colombo",
        iata: "DGM",
        countryIata: "LKDGM",
        country: "Sri Lanka",
        countryCode: "LK",
    },
    {
        airportName: "Amparai",
        city: "Gal Oya",
        iata: "GOY",
        countryIata: "LKGOY",
        country: "Sri Lanka",
        countryCode: "LK",
    },
    {
        airportName: "Kankesanturai",
        city: "Jaffna",
        iata: "JAF",
        countryIata: "LKJAF",
        country: "Sri Lanka",
        countryCode: "LK",
    },
    {
        airportName: "Minneriya",
        city: "Minneriya",
        iata: "MNH",
        countryIata: "LKMNH",
        country: "Sri Lanka",
        countryCode: "LK",
    },
    {
        airportName: "Ratmalana",
        city: "Colombo",
        iata: "RML",
        countryIata: "LKRML",
        country: "Sri Lanka",
        countryCode: "LK",
    },
    {
        airportName: "China Bay",
        city: "Trincomalee",
        iata: "TRR",
        countryIata: "LKTRR",
        country: "Sri Lanka",
        countryCode: "LK",
    },
    {
        airportName: "Cayana Airstrip",
        city: "Awaradam",
        iata: "AAJ",
        countryIata: "SRAAJ",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Albina",
        city: "Albina",
        iata: "ABN",
        countryIata: "SRABN",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Wageningen",
        city: "Wageningen",
        iata: "AGI",
        countryIata: "SRAGI",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Botopasie",
        city: "Botopasie",
        iata: "BTO",
        countryIata: "SRBTO",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Djoemoe",
        city: "Djoemoe",
        iata: "DOE",
        countryIata: "SRDOE",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Drietabbetje",
        city: "Drietabbetje",
        iata: "DRJ",
        countryIata: "SRDRJ",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Nieuw Nickerie",
        city: "Nieuw Nickerie",
        iata: "ICK",
        countryIata: "SRICK",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Tepoe Airstrip",
        city: "Kasikasima",
        iata: "KCB",
        countryIata: "SRKCB",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Ladouanie",
        city: "Ladouanie",
        iata: "LDO",
        countryIata: "SRLDO",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Moengo",
        city: "Moengo",
        iata: "MOJ",
        countryIata: "SRMOJ",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Vincent Fayks",
        city: "Paloemeu",
        iata: "OEM",
        countryIata: "SROEM",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Zorg en Hoop Airport",
        city: "Paramaribo",
        iata: "ORG",
        countryIata: "SRORG",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Zanderij International",
        city: "Paramaribo",
        iata: "PBM",
        countryIata: "SRPBM",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Stoelmans Eiland",
        city: "Stoelmans Eiland",
        iata: "SMZ",
        countryIata: "SRSMZ",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Coronie",
        city: "Totness",
        iata: "TOT",
        countryIata: "SRTOT",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Washabo",
        city: "Washabo",
        iata: "WSO",
        countryIata: "SRWSO",
        country: "Suriname",
        countryCode: "SR",
    },
    {
        airportName: "Ängelholm–Helsingborg Airport",
        city: "Angelholm, Helsingborg",
        iata: "AGH",
        countryIata: "SEAGH",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Arvidsjaur",
        city: "Arvidsjaur",
        iata: "AJR",
        countryIata: "SEAJR",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Arlanda",
        city: "Stockholm",
        iata: "ARN",
        countryIata: "SEARN",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Dala",
        city: "Borlange/Falun",
        iata: "BLE",
        countryIata: "SEBLE",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Bromma",
        city: "Stockholm",
        iata: "BMA",
        countryIata: "SEBMA",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Eskilstuna",
        city: "Eskilstuna",
        iata: "EKT",
        countryIata: "SEEKT",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Sveg",
        city: "Sveg",
        iata: "EVG",
        countryIata: "SEEVG",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Gallivare",
        city: "Gallivare",
        iata: "GEV",
        countryIata: "SEGEV",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Landvetter",
        city: "Gothenburg",
        iata: "GOT",
        countryIata: "SEGOT",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Saeve",
        city: "Gothenburg",
        iata: "GSE",
        countryIata: "SEGSE",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Sandviken",
        city: "Gavle",
        iata: "GVX",
        countryIata: "SEGVX",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Halmstad",
        city: "Halmstad",
        iata: "HAD",
        countryIata: "SEHAD",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Hagfors",
        city: "Hagfors",
        iata: "HFS",
        countryIata: "SEHFS",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Hultsfred",
        city: "Hultsfred",
        iata: "HLF",
        countryIata: "SEHLF",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Hemavan",
        city: "Hemavan",
        iata: "HMV",
        countryIata: "SEHMV",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Hudiksvall",
        city: "Hudiksvall",
        iata: "HUV",
        countryIata: "SEHUV",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Idre",
        city: "Idre",
        iata: "IDB",
        countryIata: "SEIDB",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Heliport",
        city: "Angelholm/Helsingborg",
        iata: "JHE",
        countryIata: "SEJHE",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Axamo",
        city: "Jonkoping",
        iata: "JKG",
        countryIata: "SEJKG",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Heliport",
        city: "Landskrona",
        iata: "JLD",
        countryIata: "SEJLD",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Malmo Harbour Heliport",
        city: "Malmo",
        iata: "JMM",
        countryIata: "SEJMM",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Sodertalje Heliport",
        city: "Sodertalje",
        iata: "JSO",
        countryIata: "SEJSO",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Kristianstad",
        city: "Kristianstad",
        iata: "KID",
        countryIata: "SEKID",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Kalmar",
        city: "Kalmar",
        iata: "KLR",
        countryIata: "SEKLR",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Kramfors",
        city: "Kramfors",
        iata: "KRF",
        countryIata: "SEKRF",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Kiruna",
        city: "Kiruna",
        iata: "KRN",
        countryIata: "SEKRN",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Karlstad",
        city: "Karlstad",
        iata: "KSD",
        countryIata: "SEKSD",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Karlskoga",
        city: "Karlskoga",
        iata: "KSK",
        countryIata: "SEKSK",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Skovde",
        city: "Skovde",
        iata: "KVB",
        countryIata: "SEKVB",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Hovby",
        city: "Lidkoping",
        iata: "LDK",
        countryIata: "SELDK",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Kallax",
        city: "Lulea",
        iata: "LLA",
        countryIata: "SELLA",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Linkoping",
        city: "Linkoping",
        iata: "LPI",
        countryIata: "SELPI",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Lycksele",
        city: "Lycksele",
        iata: "LYC",
        countryIata: "SELYC",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Metropolitan Area",
        city: "Malmo",
        iata: "MMA",
        countryIata: "SEMMA",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Malmö Airport",
        city: "Malmö",
        iata: "MMX",
        countryIata: "SEMMX",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Mora",
        city: "Mora",
        iata: "MXX",
        countryIata: "SEMXX",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Kungsangen",
        city: "Norrkoping",
        iata: "NRK",
        countryIata: "SENRK",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Skavsta",
        city: "Stockholm",
        iata: "NYO",
        countryIata: "SENYO",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Ornskoldsvik",
        city: "Ornskoldsvik",
        iata: "OER",
        countryIata: "SEOER",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Orebro-Bofors",
        city: "Orebro",
        iata: "ORB",
        countryIata: "SEORB",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Froesoe",
        city: "Ostersund",
        iata: "OSD",
        countryIata: "SEOSD",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Oskarshamn",
        city: "Oskarshamn",
        iata: "OSK",
        countryIata: "SEOSK",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Pajala",
        city: "Pajala",
        iata: "PJA",
        countryIata: "SEPJA",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Kallinge",
        city: "Ronneby",
        iata: "RNB",
        countryIata: "SERNB",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Sundsvall/Harnosand",
        city: "Sundsvall",
        iata: "SDL",
        countryIata: "SESDL",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Skelleftea",
        city: "Skelleftea",
        iata: "SFT",
        countryIata: "SESFT",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Soderhamn",
        city: "Soderhamn",
        iata: "SOO",
        countryIata: "SESOO",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Gunnarn",
        city: "Storuman",
        iata: "SQO",
        countryIata: "SESQO",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Metropolitan Area",
        city: "Stockholm",
        iata: "STO",
        countryIata: "SESTO",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Trollhattan",
        city: "Trollhattan",
        iata: "THN",
        countryIata: "SETHN",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Torsby Airport",
        city: "Torsby",
        iata: "TYF",
        countryIata: "SETYF",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Umea",
        city: "Umea",
        iata: "UME",
        countryIata: "SEUME",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Visby",
        city: "Visby",
        iata: "VBY",
        countryIata: "SEVBY",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Vilhelmina",
        city: "Vilhelmina",
        iata: "VHM",
        countryIata: "SEVHM",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Hasslo",
        city: "Vasteras",
        iata: "VST",
        countryIata: "SEVST",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Vastervik",
        city: "Vastervik",
        iata: "VVK",
        countryIata: "SEVVK",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Vaxjo",
        city: "Vaxjo",
        iata: "VXO",
        countryIata: "SEVXO",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Hassleholm",
        city: "Hassleholm",
        iata: "XWP",
        countryIata: "SEXWP",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Enkoping",
        city: "Enkoping",
        iata: "XWQ",
        countryIata: "SEXWQ",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Nykoping",
        city: "Nykoping",
        iata: "XWZ",
        countryIata: "SEXWZ",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Alvesta",
        city: "Alvesta",
        iata: "XXA",
        countryIata: "SEXXA",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Katrineholm",
        city: "Katrineholm",
        iata: "XXK",
        countryIata: "SEXXK",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Hedemora",
        city: "Hedemora",
        iata: "XXU",
        countryIata: "SEXXU",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Flen",
        city: "Flen",
        iata: "XYI",
        countryIata: "SEXYI",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Karlshamn",
        city: "Karlshamn",
        iata: "XYO",
        countryIata: "SEXYO",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Avesta Krylbo",
        city: "Avesta Krylbo",
        iata: "XYP",
        countryIata: "SEXYP",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Solvesborg",
        city: "Solvesborg",
        iata: "XYU",
        countryIata: "SEXYU",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Sala",
        city: "Sala",
        iata: "XYX",
        countryIata: "SEXYX",
        country: "Sweden",
        countryCode: "SE",
    },
    {
        airportName: "Altenrhein",
        city: "Altenrhein",
        iata: "ACH",
        countryIata: "CHACH",
        country: "Switzerland",
        countryCode: "CH",
    },
    {
        airportName: "Ascona",
        city: "Ascona",
        iata: "ACO",
        countryIata: "CHACO",
        country: "Switzerland",
        countryCode: "CH",
    },
    {
        airportName: "Belp",
        city: "Berne",
        iata: "BRN",
        countryIata: "CHBRN",
        country: "Switzerland",
        countryCode: "CH",
    },
    {
        airportName: "EuroAirport Swiss",
        city: "Basel/Mulhouse",
        iata: "BSL",
        countryIata: "CHBSL",
        country: "Switzerland",
        countryCode: "CH",
    },
    {
        airportName: "Emmen",
        city: "Emmen",
        iata: "EML",
        countryIata: "CHEML",
        country: "Switzerland",
        countryCode: "CH",
    },
    {
        airportName: "Geneve-Cointrin",
        city: "Geneva",
        iata: "GVA",
        countryIata: "CHGVA",
        country: "Switzerland",
        countryCode: "CH",
    },
    {
        airportName: "Lugano",
        city: "Lugano",
        iata: "LUG",
        countryIata: "CHLUG",
        country: "Switzerland",
        countryCode: "CH",
    },
    {
        airportName: "Sion",
        city: "Sion",
        iata: "SIR",
        countryIata: "CHSIR",
        country: "Switzerland",
        countryCode: "CH",
    },
    {
        airportName: "Samedan",
        city: "St Moritz",
        iata: "SMV",
        countryIata: "CHSMV",
        country: "Switzerland",
        countryCode: "CH",
    },
    {
        airportName: "Zurich International Airport",
        city: "Zürich",
        iata: "ZRH",
        countryIata: "CHZRH",
        country: "Switzerland",
        countryCode: "CH",
    },
    {
        airportName: "Aleppo International Airport (Nejrab Airport)",
        city: "Aleppo",
        iata: "ALP",
        countryIata: "SYALP",
        country: "Syrian Arab Republic",
        countryCode: "SY",
    },
    {
        airportName: "Damascus International",
        city: "Damascus",
        iata: "DAM",
        countryIata: "SYDAM",
        country: "Syrian Arab Republic",
        countryCode: "SY",
    },
    {
        airportName: "Al Jafrah",
        city: "Deirezzor",
        iata: "DEZ",
        countryIata: "SYDEZ",
        country: "Syrian Arab Republic",
        countryCode: "SY",
    },
    {
        airportName: "Kameshli",
        city: "Kameshli",
        iata: "KAC",
        countryIata: "SYKAC",
        country: "Syrian Arab Republic",
        countryCode: "SY",
    },
    {
        airportName: "Latakia",
        city: "Latakia",
        iata: "LTK",
        countryIata: "SYLTK",
        country: "Syrian Arab Republic",
        countryCode: "SY",
    },
    {
        airportName: "Palmyra",
        city: "Palmyra",
        iata: "PMS",
        countryIata: "SYPMS",
        country: "Syrian Arab Republic",
        countryCode: "SY",
    },
    {
        airportName: "Al Thaurah",
        city: "Al Thaurah",
        iata: "SOR",
        countryIata: "SYSOR",
        country: "Syrian Arab Republic",
        countryCode: "SY",
    },
    {
        airportName: "Chi Mei",
        city: "Chi Mei",
        iata: "CMJ",
        countryIata: "TWCMJ",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Chiayi",
        city: "Chiayi",
        iata: "CYI",
        countryIata: "TWCYI",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Green Island",
        city: "Green Island",
        iata: "GNI",
        countryIata: "TWGNI",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Hengchun",
        city: "Hengchun",
        iata: "HCN",
        countryIata: "TWHCN",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Hsinchu",
        city: "Hsinchu",
        iata: "HSZ",
        countryIata: "TWHSZ",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Hualien",
        city: "Hualien",
        iata: "HUN",
        countryIata: "TWHUN",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "International",
        city: "Kaohsiung",
        iata: "KHH",
        countryIata: "TWKHH",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Shang-Yi",
        city: "Kinmen",
        iata: "KNH",
        countryIata: "TWKNH",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Lanyu Airport",
        city: "Orchid Island",
        iata: "KYD",
        countryIata: "TWKYD",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Lishan",
        city: "Lishan",
        iata: "LHN",
        countryIata: "TWLHN",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Matsu",
        city: "Matsu",
        iata: "MFK",
        countryIata: "TWMFK",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Makung",
        city: "Makung",
        iata: "MZG",
        countryIata: "TWMZG",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Pingtung",
        city: "Pingtung",
        iata: "PIF",
        countryIata: "TWPIF",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Sun Moon Lake",
        city: "Sun Moon Lake",
        iata: "SMT",
        countryIata: "TWSMT",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Tainan",
        city: "Tainan",
        iata: "TNN",
        countryIata: "TWTNN",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Taoyuan International Airport",
        city: "Taipei",
        iata: "TPE",
        countryIata: "TWTPE",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Sung Shan",
        city: "Taipei",
        iata: "TSA",
        countryIata: "TWTSA",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Taitung",
        city: "Taitung",
        iata: "TTT",
        countryIata: "TWTTT",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Taichung",
        city: "Taichung",
        iata: "TXG",
        countryIata: "TWTXG",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Wang-an Airport",
        city: "Wang'an",
        iata: "WOT",
        countryIata: "TWWOT",
        country: "Taiwan",
        countryCode: "TW",
    },
    {
        airportName: "Dushanbe",
        city: "Dushanbe",
        iata: "DYU",
        countryIata: "TJDYU",
        country: "Tajikistan",
        countryCode: "TJ",
    },
    {
        airportName: "Khudzhand",
        city: "Khudzhand",
        iata: "LBD",
        countryIata: "TJLBD",
        country: "Tajikistan",
        countryCode: "TJ",
    },
    {
        airportName: "Chennai International Airport (Madras Airport)",
        city: "Chennai",
        iata: "MAA",
        countryIata: "INMAA",
        country: "India",
        countryCode: "IN",
    },
    {
        airportName: "Arusha",
        city: "Arusha",
        iata: "ARK",
        countryIata: "TZARK",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Bukoba",
        city: "Bukoba",
        iata: "BKZ",
        countryIata: "TZBKZ",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "International",
        city: "Dar Es Salaam",
        iata: "DAR",
        countryIata: "TZDAR",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Dodoma Airport",
        city: "Dodoma",
        iata: "DOD",
        countryIata: "TZDOD",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Geita",
        city: "Geita",
        iata: "GIT",
        countryIata: "TZGIT",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Nduli",
        city: "Iringa",
        iata: "IRI",
        countryIata: "TZIRI",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Njombe",
        city: "Njombe",
        iata: "JOM",
        countryIata: "TZJOM",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Kilimanjaro International Airport",
        city: "Kilimanjaro",
        iata: "JRO",
        countryIata: "TZJRO",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Kilwa",
        city: "Kilwa",
        iata: "KIY",
        countryIata: "TZKIY",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Kikwetu",
        city: "Lindi",
        iata: "LDI",
        countryIata: "TZLDI",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Lake Manyara",
        city: "Lake Manyara",
        iata: "LKY",
        countryIata: "TZLKY",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Lushoto",
        city: "Lushoto",
        iata: "LUY",
        countryIata: "TZLUY",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Mbeya",
        city: "Mbeya",
        iata: "MBI",
        countryIata: "TZMBI",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Mafia",
        city: "Mafia",
        iata: "MFA",
        countryIata: "TZMFA",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Musoma",
        city: "Musoma",
        iata: "MUZ",
        countryIata: "TZMUZ",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Mwadui",
        city: "Mwadui",
        iata: "MWN",
        countryIata: "TZMWN",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Mwanza",
        city: "Mwanza",
        iata: "MWZ",
        countryIata: "TZMWZ",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Mtwara",
        city: "Mtwara",
        iata: "MYW",
        countryIata: "TZMYW",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Nachingwea",
        city: "Nachingwea",
        iata: "NCH",
        countryIata: "TZNCH",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Wawi",
        city: "Pemba",
        iata: "PMA",
        countryIata: "TZPMA",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Seronera",
        city: "Seronera",
        iata: "SEU",
        countryIata: "TZSEU",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Songea",
        city: "Songea",
        iata: "SGX",
        countryIata: "TZSGX",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Shinyanga",
        city: "Shinyanga",
        iata: "SHY",
        countryIata: "TZSHY",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Sumbawanga Airport",
        city: "Sumbawanga",
        iata: "SUT",
        countryIata: "TZSUT",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Tabora",
        city: "Tabora",
        iata: "TBO",
        countryIata: "TZTBO",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Tanga",
        city: "Tanga",
        iata: "TGT",
        countryIata: "TZTGT",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Kigoma",
        city: "Kigoma",
        iata: "TKQ",
        countryIata: "TZTKQ",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Masasi",
        city: "Masasi",
        iata: "XMI",
        countryIata: "TZXMI",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Kisauni",
        city: "Zanzibar",
        iata: "ZNZ",
        countryIata: "TZZNZ",
        country: "Tanzania, United Republic of",
        countryCode: "TZ",
    },
    {
        airportName: "Udorn",
        city: "Ban Mak Khaen",
        iata: "BAO",
        countryIata: "THBAO",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Buri Ram",
        city: "Buri Ram",
        iata: "BFV",
        countryIata: "THBFV",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Metropolitan Area",
        city: "Bangkok",
        iata: "BKK",
        countryIata: "THBKK",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Chiang Rai",
        city: "Chiang Rai",
        iata: "CEI",
        countryIata: "THCEI",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Chumphon Airport",
        city: "Chumphon",
        iata: "CJM",
        countryIata: "THCJM",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "International",
        city: "Chiang Mai",
        iata: "CNX",
        countryIata: "THCNX",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Don Muang",
        city: "Bangkok",
        iata: "DMK",
        countryIata: "THDMK",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Hat Yai",
        city: "Hat Yai",
        iata: "HDY",
        countryIata: "THHDY",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Mae Hong Son",
        city: "Mae Hong Son",
        iata: "HGN",
        countryIata: "THHGN",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Hua Hin Airport",
        city: "Hua Hin",
        iata: "HHQ",
        countryIata: "THHHQ",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "International",
        city: "Phuket",
        iata: "HKT",
        countryIata: "THHKT",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Krabi",
        city: "Krabi",
        iata: "KBV",
        countryIata: "THKBV",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Khon Kaen",
        city: "Khon Kaen",
        iata: "KKC",
        countryIata: "THKKC",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Lop Buri",
        city: "Lop Buri",
        iata: "KKM",
        countryIata: "THKKM",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Nakhon Phanom",
        city: "Nakhon Phanom",
        iata: "KOP",
        countryIata: "THKOP",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Loei",
        city: "Loei",
        iata: "LOE",
        countryIata: "THLOE",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Lampang",
        city: "Lampang",
        iata: "LPT",
        countryIata: "THLPT",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Mae Sot",
        city: "Mae Sot",
        iata: "MAQ",
        countryIata: "THMAQ",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Nakhon Ratchasima",
        city: "Nakhon Ratchasima",
        iata: "NAK",
        countryIata: "THNAK",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Narathiwat",
        city: "Narathiwat",
        iata: "NAW",
        countryIata: "THNAW",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Nan",
        city: "Nan",
        iata: "NNT",
        countryIata: "THNNT",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Nakhon Si Thammarat",
        city: "Nakhon Si Thammarat",
        iata: "NST",
        countryIata: "THNST",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Pattani",
        city: "Pattani",
        iata: "PAN",
        countryIata: "THPAN",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Patong Beach",
        city: "Patong Beach",
        iata: "PBS",
        countryIata: "THPBS",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Phitsanulok",
        city: "Phitsanulok",
        iata: "PHS",
        countryIata: "THPHS",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Phetchabun",
        city: "Phetchabun",
        iata: "PHY",
        countryIata: "THPHY",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Phi Phi Island",
        city: "Phi Phi Island",
        iata: "PHZ",
        countryIata: "THPHZ",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Phanom Sarakham",
        city: "Phanom Sarakham",
        iata: "PMM",
        countryIata: "THPMM",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Phrae",
        city: "Phrae",
        iata: "PRH",
        countryIata: "THPRH",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Pattaya",
        city: "Pattaya",
        iata: "PYX",
        countryIata: "THPYX",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Roi Et Airport",
        city: "Roi Et",
        iata: "ROI",
        countryIata: "THROI",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Songkhla",
        city: "Songkhla",
        iata: "SGZ",
        countryIata: "THSGZ",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Sakon Nakhon",
        city: "Sakon Nakhon",
        iata: "SNO",
        countryIata: "THSNO",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Sukhothai",
        city: "Sukhothai",
        iata: "THS",
        countryIata: "THTHS",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Takhli",
        city: "Takhli",
        iata: "TKH",
        countryIata: "THTKH",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Tak",
        city: "Tak",
        iata: "TKT",
        countryIata: "THTKT",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Trang",
        city: "Trang",
        iata: "TST",
        countryIata: "THTST",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Ubon Ratchathani Airport",
        city: "Ubon Ratchathani",
        iata: "UBP",
        countryIata: "THUBP",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Ranong",
        city: "Ranong",
        iata: "UNN",
        countryIata: "THUNN",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Surat Thani",
        city: "Surat Thani",
        iata: "URT",
        countryIata: "THURT",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Koh Samui",
        city: "Koh Samui",
        iata: "USM",
        countryIata: "THUSM",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Udon Thani",
        city: "Udon Thani",
        iata: "UTH",
        countryIata: "THUTH",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Utapao",
        city: "Utapao",
        iata: "UTP",
        countryIata: "THUTP",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Uttaradit",
        city: "Uttaradit",
        iata: "UTR",
        countryIata: "THUTR",
        country: "Thailand",
        countryCode: "TH",
    },
    {
        airportName: "Lome",
        city: "Lome",
        iata: "LFW",
        countryIata: "TGLFW",
        country: "Togo",
        countryCode: "TG",
    },
    {
        airportName: "Lama-Kara",
        city: "Niamtougou",
        iata: "LRL",
        countryIata: "TGLRL",
        country: "Togo",
        countryCode: "TG",
    },
    {
        airportName: "Kaufana",
        city: "Eua",
        iata: "EUA",
        countryIata: "TOEUA",
        country: "Tonga",
        countryCode: "TO",
    },
    {
        airportName: "Salote Pilolevu",
        city: "Ha'Apai",
        iata: "HPA",
        countryIata: "TOHPA",
        country: "Tonga",
        countryCode: "TO",
    },
    {
        airportName: "Mata'aho",
        city: "Niuafo'ou",
        iata: "NFO",
        countryIata: "TONFO",
        country: "Tonga",
        countryCode: "TO",
    },
    {
        airportName: "Kuini Lavenia",
        city: "Niuatoputapu",
        iata: "NTT",
        countryIata: "TONTT",
        country: "Tonga",
        countryCode: "TO",
    },
    {
        airportName: "Fua'Amotu Internationa",
        city: "Nuku'Alofa",
        iata: "TBU",
        countryIata: "TOTBU",
        country: "Tonga",
        countryCode: "TO",
    },
    {
        airportName: "Lupepau'u",
        city: "Vava'u",
        iata: "VAV",
        countryIata: "TOVAV",
        country: "Tonga",
        countryCode: "TO",
    },
    {
        airportName: "Piarco International Airport",
        city: "Port of Spain",
        iata: "POS",
        countryIata: "TTPOS",
        country: "Trinidad and Tobago",
        countryCode: "TT",
    },
    {
        airportName: "Tobago",
        city: "Tobago",
        iata: "TAB",
        countryIata: "TTTAB",
        country: "Trinidad and Tobago",
        countryCode: "TT",
    },
    {
        airportName: "Melita",
        city: "Djerba",
        iata: "DJE",
        countryIata: "TNDJE",
        country: "Tunisia",
        countryCode: "TN",
    },
    {
        airportName: "El Borma",
        city: "El Borma",
        iata: "EBM",
        countryIata: "TNEBM",
        country: "Tunisia",
        countryCode: "TN",
    },
    {
        airportName: "Gabes",
        city: "Gabes",
        iata: "GAE",
        countryIata: "TNGAE",
        country: "Tunisia",
        countryCode: "TN",
    },
    {
        airportName: "Gafsa",
        city: "Gafsa",
        iata: "GAF",
        countryIata: "TNGAF",
        country: "Tunisia",
        countryCode: "TN",
    },
    {
        airportName: "Habib Bourguiba International",
        city: "Monastir",
        iata: "MIR",
        countryIata: "TNMIR",
        country: "Tunisia",
        countryCode: "TN",
    },
    {
        airportName: "Sfax El Maou",
        city: "Sfax",
        iata: "SFA",
        countryIata: "TNSFA",
        country: "Tunisia",
        countryCode: "TN",
    },
    {
        airportName: "Tabarka",
        city: "Tabarka",
        iata: "TBJ",
        countryIata: "TNTBJ",
        country: "Tunisia",
        countryCode: "TN",
    },
    {
        airportName: "Tozeur",
        city: "Tozeur",
        iata: "TOE",
        countryIata: "TNTOE",
        country: "Tunisia",
        countryCode: "TN",
    },
    {
        airportName: "Carthage",
        city: "Tunis",
        iata: "TUN",
        countryIata: "TNTUN",
        country: "Tunisia",
        countryCode: "TN",
    },
    {
        airportName: "Adana",
        city: "Adana",
        iata: "ADA",
        countryIata: "TRADA",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Adnan Menderes Airport",
        city: "Izmir",
        iata: "ADB",
        countryIata: "TRADB",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Adiyaman",
        city: "Adiyaman",
        iata: "ADF",
        countryIata: "TRADF",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Afyon",
        city: "Afyon",
        iata: "AFY",
        countryIata: "TRAFY",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Agri",
        city: "Agri",
        iata: "AJI",
        countryIata: "TRAJI",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Etimesgut",
        city: "Ankara",
        iata: "ANK",
        countryIata: "TRANK",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Anadolu University",
        city: "Eskisehir",
        iata: "AOE",
        countryIata: "TRAOE",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Kayseri",
        city: "Kayseri",
        iata: "ASR",
        countryIata: "TRASR",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Antalya Airport",
        city: "Antalya",
        iata: "AYT",
        countryIata: "TRAYT",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Batman",
        city: "Batman",
        iata: "BAL",
        countryIata: "TRBAL",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Bandirma",
        city: "Bandirma",
        iata: "BDM",
        countryIata: "TRBDM",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Milas Airport",
        city: "Bodrum.",
        iata: "BJV",
        countryIata: "TRBJV",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Bursa Airport",
        city: "Bursa",
        iata: "BTZ",
        countryIata: "TRBTZ",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Imsik Airport",
        city: "Bodrum",
        iata: "BXN",
        countryIata: "TRBXN",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Balikesir",
        city: "Balikesir",
        iata: "BZI",
        countryIata: "TRBZI",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Canakkale",
        city: "Canakkale",
        iata: "CKZ",
        countryIata: "TRCKZ",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Diyarbakir",
        city: "Diyarbakir",
        iata: "DIY",
        countryIata: "TRDIY",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Dalaman",
        city: "Dalaman",
        iata: "DLM",
        countryIata: "TRDLM",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Cardak",
        city: "Denizli",
        iata: "DNZ",
        countryIata: "TRDNZ",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Edremit/Korfez",
        city: "Edremit/Korfez",
        iata: "EDO",
        countryIata: "TREDO",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Erzincan",
        city: "Erzincan",
        iata: "ERC",
        countryIata: "TRERC",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Erzurum",
        city: "Erzurum",
        iata: "ERZ",
        countryIata: "TRERZ",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Esenboga",
        city: "Ankara",
        iata: "ESB",
        countryIata: "TRESB",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Eskisehir",
        city: "Eskisehir",
        iata: "ESK",
        countryIata: "TRESK",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Elazig",
        city: "Elazig",
        iata: "EZS",
        countryIata: "TREZS",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Gaziantep",
        city: "Gaziantep",
        iata: "GZT",
        countryIata: "TRGZT",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Cigli Military Airport",
        city: "Izmir",
        iata: "IGL",
        countryIata: "TRIGL",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Isparta",
        city: "Isparta",
        iata: "ISE",
        countryIata: "TRISE",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Istanbul Atatürk Airport",
        city: "Istanbul",
        iata: "IST",
        countryIata: "TRIST",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Izmir Metropolitan Area",
        city: "Izmir",
        iata: "IZM",
        countryIata: "TRIZM",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Kahramanmaras",
        city: "Kahramanmaras",
        iata: "KCM",
        countryIata: "TRKCM",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Cengiz Topel",
        city: "Kocaeli",
        iata: "KCO",
        countryIata: "TRKCO",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Kastamonu",
        city: "Kastamonu",
        iata: "KFS",
        countryIata: "TRKFS",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Kars",
        city: "Kars",
        iata: "KSY",
        countryIata: "TRKSY",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Konya",
        city: "Konya",
        iata: "KYA",
        countryIata: "TRKYA",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Kars Airport",
        city: "Kars",
        iata: "KYS",
        countryIata: "TRKYS",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Zafer Airport",
        city: "Kütahya",
        iata: "KZR",
        countryIata: "TRKZR",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Malatya",
        city: "Malatya",
        iata: "MLX",
        countryIata: "TRMLX",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Merkez",
        city: "Balikesir",
        iata: "MQJ",
        countryIata: "TRMQJ",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Mardin",
        city: "Mardin",
        iata: "MQM",
        countryIata: "TRMQM",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Mus",
        city: "Mus",
        iata: "MSR",
        countryIata: "TRMSR",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Merzifon",
        city: "Merzifon",
        iata: "MZH",
        countryIata: "TRMZH",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Nevsehir",
        city: "Nevsehir",
        iata: "NAV",
        countryIata: "TRNAV",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Zonguldak",
        city: "Zonguldak",
        iata: "ONQ",
        countryIata: "TRONQ",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Sabiha Gökçen International Airport",
        city: "Pendik, Istanbul",
        iata: "SAW",
        countryIata: "TRSAW",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Sanliurfa",
        city: "Sanliurfa",
        iata: "SFQ",
        countryIata: "TRSFQ",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Sinop Airport",
        city: "Sinop",
        iata: "SIC",
        countryIata: "TRSIC",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Sinop",
        city: "Sinop",
        iata: "SQD",
        countryIata: "TRSQD",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Samsun",
        city: "Samsun",
        iata: "SSX",
        countryIata: "TRSSX",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Siirt",
        city: "Siirt",
        iata: "SXZ",
        countryIata: "TRSXZ",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Carsamba",
        city: "Samsun",
        iata: "SZF",
        countryIata: "TRSZF",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Corlu",
        city: "Tekirdag",
        iata: "TEQ",
        countryIata: "TRTEQ",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Tokat",
        city: "Tokat",
        iata: "TJK",
        countryIata: "TRTJK",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Trabzon",
        city: "Trabzon",
        iata: "TZX",
        countryIata: "TRTZX",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Usak",
        city: "Usak",
        iata: "USQ",
        countryIata: "TRUSQ",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Van",
        city: "Van",
        iata: "VAN",
        countryIata: "TRVAN",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Sivas",
        city: "Sivas",
        iata: "VAS",
        countryIata: "TRVAS",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Yenisehir",
        city: "Bursa",
        iata: "YEI",
        countryIata: "TRYEI",
        country: "Turkey",
        countryCode: "TR",
    },
    {
        airportName: "Ashgabat Airport",
        city: "Ashgabat",
        iata: "ASB",
        countryIata: "TMASB",
        country: "Turkmenistan",
        countryCode: "TM",
    },
    {
        airportName: "Turkmenabat International Airport",
        city: "Turkmenabad",
        iata: "CRZ",
        countryIata: "TMCRZ",
        country: "Turkmenistan",
        countryCode: "TM",
    },
    {
        airportName: "Turkmanbashi",
        city: "Turkmenbashi",
        iata: "KRW",
        countryIata: "TMKRW",
        country: "Turkmenistan",
        countryCode: "TM",
    },
    {
        airportName: "Mary",
        city: "Mary",
        iata: "MYP",
        countryIata: "TMMYP",
        country: "Turkmenistan",
        countryCode: "TM",
    },
    {
        airportName: "Dashoguz",
        city: "Dashoguz",
        iata: "TAZ",
        countryIata: "TMTAZ",
        country: "Turkmenistan",
        countryCode: "TM",
    },
    {
        airportName: "International",
        city: "Funafuti Atol",
        iata: "FUN",
        countryIata: "TVFUN",
        country: "Tuvalu",
        countryCode: "TV",
    },
    {
        airportName: "Downtown Heliport",
        city: "St Croix Island",
        iata: "JCD",
        countryIata: "VIJCD",
        country: "Virgin Islands (U.S.)",
        countryCode: "VI",
    },
    {
        airportName: "Kangerlussuaq",
        city: "St John Island",
        iata: "SFJ",
        countryIata: "VISFJ",
        country: "Virgin Islands (U.S.)",
        countryCode: "VI",
    },
    {
        airportName: "St John Island",
        city: "St John Island",
        iata: "SJF",
        countryIata: "VISJF",
        country: "Virgin Islands (U.S.)",
        countryCode: "VI",
    },
    {
        airportName: "SPB",
        city: "St Thomas Island",
        iata: "SPB",
        countryIata: "VISPB",
        country: "Virgin Islands (U.S.)",
        countryCode: "VI",
    },
    {
        airportName: "SPB",
        city: "St Croix Island",
        iata: "SSB",
        countryIata: "VISSB",
        country: "Virgin Islands (U.S.)",
        countryCode: "VI",
    },
    {
        airportName: "H.S.Truman",
        city: "St Thomas Island",
        iata: "STT",
        countryIata: "VISTT",
        country: "Virgin Islands (U.S.)",
        countryCode: "VI",
    },
    {
        airportName: "Alex Hamilton",
        city: "St Croix Island",
        iata: "STX",
        countryIata: "VISTX",
        country: "Virgin Islands (U.S.)",
        countryCode: "VI",
    },
    {
        airportName: "Entebbe",
        city: "Entebbe",
        iata: "EBB",
        countryIata: "UGEBB",
        country: "Uganda",
        countryCode: "UG",
    },
    {
        airportName: "Jinja",
        city: "Jinja",
        iata: "JIN",
        countryIata: "UGJIN",
        country: "Uganda",
        countryCode: "UG",
    },
    {
        airportName: "Kabalega Falls",
        city: "Kabalega Falls",
        iata: "KBG",
        countryIata: "UGKBG",
        country: "Uganda",
        countryCode: "UG",
    },
    {
        airportName: "Masindi",
        city: "Masindi",
        iata: "KCU",
        countryIata: "UGKCU",
        country: "Uganda",
        countryCode: "UG",
    },
    {
        airportName: "Off-line Pt",
        city: "Kampala",
        iata: "KLA",
        countryIata: "UGKLA",
        country: "Uganda",
        countryCode: "UG",
    },
    {
        airportName: "Kasese",
        city: "Kasese",
        iata: "KSE",
        countryIata: "UGKSE",
        country: "Uganda",
        countryCode: "UG",
    },
    {
        airportName: "Mbarara",
        city: "Mbarara",
        iata: "MBQ",
        countryIata: "UGMBQ",
        country: "Uganda",
        countryCode: "UG",
    },
    {
        airportName: "Moyo",
        city: "Moyo",
        iata: "OYG",
        countryIata: "UGOYG",
        country: "Uganda",
        countryCode: "UG",
    },
    {
        airportName: "Pakuba",
        city: "Pakuba",
        iata: "PAF",
        countryIata: "UGPAF",
        country: "Uganda",
        countryCode: "UG",
    },
    {
        airportName: "Arua",
        city: "Arua",
        iata: "RUA",
        countryIata: "UGRUA",
        country: "Uganda",
        countryCode: "UG",
    },
    {
        airportName: "Soroti",
        city: "Soroti",
        iata: "SRT",
        countryIata: "UGSRT",
        country: "Uganda",
        countryCode: "UG",
    },
    {
        airportName: "Tororo",
        city: "Tororo",
        iata: "TRY",
        countryIata: "UGTRY",
        country: "Uganda",
        countryCode: "UG",
    },
    {
        airportName: "Gulu",
        city: "Gulu",
        iata: "ULU",
        countryIata: "UGULU",
        country: "Uganda",
        countryCode: "UG",
    },
    {
        airportName: "Chernigov",
        city: "Chernigov",
        iata: "CEJ",
        countryIata: "UACEJ",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Cherkassy",
        city: "Cherkassy",
        iata: "CKC",
        countryIata: "UACKC",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Chernovtsy",
        city: "Chernovtsy",
        iata: "CWC",
        countryIata: "UACWC",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Dnipropetrovsk Intnl",
        city: "Dnipro",
        iata: "DNK",
        countryIata: "UADNK",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Donetsk Airport (closed since 26 May 2014)",
        city: "Donetsk",
        iata: "DOK",
        countryIata: "UADOK",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Berdyansk",
        city: "Berdyansk",
        iata: "ERD",
        countryIata: "UAERD",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Khmelnitskiy",
        city: "Khmelnitskiy",
        iata: "HMJ",
        countryIata: "UAHMJ",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Kharkov",
        city: "Kharkov",
        iata: "HRK",
        countryIata: "UAHRK",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Zhulhany",
        city: "Kiev",
        iata: "IEV",
        countryIata: "UAIEV",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Ivano-Frankovsk",
        city: "Ivano-Frankovsk",
        iata: "IFO",
        countryIata: "UAIFO",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Borispol",
        city: "Kiev",
        iata: "KBP",
        countryIata: "UAKBP",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Kamenets-Podolskiy",
        city: "Kamenets-Podolskiy",
        iata: "KCP",
        countryIata: "UAKCP",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Kirovograd",
        city: "Kirovograd",
        iata: "KGO",
        countryIata: "UAKGO",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Kherson",
        city: "Kherson",
        iata: "KHE",
        countryIata: "UAKHE",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Kremenchug",
        city: "Kremenchug",
        iata: "KHU",
        countryIata: "UAKHU",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Kramatorsk",
        city: "Kramatorsk",
        iata: "KRQ",
        countryIata: "UAKRQ",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Krivoy Rog",
        city: "Krivoy Rog",
        iata: "KWG",
        countryIata: "UAKWG",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Snilow",
        city: "Lvov",
        iata: "LWO",
        countryIata: "UALWO",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Mariupol",
        city: "Mariupol",
        iata: "MPW",
        countryIata: "UAMPW",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Mirgorod",
        city: "Mirgorod",
        iata: "MXR",
        countryIata: "UAMXR",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Nikolaev",
        city: "Nikolaev",
        iata: "NLV",
        countryIata: "UANLV",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Central",
        city: "Odessa",
        iata: "ODS",
        countryIata: "UAODS",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Zaporozhye",
        city: "Zaporozhye",
        iata: "OZH",
        countryIata: "UAOZH",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Poltava",
        city: "Poltava",
        iata: "PLV",
        countryIata: "UAPLV",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Rovno",
        city: "Rovno",
        iata: "RWN",
        countryIata: "UARWN",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Severodoneck",
        city: "Severodoneck",
        iata: "SEV",
        countryIata: "UASEV",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Simferopol International Airport",
        city: "Simferopol",
        iata: "SIP",
        countryIata: "UASIP",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Ternopol",
        city: "Ternopol",
        iata: "TNL",
        countryIata: "UATNL",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Lutsk",
        city: "Lutsk",
        iata: "UCK",
        countryIata: "UAUCK",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Uzhhorod Airport",
        city: "Uzhhorod (Ungvár)",
        iata: "UDJ",
        countryIata: "UAUDJ",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Sumy",
        city: "Sumy",
        iata: "UMY",
        countryIata: "UAUMY",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Vinnica",
        city: "Vinnica",
        iata: "VIN",
        countryIata: "UAVIN",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Lugansk",
        city: "Lugansk",
        iata: "VSG",
        countryIata: "UAVSG",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Zhitomir",
        city: "Zhitomir",
        iata: "ZTR",
        countryIata: "UAZTR",
        country: "Ukraine",
        countryCode: "UA",
    },
    {
        airportName: "Al Ain International Airport",
        city: "Al Ain",
        iata: "AAN",
        countryIata: "AEAAN",
        country: "United Arab Emirates (the)",
        countryCode: "AE",
    },
    {
        airportName: "Abu Dhabi International",
        city: "Abu Dhabi",
        iata: "AUH",
        countryIata: "AEAUH",
        country: "United Arab Emirates (the)",
        countryCode: "AE",
    },
    {
        airportName: "Al Bateen Executive Airport",
        city: "Abu Dhabi",
        iata: "AZI",
        countryIata: "AEAZI",
        country: "United Arab Emirates (the)",
        countryCode: "AE",
    },
    {
        airportName: "Al Dhafra Military Apt",
        city: "Abu Dhabi",
        iata: "DHF",
        countryIata: "AEDHF",
        country: "United Arab Emirates (the)",
        countryCode: "AE",
    },
    {
        airportName: "Dubai",
        city: "Dubai",
        iata: "DXB",
        countryIata: "AEDXB",
        country: "United Arab Emirates (the)",
        countryCode: "AE",
    },
    {
        airportName: "Fujairah International Airport",
        city: "Al-Fujairah",
        iata: "FJR",
        countryIata: "AEFJR",
        country: "United Arab Emirates (the)",
        countryCode: "AE",
    },
    {
        airportName: "Military Airport",
        city: "Minhad Ab",
        iata: "NHD",
        countryIata: "AENHD",
        country: "United Arab Emirates (the)",
        countryCode: "AE",
    },
    {
        airportName: "Ajman International Airport (under construction)",
        city: "Ajman",
        iata: "QAJ",
        countryIata: "AEQAJ",
        country: "United Arab Emirates (the)",
        countryCode: "AE",
    },
    {
        airportName: "Ras Al Khaimah",
        city: "Ras Al Khaimah",
        iata: "RKT",
        countryIata: "AERKT",
        country: "United Arab Emirates (the)",
        countryCode: "AE",
    },
    {
        airportName: "Sharjah",
        city: "Sharjah",
        iata: "SHJ",
        countryIata: "AESHJ",
        country: "United Arab Emirates (the)",
        countryCode: "AE",
    },
    {
        airportName: "Dyce",
        city: "Aberdeen",
        iata: "ABZ",
        countryIata: "GBABZ",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Alderney Airport - The Blaye",
        city: "Alderney (one of the Channel Islands)",
        iata: "ACI",
        countryIata: "GBACI",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Andover",
        city: "Andover",
        iata: "ADV",
        countryIata: "GBADV",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Bembridge",
        city: "Bembridge",
        iata: "BBP",
        countryIata: "GBBBP",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Blackbush",
        city: "Blackbush",
        iata: "BBS",
        countryIata: "GBBBS",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Benbecula",
        city: "Benbecula",
        iata: "BEB",
        countryIata: "GBBEB",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Honington",
        city: "Bury St Edmunds",
        iata: "BEQ",
        countryIata: "GBBEQ",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "RAF Station",
        city: "Benson",
        iata: "BEX",
        countryIata: "GBBEX",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Belfast International Airport",
        city: "Belfast",
        iata: "BFS",
        countryIata: "GBBFS",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "George Best Belfast City Airport",
        city: "Belfast",
        iata: "BHD",
        countryIata: "GBBHD",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "International",
        city: "Birmingham",
        iata: "BHX",
        countryIata: "GBBHX",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Blackpool",
        city: "Blackpool",
        iata: "BLK",
        countryIata: "GBBLK",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "International",
        city: "Bournemouth",
        iata: "BOH",
        countryIata: "GBBOH",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Bally Kelly",
        city: "Bally Kelly",
        iata: "BOL",
        countryIata: "GBBOL",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Biggin Hill",
        city: "London",
        iata: "BQH",
        countryIata: "GBBQH",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Bradford",
        city: "Bradford",
        iata: "BRF",
        countryIata: "GBBRF",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "North Bay",
        city: "Barra",
        iata: "BRR",
        countryIata: "GBBRR",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Bristol",
        city: "Bristol",
        iata: "BRS",
        countryIata: "GBBRS",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Brighton",
        city: "Brighton",
        iata: "BSH",
        countryIata: "GBBSH",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Burtonwood",
        city: "Burtonwood",
        iata: "BUT",
        countryIata: "GBBUT",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Walney Island",
        city: "Barrow-In-Furness",
        iata: "BWF",
        countryIata: "GBBWF",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Bentwaters St",
        city: "Woodbridge",
        iata: "BWY",
        countryIata: "GBBWY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "RAF Station",
        city: "Brize Norton",
        iata: "BZZ",
        countryIata: "GBBZZ",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Machrihanish",
        city: "Campbeltown",
        iata: "CAL",
        countryIata: "GBCAL",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Carlisle",
        city: "Carlisle",
        iata: "CAX",
        countryIata: "GBCAX",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Cambridge",
        city: "Cambridge",
        iata: "CBG",
        countryIata: "GBCBG",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Chester",
        city: "Chester",
        iata: "CEG",
        countryIata: "GBCEG",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Coll Island",
        city: "Coll Island",
        iata: "COL",
        countryIata: "GBCOL",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Cromarty",
        city: "Cromarty",
        iata: "CRN",
        countryIata: "GBCRN",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Colonsay Island",
        city: "Colonsay Island",
        iata: "CSA",
        countryIata: "GBCSA",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Baginton",
        city: "Coventry",
        iata: "CVT",
        countryIata: "GBCVT",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Cardiff-Wales Airport",
        city: "Cardiff",
        iata: "CWL",
        countryIata: "GBCWL",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Dundee",
        city: "Dundee",
        iata: "DND",
        countryIata: "GBDND",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Dornoch",
        city: "Dornoch",
        iata: "DOC",
        countryIata: "GBDOC",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Robin Hood Airport Doncaster Sheffield",
        city: "Doncaster",
        iata: "DSA",
        countryIata: "GBDSA",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Turnhouse",
        city: "Edinburgh",
        iata: "EDI",
        countryIata: "GBEDI",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "East Midlands",
        city: "Nottingham",
        iata: "EMA",
        countryIata: "GBEMA",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "St Angelo",
        city: "Enniskillen",
        iata: "ENK",
        countryIata: "GBENK",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Eday",
        city: "Eday",
        iata: "EOI",
        countryIata: "GBEOI",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Shoreham",
        city: "Shoreham By Sea",
        iata: "ESH",
        countryIata: "GBESH",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Greenham RAF",
        city: "Newbury",
        iata: "EWY",
        countryIata: "GBEWY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Exeter",
        city: "Exeter",
        iata: "EXT",
        countryIata: "GBEXT",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Farnborough",
        city: "Farnborough",
        iata: "FAB",
        countryIata: "GBFAB",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Fetlar",
        city: "Fetlar",
        iata: "FEA",
        countryIata: "GBFEA",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "RAF Station",
        city: "Fairford",
        iata: "FFD",
        countryIata: "GBFFD",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Fair Isle",
        city: "Fair Isle",
        iata: "FIE",
        countryIata: "GBFIE",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Sculthorp RAF",
        city: "Fakenham",
        iata: "FKH",
        countryIata: "GBFKH",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Flotta",
        city: "Flotta",
        iata: "FLH",
        countryIata: "GBFLH",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Foula",
        city: "Foula",
        iata: "FOA",
        countryIata: "GBFOA",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Kinloss",
        city: "Forres",
        iata: "FSS",
        countryIata: "GBFSS",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Heliport",
        city: "Fort William",
        iata: "FWM",
        countryIata: "GBFWM",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Filton",
        city: "Filton",
        iata: "FZO",
        countryIata: "GBFZO",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Glasgow International",
        city: "Glasgow",
        iata: "GLA",
        countryIata: "GBGLA",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Gloucestershire",
        city: "Gloucester",
        iata: "GLO",
        countryIata: "GBGLO",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "RAF Station",
        city: "Machrihanish",
        iata: "GQJ",
        countryIata: "GBGQJ",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Binbrook",
        city: "Grimsby",
        iata: "GSY",
        countryIata: "GBGSY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "NAF",
        city: "Mildenhall",
        iata: "GXH",
        countryIata: "GBGXH",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Haverfordwest",
        city: "Haverfordwest",
        iata: "HAW",
        countryIata: "GBHAW",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Hendon",
        city: "Hendon",
        iata: "HEN",
        countryIata: "GBHEN",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Holyhead",
        city: "Holyhead",
        iata: "HLY",
        countryIata: "GBHLY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Hoy Island",
        city: "Hoy Island",
        iata: "HOY",
        countryIata: "GBHOY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Linton-On-Ouse",
        city: "Harrogate",
        iata: "HRT",
        countryIata: "GBHRT",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Hatfield",
        city: "Hatfield",
        iata: "HTF",
        countryIata: "GBHTF",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Humberside Airport",
        city: "Humberside",
        iata: "HUY",
        countryIata: "GBHUY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "High Wycombe",
        city: "High Wycombe",
        iata: "HYC",
        countryIata: "GBHYC",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Glenegedale",
        city: "Islay",
        iata: "ILY",
        countryIata: "GBILY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Inverness",
        city: "Inverness",
        iata: "INV",
        countryIata: "GBINV",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Ronaldsway",
        city: "Isle Of Man",
        iata: "IOM",
        countryIata: "GBIOM",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Ipswich",
        city: "Ipswich",
        iata: "IPW",
        countryIata: "GBIPW",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "St Marys",
        city: "Isles Of Scilly",
        iata: "ISC",
        countryIata: "GBISC",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "States",
        city: "Jersey",
        iata: "JER",
        countryIata: "GBJER",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Marham RAF",
        city: "Kings Lynn",
        iata: "KNF",
        countryIata: "GBKNF",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Kirkwall Airport",
        city: "Kirkwall (Orkney Is)",
        iata: "KOI",
        countryIata: "GBKOI",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Leeds/Bradford",
        city: "Leeds",
        iata: "LBA",
        countryIata: "GBLBA",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "London City Airport",
        city: "London",
        iata: "LCY",
        countryIata: "GBLCY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Eglinton",
        city: "Londonderry",
        iata: "LDY",
        countryIata: "GBLDY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Lands End",
        city: "Lands End",
        iata: "LEQ",
        countryIata: "GBLEQ",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Gatwick",
        city: "London",
        iata: "LGW",
        countryIata: "GBLGW",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Heathrow",
        city: "London",
        iata: "LHR",
        countryIata: "GBLHR",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Lakenheath RAF",
        city: "Brandon",
        iata: "LKZ",
        countryIata: "GBLKZ",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "RAF Station",
        city: "Lossiemouth",
        iata: "LMO",
        countryIata: "GBLMO",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Metropolitan Area",
        city: "London",
        iata: "LON",
        countryIata: "GBLON",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Heliport",
        city: "Lochgilphead",
        iata: "LPH",
        countryIata: "GBLPH",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Liverpool International Airport",
        city: "Liverpool",
        iata: "LPL",
        countryIata: "GBLPL",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Sumburgh",
        city: "Shetland Islands",
        iata: "LSI",
        countryIata: "GBLSI",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Luton Airport",
        city: "London",
        iata: "LTN",
        countryIata: "GBLTN",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Lerwick/Tingwall",
        city: "Shetland Islands",
        iata: "LWK",
        countryIata: "GBLWK",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "RAF Station",
        city: "Lyneham",
        iata: "LYE",
        countryIata: "GBLYE",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Lydd International Airport",
        city: "Lydd",
        iata: "LYX",
        countryIata: "GBLYX",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Manchester Airport",
        city: "Manchester",
        iata: "MAN",
        countryIata: "GBMAN",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Mildenhall Airport",
        city: "Mildenhall",
        iata: "MHZ",
        countryIata: "GBMHZ",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Teesside International",
        city: "Teesside",
        iata: "MME",
        countryIata: "GBMME",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Kent International",
        city: "Manston",
        iata: "MSE",
        countryIata: "GBMSE",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Newcastle Airport",
        city: "Newcastle",
        iata: "NCL",
        countryIata: "GBNCL",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Sanday",
        city: "Sanday",
        iata: "NDY",
        countryIata: "GBNDY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Northolt",
        city: "Northolt",
        iata: "NHT",
        countryIata: "GBNHT",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Nottingham Airport",
        city: "Nottingham",
        iata: "NQT",
        countryIata: "GBNQT",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "St Mawgan",
        city: "Newquay",
        iata: "NQY",
        countryIata: "GBNQY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "North Ronaldsay",
        city: "North Ronaldsay",
        iata: "NRL",
        countryIata: "GBNRL",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Norwich Airport",
        city: "Norwich",
        iata: "NWI",
        countryIata: "GBNWI",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Connel",
        city: "Oban",
        iata: "OBN",
        countryIata: "GBOBN",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "RAF Station",
        city: "Odiham",
        iata: "ODH",
        countryIata: "GBODH",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Heliport",
        city: "Oban",
        iata: "OHP",
        countryIata: "GBOHP",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Cottesmor RAF",
        city: "Oakham",
        iata: "OKH",
        countryIata: "GBOKH",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Northampton",
        city: "Northampton",
        iata: "ORM",
        countryIata: "GBORM",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Outer Skerries",
        city: "Outer Skerries",
        iata: "OUK",
        countryIata: "GBOUK",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Kidlington",
        city: "Oxford",
        iata: "OXF",
        countryIata: "GBOXF",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Prestwick",
        city: "Glasgow",
        iata: "PIK",
        countryIata: "GBPIK",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Plymouth",
        city: "Plymouth",
        iata: "PLH",
        countryIata: "GBPLH",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Portsmouth",
        city: "Portsmouth",
        iata: "PME",
        countryIata: "GBPME",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Papa Westray",
        city: "Papa Westray",
        iata: "PPW",
        countryIata: "GBPPW",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Papa Stour",
        city: "Papa Stour",
        iata: "PSV",
        countryIata: "GBPSV",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Penzance",
        city: "Penzance",
        iata: "PZE",
        countryIata: "GBPZE",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "RAF Station",
        city: "Coningsby",
        iata: "QCY",
        countryIata: "GBQCY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Goodwood Aerodrome",
        city: "Chichester",
        iata: "QUG",
        countryIata: "GBQUG",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "RAF Station",
        city: "Wyton",
        iata: "QUY",
        countryIata: "GBQUY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Rothesay Heliport",
        city: "Rothesay",
        iata: "RAY",
        countryIata: "GBRAY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Rochester",
        city: "Rochester",
        iata: "RCS",
        countryIata: "GBRCS",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Scatsta",
        city: "Shetland Islands",
        iata: "SCS",
        countryIata: "GBSCS",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Metropolitan Area",
        city: "Shetland Islands",
        iata: "SDZ",
        countryIata: "GBSDZ",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Municipal",
        city: "Southend",
        iata: "SEN",
        countryIata: "GBSEN",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Broadford",
        city: "Isle of Skye",
        iata: "SKL",
        countryIata: "GBSKL",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Eastleigh",
        city: "Southampton",
        iata: "SOU",
        countryIata: "GBSOU",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Stronsay",
        city: "Stronsay",
        iata: "SOY",
        countryIata: "GBSOY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "RAF Station",
        city: "Scampton",
        iata: "SQZ",
        countryIata: "GBSQZ",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Stansted",
        city: "London",
        iata: "STN",
        countryIata: "GBSTN",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Swindon",
        city: "Swindon",
        iata: "SWI",
        countryIata: "GBSWI",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Fairwood Comm",
        city: "Swansea",
        iata: "SWS",
        countryIata: "GBSWS",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Stornoway",
        city: "Stornoway",
        iata: "SYY",
        countryIata: "GBSYY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Sheffield City Airport",
        city: "Sheffield",
        iata: "SZD",
        countryIata: "GBSZD",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Tiree",
        city: "Tiree",
        iata: "TRE",
        countryIata: "GBTRE",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Tresco",
        city: "Isles Of Scilly",
        iata: "TSO",
        countryIata: "GBTSO",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Tottenham Hale Station",
        city: "Tottenham Hale Station",
        iata: "TTK",
        countryIata: "GBTTK",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "RAF Station",
        city: "Upper Heyford",
        iata: "UHF",
        countryIata: "GBUHF",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Mull",
        city: "Mull",
        iata: "ULL",
        countryIata: "GBULL",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Baltasound",
        city: "Unst Shetland Is",
        iata: "UNT",
        countryIata: "GBUNT",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Upavon",
        city: "Upavon",
        iata: "UPV",
        countryIata: "GBUPV",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "West Malling",
        city: "West Malling",
        iata: "WEM",
        countryIata: "GBWEM",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Woodford",
        city: "Woodford",
        iata: "WFD",
        countryIata: "GBWFD",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Whalsay",
        city: "Whalsay",
        iata: "WHS",
        countryIata: "GBWHS",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Wick",
        city: "Wick",
        iata: "WIC",
        countryIata: "GBWIC",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Woodbridge RAF",
        city: "Suttonheath",
        iata: "WOB",
        countryIata: "GBWOB",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Westray",
        city: "Westray",
        iata: "WRY",
        countryIata: "GBWRY",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "RAF Station",
        city: "Waddington",
        iata: "WTN",
        countryIata: "GBWTN",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Wether Fld RAF",
        city: "Braintree",
        iata: "WXF",
        countryIata: "GBWXF",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Yeovilton",
        city: "Yeovilton",
        iata: "YEO",
        countryIata: "GBYEO",
        country: "United Kingdom of Great Britain and Northern Ireland (the)",
        countryCode: "GB",
    },
    {
        airportName: "Artigas",
        city: "Artigas",
        iata: "ATI",
        countryIata: "UYATI",
        country: "Uruguay",
        countryCode: "UY",
    },
    {
        airportName: "Bella Union",
        city: "Bella Union",
        iata: "BUV",
        countryIata: "UYBUV",
        country: "Uruguay",
        countryCode: "UY",
    },
    {
        airportName: "Colonia",
        city: "Colonia",
        iata: "CYR",
        countryIata: "UYCYR",
        country: "Uruguay",
        countryCode: "UY",
    },
    {
        airportName: "Santa Bernardina Airport",
        city: "Durazno",
        iata: "DZO",
        countryIata: "UYDZO",
        country: "Uruguay",
        countryCode: "UY",
    },
    {
        airportName: "Melo",
        city: "Melo",
        iata: "MLZ",
        countryIata: "UYMLZ",
        country: "Uruguay",
        countryCode: "UY",
    },
    {
        airportName: "Carrasco",
        city: "Montevideo",
        iata: "MVD",
        countryIata: "UYMVD",
        country: "Uruguay",
        countryCode: "UY",
    },
    {
        airportName: "Punta Del Este",
        city: "Punta Del Este",
        iata: "PDP",
        countryIata: "UYPDP",
        country: "Uruguay",
        countryCode: "UY",
    },
    {
        airportName: "Paysandu",
        city: "Paysandu",
        iata: "PDU",
        countryIata: "UYPDU",
        country: "Uruguay",
        countryCode: "UY",
    },
    {
        airportName: "Rivera",
        city: "Rivera",
        iata: "RVY",
        countryIata: "UYRVY",
        country: "Uruguay",
        countryCode: "UY",
    },
    {
        airportName: "Salto",
        city: "Salto",
        iata: "STY",
        countryIata: "UYSTY",
        country: "Uruguay",
        countryCode: "UY",
    },
    {
        airportName: "Tacuarembo",
        city: "Tacuarembo",
        iata: "TAW",
        countryIata: "UYTAW",
        country: "Uruguay",
        countryCode: "UY",
    },
    {
        airportName: "Treinta-y-Tres",
        city: "Treinta-y-Tres",
        iata: "TYT",
        countryIata: "UYTYT",
        country: "Uruguay",
        countryCode: "UY",
    },
    {
        airportName: "Vichadero",
        city: "Vichadero",
        iata: "VCH",
        countryIata: "UYVCH",
        country: "Uruguay",
        countryCode: "UY",
    },
    {
        airportName: "Municipal",
        city: "Apalachicola",
        iata: "AAF",
        countryIata: "USAAF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lehigh Valley International",
        city: "Allentown",
        iata: "ABE",
        countryIata: "USABE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Abilene",
        iata: "ABI",
        countryIata: "USABI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ambler",
        city: "Ambler",
        iata: "ABL",
        countryIata: "USABL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Albuquerque International Sunport",
        city: "Albuquerque",
        iata: "ABQ",
        countryIata: "USABQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Aberdeen",
        iata: "ABR",
        countryIata: "USABR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dougherty County",
        city: "Albany",
        iata: "ABY",
        countryIata: "USABY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Memorial",
        city: "Nantucket",
        iata: "ACK",
        countryIata: "USACK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Waco",
        iata: "ACT",
        countryIata: "USACT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Arcata",
        city: "Arcata",
        iata: "ACV",
        countryIata: "USACV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Atlantic City International",
        city: "Atlantic City",
        iata: "ACY",
        countryIata: "USACY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lenawee County",
        city: "Adrian",
        iata: "ADG",
        countryIata: "USADG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Adak Island NS",
        city: "Adak Island",
        iata: "ADK",
        countryIata: "USADK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal Airport",
        city: "Ardmore",
        iata: "ADM",
        countryIata: "USADM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kodiak Airport",
        city: "Kodiak",
        iata: "ADQ",
        countryIata: "USADQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Andrews",
        city: "Andrews",
        iata: "ADR",
        countryIata: "USADR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Addison Airport",
        city: "Dallas, Addison",
        iata: "ADS",
        countryIata: "USADS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ada",
        city: "Ada",
        iata: "ADT",
        countryIata: "USADT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Andrews AFB",
        city: "Camp Springs",
        iata: "ADW",
        countryIata: "USADW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Aleneva",
        city: "Aleneva",
        iata: "AED",
        countryIata: "USAED",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Albert Lea",
        city: "Albert Lea",
        iata: "AEL",
        countryIata: "USAEL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Allakaket Airport",
        city: "Allakaket",
        iata: "AET",
        countryIata: "USAET",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Alexandria International Airport",
        city: "Alexandria",
        iata: "AEX",
        countryIata: "USAEX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "USAF Academy",
        city: "Colorado Springs",
        iata: "AFF",
        countryIata: "USAFF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Jaffrey",
        iata: "AFN",
        countryIata: "USAFN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Afton",
        iata: "AFO",
        countryIata: "USAFO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fort Worth Alliance",
        city: "Fort Worth",
        iata: "AFW",
        countryIata: "USAFW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Allegheny County Airport",
        city: "Pittsburgh",
        iata: "AGC",
        countryIata: "USAGC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Angoon Seaplane Base",
        city: "Angoon",
        iata: "AGN",
        countryIata: "USAGN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Magnolia Municipal Airport",
        city: "Magnolia",
        iata: "AGO",
        countryIata: "USAGO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bush Field",
        city: "Augusta",
        iata: "AGS",
        countryIata: "USAGS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Amedee AAF",
        city: "Herlong",
        iata: "AHC",
        countryIata: "USAHC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Downtown",
        city: "Ardmore",
        iata: "AHD",
        countryIata: "USAHD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Arapahoe",
        iata: "AHF",
        countryIata: "USAHF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Amery",
        iata: "AHH",
        countryIata: "USAHH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Athens-Ben Epps Airport",
        city: "Athens",
        iata: "AHN",
        countryIata: "USAHN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Amchitka",
        city: "Amchitka",
        iata: "AHT",
        countryIata: "USAHT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Alliance",
        city: "Alliance",
        iata: "AIA",
        countryIata: "USAIA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Anita Bay",
        city: "Anita Bay",
        iata: "AIB",
        countryIata: "USAIB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Anderson",
        iata: "AID",
        countryIata: "USAID",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Aiken",
        iata: "AIK",
        countryIata: "USAIK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wainwright",
        city: "Wainwright",
        iata: "AIN",
        countryIata: "USAIN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Atlantic IA",
        iata: "AIO",
        countryIata: "USAIO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "George Downer",
        city: "Aliceville",
        iata: "AIV",
        countryIata: "USAIV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bader Field (closed 2006)",
        city: "Atlantic City",
        iata: "AIY",
        countryIata: "USAIY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lee C Fine Memorial",
        city: "Kaiser/Lake Ozark",
        iata: "AIZ",
        countryIata: "USAIZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Atka",
        city: "Atka",
        iata: "AKB",
        countryIata: "USAKB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Akron Fulton International Airport",
        city: "Akron/Canton",
        iata: "AKC",
        countryIata: "USAKC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Akiak",
        city: "Akiak",
        iata: "AKI",
        countryIata: "USAKI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "King Salmon",
        city: "King Salmon",
        iata: "AKN",
        countryIata: "USAKN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Colorado Plains Regional Airport",
        city: "Akron",
        iata: "AKO",
        countryIata: "USAKO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Anaktuvuk",
        city: "Anaktuvuk",
        iata: "AKP",
        countryIata: "USAKP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Albany International Airport",
        city: "Albany",
        iata: "ALB",
        countryIata: "USALB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Alpine",
        city: "Alpine",
        iata: "ALE",
        countryIata: "USALE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Alice International Airport",
        city: "Alice",
        iata: "ALI",
        countryIata: "USALI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Alamogordo",
        iata: "ALM",
        countryIata: "USALM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Alton",
        city: "Alton",
        iata: "ALN",
        countryIata: "USALN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Waterloo",
        city: "Waterloo IA",
        iata: "ALO",
        countryIata: "USALO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Alamosa",
        iata: "ALS",
        countryIata: "USALS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Walla Walla",
        city: "Walla Walla",
        iata: "ALW",
        countryIata: "USALW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Thomas C. Russell Field",
        city: "Alexander City",
        iata: "ALX",
        countryIata: "USALX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Alitak SPB",
        city: "Alitak",
        iata: "ALZ",
        countryIata: "USALZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rick Husband Amarillo International Airport",
        city: "Amarillo",
        iata: "AMA",
        countryIata: "USAMA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Animas Airpark",
        city: "Durango",
        iata: "AMK",
        countryIata: "USAMK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gratiot Community",
        city: "Alma",
        iata: "AMN",
        countryIata: "USAMN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ames",
        city: "Ames IA",
        iata: "AMW",
        countryIata: "USAMW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Anaheim",
        city: "Anaheim",
        iata: "ANA",
        countryIata: "USANA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Metropolitan Area",
        city: "Anniston",
        iata: "ANB",
        countryIata: "USANB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ted Stevens Anchorage International Airport",
        city: "Anchorage (AK)",
        iata: "ANC",
        countryIata: "USANC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Anderson",
        city: "Anderson",
        iata: "AND",
        countryIata: "USAND",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Aniak",
        city: "Aniak",
        iata: "ANI",
        countryIata: "USANI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Annette Island",
        city: "Annette Island",
        iata: "ANN",
        countryIata: "USANN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lee Airport",
        city: "Annapolis",
        iata: "ANP",
        countryIata: "USANP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tri-State Steuben City",
        city: "Angola",
        iata: "ANQ",
        countryIata: "USANQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Anvik",
        city: "Anvik",
        iata: "ANV",
        countryIata: "USANV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ainsworth",
        city: "Ainsworth",
        iata: "ANW",
        countryIata: "USANW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Anthony",
        city: "Anthony",
        iata: "ANY",
        countryIata: "USANY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Allen County",
        city: "Lima",
        iata: "AOH",
        countryIata: "USAOH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Martinsburg",
        city: "Altoona",
        iata: "AOO",
        countryIata: "USAOO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Amook",
        city: "Amook",
        iata: "AOS",
        countryIata: "USAOS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Arapahoe County - Centennial Airport",
        city: "Denver (CO)",
        iata: "APA",
        countryIata: "USAPA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Napa County",
        city: "Napa",
        iata: "APC",
        countryIata: "USAPC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Naples",
        city: "Naples",
        iata: "APF",
        countryIata: "USAPF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Phillips AAF",
        city: "Aberdeen",
        iata: "APG",
        countryIata: "USAPG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Camp A P Hill",
        city: "Bowling Green",
        iata: "APH",
        countryIata: "USAPH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Alpena County Regional",
        city: "Alpena",
        iata: "APN",
        countryIata: "USAPN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Marion County",
        city: "Jasper",
        iata: "APT",
        countryIata: "USAPT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Apple Valley",
        city: "Apple Valley",
        iata: "APV",
        countryIata: "USAPV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Alyeska",
        city: "Alyeska",
        iata: "AQY",
        countryIata: "USAQY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Acadiana Regional",
        city: "New Iberia",
        iata: "ARA",
        countryIata: "USARA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Ann Arbor",
        iata: "ARB",
        countryIata: "USARB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Arctic Village",
        city: "Arctic Village",
        iata: "ARC",
        countryIata: "USARC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Watertown",
        city: "Watertown",
        iata: "ART",
        countryIata: "USART",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Noble F. Lee",
        city: "Minocqua",
        iata: "ARV",
        countryIata: "USARV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Aspen-Pitkin County Airport",
        city: "Aspen",
        iata: "ASE",
        countryIata: "USASE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Boire Field",
        city: "Nashua",
        iata: "ASH",
        countryIata: "USASH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Harrison County",
        city: "Marshall",
        iata: "ASL",
        countryIata: "USASL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Talladega",
        city: "Talladega",
        iata: "ASN",
        countryIata: "USASN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Austin",
        city: "Austin",
        iata: "ASQ",
        countryIata: "USASQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Astoria",
        city: "Astoria",
        iata: "AST",
        countryIata: "USAST",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ashland",
        city: "Ashland",
        iata: "ASX",
        countryIata: "USASX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ashley",
        city: "Ashley",
        iata: "ASY",
        countryIata: "USASY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Antlers",
        city: "Antlers",
        iata: "ATE",
        countryIata: "USATE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Atqasuk",
        city: "Atqasuk",
        iata: "ATK",
        countryIata: "USATK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hartsfield-Jackson Atlanta Intl",
        city: "Atlanta",
        iata: "ATL",
        countryIata: "USATL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ohio University",
        city: "Athens",
        iata: "ATO",
        countryIata: "USATO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Artesia",
        city: "Artesia",
        iata: "ATS",
        countryIata: "USATS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Atmautluak",
        city: "Atmautluak",
        iata: "ATT",
        countryIata: "USATT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Casco Cove",
        city: "Attu Island",
        iata: "ATU",
        countryIata: "USATU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Outagamie County",
        city: "Appleton",
        iata: "ATW",
        countryIata: "USATW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Watertown",
        city: "Watertown",
        iata: "ATY",
        countryIata: "USATY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Augusta",
        city: "Augusta (ME)",
        iata: "AUG",
        countryIata: "USAUG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Alakanuk",
        city: "Alakanuk",
        iata: "AUK",
        countryIata: "USAUK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Austin",
        city: "Austin",
        iata: "AUM",
        countryIata: "USAUM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Auburn",
        city: "Auburn",
        iata: "AUN",
        countryIata: "USAUN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Auburn-Opelika",
        city: "Auburn",
        iata: "AUO",
        countryIata: "USAUO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Austin-Bergstrom Intl",
        city: "Austin (TX)",
        iata: "AUS",
        countryIata: "USAUS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Wausau",
        iata: "AUW",
        countryIata: "USAUW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal Airport",
        city: "Aurora",
        iata: "AUZ",
        countryIata: "USAUZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hendersonville",
        city: "Asheville",
        iata: "AVL",
        countryIata: "USAVL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Avon Park",
        iata: "AVO",
        countryIata: "USAVO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wilkes-Barre Intl",
        city: "Scranton",
        iata: "AVP",
        countryIata: "USAVP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Avra Valley",
        city: "Tucson (AZ)",
        iata: "AVW",
        countryIata: "USAVW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Avalon Bay",
        city: "Catalina Island",
        iata: "AVX",
        countryIata: "USAVX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal (General Aviation)",
        city: "West Memphis",
        iata: "AWM",
        countryIata: "USAWM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Alexandria Bay",
        city: "Alexandria Bay",
        iata: "AXB",
        countryIata: "USAXB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Algona",
        city: "Algona IA",
        iata: "AXG",
        countryIata: "USAXG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Alexandria Municipal Airport",
        city: "Alexandria",
        iata: "AXN",
        countryIata: "USAXN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Altus",
        iata: "AXS",
        countryIata: "USAXS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Neil Armstrong",
        city: "Wapakoneta",
        iata: "AXV",
        countryIata: "USAXV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Angel Fire",
        city: "Angel Fire",
        iata: "AXX",
        countryIata: "USAXX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "AAF Heliport",
        city: "Fort Devens",
        iata: "AYE",
        countryIata: "USAYE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ware County",
        city: "Waycross",
        iata: "AYS",
        countryIata: "USAYS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Zahn's (closed in 1980)",
        city: "Amityville",
        iata: "AYZ",
        countryIata: "USAYZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kalamazoo/Battle Creek International Airport",
        city: "Kalamazoo",
        iata: "AZO",
        countryIata: "USAZO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Beale AFB",
        city: "Marysville",
        iata: "BAB",
        countryIata: "USBAB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Barksdale AFB",
        city: "Shreveport",
        iata: "BAD",
        countryIata: "USBAD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Barnes",
        city: "Westfield",
        iata: "BAF",
        countryIata: "USBAF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lander County",
        city: "Battle Mountain",
        iata: "BAM",
        countryIata: "USBAM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Baker AAF",
        city: "Baker Island",
        iata: "BAR",
        countryIata: "USBAR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Benson",
        iata: "BBB",
        countryIata: "USBBB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bay City",
        city: "Bay City",
        iata: "BBC",
        countryIata: "USBBC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Curtis Field",
        city: "Brady",
        iata: "BBD",
        countryIata: "USBBD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Burlington",
        city: "Burlington",
        iata: "BBF",
        countryIata: "USBBF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Broken Bow",
        city: "Broken Bow",
        iata: "BBW",
        countryIata: "USBBW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wings Field",
        city: "Blue Bell",
        iata: "BBX",
        countryIata: "USBBX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Virginia Tech",
        city: "Blacksburg",
        iata: "BCB",
        countryIata: "USBCB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bear Creek",
        city: "Bear Creek",
        iata: "BCC",
        countryIata: "USBCC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bryce",
        city: "Bryce",
        iata: "BCE",
        countryIata: "USBCE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Baca Grande",
        city: "Baca Grande",
        iata: "BCJ",
        countryIata: "USBCJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Southern Seaplane",
        city: "Belle Chasse",
        iata: "BCS",
        countryIata: "USBCS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Public",
        city: "Boca Raton",
        iata: "BCT",
        countryIata: "USBCT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Baudette",
        city: "Baudette",
        iata: "BDE",
        countryIata: "USBDE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rinkenberger",
        city: "Bradford",
        iata: "BDF",
        countryIata: "USBDF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Blanding",
        city: "Blanding",
        iata: "BDG",
        countryIata: "USBDG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hartford Bradley International",
        city: "Hartford, Windsor Locks",
        iata: "BDL",
        countryIata: "USBDL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Igor I. Sikorsky Mem.",
        city: "Bridgeport",
        iata: "BDR",
        countryIata: "USBDR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Broadus Airport",
        city: "Broadus",
        iata: "BDX",
        countryIata: "USBDX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "State",
        city: "Bandon",
        iata: "BDY",
        countryIata: "USBDY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Beech",
        city: "Wichita",
        iata: "BEC",
        countryIata: "USBEC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hanscom Field",
        city: "Bedford",
        iata: "BED",
        countryIata: "USBED",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ross Field",
        city: "Benton Harbor",
        iata: "BEH",
        countryIata: "USBEH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bethel Airport",
        city: "Bethel",
        iata: "BET",
        countryIata: "USBET",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Blue Fox Bay",
        city: "Blue Fox Bay",
        iata: "BFB",
        countryIata: "USBFB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bradford",
        city: "Bradford",
        iata: "BFD",
        countryIata: "USBFD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Scotts Bluff County",
        city: "Scottsbluff",
        iata: "BFF",
        countryIata: "USBFF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bullfrog Basin",
        city: "Bullfrog Basin",
        iata: "BFG",
        countryIata: "USBFG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Boeing Fld International",
        city: "Seattle (WA)",
        iata: "BFI",
        countryIata: "USBFI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Buckley ANGB",
        city: "Denver (CO)",
        iata: "BFK",
        countryIata: "USBFK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Meadows Field",
        city: "Bakersfield",
        iata: "BFL",
        countryIata: "USBFL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mob Aerospace",
        city: "Mobile",
        iata: "BFM",
        countryIata: "USBFM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Beaver Falls",
        city: "Beaver Falls",
        iata: "BFP",
        countryIata: "USBFP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Virgil I Grissom Muni",
        city: "Bedford",
        iata: "BFR",
        countryIata: "USBFR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "County",
        city: "Beaufort",
        iata: "BFT",
        countryIata: "USBFT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Borger",
        city: "Borger",
        iata: "BGD",
        countryIata: "USBGD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Decatur County",
        city: "Bainbridge",
        iata: "BGE",
        countryIata: "USBGE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Greater Binghamton Airport",
        city: "Johnson City",
        iata: "BGM",
        countryIata: "USBGM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Big Lake",
        city: "Big Lake",
        iata: "BGQ",
        countryIata: "USBGQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Bangor",
        iata: "BGR",
        countryIata: "USBGR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Webb AFB",
        city: "Big Spring",
        iata: "BGS",
        countryIata: "USBGS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bagdad",
        city: "Bagdad",
        iata: "BGT",
        countryIata: "USBGT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bar Harbour",
        city: "Bar Harbour",
        iata: "BHB",
        countryIata: "USBHB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Birmingham",
        city: "Birmingham",
        iata: "BHM",
        countryIata: "USBHM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Big Creek",
        city: "Big Creek",
        iata: "BIC",
        countryIata: "USBIC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Block Island",
        city: "Block Island",
        iata: "BID",
        countryIata: "USBID",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Beatrice",
        city: "Beatrice",
        iata: "BIE",
        countryIata: "USBIE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Biggs AAF",
        city: "El Paso (TX)",
        iata: "BIF",
        countryIata: "USBIF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Intermediate Field",
        city: "Big Delta",
        iata: "BIG",
        countryIata: "USBIG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bishop",
        city: "Bishop",
        iata: "BIH",
        countryIata: "USBIH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Billings",
        city: "Billings",
        iata: "BIL",
        countryIata: "USBIL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bismarck",
        city: "Bismarck (ND)",
        iata: "BIS",
        countryIata: "USBIS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Keesler AFB",
        city: "Biloxi",
        iata: "BIX",
        countryIata: "USBIX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jeffco",
        city: "Broomfield",
        iata: "BJC",
        countryIata: "USBJC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bemidji",
        city: "Bemidji",
        iata: "BJI",
        countryIata: "USBJI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wayne County",
        city: "Wooster",
        iata: "BJJ",
        countryIata: "USBJJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Buckland",
        city: "Buckland",
        iata: "BKC",
        countryIata: "USBKC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Stephens County",
        city: "Breckenridge",
        iata: "BKD",
        countryIata: "USBKD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Baker",
        city: "Baker",
        iata: "BKE",
        countryIata: "USBKE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Brooks Lake",
        city: "Brooks Lake",
        iata: "BKF",
        countryIata: "USBKF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Barking Sands",
        city: "Kekaha",
        iata: "BKH",
        countryIata: "USBKH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Burke Lakefront",
        city: "Cleveland (OH)",
        iata: "BKL",
        countryIata: "USBKL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Blackstone AAF",
        city: "Blackstone",
        iata: "BKT",
        countryIata: "USBKT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Beckley",
        city: "Beckley",
        iata: "BKW",
        countryIata: "USBKW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Brookings",
        city: "Brookings",
        iata: "BKX",
        countryIata: "USBKX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Boulder City",
        city: "Boulder City",
        iata: "BLD",
        countryIata: "USBLD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bluefield/Princeton Airport",
        city: "Princeton",
        iata: "BLF",
        countryIata: "USBLF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Blythe",
        city: "Blythe",
        iata: "BLH",
        countryIata: "USBLH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bellingham",
        city: "Bellingham",
        iata: "BLI",
        countryIata: "USBLI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Monmouth County",
        city: "Belmar",
        iata: "BLM",
        countryIata: "USBLM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Blue Canyon",
        city: "Blue Canyon",
        iata: "BLU",
        countryIata: "USBLU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Scott AFB/Mid America",
        city: "Belleville",
        iata: "BLV",
        countryIata: "USBLV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bellows Field",
        city: "Waimanalo",
        iata: "BLW",
        countryIata: "USBLW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Brigham City",
        city: "Brigham City",
        iata: "BMC",
        countryIata: "USBMC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bloomington",
        city: "Bloomington",
        iata: "BMG",
        countryIata: "USBMG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bloomington-Normal",
        city: "Bloomington-Normal",
        iata: "BMI",
        countryIata: "USBMI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Berlin",
        city: "Berlin",
        iata: "BML",
        countryIata: "USBML",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Beaumont Muni",
        city: "Beaumont",
        iata: "BMT",
        countryIata: "USBMT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Big Mountain",
        city: "Big Mountain",
        iata: "BMX",
        countryIata: "USBMX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Metropolitan",
        city: "Nashville (TN)",
        iata: "BNA",
        countryIata: "USBNA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Warm Spring Bay SPB",
        city: "Baranof",
        iata: "BNF",
        countryIata: "USBNF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Banning",
        city: "Banning",
        iata: "BNG",
        countryIata: "USBNG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Barnes",
        city: "Hartford",
        iata: "BNH",
        countryIata: "USBNH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Barnwell County",
        city: "Barnwell",
        iata: "BNL",
        countryIata: "USBNL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Burns",
        city: "Burns",
        iata: "BNO",
        countryIata: "USBNO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Boone",
        city: "Boone IA",
        iata: "BNW",
        countryIata: "USBNW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bolling AFB",
        city: "Washington, DC",
        iata: "BOF",
        countryIata: "USBOF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Air Terminal Gowen Field",
        city: "Boise",
        iata: "BOI",
        countryIata: "USBOI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Brookings State",
        city: "Brookings",
        iata: "BOK",
        countryIata: "USBOK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Logan International",
        city: "Boston",
        iata: "BOS",
        countryIata: "USBOS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bartow Municipal Airport",
        city: "Bartow",
        iata: "BOW",
        countryIata: "USBOW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Grumman",
        city: "Bethpage",
        iata: "BPA",
        countryIata: "USBPA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Big Piney-Marbleton",
        city: "Big Piney",
        iata: "BPI",
        countryIata: "USBPI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Southeast Texas Regional Airport",
        city: "Beaumont",
        iata: "BPT",
        countryIata: "USBPT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Glynco Jetport",
        city: "Brunswick",
        iata: "BQK",
        countryIata: "USBQK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bartlett SPB",
        city: "Gustavus",
        iata: "BQV",
        countryIata: "USBQV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Crow Wing County",
        city: "Brainerd",
        iata: "BRD",
        countryIata: "USBRD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Whitesburg",
        iata: "BRG",
        countryIata: "USBRG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Burlington",
        city: "Burlington IA",
        iata: "BRL",
        countryIata: "USBRL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "South Padre Is. International",
        city: "Brownsville",
        iata: "BRO",
        countryIata: "USBRO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wiley Post/W.Rogers M",
        city: "Barrow",
        iata: "BRW",
        countryIata: "USBRW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Samuels Field",
        city: "Bardstown",
        iata: "BRY",
        countryIata: "USBRY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bradshaw AAF",
        city: "Pohakuloa",
        iata: "BSF",
        countryIata: "USBSF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Blairsville",
        city: "Blairsville",
        iata: "BSI",
        countryIata: "USBSI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bisbee Municipal Airport",
        city: "Bisbee",
        iata: "BSQ",
        countryIata: "USBSQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Boswell Bay",
        city: "Boswell Bay",
        iata: "BSW",
        countryIata: "USBSW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bartletts",
        city: "Bartletts",
        iata: "BSZ",
        countryIata: "USBSZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Salt Lake Skypark",
        city: "Bountiful",
        iata: "BTF",
        countryIata: "USBTF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Barter Island",
        city: "Barter Island",
        iata: "BTI",
        countryIata: "USBTI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "WK Kellogg Regional",
        city: "Battle Creek",
        iata: "BTL",
        countryIata: "USBTL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Butte",
        city: "Butte",
        iata: "BTM",
        countryIata: "USBTM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bennettsville",
        city: "Bennettsville",
        iata: "BTN",
        countryIata: "USBTN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Graham Field",
        city: "Butler",
        iata: "BTP",
        countryIata: "USBTP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Metropolitan Airport",
        city: "Baton Rouge (LA)",
        iata: "BTR",
        countryIata: "USBTR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bettles",
        city: "Bettles",
        iata: "BTT",
        countryIata: "USBTT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Burlington",
        iata: "BTV",
        countryIata: "USBTV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Beatty",
        city: "Beatty",
        iata: "BTY",
        countryIata: "USBTY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Burwell",
        iata: "BUB",
        countryIata: "USBUB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Greater Buffalo International",
        city: "Buffalo",
        iata: "BUF",
        countryIata: "USBUF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Butler",
        city: "Butler",
        iata: "BUM",
        countryIata: "USBUM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Burbank",
        city: "Burbank",
        iata: "BUR",
        countryIata: "USBUR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sea Port",
        city: "Beaver Inlet",
        iata: "BVD",
        countryIata: "USBVD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bartlesville",
        city: "Bartlesville",
        iata: "BVO",
        countryIata: "USBVO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Beluga",
        city: "Beluga",
        iata: "BVU",
        countryIata: "USBVU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Batesville",
        iata: "BVX",
        countryIata: "USBVX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Beverly",
        city: "Beverly",
        iata: "BVY",
        countryIata: "USBVY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Brawley",
        city: "Brawley",
        iata: "BWC",
        countryIata: "USBWC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Brownwood",
        city: "Brownwood",
        iata: "BWD",
        countryIata: "USBWD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Warren County",
        city: "Bowling Green",
        iata: "BWG",
        countryIata: "USBWG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Baltimore/Washington International",
        city: "Baltimore (MD)",
        iata: "BWI",
        countryIata: "USBWI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Blackwell",
        city: "Blackwell",
        iata: "BWL",
        countryIata: "USBWL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bowman",
        city: "Bowman",
        iata: "BWM",
        countryIata: "USBWM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Blaine",
        city: "Blaine",
        iata: "BWS",
        countryIata: "USBWS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "George R Carr Air Field",
        city: "Bogalusa",
        iata: "BXA",
        countryIata: "USBXA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Buckeye",
        city: "Buckeye",
        iata: "BXK",
        countryIata: "USBXK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Borrego Springs",
        city: "Borrego Springs",
        iata: "BXS",
        countryIata: "USBXS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Boundary",
        city: "Boundary",
        iata: "BYA",
        countryIata: "USBYA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Buffalo",
        iata: "BYG",
        countryIata: "USBYG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Blytheville AFB",
        city: "Blytheville",
        iata: "BYH",
        countryIata: "USBYH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bicycle Lake AAF",
        city: "Fort Irwin",
        iata: "BYS",
        countryIata: "USBYS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Blakely Island",
        city: "Blakely Island",
        iata: "BYW",
        countryIata: "USBYW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gallatin Field",
        city: "Bozeman",
        iata: "BZN",
        countryIata: "USBZN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Buzzards Pt S",
        city: "Washington, DC",
        iata: "BZS",
        countryIata: "USBZS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hinkles Ferry",
        city: "Brazoria",
        iata: "BZT",
        countryIata: "USBZT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cadillac",
        city: "Cadillac",
        iata: "CAD",
        countryIata: "USCAD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Metropolitan Airport",
        city: "Columbia (SC)",
        iata: "CAE",
        countryIata: "USCAE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Akron/Canton Regional",
        city: "Akron/Canton",
        iata: "CAK",
        countryIata: "USCAK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Caribou",
        iata: "CAR",
        countryIata: "USCAR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Corner Bay",
        city: "Corner Bay",
        iata: "CBA",
        countryIata: "USCBA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wiley Ford",
        city: "Cumberland",
        iata: "CBE",
        countryIata: "USCBE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Council Bluffs IA",
        iata: "CBF",
        countryIata: "USCBF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Colby",
        iata: "CBK",
        countryIata: "USCBK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Columbus AFB",
        city: "Columbus",
        iata: "CBM",
        countryIata: "USCBM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cabin Creek",
        city: "Cabin Creek",
        iata: "CBZ",
        countryIata: "USCBZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chaffee AFB",
        city: "Fort Chaffee",
        iata: "CCA",
        countryIata: "USCCA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cable",
        city: "Upland",
        iata: "CCB",
        countryIata: "USCCB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Century City",
        city: "Los Angeles (CA)",
        iata: "CCD",
        countryIata: "USCCD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Crane County Airport",
        city: "Crane",
        iata: "CCG",
        countryIata: "USCCG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Buchanan Field",
        city: "Concord",
        iata: "CCR",
        countryIata: "USCCR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Charles City IA",
        iata: "CCY",
        countryIata: "USCCY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cold Bay",
        city: "Cold Bay",
        iata: "CDB",
        countryIata: "USCDB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cedar City",
        city: "Cedar City",
        iata: "CDC",
        countryIata: "USCDC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Harrell Fld",
        city: "Camden",
        iata: "CDH",
        countryIata: "USCDH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lewis",
        city: "Cedar Key",
        iata: "CDK",
        countryIata: "USCDK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Candle",
        city: "Candle",
        iata: "CDL",
        countryIata: "USCDL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Woodward Field",
        city: "Camden",
        iata: "CDN",
        countryIata: "USCDN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chadron",
        city: "Chadron",
        iata: "CDR",
        countryIata: "USCDR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Childress",
        city: "Childress",
        iata: "CDS",
        countryIata: "USCDS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mudhole Smith",
        city: "Cordova",
        iata: "CDV",
        countryIata: "USCDV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Caldwell Wright",
        city: "Caldwell",
        iata: "CDW",
        countryIata: "USCDW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cessna Aircraft Field",
        city: "Wichita",
        iata: "CEA",
        countryIata: "USCEA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mc Namara Fld",
        city: "Crescent City",
        iata: "CEC",
        countryIata: "USCEC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Westover Metro Airport",
        city: "Springfield",
        iata: "CEF",
        countryIata: "USCEF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Central",
        city: "Central",
        iata: "CEM",
        countryIata: "USCEM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Oconee County",
        city: "Clemson",
        iata: "CEU",
        countryIata: "USCEU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mettle Field",
        city: "Connersville",
        iata: "CEV",
        countryIata: "USCEV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bob Sikes",
        city: "Crestview",
        iata: "CEW",
        countryIata: "USCEW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chena Hot Springs",
        city: "Chena Hot Springs",
        iata: "CEX",
        countryIata: "USCEX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Calloway County",
        city: "Murray",
        iata: "CEY",
        countryIata: "USCEY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Montezuma County",
        city: "Cortez",
        iata: "CEZ",
        countryIata: "USCEZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Coffee Point",
        city: "Coffee Point",
        iata: "CFA",
        countryIata: "USCFA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Coulter Field",
        city: "Bryan",
        iata: "CFD",
        countryIata: "USCFD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Morenci",
        city: "Clifton",
        iata: "CFT",
        countryIata: "USCFT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Coffeyville",
        iata: "CFV",
        countryIata: "USCFV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Craig SPB",
        city: "Craig",
        iata: "CGA",
        countryIata: "USCGA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cambridge",
        city: "Cambridge",
        iata: "CGE",
        countryIata: "USCGE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cuyahoga County",
        city: "Cleveland (OH)",
        iata: "CGF",
        countryIata: "USCGF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cape Girardeau",
        city: "Cape Girardeau",
        iata: "CGI",
        countryIata: "USCGI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "College Park",
        city: "College Park",
        iata: "CGS",
        countryIata: "USCGS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Merrill C Meigs",
        city: "Chicago (IL)",
        iata: "CGX",
        countryIata: "USCGX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Casa Grande",
        iata: "CGZ",
        countryIata: "USCGZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lovell Field",
        city: "Chattanooga",
        iata: "CHA",
        countryIata: "USCHA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Williams AFB",
        city: "Chandler",
        iata: "CHD",
        countryIata: "USCHD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chicago FSS",
        city: "Chicago (IL)",
        iata: "CHI",
        countryIata: "USCHI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Chickasha",
        iata: "CHK",
        countryIata: "USCHK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Challis",
        city: "Challis",
        iata: "CHL",
        countryIata: "USCHL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Albemarle",
        city: "Charlottesville",
        iata: "CHO",
        countryIata: "USCHO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Circle Hot Springs",
        city: "Circle Hot Springs",
        iata: "CHP",
        countryIata: "USCHP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "AFB Municipal",
        city: "Charleston",
        iata: "CHS",
        countryIata: "USCHS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chuathbaluk",
        city: "Chuathbaluk",
        iata: "CHU",
        countryIata: "USCHU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "State",
        city: "Chiloquin",
        iata: "CHZ",
        countryIata: "USCHZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ap In The Sky",
        city: "Catalina Island",
        iata: "CIB",
        countryIata: "USCIB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chico",
        city: "Chico",
        iata: "CIC",
        countryIata: "USCIC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cedar Rapids",
        city: "Cedar Rapids IA",
        iata: "CID",
        countryIata: "USCID",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Craig-Moffat",
        city: "Craig",
        iata: "CIG",
        countryIata: "USCIG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chalkyitsik",
        city: "Chalkyitsik",
        iata: "CIK",
        countryIata: "USCIK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Melsing Creek",
        city: "Council",
        iata: "CIL",
        countryIata: "USCIL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Carroll",
        city: "Carroll IA",
        iata: "CIN",
        countryIata: "USCIN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cairo",
        city: "Cairo",
        iata: "CIR",
        countryIata: "USCIR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chippewa County",
        city: "Sault Ste Marie",
        iata: "CIU",
        countryIata: "USCIU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chomley",
        city: "Chomley",
        iata: "CIV",
        countryIata: "USCIV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "El Cajon",
        city: "El Cajon",
        iata: "CJN",
        countryIata: "USCJN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kegelman AF",
        city: "Cherokee",
        iata: "CKA",
        countryIata: "USCKA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Benedum",
        city: "Clarksburg",
        iata: "CKB",
        countryIata: "USCKB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Crooked Creek",
        city: "Crooked Creek",
        iata: "CKD",
        countryIata: "USCKD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Clear Lake",
        city: "Clear Lake",
        iata: "CKE",
        countryIata: "USCKE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cherokee",
        city: "Cherokee",
        iata: "CKK",
        countryIata: "USCKK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fletcher Field",
        city: "Clarksdale",
        iata: "CKM",
        countryIata: "USCKM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Crookston",
        iata: "CKN",
        countryIata: "USCKN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Crane Island",
        city: "Crane Island",
        iata: "CKR",
        countryIata: "USCKR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "City",
        city: "Cordova",
        iata: "CKU",
        countryIata: "USCKU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Outlaw Field",
        city: "Clarksville",
        iata: "CKV",
        countryIata: "USCKV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chicken",
        city: "Chicken",
        iata: "CKX",
        countryIata: "USCKX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Metroport",
        city: "Clearlake",
        iata: "CLC",
        countryIata: "USCLC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Carlsbad",
        city: "Carlsbad",
        iata: "CLD",
        countryIata: "USCLD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hopkins International",
        city: "Cleveland (OH)",
        iata: "CLE",
        countryIata: "USCLE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Coalinga",
        city: "Coalinga",
        iata: "CLG",
        countryIata: "USCLG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Clintonville",
        city: "Clintonville",
        iata: "CLI",
        countryIata: "USCLI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Clinton",
        iata: "CLK",
        countryIata: "USCLK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Easterwood Field",
        city: "College Station",
        iata: "CLL",
        countryIata: "USCLL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fairchild International",
        city: "Port Angeles",
        iata: "CLM",
        countryIata: "USCLM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Clarks Point",
        city: "Clarks Point",
        iata: "CLP",
        countryIata: "USCLP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Calipatria",
        city: "Calipatria",
        iata: "CLR",
        countryIata: "USCLR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Centralia",
        city: "Chehalis",
        iata: "CLS",
        countryIata: "USCLS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Douglas",
        city: "Charlotte (NC)",
        iata: "CLT",
        countryIata: "USCLT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Columbus Municipal",
        city: "Columbus",
        iata: "CLU",
        countryIata: "USCLU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Clearwater Air Park",
        city: "Clearwater",
        iata: "CLW",
        countryIata: "USCLW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Columbus International official website",
        city: "Columbus (OH)",
        iata: "CMH",
        countryIata: "USCMH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Willard University",
        city: "Champaign",
        iata: "CMI",
        countryIata: "USCMI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Houghton County",
        city: "Hancock",
        iata: "CMX",
        countryIata: "USCMX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Camp Mccoy AAF",
        city: "Sparta",
        iata: "CMY",
        countryIata: "USCMY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Canon City",
        city: "Canon City",
        iata: "CNE",
        countryIata: "USCNE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Claremont",
        iata: "CNH",
        countryIata: "USCNH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Blosser Municipal",
        city: "Concordia",
        iata: "CNK",
        countryIata: "USCNK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Carlsbad",
        city: "Carlsbad",
        iata: "CNM",
        countryIata: "USCNM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chino",
        city: "Chino",
        iata: "CNO",
        countryIata: "USCNO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Martin Johnson",
        city: "Chanute",
        iata: "CNU",
        countryIata: "USCNU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "James Connall",
        city: "Waco",
        iata: "CNW",
        countryIata: "USCNW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Canyonlands Field",
        city: "Moab",
        iata: "CNY",
        countryIata: "USCNY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Columbia",
        city: "Columbia",
        iata: "COA",
        countryIata: "USCOA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Yellowstone Regional Airport",
        city: "Cody/Yellowstone",
        iata: "COD",
        countryIata: "USCOD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Coeur D'Alene",
        city: "Coeur D'Alene",
        iata: "COE",
        countryIata: "USCOE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Patrick AFB",
        city: "Cocoa",
        iata: "COF",
        countryIata: "USCOF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Merritt Island",
        city: "Cocoa",
        iata: "COI",
        countryIata: "USCOI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Coleman",
        city: "Coleman",
        iata: "COM",
        countryIata: "USCOM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Concord Municipal Airport",
        city: "Concord (NH)",
        iata: "CON",
        countryIata: "USCON",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cooperstown",
        city: "Cooperstown",
        iata: "COP",
        countryIata: "USCOP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Peterson Field",
        city: "Colorado Springs",
        iata: "COS",
        countryIata: "USCOS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cotulla",
        city: "Cotulla",
        iata: "COT",
        countryIata: "USCOT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Columbia Regional",
        city: "Columbia",
        iata: "COU",
        countryIata: "USCOU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Compton",
        city: "Compton",
        iata: "CPM",
        countryIata: "USCPM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Casper",
        city: "Casper",
        iata: "CPR",
        countryIata: "USCPR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bi-State Parks (Downtown Airport)",
        city: "St. Louis (MO)",
        iata: "CPS",
        countryIata: "USCPS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Grand Strand Airport",
        city: "Myrtle Beach",
        iata: "CRE",
        countryIata: "USCRE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Craig Municipal",
        city: "Jacksonville (FL)",
        iata: "CRG",
        countryIata: "USCRG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Corcoran",
        city: "Corcoran",
        iata: "CRO",
        countryIata: "USCRO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Corpus Christi",
        iata: "CRP",
        countryIata: "USCRP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Corsicana",
        city: "Corsicana",
        iata: "CRS",
        countryIata: "USCRS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Crossett",
        iata: "CRT",
        countryIata: "USCRT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Yeager Airport",
        city: "Charleston (WV)",
        iata: "CRW",
        countryIata: "USCRW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Roscoe Turner",
        city: "Corinth",
        iata: "CRX",
        countryIata: "USCRX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Crested Butte",
        city: "Crested Butte",
        iata: "CSE",
        countryIata: "USCSE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "O'Sullivan AAF",
        city: "San Luis Obispo",
        iata: "CSL",
        countryIata: "USCSL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sherman",
        city: "Clinton",
        iata: "CSM",
        countryIata: "USCSM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Carson City",
        city: "Carson City (NV)",
        iata: "CSN",
        countryIata: "USCSN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Coast Guard Heliport",
        city: "Cape Spencer",
        iata: "CSP",
        countryIata: "USCSP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Creston",
        iata: "CSQ",
        countryIata: "USCSQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Memorial",
        city: "Crossville",
        iata: "CSV",
        countryIata: "USCSV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cut Bank",
        city: "Cut Bank",
        iata: "CTB",
        countryIata: "USCTB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chestercounty Carlson",
        city: "Coatesville",
        iata: "CTH",
        countryIata: "USCTH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Canton",
        city: "Canton",
        iata: "CTK",
        countryIata: "USCTK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Peconic River",
        city: "Calverton",
        iata: "CTO",
        countryIata: "USCTO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cottonwood",
        city: "Cottonwood",
        iata: "CTW",
        countryIata: "USCTW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cortland",
        city: "Cortland",
        iata: "CTX",
        countryIata: "USCTX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cross City",
        city: "Cross City",
        iata: "CTY",
        countryIata: "USCTY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sampson County",
        city: "Clinton",
        iata: "CTZ",
        countryIata: "USCTZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Owens Field",
        city: "Columbia (SC)",
        iata: "CUB",
        countryIata: "USCUB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Cushing",
        iata: "CUH",
        countryIata: "USCUH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Columbus",
        iata: "CUS",
        countryIata: "USCUS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cube Cove",
        city: "Cube Cove",
        iata: "CUW",
        countryIata: "USCUW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cuddihy Field",
        city: "Corpus Christi",
        iata: "CUX",
        countryIata: "USCUX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Civic Ar Heli",
        city: "Pittsburgh (PA)",
        iata: "CVA",
        countryIata: "USCVA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cincinnati International",
        city: "Cincinnati (OH)",
        iata: "CVG",
        countryIata: "USCVG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Clovis",
        iata: "CVN",
        countryIata: "USCVN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Albany/Corvallis Airport",
        city: "Corvallis",
        iata: "CVO",
        countryIata: "USCVO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hughes",
        city: "Culver City",
        iata: "CVR",
        countryIata: "USCVR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Central Wisconsin",
        city: "Wausau",
        iata: "CWA",
        countryIata: "USCWA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Callaway Gardens",
        city: "Callaway Gardens",
        iata: "CWG",
        countryIata: "USCWG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Clinton",
        city: "Clinton",
        iata: "CWI",
        countryIata: "USCWI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ft Wolter AAF",
        city: "Mineral Wells",
        iata: "CWO",
        countryIata: "USCWO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Center Island",
        city: "Center Island",
        iata: "CWS",
        countryIata: "USCWS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chitina Airport",
        city: "Chitina",
        iata: "CXC",
        countryIata: "USCXC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Coldfoot",
        city: "Coldfoot",
        iata: "CXF",
        countryIata: "USCXF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Calexico",
        iata: "CXL",
        countryIata: "USCXL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Montgomery Co",
        city: "Conroe",
        iata: "CXO",
        countryIata: "USCXO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Crystal Lake",
        city: "Crystal Lake",
        iata: "CYE",
        countryIata: "USCYE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chefornak SPB",
        city: "Chefornak",
        iata: "CYF",
        countryIata: "USCYF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chatham SPB",
        city: "Chatham",
        iata: "CYM",
        countryIata: "USCYM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cheyenne Regional",
        city: "Cheyenne (WY)",
        iata: "CYS",
        countryIata: "USCYS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Intermediate",
        city: "Yakataga",
        iata: "CYT",
        countryIata: "USCYT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Copper Centre",
        city: "Copper Centre",
        iata: "CZC",
        countryIata: "USCZC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cape Romanzof",
        city: "Cape Romanzof",
        iata: "CZF",
        countryIata: "USCZF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cascade Locks/Stevens",
        city: "Cascade Locks",
        iata: "CZK",
        countryIata: "USCZK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Davison AAF",
        city: "Fort Belvoir",
        iata: "DAA",
        countryIata: "USDAA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Regional",
        city: "Daytona Beach",
        iata: "DAB",
        countryIata: "USDAB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Barstow-Daggett",
        city: "Daggett",
        iata: "DAG",
        countryIata: "USDAG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Love Field",
        city: "Fort Worth (TX)",
        iata: "DAL",
        countryIata: "USDAL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Danville",
        iata: "DAN",
        countryIata: "USDAN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "James Cox Dayton International",
        city: "Dayton",
        iata: "DAY",
        countryIata: "USDAY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Dublin",
        iata: "DBN",
        countryIata: "USDBN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Dubuque IA",
        iata: "DBQ",
        countryIata: "USDBQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dubois",
        city: "Dubois",
        iata: "DBS",
        countryIata: "USDBS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ronald Reagan National",
        city: "Washington, DC",
        iata: "DCA",
        countryIata: "USDCA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dahl Creek Airport",
        city: "Dahl Creek",
        iata: "DCK",
        countryIata: "USDCK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Decatur Hi-Way",
        city: "Decatur",
        iata: "DCR",
        countryIata: "USDCR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pyor",
        city: "Decatur",
        iata: "DCU",
        countryIata: "USDCU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dodge City Municipal",
        city: "Dodge City",
        iata: "DDC",
        countryIata: "USDDC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Decatur Airport",
        city: "Decatur",
        iata: "DEC",
        countryIata: "USDEC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Decorah IA",
        iata: "DEH",
        countryIata: "USDEH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Denver International",
        city: "Denver (CO)",
        iata: "DEN",
        countryIata: "USDEN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hyatt Regency H/P",
        city: "Dearborn",
        iata: "DEO",
        countryIata: "USDEO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Detroit City",
        city: "Detroit (MI)",
        iata: "DET",
        countryIata: "USDET",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Memorial",
        city: "Defiance",
        iata: "DFI",
        countryIata: "USDFI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dallas/Fort Worth International Airport",
        city: "Dallas (TX)",
        iata: "DFW",
        countryIata: "USDFW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Danger Bay",
        city: "Danger Bay",
        iata: "DGB",
        countryIata: "USDGB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Douglas Municipal",
        city: "Douglas",
        iata: "DGL",
        countryIata: "USDGL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAF",
        city: "Dahlgren",
        iata: "DGN",
        countryIata: "USDGN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Converse County",
        city: "Douglas",
        iata: "DGW",
        countryIata: "USDGW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dothan Airport",
        city: "Dothan",
        iata: "DHN",
        countryIata: "USDHN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dalhart",
        city: "Dalhart",
        iata: "DHT",
        countryIata: "USDHT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dickinson",
        city: "Dickinson",
        iata: "DIK",
        countryIata: "USDIK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Diomede Island",
        city: "Diomede Island",
        iata: "DIO",
        countryIata: "USDIO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dunkirk",
        city: "Dunkirk",
        iata: "DKK",
        countryIata: "USDKK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Laughlin AFB",
        city: "Del Rio",
        iata: "DLF",
        countryIata: "USDLF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Dillingham",
        iata: "DLG",
        countryIata: "USDLG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Duluth International",
        city: "Duluth",
        iata: "DLH",
        countryIata: "USDLH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dillon",
        city: "Dillon",
        iata: "DLL",
        countryIata: "USDLL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dillon",
        city: "Dillon",
        iata: "DLN",
        countryIata: "USDLN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dolomi",
        city: "Dolomi",
        iata: "DLO",
        countryIata: "USDLO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "The Dalles",
        city: "The Dalles",
        iata: "DLS",
        countryIata: "USDLS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Davis Monthan AFB",
        city: "Tucson (AZ)",
        iata: "DMA",
        countryIata: "USDMA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Deming",
        city: "Deming",
        iata: "DMN",
        countryIata: "USDMN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sedalia",
        city: "Sedalia",
        iata: "DMO",
        countryIata: "USDMO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dallas North Airport",
        city: "Fort Worth (TX)",
        iata: "DNE",
        countryIata: "USDNE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Daniel Field",
        city: "Augusta",
        iata: "DNL",
        countryIata: "USDNL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Dalton",
        iata: "DNN",
        countryIata: "USDNN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Denison IA",
        iata: "DNS",
        countryIata: "USDNS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Downtown Heliport",
        city: "Santa Ana",
        iata: "DNT",
        countryIata: "USDNT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Vermilion County",
        city: "Danville",
        iata: "DNV",
        countryIata: "USDNV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dora Bay",
        city: "Dora Bay",
        iata: "DOF",
        countryIata: "USDOF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dover AFB",
        city: "Dover (DE)",
        iata: "DOV",
        countryIata: "USDOV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dupage County",
        city: "Chicago (IL)",
        iata: "DPA",
        countryIata: "USDPA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Michael AAF",
        city: "Dugway",
        iata: "DPG",
        countryIata: "USDPG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Deer Park",
        city: "Deer Park",
        iata: "DPK",
        countryIata: "USDPK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Desert Rock",
        city: "Mercury",
        iata: "DRA",
        countryIata: "USDRA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Drummond Island",
        city: "Drummond Island",
        iata: "DRE",
        countryIata: "USDRE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Drift River",
        city: "Drift River",
        iata: "DRF",
        countryIata: "USDRF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Deering",
        city: "Deering",
        iata: "DRG",
        countryIata: "USDRG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Beauregard Parish",
        city: "De Ridder",
        iata: "DRI",
        countryIata: "USDRI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "La Plata",
        city: "Durango",
        iata: "DRO",
        countryIata: "USDRO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Del Rio",
        iata: "DRT",
        countryIata: "USDRT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Destin",
        city: "Destin",
        iata: "DSI",
        countryIata: "USDSI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Des Moines",
        city: "Des Moines IA",
        iata: "DSM",
        countryIata: "USDSM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dansville",
        city: "Dansville",
        iata: "DSV",
        countryIata: "USDSV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Delta Municipal Airport",
        city: "Delta",
        iata: "DTA",
        countryIata: "USDTA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Death Valley",
        city: "Death Valley",
        iata: "DTH",
        countryIata: "USDTH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Detroit Lakes",
        iata: "DTL",
        countryIata: "USDTL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Downtown",
        city: "Shreveport",
        iata: "DTN",
        countryIata: "USDTN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Decatur Island",
        city: "Decatur Island",
        iata: "DTR",
        countryIata: "USDTR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Detroit City",
        city: "Detroit City",
        iata: "DTT",
        countryIata: "USDTT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wayne County",
        city: "Detroit (MI)",
        iata: "DTW",
        countryIata: "USDTW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Eaker",
        city: "Durant",
        iata: "DUA",
        countryIata: "USDUA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Halliburton",
        city: "Duncan",
        iata: "DUC",
        countryIata: "USDUC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pine Island Airport",
        city: "Duck",
        iata: "DUF",
        countryIata: "USDUF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bisbee-Douglas International",
        city: "Douglas",
        iata: "DUG",
        countryIata: "USDUG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jefferson County",
        city: "Dubois",
        iata: "DUJ",
        countryIata: "USDUJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Emergency Field",
        city: "Dutch Harbor",
        iata: "DUT",
        countryIata: "USDUT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Devils Lake",
        city: "Devils Lake",
        iata: "DVL",
        countryIata: "USDVL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Davenport",
        city: "Davenport IA",
        iata: "DVN",
        countryIata: "USDVN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Phoenix-Deer Valley",
        city: "Phoenix (AZ)",
        iata: "DVT",
        countryIata: "USDVT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wright AFB",
        city: "Dayton",
        iata: "DWF",
        countryIata: "USDWF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "David Wayne Hooks",
        city: "Houston (TX)",
        iata: "DWH",
        countryIata: "USDWH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Downtown Airpark",
        city: "Oklahoma City (OK)",
        iata: "DWN",
        countryIata: "USDWN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Walt Disney World",
        city: "Orlando (FL)",
        iata: "DWS",
        countryIata: "USDWS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Danbury",
        city: "Danbury",
        iata: "DXR",
        countryIata: "USDXR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Doylestown",
        city: "Doylestown",
        iata: "DYL",
        countryIata: "USDYL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dyess AFB",
        city: "Abilene",
        iata: "DYS",
        countryIata: "USDYS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Eagle",
        city: "Eagle",
        iata: "EAA",
        countryIata: "USEAA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Phifer Field",
        city: "Wheatland",
        iata: "EAN",
        countryIata: "USEAN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kearney",
        city: "Kearney",
        iata: "EAR",
        countryIata: "USEAR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pangborn Field",
        city: "Wenatchee",
        iata: "EAT",
        countryIata: "USEAT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Eau Claire",
        city: "Eau Claire",
        iata: "EAU",
        countryIata: "USEAU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Downtown",
        city: "Baton Rouge (LA)",
        iata: "EBR",
        countryIata: "USEBR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Webster City IA",
        iata: "EBS",
        countryIata: "USEBS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Emmet County Airport",
        city: "East Tawas",
        iata: "ECA",
        countryIata: "USECA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Elizabeth City",
        city: "Elizabeth City",
        iata: "ECG",
        countryIata: "USECG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mondell",
        city: "Newcastle",
        iata: "ECS",
        countryIata: "USECS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Edna Bay",
        city: "Edna Bay",
        iata: "EDA",
        countryIata: "USEDA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Edenton",
        iata: "EDE",
        countryIata: "USEDE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Elmendorf AFB",
        city: "Anchorage (AK)",
        iata: "EDF",
        countryIata: "USEDF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Weide AAF",
        city: "Edgewood",
        iata: "EDG",
        countryIata: "USEDG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "El Dorado",
        city: "El Dorado",
        iata: "EDK",
        countryIata: "USEDK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Edwards AFB",
        city: "Edwards AFB",
        iata: "EDW",
        countryIata: "USEDW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Needles",
        city: "Needles",
        iata: "EED",
        countryIata: "USEED",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Eek",
        city: "Eek",
        iata: "EEK",
        countryIata: "USEEK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dillant-Hopkins",
        city: "Keene",
        iata: "EEN",
        countryIata: "USEEN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Eight Fathom Bight",
        city: "Eight Fathom Bight",
        iata: "EFB",
        countryIata: "USEFB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ellington Field",
        city: "Houston (TX)",
        iata: "EFD",
        countryIata: "USEFD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Newport",
        city: "Newport",
        iata: "EFK",
        countryIata: "USEFK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "East Fork",
        city: "East Fork",
        iata: "EFO",
        countryIata: "USEFO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Jefferson IA",
        iata: "EFW",
        countryIata: "USEFW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Eagle County",
        city: "Vail/Eagle",
        iata: "EGE",
        countryIata: "USEGE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Duke Field",
        city: "Valparaiso",
        iata: "EGI",
        countryIata: "USEGI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Maverick Co",
        city: "Eagle Pass",
        iata: "EGP",
        countryIata: "USEGP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Eagle River",
        city: "Eagle River",
        iata: "EGV",
        countryIata: "USEGV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Egegik",
        city: "Egegik",
        iata: "EGX",
        countryIata: "USEGX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cape Newenham",
        city: "Cape Newenham",
        iata: "EHM",
        countryIata: "USEHM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rentschler",
        city: "East Hartford",
        iata: "EHT",
        countryIata: "USEHT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Eielson AFB",
        city: "Fairbanks",
        iata: "EIL",
        countryIata: "USEIL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Murray Field",
        city: "Eureka",
        iata: "EKA",
        countryIata: "USEKA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Elkhart",
        iata: "EKI",
        countryIata: "USEKI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Elkins",
        city: "Elkins",
        iata: "EKN",
        countryIata: "USEKN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Elko",
        city: "Elko",
        iata: "EKO",
        countryIata: "USEKO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Elizabethtown",
        city: "Elizabethtown",
        iata: "EKX",
        countryIata: "USEKX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Eagle Lake",
        city: "Eagle Lake",
        iata: "ELA",
        countryIata: "USELA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Goodwin Field",
        city: "El Dorado",
        iata: "ELD",
        countryIata: "USELD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Elim",
        city: "Elim",
        iata: "ELI",
        countryIata: "USELI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Elk City",
        iata: "ELK",
        countryIata: "USELK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Corning/Elmina Regional Airport",
        city: "Elmira",
        iata: "ELM",
        countryIata: "USELM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bowers Field",
        city: "Ellensburg",
        iata: "ELN",
        countryIata: "USELN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "El Paso (TX)",
        iata: "ELP",
        countryIata: "USELP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Elfin Cove SPB",
        city: "Elfin Cove",
        iata: "ELV",
        countryIata: "USELV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ellamar",
        city: "Ellamar",
        iata: "ELW",
        countryIata: "USELW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Yelland",
        city: "Ely",
        iata: "ELY",
        countryIata: "USELY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Wellsville",
        iata: "ELZ",
        countryIata: "USELZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Embarkadero (Embarcadero)",
        city: "San Francisco (CA)",
        iata: "EMB",
        countryIata: "USEMB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Emmonak",
        city: "Emmonak",
        iata: "EMK",
        countryIata: "USEMK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kemerer",
        city: "Kemerer",
        iata: "EMM",
        countryIata: "USEMM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Emporia",
        city: "Emporia",
        iata: "EMP",
        countryIata: "USEMP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "El Monte",
        city: "El Monte",
        iata: "EMT",
        countryIata: "USEMT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kenai",
        city: "Kenai",
        iata: "ENA",
        countryIata: "USENA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Vance AFB",
        city: "Enid",
        iata: "END",
        countryIata: "USEND",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Centralia",
        iata: "ENL",
        countryIata: "USENL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Nenana",
        iata: "ENN",
        countryIata: "USENN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wendover",
        city: "Wendover",
        iata: "ENV",
        countryIata: "USENV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Kenosha",
        iata: "ENW",
        countryIata: "USENW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Keokuk",
        city: "Keokuk IA",
        iata: "EOK",
        countryIata: "USEOK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Neosho",
        city: "Neosho",
        iata: "EOS",
        countryIata: "USEOS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Browns",
        city: "Weeping Water",
        iata: "EPG",
        countryIata: "USEPG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ephrata",
        city: "Ephrata",
        iata: "EPH",
        countryIata: "USEPH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Erie",
        iata: "ERI",
        countryIata: "USERI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Coast Guard",
        city: "Eldred Rock",
        iata: "ERO",
        countryIata: "USERO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Errol",
        city: "Errol",
        iata: "ERR",
        countryIata: "USERR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kerrville",
        city: "Kerrville",
        iata: "ERV",
        countryIata: "USERV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Delta County Airport",
        city: "Escanaba",
        iata: "ESC",
        countryIata: "USESC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Orcas Island",
        city: "Eastsound",
        iata: "ESD",
        countryIata: "USESD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Esler Field",
        city: "Alexandria",
        iata: "ESF",
        countryIata: "USESF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Easton",
        city: "Easton",
        iata: "ESN",
        countryIata: "USESN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Espanola",
        city: "Espanola",
        iata: "ESO",
        countryIata: "USESO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Birchwood-Pocono",
        city: "East Stroudsburg",
        iata: "ESP",
        countryIata: "USESP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Estherville IA",
        iata: "EST",
        countryIata: "USEST",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "State",
        city: "Easton",
        iata: "ESW",
        countryIata: "USESW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "West Bend",
        city: "West Bend",
        iata: "ETB",
        countryIata: "USETB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Eastland",
        iata: "ETN",
        countryIata: "USETN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Enterprise",
        iata: "ETS",
        countryIata: "USETS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Eureka",
        city: "Eureka",
        iata: "EUE",
        countryIata: "USEUE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Weedon Field",
        city: "Eufaula",
        iata: "EUF",
        countryIata: "USEUF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Eugene",
        city: "Eugene",
        iata: "EUG",
        countryIata: "USEUG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Landing Strip",
        city: "Evadale",
        iata: "EVA",
        countryIata: "USEVA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Eveleth",
        city: "Eveleth",
        iata: "EVM",
        countryIata: "USEVM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dress Regional",
        city: "Evansville",
        iata: "EVV",
        countryIata: "USEVV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Evanston",
        city: "Evanston",
        iata: "EVW",
        countryIata: "USEVW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "New Bedford",
        city: "New Bedford",
        iata: "EWB",
        countryIata: "USEWB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "City-County",
        city: "Newton",
        iata: "EWK",
        countryIata: "USEWK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Simmons Nott",
        city: "New Bern",
        iata: "EWN",
        countryIata: "USEWN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Newark International",
        city: "Newark",
        iata: "EWR",
        countryIata: "USEWR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Excursion Inlet",
        iata: "EXI",
        countryIata: "USEXI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Yerington",
        city: "Yerington",
        iata: "EYR",
        countryIata: "USEYR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Key West",
        iata: "EYW",
        countryIata: "USEYW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Felker AAF",
        city: "Fort Eustis",
        iata: "FAF",
        countryIata: "USFAF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Fairbanks",
        iata: "FAI",
        countryIata: "USFAI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "False Island",
        city: "False Island",
        iata: "FAK",
        countryIata: "USFAK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Falcon State",
        city: "Roma",
        iata: "FAL",
        countryIata: "USFAL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Regional",
        city: "Farmington",
        iata: "FAM",
        countryIata: "USFAM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hector Field",
        city: "Fargo",
        iata: "FAR",
        countryIata: "USFAR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Airterminal",
        city: "Fresno",
        iata: "FAT",
        countryIata: "USFAT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Fayetteville",
        iata: "FAY",
        countryIata: "USFAY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Simmons AAF",
        city: "Fort Bragg",
        iata: "FBG",
        countryIata: "USFBG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ft Wainwright",
        city: "Fairbanks",
        iata: "FBK",
        countryIata: "USFBK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Faribault",
        iata: "FBL",
        countryIata: "USFBL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fort Bridger",
        city: "Fort Bridger",
        iata: "FBR",
        countryIata: "USFBR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Friday Harbor SPB",
        city: "Friday Harbor SPB",
        iata: "FBS",
        countryIata: "USFBS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Fairbury",
        iata: "FBY",
        countryIata: "USFBY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Glacier National Park",
        city: "Kalispell",
        iata: "FCA",
        countryIata: "USFCA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fresno-Chandler",
        city: "Fresno",
        iata: "FCH",
        countryIata: "USFCH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Flying Cloud Airport",
        city: "Minneapolis (MN)",
        iata: "FCM",
        countryIata: "USFCM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Butts AAF",
        city: "Colorado Springs",
        iata: "FCS",
        countryIata: "USFCS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Firing Center AAF",
        city: "Yakima",
        iata: "FCT",
        countryIata: "USFCT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Forrest City",
        iata: "FCY",
        countryIata: "USFCY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Frederick",
        iata: "FDK",
        countryIata: "USFDK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Frederick",
        iata: "FDR",
        countryIata: "USFDR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Findlay",
        city: "Findlay",
        iata: "FDY",
        countryIata: "USFDY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Albertus",
        city: "Freeport",
        iata: "FEP",
        countryIata: "USFEP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Fremont",
        iata: "FET",
        countryIata: "USFET",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Warren AFB",
        city: "Cheyenne (WY)",
        iata: "FEW",
        countryIata: "USFEW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "First Flight",
        city: "Kill Devil Hills",
        iata: "FFA",
        countryIata: "USFFA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Fairfield IA",
        iata: "FFL",
        countryIata: "USFFL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fergus Falls",
        city: "Fergus Falls",
        iata: "FFM",
        countryIata: "USFFM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Patterson AFB",
        city: "Dayton",
        iata: "FFO",
        countryIata: "USFFO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Capital City",
        city: "Frankfort (KY)",
        iata: "FFT",
        countryIata: "USFFT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fort Huachu Municipal Airport",
        city: "Sierra Vista",
        iata: "FHU",
        countryIata: "USFHU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fire Cove",
        city: "Fire Cove",
        iata: "FIC",
        countryIata: "USFIC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Elizabeth Field",
        city: "Fishers Island",
        iata: "FID",
        countryIata: "USFID",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Fillmore",
        iata: "FIL",
        countryIata: "USFIL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Five Finger",
        city: "Five Finger",
        iata: "FIV",
        countryIata: "USFIV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chess-Lambertin",
        city: "Franklin",
        iata: "FKL",
        countryIata: "USFKL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Franklin",
        iata: "FKN",
        countryIata: "USFKN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fond Du Lac",
        city: "Fond Du Lac",
        iata: "FLD",
        countryIata: "USFLD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fort Lee AAF",
        city: "Petersburg",
        iata: "FLE",
        countryIata: "USFLE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pulliam Field",
        city: "Grand Canyon",
        iata: "FLG",
        countryIata: "USFLG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Falls Bay",
        city: "Falls Bay",
        iata: "FLJ",
        countryIata: "USFLJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Fort Lauderdale",
        iata: "FLL",
        countryIata: "USFLL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Florence",
        city: "Florence",
        iata: "FLO",
        countryIata: "USFLO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Flippin",
        city: "Flippin",
        iata: "FLP",
        countryIata: "USFLP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Flat",
        city: "Flat",
        iata: "FLT",
        countryIata: "USFLT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Flushing",
        city: "New York (NY)",
        iata: "FLU",
        countryIata: "USFLU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sherman AAF",
        city: "Fort Leavenworth",
        iata: "FLV",
        countryIata: "USFLV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Fallon",
        iata: "FLX",
        countryIata: "USFLX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Five Mile",
        city: "Five Mile",
        iata: "FMC",
        countryIata: "USFMC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tipton AAF",
        city: "Fort Meade",
        iata: "FME",
        countryIata: "USFME",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Otis AFB",
        city: "Falmouth",
        iata: "FMH",
        countryIata: "USFMH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Farmington",
        iata: "FMN",
        countryIata: "USFMN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Fort Madison IA",
        iata: "FMS",
        countryIata: "USFMS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Page Field",
        city: "Fort Myers",
        iata: "FMY",
        countryIata: "USFMY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fin Creek",
        city: "Fin Creek",
        iata: "FNK",
        countryIata: "USFNK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal Airport",
        city: "Fort Collins/Loveland",
        iata: "FNL",
        countryIata: "USFNL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Funter Bay",
        iata: "FNR",
        countryIata: "USFNR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bishop",
        city: "Flint",
        iata: "FNT",
        countryIata: "USFNT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fort Bragg",
        city: "Fort Bragg",
        iata: "FOB",
        countryIata: "USFOB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fort Dodge",
        city: "Fort Dodge IA",
        iata: "FOD",
        countryIata: "USFOD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Forbes AFB",
        city: "Topeka (KS)",
        iata: "FOE",
        countryIata: "USFOE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Suffolk County",
        city: "Westhampton",
        iata: "FOK",
        countryIata: "USFOK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Morris AAF",
        city: "Forest Park",
        iata: "FOP",
        countryIata: "USFOP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fox",
        city: "Fox",
        iata: "FOX",
        countryIata: "USFOX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "St Lucie County",
        city: "Fort Pierce",
        iata: "FPR",
        countryIata: "USFPR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Perry-Foley",
        city: "Perry",
        iata: "FPY",
        countryIata: "USFPY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Republic Field",
        city: "Farmingdale",
        iata: "FRG",
        countryIata: "USFRG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "French Lick",
        iata: "FRH",
        countryIata: "USFRH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Marshall AAF",
        city: "Fort Riley",
        iata: "FRI",
        countryIata: "USFRI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fairmont",
        city: "Fairmont",
        iata: "FRM",
        countryIata: "USFRM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bryant AAF",
        city: "Fort Richardson",
        iata: "FRN",
        countryIata: "USFRN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fresh Water Bay",
        city: "Fresh Water Bay",
        iata: "FRP",
        countryIata: "USFRP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Warren County",
        city: "Front Royal",
        iata: "FRR",
        countryIata: "USFRR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fryeburg",
        city: "Fryeburg",
        iata: "FRY",
        countryIata: "USFRY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Regional(Jo Foss Fld)",
        city: "Sioux Falls",
        iata: "FSD",
        countryIata: "USFSD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Henry Post AAF",
        city: "Fort Sill",
        iata: "FSI",
        countryIata: "USFSI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Fort Scott",
        iata: "FSK",
        countryIata: "USFSK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Fort Smith",
        iata: "FSM",
        countryIata: "USFSM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Haley AAF",
        city: "Fort Sheridan",
        iata: "FSN",
        countryIata: "USFSN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pecos County",
        city: "Fort Stockton",
        iata: "FST",
        countryIata: "USFST",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fort Sumner",
        city: "Fort Sumner",
        iata: "FSU",
        countryIata: "USFSU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Godman AAF",
        city: "Fort Knox",
        iata: "FTK",
        countryIata: "USFTK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fortuna Ledge",
        city: "Fortuna Ledge",
        iata: "FTL",
        countryIata: "USFTL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Meacham Field",
        city: "Fort Worth/Dallas",
        iata: "FTW",
        countryIata: "USFTW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fulton County",
        city: "Atlanta (GA)",
        iata: "FTY",
        countryIata: "USFTY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Fullerton",
        iata: "FUL",
        countryIata: "USFUL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal/Baer Field",
        city: "Fort Wayne",
        iata: "FWA",
        countryIata: "USFWA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Carswell AFB",
        city: "Fort Worth/Dallas (TX)",
        iata: "FWH",
        countryIata: "USFWH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Farewell",
        city: "Farewell",
        iata: "FWL",
        countryIata: "USFWL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Executive",
        city: "Fort Lauderdale",
        iata: "FXE",
        countryIata: "USFXE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Flaxman Island",
        city: "Flaxman Island",
        iata: "FXM",
        countryIata: "USFXM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Forest City IA",
        iata: "FXY",
        countryIata: "USFXY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Fayetteville",
        iata: "FYM",
        countryIata: "USFYM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fort Yukon",
        city: "Fort Yukon",
        iata: "FYU",
        countryIata: "USFYU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal (Drake Fld)",
        city: "Fayetteville",
        iata: "FYV",
        countryIata: "USFYV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gabbs",
        city: "Gabbs",
        iata: "GAB",
        countryIata: "USGAB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Gadsden",
        iata: "GAD",
        countryIata: "USGAD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gage",
        city: "Gage",
        iata: "GAG",
        countryIata: "USGAG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Montgomery County",
        city: "Gaithersburg",
        iata: "GAI",
        countryIata: "USGAI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gakona",
        city: "Gakona",
        iata: "GAK",
        countryIata: "USGAK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Galena",
        city: "Galena",
        iata: "GAL",
        countryIata: "USGAL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gambell",
        city: "Gambell",
        iata: "GAM",
        countryIata: "USGAM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Great Bend",
        city: "Great Bend",
        iata: "GBD",
        countryIata: "USGBD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Galesburg",
        city: "Galesburg",
        iata: "GBG",
        countryIata: "USGBG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Galbraith Lake",
        city: "Galbraith Lake",
        iata: "GBH",
        countryIata: "USGBH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Baltimore Greenbelt T",
        city: "Baltimore (MD)",
        iata: "GBO",
        countryIata: "USGBO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Great Barrington",
        city: "Great Barrington",
        iata: "GBR",
        countryIata: "USGBR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Campbell County",
        city: "Gillette",
        iata: "GCC",
        countryIata: "USGCC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal Airport",
        city: "Garden City",
        iata: "GCK",
        countryIata: "USGCK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "National Park",
        city: "Grand Canyon",
        iata: "GCN",
        countryIata: "USGCN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Greenville",
        iata: "GCY",
        countryIata: "USGCY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Donaldson Center",
        city: "Greer",
        iata: "GDC",
        countryIata: "USGDC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Golden Horn",
        iata: "GDH",
        countryIata: "USGDH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Gardner",
        iata: "GDM",
        countryIata: "USGDM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dawson Community",
        city: "Glendive",
        iata: "GDV",
        countryIata: "USGDV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gladwin",
        city: "Gladwin",
        iata: "GDW",
        countryIata: "USGDW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sussex County",
        city: "Georgetown",
        iata: "GED",
        countryIata: "USGED",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Spokane",
        iata: "GEG",
        countryIata: "USGEG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ganes Creek",
        city: "Ganes Creek",
        iata: "GEK",
        countryIata: "USGEK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "South Big Horn County",
        city: "Greybull",
        iata: "GEY",
        countryIata: "USGEY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Malmstrom AFB",
        city: "Great Falls",
        iata: "GFA",
        countryIata: "USGFA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Togiak Fish",
        city: "Togiak Fish",
        iata: "GFB",
        countryIata: "USGFB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pope Field",
        city: "Greenfield",
        iata: "GFD",
        countryIata: "USGFD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Grand Forks",
        city: "Grand Forks",
        iata: "GFK",
        countryIata: "USGFK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Warren County",
        city: "Glens Falls",
        iata: "GFL",
        countryIata: "USGFL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Georgetown",
        city: "Georgetown",
        iata: "GGE",
        countryIata: "USGGE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gregg County Airport",
        city: "Longview",
        iata: "GGG",
        countryIata: "USGGG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Glasgow",
        iata: "GGW",
        countryIata: "USGGW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Centerville",
        iata: "GHM",
        countryIata: "USGHM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Winter Haven's Gilbert Airport",
        city: "Winter Haven",
        iata: "GIF",
        countryIata: "USGIF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Walker Field",
        city: "Grand Junction",
        iata: "GJT",
        countryIata: "USGJT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gulkana",
        city: "Gulkana",
        iata: "GKN",
        countryIata: "USGKN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gatlinburg",
        city: "Gatlinburg",
        iata: "GKT",
        countryIata: "USGKT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Renner Field",
        city: "Goodland",
        iata: "GLD",
        countryIata: "USGLD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Gainesville",
        iata: "GLE",
        countryIata: "USGLE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Greenville",
        city: "Greenville",
        iata: "GLH",
        countryIata: "USGLH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Glennallen",
        city: "Glennallen",
        iata: "GLQ",
        countryIata: "USGLQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Otsego County",
        city: "Gaylord",
        iata: "GLR",
        countryIata: "USGLR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Scholes Field",
        city: "Galveston",
        iata: "GLS",
        countryIata: "USGLS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Golovin",
        city: "Golovin",
        iata: "GLV",
        countryIata: "USGLV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Glasgow",
        iata: "GLW",
        countryIata: "USGLW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Granite Mountain",
        city: "Granite Mountain",
        iata: "GMT",
        countryIata: "USGMT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Downtown",
        city: "Greer",
        iata: "GMU",
        countryIata: "USGMU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Monument Valley",
        city: "Goulding",
        iata: "GMV",
        countryIata: "USGMV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gooding",
        city: "Gooding",
        iata: "GNG",
        countryIata: "USGNG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Milan",
        city: "Grants",
        iata: "GNT",
        countryIata: "USGNT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Goodnews Bay",
        city: "Goodnews Bay",
        iata: "GNU",
        countryIata: "USGNU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "J R Alison Municipal",
        city: "Gainesville",
        iata: "GNV",
        countryIata: "USGNV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Goodfellow AFB",
        city: "San Angelo",
        iata: "GOF",
        countryIata: "USGOF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Guthrie",
        city: "Guthrie",
        iata: "GOK",
        countryIata: "USGOK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "State",
        city: "Gold Beach",
        iata: "GOL",
        countryIata: "USGOL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "New London",
        city: "New London",
        iata: "GON",
        countryIata: "USGON",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Biloxi Regional",
        city: "Gulfport",
        iata: "GPT",
        countryIata: "USGPT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Grand Rapids",
        city: "Grand Rapids",
        iata: "GPZ",
        countryIata: "USGPZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Galion",
        city: "Galion",
        iata: "GQQ",
        countryIata: "USGQQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Austin-Straubel Field",
        city: "Green Bay",
        iata: "GRB",
        countryIata: "USGRB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Greenwood",
        city: "Greenwood",
        iata: "GRD",
        countryIata: "USGRD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Greenville",
        iata: "GRE",
        countryIata: "USGRE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gray AAF",
        city: "Tacoma",
        iata: "GRF",
        countryIata: "USGRF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Grand Island",
        city: "Grand Island",
        iata: "GRI",
        countryIata: "USGRI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gray AAF",
        city: "Killeen",
        iata: "GRK",
        countryIata: "USGRK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Grand Marais/Cook County Airport",
        city: "Grand Marais",
        iata: "GRM",
        countryIata: "USGRM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gordon",
        city: "Gordon",
        iata: "GRN",
        countryIata: "USGRN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kent County International",
        city: "Grand Rapids",
        iata: "GRR",
        countryIata: "USGRR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Seymour Johnson AFB",
        city: "Goldsboro",
        iata: "GSB",
        countryIata: "USGSB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Goshen",
        city: "Goshen",
        iata: "GSH",
        countryIata: "USGSH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Piedmont Triad International Airport",
        city: "Guilford County",
        iata: "GSO",
        countryIata: "USGSO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Greenville-Spartanbur Airport",
        city: "Greer",
        iata: "GSP",
        countryIata: "USGSP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gustavus Airport",
        city: "Gustavus",
        iata: "GST",
        countryIata: "USGST",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Great Falls",
        iata: "GTF",
        countryIata: "USGTF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Grantsburg",
        iata: "GTG",
        countryIata: "USGTG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Golden Triangle Reg.",
        city: "Columbus",
        iata: "GTR",
        countryIata: "USGTR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gettysburg",
        city: "Gettysburg",
        iata: "GTY",
        countryIata: "USGTY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gunnison",
        city: "Gunnison",
        iata: "GUC",
        countryIata: "USGUC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Edwards",
        city: "Gulf Shores",
        iata: "GUF",
        countryIata: "USGUF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gunter AFB",
        city: "Montgomery (AL)",
        iata: "GUN",
        countryIata: "USGUN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Senator Clark",
        city: "Gallup",
        iata: "GUP",
        countryIata: "USGUP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Grissom AFB",
        city: "Peru",
        iata: "GUS",
        countryIata: "USGUS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Guymon",
        city: "Guymon",
        iata: "GUY",
        countryIata: "USGUY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Gordonsville",
        iata: "GVE",
        countryIata: "USGVE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lee Gilmer Memorial",
        city: "Gainesville",
        iata: "GVL",
        countryIata: "USGVL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Majors Field",
        city: "Greenville",
        iata: "GVT",
        countryIata: "USGVT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Richards-Gebaur",
        city: "Grandview",
        iata: "GVW",
        countryIata: "USGVW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Leflore",
        city: "Greenwood",
        iata: "GWO",
        countryIata: "USGWO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Glenwood Springs",
        city: "Glenwood Springs",
        iata: "GWS",
        countryIata: "USGWS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Glendale",
        city: "Glendale",
        iata: "GWV",
        countryIata: "USGWV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Weld County",
        city: "Greeley",
        iata: "GXY",
        countryIata: "USGXY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Litchfield",
        city: "Goodyear",
        iata: "GYR",
        countryIata: "USGYR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Regional",
        city: "Gary",
        iata: "GYY",
        countryIata: "USGYY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Marion County – Rankin Fite Airport",
        city: "Hamilton",
        iata: "HAB",
        countryIata: "USHAB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Havasupai",
        city: "Havasupai",
        iata: "HAE",
        countryIata: "USHAE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Half Moon",
        city: "Half Moon",
        iata: "HAF",
        countryIata: "USHAF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dr Haines",
        city: "Three Rivers",
        iata: "HAI",
        countryIata: "USHAI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hamilton",
        city: "Hamilton",
        iata: "HAO",
        countryIata: "USHAO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Harrisburg Skyport",
        city: "Harrisburg (PA)",
        iata: "HAR",
        countryIata: "USHAR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hatbox Field",
        city: "Muskogee",
        iata: "HAX",
        countryIata: "USHAX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Haycock",
        city: "Haycock",
        iata: "HAY",
        countryIata: "USHAY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Industrial Airpark",
        city: "Hobbs",
        iata: "HBB",
        countryIata: "USHBB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hanus Bay",
        city: "Hanus Bay",
        iata: "HBC",
        countryIata: "USHBC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bobby L. Chain Mun.",
        city: "Hattiesburg",
        iata: "HBG",
        countryIata: "USHBG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hobart Bay",
        city: "Hobart Bay",
        iata: "HBH",
        countryIata: "USHBH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Humboldt",
        iata: "HBO",
        countryIata: "USHBO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hobart",
        city: "Hobart",
        iata: "HBR",
        countryIata: "USHBR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Howard County",
        city: "Big Spring",
        iata: "HCA",
        countryIata: "USHCA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Shoal Cove",
        city: "Shoal Cove",
        iata: "HCB",
        countryIata: "USHCB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Columbia County",
        city: "Hudson",
        iata: "HCC",
        countryIata: "USHCC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Holy Cross",
        city: "Holy Cross",
        iata: "HCR",
        countryIata: "USHCR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cheraw",
        city: "Cheraw",
        iata: "HCW",
        countryIata: "USHCW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hidden Falls",
        city: "Hidden Falls",
        iata: "HDA",
        countryIata: "USHDA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Brewster Field",
        city: "Holdrege",
        iata: "HDE",
        countryIata: "USHDE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dillingham Airfield",
        city: "Oahu",
        iata: "HDH",
        countryIata: "USHDH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Yampa Valley",
        city: "Hayden",
        iata: "HDN",
        countryIata: "USHDN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Herendeen",
        city: "Herendeen",
        iata: "HED",
        countryIata: "USHED",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Thompson-Robbins",
        city: "Helena",
        iata: "HEE",
        countryIata: "USHEE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hermiston Airport",
        city: "Hermiston",
        iata: "HES",
        countryIata: "USHES",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hanchey Army Heliport",
        city: "Ozark",
        iata: "HEY",
        countryIata: "USHEY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Natchez–Adams County Airport",
        city: "Natchez",
        iata: "HEZ",
        countryIata: "USHEZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Brainard",
        city: "Hartford",
        iata: "HFD",
        countryIata: "USHFD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mackall AAF",
        city: "Hoffman",
        iata: "HFF",
        countryIata: "USHFF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wash. County Regional",
        city: "Hagerstown",
        iata: "HGR",
        countryIata: "USHGR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hunter AAF",
        city: "Jolon",
        iata: "HGT",
        countryIata: "USHGT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hogatza",
        city: "Hogatza",
        iata: "HGZ",
        countryIata: "USHGZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hilton Head",
        city: "Hilton Head",
        iata: "HHH",
        countryIata: "USHHH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wheeler AFB",
        city: "Wahiawa",
        iata: "HHI",
        countryIata: "USHHI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hawthorne",
        city: "Hawthorne",
        iata: "HHR",
        countryIata: "USHHR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chisholm Airport",
        city: "Hibbing",
        iata: "HIB",
        countryIata: "USHIB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Regional",
        city: "Whitefield",
        iata: "HIE",
        countryIata: "USHIE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hill AFB",
        city: "Ogden",
        iata: "HIF",
        countryIata: "USHIF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Lake Havasu City",
        iata: "HII",
        countryIata: "USHII",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hickam AFB",
        city: "Honolulu",
        iata: "HIK",
        countryIata: "USHIK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Portland",
        city: "Hillsboro",
        iata: "HIO",
        countryIata: "USHIO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Blytheville",
        iata: "HKA",
        countryIata: "USHKA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Healy Lake",
        city: "Healy Lake",
        iata: "HKB",
        countryIata: "USHKB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hawkins Field",
        city: "Jackson (MS)",
        iata: "HKS",
        countryIata: "USHKS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hickory",
        city: "Hickory",
        iata: "HKY",
        countryIata: "USHKY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hillenbrand",
        city: "Batesville",
        iata: "HLB",
        countryIata: "USHLB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hill City",
        city: "Hill City",
        iata: "HLC",
        countryIata: "USHLC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ohio County",
        city: "Wheeling",
        iata: "HLG",
        countryIata: "USHLG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hollister",
        city: "Hollister",
        iata: "HLI",
        countryIata: "USHLI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Park Township",
        city: "Holland",
        iata: "HLM",
        countryIata: "USHLM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Helena Regional",
        city: "Helena (MT)",
        iata: "HLN",
        countryIata: "USHLN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fort Hood AAF",
        city: "Killeen",
        iata: "HLR",
        countryIata: "USHLR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Holloman AFB",
        city: "Alamogordo",
        iata: "HMN",
        countryIata: "USHMN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Homeshore",
        city: "Homeshore",
        iata: "HMS",
        countryIata: "USHMS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ryan Field",
        city: "Hemet",
        iata: "HMT",
        countryIata: "USHMT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Huntingburg",
        iata: "HNB",
        countryIata: "USHNB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hatteras",
        city: "Hatteras",
        iata: "HNC",
        countryIata: "USHNC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tahneta Pass",
        city: "Tahneta Pass Lodge",
        iata: "HNE",
        countryIata: "USHNE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hoonah",
        city: "Hoonah",
        iata: "HNH",
        countryIata: "USHNH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Honolulu (HI)",
        iata: "HNL",
        countryIata: "USHNL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hana",
        city: "Hana",
        iata: "HNM",
        countryIata: "USHNM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Haines",
        iata: "HNS",
        countryIata: "USHNS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hanna",
        city: "Hanna",
        iata: "HNX",
        countryIata: "USHNX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lea County",
        city: "Hobbs",
        iata: "HOB",
        countryIata: "USHOB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Holikachu",
        city: "Holikachu",
        iata: "HOL",
        countryIata: "USHOL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Homer",
        city: "Homer",
        iata: "HOM",
        countryIata: "USHOM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Howes",
        city: "Huron",
        iata: "HON",
        countryIata: "USHON",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Campbell AAF",
        city: "Hopkinsville",
        iata: "HOP",
        countryIata: "USHOP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Memorial Field",
        city: "Hot Springs",
        iata: "HOT",
        countryIata: "USHOT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hobby",
        city: "Houston (TX)",
        iata: "HOU",
        countryIata: "USHOU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hooper Bay",
        city: "Hooper Bay",
        iata: "HPB",
        countryIata: "USHPB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Westchester County Apt",
        city: "Westchester County",
        iata: "HPN",
        countryIata: "USHPN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Hampton IA",
        iata: "HPT",
        countryIata: "USHPT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Princeville",
        city: "Kauai Island",
        iata: "HPV",
        countryIata: "USHPV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Baytown",
        city: "Baytown",
        iata: "HPY",
        countryIata: "USHPY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bowerman Airport",
        city: "Hoquiam",
        iata: "HQM",
        countryIata: "USHQM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Valley International",
        city: "Harlingen",
        iata: "HRL",
        countryIata: "USHRL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Boone County",
        city: "Harrison",
        iata: "HRO",
        countryIata: "USHRO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Raleigh",
        city: "Harrisburg",
        iata: "HSB",
        countryIata: "USHSB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Henderson Sky Harbor",
        city: "Las Vegas (NV)",
        iata: "HSH",
        countryIata: "USHSH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hastings",
        city: "Hastings",
        iata: "HSI",
        countryIata: "USHSI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Huslia",
        city: "Huslia",
        iata: "HSL",
        countryIata: "USHSL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ingalls Field",
        city: "Hot Springs",
        iata: "HSP",
        countryIata: "USHSP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "AFB",
        city: "Homestead",
        iata: "HST",
        countryIata: "USHST",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Madison County",
        city: "Huntsville",
        iata: "HSV",
        countryIata: "USHSV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hawthorne",
        city: "Hawthorne",
        iata: "HTH",
        countryIata: "USHTH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Roscommon County",
        city: "Houghton",
        iata: "HTL",
        countryIata: "USHTL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "East Hampton",
        city: "East Hampton",
        iata: "HTO",
        countryIata: "USHTO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tri-State/Milton",
        city: "Huntington",
        iata: "HTS",
        countryIata: "USHTS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Huntsville",
        city: "Huntsville",
        iata: "HTV",
        countryIata: "USHTV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Huntington County",
        city: "Chesapeake",
        iata: "HTW",
        countryIata: "USHTW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Redstone AAF",
        city: "Huntsville",
        iata: "HUA",
        countryIata: "USHUA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Humboldt",
        city: "Humboldt IA",
        iata: "HUD",
        countryIata: "USHUD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hulman Field",
        city: "Terre Haute",
        iata: "HUF",
        countryIata: "USHUF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hugo",
        city: "Hugo",
        iata: "HUJ",
        countryIata: "USHUJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Houlton",
        iata: "HUL",
        countryIata: "USHUL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Terrebonne",
        city: "Houma",
        iata: "HUM",
        countryIata: "USHUM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Hughes",
        iata: "HUS",
        countryIata: "USHUS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hutchinson",
        city: "Hutchinson",
        iata: "HUT",
        countryIata: "USHUT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Intermediate",
        city: "Hanksville",
        iata: "HVE",
        countryIata: "USHVE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tweed New Haven Airport",
        city: "New Haven",
        iata: "HVN",
        countryIata: "USHVN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "City County",
        city: "Havre",
        iata: "HVR",
        countryIata: "USHVR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Hartsville",
        iata: "HVS",
        countryIata: "USHVS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Air Terminal",
        city: "Hayward",
        iata: "HWD",
        countryIata: "USHWD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Hawk Inlet",
        iata: "HWI",
        countryIata: "USHWI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "North Perry",
        city: "Hollywood",
        iata: "HWO",
        countryIata: "USHWO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Barnstable",
        city: "Hyannis",
        iata: "HYA",
        countryIata: "USHYA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Hydaburg",
        iata: "HYG",
        countryIata: "USHYG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Hollis",
        iata: "HYL",
        countryIata: "USHYL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Hayward",
        iata: "HYR",
        countryIata: "USHYR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Hays",
        iata: "HYS",
        countryIata: "USHYS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hazleton",
        city: "Hazleton",
        iata: "HZL",
        countryIata: "USHZL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mcconnell AFB",
        city: "Wichita",
        iata: "IAB",
        countryIata: "USIAB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dulles International",
        city: "Washington, DC",
        iata: "IAD",
        countryIata: "USIAD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Niagara Falls",
        iata: "IAG",
        countryIata: "USIAG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "George Bush Intercontinental",
        city: "Houston (TX)",
        iata: "IAH",
        countryIata: "USIAH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bob Barker Memorial",
        city: "Kiana",
        iata: "IAN",
        countryIata: "USIAN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Clarinda IA",
        iata: "ICL",
        countryIata: "USICL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mid-Continent",
        city: "Wichita",
        iata: "ICT",
        countryIata: "USICT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Icy Bay",
        city: "Icy Bay",
        iata: "ICY",
        countryIata: "USICY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fanning Field",
        city: "Idaho Falls",
        iata: "IDA",
        countryIata: "USIDA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Ida Grove IA",
        iata: "IDG",
        countryIata: "USIDG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Indiana",
        city: "Indiana",
        iata: "IDI",
        countryIata: "USIDI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Independence",
        city: "Independence",
        iata: "IDP",
        countryIata: "USIDP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Iowa Falls",
        city: "Iowa Falls IA",
        iata: "IFA",
        countryIata: "USIFA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Laughlin Bullhead International",
        city: "Bullhead City",
        iata: "IFP",
        countryIata: "USIFP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Igiugig",
        city: "Igiugig",
        iata: "IGG",
        countryIata: "USIGG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kingman",
        city: "Kingman",
        iata: "IGM",
        countryIata: "USIGM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Jacksonville",
        iata: "IJX",
        countryIata: "USIJX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wilkes County",
        city: "Wilkesboro",
        iata: "IKB",
        countryIata: "USIKB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Greater Kankakee",
        city: "Kankakee",
        iata: "IKK",
        countryIata: "USIKK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nikolski AFS",
        city: "Nikolski",
        iata: "IKO",
        countryIata: "USIKO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Killeen",
        iata: "ILE",
        countryIata: "USILE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Greater Wilmington",
        city: "Wilmington",
        iata: "ILG",
        countryIata: "USILG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Iliamna",
        city: "Iliamna",
        iata: "ILI",
        countryIata: "USILI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Willmar",
        city: "Willmar",
        iata: "ILL",
        countryIata: "USILL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "New Hanover County",
        city: "Wilmington",
        iata: "ILM",
        countryIata: "USILM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Clinton Field",
        city: "Wilmington",
        iata: "ILN",
        countryIata: "USILN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Imperial",
        city: "Imperial",
        iata: "IML",
        countryIata: "USIML",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Immokalee",
        city: "Immokalee",
        iata: "IMM",
        countryIata: "USIMM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ford",
        city: "Iron Mountain",
        iata: "IMT",
        countryIata: "USIMT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Indianapolis International",
        city: "Indianapolis (IN)",
        iata: "IND",
        countryIata: "USIND",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wink",
        city: "Wink",
        iata: "INK",
        countryIata: "USINK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Falls International",
        city: "International Falls",
        iata: "INL",
        countryIata: "USINL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kincheloe AFB",
        city: "Sault Ste Marie",
        iata: "INR",
        countryIata: "USINR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Af Aux",
        city: "Indian Springs",
        iata: "INS",
        countryIata: "USINS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Smith-Reynolds",
        city: "Winston Salem",
        iata: "INT",
        countryIata: "USINT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Winslow",
        city: "Winslow",
        iata: "INW",
        countryIata: "USINW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Iowa City",
        city: "Iowa City IA",
        iata: "IOW",
        countryIata: "USIOW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Imperial County Airport",
        city: "Imperial",
        iata: "IPL",
        countryIata: "USIPL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lycoming County",
        city: "Williamsport",
        iata: "IPT",
        countryIata: "USIPT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Iraan",
        iata: "IRB",
        countryIata: "USIRB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Circle City",
        city: "Circle",
        iata: "IRC",
        countryIata: "USIRC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Kirksville",
        iata: "IRK",
        countryIata: "USIRK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kirsch Municipal",
        city: "Sturgis",
        iata: "IRS",
        countryIata: "USIRS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Isabel Pass",
        city: "Isabel Pass",
        iata: "ISL",
        countryIata: "USISL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Kissimmee",
        iata: "ISM",
        countryIata: "USISM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sloulin Field International",
        city: "Williston",
        iata: "ISN",
        countryIata: "USISN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Stallings Field",
        city: "Kinston",
        iata: "ISO",
        countryIata: "USISO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Long Island Macarthur",
        city: "Islip",
        iata: "ISP",
        countryIata: "USISP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Schoolcraft County",
        city: "Manistique",
        iata: "ISQ",
        countryIata: "USISQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wiscasset",
        city: "Wiscasset",
        iata: "ISS",
        countryIata: "USISS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Alexander Field",
        city: "Wisconsin Rapids",
        iata: "ISW",
        countryIata: "USISW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tompkins County",
        city: "Ithaca",
        iata: "ITH",
        countryIata: "USITH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hilo International",
        city: "Hilo",
        iata: "ITO",
        countryIata: "USITO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ivishak",
        city: "Ivishak",
        iata: "IVH",
        countryIata: "USIVH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gogebic County",
        city: "Ironwood",
        iata: "IWD",
        countryIata: "USIWD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "West Houston",
        city: "Houston (TX)",
        iata: "IWS",
        countryIata: "USIWS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kern County",
        city: "Inyokern",
        iata: "IYK",
        countryIata: "USIYK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jackson Hole",
        city: "Jackson",
        iata: "JAC",
        countryIata: "USJAC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Technology Park",
        city: "Atlanta (GA)",
        iata: "JAE",
        countryIata: "USJAE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Perimeter Mall",
        city: "Atlanta (GA)",
        iata: "JAJ",
        countryIata: "USJAJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jackson International",
        city: "Jackson (MS)",
        iata: "JAN",
        countryIata: "USJAN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Beaver Ruin",
        city: "Atlanta (GA)",
        iata: "JAO",
        countryIata: "USJAO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "County",
        city: "Jasper",
        iata: "JAS",
        countryIata: "USJAS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Jacksonville (FL)",
        iata: "JAX",
        countryIata: "USJAX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Boston City Heliport",
        city: "Boston (MA)",
        iata: "JBC",
        countryIata: "USJBC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Berkeley",
        city: "Berkeley",
        iata: "JBK",
        countryIata: "USJBK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Commerce Bus Plaza",
        city: "Los Angeles (CA)",
        iata: "JBP",
        countryIata: "USJBP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jonesboro",
        city: "Jonesboro",
        iata: "JBR",
        countryIata: "USJBR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hacienda Bus.Park H/P",
        city: "Pleasanton",
        iata: "JBS",
        countryIata: "USJBS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "City Landing",
        city: "Bethel",
        iata: "JBT",
        countryIata: "USJBT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "China Basin Heliport",
        city: "San Francisco (CA)",
        iata: "JCC",
        countryIata: "USJCC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Convention Center H/P",
        city: "Oakland",
        iata: "JCE",
        countryIata: "USJCE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Johnson Industrial",
        city: "Kansas City (MO)",
        iata: "JCI",
        countryIata: "USJCI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kimble County",
        city: "Junction",
        iata: "JCT",
        countryIata: "USJCT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Citicorp Plaza H/P",
        city: "Los Angeles (CA)",
        iata: "JCX",
        countryIata: "USJCX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Johnson",
        city: "Johnson",
        iata: "JCY",
        countryIata: "USJCY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "John Day",
        city: "John Day",
        iata: "JDA",
        countryIata: "USJDA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Downtown Heliport",
        city: "Dallas/Fort Worth (TX)",
        iata: "JDB",
        countryIata: "USJDB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Downtown Heliport",
        city: "(FL)",
        iata: "JDM",
        countryIata: "USJDM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jordan",
        city: "Jordan",
        iata: "JDN",
        countryIata: "USJDN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Downtown Heliport",
        city: "Minneapolis (MN)",
        iata: "JDT",
        countryIata: "USJDT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Heliport",
        city: "Downey",
        iata: "JDY",
        countryIata: "USJDY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jefferson Memorial",
        city: "Jefferson City (MO)",
        iata: "JEF",
        countryIata: "USJEF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Heliport",
        city: "Emeryville",
        iata: "JEM",
        countryIata: "USJEM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "John F Kennedy International",
        city: "New York (NY)",
        iata: "JFK",
        countryIata: "USJFK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ashtabula",
        city: "Jefferson",
        iata: "JFN",
        countryIata: "USJFN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Heliport",
        city: "Grand Canyon",
        iata: "JGC",
        countryIata: "USJGC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Galleria",
        city: "Atlanta (GA)",
        iata: "JGL",
        countryIata: "USJGL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Greenway Plaza H/P",
        city: "Houston (TX)",
        iata: "JGP",
        countryIata: "USJGP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Transco Twr Galleria",
        city: "Houston (TX)",
        iata: "JGQ",
        countryIata: "USJGQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Heliport",
        city: "Glendale",
        iata: "JGX",
        countryIata: "USJGX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Island Heliport",
        city: "Garden City",
        iata: "JHC",
        countryIata: "USJHC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kapalua",
        city: "Kapalua",
        iata: "JHM",
        countryIata: "USJHM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jamestown",
        city: "Jamestown",
        iata: "JHW",
        countryIata: "USJHW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hyatt Regency H/P",
        city: "Cambridge",
        iata: "JHY",
        countryIata: "USJHY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "City Of Industry H/P",
        city: "Los Angeles (CA)",
        iata: "JID",
        countryIata: "USJID",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International Heliport",
        city: "Ontario",
        iata: "JIO",
        countryIata: "USJIO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jacksonville",
        city: "Jacksonville (TX)",
        iata: "JKV",
        countryIata: "USJKV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Quartz Creek",
        city: "Cooper Lodge",
        iata: "JLA",
        countryIata: "USJLA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Heliport",
        city: "Long Beach",
        iata: "JLB",
        countryIata: "USJLB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "US Army Heliport",
        city: "Arlington Heights",
        iata: "JLH",
        countryIata: "USJLH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Joplin",
        city: "Joplin",
        iata: "JLN",
        countryIata: "USJLN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Union Station H/P",
        city: "Los Angeles (CA)",
        iata: "JLX",
        countryIata: "USJLX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Marriot Astrodome",
        city: "Houston (TX)",
        iata: "JMA",
        countryIata: "USJMA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Marin County",
        city: "Sausalito",
        iata: "JMC",
        countryIata: "USJMC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Market Centre H/P",
        city: "Dallas, Fort Worth (TX)",
        iata: "JMD",
        countryIata: "USJMD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Marriott Heliport",
        city: "Schaumburg",
        iata: "JMH",
        countryIata: "USJMH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal Heliport",
        city: "Mankato",
        iata: "JMN",
        countryIata: "USJMN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jamestown",
        city: "Jamestown",
        iata: "JMS",
        countryIata: "USJMS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "North Park Inn H/P",
        city: "Dallas, Fort Worth (TX)",
        iata: "JNH",
        countryIata: "USJNH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Heliport",
        city: "Newport Beach",
        iata: "JNP",
        countryIata: "USJNP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Juneau International",
        city: "Juneau (AK)",
        iata: "JNU",
        countryIata: "USJNU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Centerport Heliport",
        city: "Santa Ana",
        iata: "JOC",
        countryIata: "USJOC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "The City Heliport",
        city: "Orange",
        iata: "JOR",
        countryIata: "USJOR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Joliet",
        iata: "JOT",
        countryIata: "USJOT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Heliport",
        city: "Pasadena",
        iata: "JPD",
        countryIata: "USJPD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pentagon Army",
        city: "Washington, DC",
        iata: "JPN",
        countryIata: "USJPN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Park Ten Heliport",
        city: "Houston (TX)",
        iata: "JPT",
        countryIata: "USJPT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal Heliport",
        city: "Rochester",
        iata: "JRC",
        countryIata: "USJRC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Heliport",
        city: "Riverside",
        iata: "JRD",
        countryIata: "USJRD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "East 60th Street H/P",
        city: "New York (NY)",
        iata: "JRE",
        countryIata: "USJRE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sikorsky Heliport",
        city: "Stratford",
        iata: "JSD",
        countryIata: "USJSD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "San Rafael H/P",
        city: "San Rafael",
        iata: "JSG",
        countryIata: "USJSG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal Heliport",
        city: "Saint Cloud",
        iata: "JSK",
        countryIata: "USJSK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Steel Pier Heliport",
        city: "Atlantic City",
        iata: "JSL",
        countryIata: "USJSL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sherman Oaks H/P",
        city: "Los Angeles (CA)",
        iata: "JSN",
        countryIata: "USJSN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cambria County",
        city: "Johnstown",
        iata: "JST",
        countryIata: "USJST",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Heliport",
        city: "Thousand Oaks",
        iata: "JTO",
        countryIata: "USJTO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Universal City H/P",
        city: "Los Angeles (CA)",
        iata: "JUC",
        countryIata: "USJUC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kupper",
        city: "Manville",
        iata: "JVI",
        countryIata: "USJVI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rock County",
        city: "Janesville",
        iata: "JVL",
        countryIata: "USJVL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Reynolds Municipal",
        city: "Jackson",
        iata: "JXN",
        countryIata: "USJXN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Kake",
        iata: "KAE",
        countryIata: "USKAE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kaltag",
        city: "Kaltag",
        iata: "KAL",
        countryIata: "USKAL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Birch Creek",
        city: "Birch Creek",
        iata: "KBC",
        countryIata: "USKBC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hot Springs SPB",
        city: "Bell Island",
        iata: "KBE",
        countryIata: "USKBE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Klag Bay",
        city: "Klag Bay",
        iata: "KBK",
        countryIata: "USKBK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chignik Bay",
        city: "Chignik",
        iata: "KBW",
        countryIata: "USKBW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Coffman Cove SPB",
        city: "Coffman Cove",
        iata: "KCC",
        countryIata: "USKCC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fisheries",
        city: "Chignik",
        iata: "KCG",
        countryIata: "USKCG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fairfax Municipal",
        city: "Kansas City",
        iata: "KCK",
        countryIata: "USKCK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lagoon",
        city: "Chignik",
        iata: "KCL",
        countryIata: "USKCL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Chernofski",
        iata: "KCN",
        countryIata: "USKCN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chignik",
        city: "Chignik",
        iata: "KCQ",
        countryIata: "USKCQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Colorado Creek",
        city: "Colorado Creek",
        iata: "KCR",
        countryIata: "USKCR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Kodiak",
        iata: "KDK",
        countryIata: "USKDK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nanwalek",
        city: "Nanwalek",
        iata: "KEB",
        countryIata: "USKEB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kenmore Air Harbor",
        city: "Kenmore Air Harbor",
        iata: "KEH",
        countryIata: "USKEH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ekwok",
        city: "Ekwok",
        iata: "KEK",
        countryIata: "USKEK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kelly Bar",
        city: "Kelly Bar",
        iata: "KEU",
        countryIata: "USKEU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "False Pass",
        city: "False Pass",
        iata: "KFP",
        countryIata: "USKFP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "New Koliganek",
        city: "New Koliganek",
        iata: "KGK",
        countryIata: "USKGK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Grayling",
        city: "Grayling",
        iata: "KGX",
        countryIata: "USKGX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Glacier Creek",
        city: "Glacier Creek",
        iata: "KGZ",
        countryIata: "USKGZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Ivanof Bay",
        iata: "KIB",
        countryIata: "USKIB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mesa Del Rey",
        city: "King City",
        iata: "KIC",
        countryIata: "USKIC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kickapoo",
        city: "Wichita Falls",
        iata: "KIP",
        countryIata: "USKIP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Koyuk",
        city: "Koyuk",
        iata: "KKA",
        countryIata: "USKKA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Kitoi Bay",
        iata: "KKB",
        countryIata: "USKKB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kagvik Creek",
        city: "Kagvik Creek",
        iata: "KKF",
        countryIata: "USKKF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kongiganak",
        city: "Kongiganak",
        iata: "KKH",
        countryIata: "USKKH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Spb",
        city: "Akiachak",
        iata: "KKI",
        countryIata: "USKKI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kalakaket AFS",
        city: "Kalakaket",
        iata: "KKK",
        countryIata: "USKKK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Karluk Lake SPB",
        city: "Karluk Lake",
        iata: "KKL",
        countryIata: "USKKL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kentland",
        city: "Kentland",
        iata: "KKT",
        countryIata: "USKKT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ekuk",
        city: "Ekuk",
        iata: "KKU",
        countryIata: "USKKU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Kalskag",
        iata: "KLG",
        countryIata: "USKLG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Levelock",
        city: "Levelock",
        iata: "KLL",
        countryIata: "USKLL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Larsen SPB",
        city: "Larsen Bay",
        iata: "KLN",
        countryIata: "USKLN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kelp Bay",
        city: "Kelp Bay",
        iata: "KLP",
        countryIata: "USKLP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Longview",
        city: "Kelso",
        iata: "KLS",
        countryIata: "USKLS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Klawock",
        city: "Klawock",
        iata: "KLW",
        countryIata: "USKLW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Manokotak SPB",
        city: "Manokotak",
        iata: "KMO",
        countryIata: "USKMO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Moser Bay",
        city: "Moser Bay",
        iata: "KMY",
        countryIata: "USKMY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kanab",
        city: "Kanab",
        iata: "KNB",
        countryIata: "USKNB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kakhonak",
        city: "Kakhonak",
        iata: "KNK",
        countryIata: "USKNK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Kennett",
        iata: "KNT",
        countryIata: "USKNT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "New Stuyahok",
        city: "New Stuyahok",
        iata: "KNW",
        countryIata: "USKNW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Keahole",
        city: "Kona",
        iata: "KOA",
        countryIata: "USKOA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kotlik",
        city: "Kotlik",
        iata: "KOT",
        countryIata: "USKOT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Olga Bay SPB",
        city: "Olga Bay",
        iata: "KOY",
        countryIata: "USKOY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ouzinkie SPB",
        city: "Ouzinkie",
        iata: "KOZ",
        countryIata: "USKOZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Point Baker SPB",
        city: "Point Baker",
        iata: "KPB",
        countryIata: "USKPB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Clarence",
        city: "Port Clarence",
        iata: "KPC",
        countryIata: "USKPC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "King Of Prussia",
        city: "King Of Prussia",
        iata: "KPD",
        countryIata: "USKPD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pauloff Harbor SPB",
        city: "Pauloff Harbor",
        iata: "KPH",
        countryIata: "USKPH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Parks SPB",
        city: "Parks",
        iata: "KPK",
        countryIata: "USKPK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kipnuk SPB",
        city: "Kipnuk",
        iata: "KPN",
        countryIata: "USKPN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Williams SPB",
        city: "Port Williams",
        iata: "KPR",
        countryIata: "USKPR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jackpot",
        city: "Jackpot",
        iata: "KPT",
        countryIata: "USKPT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Perryville SPB",
        city: "Perryville",
        iata: "KPV",
        countryIata: "USKPV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Bailey SPB",
        city: "Port Bailey",
        iata: "KPY",
        countryIata: "USKPY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Akutan",
        city: "Akutan",
        iata: "KQA",
        countryIata: "USKQA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Saint Marys",
        city: "Saint Marys",
        iata: "KSM",
        countryIata: "USKSM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Federal No 1",
        city: "Sandy River",
        iata: "KSR",
        countryIata: "USKSR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Thorne Bay",
        city: "Thorne Bay",
        iata: "KTB",
        countryIata: "USKTB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Tikchik",
        iata: "KTH",
        countryIata: "USKTH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Ketchikan",
        iata: "KTN",
        countryIata: "USKTN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Brevig Mission",
        city: "Teller Mission",
        iata: "KTS",
        countryIata: "USKTS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kasigluk",
        city: "Kasigluk",
        iata: "KUK",
        countryIata: "USKUK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kugururok River",
        city: "Kugururok River",
        iata: "KUW",
        countryIata: "USKUW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "King Cove",
        city: "King Cove",
        iata: "KVC",
        countryIata: "USKVC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kivalina",
        city: "Kivalina",
        iata: "KVL",
        countryIata: "USKVL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Waterfall SPB",
        city: "Waterfall",
        iata: "KWF",
        countryIata: "USKWF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kwigillingok",
        city: "Kwigillingok",
        iata: "KWK",
        countryIata: "USKWK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kwinhagak",
        city: "Quinhagak",
        iata: "KWN",
        countryIata: "USKWN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Village SPB",
        city: "West Point",
        iata: "KWP",
        countryIata: "USKWP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kwethluk",
        city: "Kwethluk",
        iata: "KWT",
        countryIata: "USKWT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kasaan SPB",
        city: "Kasaan",
        iata: "KXA",
        countryIata: "USKXA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Karluk Airport",
        city: "Karluk",
        iata: "KYK",
        countryIata: "USKYK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Largo Airport (abandoned)",
        city: "Key Largo",
        iata: "KYL",
        countryIata: "USKYL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Topp Of Tampa",
        city: "Tampa",
        iata: "KYO",
        countryIata: "USKYO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Koyukuk",
        city: "Koyukuk",
        iata: "KYU",
        countryIata: "USKYU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Zachar Bay SPB",
        city: "Zachar Bay",
        iata: "KZB",
        countryIata: "USKZB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kizhuyak",
        city: "Kizhuyak",
        iata: "KZH",
        countryIata: "USKZH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lamar Field",
        city: "Lamar",
        iata: "LAA",
        countryIata: "USLAA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Purdue University",
        city: "Lafayette",
        iata: "LAF",
        countryIata: "USLAF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lakeland Linder Regional Airport",
        city: "Lakeland",
        iata: "LAL",
        countryIata: "USLAL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Los Alamos",
        city: "Los Alamos",
        iata: "LAM",
        countryIata: "USLAM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Capital City",
        city: "Lansing (MI)",
        iata: "LAN",
        countryIata: "USLAN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "General Brees Field",
        city: "Laramie",
        iata: "LAR",
        countryIata: "USLAR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mccarran International",
        city: "Las Vegas (NV)",
        iata: "LAS",
        countryIata: "USLAS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Lawton",
        iata: "LAW",
        countryIata: "USLAW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Los Angeles (CA)",
        iata: "LAX",
        countryIata: "USLAX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Lubbock",
        iata: "LBB",
        countryIata: "USLBB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Westmoreland County",
        city: "Latrobe",
        iata: "LBE",
        countryIata: "USLBE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lee Bird Field",
        city: "North Platte",
        iata: "LBF",
        countryIata: "USLBF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Liberal",
        iata: "LBL",
        countryIata: "USLBL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lumberton",
        city: "Lumberton",
        iata: "LBT",
        countryIata: "USLBT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Lake Charles",
        iata: "LCH",
        countryIata: "USLCH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Laconia",
        iata: "LCI",
        countryIata: "USLCI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rickenbacker",
        city: "Columbus (OH)",
        iata: "LCK",
        countryIata: "USLCK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Linden",
        city: "Linden",
        iata: "LDJ",
        countryIata: "USLDJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mason County",
        city: "Ludington",
        iata: "LDM",
        countryIata: "USLDM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hanover/Lebanon/White River Airport",
        city: "White River",
        iata: "LEB",
        countryIata: "USLEB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Leesburg",
        city: "Leesburg",
        iata: "LEE",
        countryIata: "USLEE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "lemmon",
        city: "Lemmon",
        iata: "LEM",
        countryIata: "USLEM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Auburn Airport",
        city: "Lewiston",
        iata: "LEW",
        countryIata: "USLEW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Blue Grass",
        city: "Lexington",
        iata: "LEX",
        countryIata: "USLEX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Langley AFB",
        city: "Williamsburg",
        iata: "LFI",
        countryIata: "USLFI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lufkin Angelina County",
        city: "Nacogdoches",
        iata: "LFK",
        countryIata: "USLFK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Franklin",
        city: "Louisburg",
        iata: "LFN",
        countryIata: "USLFN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lafayette Regional Airport",
        city: "Lafayette",
        iata: "LFT",
        countryIata: "USLFT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "LaGuardia",
        city: "New York (NY)",
        iata: "LGA",
        countryIata: "USLGA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Long Beach Municipal",
        city: "Long Beach (CA)",
        iata: "LGB",
        countryIata: "USLGB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Calloway",
        city: "La Grange",
        iata: "LGC",
        countryIata: "USLGC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "La Grande",
        city: "La Grande",
        iata: "LGD",
        countryIata: "USLGD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Laguna AAF",
        city: "Yuma",
        iata: "LGF",
        countryIata: "USLGF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cache",
        city: "Logan",
        iata: "LGU",
        countryIata: "USLGU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lost Harbor Sea Port",
        city: "Lost Harbor",
        iata: "LHB",
        countryIata: "USLHB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "W T Piper Memorial",
        city: "Lock Haven",
        iata: "LHV",
        countryIata: "USLHV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Liangping",
        city: "Lima",
        iata: "LIA",
        countryIata: "USLIA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Limon",
        iata: "LIC",
        countryIata: "USLIC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lihue",
        city: "Kauai Island",
        iata: "LIH",
        countryIata: "USLIH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Long Island",
        city: "Long Island",
        iata: "LIJ",
        countryIata: "USLIJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Regional Airport",
        city: "Little Rock (AR)",
        iata: "LIT",
        countryIata: "USLIT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Livengood",
        city: "Livengood",
        iata: "LIV",
        countryIata: "USLIV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wright AAF",
        city: "Hinesville",
        iata: "LIY",
        countryIata: "USLIY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Loring AFB",
        city: "Limestone",
        iata: "LIZ",
        countryIata: "USLIZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Intercontinental",
        city: "Louisville",
        iata: "LJC",
        countryIata: "USLJC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Brazoria County",
        city: "Lake Jackson",
        iata: "LJN",
        countryIata: "USLJN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lake Union SPB",
        city: "Seattle (WA)",
        iata: "LKE",
        countryIata: "USLKE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lakeside USAF",
        city: "Duluth",
        iata: "LKI",
        countryIata: "USLKI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kulik Lake",
        city: "Kulik Lake",
        iata: "LKK",
        countryIata: "USLKK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lake Placid",
        city: "Lake Placid",
        iata: "LKP",
        countryIata: "USLKP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lakeside",
        city: "Lakeside",
        iata: "LKS",
        countryIata: "USLKS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lake County",
        city: "Lakeview",
        iata: "LKV",
        countryIata: "USLKV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lyndonville",
        city: "Lyndonville",
        iata: "LLX",
        countryIata: "USLLX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Burlington County",
        city: "Mount Holly",
        iata: "LLY",
        countryIata: "USLLY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lake Minchumina",
        city: "Lake Minchumina",
        iata: "LMA",
        countryIata: "USLMA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Winston County",
        city: "Louisville",
        iata: "LMS",
        countryIata: "USLMS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kingsley Field",
        city: "Klamath Falls",
        iata: "LMT",
        countryIata: "USLMT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Palm Beach County",
        city: "West Palm Beach",
        iata: "LNA",
        countryIata: "USLNA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hunt Field",
        city: "Lander",
        iata: "LND",
        countryIata: "USLND",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dew Station",
        city: "Lonely",
        iata: "LNI",
        countryIata: "USLNI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Lincoln (NE)",
        iata: "LNK",
        countryIata: "USLNK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lost Nation",
        city: "Willoughby",
        iata: "LNN",
        countryIata: "USLNN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wise",
        city: "Wise",
        iata: "LNP",
        countryIata: "USLNP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tri County",
        city: "Lone Rock",
        iata: "LNR",
        countryIata: "USLNR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lancaster",
        city: "Lancaster",
        iata: "LNS",
        countryIata: "USLNS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lanai City",
        city: "Lanai City",
        iata: "LNY",
        countryIata: "USLNY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Longview",
        city: "Longview",
        iata: "LOG",
        countryIata: "USLOG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Derby Field",
        city: "Lovelock",
        iata: "LOL",
        countryIata: "USLOL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lowe Army Heliport",
        city: "Ozark",
        iata: "LOR",
        countryIata: "USLOR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lewis Lockport",
        city: "Lockport",
        iata: "LOT",
        countryIata: "USLOT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bowman Field",
        city: "Louisville",
        iata: "LOU",
        countryIata: "USLOU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Louisa",
        city: "Louisa",
        iata: "LOW",
        countryIata: "USLOW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Corbin-London",
        city: "London",
        iata: "LOZ",
        countryIata: "USLOZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lompoc Airport",
        city: "Lompoc",
        iata: "LPC",
        countryIata: "USLPC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Laporte",
        iata: "LPO",
        countryIata: "USLPO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lopez Island",
        city: "Lopez Island",
        iata: "LPS",
        countryIata: "USLPS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Little Port Walter",
        city: "Little Port Walter",
        iata: "LPW",
        countryIata: "USLPW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pickens",
        city: "Pickens",
        iata: "LQK",
        countryIata: "USLQK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Laredo",
        iata: "LRD",
        countryIata: "USLRD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Little Rock AFB",
        city: "Jacksonville",
        iata: "LRF",
        countryIata: "USLRF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Lemars IA",
        iata: "LRJ",
        countryIata: "USLRJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Coast Guard",
        city: "Lincoln Rock",
        iata: "LRK",
        countryIata: "USLRK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Larson AFB",
        city: "Moses Lake",
        iata: "LRN",
        countryIata: "USLRN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sharpe AAF",
        city: "Lathrop",
        iata: "LRO",
        countryIata: "USLRO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Las Cruces",
        iata: "LRU",
        countryIata: "USLRU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lordsburg",
        city: "Lordsburg",
        iata: "LSB",
        countryIata: "USLSB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Creech AAF",
        city: "Lexington",
        iata: "LSD",
        countryIata: "USLSD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "La Crosse",
        iata: "LSE",
        countryIata: "USLSE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lawson AAF",
        city: "Fort Benning",
        iata: "LSF",
        countryIata: "USLSF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lusk",
        city: "Lusk",
        iata: "LSK",
        countryIata: "USLSK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Los Banos",
        city: "Los Banos",
        iata: "LSN",
        countryIata: "USLSN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lost River",
        city: "Lost River",
        iata: "LSR",
        countryIata: "USLSR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nellis AFB",
        city: "Las Vegas (NV)",
        iata: "LSV",
        countryIata: "USLSV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lathrop Wells",
        city: "Lathrop Wells",
        iata: "LTH",
        countryIata: "USLTH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Altus AFB",
        city: "Altus",
        iata: "LTS",
        countryIata: "USLTS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "St Marys County",
        city: "Leonardtown",
        iata: "LTW",
        countryIata: "USLTW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Luke AFB",
        city: "Phoenix (AZ)",
        iata: "LUF",
        countryIata: "USLUF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Cincinnati (OH)",
        iata: "LUK",
        countryIata: "USLUK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hesler-Noble Field",
        city: "Laurel",
        iata: "LUL",
        countryIata: "USLUL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kalaupapa",
        city: "Kalaupapa",
        iata: "LUP",
        countryIata: "USLUP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cape Lisburne",
        city: "Cape Lisburne",
        iata: "LUR",
        countryIata: "USLUR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lime Village",
        city: "Lime Village",
        iata: "LVD",
        countryIata: "USLVD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Livermore",
        city: "Livermore",
        iata: "LVK",
        countryIata: "USLVK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lawrenceville",
        city: "Lawrenceville",
        iata: "LVL",
        countryIata: "USLVL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mission Field",
        city: "Livingston",
        iata: "LVM",
        countryIata: "USLVM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Las Vegas",
        city: "Las Vegas",
        iata: "LVS",
        countryIata: "USLVS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Greenbrier Valley",
        city: "Lewisburg",
        iata: "LWB",
        countryIata: "USLWB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lawrence",
        city: "Lawrence",
        iata: "LWC",
        countryIata: "USLWC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Harriet Field",
        city: "Wells",
        iata: "LWL",
        countryIata: "USLWL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lawrence",
        city: "Lawrence",
        iata: "LWM",
        countryIata: "USLWM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nez Perce County Rgnl",
        city: "Lewiston",
        iata: "LWS",
        countryIata: "USLWS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Lewistown",
        iata: "LWT",
        countryIata: "USLWT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Lawrenceville",
        iata: "LWV",
        countryIata: "USLWV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lexington",
        city: "Lexington",
        iata: "LXN",
        countryIata: "USLXN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Leadville",
        city: "Leadville",
        iata: "LXV",
        countryIata: "USLXV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Preston-Glenn Field",
        city: "Lynchburg",
        iata: "LYH",
        countryIata: "USLYH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rice County Municipal",
        city: "Lyons",
        iata: "LYO",
        countryIata: "USLYO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ely",
        city: "Ely",
        iata: "LYU",
        countryIata: "USLYU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Macon Downtown Airport (Smart)",
        city: "Macon",
        iata: "MAC",
        countryIata: "USMAC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Madera",
        city: "Madera",
        iata: "MAE",
        countryIata: "USMAE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Odessa Regional",
        city: "Midland",
        iata: "MAF",
        countryIata: "USMAF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Malden",
        city: "Malden",
        iata: "MAW",
        countryIata: "USMAW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mobridge",
        city: "Mobridge",
        iata: "MBG",
        countryIata: "USMBG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Blacker",
        city: "Manistee",
        iata: "MBL",
        countryIata: "USMBL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tri City Airport",
        city: "Saginaw",
        iata: "MBS",
        countryIata: "USMBS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Moberly",
        city: "Moberly",
        iata: "MBY",
        countryIata: "USMBY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pike County",
        city: "Mccomb",
        iata: "MCB",
        countryIata: "USMCB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mcclellan AFB",
        city: "Sacramento (CA)",
        iata: "MCC",
        countryIata: "USMCC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mackinac Island",
        city: "Mackinac Island",
        iata: "MCD",
        countryIata: "USMCD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Merced Municipal Airport",
        city: "Merced",
        iata: "MCE",
        countryIata: "USMCE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mac Dill AFB",
        city: "Tampa (FL)",
        iata: "MCF",
        countryIata: "USMCF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mcgrath",
        city: "Mcgrath",
        iata: "MCG",
        countryIata: "USMCG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kansas City International Airport",
        city: "Kansas City (MO)",
        iata: "MCI",
        countryIata: "USMCI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mccook",
        city: "Mccook",
        iata: "MCK",
        countryIata: "USMCK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mt Mckinley",
        city: "Mt Mckinley",
        iata: "MCL",
        countryIata: "USMCL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lewis B Wilson",
        city: "Macon",
        iata: "MCN",
        countryIata: "USMCN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Orlando International Airport",
        city: "Orlando (FL)",
        iata: "MCO",
        countryIata: "USMCO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mason City",
        city: "Mason City",
        iata: "MCW",
        countryIata: "USMCW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Airpark",
        city: "Midland",
        iata: "MDD",
        countryIata: "USMDD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Medford",
        city: "Medford",
        iata: "MDF",
        countryIata: "USMDF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Southern Illinois",
        city: "Carbondale",
        iata: "MDH",
        countryIata: "USMDH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "City-county",
        city: "Madras",
        iata: "MDJ",
        countryIata: "USMDJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jefferson Proving Grd",
        city: "Madison",
        iata: "MDN",
        countryIata: "USMDN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Intermediate",
        city: "Middleton Island",
        iata: "MDO",
        countryIata: "USMDO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Medfra",
        city: "Medfra",
        iata: "MDR",
        countryIata: "USMDR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Harrisburg International Airport",
        city: "Harrisburg (PA)",
        iata: "MDT",
        countryIata: "USMDT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chicago Midway International Airport",
        city: "Chicago (IL)",
        iata: "MDW",
        countryIata: "USMDW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Key Field",
        city: "Meridian",
        iata: "MEI",
        countryIata: "USMEI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Meadville",
        city: "Meadville",
        iata: "MEJ",
        countryIata: "USMEJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Memphis (TN)",
        iata: "MEM",
        countryIata: "USMEM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dare County Regional",
        city: "Manteo",
        iata: "MEO",
        countryIata: "USMEO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Castle AFB",
        city: "Merced",
        iata: "MER",
        countryIata: "USMER",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Douglas County",
        city: "Minden",
        iata: "MEV",
        countryIata: "USMEV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lahm Municipal",
        city: "Mansfield",
        iata: "MFD",
        countryIata: "USMFD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Miller International",
        city: "Mission",
        iata: "MFE",
        countryIata: "USMFE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mesquite",
        city: "Mesquite",
        iata: "MFH",
        countryIata: "USMFH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Marshfield",
        iata: "MFI",
        countryIata: "USMFI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jackson County",
        city: "Medford",
        iata: "MFR",
        countryIata: "USMFR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Accomack County",
        city: "Melfa",
        iata: "MFV",
        countryIata: "USMFV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Michigan City Airport",
        city: "Michigan City",
        iata: "MGC",
        countryIata: "USMGC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dobbins AFB",
        city: "Marietta",
        iata: "MGE",
        countryIata: "USMGE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Matagorda AFB",
        city: "Matagorda Island",
        iata: "MGI",
        countryIata: "USMGI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Orange County",
        city: "Montgomery",
        iata: "MGJ",
        countryIata: "USMGJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dannelly Fld",
        city: "Montgomery (AL)",
        iata: "MGM",
        countryIata: "USMGM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Thomasville",
        city: "Moultrie",
        iata: "MGR",
        countryIata: "USMGR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Morgantown",
        city: "Morgantown",
        iata: "MGW",
        countryIata: "USMGW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dayton–Wright Brothers Airport (Montgomery County)",
        city: "Dayton",
        iata: "MGY",
        countryIata: "USMGY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Mitchell",
        iata: "MHE",
        countryIata: "USMHE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Manhattan Regional Airport",
        city: "Manhattan",
        iata: "MHK",
        countryIata: "USMHK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Memorial Municipal",
        city: "Marshall",
        iata: "MHL",
        countryIata: "USMHL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Intermediate",
        city: "Minchumina",
        iata: "MHM",
        countryIata: "USMHM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mullen",
        city: "Mullen",
        iata: "MHN",
        countryIata: "USMHN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mather AFB",
        city: "Sacramento (CA)",
        iata: "MHR",
        countryIata: "USMHR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rep",
        city: "Mount Shasta",
        iata: "MHS",
        countryIata: "USMHS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Manchester",
        iata: "MHT",
        countryIata: "USMHT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kern County",
        city: "Mojave",
        iata: "MHV",
        countryIata: "USMHV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Miami (FL)",
        iata: "MIA",
        countryIata: "USMIA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Minot AFB",
        city: "Minot",
        iata: "MIB",
        countryIata: "USMIB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Crystal",
        city: "Minneapolis/St Paul (MN)",
        iata: "MIC",
        countryIata: "USMIC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Delaware County",
        city: "Muncie",
        iata: "MIE",
        countryIata: "USMIE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Roy Hurd Memorial",
        city: "Monahans",
        iata: "MIF",
        countryIata: "USMIF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Miami",
        city: "Miami",
        iata: "MIO",
        countryIata: "USMIO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Millard",
        city: "Omaha",
        iata: "MIQ",
        countryIata: "USMIQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kern County",
        city: "Shafter",
        iata: "MIT",
        countryIata: "USMIT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Millville",
        city: "Millville",
        iata: "MIV",
        countryIata: "USMIV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Marshalltown IA",
        iata: "MIW",
        countryIata: "USMIW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jackson",
        city: "Jackson",
        iata: "MJQ",
        countryIata: "USMJQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Robert J. Miller",
        city: "Toms River",
        iata: "MJX",
        countryIata: "USMJX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Downtown",
        city: "Kansas City (MO)",
        iata: "MKC",
        countryIata: "USMKC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "General Mitchell",
        city: "Milwaukee (WI)",
        iata: "MKE",
        countryIata: "USMKE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mckenna AAF",
        city: "Columbus",
        iata: "MKF",
        countryIata: "USMKF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Muskegon",
        city: "Muskegon",
        iata: "MKG",
        countryIata: "USMKG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Molokai",
        city: "Hoolehua",
        iata: "MKK",
        countryIata: "USMKK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mckellar",
        city: "Jackson",
        iata: "MKL",
        countryIata: "USMKL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Davis Field",
        city: "Muskogee",
        iata: "MKO",
        countryIata: "USMKO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Mankato",
        iata: "MKT",
        countryIata: "USMKT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Melbourne International",
        city: "Melbourne",
        iata: "MLB",
        countryIata: "USMLB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mc Alester",
        city: "Mc Alester",
        iata: "MLC",
        countryIata: "USMLC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Malad City",
        city: "Malad City",
        iata: "MLD",
        countryIata: "USMLD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Milford",
        city: "Milford",
        iata: "MLF",
        countryIata: "USMLF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Quad-City",
        city: "Moline",
        iata: "MLI",
        countryIata: "USMLI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Baldwin County",
        city: "Milledgeville",
        iata: "MLJ",
        countryIata: "USMLJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Malta",
        city: "Malta",
        iata: "MLK",
        countryIata: "USMLK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Marshall",
        city: "Marshall",
        iata: "MLL",
        countryIata: "USMLL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Miles City",
        iata: "MLS",
        countryIata: "USMLS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Millinocket",
        city: "Millinocket",
        iata: "MLT",
        countryIata: "USMLT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Monroe",
        iata: "MLU",
        countryIata: "USMLU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Manley Hot Springs",
        city: "Manley Hot Springs",
        iata: "MLY",
        countryIata: "USMLY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mammoth Lakes",
        city: "Mammoth Lakes",
        iata: "MMH",
        countryIata: "USMMH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "McMinn County",
        city: "Athens",
        iata: "MMI",
        countryIata: "USMMI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal-Ryan Field",
        city: "Marshall",
        iata: "MML",
        countryIata: "USMML",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Minute Man Airfield",
        city: "Stow",
        iata: "MMN",
        countryIata: "USMMN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Camp Maybry AHP",
        city: "Austin (TX)",
        iata: "MMR",
        countryIata: "USMMR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Selfs",
        city: "Marks",
        iata: "MMS",
        countryIata: "USMMS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mc Entire ANG Base",
        city: "Columbia (SC)",
        iata: "MMT",
        countryIata: "USMMT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Morristown",
        iata: "MMU",
        countryIata: "USMMU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Menominee",
        city: "Menominee",
        iata: "MNM",
        countryIata: "USMNM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Marion",
        iata: "MNN",
        countryIata: "USMNN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Minto",
        city: "Minto",
        iata: "MNT",
        countryIata: "USMNT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Manassas",
        city: "Manassas",
        iata: "MNZ",
        countryIata: "USMNZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mobile Municipal Airport",
        city: "Mobile",
        iata: "MOB",
        countryIata: "USMOB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Modesto",
        iata: "MOD",
        countryIata: "USMOD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Mount Pleasant",
        iata: "MOP",
        countryIata: "USMOP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Moore-Murrell",
        city: "Morristown",
        iata: "MOR",
        countryIata: "USMOR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Intermediate",
        city: "Moses Point",
        iata: "MOS",
        countryIata: "USMOS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Minot",
        iata: "MOT",
        countryIata: "USMOT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mountain Village",
        city: "Mountain Village",
        iata: "MOU",
        countryIata: "USMOU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Morris",
        iata: "MOX",
        countryIata: "USMOX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Miami Seaplane Base",
        city: "Miami (FL)",
        iata: "MPB",
        countryIata: "USMPB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Griswold Airport (closed since the 2007)",
        city: "Madison",
        iata: "MPE",
        countryIata: "USMPE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Petit Jean Park",
        city: "Morrilton",
        iata: "MPJ",
        countryIata: "USMPJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mt Pocono",
        city: "Mt Pocono",
        iata: "MPO",
        countryIata: "USMPO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mcpherson",
        city: "Mcpherson",
        iata: "MPR",
        countryIata: "USMPR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mount Pleasant",
        city: "Mount Pleasant",
        iata: "MPS",
        countryIata: "USMPS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Edward F Knapp State",
        city: "Montpelier (VT)",
        iata: "MPV",
        countryIata: "USMPV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Mt Pleasant IA",
        iata: "MPZ",
        countryIata: "USMPZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Macomb",
        iata: "MQB",
        countryIata: "USMQB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Quincy",
        city: "Quincy",
        iata: "MQI",
        countryIata: "USMQI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sawyer International",
        city: "Marquette",
        iata: "MQT",
        countryIata: "USMQT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Telfair-Wheeler",
        city: "Mc Rae",
        iata: "MQW",
        countryIata: "USMQW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Smyrna",
        city: "Smyrna",
        iata: "MQY",
        countryIata: "USMQY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Martinsburg",
        city: "Martinsburg",
        iata: "MRB",
        countryIata: "USMRB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Maury County",
        city: "Columbia",
        iata: "MRC",
        countryIata: "USMRC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Marfa",
        iata: "MRF",
        countryIata: "USMRF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Merrill Field",
        city: "Anchorage (AK)",
        iata: "MRI",
        countryIata: "USMRI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Marco Island",
        city: "Marco Island",
        iata: "MRK",
        countryIata: "USMRK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lenoir",
        city: "Morganton",
        iata: "MRN",
        countryIata: "USMRN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Monterey Regional Airport (Monterey Peninsula Airport)",
        city: "Monterey, Carmel",
        iata: "MRY",
        countryIata: "USMRY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Falcon Field",
        city: "Mesa",
        iata: "MSC",
        countryIata: "USMSC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mt Pleasant",
        city: "Mt Pleasant",
        iata: "MSD",
        countryIata: "USMSD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Florence/Muscle Sh/Sheffield Airport",
        city: "Sheffield",
        iata: "MSL",
        countryIata: "USMSL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dane County Regional",
        city: "Madison (WI)",
        iata: "MSN",
        countryIata: "USMSN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Johnson-Bell Field",
        city: "Missoula",
        iata: "MSO",
        countryIata: "USMSO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "St Paul International",
        city: "Minneapolis, Saint Paul (MN)",
        iata: "MSP",
        countryIata: "USMSP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Richards Field",
        city: "Massena",
        iata: "MSS",
        countryIata: "USMSS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sullivan County International",
        city: "Monticello",
        iata: "MSV",
        countryIata: "USMSV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "New Orleans (LA)",
        iata: "MSY",
        countryIata: "USMSY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Selfridge ANGB",
        city: "Mt Clemens",
        iata: "MTC",
        countryIata: "USMTC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Marathon Flight Strip",
        city: "Marathon",
        iata: "MTH",
        countryIata: "USMTH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Montrose County",
        city: "Montrose",
        iata: "MTJ",
        countryIata: "USMTJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Metlakatla",
        iata: "MTM",
        countryIata: "USMTM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Glenn L Martin",
        city: "Baltimore (MD)",
        iata: "MTN",
        countryIata: "USMTN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Coles County Memorial",
        city: "Mattoon",
        iata: "MTO",
        countryIata: "USMTO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sky Portal",
        city: "Montauk",
        iata: "MTP",
        countryIata: "USMTP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Manitowoc",
        iata: "MTW",
        countryIata: "USMTW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Metro Field",
        city: "Fairbanks",
        iata: "MTX",
        countryIata: "USMTX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kamuela",
        city: "Kamuela",
        iata: "MUE",
        countryIata: "USMUE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Muir AAF",
        city: "Fort Indiantown",
        iata: "MUI",
        countryIata: "USMUI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Spence",
        city: "Moultrie",
        iata: "MUL",
        countryIata: "USMUL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "AFB",
        city: "Mountain Home",
        iata: "MUO",
        countryIata: "USMUO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Muscatine",
        city: "Muscatine IA",
        iata: "MUT",
        countryIata: "USMUT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mount Union",
        city: "Mount Union",
        iata: "MUU",
        countryIata: "USMUU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mustin Alf (Inactive)",
        city: "Philadelphia (PA)",
        iata: "MUV",
        countryIata: "USMUV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Monroe County",
        city: "Monroeville",
        iata: "MVC",
        countryIata: "USMVC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Montevideo",
        iata: "MVE",
        countryIata: "USMVE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Morrisville-Stowe",
        city: "Stowe",
        iata: "MVL",
        countryIata: "USMVL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Monument Valley",
        city: "Kayenta",
        iata: "MVM",
        countryIata: "USMVM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mt Vernon-Outland",
        city: "Mt Vernon",
        iata: "MVN",
        countryIata: "USMVN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Skagit Regional",
        city: "Mount Vernon",
        iata: "MVW",
        countryIata: "USMVW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Martha's Vineyard",
        city: "Martha's Vineyard",
        iata: "MVY",
        countryIata: "USMVY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Williamson County",
        city: "Marion",
        iata: "MWA",
        countryIata: "USMWA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lawrence J Timmerman",
        city: "Milwaukee (WI)",
        iata: "MWC",
        countryIata: "USMWC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Grant County",
        city: "Moses Lake",
        iata: "MWH",
        countryIata: "USMWH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mineral Wells Airport",
        city: "Mineral Wells",
        iata: "MWL",
        countryIata: "USMWL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Windom",
        iata: "MWM",
        countryIata: "USMWM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hook Field",
        city: "Middletown",
        iata: "MWO",
        countryIata: "USMWO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mount Wilson",
        city: "Mount Wilson",
        iata: "MWS",
        countryIata: "USMWS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Manila",
        iata: "MXA",
        countryIata: "USMXA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "San Juan County",
        city: "Monticello",
        iata: "MXC",
        countryIata: "USMXC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Maxton",
        city: "Maxton",
        iata: "MXE",
        countryIata: "USMXE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Maxwell AFB",
        city: "Montgomery (AL)",
        iata: "MXF",
        countryIata: "USMXF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Marlborough",
        city: "Marlborough",
        iata: "MXG",
        countryIata: "USMXG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Monticello IA",
        iata: "MXO",
        countryIata: "USMXO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mccarthy",
        city: "Mccarthy",
        iata: "MXY",
        countryIata: "USMXY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Montgomery Field",
        city: "San Diego",
        iata: "MYF",
        countryIata: "USMYF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Marble Canyon",
        city: "Marble Canyon",
        iata: "MYH",
        countryIata: "USMYH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "May Creek",
        city: "May Creek",
        iata: "MYK",
        countryIata: "USMYK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mccall",
        city: "Mccall",
        iata: "MYL",
        countryIata: "USMYL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Myrtle Beach AFB",
        city: "Myrtle Beach",
        iata: "MYR",
        countryIata: "USMYR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ellis Field",
        city: "Mekoryuk",
        iata: "MYU",
        countryIata: "USMYU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Yuba County",
        city: "Marysville",
        iata: "MYV",
        countryIata: "USMYV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Marana",
        city: "Marana",
        iata: "MZJ",
        countryIata: "USMZJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Marion",
        city: "Marion",
        iata: "MZZ",
        countryIata: "USMZZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Albany NAS",
        city: "Albany",
        iata: "NAB",
        countryIata: "USNAB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Barbers Point",
        city: "Barbers Point",
        iata: "NAX",
        countryIata: "USNAX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS/Alvin Callendar",
        city: "New Orleans (LA)",
        iata: "NBG",
        countryIata: "USNBG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Battery Park City/N. Cov",
        city: "New York (NY)",
        iata: "NBP",
        countryIata: "USNBP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS",
        city: "Glenview",
        iata: "NBU",
        countryIata: "USNBU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "New Chenega",
        city: "New Chenega",
        iata: "NCN",
        countryIata: "USNCN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS",
        city: "Quonset Point",
        iata: "NCO",
        countryIata: "USNCO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Atlanta NAS",
        city: "Marietta",
        iata: "NCQ",
        countryIata: "USNCQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ellyson NAS",
        city: "Pensacola",
        iata: "NDP",
        countryIata: "USNDP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "USN Heliport",
        city: "Anacostia, DC",
        iata: "NDV",
        countryIata: "USNDV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS",
        city: "Glynco",
        iata: "NEA",
        countryIata: "USNEA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Naec",
        city: "Lakehurst",
        iata: "NEL",
        countryIata: "USNEL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Olf Usn",
        city: "Whitehouse",
        iata: "NEN",
        countryIata: "USNEN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "East 34th St Landing",
        city: "New York (NY)",
        iata: "NES",
        countryIata: "USNES",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lakefront",
        city: "New Orleans (LA)",
        iata: "NEW",
        countryIata: "USNEW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Detroit NAF",
        city: "Mt Clemens",
        iata: "NFB",
        countryIata: "USNFB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS",
        city: "Fallon",
        iata: "NFL",
        countryIata: "USNFL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "North Rim",
        city: "Grand Canyon",
        iata: "NGC",
        countryIata: "USNGC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS",
        city: "Corpus Christi",
        iata: "NGP",
        countryIata: "USNGP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS Chambers",
        city: "Norfolk (VA)",
        iata: "NGU",
        countryIata: "USNGU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cabaniss Field",
        city: "Corpus Christi",
        iata: "NGW",
        countryIata: "USNGW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS",
        city: "Alameda",
        iata: "NGZ",
        countryIata: "USNGZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS",
        city: "Patuxent River",
        iata: "NHK",
        countryIata: "USNHK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Barin OLF Osn",
        city: "Foley",
        iata: "NHX",
        countryIata: "USNHX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS",
        city: "Brunswick",
        iata: "NHZ",
        countryIata: "USNHZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nikolai",
        city: "Nikolai",
        iata: "NIB",
        countryIata: "USNIB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Niblack",
        city: "Niblack",
        iata: "NIE",
        countryIata: "USNIE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ninilchik",
        city: "Ninilchik",
        iata: "NIN",
        countryIata: "USNIN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jacksonville NAS",
        city: "Jacksonville (FL)",
        iata: "NIP",
        countryIata: "USNIP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chase Field NAS",
        city: "Beeville",
        iata: "NIR",
        countryIata: "USNIR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAF",
        city: "Imperial",
        iata: "NJK",
        countryIata: "USNJK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Naukiti",
        city: "Naukiti",
        iata: "NKI",
        countryIata: "USNKI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nichen Cove",
        city: "Nichen Cove",
        iata: "NKV",
        countryIata: "USNKV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Miramar NAS",
        city: "San Diego (CA)",
        iata: "NKX",
        countryIata: "USNKX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS/Reeves Field",
        city: "Lemoore",
        iata: "NLC",
        countryIata: "USNLC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jerry Tyler Memorial",
        city: "Niles",
        iata: "NLE",
        countryIata: "USNLE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nelson Lagoon",
        city: "Nelson Lagoon",
        iata: "NLG",
        countryIata: "USNLG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nightmute",
        city: "Nightmute",
        iata: "NME",
        countryIata: "USNME",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Naknek",
        city: "Naknek",
        iata: "NNK",
        countryIata: "USNNK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nondalton",
        city: "Nondalton",
        iata: "NNL",
        countryIata: "USNNL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chicago NAS",
        city: "Chicago (IL)",
        iata: "NOH",
        countryIata: "USNOH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nakolik River",
        city: "Nakolik River",
        iata: "NOL",
        countryIata: "USNOL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Novato",
        city: "Novato",
        iata: "NOT",
        countryIata: "USNOT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pensacola NAS",
        city: "Pensacola",
        iata: "NPA",
        countryIata: "USNPA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nephi",
        city: "Nephi",
        iata: "NPH",
        countryIata: "USNPH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "State",
        city: "Newport",
        iata: "NPT",
        countryIata: "USNPT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS",
        city: "Memphis (TN)",
        iata: "NQA",
        countryIata: "USNQA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS",
        city: "Kingsville",
        iata: "NQI",
        countryIata: "USNQI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS",
        city: "Key West",
        iata: "NQX",
        countryIata: "USNQX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ns",
        city: "Mayport",
        iata: "NRB",
        countryIata: "USNRB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Aux Field",
        city: "Crows Landing",
        iata: "NRC",
        countryIata: "USNRC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Shangri-la",
        city: "Shangri-la",
        iata: "NRI",
        countryIata: "USNRI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS",
        city: "Imperial Beach",
        iata: "NRS",
        countryIata: "USNRS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Whiting Field NAS",
        city: "Milton",
        iata: "NSE",
        countryIata: "USNSE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Andrews NAF",
        city: "Camp Springs",
        iata: "NSF",
        countryIata: "USNSF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Point Mugu NAS",
        city: "Port Hueneme",
        iata: "NTD",
        countryIata: "USNTD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Manti-Ephraim",
        city: "Manti",
        iata: "NTJ",
        countryIata: "USNTJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "NAS",
        city: "Oceana",
        iata: "NTU",
        countryIata: "USNTU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nuiqsut",
        city: "Nuiqsut",
        iata: "NUI",
        countryIata: "USNUI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nulato",
        city: "Nulato",
        iata: "NUL",
        countryIata: "USNUL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Saufley NAS",
        city: "Pensacola",
        iata: "NUN",
        countryIata: "USNUN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nunapitchuk",
        city: "Nunapitchuk",
        iata: "NUP",
        countryIata: "USNUP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Moffett Field",
        city: "Mountain View",
        iata: "NUQ",
        countryIata: "USNUQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ault Field",
        city: "Whidbey Island",
        iata: "NUW",
        countryIata: "USNUW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nevada",
        city: "Nevada",
        iata: "NVD",
        countryIata: "USNVD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Parlin Field",
        city: "Newport",
        iata: "NWH",
        countryIata: "USNWH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pier 11/Wall St. SPB",
        city: "New York (NY)",
        iata: "NWS",
        countryIata: "USNWS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Willow Grove NAS",
        city: "Willow Grove",
        iata: "NXX",
        countryIata: "USNXX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Metropolitan Area",
        city: "New York (NY)",
        iata: "NYC",
        countryIata: "USNYC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Quantanico NAS",
        city: "Quantico",
        iata: "NYG",
        countryIata: "USNYG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cecil Field NAS",
        city: "Jacksonville (FL)",
        iata: "NZC",
        countryIata: "USNZC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "South Weymouth",
        city: "South Weymouth",
        iata: "NZW",
        countryIata: "USNZW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "North Island NAS",
        city: "San Diego (CA)",
        iata: "NZY",
        countryIata: "USNZY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Albert J Ellis",
        city: "Jacksonville",
        iata: "OAJ",
        countryIata: "USOAJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Metropolitan Oak International",
        city: "Oakland",
        iata: "OAK",
        countryIata: "USOAK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fritzsche AAF",
        city: "Monterey",
        iata: "OAR",
        countryIata: "USOAR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "County",
        city: "Okeechobee",
        iata: "OBE",
        countryIata: "USOBE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sky Harbor",
        city: "Northbrook",
        iata: "OBK",
        countryIata: "USOBK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Oakland/Coliseum Stat",
        city: "Oakland",
        iata: "OBT",
        countryIata: "USOBT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kobuk/Wien",
        city: "Kobuk",
        iata: "OBU",
        countryIata: "USOBU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ocean Reef",
        city: "Ocean Reef",
        iata: "OCA",
        countryIata: "USOCA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Ocean City",
        iata: "OCE",
        countryIata: "USOCE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Taylor Field",
        city: "Ocala",
        iata: "OCF",
        countryIata: "USOCF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lufkin Nacogdoches",
        city: "Nacogdoches",
        iata: "OCH",
        countryIata: "USOCH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Oceanic",
        city: "Oceanic",
        iata: "OCI",
        countryIata: "USOCI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Oceanside",
        iata: "OCN",
        countryIata: "USOCN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Warren Field",
        city: "Washington",
        iata: "OCW",
        countryIata: "USOCW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Oak Harbor",
        city: "Oak Harbor",
        iata: "ODW",
        countryIata: "USODW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Oneal",
        city: "Vincennes",
        iata: "OEA",
        countryIata: "USOEA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Osceola",
        iata: "OEO",
        countryIata: "USOEO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Stefan Field",
        city: "Norfolk",
        iata: "OFK",
        countryIata: "USOFK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Searle Field",
        city: "Ogallala",
        iata: "OGA",
        countryIata: "USOGA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Orangeburg",
        iata: "OGB",
        countryIata: "USOGB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Ogden",
        iata: "OGD",
        countryIata: "USOGD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kahului",
        city: "Kahului",
        iata: "OGG",
        countryIata: "USOGG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ogdensburg",
        city: "Ogdensburg",
        iata: "OGS",
        countryIata: "USOGS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "AFS",
        city: "Northeast Cape",
        iata: "OHC",
        countryIata: "USOHC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Eaton",
        city: "Norwich",
        iata: "OIC",
        countryIata: "USOIC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Splane Memorial",
        city: "Oil City",
        iata: "OIL",
        countryIata: "USOIL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Johnson Executive",
        city: "Kansas City",
        iata: "OJC",
        countryIata: "USOJC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Will Rogers World",
        city: "Oklahoma City (OK)",
        iata: "OKC",
        countryIata: "USOKC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kokomo",
        city: "Kokomo",
        iata: "OKK",
        countryIata: "USOKK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Okmulgee",
        city: "Okmulgee",
        iata: "OKM",
        countryIata: "USOKM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Oshkosh",
        city: "Oshkosh",
        iata: "OKS",
        countryIata: "USOKS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Old Town",
        city: "Old Town",
        iata: "OLD",
        countryIata: "USOLD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Olean",
        iata: "OLE",
        countryIata: "USOLE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Wolf Point",
        iata: "OLF",
        countryIata: "USOLF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Old Harbor Airport and SPB",
        city: "Old Harbor",
        iata: "OLH",
        countryIata: "USOLH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Olympia",
        city: "Olympia (WA)",
        iata: "OLM",
        countryIata: "USOLM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Nogales",
        iata: "OLS",
        countryIata: "USOLS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Columbus",
        city: "Columbus",
        iata: "OLU",
        countryIata: "USOLU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Olive Branch Airport",
        city: "Olive Branch",
        iata: "OLV",
        countryIata: "USOLV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Olney-Noble",
        city: "Olney",
        iata: "OLY",
        countryIata: "USOLY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Eppley Airfield",
        city: "Omaha",
        iata: "OMA",
        countryIata: "USOMA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nome",
        city: "Nome",
        iata: "OME",
        countryIata: "USOME",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Omak",
        iata: "OMK",
        countryIata: "USOMK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Winona",
        iata: "ONA",
        countryIata: "USONA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Oneonta",
        iata: "ONH",
        countryIata: "USONH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Oneill",
        iata: "ONL",
        countryIata: "USONL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Socorro",
        city: "Socorro",
        iata: "ONM",
        countryIata: "USONM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Onion Bay Lodge SPB",
        city: "Onion Bay",
        iata: "ONN",
        countryIata: "USONN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ontario",
        city: "Ontario",
        iata: "ONO",
        countryIata: "USONO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Newport",
        city: "Newport",
        iata: "ONP",
        countryIata: "USONP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Ontario",
        iata: "ONT",
        countryIata: "USONT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Olney",
        city: "Olney",
        iata: "ONY",
        countryIata: "USONY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Oskaloosa IA",
        iata: "OOA",
        countryIata: "USOOA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Toksook Bay",
        city: "Toksook Bay",
        iata: "OOK",
        countryIata: "USOOK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Opa Locka",
        city: "Miami (FL)",
        iata: "OPF",
        countryIata: "USOPF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "St Landry Parish",
        city: "Opelousas",
        iata: "OPL",
        countryIata: "USOPL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "O'Hare International",
        city: "Chicago (IL)",
        iata: "ORD",
        countryIata: "USORD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Norfolk (VA)",
        iata: "ORF",
        countryIata: "USORF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Worcester",
        city: "Worcester",
        iata: "ORH",
        countryIata: "USORH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Lions SPB",
        city: "Port Lions",
        iata: "ORI",
        countryIata: "USORI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Herndon",
        city: "Orlando (FL)",
        iata: "ORL",
        countryIata: "USORL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Heliport",
        city: "Norwalk",
        iata: "ORQ",
        countryIata: "USORQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Northway Airport",
        city: "Northway",
        iata: "ORT",
        countryIata: "USORT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Curtis Memorial",
        city: "Noorvik",
        iata: "ORV",
        countryIata: "USORV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Osage Beach",
        city: "Osage Beach",
        iata: "OSB",
        countryIata: "USOSB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wurtsmith AFB",
        city: "Oscoda",
        iata: "OSC",
        countryIata: "USOSC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wittman Field",
        city: "Oshkosh",
        iata: "OSH",
        countryIata: "USOSH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ohio State University",
        city: "Columbus (OH)",
        iata: "OSU",
        countryIata: "USOSU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Attala County",
        city: "Kosciusko",
        iata: "OSX",
        countryIata: "USOSX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Worthington",
        city: "Worthington",
        iata: "OTG",
        countryIata: "USOTG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "North Bend",
        city: "North Bend",
        iata: "OTH",
        countryIata: "USOTH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Industrial",
        city: "Ottumwa IA",
        iata: "OTM",
        countryIata: "USOTM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Green",
        city: "Oaktown",
        iata: "OTN",
        countryIata: "USOTN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Vor",
        city: "Otto",
        iata: "OTO",
        countryIata: "USOTO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Anacortes",
        city: "Anacortes",
        iata: "OTS",
        countryIata: "USOTS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kotzebue",
        city: "Kotzebue",
        iata: "OTZ",
        countryIata: "USOTZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Max Westheimer",
        city: "Norman",
        iata: "OUN",
        countryIata: "USOUN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Oroville",
        city: "Oroville",
        iata: "OVE",
        countryIata: "USOVE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Owatonna",
        city: "Owatonna",
        iata: "OWA",
        countryIata: "USOWA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Daviess County",
        city: "Owensboro",
        iata: "OWB",
        countryIata: "USOWB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Memorial",
        city: "Norwood",
        iata: "OWD",
        countryIata: "USOWD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Central Maine",
        city: "Norridgewock",
        iata: "OWK",
        countryIata: "USOWK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Waterbury-Oxford",
        city: "Oxford",
        iata: "OXC",
        countryIata: "USOXC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Miami University",
        city: "Oxford",
        iata: "OXD",
        countryIata: "USOXD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Oxnard/Ventura Airport",
        city: "Ventura",
        iata: "OXR",
        countryIata: "USOXR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Yosemite Ntl Park",
        city: "Yosemite Ntl Park",
        iata: "OYS",
        countryIata: "USOYS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ozona",
        city: "Ozona",
        iata: "OZA",
        countryIata: "USOZA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cairns AAF",
        city: "Ozark",
        iata: "OZR",
        countryIata: "USOZR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Snohomish County, Paine Field",
        city: "Everett",
        iata: "PAE",
        countryIata: "USPAE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Barkley Regional",
        city: "Paducah",
        iata: "PAH",
        countryIata: "USPAH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Allen",
        city: "Hanapepe",
        iata: "PAK",
        countryIata: "USPAK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tyndall AFB",
        city: "Panama City",
        iata: "PAM",
        countryIata: "USPAM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Palo Alto",
        city: "Palo Alto",
        iata: "PAO",
        countryIata: "USPAO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Palmer",
        iata: "PAQ",
        countryIata: "USPAQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Point Barrow",
        city: "Barrow",
        iata: "PBA",
        countryIata: "USPBA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Grider Field",
        city: "Pine Bluff",
        iata: "PBF",
        countryIata: "USPBF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "AFB",
        city: "Plattsburgh",
        iata: "PBG",
        countryIata: "USPBG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "West Palm Beach",
        iata: "PBI",
        countryIata: "USPBI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pack Creek",
        city: "Pack Creek",
        iata: "PBK",
        countryIata: "USPBK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Portage Creek",
        city: "Portage Creek",
        iata: "PCA",
        countryIata: "USPCA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Prairie Du Chien",
        iata: "PCD",
        countryIata: "USPCD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Painter Creek",
        city: "Painter Creek",
        iata: "PCE",
        countryIata: "USPCE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Porcupine Creek",
        city: "Porcupine Creek",
        iata: "PCK",
        countryIata: "USPCK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Princeton",
        city: "Princeton",
        iata: "PCT",
        countryIata: "USPCT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pearl River County",
        city: "Picayune",
        iata: "PCU",
        countryIata: "USPCU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pedro Bay",
        city: "Pedro Bay",
        iata: "PDB",
        countryIata: "USPDB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "De Kalb/Peachtree",
        city: "Atlanta (GA)",
        iata: "PDK",
        countryIata: "USPDK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pendleton",
        city: "Pendleton",
        iata: "PDT",
        countryIata: "USPDT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Portland OR International",
        city: "Portland (OR)",
        iata: "PDX",
        countryIata: "USPDX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pelican SPB",
        city: "Pelican",
        iata: "PEC",
        countryIata: "USPEC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pecos City",
        city: "Pecos City",
        iata: "PEQ",
        countryIata: "USPEQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Paf Warren",
        city: "Paf Warren",
        iata: "PFA",
        countryIata: "USPFA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "tate",
        city: "Pacific City",
        iata: "PFC",
        countryIata: "USPFC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Frederick",
        city: "Port Frederick",
        iata: "PFD",
        countryIata: "USPFD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bay County",
        city: "Panama City",
        iata: "PFN",
        countryIata: "USPFN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Page",
        city: "Page",
        iata: "PGA",
        countryIata: "USPGA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Grant County",
        city: "Petersburg",
        iata: "PGC",
        countryIata: "USPGC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Charlotte County",
        city: "Punta Gorda",
        iata: "PGD",
        countryIata: "USPGD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Jackson County",
        city: "Pascagoula",
        iata: "PGL",
        countryIata: "USPGL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Graham",
        city: "Port Graham",
        iata: "PGM",
        countryIata: "USPGM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "tevens Field",
        city: "Pagosa Springs",
        iata: "PGO",
        countryIata: "USPGO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Paragould",
        iata: "PGR",
        countryIata: "USPGR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Peach Springs",
        city: "Peach Springs",
        iata: "PGS",
        countryIata: "USPGS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pitt-Greenville",
        city: "Greenville",
        iata: "PGV",
        countryIata: "USPGV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Harry Clever Field",
        city: "New Philadelphia",
        iata: "PHD",
        countryIata: "USPHD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Newport News-Williamsburg Intl",
        city: "Newport News",
        iata: "PHF",
        countryIata: "USPHF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Palm Beach Co Glades",
        city: "Pahokee",
        iata: "PHK",
        countryIata: "USPHK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Philadelphia (PA)",
        iata: "PHL",
        countryIata: "USPHL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "t Clair County International",
        city: "Port Huron",
        iata: "PHN",
        countryIata: "USPHN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Point Hope",
        city: "Point Hope",
        iata: "PHO",
        countryIata: "USPHO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Philip",
        city: "Philip",
        iata: "PHP",
        countryIata: "USPHP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Henry County",
        city: "Paris",
        iata: "PHT",
        countryIata: "USPHT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "ky Harbor International",
        city: "Phoenix (AZ)",
        iata: "PHX",
        countryIata: "USPHX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Greater Peoria",
        city: "Peoria",
        iata: "PIA",
        countryIata: "USPIA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hattiesburg-Laurel Reg",
        city: "Laurel",
        iata: "PIB",
        countryIata: "USPIB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "St. Petersburg-Clearwater International",
        city: "St. Petersburg",
        iata: "PIE",
        countryIata: "USPIE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pocatello",
        city: "Pocatello",
        iata: "PIH",
        countryIata: "USPIH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Phillips Field",
        city: "Fairbanks",
        iata: "PII",
        countryIata: "USPII",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Garden Harris County",
        city: "Pine Mountain",
        iata: "PIM",
        countryIata: "USPIM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pilot Point Airport",
        city: "Pilot Point",
        iata: "PIP",
        countryIata: "USPIP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pierre",
        city: "Pierre (SD)",
        iata: "PIR",
        countryIata: "USPIR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pittsburgh International Airport",
        city: "Pittsburgh (PA)",
        iata: "PIT",
        countryIata: "USPIT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dew Station",
        city: "Point Lay",
        iata: "PIZ",
        countryIata: "USPIZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Payson",
        city: "Payson",
        iata: "PJB",
        countryIata: "USPJB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port San Juan",
        city: "Port San Juan",
        iata: "PJS",
        countryIata: "USPJS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "PB",
        city: "Napaskiak",
        iata: "PKA",
        countryIata: "USPKA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wood County Airport",
        city: "Parkersburg",
        iata: "PKB",
        countryIata: "USPKB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Park Rapids",
        city: "Park Rapids",
        iata: "PKD",
        countryIata: "USPKD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Park Falls",
        city: "Park Falls",
        iata: "PKF",
        countryIata: "USPKF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Clinton County",
        city: "Plattsburgh",
        iata: "PLB",
        countryIata: "USPLB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "M Graham Clark",
        city: "Branson/Point Lookout",
        iata: "PLK",
        countryIata: "USPLK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Emmet County",
        city: "Pellston",
        iata: "PLN",
        countryIata: "USPLN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "t Clair County",
        city: "Pell City",
        iata: "PLR",
        countryIata: "USPLR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Plymouth",
        city: "Plymouth",
        iata: "PLY",
        countryIata: "USPLY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Intermediate",
        city: "Pembina",
        iata: "PMB",
        countryIata: "USPMB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Air Force 42",
        city: "Palmdale",
        iata: "PMD",
        countryIata: "USPMD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Regional",
        city: "Portsmouth",
        iata: "PMH",
        countryIata: "USPMH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "AFS",
        city: "Port Moller",
        iata: "PML",
        countryIata: "USPML",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "PB",
        city: "Paimiut",
        iata: "PMU",
        countryIata: "USPMU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Metropolitan",
        city: "Palmer",
        iata: "PMX",
        countryIata: "USPMX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ponca City",
        city: "Ponca City",
        iata: "PNC",
        countryIata: "USPNC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "North Philadelphia",
        city: "Philadelphia",
        iata: "PNE",
        countryIata: "USPNE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Peterson's Point",
        city: "Peterson's Point",
        iata: "PNF",
        countryIata: "USPNF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Princeton",
        city: "Princeton",
        iata: "PNN",
        countryIata: "USPNN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Regional",
        city: "Pensacola",
        iata: "PNS",
        countryIata: "USPNS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Panguitch",
        city: "Panguitch",
        iata: "PNU",
        countryIata: "USPNU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Grayson County",
        city: "Sherman-Denison",
        iata: "PNX",
        countryIata: "USPNX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pope AFB",
        city: "Fayetteville",
        iata: "POB",
        countryIata: "USPOB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Brackett Field",
        city: "La Verne",
        iata: "POC",
        countryIata: "USPOC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Polk AAF",
        city: "Fort Polk",
        iata: "POE",
        countryIata: "USPOE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Earl Fields Memorial",
        city: "Poplar Bluff",
        iata: "POF",
        countryIata: "USPOF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Pocahontas IA",
        iata: "POH",
        countryIata: "USPOH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Polk Inlet",
        city: "Polk Inlet",
        iata: "POQ",
        countryIata: "USPOQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dutchess County",
        city: "Poughkeepsie",
        iata: "POU",
        countryIata: "USPOU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lovell",
        city: "Powell",
        iata: "POY",
        countryIata: "USPOY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Perry Lefors Field",
        city: "Pampa",
        iata: "PPA",
        countryIata: "USPPA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Prospect Creek",
        city: "Prospect Creek",
        iata: "PPC",
        countryIata: "USPPC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tri-City",
        city: "Parsons",
        iata: "PPF",
        countryIata: "USPPF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pompano Beach",
        city: "Pompano Beach",
        iata: "PPM",
        countryIata: "USPPM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Protection",
        city: "Port Protection",
        iata: "PPV",
        countryIata: "USPPV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Presque Isle",
        iata: "PQI",
        countryIata: "USPQI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pilot Station",
        city: "Pilot Station",
        iata: "PQS",
        countryIata: "USPQS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Paso Robles",
        city: "Paso Robles",
        iata: "PRB",
        countryIata: "USPRB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Prescott",
        city: "Prescott",
        iata: "PRC",
        countryIata: "USPRC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Johnson",
        city: "Port Johnson",
        iata: "PRF",
        countryIata: "USPRF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Oceanic",
        city: "Port Oceanic",
        iata: "PRL",
        countryIata: "USPRL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Perry IA",
        iata: "PRO",
        countryIata: "USPRO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Coast Guard Heliport",
        city: "Point Retreat",
        iata: "PRT",
        countryIata: "USPRT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Prentice",
        city: "Prentice",
        iata: "PRW",
        countryIata: "USPRW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cox Field",
        city: "Paris",
        iata: "PRX",
        countryIata: "USPRX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Prineville",
        city: "Prineville",
        iata: "PRZ",
        countryIata: "USPRZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mid-State",
        city: "Philipsburg",
        iata: "PSB",
        countryIata: "USPSB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tri-Cities",
        city: "Pasco",
        iata: "PSC",
        countryIata: "USPSC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pittsfield",
        city: "Pittsfield",
        iata: "PSF",
        countryIata: "USPSF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Petersburg",
        iata: "PSG",
        countryIata: "USPSG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "New River Valley",
        city: "Dublin",
        iata: "PSK",
        countryIata: "USPSK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pease AFB",
        city: "Portsmouth",
        iata: "PSM",
        countryIata: "USPSM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Palestine",
        city: "Palestine",
        iata: "PSN",
        countryIata: "USPSN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Palm Springs (CA) ,",
        iata: "PSP",
        countryIata: "USPSP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "PB",
        city: "Philadelphia (PA)",
        iata: "PSQ",
        countryIata: "USPSQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Palacios",
        city: "Palacios",
        iata: "PSX",
        countryIata: "USPSX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Alsworth",
        city: "Port Alsworth",
        iata: "PTA",
        countryIata: "USPTA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Petersburg",
        iata: "PTB",
        countryIata: "USPTB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Alice",
        city: "Port Alice",
        iata: "PTC",
        countryIata: "USPTC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Alexander",
        city: "Port Alexander",
        iata: "PTD",
        countryIata: "USPTD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Heiden",
        city: "Port Heiden",
        iata: "PTH",
        countryIata: "USPTH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pontiac",
        city: "Pontiac",
        iata: "PTK",
        countryIata: "USPTK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Armstrong",
        city: "Port Armstrong",
        iata: "PTL",
        countryIata: "USPTL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Williams Memorial",
        city: "Patterson",
        iata: "PTN",
        countryIata: "USPTN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pleasant Harbour",
        city: "Pleasant Harbour",
        iata: "PTR",
        countryIata: "USPTR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal Airport",
        city: "Pittsburg",
        iata: "PTS",
        countryIata: "USPTS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pratt",
        city: "Pratt",
        iata: "PTT",
        countryIata: "USPTT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Platinum",
        city: "Platinum",
        iata: "PTU",
        countryIata: "USPTU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Porterville",
        city: "Porterville",
        iata: "PTV",
        countryIata: "USPTV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pottstown/Limerick",
        city: "Pottstown",
        iata: "PTW",
        countryIata: "USPTW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Memorial",
        city: "Pueblo",
        iata: "PUB",
        countryIata: "USPUB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Carbon County",
        city: "Price",
        iata: "PUC",
        countryIata: "USPUC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Poulsbo",
        city: "Poulsbo",
        iata: "PUL",
        countryIata: "USPUL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Prudhoe Bay",
        city: "Prudhoe Bay",
        iata: "PUO",
        countryIata: "USPUO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Moscow Regional",
        city: "Pullman",
        iata: "PUW",
        countryIata: "USPUW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Provincetown",
        city: "Provincetown",
        iata: "PVC",
        countryIata: "USPVC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Theodore Francis",
        city: "Providence (RI)",
        iata: "PVD",
        countryIata: "USPVD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Placerville",
        city: "Placerville",
        iata: "PVF",
        countryIata: "USPVF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Provo",
        city: "Provo",
        iata: "PVU",
        countryIata: "USPVU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hale County",
        city: "Plainview",
        iata: "PVW",
        countryIata: "USPVW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pope Vanoy",
        city: "Pope Vanoy",
        iata: "PVY",
        countryIata: "USPVY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Casement",
        city: "Painesville",
        iata: "PVZ",
        countryIata: "USPVZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wiley Post",
        city: "Oklahoma City (OK)",
        iata: "PWA",
        countryIata: "USPWA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "herwood",
        city: "Plentywood",
        iata: "PWD",
        countryIata: "USPWD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pal-Waukee",
        city: "Chicago (IL)",
        iata: "PWK",
        countryIata: "USPWK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International Jetport",
        city: "Portland",
        iata: "PWM",
        countryIata: "USPWM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Walter",
        city: "Port Walter",
        iata: "PWR",
        countryIata: "USPWR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bremerton",
        city: "Bremerton",
        iata: "PWT",
        countryIata: "USPWT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Polacca",
        city: "Polacca",
        iata: "PXL",
        countryIata: "USPXL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Perry SPB",
        city: "Perry Island",
        iata: "PYL",
        countryIata: "USPYL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Plymouth",
        city: "Plymouth",
        iata: "PYM",
        countryIata: "USPYM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Montvale",
        city: "Montvale",
        iata: "QMV",
        countryIata: "USQMV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Marine Air Terminal",
        city: "New York (NY)",
        iata: "QNY",
        countryIata: "USQNY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wilgrove Air Park",
        city: "Charlotte (NC)",
        iata: "QWG",
        countryIata: "USQWG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Horlick",
        city: "Racine",
        iata: "RAC",
        countryIata: "USRAC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Riverside Municipal",
        city: "Riverside",
        iata: "RAL",
        countryIata: "USRAL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Regional Airport",
        city: "Rapid City",
        iata: "RAP",
        countryIata: "USRAP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Redbird",
        city: "Dallas/Fort Worth (TX)",
        iata: "RBD",
        countryIata: "USRBD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Big Bear City Airport",
        city: "Big Bear",
        iata: "RBF",
        countryIata: "USRBF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal Airport",
        city: "Roseburg",
        iata: "RBG",
        countryIata: "USRBG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Brooks Lodge",
        city: "Brooks Lodge",
        iata: "RBH",
        countryIata: "USRBH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "French Valley",
        city: "Rancho",
        iata: "RBK",
        countryIata: "USRBK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Red Bluff Fss",
        city: "Red Bluff",
        iata: "RBL",
        countryIata: "USRBL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fort Jefferson",
        city: "Fort Jefferson",
        iata: "RBN",
        countryIata: "USRBN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal Airport",
        city: "Walterboro",
        iata: "RBW",
        countryIata: "USRBW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ruby",
        city: "Ruby",
        iata: "RBY",
        countryIata: "USRBY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ellsworth AFB",
        city: "Rapid City",
        iata: "RCA",
        countryIata: "USRCA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Roche Harbor",
        city: "Roche Harbor",
        iata: "RCE",
        countryIata: "USRCE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Coffield",
        city: "Rockdale",
        iata: "RCK",
        countryIata: "USRCK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fulton County",
        city: "Rochester",
        iata: "RCR",
        countryIata: "USRCR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Miller Field",
        city: "Reed City",
        iata: "RCT",
        countryIata: "USRCT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Red Dog",
        city: "Red Dog",
        iata: "RDB",
        countryIata: "USRDB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Redding",
        city: "Redding",
        iata: "RDD",
        countryIata: "USRDD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal/Spaatz Fld",
        city: "Reading",
        iata: "RDG",
        countryIata: "USRDG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Roberts Field",
        city: "Redmond",
        iata: "RDM",
        countryIata: "USRDM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Grand Forks AFB",
        city: "Red River",
        iata: "RDR",
        countryIata: "USRDR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Durham/Raleigh Airport",
        city: "Raleigh/Durham",
        iata: "RDU",
        countryIata: "USRDU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Red Devil",
        city: "Red Devil",
        iata: "RDV",
        countryIata: "USRDV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mifflin County",
        city: "Reedsville",
        iata: "RED",
        countryIata: "USRED",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Reese AFB",
        city: "Lubbock",
        iata: "REE",
        countryIata: "USREE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rehoboth Beach",
        city: "Rehoboth Beach",
        iata: "REH",
        countryIata: "USREH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "State",
        city: "Rome",
        iata: "REO",
        countryIata: "USREO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Greater Rockford",
        city: "Rockford",
        iata: "RFD",
        countryIata: "USRFD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rooke Field",
        city: "Refugio",
        iata: "RFG",
        countryIata: "USRFG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rollang Field",
        city: "Anguilla",
        iata: "RFK",
        countryIata: "USRFK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ranger Municipal",
        city: "Ranger",
        iata: "RGR",
        countryIata: "USRGR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Oneida County",
        city: "Rhinelander",
        iata: "RHI",
        countryIata: "USRHI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Reid-Hillview",
        city: "San Jose (CA)",
        iata: "RHV",
        countryIata: "USRHV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International (Byrd Field)",
        city: "Richmond (VA)",
        iata: "RIC",
        countryIata: "USRIC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Richmond",
        city: "Richmond",
        iata: "RID",
        countryIata: "USRID",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rice Lake",
        city: "Rice Lake",
        iata: "RIE",
        countryIata: "USRIE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Reynolds",
        city: "Richfield",
        iata: "RIF",
        countryIata: "USRIF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Garfield County",
        city: "Rifle",
        iata: "RIL",
        countryIata: "USRIL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Riverside Fla-Bob",
        city: "Riverside",
        iata: "RIR",
        countryIata: "USRIR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "March AFB",
        city: "Riverside",
        iata: "RIV",
        countryIata: "USRIV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Riverton",
        city: "Riverton",
        iata: "RIW",
        countryIata: "USRIW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Yreka",
        city: "Yreka",
        iata: "RKC",
        countryIata: "USRKC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Knox County Regional",
        city: "Rockland",
        iata: "RKD",
        countryIata: "USRKD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rock Hill",
        city: "Rock Hill",
        iata: "RKH",
        countryIata: "USRKH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Aransas County",
        city: "Rockport",
        iata: "RKP",
        countryIata: "USRKP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Robert S Kerr",
        city: "Poteau",
        iata: "RKR",
        countryIata: "USRKR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sweetwater County",
        city: "Rock Springs",
        iata: "RKS",
        countryIata: "USRKS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Rockwood",
        iata: "RKW",
        countryIata: "USRKW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "National",
        city: "Rolla",
        iata: "RLA",
        countryIata: "USRLA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Richland",
        city: "Richland",
        iata: "RLD",
        countryIata: "USRLD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Reilly AHP",
        city: "Anniston",
        iata: "RLI",
        countryIata: "USRLI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bornite Upper",
        city: "Bornite",
        iata: "RLU",
        countryIata: "USRLU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Machesney",
        city: "Rockford",
        iata: "RMC",
        countryIata: "USRMC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Griffiss AFB",
        city: "Rome",
        iata: "RME",
        countryIata: "USRME",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Richard B Russell",
        city: "Rome",
        iata: "RMG",
        countryIata: "USRMG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rampart",
        city: "Rampart",
        iata: "RMP",
        countryIata: "USRMP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Warren County",
        city: "Mcminnville",
        iata: "RNC",
        countryIata: "USRNC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Randolph AFB",
        city: "San Antonio (TX)",
        iata: "RND",
        countryIata: "USRND",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rangely",
        city: "Rangely",
        iata: "RNG",
        countryIata: "USRNG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "New Richmond",
        iata: "RNH",
        countryIata: "USRNH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Reno-Tahoe International Airport",
        city: "Reno",
        iata: "RNO",
        countryIata: "USRNO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Renton",
        city: "Renton",
        iata: "RNT",
        countryIata: "USRNT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rensselaer",
        city: "Rensselaer",
        iata: "RNZ",
        countryIata: "USRNZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Roanoke",
        iata: "ROA",
        countryIata: "USROA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Monroe County",
        city: "Rochester",
        iata: "ROC",
        countryIata: "USROC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rogers",
        city: "Rogers",
        iata: "ROG",
        countryIata: "USROG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Roosevelt",
        city: "Roosevelt",
        iata: "ROL",
        countryIata: "USROL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Industrial",
        city: "Roswell",
        iata: "ROW",
        countryIata: "USROW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Roseau",
        iata: "ROX",
        countryIata: "USROX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Roundup",
        city: "Roundup",
        iata: "RPX",
        countryIata: "USRPX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Merrill",
        iata: "RRL",
        countryIata: "USRRL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Warroad",
        city: "Warroad",
        iata: "RRT",
        countryIata: "USRRT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Russian SPB",
        city: "Russian Mission",
        iata: "RSH",
        countryIata: "USRSH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rosario SPB",
        city: "Rosario",
        iata: "RSJ",
        countryIata: "USRSJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Russell",
        city: "Russell",
        iata: "RSL",
        countryIata: "USRSL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ruston",
        city: "Ruston",
        iata: "RSN",
        countryIata: "USRSN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Raspberry Strait",
        city: "Raspberry Strait",
        iata: "RSP",
        countryIata: "USRSP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Rochester",
        iata: "RST",
        countryIata: "USRST",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Southwest Florida Reg",
        city: "Fort Myers",
        iata: "RSW",
        countryIata: "USRSW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rouses Point",
        city: "Rouses Point",
        iata: "RSX",
        countryIata: "USRSX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rotunda",
        city: "Rotunda",
        iata: "RTD",
        countryIata: "USRTD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Marguerite Bay",
        city: "Marguerite Bay",
        iata: "RTE",
        countryIata: "USRTE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Spirit Lake",
        city: "Spirit Lake IA",
        iata: "RTL",
        countryIata: "USRTL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Crews Field",
        city: "Raton",
        iata: "RTN",
        countryIata: "USRTN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Ruidoso",
        iata: "RUI",
        countryIata: "USRUI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rutland",
        city: "Rutland",
        iata: "RUT",
        countryIata: "USRUT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Green River",
        city: "Green River",
        iata: "RVR",
        countryIata: "USRVR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "R.Lloyd Jones",
        city: "Tulsa",
        iata: "RVS",
        countryIata: "USRVS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rowan Bay",
        city: "Rowan Bay",
        iata: "RWB",
        countryIata: "USRWB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Redwood Falls Municipal",
        city: "Redwood Falls",
        iata: "RWF",
        countryIata: "USRWF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rocky Mount-Wilson",
        city: "Rocky Mount",
        iata: "RWI",
        countryIata: "USRWI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rawlins",
        city: "Rawlins",
        iata: "RWL",
        countryIata: "USRWL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Quartz Hill",
        city: "Lancaster",
        iata: "RZH",
        countryIata: "USRZH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Halifax County",
        city: "Roanoke Rapids",
        iata: "RZZ",
        countryIata: "USRZZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Shively Field",
        city: "Saratoga",
        iata: "SAA",
        countryIata: "USSAA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sacramento Executive Airport",
        city: "Sacramento (CA)",
        iata: "SAC",
        countryIata: "USSAC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Safford Regional",
        city: "Safford",
        iata: "SAD",
        countryIata: "USSAD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Santa Fe Municipal",
        city: "Santa Fe (NM)",
        iata: "SAF",
        countryIata: "USSAF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "San Diego International Airport",
        city: "San Diego (CA)",
        iata: "SAN",
        countryIata: "USSAN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sparta Community",
        city: "Sparta",
        iata: "SAR",
        countryIata: "USSAR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Salton City",
        city: "Salton City",
        iata: "SAS",
        countryIata: "USSAS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "San Antonio International",
        city: "San Antonio (TX)",
        iata: "SAT",
        countryIata: "USSAT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Savannah International",
        city: "Savannah",
        iata: "SAV",
        countryIata: "USSAV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal Airport",
        city: "Santa Barbara",
        iata: "SBA",
        countryIata: "USSBA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "San Bernardino International Airport",
        city: "San Bernardino",
        iata: "SBD",
        countryIata: "USSBD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sheboygan County Memorial Airport",
        city: "Sheboygan",
        iata: "SBM",
        countryIata: "USSBM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "South Bend Regional",
        city: "South Bend",
        iata: "SBN",
        countryIata: "USSBN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Salina",
        city: "Salina",
        iata: "SBO",
        countryIata: "USSBO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "County Airport",
        city: "San Luis Obispo",
        iata: "SBP",
        countryIata: "USSBP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Steamboat Springs",
        city: "Steamboat Springs",
        iata: "SBS",
        countryIata: "USSBS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tri-City",
        city: "San Bernardino",
        iata: "SBT",
        countryIata: "USSBT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Shelby",
        city: "Shelby",
        iata: "SBX",
        countryIata: "USSBX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wicomico Regional",
        city: "Salisbury-Ocean City",
        iata: "SBY",
        countryIata: "USSBY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "State",
        city: "Scribner",
        iata: "SCB",
        countryIata: "USSCB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Prudhoe Bay/Deadhorse",
        city: "Prudhoe Bay/Deadhorse",
        iata: "SCC",
        countryIata: "USSCC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "University Park",
        city: "State College",
        iata: "SCE",
        countryIata: "USSCE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Scottsdale Municipal",
        city: "Phoenix (AZ)",
        iata: "SCF",
        countryIata: "USSCF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "County",
        city: "Schenectady",
        iata: "SCH",
        countryIata: "USSCH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Smith Cove",
        city: "Smith Cove",
        iata: "SCJ",
        countryIata: "USSCJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Stockton",
        city: "Stockton",
        iata: "SCK",
        countryIata: "USSCK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Scammon Bay",
        iata: "SCM",
        countryIata: "USSCM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal Airport",
        city: "Wilkes-Barre",
        iata: "SCR",
        countryIata: "USSCR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Standiford Field",
        city: "Louisville",
        iata: "SDF",
        countryIata: "USSDF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Brown Field Municipal",
        city: "San Diego (CA)",
        iata: "SDM",
        countryIata: "USSDM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Sand Point",
        iata: "SDP",
        countryIata: "USSDP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sedona",
        city: "Sedona",
        iata: "SDX",
        countryIata: "USSDX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Richland Municipal",
        city: "Sidney",
        iata: "SDY",
        countryIata: "USSDY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Seattle-Tacoma International (SeaTac)",
        city: "Seattle (WA)",
        iata: "SEA",
        countryIata: "USSEA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Gillespie Field",
        city: "San Diego (CA)",
        iata: "SEE",
        countryIata: "USSEE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Air Terminal",
        city: "Sebring",
        iata: "SEF",
        countryIata: "USSEF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Penn Valley",
        city: "Selinsgrove",
        iata: "SEG",
        countryIata: "USSEG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Craig AFB",
        city: "Selma",
        iata: "SEM",
        countryIata: "USSEM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Clark Field",
        city: "Stephenville",
        iata: "SEP",
        countryIata: "USSEP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Freeman Municipal",
        city: "Seymour",
        iata: "SER",
        countryIata: "USSER",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Selfield",
        city: "Selma",
        iata: "SES",
        countryIata: "USSES",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Central Florida Reg.",
        city: "Orlando (FL)",
        iata: "SFB",
        countryIata: "USSFB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Felts Field",
        city: "Spokane",
        iata: "SFF",
        countryIata: "USSFF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sanford",
        city: "Sanford",
        iata: "SFM",
        countryIata: "USSFM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "San Francisco (CA)",
        iata: "SFO",
        countryIata: "USSFO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "San Fernando",
        city: "San Fernando",
        iata: "SFR",
        countryIata: "USSFR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Metropolitan Area",
        city: "Springfield",
        iata: "SFY",
        countryIata: "USSFY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "North Central",
        city: "Smithfield",
        iata: "SFZ",
        countryIata: "USSFZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Springfield-Branson Rg",
        city: "Springfield",
        iata: "SGF",
        countryIata: "USSGF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Springfield",
        city: "Springfield",
        iata: "SGH",
        countryIata: "USSGH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sugar Land Municipal",
        city: "Houston (TX)",
        iata: "SGR",
        countryIata: "USSGR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Stuttgart",
        city: "Stuttgart",
        iata: "SGT",
        countryIata: "USSGT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Saint George",
        iata: "SGU",
        countryIata: "USSGU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Saginaw Bay",
        city: "Saginaw Bay",
        iata: "SGW",
        countryIata: "USSGW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Skagway",
        iata: "SGY",
        countryIata: "USSGY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Shenandoah Valley",
        city: "Staunton",
        iata: "SHD",
        countryIata: "USSHD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Shungnak",
        city: "Shungnak",
        iata: "SHG",
        countryIata: "USSHG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Shishmaref",
        city: "Shishmaref",
        iata: "SHH",
        countryIata: "USSHH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sanderson Field",
        city: "Shelton",
        iata: "SHN",
        countryIata: "USSHN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sheridan",
        city: "Sheridan",
        iata: "SHR",
        countryIata: "USSHR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Regional",
        city: "Shreveport",
        iata: "SHV",
        countryIata: "USSHV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Shageluk",
        city: "Shageluk",
        iata: "SHX",
        countryIata: "USSHX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Memorial",
        city: "Sikeston",
        iata: "SIK",
        countryIata: "USSIK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sitka",
        city: "Sitka",
        iata: "SIT",
        countryIata: "USSIT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "County",
        city: "Sullivan",
        iata: "SIV",
        countryIata: "USSIV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Siskiyou County",
        city: "Montague",
        iata: "SIY",
        countryIata: "USSIY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "San Jose (CA)",
        iata: "SJC",
        countryIata: "USSJC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "St Johns",
        iata: "SJN",
        countryIata: "USSJN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mathis Field",
        city: "San Angelo",
        iata: "SJT",
        countryIata: "USSJT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Fairchild AFB",
        city: "Spokane",
        iata: "SKA",
        countryIata: "USSKA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kelly AFB",
        city: "San Antonio (TX)",
        iata: "SKF",
        countryIata: "USSKF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sitkinak Cgs",
        city: "Sitkinak Island",
        iata: "SKJ",
        countryIata: "USSKJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Shaktoolik",
        city: "Shaktoolik",
        iata: "SKK",
        countryIata: "USSKK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Intermediate",
        city: "Skwentna",
        iata: "SKW",
        countryIata: "USSKW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Griffing Sandusky",
        city: "Sandusky",
        iata: "SKY",
        countryIata: "USSKY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Storm Lake IA",
        iata: "SLB",
        countryIata: "USSLB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Salt Lake City (UT)",
        iata: "SLC",
        countryIata: "USSLC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mcnary Field",
        city: "Salem (OR)",
        iata: "SLE",
        countryIata: "USSLE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Smith Field",
        city: "Siloam Springs",
        iata: "SLG",
        countryIata: "USSLG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Stellar Air Park",
        city: "Chandler",
        iata: "SLJ",
        countryIata: "USSLJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Adirondack",
        city: "Saranac Lake",
        iata: "SLK",
        countryIata: "USSLK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Salina",
        city: "Salina",
        iata: "SLN",
        countryIata: "USSLN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Leckrone",
        city: "Salem",
        iata: "SLO",
        countryIata: "USSLO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sleetmute",
        city: "Sleetmute",
        iata: "SLQ",
        countryIata: "USSLQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sulphur Springs",
        city: "Sulphur Springs",
        iata: "SLR",
        countryIata: "USSLR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Salida",
        city: "Salida",
        iata: "SLT",
        countryIata: "USSLT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Smith Field",
        city: "Fort Wayne",
        iata: "SMD",
        countryIata: "USSMD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pulaski County",
        city: "Somerset",
        iata: "SME",
        countryIata: "USSME",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Metropolitan",
        city: "Sacramento (CA)",
        iata: "SMF",
        countryIata: "USSMF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "St Michael",
        city: "St Michael",
        iata: "SMK",
        countryIata: "USSMK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Salmon",
        city: "Salmon",
        iata: "SMN",
        countryIata: "USSMN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Santa Monica",
        city: "Santa Monica",
        iata: "SMO",
        countryIata: "USSMO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sheep Mountain",
        city: "Sheep Mountain",
        iata: "SMU",
        countryIata: "USSMU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Public",
        city: "Santa Maria",
        iata: "SMX",
        countryIata: "USSMX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "John Wayne International",
        city: "Santa Ana",
        iata: "SNA",
        countryIata: "USSNA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Winston Field",
        city: "Snyder",
        iata: "SNK",
        countryIata: "USSNK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Shawnee",
        iata: "SNL",
        countryIata: "USSNL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Saint Paul Island",
        city: "Saint Paul Island",
        iata: "SNP",
        countryIata: "USSNP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Salinas",
        city: "Salinas",
        iata: "SNS",
        countryIata: "USSNS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sidney",
        city: "Sidney",
        iata: "SNY",
        countryIata: "USSNY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Solomon",
        city: "Solomon",
        iata: "SOL",
        countryIata: "USSOL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pinehurst-S. Pines",
        city: "Southern Pines",
        iata: "SOP",
        countryIata: "USSOP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Seldovia",
        city: "Seldovia",
        iata: "SOV",
        countryIata: "USSOV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Show Low",
        city: "Show Low",
        iata: "SOW",
        countryIata: "USSOW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Downtown Memorial",
        city: "Spartanburg",
        iata: "SPA",
        countryIata: "USSPA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Black Hills",
        city: "Spearfish",
        iata: "SPF",
        countryIata: "USSPF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Albert Whitted Airport",
        city: "St Petersburg",
        iata: "SPG",
        countryIata: "USSPG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Capital",
        city: "Springfield (IL)",
        iata: "SPI",
        countryIata: "USSPI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Catalina SPB",
        city: "San Pedro",
        iata: "SPQ",
        countryIata: "USSPQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sheppard AFB",
        city: "Wichita Falls",
        iata: "SPS",
        countryIata: "USSPS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Spencer IA",
        iata: "SPW",
        countryIata: "USSPW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Spaceland",
        city: "Houston (TX)",
        iata: "SPX",
        countryIata: "USSPX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Springdale Muni",
        city: "Springdale",
        iata: "SPZ",
        countryIata: "USSPZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Santa Ynez",
        city: "Santa Ynez",
        iata: "SQA",
        countryIata: "USSQA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Whiteside County",
        city: "Sterling Rockfalls",
        iata: "SQI",
        countryIata: "USSQI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "San Carlos",
        city: "San Carlos",
        iata: "SQL",
        countryIata: "USSQL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sequim Valley Airport",
        city: "Sequim",
        iata: "SQV",
        countryIata: "USSQV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Searcy",
        city: "Searcy",
        iata: "SRC",
        countryIata: "USSRC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hamilton Field",
        city: "San Rafael",
        iata: "SRF",
        countryIata: "USSRF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sarasota–Bradenton International Airport",
        city: "Sarasota",
        iata: "SRQ",
        countryIata: "USSRQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Skypark",
        city: "Santa Cruz",
        iata: "SRU",
        countryIata: "USSRU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Stony River",
        city: "Stony River",
        iata: "SRV",
        countryIata: "USSRV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rowan County",
        city: "Salisbury",
        iata: "SRW",
        countryIata: "USSRW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Shaw AFB",
        city: "Sumter",
        iata: "SSC",
        countryIata: "USSSC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Stinson Municipal",
        city: "San Antonio (TX)",
        iata: "SSF",
        countryIata: "USSSF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mckinnon, St Simons Is.",
        city: "Brunswick",
        iata: "SSI",
        countryIata: "USSSI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Metropolitan Area",
        city: "Sault Ste. Marie",
        iata: "SSM",
        countryIata: "USSSM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Greenbrier",
        city: "White Sulphur Springs",
        iata: "SSU",
        countryIata: "USSSU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Stuart Island",
        city: "Stuart Island",
        iata: "SSW",
        countryIata: "USSSW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Saint Cloud",
        iata: "STC",
        countryIata: "USSTC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Stevens Point",
        city: "Stevens Point",
        iata: "STE",
        countryIata: "USSTE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "St George Island",
        city: "St George Island",
        iata: "STG",
        countryIata: "USSTG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rosecrans Memorial",
        city: "St Joseph",
        iata: "STJ",
        countryIata: "USSTJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Crosson Field",
        city: "Sterling",
        iata: "STK",
        countryIata: "USSTK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lambert-St Louis International",
        city: "St Louis (MO)",
        iata: "STL",
        countryIata: "USSTL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "St. Paul Downtown",
        city: "St Paul (MN)",
        iata: "STP",
        countryIata: "USSTP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "St Marys",
        city: "St Marys",
        iata: "STQ",
        countryIata: "USSTQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sonoma County",
        city: "Santa Rosa",
        iata: "STS",
        countryIata: "USSTS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Witham Field",
        city: "Stuart",
        iata: "SUA",
        countryIata: "USSUA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Schloredt",
        city: "Sundance",
        iata: "SUC",
        countryIata: "USSUC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Stroud",
        city: "Stroud",
        iata: "SUD",
        countryIata: "USSUD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Door County",
        city: "Sturgeon Bay",
        iata: "SUE",
        countryIata: "USSUE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Sumter",
        iata: "SUM",
        countryIata: "USSUM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hailey/Sun Valley Airport",
        city: "Sun Valley",
        iata: "SUN",
        countryIata: "USSUN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sun River",
        city: "Sun River",
        iata: "SUO",
        countryIata: "USSUO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Spirit Of St Louis",
        city: "St Louis (MO)",
        iata: "SUS",
        countryIata: "USSUS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Travis AFB",
        city: "Fairfield",
        iata: "SUU",
        countryIata: "USSUU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Richard I Bong Airport",
        city: "Superior",
        iata: "SUW",
        countryIata: "USSUW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sioux Gateway",
        city: "Sioux City IA",
        iata: "SUX",
        countryIata: "USSUX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Savoonga",
        city: "Savoonga",
        iata: "SVA",
        countryIata: "USSVA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Grant County",
        city: "Silver City",
        iata: "SVC",
        countryIata: "USSVC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Susanville",
        city: "Susanville",
        iata: "SVE",
        countryIata: "USSVE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Statesville",
        iata: "SVH",
        countryIata: "USSVH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hunter AAF",
        city: "Savannah",
        iata: "SVN",
        countryIata: "USSVN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Stevens Village",
        city: "Stevens Village",
        iata: "SVS",
        countryIata: "USSVS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sparrevohn AFS",
        city: "Sparrevohn",
        iata: "SVW",
        countryIata: "USSVW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Seward",
        city: "Seward",
        iata: "SWD",
        countryIata: "USSWD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Stewart",
        city: "Poughkeepsie",
        iata: "SWF",
        countryIata: "USSWF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Searcy Fld",
        city: "Stillwater",
        iata: "SWO",
        countryIata: "USSWO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sweetwater",
        city: "Sweetwater",
        iata: "SWW",
        countryIata: "USSWW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Avalo Vor/WP",
        city: "Catalina Island",
        iata: "SXC",
        countryIata: "USSXC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sheldon SPB",
        city: "Sheldon Point",
        iata: "SXP",
        countryIata: "USSXP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Soldotna",
        city: "Soldotna",
        iata: "SXQ",
        countryIata: "USSXQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sidney",
        city: "Sidney",
        iata: "SXY",
        countryIata: "USSXY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Shemya AFB",
        city: "Shemya",
        iata: "SYA",
        countryIata: "USSYA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Seal Bay",
        city: "Seal Bay",
        iata: "SYB",
        countryIata: "USSYB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bomar Field",
        city: "Shelbyville",
        iata: "SYI",
        countryIata: "USSYI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Roberts AAF",
        city: "San Miguel",
        iata: "SYL",
        countryIata: "USSYL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Carleton",
        city: "Stanton",
        iata: "SYN",
        countryIata: "USSYN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hancock International",
        city: "Syracuse",
        iata: "SYR",
        countryIata: "USSYR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sylvester",
        city: "Sylvester",
        iata: "SYV",
        countryIata: "USSYV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Whiteman AFB",
        city: "Warrensburg",
        iata: "SZL",
        countryIata: "USSZL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Santa Cruz Island",
        city: "Santa Barbara",
        iata: "SZN",
        countryIata: "USSZN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Santa Paula",
        city: "Santa Paula",
        iata: "SZP",
        countryIata: "USSZP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Las Animas",
        city: "Trinidad",
        iata: "TAD",
        countryIata: "USTAD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ralph Calhoun",
        city: "Tanana",
        iata: "TAL",
        countryIata: "USTAL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tuba City",
        city: "Tuba City",
        iata: "TBC",
        countryIata: "USTBC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Forney AAF",
        city: "Fort Leonard Wood",
        iata: "TBN",
        countryIata: "USTBN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Statesboro",
        iata: "TBR",
        countryIata: "USTBR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tucumcari",
        city: "Tucumcari",
        iata: "TCC",
        countryIata: "USTCC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Van De Graaf",
        city: "Tuscaloosa",
        iata: "TCL",
        countryIata: "USTCL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mc Chord AFB",
        city: "Tacoma",
        iata: "TCM",
        countryIata: "USTCM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Truth Or Consequences",
        iata: "TCS",
        countryIata: "USTCS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Takotna",
        city: "Takotna",
        iata: "TCT",
        countryIata: "USTCT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Winlock",
        city: "Toledo",
        iata: "TDO",
        countryIata: "USTDO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tradewind",
        city: "Amarillo",
        iata: "TDW",
        countryIata: "USTDW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Toledo",
        city: "Toledo",
        iata: "TDZ",
        countryIata: "USTDZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Teterboro",
        city: "Teterboro",
        iata: "TEB",
        countryIata: "USTEB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tetlin",
        city: "Tetlin",
        iata: "TEH",
        countryIata: "USTEH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tatitlek",
        city: "Tatitlek",
        iata: "TEK",
        countryIata: "USTEK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Telluride",
        city: "Telluride",
        iata: "TEX",
        countryIata: "USTEX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sharpe Field",
        city: "Tuskegee",
        iata: "TGE",
        countryIata: "USTGE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Northern",
        city: "Tullahoma",
        iata: "THA",
        countryIata: "USTHA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Thompsonfield",
        city: "Thompsonfield",
        iata: "THM",
        countryIata: "USTHM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hot Springs",
        city: "Thermopolis",
        iata: "THP",
        countryIata: "USTHP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "York",
        city: "York",
        iata: "THV",
        countryIata: "USTHV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tinker AFB",
        city: "Oklahoma City (OK)",
        iata: "TIK",
        countryIata: "USTIK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Industrial",
        city: "Tacoma",
        iata: "TIW",
        countryIata: "USTIW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Space Center Exect.",
        city: "Titusville",
        iata: "TIX",
        countryIata: "USTIX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Talkeetna",
        city: "Talkeetna",
        iata: "TKA",
        countryIata: "USTKA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tenakee SPB",
        city: "Tenakee Springs",
        iata: "TKE",
        countryIata: "USTKE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Truckee",
        city: "Truckee",
        iata: "TKF",
        countryIata: "USTKF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tokeen",
        city: "Tokeen",
        iata: "TKI",
        countryIata: "USTKI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tok",
        city: "Tok",
        iata: "TKJ",
        countryIata: "USTKJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Taku SPB",
        city: "Taku Lodge",
        iata: "TKL",
        countryIata: "USTKL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Teller",
        city: "Teller",
        iata: "TLA",
        countryIata: "USTLA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Telida",
        city: "Telida",
        iata: "TLF",
        countryIata: "USTLF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Tallahassee (FL)",
        iata: "TLH",
        countryIata: "USTLH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tatalina AFS",
        city: "Tatalina",
        iata: "TLJ",
        countryIata: "USTLJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tulare",
        city: "Tulare",
        iata: "TLR",
        countryIata: "USTLR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tuluksak",
        city: "Tuluksak",
        iata: "TLT",
        countryIata: "USTLT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Henry Tift Myers",
        city: "Tifton",
        iata: "TMA",
        countryIata: "USTMA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tamiami",
        city: "Miami (FL)",
        iata: "TMB",
        countryIata: "USTMB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tin City AFS",
        city: "Tin City",
        iata: "TNC",
        countryIata: "USTNC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tununak",
        city: "Tununak",
        iata: "TNK",
        countryIata: "USTNK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Twentynine Palms",
        city: "Twentynine Palms",
        iata: "TNP",
        countryIata: "USTNP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dade Collier",
        city: "Miami (FL)",
        iata: "TNT",
        countryIata: "USTNT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Newton IA",
        iata: "TNU",
        countryIata: "USTNU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Torrance",
        city: "Torrance",
        iata: "TOA",
        countryIata: "USTOA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Toccoa",
        city: "Toccoa",
        iata: "TOC",
        countryIata: "USTOC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Togiak Airport",
        city: "Togiak Village",
        iata: "TOG",
        countryIata: "USTOG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Troy",
        iata: "TOI",
        countryIata: "USTOI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Express",
        city: "Toledo",
        iata: "TOL",
        countryIata: "USTOL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Philip Billard",
        city: "Topeka",
        iata: "TOP",
        countryIata: "USTOP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Torrington",
        iata: "TOR",
        countryIata: "USTOR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tampa International",
        city: "Tampa (FL)",
        iata: "TPA",
        countryIata: "USTPA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Peter O. Knight Airport",
        city: "Tampa (FL)",
        iata: "TPF",
        countryIata: "USTPF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tonopah Airport",
        city: "Tonopah",
        iata: "TPH",
        countryIata: "USTPH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Draughon-Miller",
        city: "Temple",
        iata: "TPL",
        countryIata: "USTPL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tanalian Point",
        city: "Tanalian Point",
        iata: "TPO",
        countryIata: "USTPO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Trona",
        city: "Trona",
        iata: "TRH",
        countryIata: "USTRH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tri-Cities Regional",
        city: "Kingsport",
        iata: "TRI",
        countryIata: "USTRI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Terrell Field",
        city: "Terrell",
        iata: "TRL",
        countryIata: "USTRL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Thermal",
        city: "Thermal",
        iata: "TRM",
        countryIata: "USTRM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Coast Guard Heliport",
        city: "Tree Point",
        iata: "TRP",
        countryIata: "USTRP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tremonton",
        city: "Tremonton",
        iata: "TRT",
        countryIata: "USTRT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Memorial",
        city: "Trenton",
        iata: "TRX",
        countryIata: "USTRX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Intermediate",
        city: "Tanacross",
        iata: "TSG",
        countryIata: "USTSG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Taos",
        city: "Taos",
        iata: "TSM",
        countryIata: "USTSM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kern County",
        city: "Tehachapi",
        iata: "TSP",
        countryIata: "USTSP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "East 34th St Heliport",
        city: "New York (NY)",
        iata: "TSS",
        countryIata: "USTSS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Troutdale",
        city: "Troutdale",
        iata: "TTD",
        countryIata: "USTTD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Trenton-Mercer",
        city: "Trenton/Philadelphia (PA)",
        iata: "TTN",
        countryIata: "USTTN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Britton",
        iata: "TTO",
        countryIata: "USTTO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Arnold AFS",
        city: "Tullahoma",
        iata: "TUH",
        countryIata: "USTUH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Tulsa",
        iata: "TUL",
        countryIata: "USTUL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lemons Municipal",
        city: "Tupelo",
        iata: "TUP",
        countryIata: "USTUP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Tucson (AZ)",
        iata: "TUS",
        countryIata: "USTUS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Traverse City",
        city: "Traverse City",
        iata: "TVC",
        countryIata: "USTVC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Regional",
        city: "Thief River Falls",
        iata: "TVF",
        countryIata: "USTVF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Thomasville",
        iata: "TVI",
        countryIata: "USTVI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "South Lake Tahoe",
        city: "South Lake Tahoe",
        iata: "TVL",
        countryIata: "USTVL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Twin Hills",
        city: "Twin Hills",
        iata: "TWA",
        countryIata: "USTWA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Port Townsend",
        city: "Port Townsend",
        iata: "TWD",
        countryIata: "USTWD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Taylor",
        city: "Taylor",
        iata: "TWE",
        countryIata: "USTWE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "City County",
        city: "Twin Falls",
        iata: "TWF",
        countryIata: "USTWF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Two Harbors",
        city: "Catalina Island",
        iata: "TWH",
        countryIata: "USTWH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Texarkana",
        iata: "TXK",
        countryIata: "USTXK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tyonek",
        city: "Tyonek",
        iata: "TYE",
        countryIata: "USTYE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pounds Field",
        city: "Tyler",
        iata: "TYR",
        countryIata: "USTYR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mc Ghee Tyson",
        city: "Knoxville",
        iata: "TYS",
        countryIata: "USTYS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Taylor",
        city: "Taylor",
        iata: "TYZ",
        countryIata: "USTYZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Lowndes County",
        city: "Columbus",
        iata: "UBS",
        countryIata: "USUBS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Oneida County",
        city: "Utica",
        iata: "UCA",
        countryIata: "USUCA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Yucca Flat",
        city: "Yucca Flat",
        iata: "UCC",
        countryIata: "USUCC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Eunice",
        city: "Eunice",
        iata: "UCE",
        countryIata: "USUCE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Everett-Stewart",
        city: "Union City",
        iata: "UCY",
        countryIata: "USUCY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Bermuda Dunes Airport",
        city: "Palm Springs",
        iata: "UDD",
        countryIata: "USUDD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Waukesha",
        city: "Waukesha",
        iata: "UES",
        countryIata: "USUES",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ugashik Bay",
        city: "Pilot Point",
        iata: "UGB",
        countryIata: "USUGB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Uganik",
        city: "Uganik",
        iata: "UGI",
        countryIata: "USUGI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Memorial",
        city: "Waukegan",
        iata: "UGN",
        countryIata: "USUGN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ugashik",
        city: "Ugashik",
        iata: "UGS",
        countryIata: "USUGS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Quillayute State",
        city: "Quillayute",
        iata: "UIL",
        countryIata: "USUIL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Quincy",
        iata: "UIN",
        countryIata: "USUIN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Berz-Macomb",
        city: "Utica",
        iata: "UIZ",
        countryIata: "USUIZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ukiah",
        city: "Ukiah",
        iata: "UKI",
        countryIata: "USUKI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Waukon IA",
        iata: "UKN",
        countryIata: "USUKN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Upper Bucks",
        city: "Quakertown",
        iata: "UKT",
        countryIata: "USUKT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "New Ulm",
        city: "New Ulm",
        iata: "ULM",
        countryIata: "USULM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "North Shore",
        city: "Umnak Island",
        iata: "UMB",
        countryIata: "USUMB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Summit",
        city: "Summit",
        iata: "UMM",
        countryIata: "USUMM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Umiat",
        city: "Umiat",
        iata: "UMT",
        countryIata: "USUMT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Unalakleet",
        city: "Unalakleet",
        iata: "UNK",
        countryIata: "USUNK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Umnak",
        city: "Umnak Island",
        iata: "UNS",
        countryIata: "USUNS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Dodge County",
        city: "Juneau",
        iata: "UNU",
        countryIata: "USUNU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Franklin County",
        city: "Sewanee",
        iata: "UOS",
        countryIata: "USUOS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "University-Oxford",
        city: "Oxford",
        iata: "UOX",
        countryIata: "USUOX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Upolu Point",
        city: "Upolu Point",
        iata: "UPP",
        countryIata: "USUPP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Queen",
        city: "Queen",
        iata: "UQE",
        countryIata: "USUQE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "St Augustine",
        city: "St Augustine",
        iata: "UST",
        countryIata: "USUST",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Indian Mountain AFS",
        city: "Utopia Creek",
        iata: "UTO",
        countryIata: "USUTO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kuparuk",
        city: "Kuparuk",
        iata: "UUK",
        countryIata: "USUUK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Garner Fld",
        city: "Uvalde",
        iata: "UVA",
        countryIata: "USUVA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ware",
        city: "Ware",
        iata: "UWA",
        countryIata: "USUWA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Moody AFB",
        city: "Valdosta",
        iata: "VAD",
        countryIata: "USVAD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chevak",
        city: "Chevak",
        iata: "VAK",
        countryIata: "USVAK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Vandenberg AFB",
        city: "Lompoc",
        iata: "VBG",
        countryIata: "USVBG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "View Cove",
        city: "View Cove",
        iata: "VCB",
        countryIata: "USVCB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "County-Foster",
        city: "Victoria",
        iata: "VCT",
        countryIata: "USVCT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "George AFB",
        city: "Victorville",
        iata: "VCV",
        countryIata: "USVCV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Vidalia",
        iata: "VDI",
        countryIata: "USVDI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tom O'Connor Oilfield",
        city: "Refugio",
        iata: "VDU",
        countryIata: "USVDU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Valdez",
        iata: "VDZ",
        countryIata: "USVDZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Venetie",
        city: "Venetie",
        iata: "VEE",
        countryIata: "USVEE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Vernal",
        city: "Vernal",
        iata: "VEL",
        countryIata: "USVEL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Tioga",
        iata: "VEX",
        countryIata: "USVEX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "North Air Terminal",
        city: "Las Vegas",
        iata: "VGT",
        countryIata: "USVGT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Culberson County",
        city: "Van Horn",
        iata: "VHN",
        countryIata: "USVHN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Rolla National",
        city: "Vichy",
        iata: "VIH",
        countryIata: "USVIH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Airstrip",
        city: "Kavik",
        iata: "VIK",
        countryIata: "USVIK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Visalia",
        city: "Visalia",
        iata: "VIS",
        countryIata: "USVIS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Virginia Highlands",
        city: "Abingdon",
        iata: "VJI",
        countryIata: "USVJI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Vicksburg",
        city: "Vicksburg",
        iata: "VKS",
        countryIata: "USVKS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "West Kavik",
        city: "West Kavik",
        iata: "VKW",
        countryIata: "USVKW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Vandalia",
        city: "Vandalia",
        iata: "VLA",
        countryIata: "USVLA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Regional",
        city: "Valdosta",
        iata: "VLD",
        countryIata: "USVLD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "J t Robidoux",
        city: "Valle",
        iata: "VLE",
        countryIata: "USVLE",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Stolport",
        city: "Vallejo",
        iata: "VLO",
        countryIata: "USVLO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Venice Municipal Airport",
        city: "Venice",
        iata: "VNC",
        countryIata: "USVNC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Van Nuys",
        city: "Los Angeles (CA)",
        iata: "VNY",
        countryIata: "USVNY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Volk Field",
        city: "Camp Douglas",
        iata: "VOK",
        countryIata: "USVOK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Ft. Walton Beach",
        city: "Valparaiso",
        iata: "VPS",
        countryIata: "USVPS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Porter County",
        city: "Valparaiso",
        iata: "VPZ",
        countryIata: "USVPZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Vero Beach",
        iata: "VRB",
        countryIata: "USVRB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Versailles",
        city: "Versailles",
        iata: "VRS",
        countryIata: "USVRS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "State",
        city: "Springfield",
        iata: "VSF",
        countryIata: "USVSF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Miller Field",
        city: "Valentine",
        iata: "VTN",
        countryIata: "USVTN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Illinois Valley Regnl",
        city: "Peru",
        iata: "VYS",
        countryIata: "USVYS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wales",
        city: "Wales",
        iata: "WAA",
        countryIata: "USWAA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wahpeton",
        city: "Wahpeton",
        iata: "WAH",
        countryIata: "USWAH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wallops Flight Center",
        city: "Chincoteague",
        iata: "WAL",
        countryIata: "USWAL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Metropolitan Area",
        city: "Washington, DC",
        iata: "WAS",
        countryIata: "USWAS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Green County",
        city: "Waynesburg",
        iata: "WAY",
        countryIata: "USWAY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Stebbins",
        city: "Stebbins",
        iata: "WBB",
        countryIata: "USWBB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Broker Inn",
        city: "Boulder",
        iata: "WBI",
        countryIata: "USWBI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cummings Park",
        city: "Woburn",
        iata: "WBN",
        countryIata: "USWBN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Beaver",
        city: "Beaver",
        iata: "WBQ",
        countryIata: "USWBQ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Big Rapids",
        city: "Big Rapids",
        iata: "WBR",
        countryIata: "USWBR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Boulder",
        iata: "WBU",
        countryIata: "USWBU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wyoming Valle",
        city: "Wilkes-Barre",
        iata: "WBW",
        countryIata: "USWBW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chandalar",
        city: "Chandalar",
        iata: "WCR",
        countryIata: "USWCR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Deep Bay",
        city: "Deep Bay",
        iata: "WDB",
        countryIata: "USWDB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Enid Woodring Mun.",
        city: "Enid",
        iata: "WDG",
        countryIata: "USWDG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Waldron Island",
        city: "Waldron Island",
        iata: "WDN",
        countryIata: "USWDN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Winder",
        city: "Winder",
        iata: "WDR",
        countryIata: "USWDR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Kodiak Fss",
        city: "Kodiak",
        iata: "WDY",
        countryIata: "USWDY",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Parker County",
        city: "Weatherford",
        iata: "WEA",
        countryIata: "USWEA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Waterfront SPB",
        city: "Ketchikan",
        iata: "WFB",
        countryIata: "USWFB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Frenchville",
        city: "Frenchville",
        iata: "WFK",
        countryIata: "USWFK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Winchester",
        iata: "WGO",
        countryIata: "USWGO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Hyder",
        iata: "WHD",
        countryIata: "USWHD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Hiltons Har H",
        city: "Boulder",
        iata: "WHH",
        countryIata: "USWHH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Whiteman",
        city: "Los Angeles (CA)",
        iata: "WHP",
        countryIata: "USWHP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wharton",
        city: "Wharton",
        iata: "WHT",
        countryIata: "USWHT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "William J Fox",
        city: "Lancaster",
        iata: "WJF",
        countryIata: "USWJF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Aleknagik Airport",
        city: "Aleknagik",
        iata: "WKK",
        countryIata: "USWKK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Waikoloa Airport",
        city: "Waikoloa",
        iata: "WKL",
        countryIata: "USWKL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Labouchere Bay",
        city: "Labouchere Bay",
        iata: "WLB",
        countryIata: "USWLB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Arkansas City",
        city: "Winfield",
        iata: "WLD",
        countryIata: "USWLD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Selawik",
        city: "Selawik",
        iata: "WLK",
        countryIata: "USWLK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Waltham",
        city: "Waltham",
        iata: "WLM",
        countryIata: "USWLM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Little Naukati",
        city: "Little Naukati",
        iata: "WLN",
        countryIata: "USWLN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Loring",
        city: "Loring",
        iata: "WLR",
        countryIata: "USWLR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Willows-Glenn County Airport",
        city: "Willows",
        iata: "WLW",
        countryIata: "USWLW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Winnemucca",
        city: "Winnemucca",
        iata: "WMC",
        countryIata: "USWMC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mountain Home",
        city: "Mountain Home",
        iata: "WMH",
        countryIata: "USWMH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Meyers Chuck",
        iata: "WMK",
        countryIata: "USWMK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "White Mountain",
        city: "White Mountain",
        iata: "WMO",
        countryIata: "USWMO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Napakiak SPB",
        city: "Napakiak",
        iata: "WNA",
        countryIata: "USWNA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nichen Cove SPB",
        city: "Tuxekan Island",
        iata: "WNC",
        countryIata: "USWNC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wood River",
        city: "Wood River",
        iata: "WOD",
        countryIata: "USWOD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Woodchopper",
        city: "Woodchopper",
        iata: "WOO",
        countryIata: "USWOO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Willow",
        city: "Willow",
        iata: "WOW",
        countryIata: "USWOW",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "North Fork Valley",
        city: "Paonia",
        iata: "WPO",
        countryIata: "USWPO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Robins AFB",
        city: "Macon",
        iata: "WRB",
        countryIata: "USWRB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wrangell SPB",
        city: "Wrangell",
        iata: "WRG",
        countryIata: "USWRG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wrench Creek",
        city: "Wrench Creek",
        iata: "WRH",
        countryIata: "USWRH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Mc Guire AFB",
        city: "Fort Dix",
        iata: "WRI",
        countryIata: "USWRI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Worland",
        city: "Worland",
        iata: "WRL",
        countryIata: "USWRL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Steamboat Bay",
        iata: "WSB",
        countryIata: "USWSB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Condron AAF",
        city: "White Sands",
        iata: "WSD",
        countryIata: "USWSD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Sarichef",
        city: "Sarichef",
        iata: "WSF",
        countryIata: "USWSF",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "County",
        city: "Washington",
        iata: "WSG",
        countryIata: "USWSG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Brookhaven",
        city: "Shirley",
        iata: "WSH",
        countryIata: "USWSH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "San Juan SPB",
        city: "San Juan",
        iata: "WSJ",
        countryIata: "USWSJ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wiseman",
        city: "Wiseman",
        iata: "WSM",
        countryIata: "USWSM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "South Naknek",
        city: "South Naknek",
        iata: "WSN",
        countryIata: "USWSN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Westerly State",
        city: "Westerly",
        iata: "WST",
        countryIata: "USWST",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Westsound",
        city: "Westsound",
        iata: "WSX",
        countryIata: "USWSX",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "World Trade Center",
        city: "New York (NY)",
        iata: "WTC",
        countryIata: "USWTC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Noatak",
        city: "Noatak",
        iata: "WTK",
        countryIata: "USWTK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Tuntutuliak",
        city: "Tuntutuliak",
        iata: "WTL",
        countryIata: "USWTL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "White River",
        city: "White River",
        iata: "WTR",
        countryIata: "USWTR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Watsonville",
        city: "Watsonville",
        iata: "WVI",
        countryIata: "USWVI",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Robert Lafleur",
        city: "Waterville",
        iata: "WVL",
        countryIata: "USWVL",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Wasilla",
        city: "Wasilla",
        iata: "WWA",
        countryIata: "USWWA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cape May County",
        city: "Wildwood",
        iata: "WWD",
        countryIata: "USWWD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Whale Pass",
        city: "Whale Pass",
        iata: "WWP",
        countryIata: "USWWP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "West Woodward",
        city: "Woodward",
        iata: "WWR",
        countryIata: "USWWR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "USAF Heliport",
        city: "Wildwood",
        iata: "WWS",
        countryIata: "USWWS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Newtok",
        city: "Newtok",
        iata: "WWT",
        countryIata: "USWWT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "SPB",
        city: "Yes Bay",
        iata: "WYB",
        countryIata: "USWYB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Yellowstone",
        city: "West Yellowstone",
        iata: "WYS",
        countryIata: "USWYS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Municipal",
        city: "Lake Geneva",
        iata: "XES",
        countryIata: "USXES",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Madison",
        city: "Madison",
        iata: "XMD",
        countryIata: "USXMD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Northwest Arkansas Rgn",
        city: "Fayetteville",
        iata: "XNA",
        countryIata: "USXNA",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Pine Ridge",
        city: "Pine Ridge",
        iata: "XPR",
        countryIata: "USXPR",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "West Kuparuk",
        city: "West Kuparuk",
        iata: "XPU",
        countryIata: "USXPU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Test Range",
        city: "Tonopah",
        iata: "XSD",
        countryIata: "USXSD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "St Marys",
        city: "St Marys",
        iata: "XSM",
        countryIata: "USXSM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Yakutat",
        city: "Yakutat",
        iata: "YAK",
        countryIata: "USYAK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Willow Run",
        city: "Detroit (MI) ,",
        iata: "YIP",
        countryIata: "USYIP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Yakima Air Terminal",
        city: "Yakima",
        iata: "YKM",
        countryIata: "USYKM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chan Gurney",
        city: "Yankton",
        iata: "YKN",
        countryIata: "USYKN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Youngstown",
        city: "Youngstown",
        iata: "YNG",
        countryIata: "USYNG",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "International",
        city: "Yuma",
        iata: "YUM",
        countryIata: "USYUM",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Buffalo Depew RR",
        city: "Buffalo",
        iata: "ZFZ",
        countryIata: "USZFZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Nyac",
        city: "Nyac",
        iata: "ZNC",
        countryIata: "USZNC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Zephyrhills Municipal Airport",
        city: "Zephyrhills",
        iata: "ZPH",
        countryIata: "USZPH",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Zanesville",
        city: "Zanesville",
        iata: "ZZV",
        countryIata: "USZZV",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chisana Field",
        city: "Chisana",
        iata: "CZN",
        countryIata: "USCZN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Chistochina",
        city: "Chistochina",
        iata: "CZO",
        countryIata: "USCZO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cape Pole",
        city: "Cape Pole",
        iata: "CZP",
        countryIata: "USCZP",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Delta Junction",
        city: "Delta Junction",
        iata: "DJN",
        countryIata: "USDJN",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Campo",
        city: "Campo",
        iata: "CZZ",
        countryIata: "USCZZ",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Boxborough",
        city: "Boxborough",
        iata: "BXC",
        countryIata: "USBXC",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Antrim County",
        city: "Bellaire",
        iata: "ACB",
        countryIata: "USACB",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Drummond",
        city: "Drummond",
        iata: "DRU",
        countryIata: "USDRU",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Clayton",
        city: "Clayton",
        iata: "CAO",
        countryIata: "USCAO",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Cannon AFB",
        city: "Clovis",
        iata: "CVS",
        countryIata: "USCVS",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Carrizo Springs",
        city: "Carrizo Springs",
        iata: "CZT",
        countryIata: "USCZT",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Friday Harbor",
        city: "Friday Harbor",
        iata: "FRD",
        countryIata: "USFRD",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Akhiok SPB",
        city: "Akhiok",
        iata: "AKK",
        countryIata: "USAKK",
        country: "USA",
        countryCode: "US",
    },
    {
        airportName: "Andizhan",
        city: "Andizhan",
        iata: "AZN",
        countryIata: "UZAZN",
        country: "Uzbekistan",
        countryCode: "UZ",
    },
    {
        airportName: "Bukhara",
        city: "Bukhara",
        iata: "BHK",
        countryIata: "UZBHK",
        country: "Uzbekistan",
        countryCode: "UZ",
    },
    {
        airportName: "Fergana",
        city: "Fergana",
        iata: "FEG",
        countryIata: "UZFEG",
        country: "Uzbekistan",
        countryCode: "UZ",
    },
    {
        airportName: "Karshi",
        city: "Karshi",
        iata: "KSQ",
        countryIata: "UZKSQ",
        country: "Uzbekistan",
        countryCode: "UZ",
    },
    {
        airportName: "Nukus",
        city: "Nukus",
        iata: "NCU",
        countryIata: "UZNCU",
        country: "Uzbekistan",
        countryCode: "UZ",
    },
    {
        airportName: "Namangan",
        city: "Namangan",
        iata: "NMA",
        countryIata: "UZNMA",
        country: "Uzbekistan",
        countryCode: "UZ",
    },
    {
        airportName: "Samarkand",
        city: "Samarkand",
        iata: "SKD",
        countryIata: "UZSKD",
        country: "Uzbekistan",
        countryCode: "UZ",
    },
    {
        airportName: "Vostochny",
        city: "Tashkent",
        iata: "TAS",
        countryIata: "UZTAS",
        country: "Uzbekistan",
        countryCode: "UZ",
    },
    {
        airportName: "Termez",
        city: "Termez",
        iata: "TMJ",
        countryIata: "UZTMJ",
        country: "Uzbekistan",
        countryCode: "UZ",
    },
    {
        airportName: "Urgench",
        city: "Urgench",
        iata: "UGC",
        countryIata: "UZUGC",
        country: "Uzbekistan",
        countryCode: "UZ",
    },
    {
        airportName: "Aneityum",
        city: "Aneityum",
        iata: "AUY",
        countryIata: "VUAUY",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Aniwa",
        city: "Aniwa",
        iata: "AWD",
        countryIata: "VUAWD",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Craig Cove",
        city: "Craig Cove",
        iata: "CCV",
        countryIata: "VUCCV",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Dillons Bay",
        city: "Dillons Bay",
        iata: "DLY",
        countryIata: "VUDLY",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Emae",
        city: "Emae",
        iata: "EAE",
        countryIata: "VUEAE",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Epi",
        city: "Epi",
        iata: "EPI",
        countryIata: "VUEPI",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Futuna Airport",
        city: "Futuna Island",
        iata: "FTA",
        countryIata: "VUFTA",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Big Bay",
        city: "Big Bay",
        iata: "GBA",
        countryIata: "VUGBA",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Ipota",
        city: "Ipota",
        iata: "IPA",
        countryIata: "VUIPA",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Lamen Bay",
        city: "Lamen Bay",
        iata: "LNB",
        countryIata: "VULNB",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Lonorore",
        city: "Lonorore",
        iata: "LNE",
        countryIata: "VULNE",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Longana",
        city: "Longana",
        iata: "LOD",
        countryIata: "VULOD",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Lamap",
        city: "Lamap",
        iata: "LPM",
        countryIata: "VULPM",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Mota Lava",
        city: "Mota Lava",
        iata: "MTV",
        countryIata: "VUMTV",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Maewo",
        city: "Maewo",
        iata: "MWF",
        countryIata: "VUMWF",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Norsup",
        city: "Norsup",
        iata: "NUS",
        countryIata: "VUNUS",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Olpoi",
        city: "Olpoi",
        iata: "OLJ",
        countryIata: "VUOLJ",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Paama",
        city: "Paama",
        iata: "PBJ",
        countryIata: "VUPBJ",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Redcliffe",
        city: "Redcliffe",
        iata: "RCL",
        countryIata: "VURCL",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Sola",
        city: "Sola",
        iata: "SLH",
        countryIata: "VUSLH",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Pekoa",
        city: "Espiritu Santo",
        iata: "SON",
        countryIata: "VUSON",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Sara",
        city: "Sara",
        iata: "SSR",
        countryIata: "VUSSR",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "South West Bay",
        city: "South West Bay",
        iata: "SWJ",
        countryIata: "VUSWJ",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Tanna",
        city: "Tanna",
        iata: "TAH",
        countryIata: "VUTAH",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Tongoa",
        city: "Tongoa",
        iata: "TGH",
        countryIata: "VUTGH",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Torres Airstrip",
        city: "Torres",
        iata: "TOH",
        countryIata: "VUTOH",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Quine Hill",
        city: "Quine Hill",
        iata: "UIQ",
        countryIata: "VUUIQ",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Ulei",
        city: "Ulei",
        iata: "ULB",
        countryIata: "VUULB",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Bauerfield",
        city: "Port Vila",
        iata: "VLI",
        countryIata: "VUVLI",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Valesdir",
        city: "Valesdir",
        iata: "VLS",
        countryIata: "VUVLS",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Walaha airstrip",
        city: "Walaha",
        iata: "WLH",
        countryIata: "VUWLH",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Gaua",
        city: "Gaua",
        iata: "ZGU",
        countryIata: "VUZGU",
        country: "Vanuatu",
        countryCode: "VU",
    },
    {
        airportName: "Anzualegui",
        city: "Anaco",
        iata: "AAO",
        countryIata: "VEAAO",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Oswaldo Guevara Mujica Airport",
        city: "Acarigua",
        iata: "AGV",
        countryIata: "VEAGV",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Gen. J A Anzoategui",
        city: "Barcelona",
        iata: "BLA",
        countryIata: "VEBLA",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Barinas",
        city: "Barinas",
        iata: "BNS",
        countryIata: "VEBNS",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Barquisimeto",
        city: "Barquisimeto",
        iata: "BRM",
        countryIata: "VEBRM",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Canaima",
        city: "Canaima",
        iata: "CAJ",
        countryIata: "VECAJ",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Ciudad Bolivar",
        city: "Ciudad Bolivar",
        iata: "CBL",
        countryIata: "VECBL",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Oro Negro",
        city: "Cabimas",
        iata: "CBS",
        countryIata: "VECBS",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Simon Bolivar Airport official website",
        city: "Caracas",
        iata: "CCS",
        countryIata: "VECCS",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Ciudad Guayana",
        city: "Ciudad Guayana",
        iata: "CGU",
        countryIata: "VECGU",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Calabozo",
        city: "Calabozo",
        iata: "CLZ",
        countryIata: "VECLZ",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Cumana",
        city: "Cumana",
        iata: "CUM",
        countryIata: "VECUM",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Carupano",
        city: "Carupano",
        iata: "CUP",
        countryIata: "VECUP",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Casigua",
        city: "Casigua",
        iata: "CUV",
        countryIata: "VECUV",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Caicara De Oro",
        city: "Caicara De Oro",
        iata: "CXA",
        countryIata: "VECXA",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Coro",
        city: "Coro",
        iata: "CZE",
        countryIata: "VECZE",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "El Tigre",
        city: "El Tigre",
        iata: "ELX",
        countryIata: "VEELX",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "El Dorado",
        city: "El Dorado",
        iata: "EOR",
        countryIata: "VEEOR",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Elorza",
        city: "Elorza",
        iata: "EOZ",
        countryIata: "VEEOZ",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Vare Maria",
        city: "Guasdualito",
        iata: "GDO",
        countryIata: "VEGDO",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Guiria",
        city: "Guiria",
        iata: "GUI",
        countryIata: "VEGUI",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Guanare",
        city: "Guanare",
        iata: "GUQ",
        countryIata: "VEGUQ",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Icabaru",
        city: "Icabaru",
        iata: "ICA",
        countryIata: "VEICA",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Kavanayen",
        city: "Kavanayen",
        iata: "KAV",
        countryIata: "VEKAV",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Kamarata",
        city: "Kamarata",
        iata: "KTV",
        countryIata: "VEKTV",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "La Guaira",
        city: "La Guaira",
        iata: "LAG",
        countryIata: "VELAG",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "La Fria",
        city: "La Fria",
        iata: "LFR",
        countryIata: "VELFR",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Lagunillas",
        city: "Lagunillas",
        iata: "LGY",
        countryIata: "VELGY",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Pijiguaos",
        city: "Pijiguaos",
        iata: "LPJ",
        countryIata: "VELPJ",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Los Roques",
        city: "Los Roques",
        iata: "LRV",
        countryIata: "VELRV",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Josefa Camejo",
        city: "Las Piedras",
        iata: "LSP",
        countryIata: "VELSP",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "La Chinita",
        city: "Maracaibo",
        iata: "MAR",
        countryIata: "VEMAR",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "A Carnevalli",
        city: "Merida",
        iata: "MRD",
        countryIata: "VEMRD",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Quiriquire",
        city: "Maturin",
        iata: "MUN",
        countryIata: "VEMUN",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Maracay",
        city: "Maracay",
        iata: "MYC",
        countryIata: "VEMYC",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Puerto Cabello",
        city: "Puerto Cabello",
        iata: "PBL",
        countryIata: "VEPBL",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Pedernales",
        city: "Pedernales",
        iata: "PDZ",
        countryIata: "VEPDZ",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "DelCaribe Gen S Marino",
        city: "Porlamar",
        iata: "PMV",
        countryIata: "VEPMV",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Peraitepuy",
        city: "Peraitepuy",
        iata: "PPH",
        countryIata: "VEPPH",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Puerto Paez",
        city: "Puerto Paez",
        iata: "PPZ",
        countryIata: "VEPPZ",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Palmarito",
        city: "Palmarito",
        iata: "PTM",
        countryIata: "VEPTM",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Puerto Ayacucho",
        city: "Puerto Ayacucho",
        iata: "PYH",
        countryIata: "VEPYH",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Puerto Ordaz",
        city: "Puerto Ordaz",
        iata: "PZO",
        countryIata: "VEPZO",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Santa Bárbara de Barinas Ap",
        city: "Santa Barbara de Barinas",
        iata: "SBB",
        countryIata: "VESBB",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "San Cristobal",
        city: "San Cristobal",
        iata: "SCI",
        countryIata: "VESCI",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Las Flecheras",
        city: "San Fernando De Apure",
        iata: "SFD",
        countryIata: "VESFD",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "San Felix",
        city: "San Felix",
        iata: "SFX",
        countryIata: "VESFX",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "San Felipe",
        city: "San Felipe",
        iata: "SNF",
        countryIata: "VESNF",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Santa Elena",
        city: "Santa Elena",
        iata: "SNV",
        countryIata: "VESNV",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "El Tigre",
        city: "San Tome",
        iata: "SOM",
        countryIata: "VESOM",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "L Delicias",
        city: "Santa Barbara Ed",
        iata: "STB",
        countryIata: "VESTB",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Mayo Guerrero",
        city: "Santo Domingo",
        iata: "STD",
        countryIata: "VESTD",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "San Salvador De",
        city: "San Salvador De",
        iata: "SVV",
        countryIata: "VESVV",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "San Antonio",
        city: "San Antonio",
        iata: "SVZ",
        countryIata: "VESVZ",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Tumeremo",
        city: "Tumeremo",
        iata: "TMO",
        countryIata: "VETMO",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Tucupita",
        city: "Tucupita",
        iata: "TUV",
        countryIata: "VETUV",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Uriman",
        city: "Uriman",
        iata: "URM",
        countryIata: "VEURM",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Carora",
        city: "Carora",
        iata: "VCR",
        countryIata: "VEVCR",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Valle De Pascua",
        city: "Valle De Pascua",
        iata: "VDP",
        countryIata: "VEVDP",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "El Vigia",
        city: "El Vigia",
        iata: "VIG",
        countryIata: "VEVIG",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Valencia",
        city: "Valencia",
        iata: "VLN",
        countryIata: "VEVLN",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Carvajal",
        city: "Valera",
        iata: "VLV",
        countryIata: "VEVLV",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Wonken",
        city: "Wonken",
        iata: "WOK",
        countryIata: "VEWOK",
        country: "Venezuela (Bolivarian Republic of)",
        countryCode: "VE",
    },
    {
        airportName: "Phung-Duc",
        city: "Banmethuot",
        iata: "BMV",
        countryIata: "VNBMV",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Ca Mau",
        city: "Ca Mau",
        iata: "CAH",
        countryIata: "VNCAH",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Cape St Jacques",
        city: "Cape St Jacques",
        iata: "CSJ",
        countryIata: "VNCSJ",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Da Nang",
        city: "Da Nang",
        iata: "DAD",
        countryIata: "VNDAD",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Gialam",
        city: "Dien Bien Phu",
        iata: "DIN",
        countryIata: "VNDIN",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Lienkhang",
        city: "Dalat",
        iata: "DLI",
        countryIata: "VNDLI",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Noibai",
        city: "Hanoi",
        iata: "HAN",
        countryIata: "VNHAN",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Phu-bon",
        city: "Phu-bon",
        iata: "HBN",
        countryIata: "VNHBN",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Nhon Co",
        city: "Quanduc",
        iata: "HOO",
        countryIata: "VNHOO",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Catbi",
        city: "Haiphong",
        iata: "HPH",
        countryIata: "VNHPH",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Phu Bai",
        city: "Hue",
        iata: "HUI",
        countryIata: "VNHUI",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Kontum",
        city: "Kontum",
        iata: "KON",
        countryIata: "VNKON",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Nha Trang",
        city: "Nha Trang",
        iata: "NHA",
        countryIata: "VNNHA",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Ho Chi Minh City",
        city: "Ho Chi Minh City",
        iata: "SGN",
        countryIata: "VNSGN",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Soc Trang",
        city: "Soc Trang",
        iata: "SOA",
        countryIata: "VNSOA",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Na-San",
        city: "Son-La",
        iata: "SQH",
        countryIata: "VNSQH",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Tuy Hoa",
        city: "Tuy Hoa",
        iata: "TBB",
        countryIata: "VNTBB",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Tamky",
        city: "Tamky",
        iata: "TMK",
        countryIata: "VNTMK",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Can Tho",
        city: "Can Tho",
        iata: "VCA",
        countryIata: "VNVCA",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Vinh City",
        city: "Vinh City",
        iata: "VII",
        countryIata: "VNVII",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Rach Gia",
        city: "Rach Gia",
        iata: "VKG",
        countryIata: "VNVKG",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Phuoclong",
        city: "Phuoclong",
        iata: "VSO",
        countryIata: "VNVSO",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Vung Tau",
        city: "Vung Tau",
        iata: "VTG",
        countryIata: "VNVTG",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Long Xuyen",
        city: "Long Xuyen",
        iata: "XLO",
        countryIata: "VNXLO",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Quang Ngai",
        city: "Quang Ngai",
        iata: "XNG",
        countryIata: "VNXNG",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Vinh Long",
        city: "Vinh Long",
        iata: "XVL",
        countryIata: "VNXVL",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Phan Rang AB",
        city: "Phan Rang",
        iata: "PHA",
        countryIata: "VNPHA",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Phan Thiet",
        city: "Phan Thiet",
        iata: "PHH",
        countryIata: "VNPHH",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Phu Vinh",
        city: "Phu Vinh",
        iata: "PHU",
        countryIata: "VNPHU",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Phu Quoc International Airport",
        city: "Phu Quoc",
        iata: "PQC",
        countryIata: "VNPQC",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Pleiku",
        city: "Pleiku",
        iata: "PXU",
        countryIata: "VNPXU",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Qui Nhon",
        city: "Qui Nhon",
        iata: "UIH",
        countryIata: "VNUIH",
        country: "Vietnam",
        countryCode: "VN",
    },
    {
        airportName: "Futuna Island",
        city: "Futuna Island",
        iata: "FUT",
        countryIata: "WFFUT",
        country: "Wallis and Futuna",
        countryCode: "WF",
    },
    {
        airportName: "Al Ghaydah",
        city: "Al Ghaydah",
        iata: "AAY",
        countryIata: "YEAAY",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Aden International Airport",
        city: "Aden",
        iata: "ADE",
        countryIata: "YEADE",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Aljouf",
        city: "Aljouf",
        iata: "AJO",
        countryIata: "YEAJO",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Ataq",
        city: "Ataq",
        iata: "AXK",
        countryIata: "YEAXK",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Beihan",
        city: "Beihan",
        iata: "BHN",
        countryIata: "YEBHN",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Albuq",
        city: "Albuq",
        iata: "BUK",
        countryIata: "YEBUK",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Beidah",
        city: "Beidah",
        iata: "BYD",
        countryIata: "YEBYD",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Dathina",
        city: "Dathina",
        iata: "DAH",
        countryIata: "YEDAH",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Dhala",
        city: "Dhala",
        iata: "DHL",
        countryIata: "YEDHL",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Dhamar",
        city: "Dhamar",
        iata: "DMR",
        countryIata: "YEDMR",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Abbse",
        city: "Abbse",
        iata: "EAB",
        countryIata: "YEEAB",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Seiyun",
        city: "Seiyun",
        iata: "GXF",
        countryIata: "YEGXF",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Hodeidah Airport",
        city: "Hodeidah",
        iata: "HOD",
        countryIata: "YEHOD",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Qishn",
        city: "Qishn",
        iata: "IHN",
        countryIata: "YEIHN",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Kamaran Island",
        city: "Kamaran Island",
        iata: "KAM",
        countryIata: "YEKAM",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Lodar",
        city: "Lodar",
        iata: "LDR",
        countryIata: "YELDR",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Mayfa'ah",
        city: "Mayfa'ah",
        iata: "MFY",
        countryIata: "YEMFY",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Mukalla",
        city: "Mukalla",
        iata: "MKX",
        countryIata: "YEMKX",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Mareb",
        city: "Mareb",
        iata: "MYN",
        countryIata: "YEMYN",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Riyan Mukalla",
        city: "Riyan Mukalla",
        iata: "RIY",
        countryIata: "YERIY",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Raudha",
        city: "Raudha",
        iata: "RXA",
        countryIata: "YERXA",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Sana'a International",
        city: "Sana'a",
        iata: "SAH",
        countryIata: "YESAH",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Socotra",
        city: "Socotra",
        iata: "SCT",
        countryIata: "YESCT",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Sadah",
        city: "Sadah",
        iata: "SYE",
        countryIata: "YESYE",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Taiz International Airport (Al Janad)",
        city: "Taiz",
        iata: "TAI",
        countryIata: "YETAI",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Mukeiras",
        city: "Mukeiras",
        iata: "UKR",
        countryIata: "YEUKR",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Wadi Ain",
        city: "Wadi Ain",
        iata: "WDA",
        countryIata: "YEWDA",
        country: "Yemen",
        countryCode: "YE",
    },
    {
        airportName: "Zambezi",
        city: "Zambezi",
        iata: "BBZ",
        countryIata: "ZMBBZ",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Chingola",
        city: "Chingola",
        iata: "CGJ",
        countryIata: "ZMCGJ",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Chipata",
        city: "Chipata",
        iata: "CIP",
        countryIata: "ZMCIP",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Kasama",
        city: "Kasama",
        iata: "KAA",
        countryIata: "ZMKAA",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Southdowns",
        city: "Kitwe",
        iata: "KIW",
        countryIata: "ZMKIW",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Kalabo",
        city: "Kalabo",
        iata: "KLB",
        countryIata: "ZMKLB",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Kaoma",
        city: "Kaoma",
        iata: "KMZ",
        countryIata: "ZMKMZ",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Lusaka International Airport",
        city: "Lusaka",
        iata: "LUN",
        countryIata: "ZMLUN",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Livingstone",
        city: "Livingstone",
        iata: "LVI",
        countryIata: "ZMLVI",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Lukulu",
        city: "Lukulu",
        iata: "LXU",
        countryIata: "ZMLXU",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Mfuwe",
        city: "Mfuwe",
        iata: "MFU",
        countryIata: "ZMMFU",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Mbala",
        city: "Mbala",
        iata: "MMQ",
        countryIata: "ZMMMQ",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Mongu",
        city: "Mongu",
        iata: "MNR",
        countryIata: "ZMMNR",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Mansa",
        city: "Mansa",
        iata: "MNS",
        countryIata: "ZMMNS",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Ndola",
        city: "Ndola",
        iata: "NLA",
        countryIata: "ZMNLA",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Sesheke",
        city: "Sesheke",
        iata: "SJQ",
        countryIata: "ZMSJQ",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Solwezi",
        city: "Solwezi",
        iata: "SLI",
        countryIata: "ZMSLI",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Senanga",
        city: "Senanga",
        iata: "SXG",
        countryIata: "ZMSXG",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Ngoma",
        city: "Ngoma",
        iata: "ZGM",
        countryIata: "ZMZGM",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Kasaba Bay",
        city: "Kasaba Bay",
        iata: "ZKB",
        countryIata: "ZMZKB",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Kasompe",
        city: "Kasompe",
        iata: "ZKP",
        countryIata: "ZMZKP",
        country: "Zambia",
        countryCode: "ZM",
    },
    {
        airportName: "Buffalo Range",
        city: "Buffalo Range",
        iata: "BFO",
        countryIata: "ZWBFO",
        country: "Zimbabwe",
        countryCode: "ZW",
    },
    {
        airportName: "Bulawayo",
        city: "Bulawayo",
        iata: "BUQ",
        countryIata: "ZWBUQ",
        country: "Zimbabwe",
        countryCode: "ZW",
    },
    {
        airportName: "Bumi Hills",
        city: "Bumi Hills",
        iata: "BZH",
        countryIata: "ZWBZH",
        country: "Zimbabwe",
        countryCode: "ZW",
    },
    {
        airportName: "Chipinge",
        city: "Chipinge",
        iata: "CHJ",
        countryIata: "ZWCHJ",
        country: "Zimbabwe",
        countryCode: "ZW",
    },
    {
        airportName: "Gweru",
        city: "Gweru",
        iata: "GWE",
        countryIata: "ZWGWE",
        country: "Zimbabwe",
        countryCode: "ZW",
    },
    {
        airportName: "Harare",
        city: "Harare",
        iata: "HRE",
        countryIata: "ZWHRE",
        country: "Zimbabwe",
        countryCode: "ZW",
    },
    {
        airportName: "Hwange Nat Park",
        city: "Hwange Nat Park",
        iata: "HWN",
        countryIata: "ZWHWN",
        country: "Zimbabwe",
        countryCode: "ZW",
    },
    {
        airportName: "Kariba",
        city: "Kariba",
        iata: "KAB",
        countryIata: "ZWKAB",
        country: "Zimbabwe",
        countryCode: "ZW",
    },
    {
        airportName: "Mahenye",
        city: "Mahenye",
        iata: "MJW",
        countryIata: "ZWMJW",
        country: "Zimbabwe",
        countryCode: "ZW",
    },
    {
        airportName: "Masvingo",
        city: "Masvingo",
        iata: "MVZ",
        countryIata: "ZWMVZ",
        country: "Zimbabwe",
        countryCode: "ZW",
    },
    {
        airportName: "Mutare",
        city: "Mutare",
        iata: "UTA",
        countryIata: "ZWUTA",
        country: "Zimbabwe",
        countryCode: "ZW",
    },
    {
        airportName: "Victoria Falls",
        city: "Victoria Falls",
        iata: "VFA",
        countryIata: "ZWVFA",
        country: "Zimbabwe",
        countryCode: "ZW",
    },
    {
        airportName: "Hwange",
        city: "Hwange",
        iata: "WKI",
        countryIata: "ZWWKI",
        country: "Zimbabwe",
        countryCode: "ZW",
    },
];
