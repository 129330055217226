import { CargoReadyTime } from "../../../models/rfq/rfq-types";

export const HeaderTexts = {
    mainHeader: "Where should we send your quote?",
    subHeader: "Already a user?",
    backToDetails: "Don’t have an account?",
    signUp: "Don’t have an account?",
};
export const ButtonText = "Get your quote";
export const radioOptions = [
    {
        label: "Now!",
        value: CargoReadyTime.Now,
    },
    {
        label: "In 2 weeks",
        value: CargoReadyTime.TwoWeeks,
    },
    {
        label: "Later date",
        value: CargoReadyTime.Later,
    },
];
export const checkboxOptionsSecond = [
    {
        label: "Sign me up for the latest updates and exclusive content!",
        value: "isUpdateContent",
    },
];

export const validateEmail = (email: string) => {
    const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if (!email) {
        return "Email is required";
    } else if (!regex.test(email)) {
        return "Invalid email address";
    } else {
        return true;
    }
};
