import { Fragment, useEffect, useMemo, useState } from "react";
import { News } from "../../../models/blocks/INewsBlock";
import "./NewsBlock.scss";
import { useGetNewsQuery } from "../../../store/api-slices/newsSlice";
import { MobileStepper, Button } from "@mui/material";
import { useScreenWidthType } from "../../../helpers/custom-hooks";
import { ScreenWidthType } from "../../../assets/data/ui";
import { ArrowRightFullIcon } from "../../../assets/icons";
import TextButton from "../../buttons/text-button/TextButton";
import SkeletonLoaderItem from "../../ui-decorations/SkeletonLoader/SkeletonLoaderItem";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";

const allNewsUrl = "https://www.unicargo.com/blog/";

const renderLoader = (
    <div className="LoaderContainer">
        <div className="TitleAndLinkLoader">
            <SkeletonLoaderItem className="TitleLoader" width="40%" />
            <SkeletonLoaderItem className="LinkLoader" width="20%" />
        </div>
        <SkeletonLoaderItem className="ImageLoader" height="50%" customStyle={{ borderRadius: "4px", flexGrow: 1 }} />
        <div className="ArticleTitleDescriptionsLoader">
            <SkeletonLoaderItem className="ArticleTitleLoader" />
            <div className="DescriptionLoadersContainer">
                <SkeletonLoaderItem className="ArticleDescriptionLoader1" height="0.75rem" />
                <SkeletonLoaderItem className="ArticleDescriptionLoader2" height="0.75rem" width="80%" />
            </div>
        </div>
        <SkeletonLoaderItem className="PaginationLoader" width="40%" />
    </div>
);

interface NewsBlockProps {
    onFinishedLoading: () => void;
    shouldShowLoader: boolean;
}

export const NewsBlock = ({ onFinishedLoading, shouldShowLoader }: NewsBlockProps) => {
    const screenWidthType = useScreenWidthType();
    const isMobile = screenWidthType === ScreenWidthType.MOBILE;
    const { data, isError, isFetching, isLoading, isSuccess } = useGetNewsQuery();
    const [totalAmountOfNews, setTotalAmountOfNews] = useState(0);
    const [currentNewsArticleIndex, setCurrentNewsArticleIndex] = useState(0);

    useEffect(() => {
        if (!isFetching && !isLoading) {
            onFinishedLoading();
        }
    }, [isLoading, isFetching]);

    useEffect(() => {
        if (isSuccess && data) {
            setTotalAmountOfNews(data.length);
        }
    }, [data, isSuccess]);

    const openLink = (urlStr: string) => {
        window.open(urlStr, "_blank", "noopener,noreferrer");
        if (urlStr === allNewsUrl) {
            tryTrackEvent("[Overview page]: 'See all' (articles, in the news block) clicked");
        } else {
            tryTrackEvent("[Overview page]: Article link clicked (in the news block)");
        }
    };
    const handleNext = () => {
        setCurrentNewsArticleIndex((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setCurrentNewsArticleIndex((prevActiveStep) => prevActiveStep - 1);
    };

    const newsArticlesBodies = useMemo(() => {
        if (!data) {
            return [];
        }
        return data?.map((newsObj: News) => {
            return (
                <div
                    key={currentNewsArticleIndex}
                    className="ImageAndDescContainer"
                    onClick={() => openLink(newsObj.link)}
                >
                    <img
                        className="ArticleImage"
                        src={newsObj.yoast_head_json.og_image[0].url}
                        alt={"NewsArticleImage"}
                    />
                    <div className="ArticleTitle">{newsObj.yoast_head_json.og_title}</div>
                    {!isMobile && <div className="ArticleDescription">{newsObj.yoast_head_json.description}</div>}
                </div>
            );
        });
    }, [data]);

    return (
        <div className={`NewsBlock ${shouldShowLoader && "Loading"}`}>
            {shouldShowLoader ? (
                renderLoader
            ) : isError ? (
                <div>Can't load the articles right now, Please try again later!</div>
            ) : (
                isSuccess &&
                data &&
                data.length > 0 && (
                    <Fragment>
                        <section className="NewsArticleHeader">
                            <div className="CardTitle">Industry News & Insights</div>
                            <div className="SeeAllContainer" onClick={() => openLink(allNewsUrl)}>
                                <TextButton className="SeeAllText" withArrowRight>
                                    {`See all (${data.length})`}{" "}
                                </TextButton>
                            </div>
                        </section>
                        <section className="NewsArticleBody">
                            <Fragment>{newsArticlesBodies[currentNewsArticleIndex]}</Fragment>
                        </section>
                        <MobileStepper
                            className="ImagePaginationStepper"
                            steps={totalAmountOfNews}
                            position="static"
                            activeStep={currentNewsArticleIndex}
                            sx={{ justifyContent: "center", width: "inherit" }}
                            backButton={
                                !isMobile && (
                                    <Button size="small" onClick={handleBack} disabled={currentNewsArticleIndex === 0}>
                                        <ArrowRightFullIcon className="ImagePaginationArrow Previous" />
                                    </Button>
                                )
                            }
                            nextButton={
                                !isMobile && (
                                    <Button
                                        size="small"
                                        onClick={handleNext}
                                        disabled={currentNewsArticleIndex === totalAmountOfNews - 1}
                                        className="arrowNext"
                                    >
                                        <ArrowRightFullIcon className="ImagePaginationArrow Next " />
                                    </Button>
                                )
                            }
                        />
                        {isMobile && (
                            <div className="MobilePaginationContainer">
                                <Button size="small" onClick={handleBack} disabled={currentNewsArticleIndex === 0}>
                                    <ArrowRightFullIcon className="ImagePaginationArrow Previous" />
                                </Button>
                                <Button
                                    size="small"
                                    onClick={handleNext}
                                    disabled={currentNewsArticleIndex === totalAmountOfNews - 1}
                                >
                                    <ArrowRightFullIcon className="ImagePaginationArrow Next" />
                                </Button>
                            </div>
                        )}
                    </Fragment>
                )
            )}
        </div>
    );
};

export default NewsBlock;
