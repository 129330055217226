import { useEffect } from "react";
import { FormInputTextField } from "../form-elements/text-field/formInputTextField";
import { useStyles } from "./useStyles";
import { Box } from "@mui/system";
import { ISecondPhaseProps, heightUnits, weightUnits } from "./SecondPhaseUtils";
import TextInputWithUnitDropdown from "../form-elements/Text-input-with-unit-dropdown/TextInputWithUnitDropdown";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";
import { UiSchemeV2 } from "../../../assets/data/ui";

const SecondPhasePerUnit = ({ control, setFormValue }: ISecondPhaseProps) => {
    useEffect(() => {
        tryTrackEvent("[Rfq - Step 2]: 'Calculate Per Unit' option selected");
    }, []);
    const classes = useStyles();
    return (
        <Box className={classes.wrapperPerUnit}>
            <Box className={classes.wrapper}>
                <FormInputTextField
                    type="number"
                    size="md"
                    required={true}
                    name="quantity"
                    control={control}
                    label={"Number of units"}
                />
                <TextInputWithUnitDropdown
                    name="weight"
                    unitName="weightUnit"
                    control={control}
                    required={true}
                    label={"Weight"}
                    options={weightUnits}
                    setFormValue={setFormValue}
                />
            </Box>
            <Box className={classes.wrapperPerUnitSecondSection}>
                <Box className={classes.wrapperPerUnitBoxes}>
                    <FormInputTextField
                        type="number"
                        size="md"
                        required={true}
                        name="boxPerUnitLength"
                        control={control}
                        label={"Length"}
                    />
                    <FormInputTextField
                        type="number"
                        size="md"
                        required={true}
                        name="boxPerUnitWidth"
                        control={control}
                        label={"Width"}
                        borderLeft={`1px solid ${UiSchemeV2.colors.greys.background}`}
                        borderRight={`1px solid ${UiSchemeV2.colors.greys.background}`}
                    />
                </Box>
                <TextInputWithUnitDropdown
                    name="boxPerUnitHeight"
                    unitName="heightUnit"
                    control={control}
                    required={true}
                    label={"Height"}
                    options={heightUnits}
                    // borderRadius="16px"
                    setFormValue={setFormValue}
                />
            </Box>
        </Box>
    );
};

export default SecondPhasePerUnit;
