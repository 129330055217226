import { Box, Tooltip } from "@mui/material";
import { IExclamtionInfoProps, toolTipStyle } from "./ExclamationInfoUtil";
import { ExclamationMarkIcon } from "../../../assets/icons";
import { useStyles } from "./useStyles";

const ExclamtionInfo = ({ text }: IExclamtionInfoProps) => {
    const classes = useStyles();

    return (
        <Tooltip
            placement="top"
            arrow
            describeChild
            enterTouchDelay={0}
            PopperProps={{
                sx: toolTipStyle,
            }}
            title={<Box>{text}</Box>}
        >
            <Box>
                <Box className={classes.infoIcon}>
                    <ExclamationMarkIcon />
                </Box>
            </Box>
        </Tooltip>
    );
};

export default ExclamtionInfo;
