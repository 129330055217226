import { FC } from "react";
import styles from "./OutcomeModalView.module.scss";
import { BigCheckMarkIcon, BigCrossIcon } from "../../../assets/icons";

interface IOutcomeModalViewProps {
    outcome: "success" | "error";
}

const OutcomeModalView: FC<IOutcomeModalViewProps> = ({ outcome }) => {
    const outcomeTitle = outcome === "success" ? "Thank You !" : "Error :(";
    const outcomeSubTitle = outcome === "success" ? "" : "Please contact us for support!";
    return (
        <div className={styles.OutcomeModalView}>
            <h1 className={`${styles.OutcomeTitle} ${styles[outcome]} Bold`}>{outcomeTitle}</h1>
            <h2 className={`${styles.OutcomeSubtitle} ${styles[outcome]} Bold`}>{outcomeSubTitle}</h2>
            {outcome === "success" ? (
                <BigCheckMarkIcon className={styles.OutcomeIcon} />
            ) : (
                <BigCrossIcon className={styles.OutcomeIcon} />
            )}
        </div>
    );
};

export default OutcomeModalView;
