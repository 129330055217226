import "./ContainedButton.scss";
import { MuiTheme } from "../../../helpers";
import { IButton } from "../../../models";
import BaseButton from "../base-button/BaseButton";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../assets/data/ui";

const useStyles = makeStyles(() => ({
    ContainedButton: {
        "&:hover": {
            backgroundColor: COLORS.success,
        },
    },
}));

const ContainedButton = ({ className, ...rest }: IButton) => {
    const classes = useStyles();

    return (
        <MuiTheme>
            <BaseButton
                {...rest}
                className={`${classes.ContainedButton} ${className ? className : ""}`}
                variant={"contained"}
            />
        </MuiTheme>
    );
};

export default ContainedButton;
