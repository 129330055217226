import { makeStyles } from "@mui/styles";
import { COLORS, UiSchemeV2 } from "../../../assets/data/ui";
import { theme } from "../../../assets/theme/theme";

const useStyles = makeStyles({
    root: {
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
        
    },
    // Stage / status label
    mobileCloseButtonContainer: {
        alignSelf: "flex-end",
        padding: "8px",
        marginTop: "1.5rem",
    },
    stageChip: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50px",
        padding: "2px 12px",
        gap: "10px",
        color: COLORS.white,
        backgroundColor: `${UiSchemeV2.colors.primaryDark}`,
        fontSize: "14px",
    },
    stageColor: {
        borderRadius: "55px",
        width: "6px",
        height: "6px",
    },
    closeButton: {
        padding: "0",
        margin: "0",
        marginRight: "1rem",
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    // Head container + content
    headContainer: {
        display: "flex",
        flexDirection: "column",
    },
    headContent: {
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        gap: "1rem",
    },
    headerPillContainer: {
        height: "121px",

        [theme.breakpoints.down("lg")]: {
            height: "147px",
            padding: "21px 2rem",
        },
        [theme.breakpoints.down("sm")]: {
            height: "200px",
            padding: 0,
        },
    },
    primaryDetailsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    primaryDetails: {
        display: "flex",
        gap: "0.5rem",
        flexDirection: "column",
    },
    shipmentNumberContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "start",
        gap: "2rem",
    },
    shipmentNumberWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "0.4rem",
    },
    shipmentNumberWithIconWrapper: {
        display: "flex",
        gap: "0.2rem",
    },
    shipmentNumber: {
        margin: 0,
        fontSize: "20px",
    },

    shipmentReferencesContainer: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
            alignItems: "flex-start"
        }, 
    },
    purchaseOrderCode: {
        display: "flex",
        // alignItems: "center",
    },
    quoteNumber: {
        display: "flex",
        alignItems: "center",
    },
    divider: {
        borderLeft: `1px solid ${COLORS.light}`,
        paddingRight: "0.5rem",
        height: "20px",
        
    },
   
    editableNameField: {
        maxWidth: "130px",
        borderRadius: "6px",
        "& fieldset": {
            border: "none",
        },
    },

    editiableName: {
        height: "40px",
        paddingInlineStart: "0.5rem",
        border: "1px solid transparent",
        "& input": {
            padding: "0",
        },
    },
    contactUsButton: {
        borderRadius: "39px",
        border: `1px solid ${UiSchemeV2.colors.primaryDark}`,
        color: `${UiSchemeV2.colors.primaryDark}`,
    },
    referenceItemWrapper: {
        display: "flex",
        flexWrap: "wrap",
    },
    referenceTitleItem: {
        fontSize: "16px",
    },
    referenceContentItem: {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        paddingRight: "0.5rem",
    },

    shippingAndPackageDetails: {
        display: "flex",
        gap: "2rem",
        [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
            gap: "1rem",
        },
    },

    greyBox: {
        background:`${UiSchemeV2.colors.greys.background}`,
        width: "fit-content",
        padding: "20px",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
    },

    detailsHeader: {
        color: `${UiSchemeV2.colors.primaryDark}`,
    },
    shippingDetailsItemsWrapper: {
        display: "flex",
        flexWrap: "wrap",
    },
    detailsTextContainer: {
        display: "flex",
        
        fontSize: "14px",
        color: `${UiSchemeV2.colors.greys.grey300}`,
        // gap: "0.5rem",
        // margin: "0 15px",
    },
    detailsText: {
        paddingRight: "0.5rem",
    },
    shipmentFromToDestWrapper: {
        gap: "2px",
    },
    rightArrowIcon: {
        color: `${UiSchemeV2.colors.primaryDark}`,
        marginBottom: "-3px",
        marginRight: "8px",
        marginLeft: "8px",
    },
    packagesDetailsRow: {
        display: "flex",
        flexWrap: "wrap",
    },
    cargoStatusContainer: {
        display: "flex",
        // gap: "1rem",
    },

    CargoStatusItem: {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        paddingRight: "0.5rem",
    },
});

export default useStyles;
