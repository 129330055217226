import useStyles from "./useStyles";
import { UnicargoLogoSvg, EmptyStateGrayLines } from "../../../assets/icons";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";
import { useNavigate } from "react-router-dom";

export const ShipmentsDashboardEmptyState = () => {
    const navigate = useNavigate();
    const classes = useStyles();

    const handleGetQuote = () => {
        tryTrackEvent("[Overview Page]: 'Get a quote' button clicked");
        navigate("/rfq");
    };

    return (
        <section className={classes.EmptyActiveShipmentsContainer}>
            <div className={classes.EmptyActiveShipmentsContent}>
                <EmptyStateGrayLines className={classes.EmptyStateGrayLinesLeft} />
                <div className={classes.EmptyActiveShipmentsCenter}>
                    <UnicargoLogoSvg />
                    <div className={classes.NoActiveShipmentsMessage}>No active shipments</div>
                    <button className={classes.GetQuoteButton} onClick={handleGetQuote}>
                        Get a quote
                    </button>
                </div>
                <EmptyStateGrayLines className={classes.EmptyStateGrayLinesRight} />
            </div>
        </section>
    );
};

export default ShipmentsDashboardEmptyState;
