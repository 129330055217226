import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormInputDropdown } from "../../../components/RFQ/form-elements/dropdown/formInputDropdown";
import useStyles from "./useStyle";
import { ContainedButton } from "../../../components";
import { useEffect, useLayoutEffect, useState } from "react";
import { HeaderTexts, shipmentTypesHeaderTexts } from "./firsPhaseFormUtils";
import { DestinationDropdownOptions, OriginDropdownOptions } from "./firsPhaseFormUtils";
import {
    DropdownItemtype,
    IFirstPhaseFormInput,
    LocationType,
    RfqStep,
    ShipmentMethod,
} from "../../../models/rfq/rfq-types";
import { useAppSelector, useAppDispatch } from "../../../store";
import { updateFirstPhase, updateShipmentMethod } from "../../../store/reducers/rfqSlice.store";
import { FormInputAutocomplete } from "../../../components/RFQ/form-elements/autocomplete/autocompleteInput";
import { Chip } from "../../../components";
import { AirplaneIcon, TruckIcon, ShipIcon, RfqArrow } from "../../../assets/icons";
import { convertToOBj } from "../../../components/RFQ/form-elements/autocomplete/autocompleteUtils";
import { COLORS, ScreenWidthType } from "../../../assets/data/ui";
import useDidMountEffect from "../hooks/useDidMountEffect";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";
import { useScreenWidthType } from "../../../helpers/custom-hooks";

const FirstPhaseForm: React.FC<{
    updateFormState: React.Dispatch<React.SetStateAction<RfqStep>>;
}> = ({ updateFormState }) => {
    const dispatch = useAppDispatch();
    const { firstPhaseForm } = useAppSelector((state) => state.rootReducer.rfq);
    const [currentOriginDropdownOptions, setCurrentOriginDropdownOptions] =
        useState<DropdownItemtype[]>(OriginDropdownOptions);
    const [destinationDropDownOptions, setDestintaionDropdownOptions] =
        useState<DropdownItemtype[]>(DestinationDropdownOptions);
    const classes = useStyles();
    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;
    const inputWidth = isMobile ? "100%" : "184px";
    const methods = useForm<IFirstPhaseFormInput>({
        defaultValues: { ...firstPhaseForm },
    });
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { isValid },
    } = methods;
    const watchForm = watch();

    const [airChip, setAirChip] = useState<boolean>(firstPhaseForm.shippingMethod.air);
    const [landChip, setLandChip] = useState<boolean>(firstPhaseForm.shippingMethod.land);
    const [oceanChip, setOceanChip] = useState<boolean>(firstPhaseForm.shippingMethod.ocean);

    const checkIfChipWasSelected = (): boolean => airChip || landChip || oceanChip;

    //only one chip selected
    const checkIfOnlyAirSelected = (): boolean => airChip && !(oceanChip || landChip);
    const checkIfOnlyOceanSelected = (): boolean => oceanChip && !(landChip || airChip);
    const checkIfOnlyLandSelected = (): boolean => landChip && !(oceanChip || airChip);

    //only two chips selected
    const checkIfOceanAndLandSelected = (): boolean => oceanChip && landChip && !airChip;
    const checkIfLandAndAirSelected = (): boolean => landChip && airChip && !oceanChip;

    const filterOptions = (options: DropdownItemtype[], excludeValues: LocationType[]) => {
        return options.filter((op: DropdownItemtype) => !excludeValues.includes(op.value as LocationType));
    };

    useDidMountEffect(() => {
        let originExcludeValues: LocationType[] = [];
        let destinationExcludeValues: LocationType[] = [];
        setValue("fromType", "", { shouldValidate: true });
        setValue("destinationType", "", { shouldValidate: true });
        if (checkIfOnlyOceanSelected() || checkIfOceanAndLandSelected()) {
            originExcludeValues = [LocationType.Airport, LocationType.AirportAndPort];
            destinationExcludeValues = [LocationType.Airport, LocationType.AirportAndPort];
        } else if (checkIfOnlyAirSelected() || checkIfLandAndAirSelected()) {
            originExcludeValues = [LocationType.Port, LocationType.AirportAndPort];
            destinationExcludeValues = [LocationType.Port, LocationType.AirportAndPort];
        } else if (checkIfOnlyLandSelected()) {
            originExcludeValues = [LocationType.AirportAndPort];
            destinationExcludeValues = [LocationType.AirportAndPort];
        } else {
            originExcludeValues = [LocationType.Airport, LocationType.Port];
            destinationExcludeValues = [LocationType.Airport, LocationType.Port];
        }

        setCurrentOriginDropdownOptions(filterOptions(OriginDropdownOptions, originExcludeValues));
        setDestintaionDropdownOptions(filterOptions(DestinationDropdownOptions, destinationExcludeValues));
    }, [airChip, landChip, oceanChip]);

    const onSubmit = (data: IFirstPhaseFormInput) => {
        tryTrackEvent("[Rfq - Step 1]: 'Next' button clicked");

        const shippmentChosenMethods = [];
        if (oceanChip) shippmentChosenMethods.push(ShipmentMethod.ByOcean);
        if (airChip) shippmentChosenMethods.push(ShipmentMethod.ByAir);
        if (landChip) shippmentChosenMethods.push(ShipmentMethod.ByLand);

        dispatch(updateShipmentMethod(shippmentChosenMethods));

        dispatch(
            updateFirstPhase({
                ...data,
                shippingMethod: {
                    air: airChip,
                    ocean: oceanChip,
                    land: landChip,
                },
                destinationBannerData: convertToOBj(data.destinationType as LocationType, data.destinationAddress),
                fromBannerData: convertToOBj(data.fromType as LocationType, data.fromAddress),
            }),
        );
        updateFormState(RfqStep.SecondStep);
    };

    return (
        <Box className={classes.formWrapper}>
            <Box className={classes.headerWrapper}>
                <h1 className={classes.header}>{HeaderTexts.mainHeader}</h1>
                <span className={classes.subHeader}>{HeaderTexts.subHeader}</span>
            </Box>

            <Box className={classes.formChip}>
                <div className={classes.formChipTitle}>{shipmentTypesHeaderTexts.mainHeader}</div>
                <Box className={classes.formChipWrapper}>
                    <Chip
                        rfqChipIcon={AirplaneIcon}
                        label="Air"
                        onSelect={() => setAirChip((prev) => !prev)}
                        selected={airChip}
                        isRfqChip
                    />
                    <Chip
                        rfqChipIcon={ShipIcon}
                        label="Ocean"
                        onSelect={() => setOceanChip((prev) => !prev)}
                        selected={oceanChip}
                        isRfqChip
                    />
                    <Chip
                        rfqChipIcon={TruckIcon}
                        label="Land"
                        onSelect={() => setLandChip((prev) => !prev)}
                        selected={landChip}
                        isRfqChip
                    />
                </Box>
                <Box className={classes.formLocation}>
                    <Box className={classes.wrapperLocations}>
                        <Box className={classes.wrapperLocation}>
                            <span className={classes.inputlabel}>From</span>
                            <div className={classes.formlocationLine}>
                                <FormInputDropdown
                                    width={inputWidth}
                                    required={true}
                                    items={currentOriginDropdownOptions}
                                    name="fromType"
                                    control={control}
                                    label="Location Type*"
                                    disabled={!checkIfChipWasSelected()}
                                    setFormValue={setValue}
                                    nameFieldAutocomplete="fromAddress"
                                />
                                <FormInputAutocomplete
                                    disabled={!watchForm.fromType}
                                    locationType={watchForm.fromType}
                                    required={true}
                                    name="fromAddress"
                                    control={control}
                                    label={"Origin"}
                                />
                            </div>
                        </Box>

                        <Box className={classes.wrapperLocation}>
                            <Box className={classes.iconWrapper}>
                                <RfqArrow />
                                <span className={classes.inputlabel}>To</span>
                            </Box>
                            <div className={classes.formlocationLine}>
                                <FormInputDropdown
                                    width={inputWidth}
                                    required={true}
                                    items={destinationDropDownOptions}
                                    name="destinationType"
                                    control={control}
                                    label="Location Type*"
                                    disabled={!checkIfChipWasSelected()}
                                    setFormValue={setValue}
                                    nameFieldAutocomplete="destinationAddress"
                                />
                                <FormInputAutocomplete
                                    disabled={!watchForm.destinationType}
                                    locationType={watchForm.destinationType}
                                    required={true}
                                    name="destinationAddress"
                                    control={control}
                                    label={"Destination"}
                                />
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <ContainedButton
                disabled={!isValid || !checkIfChipWasSelected()}
                className={`${classes.button} ${classes.noBoxShadow}`}
                onClick={handleSubmit(onSubmit)}
            >
                Next
            </ContainedButton>
        </Box>
    );
};

export default FirstPhaseForm;
