import { CSSProperties } from "react";
import { COLORS } from "../../data/ui";
declare module "@mui/material/styles" {
    interface Palette {
        backgroundColors: {
            dashboardBG?: CSSProperties["color"];
            componentsBG?: CSSProperties["color"];
            dividers?: CSSProperties["color"];
        };
    }

    interface PaletteOptions {
        backgroundColors?: {
            dashboardBG?: string;
            componentsBG?: string;
            dividers?: string;
        };
    }
}

export const backgroundColors = {
    dividers: COLORS.general.dividers,
    dashboardBG: COLORS.general.dashboardBG,
    componentsBG: COLORS.general.componentsBG,
};
