import useStyles from "./useStyle";
import { Box } from "@mui/material";
import { useUpdateNotificationsSeenStatusMutation } from "../../../store/api-slices/notificationsSlice.api";
import { VerificationCheckIcon } from "../../../assets/icons";
import { useAppSelector } from "../../../store";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";
export interface IContactUsProps {
    markAllAsRead: React.Dispatch<React.SetStateAction<boolean>>;
    getNotifications: ({ customerCode }: { customerCode: string }) => Promise<any>;
}

const NotificationMarkAllPopup = ({ markAllAsRead, getNotifications }: IContactUsProps) => {
    const classes = useStyles();
    const userState = useAppSelector((state) => state.rootReducer.users);
    const [triggerMarkAll] = useUpdateNotificationsSeenStatusMutation();

    const markAll = async () => {
        await triggerMarkAll(userState.code);
        await getNotifications({ customerCode: userState.code });
        markAllAsRead(false);
        tryTrackEvent("[Overview page]: 'Yes, clear all' button clicked (in 'Dismiss all' popup)");
    };

    return (
        <Box className={classes.popupWarpper}>
            <Box className={classes.formbox}>
                <VerificationCheckIcon />
                <div className={classes.header}>Whoa there!</div>
                <div className={classes.subHeader}>
                    You're about to clear all your updates and notifications. Are you sure you want to delete
                    everything?
                </div>
            </Box>
            <Box className={classes.buttonsWarpper}>
                <button className={classes.buttonBack} onClick={() => markAllAsRead(false)}>
                    <span className={classes.buttonText}>Back</span>
                </button>
                <button className={classes.button} onClick={() => markAll()}>
                    <span>Yes, clear all</span>
                </button>
            </Box>
        </Box>
    );
};

export default NotificationMarkAllPopup;
