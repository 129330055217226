import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import App from "../App";
import { AuthGuard, NoCustomersGuard, SelectCustomerGuard } from "../guards";
import { RequireAuth } from "../guards/RequireAuth";
import {
    LoadingYaadSarigQueryParamsPaymentPage,
    NoCustomersPage,
    OverviewPage,
    PaymentsPage,
    RfqPage,
    SelectCustomerPage,
    ShipmentsPage,
} from "../pages";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route
                path="/"
                element={
                    <RequireAuth>
                        <App />
                    </RequireAuth>
                }
            >
                <Route path="/overview" element={<OverviewPage />} />
                <Route path="/shipments" element={<ShipmentsPage />} />
                <Route path="/payments" element={<PaymentsPage />} />
            </Route>
            <Route
                path="/select-customer"
                element={
                    <RequireAuth>
                        <SelectCustomerGuard>
                            <SelectCustomerPage />
                        </SelectCustomerGuard>
                    </RequireAuth>
                }
            />
            <Route
                path="/no-customer"
                element={
                    <RequireAuth>
                        <NoCustomersGuard>
                            <NoCustomersPage />
                        </NoCustomersGuard>
                    </RequireAuth>
                }
            />
            <Route path="/login" element={<AuthGuard />} />
            <Route path="/signup" element={<AuthGuard />} />
            <Route path="/rfq" element={<RfqPage />} />
            <Route path="/payments/payment-result-loading" element={<LoadingYaadSarigQueryParamsPaymentPage />} />
        </>,
    ),
);

export const RouteToPreviousPage = -1;
