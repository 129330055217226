export const TextModal = {
    textHeaderFirst: "We’re on it",
    textHeaderSecond: "Expect your shipping quote soon",
    textHeaderThird: "Personalize your experience with the details below",
    radioText: "How many shipments did you have in the last 12 months?",
    helpType: "Anything else we can help you with?",
    textCode: "Have an affiliated code?",
    textHeaderSuccessFirst: "All done!",
    textHeaderSuccessSecond: "We’re working on your quote",
};
export const ButtonText = {
    submit: "Submit",
    takeMeBack: "Take me back",
};
export const radioOptions = [
    {
        label: "It’s my first time",
        value: "It’s my first time",
    },
    {
        label: "1-10",
        value: "1-10",
    },
    {
        label: "10-50",
        value: "10-50",
    },
    {
        label: "50+",
        value: "50+",
    },
];
export interface IHelpType {
    label: string;
    isSelected: boolean;
}
export const helpTypeContent: IHelpType[] = [
    {
        label: "Warehousing services",
        isSelected: false,
    },
    {
        label: "Freight solutions",
        isSelected: false,
    },
    {
        label: "Ecommerce logistics",
        isSelected: false,
    },
    {
        label: "Supply chain audit",
        isSelected: false,
    },
];
