import dayjs from "dayjs";
import {
    DateRangeFilterOptions,
    FilterTypes,
    IDateRangeFilter,
    IFilter,
    IFilters,
    InvoiceSortTypes,
    InvoicesFilterKeyNames,
    SortOrder,
    YYYY_MM_DD,
} from "../../models/filters/filters.interfaces";
import { ValidInvoiceStatus } from "../../models/invoices/invoices.interfaces";

export interface IInvoiceParamsForProcessing {
    page?: number;
    limit?: number;
    sortType: InvoiceSortTypes;
    sortOrder: SortOrder;
    search?: string;
    filters?: IFilters;
}

export type IInvoiceProcessedParams = Omit<IInvoiceParamsForProcessing, "filters"> & {
    filterTypes?: InvoicesFilterKeyNames[];
    filterValues?: string[][];
};

export type InvoiceService = "Shipment" | "Warehouse";

const getFilterTypesFromFilters = (filters: IFilters): InvoicesFilterKeyNames[] => {
    return filters.map((filter) => filter.keyName);
};

const getFilterValuesFromFilters = (filters: IFilters): string[][] => {
    return filters.map((filter) => {
        switch (filter.filterType) {
            case FilterTypes.SINGLE_CHOICE_SELECT:
            case FilterTypes.MULTI_CHOICE_SELECT:
                return getSelectFilterValues(filter);
            case FilterTypes.DATE_RANGE:
                return getDateRangeFilterValues(filter as IDateRangeFilter);
            default:
                return [];
        }
    });
};

const getSelectFilterValues = (filter: IFilter): string[] => {
    const filterValues = filter.options.reduce((acc, option) => {
        if (option.isSelected && option.value) {
            if (option.value === ValidInvoiceStatus.UNPAID) {
                acc.push(ValidInvoiceStatus.PARTIALLY_PAID);
            }
            acc.push(option.value as string);
        }
        return acc;
    }, [] as string[]);
    return filterValues;
};

const getDateRangeFilterValues = (filter: IDateRangeFilter): string[] => {
    const filterValues = filter.options.reduce((acc, option) => {
        if (option.isSelected && option.value) {
            const startDate = dayjs(option.value[0]).format(YYYY_MM_DD);
            const endDate = dayjs(option.value[1]).format(YYYY_MM_DD);
            if (startDate && endDate) {
                acc.push(startDate);
                acc.push(endDate);
            }
        }
        return acc;
    }, [] as string[]);
    return filterValues;
};

export const buildGetInvoicesBody = (params: IInvoiceParamsForProcessing): IInvoiceProcessedParams => {
    const { filters, ...restOfParams } = params;
    if (filters) {
        const filtersToQuery = filters.filter((filter) => {
            const hasSomeSelectedOption = filter.options.some((option) => option.isSelected);
            const notSearchFilter = filter.filterType !== FilterTypes.SEARCH;
            const notAllDatesInDateRangeFilter = !(
                filter.filterType == FilterTypes.DATE_RANGE &&
                filter.options.some((option) => option.isSelected && option.value === DateRangeFilterOptions.AllDates)
            );
            return hasSomeSelectedOption && notSearchFilter && notAllDatesInDateRangeFilter;
        });
        const filterTypes = getFilterTypesFromFilters(filtersToQuery);
        const filterValues = getFilterValuesFromFilters(filtersToQuery);
        return {
            ...restOfParams,
            filterTypes,
            filterValues,
        };
    }
    return params;
};
