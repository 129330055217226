import {
    CustomsClearedIcon,
    NotificationBookedIcon,
    NotificationCargoDefaultIcon,
    NotificationCargoNegativeIcon,
    NotificationCargoPositiveIcon,
    NotificationDepartureIcon,
    NotificationExPickupDefaultIcon,
    NotificationExPickupNegativeIcon,
    NotificationExPickupPositiveIcon,
    NotificationPODPositiveIcon,
    NotificationShipmentArrivedIcon,
    NotificationShipmentAvailableIcon,
    NotificationTelexPositive,
    NotificationTimeUpdatedIcon,
} from "../../../../assets/icons";
import { ENotificationIcons } from "../../../../models";

export const STRINGS = {
    confirmButton: "Dismiss",
    nullMessage: "",
    nullShipmentName: "Shipment name",
};

export interface INotificationIconDetails {
    icon?: JSX.Element;
    message: string;
    preMessage?: string;
}
export const STATUS_MAP: Record<number, INotificationIconDetails> = {
    // Positive + Neutral / Default
    [ENotificationIcons.shipmentBooked]: {
        icon: <NotificationBookedIcon />,
        message: "Your shipment is booked!",
        preMessage: "Route is booked:",
    },
    [ENotificationIcons.shipmentAvailable]: {
        icon: <NotificationShipmentAvailableIcon />,
        message: "Your shipment is available at the port!",
        preMessage: "Date update:",
    },
    [ENotificationIcons.telexReleasePositive]: {
        icon: <NotificationTelexPositive />,
        message: "Your Telex Release is here!",
        preMessage: "We got it!",
    },
    [ENotificationIcons.customsCleared]: {
        icon: <CustomsClearedIcon />,
        message: "Your shipment is customs cleared!",
        preMessage: "Cleared on:",
    },
    [ENotificationIcons.podScanned]: {
        icon: <NotificationPODPositiveIcon />,
        message: "Proof of delivery is available.",
        preMessage: "Shipment closed at:",
    },
    [ENotificationIcons.expectedDeparture]: {
        icon: <NotificationDepartureIcon />,
        message: "We are getting close to departure!",
        preMessage: "ETD on:",
    },
    [ENotificationIcons.actualDeparture]: {
        icon: <NotificationDepartureIcon />,
        message: "Your shipment is on its way! ",
        preMessage: "Your shipment departured on:",
    },
    [ENotificationIcons.expectedPickupOrigin]: {
        icon: <NotificationExPickupDefaultIcon />,
        message: "Pickup Scheduled.",
        preMessage: "Expect to be picked up:",
    },
    [ENotificationIcons.actualPickupOrigin]: {
        icon: <NotificationExPickupPositiveIcon />,
        message: "Your cargo is on the move!",
        preMessage: "We picked up on:",
    },
    [ENotificationIcons.expectedPickupDest]: {
        icon: <NotificationExPickupDefaultIcon />,
        message: "Pick up from port is scheduled.",
        preMessage: "ETD on:",
    },
    [ENotificationIcons.actualPickupDest]: {
        icon: <NotificationExPickupPositiveIcon />,
        message: "Shipment was picked up for delivery!",
        preMessage: "Specifically on:",
    },
    [ENotificationIcons.expectedCargoReadyDate]: {
        icon: <NotificationCargoDefaultIcon />,
        message: "Your cargo is almost ready!",
        preMessage: "Expected cargo ready date:",
    },
    [ENotificationIcons.expectedArrivalToPort]: {
        icon: <NotificationCargoDefaultIcon />,
        message: "We’re almost at the final port.",
        preMessage: "ETA at port:",
    },
    [ENotificationIcons.actualCargoReadyDate]: {
        icon: <NotificationCargoPositiveIcon />,
        message: "Your cargo is ready to ship!",
        preMessage: "Ready to leave on:",
    },
    [ENotificationIcons.actualArrivalToPort]: {
        icon: <NotificationCargoPositiveIcon />,
        message: "We made it to port!",
        preMessage: "Final destination port:",
    },
    [ENotificationIcons.shipmentArrived]: {
        icon: <NotificationShipmentArrivedIcon />,
        message: "DELIVERED!",
        preMessage: "The time has come!",
    },

    // Negative
    [ENotificationIcons.expPickupFromOriginChanged]: {
        icon: <NotificationExPickupNegativeIcon />,
        message: "Expected Pick up from supplier update!",
        preMessage: "ETA:",
    },
    [ENotificationIcons.expDepartureChanged]: {
        icon: <NotificationTimeUpdatedIcon />,
        message: "Expected departure update",
        preMessage: "ETD:",
    },
    [ENotificationIcons.expArrivalToPortChanged]: {
        icon: <NotificationTimeUpdatedIcon />,
        message: "ETA to Port update!",
        preMessage: "ETA:",
    },
    [ENotificationIcons.exptPickupToDeliveryChanged]: {
        icon: <NotificationExPickupNegativeIcon />,
        message: "Expected Pick up for delivery update!",
        preMessage: "ETA:",
    },
    [ENotificationIcons.exptCargoReadyDateChanged]: {
        icon: <NotificationCargoNegativeIcon />,
        message: "Your cargo ready date has changed!",
        preMessage: "CRD:",
    },
};
