import { makeStyles } from "@mui/styles";
import { theme } from "../../../assets/theme/theme";
import { COLORS } from "../../../assets/data/ui";

const useStyles = makeStyles({
    formWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        padding: "32px",
        margin: "0",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            marginTop: "0",
            paddingTop: "24px",
        },
    },
    chips: {},
    formChip: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",

        [theme.breakpoints.down("sm")]: {
            margin: "unset",
        },
    },
    formChipWrapper: {
        display: "flex",
        gap: "24px",
        padding: "36px 0",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            justifyContent: "space-evenly",
            gap: "16px",
            marginTop: "16px",
        },
    },

    formChipTitle: {
        margin: "0px auto",
        marginTop: "40px",
        fontSize: "16px",
        color: COLORS.primaryDark,
        [theme.breakpoints.down("md")]: {
            marginTop: "24px",
        },
    },
    subHeader: {
        fontSize: "18px",
        fontWeight: "600",
        lineHeight: "22px",
        color: COLORS.primaryDark,
    },
    formlocationLine: {
        display: "flex",
        gap: "16px",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    formLocation: {
        gap: "22px",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            gap: "10px",
            margin: " 0 auto",
        },
    },
    iconWrapper: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-start",
    },
    inputlabel: {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "14px",
        marginLeft: "10px",
        color: COLORS.primaryDark,
    },
    wrapperLocations: {
        display: "flex",
        flexDirection: "column",
    },
    wrapperLocation: {
        gap: "8px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            gap: "16px",
        },
    },

    button: {
        width: "80px",
        borderRadius: "32px",
        alignSelf: "center",
        backgroundColor: COLORS.success,
        color: COLORS.primaryDark,
        marginTop: "28px !important",
        "&:hover": {
            backgroundColor: COLORS.success,
        },
    },
    noBoxShadow: {
        boxShadow: "none !important",
    },
    headerWrapper: {
        margin: "0px auto",
        textAlign: "center",
    },
    header: {
        padding: "10px",
        color: COLORS.primaryDark,
    },
});

export default useStyles;
