import { makeStyles } from "@mui/styles";
import { TYPOGRAPHY, UiSchemeV2 } from "../../../assets/data/ui";
import { theme } from "../../../assets/theme/theme";

const filterContainer = {
    display: "flex",
    gap: "1rem",
    padding: "0.75rem 0",
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
    },
    FilterPopupHead: {
        display: "flex",
        justifyContent: "space-between",
        fontSize: TYPOGRAPHY.titleExtraLarge,
    },
    popupCloseIcon: {
        "&:hover": {
            cursor: "pointer",
        },
    },
    FilterPopupBody: {},
    FilterPopupFooter: {
        display: "flex",
        gap: "1rem",
        marginTop: "1rem",
    },
    FilterSubTitle: {
        color: UiSchemeV2.colors.primaryDark,
        fontSize: TYPOGRAPHY.title,
    },
    StatusContainer: {
        ...filterContainer,
        display: "flex",
        flexDirection: "column",
    },
    StatusChipsContainer: {
        [theme.breakpoints.down("lg")]: {
            flexWrap: "wrap",
        },
        display: "flex",
        gap: "1.5rem",
    },
    TypeContainer: {
        ...filterContainer,
        display: "flex",
        flexDirection: "column",
        wordBreak: "break-word",
    },
    TypeChipsContainer: {
        display: "flex",
        gap: "1rem",
    },
    SelectsContainer: {
        padding: "0.75rem 0",
        display: "flex",
        gap: "1rem",
    },
    ResetAllButton: {
        textDecoration: "underline",
        textUnderlineOffset: "4px",
    },
});

export default useStyles;
