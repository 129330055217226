import { FormControl } from "@mui/material";
import { ToggleButton } from "../form-elements/toggleButton/ToggleButton";
import { Box } from "@mui/system";
import {
    oceanToggleButtonDetails,
    oceanCargoType,
    oceanLoadToggleButtonDetails,
    oceanradioOptions,
} from "../../../pages/rfq/second-form/secondPhaseFormUtils";
import { ISecondPhaseOceanHeaderProps, oceanHeaderText } from "./SecondPhaseUtils";
import { useStyles } from "./useStyles";
import { TabsInput } from "../tabs/TabsInput";

const SecondPhaseOceanHeader = ({ control, watchFullVsPart }: ISecondPhaseOceanHeaderProps) => {
    const classes = useStyles();
    return (
        <Box>
            <FormControl className={classes.headerWrapper}>
                <span className={classes.subHeader}>{oceanHeaderText.containerText}</span>
                <ToggleButton
                    buttonsDetails={oceanToggleButtonDetails}
                    name="fullVsPart"
                    control={control}
                    required={true}
                    label={"cargo"}
                />
            </FormControl>
            {watchFullVsPart === oceanCargoType.LESS_CONTAINER && (
                <FormControl className={classes.chooseWrapper}>
                    <span className={classes.subHeader}>{oceanHeaderText.loadTypeText}</span>
                    <ToggleButton
                        buttonsDetails={oceanLoadToggleButtonDetails}
                        name="cargoType"
                        control={control}
                        required={true}
                        label={"cargo"}
                    />
                    <FormControl className={classes.TabsWrapper}>
                        <span className={classes.subHeader}>{oceanHeaderText.shipmentDescription}</span>
                        <TabsInput
                            tabsDetails={oceanradioOptions}
                            type="text"
                            size="md"
                            required={true}
                            name={"isTotalShipment"}
                            control={control}
                            label={"cargo"}
                        />
                    </FormControl>
                </FormControl>
            )}
        </Box>
    );
};

export default SecondPhaseOceanHeader;
