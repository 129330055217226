import { IShipment } from "../../../../../../../models";
import AccordionStepper from "../../../../../../accordionStepper/AccordionStepper";
import useStyles from "./useStyles";

export const RouteTab = (shipment: IShipment) => {
    const classes = useStyles();

    const { shipmentDetails, postDelivery, preDelivery, steps } = shipment;

    return (
        <div className={classes.root}>
            <AccordionStepper
                steps={steps}
                shipmentDetails={shipmentDetails}
                preDelivery={preDelivery}
                postDelivery={postDelivery}
            />
        </div>
    );
};
