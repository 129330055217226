interface INextLabels {
    [key: string]: string;
    pickupDeparture: string;
    mainCarriageDeparture: string;
    mainCarriageArrival: string;
    availableForDeliveryDate: string;
    pickupForDeliveryDeparture: string;
    pickupForDeliveryArrival: string;
}

interface IActiveLabels {
    [key: string]: string;
    pickupDeparture: string;
    mainCarriageDeparture: string;
    mainCarriageArrival: string;
    availableForDeliveryDate: string;
    pickupForDeliveryDeparture: string;
}

interface IBaseLabelsVertical {
    [key: string]: string;
    cargoReady: string;
    pickupDeparture: string;
    pickupArrival: string;
    mainCarriageDeparture: string;
    mainCarriageArrival: string;
    transshipment1Departure: string;
    transshipment1Arrival: string;
    transshipment2Departure: string;
    transshipment2Arrival: string;
    transshipment3Departure: string;
    transshipment3Arrival: string;
    destinationPort: string; // (ETA of last available mainCarriage process step) The text to display when the current step is the actual destination port
    onCarriageDeparture: string;
    onCarriageArrival: string;
    pickupForDeliveryDeparture: string;
    pickupForDeliveryArrival: string;
}
interface IActualLabelsVertical extends IBaseLabelsVertical {
    orderConfirmedDate: string;
    booked: string;
    availableForDeliveryDate: string;
}

export const ACTIVE_STEP_LABELS: IActiveLabels = {
    pickupDeparture: "Picked Up:",
    mainCarriageDeparture: "Departed from Origin:",
    mainCarriageArrival: "Arrived at Port:",
    availableForDeliveryDate: "Available at Port:",
    onCarriageDeparture: "Departed from Ocean Port:",
    onCarriageArrival: "Arrived at Final Port:",
    pickupForDeliveryDeparture: "Picked Up for Delivery:",
};

export const NEXT_STEP_LABELS: INextLabels = {
    pickup: "Expected Pickup:",
    pickupDeparture: "Expected Pickup:",
    mainCarriageDeparture: "Expected Departure:",
    mainCarriageArrival: "Expected Arrival to Port:",
    availableForDeliveryDate: "Expected Availability at Port:",
    pickupForDeliveryDeparture: "Expected Pickup for Delivery:",
    onCarriageDeparture: "Expected Departure from Ocean Port:",
    onCarriageArrival: "Expected arrival to Final Port:",
    pickupForDeliveryArrival: "Arrival:",
};

export const EXPECTED_STEP_LABELS_VERTICAL: IBaseLabelsVertical = {
    cargoReady: "Expected cargo ready date:",
    pickupDeparture: "Pickup Scheduled for:",
    pickupArrival: "Expected Arrival to Port:",
    mainCarriageDeparture: "Expected Departure:",
    mainCarriageArrival: "Expected Arrival to Transshipment Port:",
    transshipment1Departure: "Expected Departure from Transshipment Port:",
    transshipment1Arrival: "Expected Arrival to Transshipment Port:",
    transshipment2Departure: "Expected Departure from Transshipment Port:",
    transshipment2Arrival: "Expected Arrival to Transshipment Port:",
    transshipment3Departure: "Expected Departure from Transshipment Port:",
    transshipment3Arrival: "Expected Arrival to Transshipment Port:",
    destinationPort: "Expected Arrival at Port:", // (ETA of last available mainCarriage process step) The text to display when the current step is the actual destination port
    onCarriageDeparture: "Expected Departure from Ocean Port:",
    onCarriageArrival: "Expected arrival to Final Port:",
    pickupForDeliveryDeparture: "Expected Pickup for Delivery:",
    pickupForDeliveryArrival: "Expected Delivery:",
};

export const ACTUAL_STEP_LABELS_VERTICAL: IActualLabelsVertical = {
    orderConfirmedDate: "Shipment Created:",
    booked: "Booking Confirmed.",
    cargoReady: "Actual Cargo Ready Date:",
    pickupDeparture: "Confirmed Pickup:",
    pickupArrival: "Confirmed Arrival to Port:",
    mainCarriageDeparture: "Confirmed Departure:",
    mainCarriageArrival: "Confirmed Arrival at Transshipment Port:",
    transshipment1Departure: "Confirmed Departure from Transshipment Port:",
    transshipment1Arrival: "Confirmed Arrival at Transshipment Port:",
    transshipment2Departure: "Confirmed Departure from Transshipment Port:",
    transshipment2Arrival: "Confirmed Arrival at Transshipment Port:",
    transshipment3Departure: "Confirmed Departure from Transshipment Port:",
    transshipment3Arrival: "Confirmed Arrival at Transshipment Port:",
    destinationPort: "Confirmed Arrival at Port:", // (ATA of last available mainCarriage process step) The text to display when the current step is the actual destination port
    onCarriageDeparture: "Confirmed Departure from Ocean Port:",
    onCarriageArrival: "Confirmed Arrival to Final Port:",
    availableForDeliveryDate: "Available for Delivery:",
    pickupForDeliveryDeparture: "Confirmed Pickup for Delivery:",
    pickupForDeliveryArrival: "Delivered:",
};

export const SPECIAL_FIRST_STEPS = ["orderConfirmedDate", "cargoReady", "booked"];

export const ACTUAL_ONLY_STEPS = ["orderConfirmedDate", "cargoReady", "booked", "availableForDeliveryDate"];

export const POSSIBLE_DEST_PORT_FIELDS = [
    "mainCarriageDeparture",
    "mainCarriageArrival",
    "transshipment1Departure",
    "transshipment1Arrival",
    "transshipment2Departure",
    "transshipment2Arrival",
    "transshipment3Departure",
    "transshipment3Arrival",
    "onCarriageArrival",
]; // TODO: check if need to add onCarriage

export const ACTUAL_DEST_PORT_FIELDS = [
    "mainCarriageArrival",
    "transshipment1Arrival",
    "transshipment2Arrival",
    "transshipment3Arrival",
    "onCarriageArrival",
];

export const STRINGS = {
    NEXT_STEP: "Next Step:",
    NULL_MSG: "TBD",
    DESTINATION_PORT: "destinationPort",
    LAST_FREE_DATE: "Last free date:",
};
