import { FunctionComponent } from "react";
import DotsLoader, { DotsLoaderProps } from "../DotsLoader/DotsLoader";
import styles from "./ThreeDotsLoader.module.scss";

interface ThreeDotsLoaderProps extends DotsLoaderProps {
    className?: string;
    gap?: number;
    dotSize?: number;
}

const ThreeDotsLoader: FunctionComponent<ThreeDotsLoaderProps> = ({ className, gap = 4, dotSize = 14 }) => {
    return (
        <div className={`${styles.ThreeDotsLoader} ${className}`} style={{ gap: gap }}>
            {Array.from(Array(3).keys()).map((i) => (
                <DotsLoader
                    key={i}
                    className={`${styles.Dot} ${styles[`Dot${i + 1}`]}`}
                    predefinedStyle="Circle"
                    isLoading={true}
                    customStyle={{ width: dotSize, height: dotSize, animationDelay: `${i * 0.1}s` }}
                    animationDuration={1.5}
                />
            ))}
        </div>
    );
};

export default ThreeDotsLoader;
