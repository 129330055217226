import {
    BoxesIcon,
    FullContainerIcon,
    PalletsActiveIcon,
    FullContainerActiveIcon,
    PartialContainerActiveIcon,
    PalletsIcon,
    BoxesActiveIcon,
    PartialContainerIcon,
} from "../../../assets/icons";
import { IToggleButtonDetails } from "../../../components/RFQ/form-elements/toggleButton/toggleButtonUtils";
import { CargoArrangement, DropdownItemtype, MeasureType, PalletType } from "../../../models/rfq/rfq-types";

export const HeaderTexts = {
    mainHeader: "Share your cargo details",
};
export const ExclamationInfoText =
    "We cover the combined value of goods and initial freight costs up to $500k, with a 10% deduction over $5,000.";
export const airOrLandToggleButtonDetails: IToggleButtonDetails = {
    firstButton: {
        label: "Cartons",
        icon: <BoxesIcon />,
        activeIcon: <BoxesActiveIcon />,
    },
    secondButton: {
        label: "Pallets",
        icon: <PalletsIcon />,
        activeIcon: <PalletsActiveIcon />,
    },
};
export enum oceanCargoType {
    LESS_CONTAINER = "Partial (LCL)",
    FULL_CONTAINER = "Full (FCL)",
}
export const oceanToggleButtonDetails: IToggleButtonDetails = {
    firstButton: {
        icon: <PartialContainerIcon />,
        label: oceanCargoType.LESS_CONTAINER,
        activeIcon: <PartialContainerActiveIcon />,
    },
    secondButton: {
        label: oceanCargoType.FULL_CONTAINER,
        icon: <FullContainerIcon />,
        activeIcon: <FullContainerActiveIcon />,
    },
};
export const oceanLoadToggleButtonDetails: IToggleButtonDetails = {
    firstButton: {
        label: "Pallets",
        icon: <PalletsIcon />,
        activeIcon: <PalletsActiveIcon />,
    },
    secondButton: {
        label: "Cartons",
        icon: <BoxesIcon />,
        activeIcon: <BoxesActiveIcon />,
    },
};
export const oceanradioOptions = {
    firstTabs: {
        label: "Calculate Per Unit",
        value: CargoArrangement.ShipmentPerUnit,
    },
    secondTabs: {
        label: "Calculate Total Load",
        value: CargoArrangement.TotalShipment,
    },
};
export const PalltTypeDropdownOptions: DropdownItemtype[] = [
    {
        value: PalletType.SmallCm,
    },
    {
        value: PalletType.MediumCm,
    },
    {
        value: PalletType.LargeCm,
    },
    {
        value: PalletType.SmallIn,
    },
    {
        value: PalletType.MediumIn,
    },
];
export const fullContainerDropdownOption: DropdownItemtype[] = [
    {
        value: "20 FT",
    },
    {
        value: "40 FT",
    },
    {
        value: "40 FT HC",
    },
    {
        value: "45 FT HC",
    },
];
export const weightUnits = ["KG", "LB"];
export const volumeUnits = ["CBM", "CFT", "CIN"];
export const heightUnits = ["CM", "IN"];
export const checkboxOptions = [
    {
        label: "I want to insure my goods",
        value: "goodsInsurance",
    },
];
export const calcTotalShipment = (
    measureType: MeasureType,
    perUnitQuantity: string,
    perUnitWeight: string,
    boxPerUnitHeight: string,
    boxPerUnitLength: string,
    boxPerUnitWidth: string,
) => {
    let totalBoxesUnits = "";
    let totalQtyAndWeight = "";
    const heightUnit = measureType === MeasureType.Imperial ? "CFT" : "CBM";
    const weightUnit = measureType === MeasureType.Imperial ? "LB" : "KG";
    totalQtyAndWeight =
        perUnitWeight != "" && perUnitQuantity != ""
            ? `${Number(perUnitQuantity) * Number(perUnitWeight)} ${weightUnit}`
            : "";

    totalBoxesUnits =
        boxPerUnitHeight != "" && boxPerUnitLength != "" && boxPerUnitWidth != ""
            ? heightUnit != "CFT"
                ? `${(
                      (Number(boxPerUnitHeight) *
                          Number(boxPerUnitLength) *
                          Number(perUnitQuantity) *
                          Number(boxPerUnitWidth)) /
                      1000000
                  ).toFixed(2)} ${heightUnit} `
                : `${(
                      (Number(boxPerUnitHeight) *
                          Number(boxPerUnitLength) *
                          Number(perUnitQuantity) *
                          Number(boxPerUnitWidth)) /
                      1728
                  ).toFixed(2)} ${heightUnit} `
            : "";

    return `${totalQtyAndWeight}${totalQtyAndWeight && totalBoxesUnits != "" ? "," : ""} ${totalBoxesUnits}`;
};
