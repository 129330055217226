import { EmptyStateGrayLines, UnicargoLogoSvg } from "../../../../../assets/icons";
import useStyles from "./useStyles";

const ShipmentsDetailsEmptyState = () => {
    const classes = useStyles();
    return (
        <section className={classes.EmptyActiveShipmentsContainer}>
            <div className={classes.EmptyActiveShipmentsContent}>
                <EmptyStateGrayLines className={classes.EmptyStateGrayLinesLeft} />
                <div className={classes.EmptyActiveShipmentsCenter}>
                    <UnicargoLogoSvg />
                    <div className={classes.NoActiveShipmentsMessage}>No shipments to display</div>
                </div>
                <EmptyStateGrayLines className={classes.EmptyStateGrayLinesRight} />
            </div>
        </section>
    );
};

export default ShipmentsDetailsEmptyState;
