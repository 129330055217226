import { useStyles } from "./useStyles";

export interface ISecondPhaseCalcProps {
    totalShipmentCalculation: string;
}

const SecondPhaseCalcShipmentPerUnit = ({ totalShipmentCalculation }: ISecondPhaseCalcProps) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.calcPerUnitWrapper}>
                <span className={classes.title}>Total Cargo Dimensions</span>
                <span className={classes.calcPerUnit}>{totalShipmentCalculation}</span>
            </div>
        </div>
    );
};

export default SecondPhaseCalcShipmentPerUnit;
