import { Button, MenuItem, Select, SelectChangeEvent, SxProps } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { COLORS, UiSchemeV2 } from "../../assets/data/ui";
import { ArrowLeftIcon } from "../../assets/icons";
import { UnicargoLogoFull } from "../../assets/images";
import ThreeDotsLoader from "../../components/ui-decorations/ThreeDotsLoader/ThreeDotsLoader";
import DecorativeLine from "../../components/ui-decorations/decorative-backgrounds/DecorativeLine";
import HelpButtonDialog from "../../guards/HelpButtonDialog";
import { formatNameToCapitalFirstLetters } from "../../helpers/services/DateFormatsService.service";
import { ICustomer } from "../../models";
import { useAppDispatch, useAppSelector } from "../../store";
import { useGetCustomersByEmailQuery } from "../../store/api-slices/customersSlice.api";
import { setCurrentCustomer, userLogOut } from "../../store/reducers/usersSlice.store";
import { menuPropsStyles } from "./SelectCustomerUtils";

import styles from "../../App.module.scss";
import "./SelectCustomerPage.scss";
import { SESSION_STORAGE } from "../../assets/data/resources";
import { registerSuperPropertiesForMixpanel } from "../../helpers/services/MixPanelService";
import { getStorageItem } from "../../helpers/services";

const SelectCustomerPage = () => {
    const userData = useAppSelector((state) => state.rootReducer.users);
    const customersReq = useGetCustomersByEmailQuery(userData.email); // mock data as an array of objects.
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedCustomer, setSelectedCustomer] = useState<string>();
    const location = useLocation();
    const [errorCount, setErrorCount] = useState<number>(0);

    const redirectFromCustomerSelection = location.state?.redirectFromCustomerSelection || "/overview";

    useEffect(() => {
        if (userData.code) {
            setSelectedCustomer(
                JSON.stringify({
                    code: userData.code,
                    name: userData.name,
                    customerName: userData.customerName,
                    isNewUser: userData.isNewUser,
                }),
            );
        }
    }, [userData]);
    const sxStyle: SxProps = {
        ".MuiOutlinedInput-notchedOutline": {
            border: "none",
            borderColor: COLORS.light,
            borderBottom: isMenuOpen ? "none !important" : "",
            borderRadius: isMenuOpen ? "16px 16px 0px 0px" : "16px",
            boxShadow: "0px 4px 12px 0px #00000014",
        },

        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${COLORS.success} !important`,
            borderBottom: isMenuOpen ? "none !important" : "",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: COLORS.system.desitnation,
        },
        "& legend": {
            display: "none",
        },
    };

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedCustomer(event.target.value);
    };

    const setMixpanelEventsData = () => {
        const customerData = getStorageItem<ICustomer>(SESSION_STORAGE.customerData);
        registerSuperPropertiesForMixpanel({
            username: customerData?.name ?? "",
            customerName: customerData?.customerName ?? "",
        });
    };
    // If has a selected value, dispatch the customer code and navigate to the next page.
    const handleClick = () => {
        if (selectedCustomer) {
            const parsedObj: ICustomer = JSON.parse(selectedCustomer);
            dispatch(
                setCurrentCustomer({
                    ...userData,
                    customerName: parsedObj.customerName,
                    code: parsedObj.code,
                    name: parsedObj.name,
                    isNewUser: parsedObj.isNewUser,
                }),
            );
            setMixpanelEventsData();
            navigate(redirectFromCustomerSelection);
        }
    };

    const onNavigateBack = () => {
        dispatch(userLogOut());
    };
    useEffect(() => {
        if (customersReq.isError && errorCount <= 2) {
            retryFetch();
        }
    }, [customersReq.isError, errorCount]);

    const retryFetch = () => {
        if (errorCount < 2) {
            setErrorCount((prev) => prev + 1);
            customersReq.refetch();
        } else {
            navigate("/no-customer");
        }
    };
    const renderSelectItems = useMemo(() => {
        if (customersReq.isError) {
            return null;
        } else {
            if (customersReq.isSuccess) {
                if (customersReq.data && customersReq.data.customers) {
                    switch (customersReq.data.customers.length) {
                        case 0:
                            return navigate("/no-customer");
                        case 1: {
                            dispatch(
                                setCurrentCustomer({
                                    ...userData,
                                    code: customersReq.data?.customers[0].code,
                                    customerName: customersReq.data?.customers[0].name ?? "",
                                    name: customersReq.data?.name ?? "",
                                    isNewUser: customersReq.data?.customers[0].isNewUser,
                                }),
                            );
                            return navigate(redirectFromCustomerSelection);
                        }
                        default: {
                            return customersReq.data?.customers?.map((data) => {
                                const actualData = {
                                    code: data.code,
                                    customerName: data.name,
                                    name: customersReq.data?.name,
                                    isNewUser: customersReq.data?.isNewUser,
                                };

                                // TODO: Remove the use of this function if Unicargo ever decides to actually set the names correctly themselves.
                                const name = data.name ? formatNameToCapitalFirstLetters(data.name) : "";
                                return (
                                    <MenuItem value={JSON.stringify(actualData)} key={data.code}>
                                        {`${data.code}${`- ${name}`}`}
                                    </MenuItem>
                                );
                            });
                        }
                    }
                } else {
                    return navigate("/no-customer");
                }
            }
        }
    }, [customersReq]);

    return (
        <>
            <div className="SelectCustomerPage">
                {customersReq.isFetching ? (
                    <div className="LoaderContainer">
                        <ThreeDotsLoader className="LoadingCustomersCode" />
                    </div>
                ) : (
                    <>
                        <div className="select-customer-header">
                            <div className="back-btn">
                                <ArrowLeftIcon height={"14px"} width={"14px"} onClick={onNavigateBack} />
                            </div>
                            <div className="unicargo-logo">
                                <UnicargoLogoFull />
                            </div>
                        </div>
                        <div className="select-customer-title">
                            <span>Hello!</span>
                            <span>Welcome back</span>
                        </div>
                        <div className="select-customer-sub-title">
                            <span>Choose your account</span>
                        </div>
                        <div className="select-customer-box-container">
                            <Select
                                className="select-customer-box"
                                value={selectedCustomer}
                                onChange={handleChange}
                                label="Choose your account"
                                MenuProps={menuPropsStyles}
                                sx={sxStyle}
                                onClose={() => {
                                    setIsMenuOpen((prevValue) => !prevValue);
                                }}
                                onOpen={() => {
                                    setIsMenuOpen((prevValue) => !prevValue);
                                }}
                            >
                                {renderSelectItems}
                            </Select>
                        </div>
                        <div className="select-customer-button-container">
                            <Button
                                disabled={!selectedCustomer}
                                className="select-customer-login-button"
                                onClick={handleClick}
                            >
                                Log in
                            </Button>
                        </div>
                        <HelpButtonDialog />
                    </>
                )}
            </div>
            <DecorativeLine
                width="100%"
                className={`${styles.LineBackgroundSvg}`}
                colorLeftLine={UiSchemeV2.colors.primaryLight}
                colorRightLine={UiSchemeV2.colors.secondaryGreen}
                colorMiddleCircle={"#05B834"}
                leftLinePercentage={60}
                height="52px"
                borderRadius="0"
                style={{ zIndex: 1 }}
            />
        </>
    );
};

export default SelectCustomerPage;
