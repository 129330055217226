import { COLORS, UiSchemeV2 } from "../../../assets/data/ui";
import { IChip } from "../../../models";
import { formatNumberWithDecimal } from "../../../helpers/services/DateFormatsService.service";
import StageColorContainer from "../../colorContainer/StageColorContainer";
import useStyles from "./useStyles";

const Chip = ({ label, count, color, icon: Icon, onSelect, selected, rfqChipIcon: RfqChipIcon, isRfqChip }: IChip) => {
    const classes = useStyles();
    const border = selected ? (Icon ? "none" : `1px solid ${color}`) : `1px solid ${COLORS.light}`;
    const backgroundColor = selected ? UiSchemeV2.colors.primaryDark : UiSchemeV2.colors.greys.white;
    const labelColor = selected ? UiSchemeV2.colors.greys.white : UiSchemeV2.colors.primaryDark;
    const counterContainerColor = selected ? UiSchemeV2.colors.greys.white : UiSchemeV2.colors.primaryDark;
    const counterColor = selected ? UiSchemeV2.colors.primaryDark : UiSchemeV2.colors.greys.white;

    return (
        <button
            className={`${classes.root} ${isRfqChip ? classes.RfqContainer : ""} ${selected && "selected"}`}
            onClick={() => onSelect(label, !selected)}
            style={{ border, backgroundColor }}
        >
            {RfqChipIcon && (
                <div className={`${classes.rfqIconContainer} ${selected && "selected"}`}>
                    {<RfqChipIcon width={"18px"} height={"18px"} />}
                </div>
            )}
            {!Icon && color && <StageColorContainer color={color} />}
            {Icon && <div className={`${classes.iconContainer} ${selected && "selected"}`}>{<Icon />}</div>}
            <div style={{ color: labelColor }} className={classes.label}>
                {label}
            </div>
            {count !== undefined && (
                <div style={{ backgroundColor: counterContainerColor }} className={`${classes.counterContainer}`}>
                    <div style={{ color: counterColor }} className={classes.counter}>
                        {formatNumberWithDecimal(count)}
                    </div>
                </div>
            )}
        </button>
    );
};

export default Chip;
