import { IShipmentUpdateResponse } from "./../../models/http/IShipmentUpdate";
import { unicargoApiSlice } from "../apiSlice";
import { buildShipmentQueryString, IShipmentQueryParams } from "../../helpers/services/ShipmentService";
import { IShipmentResponse } from "../../models/http";
import { IShipmentDocsResponse } from "../../models/http/IShipmentDocsResponse";

interface IQueryParams {
    customerCode: string;
    params?: IShipmentQueryParams;
}

interface IDocsQueryParams {
    shipmentNumber: string;
    customerCode: string;
}

interface IUpdateShipmentQueryParams {
    customerCode: string;
    shipmentNumber: string;
    shipmentName: string;
}

export interface IShipmentDocumentRequest {
    documentSecurityId: string;
    shipmentNumber: string;
    customerCode: string;
    fileExtension?: string;
    fileDownload?: boolean;
    fileName?: string;
}

const endpointPrefix = "shipments";
export const shipmentsApiSlice = unicargoApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getShipmentsByCustomerCode: builder.query<IShipmentResponse, IQueryParams>({
            query: ({ customerCode, params }) =>
                `/${endpointPrefix}/${customerCode}${params ? `?${buildShipmentQueryString(params)}` : ""}`,
        }),
        getShipmentDocs: builder.query<IShipmentDocsResponse, IDocsQueryParams>({
            query: (body) => ({
                url: `/${endpointPrefix}/docs`,
                method: "POST",
                body,
            }),
        }),
        updateShipment: builder.mutation<IShipmentUpdateResponse, IUpdateShipmentQueryParams>({
            query: (body) => ({
                url: `/${endpointPrefix}`,
                method: "PUT",
                body,
            }),
        }),
        getShipmentDocument: builder.query<any, IShipmentDocumentRequest>({
            query: (body) => ({
                url: `/${endpointPrefix}/doc`,
                method: "POST",
                body,
                responseHandler: async (response) => {
                    const { fileDownload, fileName, fileExtension } = body;
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    if (fileDownload) {
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = `${fileName}.${fileExtension}`;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    } else {
                        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
                        if (newWindow) {
                            newWindow.opener = null;
                        }
                    }
                    window.URL.revokeObjectURL(url);
                },
                cache: "no-cache",
            }),
        }),
        getShipmentsDataForDownloadByCustomerCode: builder.query<any, any>({
            query: ({ customerCode, params }) => ({
                url: `/${endpointPrefix}/${customerCode}/sheets${params ? `?${buildShipmentQueryString(params)}` : ""}`,
                method: "POST",
                body: { ...params },
                responseHandler: async (response) => {
                    // report was sent via email
                    if (response.status === 200) {
                        console.log("Success while downloading shipments data: ", response);
                        const responseData = await response.json();
                        return responseData;
                    }
                    // report is sent for download
                    if (response.status === 201) {
                        const blob = await response.blob();
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        const fileName = `${params.reportType}.xlsx`;
                        link.download = fileName;
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                        return;
                    }
                    console.error("Error while downloading invoices data: ", response);
                },
            }),
        }),
    }),
    overrideExisting: false,
});
export const {
    useGetShipmentsByCustomerCodeQuery,
    useLazyGetShipmentsByCustomerCodeQuery,
    useLazyGetShipmentDocumentQuery,
    useGetShipmentDocsQuery,
    useUpdateShipmentMutation,
    useLazyGetShipmentsDataForDownloadByCustomerCodeQuery,
} = shipmentsApiSlice;
