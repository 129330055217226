import { makeStyles } from "@mui/styles";
import { UiSchemeV2, COLORS, TYPOGRAPHY } from "../../../assets/data/ui";
import { theme } from "../../../assets/theme/theme";

const removeDefaultBtnStyle = {
    background: "transparent",
    border: "none",
    margin: "0",
    padding: "0",
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        gap: "1rem",
        [theme.breakpoints.down("lg")]: {
            padding: "0 1rem 1rem",
        },
        borderRadius: "4px",
        overflow: "hidden",
    },
    noActiveShipment: {
        "& + div": {
            display: "none",
        },
    },
    // Head
    ShipmentsListHead: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: `1px solid ${COLORS.general.dividers}`,
        paddingTop: "1rem",
        [theme.breakpoints.up("lg")]: {
            margin: "0 1rem",
        },
    },
    TabsContainer: {
        display: "flex",
        gap: "1rem",
    },
    TabContainer: {
        padding: "0.5rem 0.25rem",
        "&:hover": {
            cursor: "pointer",
            "& button": {
                cursor: "pointer",
            },
        },
        "&.active": {
            borderBottom: `1px solid ${COLORS.main}`,
            "& button": {
                color: COLORS.main,
            },
        },
    },
    Tab: {
        ...removeDefaultBtnStyle,
        fontSize: TYPOGRAPHY.title,
        color: COLORS.medium,
        fontWeight: 600,
        "&.active": {},
    },
    // Filter button + popup
    FilterButton: {
        ...removeDefaultBtnStyle,
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        justifyContent: "flex-end",
        fontSize: TYPOGRAPHY.title,
        padding: "5px 0",
        lineHeight: "14px",
        "&:hover": {
            cursor: "pointer",
        },
        "&.active": {
            backgroundColor: UiSchemeV2.colors.greys.background,
        },
    },
    filterIconAndTitle: {
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        justifyContent: "flex-end",
    },

    activeFiltersCountText: {
        width: "1.5rem",
        height: "1.5rem",
        borderRadius: "50%",
        backgroundColor: UiSchemeV2.colors.primaryDark,
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignText: "center",
    },

    // Search
    SearchContainer: {
        [theme.breakpoints.up("lg")]: {
            padding: "0 1rem",
        },
    },
    // List
    ListContainer: {
        display: "flex",
        height: "65vh",
        flexDirection: "column",
        gap: "1rem",
        overflow: "auto",
        [theme.breakpoints.up("sm")]: {
            padding: "0 1rem 1rem",
            height: "70vh",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "0 1rem 1rem",
            height: "100%",
        },
    },
});

export default useStyles;