import { useEffect, useState } from "react";
import { IStepper } from "./types";
import { UiSchemeV2 } from "../../assets/data/ui";
import "./Stepper.scss";

const Stepper = ({ steps, accordionState, dotColor, shipmentDetails }: IStepper) => {
    const [distance, setDistance] = useState(0);
    const [stepperHeight, setStepperHeight] = useState(0);

    const landTransportMode = shipmentDetails.transportMode === "Inland";

    useEffect(() => {
        const allDots = document.getElementsByClassName("step-dot");
        const lastDots = document.getElementsByClassName("is-done-dot");

        let lastDoneDot = lastDots[lastDots.length - 1];
        const firstDot = allDots[0];
        const lastDot = allDots[allDots.length - 1];

        setTimeout(() => {
            if (!lastDots.length) {
                const titleDots = document.getElementsByClassName("title-dot");
                if (accordionState.destination) {
                    lastDoneDot = titleDots[2];
                } else if (accordionState.inTransit) {
                    lastDoneDot = titleDots[1];
                } else {
                    lastDoneDot = titleDots[0];
                }
            }

            const firstDotRect = firstDot?.getBoundingClientRect();
            const lastDotRect = lastDot?.getBoundingClientRect();

            setStepperHeight(Math.round(lastDotRect.bottom - firstDotRect.top));

            if (lastDoneDot === allDots[allDots.length - 1]) {
                setDistance(10000);
                return;
            }

            if (!firstDot || !lastDoneDot) {
                return;
            }

            const lastDoneDotRect = lastDoneDot?.getBoundingClientRect();

            setDistance(lastDoneDotRect.bottom - firstDotRect.top - 5);

            if (!accordionState.destination && !accordionState.inTransit && !accordionState.origin) {
                const titleDots = document.getElementsByClassName("title-dot");
                if (dotColor === UiSchemeV2.colors.destinationPurple) {
                    lastDoneDot = titleDots[2];
                    const lastDotRect = lastDoneDot.getBoundingClientRect();
                    setDistance(lastDotRect.bottom - firstDotRect.top);
                } else if (dotColor === UiSchemeV2.colors.secondaryGreen) {
                    lastDoneDot = titleDots[1];
                    const lastDotRect = lastDoneDot.getBoundingClientRect();
                    setDistance(lastDotRect.bottom - firstDotRect.top);
                } else if (dotColor === UiSchemeV2.colors.originLightBlue) {
                    lastDoneDot = titleDots[0];
                    const lastDotRect = lastDoneDot.getBoundingClientRect();
                    setDistance(lastDotRect.bottom - firstDotRect.top);
                }
            }
        }, 100);
    }, [steps, accordionState]);

    const containerStyle = {
        background: `linear-gradient(to bottom, ${dotColor} ${distance}px, #f3f3f3 0)`,
        height: `${stepperHeight}px`,
        transform: `${landTransportMode ? "translateY(90px)" : "translateY(52.5px)"}`,
    };

    return <div className="stepper-container" style={containerStyle}></div>;
};

export default Stepper;
