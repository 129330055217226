import { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { formatNumberWithDecimal } from "../../../helpers/services/DateFormatsService.service";
import { PaymentStage } from "../../../models/invoices/invoices.interfaces";
import { IUnicargoBankTransferAccount } from "../../../models/invoices/payments.dto";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
    useGetUnicargoBankAccountsQuery,
    useLazySendBankTransferReqQuery,
} from "../../../store/api-slices/paymentSlice.api";
import { setPaymentStage } from "../../../store/reducers/invoicesSlice.store";
import UnicargoKeyValueTextInfoContainer from "../../UnicargoKeyValueTextInfoContainer/UnicargoKeyValueTextInfoContainer";
import UnicargoSelectBtn2 from "../../UnicargoSelectBtn2/UnicargoSelectBtn2";
import UnicargoTextAreaInput from "../../UnicargoTextAreaInput/UnicargoTextAreaInput";
import UnicargoTextInfoContainer from "../../UnicargoTextInfoContainer/UnicargoTextInfoContainer";
import UnicargoTextInput from "../../UnicargoTextInput/UnicargoTextInput";
import GenericButton, { ButtonTypeStyles } from "../../buttons/GenericButton/GenericButton";
import styles from "./BankTransferView.module.scss";
import { UiSchemeV2 } from "../../../assets/data/ui";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";

const BankTransferView = () => {
    const dispatch = useAppDispatch();
    const invoicesState = useAppSelector((state) => state.rootReducer.invoices);
    const paymentStage = invoicesState.paymentStage;
    const customerCode = useAppSelector((state) => state.rootReducer.users.code);
    const selectedInvoices = invoicesState.selectedInvoices;
    const sumOfAmountDueToPay = selectedInvoices.reduce((acc, curr) => acc + curr.amountDue, 0);
    const [unicargoBankAccount, setUnicargoBankAccount] = useState<IUnicargoBankTransferAccount | undefined>();
    const { data: unicargoBankAccounts, isFetching: isFetchingUnicargoBankAccounts } =
        useGetUnicargoBankAccountsQuery(customerCode);
    const [sendBankTransferReq, { data: bankTransferRes, isFetching: isFetchingBankTransferReq }] =
        useLazySendBankTransferReqQuery();
    const [note, setNote] = useState("");
    const [transferNumber, setTransferNumber] = useState<string | undefined>();

    const title = "Please transfer the payment through your bank and fill in the transfer details";
    const subTitle = "These will assist us in processing your payment quicker";

    useEffect(() => {
        if (!unicargoBankAccount) return;
        tryTrackEvent(`[Payments Page Popup]: Bank Transfer - Bank chosen: ${unicargoBankAccount.bankName}`);
    }, [unicargoBankAccount]);

    useEffect(() => {
        if (isFetchingBankTransferReq || !bankTransferRes || !unicargoBankAccount) return;
        if (bankTransferRes.successMessage) {
            tryTrackEvent("[Payments Page Popup]: Bank Transfer Success", {
                flow: "Payment",
                bankName: unicargoBankAccount.bankName,
                currentStage: paymentStage,
                newStage: PaymentStage.PaymentSuccessStage,
            });
            dispatch(setPaymentStage(PaymentStage.PaymentSuccessStage));
        }
        if (bankTransferRes.errorMessage) {
            console.error(bankTransferRes.errorMessage);
            tryTrackEvent("[Payments Page Popup]: Bank Transfer Fail", {
                flow: "Payment",
                currentStage: paymentStage,
                newStage: PaymentStage.ErrorInPaymentFlowStage,
            });
            dispatch(setPaymentStage(PaymentStage.ErrorInPaymentFlowStage));
        }
    }, [bankTransferRes]);

    return (
        <div className={styles.BankTransferView}>
            <h1 className={styles.Title}>{title}</h1>
            <h2 className={styles.SubTitle}>{subTitle}</h2>
            <section className={styles.FormSection}>
                <UnicargoTextInfoContainer
                    label="Amount"
                    value={`${selectedInvoices[0].currencySign} ${
                        formatNumberWithDecimal(sumOfAmountDueToPay, 2) as string
                    }`}
                />
                <UnicargoSelectBtn2
                    options={
                        unicargoBankAccounts?.map(({ bankName, bankCountryCode }) => {
                            const flagSvg = (
                                <ReactCountryFlag
                                    countryCode={bankCountryCode}
                                    svg
                                    style={{ width: "1.25em", border: `1px solid ${UiSchemeV2.colors.greys.grey100}` }}
                                />
                            );
                            return {
                                value: bankName,
                                svg: flagSvg,
                                svgAdjacency: "start",
                                label: bankName,
                            };
                        }) || []
                    }
                    showLoader={isFetchingUnicargoBankAccounts}
                    label="Transfer to"
                    value={unicargoBankAccount?.bankName || ""}
                    onChange={(newBankName) => {
                        const newBankAccount = unicargoBankAccounts?.find(
                            (bankAccount) => bankAccount.bankName === newBankName,
                        );
                        if (newBankAccount) {
                            setUnicargoBankAccount(newBankAccount);
                        }
                    }}
                    style={{ width: "100%" }}
                />
                {unicargoBankAccount && (
                    <UnicargoKeyValueTextInfoContainer
                        value={unicargoBankAccount as Record<string, string>}
                        legend={
                            {
                                bankName: "Bank Name",
                                accountName: "Account Name",
                                accountNumber: "Account Number",
                                swiftCode: "Swift Code",
                                branch: "Branch No.",
                                iban: "IBAN No.",
                                routingNumber: "Routing Number",
                                bankAddress: "Bank Address",
                                bankCountryCode: "Bank Country Code",
                            } as Record<keyof IUnicargoBankTransferAccount, string>
                        }
                    />
                )}
                <UnicargoTextInput
                    placeholderText="Transfer number"
                    value={transferNumber}
                    onValueChange={setTransferNumber}
                />
                <UnicargoTextAreaInput placeholderText="Note" value={note} onValueChange={setNote} />
                <section className={styles.ButtonsContainer}>
                    <GenericButton
                        text="Cancel"
                        onClick={() => {
                            tryTrackEvent("[Payments Page Popup]: Bank Transfer 'Cancel' button clicked", {
                                elementName: "Cancel",
                                flow: "Payment",
                                currentStage: paymentStage,
                                newStage: PaymentStage.SelectingPaymentMethodStage,
                            });
                            dispatch(setPaymentStage(PaymentStage.SelectingPaymentMethodStage));
                        }}
                        initialBtnTypeStyle={ButtonTypeStyles.HollowStyle}
                        className={styles.CancelButton}
                    />
                    <GenericButton
                        text="Submit"
                        onClick={() => {
                            if (!unicargoBankAccount || !transferNumber) {
                                console.error("Missing data", { unicargoBankAccount, transferNumber });
                                return;
                            }
                            sendBankTransferReq({
                                customerCode,
                                clientAmountToPay: sumOfAmountDueToPay,
                                unicargoBankTransferAccount: unicargoBankAccount,
                                userBankTransferInfo: { note, transferNumber },
                                selectedInvoices: selectedInvoices.map(
                                    ({ invoiceNumber, amountDue, currencyAcronym }) => ({
                                        invoiceNumber,
                                        amountDue,
                                        currencyAcronym,
                                    }),
                                ),
                            });
                        }}
                        initialBtnTypeStyle={ButtonTypeStyles.LightGreenStyle}
                        className={styles.SubmitButton}
                        isDisabled={!transferNumber || !unicargoBankAccount || isFetchingUnicargoBankAccounts}
                    />
                </section>
            </section>
        </div>
    );
};

export default BankTransferView;
