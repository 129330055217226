import { makeStyles } from "@mui/styles";
import { theme } from "../../assets/theme/theme";
import { UiSchemeV2 } from "../../assets/data/ui";

const formUseStyles = makeStyles({
    RFQPageContainer:{
        background: UiSchemeV2.colors.greys.background,
        [theme.breakpoints.down("lg")]: {
           backgroundColor: "white",
        },
    },
    formWrapper: {
        width: "100%",
    },
    container: {
        background: "white",
        borderRadius: "8px",
        margin: "0 40px",
        height: " calc(100vh - 110px)",
        overflowY:"auto",
        position: "relative",
        zIndex: 1,
        [theme.breakpoints.down("md")]: {
            paddingTop: "64px",
            height: "fit-content",
            minHeight: "100vh",
            margin:"0",
        },
    },

    
});

export default formUseStyles;
