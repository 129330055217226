import { Fragment, ReactNode } from "react";
import { IShipmentDetails } from "../../../../../../../../models";
import { SHIPMENT_INFO_LABELS, TEXTS, toTitleCase } from "../resources";
import ReactCountryFlag from "react-country-flag";
import { UiSchemeV2 } from "../../../../../../../../assets/data/ui";
import classes from "../ShipmentTab.module.scss";
import UnicargoTooltip from "../../../../../../../UnicargoTooltip/UnicargoTooltip";

interface ShipmentInfoProps {
    shipmentDetails: IShipmentDetails;
    renderInfoItem: (labelToRender: string, data: any) => ReactNode;
    charLimit: number;
    shipmentTypeIcon: ReactNode;
}

export const ShipmentInfo: React.FC<ShipmentInfoProps> = ({
    shipmentDetails,
    renderInfoItem,
    charLimit,
    shipmentTypeIcon,
}) => {
    const { shipperName, consigneeName, fromLocation, destLocation, descriptionOfGoods } = shipmentDetails;

    const formattedShipperName = toTitleCase(shipperName ?? "...");
    const formattedConsigneeName = toTitleCase(consigneeName ?? "...");
    const formattedDescriptionOfGoods = toTitleCase(descriptionOfGoods ?? "...");

    const renderShipmentInfoItems = () =>
        Object.keys(SHIPMENT_INFO_LABELS).map((labelKey) => {
            if (!labelKey) return null;
            const labelValue = SHIPMENT_INFO_LABELS[labelKey];
            let infoItemToRender = null;
            switch (labelValue) {
                case SHIPMENT_INFO_LABELS.shipperName:
                    infoItemToRender = (
                        <Fragment key={labelKey}>
                            <div className={classes.fieldContainer}>
                                <div className={`${classes.FileInfoItemKey} ${classes.ShipperName}`}>{labelValue}</div>
                                <div className={classes.FileInfoItemValueContainer}>
                                    {fromLocation?.country && (
                                        <ReactCountryFlag
                                            countryCode={fromLocation?.country}
                                            svg
                                            style={{
                                                border: `1px solid ${UiSchemeV2.colors.greys.grey100}`,
                                                width: "1.25em",
                                            }}
                                            className={classes.countryFlag}
                                        />
                                    )}
                                    <span className={`${classes.FileInfoItemValueContent} ${classes.breakWord}`}>
                                        {formattedShipperName ?? "-"}
                                    </span>
                                </div>
                            </div>
                        </Fragment>
                    );
                    break;
                case SHIPMENT_INFO_LABELS.incoterm:
                    infoItemToRender = renderInfoItem(labelValue, shipmentDetails.incotermCode);
                    break;
                case SHIPMENT_INFO_LABELS.consignee:
                    {
                        infoItemToRender = (
                            <Fragment key={labelValue}>
                                <div className={classes.fieldContainer}>
                                    <div className={`${classes.FileInfoItemKey} ${classes.ConsigneeName}`}>
                                        {labelValue}
                                    </div>
                                    <div className={classes.FileInfoItemValueContainer}>
                                        {destLocation?.country && (
                                            <ReactCountryFlag
                                                countryCode={destLocation?.country}
                                                svg
                                                style={{
                                                    border: `1px solid ${UiSchemeV2.colors.greys.grey100}`,
                                                    width: "1.25em",
                                                }}
                                                className={classes.countryFlag}
                                            />
                                        )}
                                        <span className={`${classes.FileInfoItemValueContent} ${classes.breakWord}`}>
                                            {formattedConsigneeName ?? "-"}
                                        </span>
                                    </div>
                                </div>
                            </Fragment>
                        );
                    }
                    break;
                case SHIPMENT_INFO_LABELS.cargoInsurance:
                    if (shipmentDetails.insured === "Yes" && shipmentDetails.valueForInsurance) {
                        const insuredText = `Insured value - ${shipmentDetails.valueForInsurance}$`;
                        const shouldInsuranceHaveTooltip = insuredText.length > charLimit;
                        const insuredContent = <span className={classes.FileInfoItemValueContent}>{insuredText}</span>;
                        infoItemToRender = (
                            <Fragment key={labelValue}>
                                <div className={classes.fieldContainer}>
                                    <div className={classes.FileInfoItemKey}>{labelValue}</div>
                                    <div className={classes.FileInfoItemValueContainer}>
                                        {shouldInsuranceHaveTooltip ? (
                                            <UnicargoTooltip
                                                popperChildComponent={insuredText}
                                                child={insuredContent}
                                                childClassName="childClassName"
                                                popperClassName="popperClassName"
                                            />
                                        ) : (
                                            insuredContent
                                        )}
                                    </div>
                                </div>
                            </Fragment>
                        );
                    } else if (shipmentDetails.insured === "Yes") {
                        infoItemToRender = renderInfoItem(labelValue, "Insured");
                    } else {
                        infoItemToRender = renderInfoItem(labelValue, "Not insured");
                    }
                    break;
                // When subcategory is not available, hide the label
                case SHIPMENT_INFO_LABELS.subCategory:
                    if (shipmentDetails.subcategoryValue && shipmentDetails.subcategoryValue !== "Not Specified") {
                        infoItemToRender = renderInfoItem(labelValue, shipmentDetails.subcategoryValue);
                    }
                    break;
                case SHIPMENT_INFO_LABELS.descriptionOfGoods:
                    infoItemToRender = renderInfoItem(labelValue, formattedDescriptionOfGoods);
                    break;
            }
            return infoItemToRender;
        });

    return (
        <div className={classes.shipmentInfoContainer}>
            <p className={classes.containerTitle}>
                {shipmentTypeIcon} {TEXTS.shipmentInfo}
            </p>
            <div className={classes.shipmentInfoBoxContainer}>
                <div className={classes.FileInfoContainer}>
                    <div className={classes.FileInfoItemsContainer}>{renderShipmentInfoItems()}</div>
                </div>
            </div>
        </div>
    );
};
