import { FunctionComponent, SVGAttributes } from "react";
import styles from "./UnicargoCheckbox.module.scss";
import { ReactComponent as FilledCheckbox } from "../../assets/icons/filled-checkbox.svg";
import { ReactComponent as EmptyCheckbox } from "../../assets/icons/empty-checkbox.svg";

interface UnicargoCheckboxProps {
    isChecked?: boolean;
    className?: string;
    width?: string;
    onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    isDisabled?: boolean;
}

const UnicargoCheckbox: FunctionComponent<UnicargoCheckboxProps> = ({
    isChecked = false,
    className = "",
    width = "1.5rem",
    onClick,
    isDisabled = false,
}: UnicargoCheckboxProps) => {
    const baseClassNames = `${styles.UnicargoCheckbox} ${className} ${isDisabled ? styles.Disabled : ""}`;
    const baseStyles: SVGAttributes<SVGElement>["style"] = { width: `${width}`, height: `${width}` };
    const handleCheckboxClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.stopPropagation();
        onClick && !isDisabled && onClick(e);
    };
    return isChecked ? (
        <FilledCheckbox
            className={`${baseClassNames} ${styles.Filled}`}
            style={baseStyles}
            onClick={handleCheckboxClick}
        />
    ) : (
        <EmptyCheckbox
            className={`${baseClassNames} ${styles.Empty}`}
            style={baseStyles}
            onClick={handleCheckboxClick}
        />
    );
};

export default UnicargoCheckbox;
