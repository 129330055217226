import { FunctionComponent } from "react";
import styles from "./FilterNavbar.module.scss";
import UnicargoSelectBtn from "../UnicargoSelectBtn/UnicargoSelectBtn";
import { UnicargoSearchBar } from "../UnicargoSearchBtn/UnicargoSearchBar";
import {
    FilterTypes,
    IDateRangeFilter,
    IFilter,
    IFilters,
    InvoicesFilterKeyNames,
    SortOrder,
} from "../../models/filters/filters.interfaces";
import {
    areFiltersSameAsInitialFilters,
    getNewFiltersWithNewFilter,
    isStatusFilterOnlyUnpaid,
} from "../../helpers/services/FiltersService.service";
import UnicargoDateRangeFilter from "../UnicargoDateRangeFilter/UnicargoDateRangeFilter";
import { setSort } from "../../store/reducers/invoicesSlice.store";
import { useAppDispatch, useAppSelector } from "../../store";

export interface IFilterNavbarProps {
    filters: IFilters;
    onChange: (newFilters: IFilters) => void;
    onDefaultViewBtnClick: () => void;
    className?: string;
}
const FilterNavbar: FunctionComponent<IFilterNavbarProps> = ({
    filters,
    onChange,
    className,
    onDefaultViewBtnClick,
}: IFilterNavbarProps) => {
    const dispatch = useAppDispatch();
    const showDefaultViewBtn = !areFiltersSameAsInitialFilters(filters);
    const sort = useAppSelector((state) => state.rootReducer.invoices).sort;

    const handleFilterChange = (updatedFilter: IFilter) => {
        // If the status filter is not only "Unpaid", then set the sort order to DESC
        if (updatedFilter.keyName === InvoicesFilterKeyNames.STATUS && !isStatusFilterOnlyUnpaid(updatedFilter))
            dispatch(setSort({ ...sort, sortOrder: SortOrder.DESC }));
        const updatedFilters = getNewFiltersWithNewFilter(filters, updatedFilter);
        onChange(updatedFilters);
    };

    const renderFilters = (filters: IFilters, handleFilterChange: (updatedFilter: IFilter) => void) => {
        return filters.map((filter) => {
            if (
                filter.filterType === FilterTypes.SINGLE_CHOICE_SELECT ||
                filter.filterType === FilterTypes.MULTI_CHOICE_SELECT
            ) {
                return (
                    <UnicargoSelectBtn
                        filter={filter}
                        includeMenuTopSection={filter.includeAllOption}
                        key={filter.label}
                        onChange={handleFilterChange}
                    />
                );
            }
            if (filter.filterType === FilterTypes.SEARCH) {
                return (
                    <UnicargoSearchBar
                        searchFilter={filter}
                        onChange={handleFilterChange}
                        key={filter.label}
                        placeholder="Search by invoice # or shipment"
                        debounceTime={800}
                    />
                );
            }
            if (filter.filterType === FilterTypes.DATE_RANGE) {
                return (
                    <UnicargoDateRangeFilter
                        dateRangeFilter={filter as IDateRangeFilter}
                        onChange={handleFilterChange}
                        key={filter.label}
                    />
                );
            }
        });
    };

    const backToDefaultView = (
        <button className={styles.DefaultViewBtn} onClick={onDefaultViewBtnClick}>
            {"Default View"}
        </button>
    );

    return (
        <div className={`${styles.FilterNavbar} ${className}`}>
            <div className={styles.Filters}>{renderFilters(filters, handleFilterChange)}</div>

            {showDefaultViewBtn && backToDefaultView}
        </div>
    );
};

export default FilterNavbar;
