import { Box, Modal } from "@mui/material";
import "./PaymentPopup.scss";
import { PaymentStage } from "../../models/invoices/invoices.interfaces";
import { setPaymentStage } from "../../store/reducers/invoicesSlice.store";
import { useAppDispatch, useAppSelector } from "../../store";
import SelectingPaymentMethodStageView from "./SelectingPaymentMethodStageView/SelectingPaymentMethodStageView";
import PaymentFormPageIFrameContainerView from "./PaymentFormPageIFrameContainerView/PaymentFormPageIFrameContainerView";
import OutcomeModalView from "./OutcomeModalView/OutcomeModalView";
import BankTransferView from "./BankTransferView/BankTransferView";
import { CrossIcon } from "../../assets/icons";
import PayoneerForm from "./PayoneerForm/PayoneerForm";
import { tryTrackEvent } from "../../helpers/services/MixPanelService";
interface IPaymentPopupProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const PaymentPopup = ({ isOpen, setIsOpen }: IPaymentPopupProps) => {
    const dispatch = useAppDispatch();
    const invoicesState = useAppSelector((state) => state.rootReducer.invoices);
    const paymentStage = invoicesState.paymentStage;

    if ([PaymentStage.PaymentSuccessStage].includes(paymentStage)) {
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    }

    const handleCloseBtnClick = () => {
        tryTrackEvent(`[Payments Page Popup] 'X' button clicked from ${paymentStage}`, {
            elementName: "X",
            flow: "Payment",
            currentStage: paymentStage,
            newStage: PaymentStage.ReadingSelectedInvoicesStage,
        });
        setIsOpen(false);
        dispatch(setPaymentStage(PaymentStage.ReadingSelectedInvoicesStage));
    };

    const showPaymentPopupBasedOnStage = () => {
        switch (paymentStage) {
            case PaymentStage.ReadingSelectedInvoicesStage:
                return <div>Reading Selected Invoices...</div>;
            case PaymentStage.SelectingPaymentMethodStage:
                return <SelectingPaymentMethodStageView />;
            case PaymentStage.FillingPaymentPageStage:
            case PaymentStage.SendingPaymentQueryParamsResultStage:
                return <PaymentFormPageIFrameContainerView />;
            case PaymentStage.FillingPayoneerFormStage:
                return <PayoneerForm />;
            case PaymentStage.FillingBankTransferFormStage:
                return <BankTransferView />;
            case PaymentStage.ErrorInPaymentFlowStage:
                return <OutcomeModalView outcome="error" />;
            case PaymentStage.PaymentSuccessStage:
                return <OutcomeModalView outcome="success" />;
            default:
                return <div>Unknown Payment Stage</div>;
        }
    };

    return (
        <Modal
            className="PaymentPopupModal"
            open={isOpen}
            onClose={() => {
                if (
                    paymentStage !== PaymentStage.SendingPaymentQueryParamsResultStage &&
                    paymentStage !== PaymentStage.FillingPaymentPageStage
                ) {
                    handleCloseBtnClick();
                }
            }}
        >
            <Box className="PaymentPopupContainer">
                <div className="CloseBtnContainer">
                    <CrossIcon
                        onClick={() => {
                            if (paymentStage !== PaymentStage.SendingPaymentQueryParamsResultStage)
                                handleCloseBtnClick();
                        }}
                        width={12}
                        height={12}
                    />
                </div>
                {showPaymentPopupBasedOnStage()}
            </Box>
        </Modal>
    );
};

export default PaymentPopup;
