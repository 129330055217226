import React, { useEffect, useState } from "react";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import { CheckboxInputProps } from "../formInputProps";
import { MuiTheme } from "../../../../helpers";
import { useStyles } from "./useStyles";

export const FormInputMultiCheckbox: React.FC<CheckboxInputProps> = ({
    name,
    control,
    options,
    setValue,
    setIsTerms,
    initialValue,
}) => {
    const [selectedItems, setSelectedItems] = useState<any>(initialValue ? [initialValue] : []);

    const [isRequired, setIsRequired] = useState<boolean>(false);

    const classes = useStyles();
    const handleSelect = (value: any) => {
        const isPresent = selectedItems.indexOf(value);
        if (isPresent !== -1) {
            const remaining = selectedItems.filter((item: any) => item !== value);
            setSelectedItems(remaining);
        } else {
            setSelectedItems((prevItems: any) => [...prevItems, value]);
        }
        setIsRequired(!isRequired);
    };

    useEffect(() => {
        setValue(name, selectedItems);
        setIsTerms && setIsTerms(isRequired);
    }, [selectedItems]);

    return (
        <MuiTheme>
            <FormControl className={classes.inputContainer} size={"small"} variant={"outlined"}>
                {options.map((option: any) => {
                    const isChecked = selectedItems.includes(option.value);
                    return (
                        <FormControlLabel
                            className={classes.checkboxLabel}
                            control={
                                <Controller
                                    name={name}
                                    render={() => {
                                        return (
                                            <Checkbox
                                                checked={isChecked}
                                                style={{
                                                    padding: "9px 9px 9px 0px",
                                                }}
                                                onChange={() => handleSelect(option.value)}
                                            />
                                        );
                                    }}
                                    control={control}
                                />
                            }
                            label={option.label}
                            key={option.value}
                        />
                    );
                })}
            </FormControl>
        </MuiTheme>
    );
};
