import SkeletonLoaderItem from "../../../../../../ui-decorations/SkeletonLoader/SkeletonLoaderItem";
import styles from "./SkeletonRouteTab.module.scss";

export const SkeletonRouteTab = () => {
    return (
        <div className={styles.SkeletonRouteTabContainer}>
            <SkeletonLoaderItem className={styles.verticalLine} />
            <div className={styles.itemsContainer}>
                {Array.from(Array(8).keys()).map((_, index) => (
                    <div key={index}>
                        <SkeletonLoaderItem className={styles.header} />
                        <SkeletonLoaderItem className={styles.info} />
                    </div>
                ))}
            </div>
        </div>
    );
};
