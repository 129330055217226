import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../assets/data/ui";

export const useStyles = makeStyles({
    tabsWrapper: {
        "& .MuiTabs-flexContainer": {
            justifyContent: "space-around",
        },
        "& .MuiTabs-indicator":{
            background: `${COLORS.main} !important`,
        },
    },
    toggleButtonWrapper: {
        display: "flex",
        gap: "12px",
        width: "130px",
        "&.MuiTab-root": {
            flexDirection: "row",
            minHeight: "38px !important",
            padding: "0",
            textTransform: "none",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "17px",
            flex: "1",
            whiteSpace: "nowrap",
        },
        "&.Mui-selected": {
            fontWeight: 600,
        },
    },
    toggleIcon: {
        paddingTop: "5px",
    },
});
