import { useStyles } from "../submit-modal/useStyles";
import { Box, Modal } from "@mui/material";
import { ButtonText, TextModal } from "../submit-modal/RfqFinalModalUtil";
import { ContainedButton } from "../../../buttons";
import { ScreenWidthType } from "../../../../assets/data/ui";
import { useScreenWidthType } from "../../../../helpers/custom-hooks/useScreenWidthType";
import { RFQModalAnimation } from "../../../animations/rfq-modal/RFQModalAnimation";
interface IRfqModalProps {
    isOpen: boolean;
    setIsOpen: () => void;
}

const RfqSuccessModal = ({ isOpen, setIsOpen }: IRfqModalProps) => {
    const classes = useStyles();
    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;
    const successStyleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isMobile ? "370px" : "620px",
        minHeight: "540px",
        overflow: "hidden",
        bgcolor: "background.paper",
        borderRadius: "4px",
        boxShadow: 24,
    };

    return (
        <Modal open={isOpen} onClose={() => setIsOpen()}>
            <Box sx={successStyleModal}>
                <Box sx={{ position: "relative", minHeight: "540px" }}>
                    <Box className={classes.modalHeader} sx={{ minHeight: "540px" }}>
                        <Box className={`${classes.modalHeaderContent} ${classes.mtl}`}>
                            <span className={classes.headerTitle}> {TextModal.textHeaderSuccessFirst}</span>
                            <span className={classes.headerTitle}> {TextModal.textHeaderSuccessSecond}</span>
                        </Box>
                        <ContainedButton
                            className={`${classes.button} ${classes.noBoxShadow}`}
                            onClick={() => setIsOpen()}
                        >
                            {ButtonText.takeMeBack}
                        </ContainedButton>
                    </Box>
                    <Box className={classes.animationContainer}>
                        <RFQModalAnimation />
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default RfqSuccessModal;
