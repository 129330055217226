import { Box } from "@mui/system";
import { FormInputTextField } from "../form-elements/text-field/formInputTextField";
import { useStyles } from "./useStyles";
import { ISecondPhaseDescribeProps } from "./SecondPhaseUtils";

const SecondPhaseDescribe = ({ control, watchInsurance }: ISecondPhaseDescribeProps) => {
    const classes = useStyles();
    return (
        <Box className={classes.wrapperDescribe}>
            <FormInputTextField
                type="text"
                size="md"
                required={true}
                name="description"
                control={control}
                label={"Describe your goods"}
                maxLength={300}
            />
            {watchInsurance.includes("goodsInsurance") && (
                <Box className={classes.wrapperInsurance}>
                    <FormInputTextField
                        type="number"
                        size="md"
                        required={true}
                        name="totalValue"
                        control={control}
                        label={"Total value of goods (USD)"}
                    />
                </Box>
            )}
        </Box>
    );
};

export default SecondPhaseDescribe;
