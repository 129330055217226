import { IUserResponse } from "../../models";
import { unicargoApiSlice } from "../apiSlice";

export interface IUpdateUserQueryParams {
    email: string;
    isNewUser: boolean;
}

export interface IEmailBody {
    userId: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    subject: string;
    text: string;
    customerCode: string;
    customerName?: string;
}
export interface INotifyEmailBody {
    email: string;
}

export const customersApiSlice = unicargoApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCustomersByEmail: builder.query<IUserResponse, string>({
            query: (email: string) => `/users/${email}`,
        }),
        updateUserByEmail: builder.mutation<IUserResponse, IUpdateUserQueryParams>({
            query: ({ email, isNewUser }) => ({
                url: "/users",
                method: "PUT",
                body: {
                    email,
                    isNewUser,
                },
            }),
        }),
        sendEmail: builder.mutation<any, IEmailBody>({
            query: (body) => ({
                url: "/emails",
                method: "POST",
                body,
            }),
        }),
        sendNoCustomersNotifyMail: builder.mutation<any, INotifyEmailBody>({
            query: (body) => ({
                url: "/emails/users/missingcustomer",
                method: "POST",
                body,
            }),
        }),
    }),
    overrideExisting: false,
});
export const {
    useGetCustomersByEmailQuery,
    useUpdateUserByEmailMutation,
    useSendEmailMutation,
    useSendNoCustomersNotifyMailMutation,
} = customersApiSlice;
