import { EDetailTabs } from "./resources";
import { SkeletonDocumentTab } from "./sub-components/DocumentsTab/SkeletonDocumentTab";
import { SkeletonRouteTab } from "./sub-components/RouteTab/SkeletonRouteTab";
import { SkeletonShipmentTab } from "./sub-components/ShipmentTab/SkeletonShipmentTab";

export const renderSkeletonTab = (tab: EDetailTabs) => {
    switch (tab) {
        case EDetailTabs.route:
            return <SkeletonRouteTab />;
        case EDetailTabs.shipment:
            return <SkeletonShipmentTab />;
        case EDetailTabs.documents:
            return <SkeletonDocumentTab />;
    }
};
