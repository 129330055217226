import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    termsWrapper: {
        display: "flex",
        gap: "10px",
        flexDirection: "column",
        padding: "50px",
        margin: "0px auto",
    },
});

export default useStyles;
