import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { COLORS } from "../../../../assets/data/ui";
import { TextFieldInputProps } from "../formInputProps";
import { MuiTheme } from "../../../../helpers";
import { useStyles } from "./useStyles";

export const TextFieldContactUs = ({
    name,
    control,
    label,
    size,
    required,
    type,
    borderRadius,
    notes,
}: TextFieldInputProps) => {
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const classes = useStyles();
    return (
        <Controller
            name={name}
            rules={{ required: required }}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <MuiTheme>
                    <FormControl sx={{ width: "100%" }}>
                        <InputLabel
                            style={{
                                color: isFocus ? COLORS.primaryDark : COLORS.light,
                                fontSize: "14px",
                            }}
                            variant="filled"
                        >
                            {label && label}
                        </InputLabel>
                        <OutlinedInput
                            className={classes.root}
                            id="sandbox"
                            multiline
                            rows={notes ? 3 : 5}
                            maxRows={notes ? 3 : 5}
                            type={type}
                            style={{
                                fontSize: notes ? "14px" : "16px",
                                width: size === "md" ? "100%" : "480px",
                                height: notes ? "90px" : "160px",
                                borderRadius: borderRadius ? borderRadius : "4px",
                                padding: "16px",
                            }}
                            value={value}
                            error={!!error}
                            onChange={onChange}
                            onFocus={() => setIsFocus((prevState) => !prevState)}
                            onBlur={() => setIsFocus((prevState) => !prevState)}
                            inputProps={{
                                sx: {
                                    padding: "0 0 8px 0",
                                    margin: "10px 0 0 0 ",
                                },
                            }}
                        />
                    </FormControl>
                </MuiTheme>
            )}
        />
    );
};
