import { UiSchemeV2 } from "../../../assets/data/ui";
import { StepActiveIcon, StepCompletedIcon, StepEmptyIcon, StepFinalIcon, StepNextIcon } from "../../../assets/icons";
import { ETransportModes, IShipmentStep } from "../../../models";
import { ACTUAL_DEST_PORT_FIELDS } from "../resources";

export class StepsService {
    readonly activeStep: number;
    readonly steps: IShipmentStep[];
    readonly transportMode: ETransportModes;
    constructor(activeStep: number, steps: IShipmentStep[], transportMode: ETransportModes) {
        this.activeStep = activeStep;
        this.steps = steps;
        this.transportMode = transportMode;
    }

    // Utilities
    isFirstStep = (step: number) => step === -1;
    isNextStep = (step: number) => step === this.activeStep + 1;
    isFinalStep = (step: number) => step === this.steps.length - 1;
    isStepCompleted = (step: number) => step < this.activeStep;
    isStepActive = (step: number, isVertical = false) => step === this.activeStep;
    // this.transportMode === ETransportModes.Inland && !isVertical ? this.isFirstStep(step) : step === this.activeStep

    // Return the step icon by the step.
    getStepIcon = (index: number, isVertical = false) => {
        // The order matters!
        if (this.isFirstStep(index)) {
            // ? If transportMode = Inland - check if the next actual step is active or not (if -1 + 1 === this.activeStep).
            // ? Else - if this.activeStep === -1 -> active icon
            return this.transportMode === ETransportModes.Inland && !isVertical && index + 1 === this.activeStep
                ? StepActiveIcon
                : index == this.activeStep
                ? StepActiveIcon
                : StepCompletedIcon;
        } else if (this.isStepActive(index, isVertical)) {
            return StepActiveIcon;
        } else if (this.isFinalStep(index)) {
            return StepFinalIcon;
        } else if (this.isNextStep(index)) {
            return StepNextIcon;
        } else if (this.isStepCompleted(index)) {
            return StepCompletedIcon;
        } else {
            return StepEmptyIcon;
        }
    };

    getStepColor = (
        index: number,
        isVertical = false,
        stageColor:
            | {
                  primary: string;
                  secondary?: string | undefined;
                  trinary?: string | undefined;
              }
            | undefined,
    ) => {
        // The order matters!
        if (this.isFirstStep(index)) {
            // ? If transportMode = Inland - check if the next actual step is active or not (if -1 + 1 === this.activeStep).
            // ? Else - if this.activeStep === -1 -> active icon
            return this.transportMode === ETransportModes.Inland && !isVertical && index + 1 === this.activeStep
                ? stageColor?.trinary
                : index == this.activeStep
                ? stageColor?.trinary
                : stageColor?.secondary;
        } else if (this.isStepActive(index, isVertical)) {
            return stageColor?.trinary;
        } else if (this.isNextStep(index)) {
            return UiSchemeV2.colors.greys.grey100;
        } else if (this.isStepCompleted(index)) {
            return stageColor?.secondary;
        } else {
            return UiSchemeV2.colors.greys.grey100;
        }
    };

    isFinalCarriageStep = (currentStep: IShipmentStep, index: number) => {
        let isFinalStep = false;
        if (ACTUAL_DEST_PORT_FIELDS.includes(currentStep.stepName)) {
            const nextStep = this.steps[index + 1];
            if (nextStep.location !== "Unassigned" || nextStep.ETD || nextStep.ATD || nextStep.ETA || nextStep.ATA) {
                isFinalStep = true;
            }
        }
        return isFinalStep;
    };
}
