import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../../assets/data/ui";

export const useStyles = makeStyles({
    inputContainer: {
        height: "100%",
        color: "red",
    },
    checkboxLabel: {
        "& svg": {
            width: "18px",
            height: "18px",
        },
        "& .MuiTypography-root": {
            fontSize: "16px",
            lineHeight: "17px",
        },
        "&.MuiFormControlLabel-root": {
            marginRight: "0px",
            marginLeft: "0px",

            color: COLORS.medium,
            "& .Mui-checked": {
                color: COLORS.main,
                "& + .MuiTypography-root": {
                    color: COLORS.main,
                },
            },
        },
    },
});
