import "./NotificationCardDefault.scss";
import NotificationCardBase from "../../notification-card-base/NotificationCardBase";
import { INotificationDefault } from "../../../../../models";

const NotificationCardDefault = ({ className, onButtonClicked: onMarkClicked, ...rest }: INotificationDefault) => (
    <NotificationCardBase
        onButtonClicked={onMarkClicked}
        className={`NotificationCardDefault ${className || ""}`}
        {...rest}
    />
);

export default NotificationCardDefault;
