export const insertGoogleAnalyticsScript = () => {
    // Create the async script element
    const scriptElement = document.createElement("script");
    scriptElement.async = true;
    scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_TRACKING_ID}`;

    // Create the inline script element
    const inlineScriptElement = document.createElement("script");
    inlineScriptElement.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${process.env.REACT_APP_TRACKING_ID}');
    `;

    // Append both script elements to the head of the document
    document.head.appendChild(scriptElement);
    document.head.appendChild(inlineScriptElement);

    // Cleanup: Remove the script elements when needed
    return () => {
        document.head.removeChild(scriptElement);
        document.head.removeChild(inlineScriptElement);
    };
};


export const sendGoogleAnalyticsEvent = (event: EventObject, referrer?: string) => {
  const windowObj = window as any;
  windowObj.gtag("event", event.eventName, {
      event_category: event.category,
      event_label: event.label,
      referrer: referrer ? referrer : "Unknown referrer",
  });
};

type EventObject = {
  eventName: string;
  category: string;
  label: string;
};

export const EventMessages = {
  RFQPageViewNotLoggedIn: {
    eventName: "RFQ page viewed - non customer",
    category: "Page view",
    label: "Custom page view",
  },
  RFQPageViewLoggedIn: {
    eventName: "RFQ page viewed - customer",
    category: "Page view",
    label: "Custom page view",
  },
  GetQuoteButtonClicked: {
    eventName: "'Submit' button clicked - non customer",
    category: "Button click",
    label: "Custom button click",
  },
  SubmitButtonClicked: {
    eventName: "'Submit' button clicked - customer",
    category: "Button click",
    label: "Custom button click",
  },
};