import {
    AirplaneIcon,
    RfqSelectAirportIcon,
    RfqSelectAmazonIcon,
    RfqSelectCommercialIcon,
    RfqSelectHarborIcon,
    RfqSelectResidentialIcon,
    RfqSelectUnicargoIcon,
} from "../../../assets/icons";
import { DropdownItemtype, LocationType } from "../../../models/rfq/rfq-types";

const iconDimentions = {
    width: "20px",
    height: "20px",
};

export const HeaderTexts = {
    mainHeader: "Let's get this quote moving",
    subHeader: "Share the ״where, what and when״ of your shipment, we'll do the rest",
};

export const shipmentTypesHeaderTexts = {
    mainHeader: "Select your shipping methods (you can choose more than one)",
};

export const DestinationDropdownOptions: DropdownItemtype[] = [
    {
        value: LocationType.UnicargoWarehouse,
        icon: <RfqSelectUnicargoIcon {...iconDimentions} />,
    },
    {
        value: LocationType.AmazonWarehouse,
        icon: <RfqSelectAmazonIcon {...iconDimentions} />,
    },
    {
        value: LocationType.AirportAndPort,
        icon: <RfqSelectHarborIcon {...iconDimentions} />,
    },
    {
        value: LocationType.Port,
        icon: <RfqSelectHarborIcon {...iconDimentions} />,
    },
    {
        value: LocationType.Airport,
        icon: <RfqSelectAirportIcon {...iconDimentions} />,
    },
    {
        value: LocationType.Commercial,
        icon: <RfqSelectCommercialIcon {...iconDimentions} />,
    },
    {
        value: LocationType.Residential,
        icon: <RfqSelectResidentialIcon {...iconDimentions} />,
    },
];

export const OriginDropdownOptions: DropdownItemtype[] = [
    {
        value: LocationType.AirportAndPort,
        icon: <RfqSelectHarborIcon {...iconDimentions} />,
    },
    {
        value: LocationType.Port,
        icon: <RfqSelectHarborIcon {...iconDimentions} />,
    },
    {
        value: LocationType.Airport,
        icon: <AirplaneIcon {...iconDimentions} />,
    },
    {
        value: LocationType.Commercial,
        icon: <RfqSelectCommercialIcon {...iconDimentions} />,
    },
    {
        value: LocationType.Residential,
        icon: <RfqSelectResidentialIcon {...iconDimentions} />,
    },
    {
        value: LocationType.UnicargoWarehouse,
        icon: <RfqSelectUnicargoIcon {...iconDimentions} />,
    },
];
