import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../../assets/data/ui";
import { theme } from "../../../../assets/theme/theme";

export const useStyles = makeStyles({
    modal: {
        minWidth: "620px",
        display: "flex",
        justifyContent: "flex-start",
        [theme.breakpoints.down("lg")]: {
            minWidth: "0",
            height: "85vh",
            flexDirection: "column",
        },
    },
    modalHeader: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "32px",
        flexDirection: "column",
        [theme.breakpoints.down("lg")]:{
            padding: "18px",
        }
    },
    animationContainer: {
        position: "absolute",
        bottom: "0",
        left: "0",
        zIndex: "-1",
        transform: "scale(0.65) translate(-737px, 219px) rotate(136deg)",
        [theme.breakpoints.down("md")]: {
            transform: "scale(0.65) translate(-809px, 214px) rotate(122deg)"
        },
    },
    modalHeaderContent: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    mtl: {
        marginTop: "40px"
    },
    mtm: {
        marginTop: "24px"
    },
    mbm: {
        marginBottom: "24px"
    },
    headerTitle: {
        textAlign: "center",
        fontSize: "24px",
        fontWeight: 700,
    },
    headerSubTitle: {
        textAlign: "center",
        fontSize: "18px",
        fontWeight: 600,
    },
    shippedRadioText: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "17px",
        color: COLORS.main,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyItems: "center",
        alignItems: "center",
        gap: "20px",
        margin: "20px auto",
        [theme.breakpoints.down("lg")]:{
            gap: "18px",
            margin: "12px auto",
        }
    },
    shipedRadioWrapper: {
        [theme.breakpoints.down("sm")]: {
            minWidth: "100%",
            "& fieldset > [class*='radioButton']": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "& > *": {
                    flexBasis: "calc(50% - 10px)"
                }
            }
        },
    },
    helpTypeContent: {
        [theme.breakpoints.up("lg")]: {
            width: "360px",
            display: "grid",
            gap: "20px",
            gridTemplateColumns: "repeat(2, 1fr)"
        },
        [theme.breakpoints.down("lg")]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            overflow: "auto",
            gap: "12px",
            width: "100%",

        }
    },
    chip: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "17px",
        color: COLORS.medium,
    },
    couponCode: {
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "17px",
        color: `${COLORS.main}`,
        textDecoration: "underline",
        textUnderlineOffset: "2px",
        paddingInlineStart: "2px",
        cursor: "pointer",
    },
    couponContent: {
        marginTop: "10px",
    },
    couponContainer: {
        marginTop: "20px",
        marginBottom: "20px",
    },
    inputWrapper: {
        width: "239px",
    },
    button: {
        minWidth: "80px",
        borderRadius: "32px",
        alignSelf: "center",
        backgroundColor: COLORS.success,
        color: COLORS.primaryDark,
        marginTop: "auto !important",
        "&:hover": {
            backgroundColor: COLORS.success,
        },
    },
    noBoxShadow: {
        boxShadow: "none !important"
    },
});
