import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ICustomer } from "../../models";
import { getStorageItem, removeStorageItem, setStorageItem } from "../../helpers/services";
import { Auth } from "aws-amplify";
import { SESSION_STORAGE } from "../../assets/data/resources";
import { registerSuperPropertiesForMixpanel } from "../../helpers/services/MixPanelService";

const initialState: ICustomer = {
    email: "",
    sub: "",
    token: "",
    code: "",
    name: "",
    customerName: "",
    isNewUser: false,
};

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        userLoggedIn(state, action: PayloadAction<ICustomer>) {
            const { email, sub, token, code: customerCode, name, isNewUser, customerName } = action.payload;
            state.email = email;
            state.sub = sub;
            state.token = token;
            state.code = customerCode;
            state.name = name;
            state.isNewUser = isNewUser;
            state.customerName = customerName;

            // Double check the local storage vars each time a user is logging in
            if (!customerCode) {
                const userStorageData = getStorageItem<ICustomer>(SESSION_STORAGE.customerData);
                if (userStorageData !== null) {
                    state.code = userStorageData.code;
                    state.name = userStorageData.name;
                    state.isNewUser = userStorageData.isNewUser;
                    state.customerName = userStorageData.customerName;
                }
            }
        },
        updateIsNewUser(state) {
            state.isNewUser = false;
            setStorageItem(SESSION_STORAGE.customerData, {
                code: state.code,
                name: state.name,
                isNewUser: false,
                customerName: state.customerName,
            });
        },
        userLogOut(state) {
            // reset initial state
            Auth.signOut(); // Sign out from AWS cognito
            removeStorageItem(SESSION_STORAGE.customerData);
            state.email = "";
            state.sub = "";
            state.token = "";
            state.code = "";
            state.name = "";
            state.customerName = "";
            state.isNewUser = false;
            registerSuperPropertiesForMixpanel({ is_user_logged_in: false });
        },
        setCurrentCustomer(state, action: PayloadAction<ICustomer>) {
            const { code, name, isNewUser, customerName } = action.payload;
            state.code = code;
            state.name = name;
            state.isNewUser = isNewUser;
            state.customerName = customerName;
            setStorageItem(SESSION_STORAGE.customerData, {
                code,
                name,
                isNewUser,
                customerName,
            });
        },
    },
});

// Export actions and reducer.
export const { userLoggedIn, userLogOut, setCurrentCustomer, updateIsNewUser } = usersSlice.actions;
export default usersSlice.reducer;
