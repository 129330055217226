import { useEffect, useState } from "react";
import { CreditCardIcon, BankIcon } from "../../../assets/icons";
import GenericButton, { ButtonTypeStyles, ButtonTypes } from "../../buttons/GenericButton/GenericButton";
import { IBasicInvoice, PaymentMethod, PaymentStage } from "../../../models/invoices/invoices.interfaces";
import { useLazyGetPaymentPageURIQuery } from "../../../store/api-slices/paymentSlice.api";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setPaymentFormPageUri, setPaymentStage } from "../../../store/reducers/invoicesSlice.store";
import PayoneerLogo from "../../../assets/images/payoneer.png";
import { YaadSarigCurrency } from "../../../models/invoices/payments.interfaces";
import styles from "./SelectingPaymentMethodStageView.module.scss";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";
const SelectingPaymentMethodStageView = () => {
    const dispatch = useAppDispatch();
    const invoicesState = useAppSelector((state) => state.rootReducer.invoices);
    const paymentStage = invoicesState.paymentStage;
    const [chosenPaymentMethod, setChosenPaymentMethod] = useState<PaymentMethod | undefined>();
    const [triggerGetPaymentPageReq, { data: getPaymentPageData, isFetching }] = useLazyGetPaymentPageURIQuery();
    const customerCode = useAppSelector((state) => state.rootReducer.users.code);

    const selectedInvoices = invoicesState.selectedInvoices;
    const invoicesSum = selectedInvoices.reduce((acc, curr) => acc + curr.amountDue, 0);

    useEffect(() => {
        if (getPaymentPageData) {
            const { paymentPageURI, errorMessage } = getPaymentPageData;
            if (!paymentPageURI || errorMessage) {
                console.error("error", errorMessage ?? "Failed to get payment page URI");
                tryTrackEvent("[Payments Page Popup]: Credit Card Fail", {
                    flow: "Payment",
                    currentStage: paymentStage,
                    newStage: PaymentStage.ErrorInPaymentFlowStage,
                });
                dispatch(setPaymentStage(PaymentStage.ErrorInPaymentFlowStage));
                return;
            }
            dispatch(setPaymentStage(PaymentStage.FillingPaymentPageStage));
            dispatch(setPaymentFormPageUri(paymentPageURI));
        }
    }, [getPaymentPageData, isFetching]);

    const handlePaymentMethodClick = (paymentMethod: PaymentMethod) => {
        setChosenPaymentMethod(paymentMethod);
    };

    const handleNextButtonClick = () => {
        if (!chosenPaymentMethod) return;
        switch (chosenPaymentMethod) {
            case PaymentMethod.CreditCard: {
                tryTrackEvent("[Payments Page Popup]: Payment method selected - Credit Card", {
                    flow: "Payment",
                    currentStage: paymentStage,
                    newStage: PaymentStage.FillingPaymentPageStage,
                    paymentMethod: chosenPaymentMethod,
                });
                const basicSelectedInvoices: IBasicInvoice[] = invoicesState.selectedInvoices.map(
                    ({ invoiceNumber, amountDue, currencyAcronym }) => ({
                        invoiceNumber,
                        amountDue,
                        currencyAcronym,
                    }),
                );
                triggerGetPaymentPageReq({
                    customerCode,
                    selectedInvoices: basicSelectedInvoices,
                    clientAmountToPay: invoicesSum,
                });
                return;
            }
            case PaymentMethod.BankTransfer:
                tryTrackEvent("[Payments Page Popup]: Payment method selected - Bank Transfer", {
                    flow: "Payment",
                    currentStage: paymentStage,
                    newStage: PaymentStage.FillingBankTransferFormStage,
                    paymentMethod: chosenPaymentMethod,
                });
                dispatch(setPaymentStage(PaymentStage.FillingBankTransferFormStage));
                return;
            case PaymentMethod.Payoneer: {
                tryTrackEvent("[Payments Page Popup]: Payment method selected - Payoneer", {
                    flow: "Payment",
                    currentStage: paymentStage,
                    newStage: PaymentStage.FillingPayoneerFormStage,
                    paymentMethod: chosenPaymentMethod,
                });
                dispatch(setPaymentStage(PaymentStage.FillingPayoneerFormStage));
                return;
            }
            default:
                console.error("Unknown payment method");
                return;
        }
    };

    return (
        <div className={styles.SelectingPaymentMethodStageView}>
            <div className="SummaryDescriptionTitle">How would you like to pay?</div>
            <div className="PaymentMethodsButtonsContainer">
                <GenericButton
                    className={"CreditCardOption"}
                    text={"Credit card"}
                    width={"140px"}
                    onClick={() => handlePaymentMethodClick(PaymentMethod.CreditCard)}
                    leftSvgToDisplay={CreditCardIcon}
                    initialBtnTypeStyle={ButtonTypeStyles.HollowStyle}
                    btnType={ButtonTypes.ToggleBtn}
                    isSelected={chosenPaymentMethod === PaymentMethod.CreditCard}
                    isDisabled={
                        !Object.keys(YaadSarigCurrency)
                            .filter((key) => isNaN(Number(key)))
                            .includes(selectedInvoices[0].currencyAcronym)
                    }
                />
                <GenericButton
                    className={"BankTransferOption"}
                    text={"Bank transfer"}
                    width={"140px"}
                    onClick={() => handlePaymentMethodClick(PaymentMethod.BankTransfer)}
                    leftSvgToDisplay={BankIcon}
                    initialBtnTypeStyle={ButtonTypeStyles.HollowStyle}
                    btnType={ButtonTypes.ToggleBtn}
                    isSelected={chosenPaymentMethod === PaymentMethod.BankTransfer}
                />
                <GenericButton
                    className={"PayoneerOption"}
                    text={"Payoneer"}
                    width={"140px"}
                    onClick={() => handlePaymentMethodClick(PaymentMethod.Payoneer)}
                    leftImageElementToDisplay={<img src={PayoneerLogo} alt="Payoneer Logo" width={20} />}
                    btnType={ButtonTypes.ToggleBtn}
                    initialBtnTypeStyle={ButtonTypeStyles.HollowStyle}
                    isSelected={chosenPaymentMethod === PaymentMethod.Payoneer}
                />
            </div>
            <GenericButton
                initialBtnTypeStyle={ButtonTypeStyles.LightGreenStyle}
                onClick={handleNextButtonClick}
                text={"Next"}
                isDisabled={!chosenPaymentMethod || invoicesSum <= 0}
            />
        </div>
    );
};

export default SelectingPaymentMethodStageView;
