import { AirplaneIcon, ShipIcon, TruckIcon } from "../../../assets/icons";

export const STRINGS = {
    SHIPMENT_STATUS: "Shipment status",
    SHIPMENT_TYPE: "Shipment type",
    APPLY_FILTERS: "Apply filters",
    RESET_ALL: "Reset all",
    SELECT_ORIGIN: "Origin location",
    SELECT_DESTINATION: "Destination location",
    popupTitle: "Filter",
};
export const SHIPMENT_TYPES = [
    { type: "Air", icon: AirplaneIcon },
    { type: "Inland", icon: TruckIcon },
    { type: "Ocean", icon: ShipIcon },
];
