import { COLORS } from "../../../assets/data/ui";

export interface IExclamtionInfoProps {
    text: string;
    style?: string;
}
export const toolTipStyle = {
    "& .MuiTooltip-tooltip": {
        backgroundColor: COLORS.white,
        color: COLORS.main,
        boxShadow: " 0px 0px 7px rgba(0, 0, 0, 0.24)",
        maxWidth: "253px",
        fontSize: "14px",
        lineHeight: "17px",
        fontWeight: "400",
        whiteSpace: "break-spaces",
        margin: "0 auto",
        padding: "16px 24px 16px 16px",
        fontFamily: "Lato-Regular",
        marginBottom: "0px !important",
        right: "8px",
    },
    "& .MuiTooltip-arrow": {
        color: COLORS.white,
    },
};
