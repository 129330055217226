export const warehouseData = [
    {
        WHCode: "LA10",
        City: "Long Beach",
        State: "California",
        Country: "United States",
    },
    {
        WHCode: "NJ7",
        City: "Freehold",
        State: "New Jersey",
        Country: "United States",
    },
    {
        WHCode: "DE",
        City: "Meiningen",
        State: "Thuringen",
        Country: "Germany",
    },
    {
        WHCode: "PA1",
        City: "Allentown",
        State: "Pennsylvania",
        Country: "United States",
    },
    {
        WHCode: "CAN1",
        City: "Mississauga",
        State: "Ontario",
        Country: "Canada",
    },
    {
        WHCode: "CN3",
        City: "Qingdao",
        State: "Shandong",
        Country: "China",
    },
    {
        WHCode: "CN4",
        City: "Shenzhen",
        State: "Guangdong",
        Country: "China",
    },
    {
        WHCode: "CN5",
        City: "Ningbo",
        State: "Zhejiang",
        Country: "China",
    },
    {
        WHCode: "IND1",
        City: "Mumbai",
        State: "Maharashtra",
        Country: "India",
    },
    {
        WHCode: "UK5",
        City: "Harlow",
        State: "Essex",
        Country: "United Kingdom",
    },
    {
        WHCode: "NJ6",
        City: "Eatontown",
        State: "New Jersey",
        Country: "United States",
    },
    {
        WHCode: "TX-R1",
        City: "Carrollton",
        State: "Texas",
        Country: "United States",
    },
    {
        WHCode: "UK-R4",
        City: "Heywood",
        State: "Greater Manchester",
        Country: "United Kingdom",
    },
];
