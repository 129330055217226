import { useLocation, Navigate } from "react-router-dom";
import { SESSION_STORAGE } from "../assets/data/resources";
import { getStorageItem } from "../helpers/services";

export const SelectCustomerGuard = ({ children }: any) => {
    const location = useLocation();
    // TODO: Find a way to sync the storage values and the authenticator cookies.
    if (getStorageItem(SESSION_STORAGE.customerData)) {
        return <Navigate to={"/overview"} state={{ from: location }} replace />;
    }
    return children;
};
