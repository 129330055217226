import "./ShipmentsBlock.scss";
import Block from "../block/Block";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Fragment, useCallback, useEffect, useState } from "react";
import { TextButton } from "../../buttons";
import { useNavigate } from "react-router-dom";
import { useGetShipmentsByCustomerCodeQuery } from "../../../store/api-slices/shipmentsSlice.api";
import { getShipmentCard } from "../../cards/shipment-cards/shared/services/shipment-cards.service";
import { ETransportModes, IShipment } from "../../../models";
import { ShipmentCardMobile } from "../../cards/shipment-cards/mobile-card/ShipmentCardMobile";
import { ShipmentsDashboardEmptyState } from "../../animations";
import { SwipeableDrawer, Dialog } from "@mui/material";
import ShipmentDetailsBlock from "../shipment-details/ShipmentDetailsBlock";
import { getShipmentCurrentStage } from "../../../helpers/services/ShipmentService";
import { NameShipmentModal } from "./NameShipmentModal";
import { formatNumberWithDecimal } from "../../../helpers/services/DateFormatsService.service";
import { ScreenWidthType } from "../../../assets/data/ui";
import { useScreenWidthType } from "../../../helpers/custom-hooks/useScreenWidthType";
import SkeletonLoaderItem from "../../ui-decorations/SkeletonLoader/SkeletonLoaderItem";
import ShipmentLoaderCard from "./ShipmentLoaderCard";
import { userLogOut } from "../../../store/reducers/usersSlice.store";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";

interface IShipmentBlockProps {
    isShowModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onFinishedLoading: () => void;
    shouldShowLoader: boolean;
}

const renderLoader = (
    <div className="LoaderContainer">
        <div className="shipments-block-header-container">
            <SkeletonLoaderItem className="HeaderLoader" />
        </div>
        <div className="shipments-block-body-container">
            <ShipmentLoaderCard />
            <ShipmentLoaderCard />
            <ShipmentLoaderCard />
        </div>
        <div className="shipments-block-footer-container">
            <SkeletonLoaderItem className="FooterLoader" />
        </div>
    </div>
);

const ShipmentsBlock = ({ isShowModal, setShowModal, onFinishedLoading, shouldShowLoader }: IShipmentBlockProps) => {
    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;
    const navigate = useNavigate();
    const userState = useAppSelector((state) => state.rootReducer.users);
    const dispatch = useAppDispatch();
    const { data, error, isError, isFetching, isLoading, isSuccess, refetch } = useGetShipmentsByCustomerCodeQuery({
        customerCode: userState.code,
        params: { status: ["destination", "inTransit", "origin"] },
    });
    const [countOfShipments, setCountOfShipments] = useState<number>(0);
    const [activeShipments, setActiveShipments] = useState<IShipment[]>([]);
    const [selectedShipment, setSelectedShipment] = useState<IShipment>();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [localLoading, setLocalLoading] = useState(true);

    // Navigate to /shipments page when button is clicked.
    const handleViewShipmentsClicked = () => {
        tryTrackEvent("[Overview page]: 'View All Shipments' link clicked");
        navigate("/shipments");
    };

    useEffect(() => {
        refetch();
    }, [userState.code]);

    useEffect(() => {
        if (isError && error) {
            if ("status" in error && error.status === 403) {
                dispatch(userLogOut());
            }
        }
        if (!isFetching && !isLoading) {
            onFinishedLoading();
            setLocalLoading(false);
        } else {
            setLocalLoading(true);
        }
    }, [isLoading, isFetching, isError, userState.code]);

    useEffect(() => {
        if (isSuccess && data && data.shipments) {
            setActiveShipments(data.shipments.slice(0, 10)); // Show only 10 shipments
            if (data.shipments.length > 10) {
                setCountOfShipments(10);
            } else {
                setCountOfShipments(data.shipments.length);
            }
        }
    }, [isSuccess, data]);

    const handleCardClicked = (shipment: IShipment): void => {
        setIsDrawerOpen(true);
        setSelectedShipment(shipment);
    };

    const handleDataChanged = (shipmentNumber: string) => {
        const shipments = activeShipments.map((shipment) => {
            if (shipment.shipmentDetails.shipmentNumber === shipmentNumber) {
                const changedShipment = structuredClone(shipment);
                setSelectedShipment(changedShipment);
                return changedShipment;
            } else {
                return shipment;
            }
        });
        setActiveShipments(shipments);
    };

    // Load the relevant cards / empty state.
    const renderBody = useCallback(() => {
        let shipments: React.ReactNode[] = [];
        if (isSuccess) {
            if (!isMobile) {
                if (activeShipments?.length > 0) {
                    shipments = activeShipments.map((shipment) => {
                        const currentStage = getShipmentCurrentStage(
                            shipment.steps,
                            ETransportModes[shipment.shipmentDetails.transportMode],
                            shipment.postDelivery.PODRecievedDate,
                            shipment.shipmentDetails.mainCarriageATA,
                            shipment.shipmentDetails,
                        );
                        return getShipmentCard(currentStage, {
                            shipmentData: shipment,
                            handleCardClicked,
                        });
                    });
                }
            } else {
                shipments = activeShipments?.map((shipment) => {
                    return (
                        <ShipmentCardMobile
                            key={shipment.shipmentDetails.shipmentNumber}
                            handleCardClicked={handleCardClicked}
                            shipmentData={shipment}
                        />
                    );
                });
            }
        }

        if (shipments && shipments.length > 0) {
            return (
                <>
                    <div className={`shipments-block-body-container${isMobile ? " mobile" : ""}`}>{shipments}</div>
                    <div className="shipments-block-footer-container">
                        <TextButton className="footer-container_button" onClick={() => handleViewShipmentsClicked()}>
                            {"View All Shipments >"}
                        </TextButton>
                    </div>
                </>
            );
        }
        // Empty state
        return isMobile ? (
            // <div>No active shipments</div> // TODO: what's the empty state for the mobile?
            <></>
        ) : (
            <ShipmentsDashboardEmptyState />
        );
    }, [activeShipments, selectedShipment, isMobile, handleCardClicked, localLoading]);

    return (
        <Block className="ShipmentsBlock desktop">
            {shouldShowLoader || localLoading ? (
                renderLoader
            ) : isError ? (
                <div className="ShipmentsLoadingErrorText">Can't load the shipments right now, Sorry!</div>
            ) : (
                <Fragment>
                    <div id="shipments-block-header-container" className="shipments-block-header-container">
                        <h2
                            className={`header-container_message ${
                                isShowModal && !isMobile && activeShipments.length && "new-shipment-modal"
                            }`}
                        >
                            {isSuccess && "Active Shipments"}
                        </h2>
                        <div className="sub-header-shipments">
                            {isSuccess && `${formatNumberWithDecimal(countOfShipments)} Recently Updated `}
                        </div>
                        <div className="header-container_toggler">{/* // TODO: add toggler */}</div>
                        {activeShipments.length > 0 && (
                            <Dialog
                                className="dialog"
                                open={isShowModal && !isMobile}
                                sx={{
                                    "& .MuiPaper-root": {
                                        position: "absolute",
                                        left: "170px",
                                    },
                                    "&.MuiDialog-root": {
                                        top: "unset",
                                        bottom: "unset",
                                        left: "unset",
                                        right: "unset",
                                        height: "100%",
                                        width: "100%",
                                    },
                                }}
                                container={() => document.getElementById("shipments-block-header-container")}
                            >
                                <NameShipmentModal closeModal={setShowModal} />
                            </Dialog>
                        )}
                    </div>
                    {renderBody()}
                    <SwipeableDrawer
                        onClose={() => setIsDrawerOpen(false)}
                        onOpen={() => setIsDrawerOpen(true)}
                        open={isDrawerOpen}
                        anchor="right"
                        className={`drawer-container${isMobile ? " mobile" : ""}`}
                    >
                        {selectedShipment && !shouldShowLoader && (
                            <ShipmentDetailsBlock
                                shipment={selectedShipment}
                                handleDataChange={handleDataChanged}
                                handleCloseClicked={() => setIsDrawerOpen(false)}
                                originPage="Overview Page"
                            />
                        )}
                    </SwipeableDrawer>
                </Fragment>
            )}
        </Block>
    );
};

export default ShipmentsBlock;
