import { makeStyles } from "@mui/styles";
import { COLORS, UiSchemeV2 } from "../../../assets/data/ui";
import "../../../assets/styles/v2-styles/UiScheme.module.scss";


const useStyles = makeStyles({

    EmptyActiveShipmentsContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        zIndex: UiSchemeV2.zIndices.zIndexBackgroundImage,
    },

    EmptyActiveShipmentsContent: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },

    EmptyStateGrayLinesLeft: {
        width: "100%",
        transform: "translate(100px, -100px)",
    },

    EmptyActiveShipmentsCenter: {
       
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
       
    },

    NoActiveShipmentsMessage: {
        paddingTop: "10px",
       
    },

    GetQuoteButton: {
        cursor: "pointer",
        padding: "0.5rem 1.5rem !important",
        backgroundColor: COLORS.success,
        color: COLORS.primaryDark,
        borderRadius: "36px !important",
        border: "none",
        "&:hover": {
            cursor: "pointer",
            color: "white",
        },
    },

    EmptyStateGrayLinesRight: {
        width: "100%",
    },
});

export default useStyles;
