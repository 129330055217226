import { useMemo } from "react";
import { PackageIcon } from "../../../../../assets/icons";
import { getShipmentCurrentStage, getPartialSteps } from "../../../../../helpers/services/ShipmentService";
import { ETransportModes, IShipmentCard, IShipmentLocation } from "../../../../../models";
import { Block } from "../../../../blocks";
import { HorizontalStepper } from "../../../..";
import { getShipmentCardIcon, stringToUpperCases, roundNumber } from "../../shared/services/shipment-cards.service";
import "./ShipmentCardDashboardBase.scss";
import { formatNumberWithDecimal } from "../../../../../helpers/services/DateFormatsService.service";
import { tryTrackEvent } from "../../../../../helpers/services/MixPanelService";

/**
 * The basic shipment card, without additional styling.
 */
const ShipmentCardDashboardBase = ({ className, shipmentData, handleCardClicked }: IShipmentCard) => {
    const { packageDetails, preDelivery, shipmentDetails, postDelivery, steps } = shipmentData;
    const {
        transportMode, // (Inland | Air | Ocean)
        shipmentNumber,
        shipmentName,
        shipmentLoad,
        mainCarriageATA,
        mainCarriageFinalATA,
        c_customerReferenceID,
        moveTypeEnglishName,
        packageQuantityType,
        amazonReferenceID,
        amazonShipmentID,
        quoteNumber,
    } = shipmentDetails;
    const { totalVolumInCBM, grossWeightInKG, numberOfPackages } = packageDetails;
    const { purchaseOrderNumber } = preDelivery;
    const { PODRecievedDate } = postDelivery;
    const renderShipmentStage = useMemo(() => {
        return getShipmentCurrentStage(
            steps,
            ETransportModes[transportMode],
            PODRecievedDate,
            mainCarriageATA,
            shipmentDetails,
        );
    }, [steps, transportMode, PODRecievedDate]);
    const [seperatedSteps, activeStep] = getPartialSteps(steps, mainCarriageFinalATA, transportMode, moveTypeEnglishName);
    const shipmentIcon = getShipmentCardIcon(ETransportModes[transportMode]);
    const stage = getShipmentCurrentStage(
        steps,
        ETransportModes[transportMode],
        PODRecievedDate,
        mainCarriageATA,
        shipmentDetails,
    );

    const firstAvailableReference =
        c_customerReferenceID || purchaseOrderNumber || amazonShipmentID || amazonReferenceID || quoteNumber;

    const handleOnClick = () => {
        handleCardClicked(shipmentData);
        tryTrackEvent("[Overview Page]: Shipment card clicked (to reach drawer)");
    };
    const memoizedFromLocation = useMemo<IShipmentLocation>(() => {
        return {
            city: stringToUpperCases(shipmentDetails.fromLocation?.city),
            country: shipmentDetails.fromLocation?.country,
        };
    }, [shipmentData]);

    const memoizedDestLocation = useMemo<IShipmentLocation>(() => {
        return {
            city: stringToUpperCases(shipmentDetails.destLocation?.city),
            country: shipmentDetails.destLocation?.country,
        };
    }, [shipmentData]);

    const renderTitleContainer = useMemo(() => {
        return (
            <div className="start-container__title-container">
                <span className="title-dot"></span>
                {renderShipmentStage || transportMode}
            </div>
        );
    }, [renderShipmentStage, transportMode]);

    const renderShipmentNameContainer = useMemo(() => {
        return (
            <div className="start-container__shipment-name-container">
                <div className="shipment-name-container__icon">{shipmentIcon}</div>
                <div className="shipment-name-container__shipment-name">{`${shipmentNumber}`}</div>
                <div className="content_divider"></div>
                <div className="shipment-name-container__shipment-load">{`${shipmentLoad}`}</div>
            </div>
        );
    }, [shipmentName, shipmentNumber]);

    const renderCodesContainer = useMemo(() => {
        return (
            <div className="start-container__codes-container">
                <div className="codes-container__content">
                    {firstAvailableReference && (
                        <div className="content__shipment-number">Reference {firstAvailableReference}</div>
                    )}
                    {/* {purchaseOrderNumber && (
                        <>
                            <div className="content_divider"></div>
                            <div className="content__purchase-order">
                                {purchaseOrderNumber.split("/").map((value, index) => (
                                    <div key={index}>{value}</div>
                                ))}
                            </div>
                        </>
                    )} */}
                </div>
            </div>
        );
    }, [shipmentNumber, purchaseOrderNumber]);

    const renderVolumesContainer = useMemo(() => {
        let shouldDisplayDivider = false;

        if (
            (shipmentLoad === "FCL" && packageQuantityType) ||
            (shipmentLoad !== "FCL" && numberOfPackages && numberOfPackages > 0)
        ) {
            shouldDisplayDivider = true;
        }

        return (
            (numberOfPackages || grossWeightInKG || totalVolumInCBM) && (
                <div className="start-container__volumes-container">
                    <div className="volumes-container__icon">
                        <PackageIcon />
                    </div>
                    <div className="volumes-container__content">
                        {shipmentLoad === "FCL" && packageQuantityType && (
                            <div className="content__packages">{packageQuantityType}</div>
                        )}
                        {shipmentLoad !== "FCL" && numberOfPackages && numberOfPackages > 0 ? (
                            <div className="content__packages">
                                {`${formatNumberWithDecimal(numberOfPackages)} Packages`}
                            </div>
                        ) : null}
                        {grossWeightInKG && roundNumber(grossWeightInKG) > 0 ? (
                            <div className="content__gross-weight">
                                {shouldDisplayDivider ? <div className="content_divider"></div> : null}
                                {`${formatNumberWithDecimal(roundNumber(grossWeightInKG), 1)} KG`}
                            </div>
                        ) : null}
                        {shipmentLoad !== "FCL" && totalVolumInCBM && roundNumber(totalVolumInCBM) > 0 ? (
                            <div className="content__cbm-volume">
                                {shouldDisplayDivider || grossWeightInKG ? (
                                    <div className="content_divider"></div>
                                ) : null}
                                {`${roundNumber(totalVolumInCBM, 2)} CBM`}
                            </div>
                        ) : null}
                    </div>
                </div>
            )
        );
    }, [numberOfPackages, grossWeightInKG, totalVolumInCBM]);

    return (
        <Block onClick={() => handleOnClick()} className={`ShipmentCardDashboardBase ${className || ""}`}>
            <div className="start-container">
                {renderTitleContainer}
                {renderShipmentNameContainer}
                {renderCodesContainer}
                {renderVolumesContainer}
            </div>
            <div className="end-container">
                <HorizontalStepper
                    stage={stage}
                    steps={seperatedSteps}
                    stepsBeforeChanges={steps}
                    activeStep={activeStep}
                    fromLocation={memoizedFromLocation}
                    destLocation={memoizedDestLocation}
                    transportMode={ETransportModes[transportMode]}
                    shipmentDetails={shipmentDetails}
                />
            </div>
        </Block>
    );
};

export default ShipmentCardDashboardBase;
