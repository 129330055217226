import { FunctionComponent } from "react";
import SkeletonLoaderItem from "../../ui-decorations/SkeletonLoader/SkeletonLoaderItem";
import styles from "./NotificationsBlock.module.scss";

const NotificationLoaderCard: FunctionComponent= () => {
    return ( 
        <div className={styles.NotificationCardLoadersContainer}>
            <div className={styles.NotificationCardLoader}>
                <div className={styles.LeftNotificationCardContainer}>
                    <SkeletonLoaderItem className={styles.Square} />
                    <SkeletonLoaderItem className={styles.LineTop} />
                    <SkeletonLoaderItem className={styles.LineBottom} />
                </div>
                <div className={styles.RightNotificationCardContainer}>
                    <SkeletonLoaderItem predefinedStyle="Circle" className={styles.Circle} />
                    <div className={styles.LineTopContainer}>
                        <SkeletonLoaderItem className={styles.LineTop} />
                    </div>
                    <SkeletonLoaderItem className={styles.LineBottom} />
                </div>
            </div>
        </div> );
};
 
export default NotificationLoaderCard;
