import { FunctionComponent, ReactNode, useRef, useState } from "react";
import styles from "./UnicargoSelectBtn2.module.scss";
import { ArrowDownIconStiff as ArrowIcon } from "../../assets/icons";
import { IconButton } from "@mui/material";
import { useOutsideClick } from "../../helpers/custom-hooks/useOutsideClick";
import SpinnerLoader from "../ui-decorations/SpinnerLoader/SpinnerLoader";
import { AllOrNone } from "../../models/invoices/payments.dto";

interface UnicargoSelectBtn2Props {
    value: string;
    label: string;
    showLoader?: boolean;
    options: ({
        value: string;
        label: string;
    } & AllOrNone<{
        svg: ReactNode;
        svgAdjacency: "start" | "end";
    }>)[];
    onChange: (newValue: string) => void;
    style?: React.CSSProperties;
}

const UnicargoSelectBtn2: FunctionComponent<UnicargoSelectBtn2Props> = ({
    value,
    label,
    options,
    onChange,
    style,
    showLoader = false,
}: UnicargoSelectBtn2Props) => {
    const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
    const UnicargoSelectBtnRef = useRef<HTMLDivElement>(null);

    const searchLoader = (
        <div style={{ display: "flex", alignItems: "center", gap: "0.75rem" }}>
            <SpinnerLoader size="2rem" />
            <span>Loading...</span>
        </div>
    );

    useOutsideClick(UnicargoSelectBtnRef, () => {
        setIsOptionsMenuOpen(false);
    });

    const handleSelectClick = () => {
        setIsOptionsMenuOpen(!isOptionsMenuOpen);
    };

    const handleOptionClick = (index: number) => {
        onChange(options[index].value);
        setIsOptionsMenuOpen(false);
    };

    const renderOptionsMenu = () => {
        return (
            <div className={`${styles.OptionsMenu} ${isOptionsMenuOpen && styles.Opened}`}>
                {showLoader
                    ? searchLoader
                    : options.map((option, index) => (
                          <div
                              className={`${styles.OptionContainer} ${styles.SingleChoiceSelect}`}
                              key={index}
                              onClick={() => handleOptionClick(index)}
                          >
                              {option.svg && option.svgAdjacency === "start" && (
                                  <div className={styles.OptionSvgContainer}>{option.svg}</div>
                              )}
                              <div className={styles.OptionLabel}>{option.label}</div>
                              {option.svg && option.svgAdjacency === "end" && (
                                  <div className={styles.OptionSvgContainer}>{option.svg}</div>
                              )}
                          </div>
                      ))}
            </div>
        );
    };

    return (
        <div className={styles.UnicargoSelectBtn2} ref={UnicargoSelectBtnRef} style={style}>
            <div className={styles.SelectContainer} onClick={handleSelectClick}>
                <div className={styles.SelectContentContainer}>
                    <div className={styles.SelectLabel}>{label}</div>
                    <div className={styles.SelectValue}>{value}</div>
                </div>
                <IconButton
                    className={styles.ArrowIconContainer}
                    aria-label="expand row"
                    size="small"
                    onClick={handleSelectClick}
                >
                    {
                        <ArrowIcon
                            className={`${styles.ArrowIcon} ${isOptionsMenuOpen ? styles.Opened : styles.Closed}`}
                        />
                    }
                </IconButton>
            </div>
            {renderOptionsMenu()}
        </div>
    );
};

export default UnicargoSelectBtn2;
