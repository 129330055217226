import { Box } from "@mui/material";
import { terms } from "./termsHTML";
import useStyles from "./useStyle";

export default function TermsAndConditions() {
    const classes = useStyles();
    return (
        <Box className={classes.termsWrapper}>
            <div dangerouslySetInnerHTML={{ __html: terms }}></div>
        </Box>
    );
}
