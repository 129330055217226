/**
 * Transforms a regular date field into a formatted date field.
 * @param date
 * @returns The date input, transformed to Unicargo's intended date format.
 */
export const getFormattedDate = (date: string) => {
    return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
    });
};

/**
 * Extracts the time from a string date-time value and returns it in the format "HH:mm".
 * @param dateTimeString A string in ISO 8601 date-time format, e.g. "2023-02-16T10:25:30.000Z".
 * @returns A string in the format "HH:mm", e.g. "10:25".
 */
export const getTimeFromDateTime = (dateTimeString: string): string => {
    const date = new Date(dateTimeString);
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minute}`;
};

export function formatNumberWithDecimal(
    number: number | string | null | undefined,
    decimalPlaces = 0,
): string | null | undefined {
    if (number === null || number === undefined) return null;
    if (typeof number === "string") number = parseFloat(number);
    if (isNaN(number)) return null;
    
    const isInteger = Number.isInteger(number);

    return isInteger
        ? number.toLocaleString("en-US", { style: "decimal" }) 
        : number.toLocaleString("en-US", {
            style: "decimal",
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
          }); 
}

export const formatNameToCapitalFirstLetters = (inputString: string) => {
    let result = "";
    let capitalizeNext = true;

    for (let i = 0; i < inputString.length; i++) {
        const char = inputString.charAt(i);

        if (char === " ") {
            result += " ";
            capitalizeNext = true;
        } else if (char === ".") {
            result += ".";
            capitalizeNext = true;
        } else if (char === "(") {
            result += "(";
            capitalizeNext = true;
        } else if (capitalizeNext && /[a-zA-Z]/.test(char)) {
            result += char.toUpperCase();
            capitalizeNext = false;
        } else {
            result += char.toLowerCase();
            capitalizeNext = false;
        }
    }

    return result;
};
