import { IShipment } from "../../../../../models";
import useStyles from "./useStyles";
import { EDetailTabs, TABS } from "./resources";
import { useEffect, useState } from "react";
import { renderTab } from "./ShipmentDetailsTabs.service";
import { renderSkeletonTab } from "./ShipmentDetailsSkeletonTabs.service";
import { tryTrackEvent } from "../../../../../helpers/services/MixPanelService";

interface ShipmentDetailsTabsProps {
    shipment: IShipment;
    shouldShowLoader?: boolean;
}

export const ShipmentDetailsTabs = ({ shipment, shouldShowLoader }: ShipmentDetailsTabsProps) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(EDetailTabs.route);

    useEffect(() => {
        setSelectedTab(EDetailTabs.route);
    }, [shipment]);

    const handleTabClick = (tabIndex: EDetailTabs, label: string) => {
        setSelectedTab(tabIndex);
        tryTrackEvent(`[Shipment drawer]: '${label}' tab clicked`);
    };

    return (
        <div className={classes.root}>
            <div className={classes.tabsContainer}>
                {Object.values(TABS).map((label, index) => {
                    return (
                        <button
                            key={label}
                            className={`${classes.tab} ${selectedTab === index && "selected"}`}
                            onClick={() => handleTabClick(index, label)}
                        >
                            {label}
                        </button>
                    );
                })}
            </div>
            <div className={classes.dynamicContainer}>
                {shouldShowLoader ? renderSkeletonTab(selectedTab) : renderTab(selectedTab, shipment)}
            </div>
        </div>
    );
};
