import { IShipmentQueryParams, ShipmentStatus, ShipmentType } from "../../../../helpers/services/ShipmentService";
import { IShipment } from "../../../../models";
import { TABS } from "../resources";

/**
 * Checks if a shipment object is currently being queried.
 * @param {IShipment} shipment - The shipment to check.
 * @param {String} query - The query to check with.
 * @returns
 */
export const isQueried = (shipment: IShipment, query: string) => {
    const { shipmentDetails, preDelivery } = shipment;
    const { shipmentNumber, shipmentName, fromLocation, destLocation } = shipmentDetails;

    const { purchaseOrderNumber } = preDelivery;

    let isCurrentlyQuried = false;
    if (
        isQueriable(shipmentNumber, query) ||
        isQueriable(shipmentName, query) ||
        isQueriable(fromLocation?.city, query) ||
        isQueriable(destLocation?.city, query) ||
        isQueriable(purchaseOrderNumber, query)
    ) {
        isCurrentlyQuried = true;
    }

    return isCurrentlyQuried;
};

const isQueriable = (word: string | undefined | null, query: string) => {
    if (!word) {
        return false;
    }
    // If array has more then 1 element in it (== not splitted)
    return word.split(new RegExp(`(${query})`, "gi")).length > 1;
};

/**
 * Returns an object of type IShipmentQueryParams corresponding to the given label.
 * @param {string} label - The label to use for getting the corresponding shipment filter object.
 * @param {IShipmentQueryParams} options - An optional object of type IShipmentQueryParams containing additional filter options.
 * @returns {IShipmentQueryParams} An object of type IShipmentQueryParams corresponding to the given label.
 */
export const getQueryByTab = (label: string, options: IShipmentQueryParams = {}) => {
    const activeShipments: IShipmentQueryParams = {
        status: ["destination", "inTransit", "origin"],
    };

    const tabFilters: { [key: string]: IShipmentQueryParams } = {
        [TABS.activeShipments]: {
            ...options,
            status: activeShipments.status,
        },
        [TABS.allShipments]: {
            ...options,
            status: activeShipments.status?.concat("close"),
        },
    };
    return tabFilters[label];
};

/**
 * Update an array of shipment status or shipment type by adding or removing the provided key.
 *
 * @template T The type of the array elements, which must be either `ShipmentStatus` or `ShipmentType`.
 * @param {T[]} queryArray The array of shipment status or shipment type to be updated.
 * @param {T} key The shipment status or shipment type to be added or removed from the array.
 * @returns {void} Nothing is returned from this function.
 */
export const updateQueryObject = <T extends ShipmentStatus | ShipmentType>(queryArray: T[], key: T): void => {
    const index = queryArray.findIndex((value) => value === key);
    if (index === -1) {
        queryArray.push(key);
    } else {
        queryArray.splice(index, 1);
    }
};

export const calcActiveFilterChips = (filtersList: Record<string, boolean>) => {
    let count = 0;
    Object.values(filtersList).forEach((value) => {
        if (value) {
            count++;
        }
    });
    return count;
};
