import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    infoIcon: {
        height: "36px",
        width: "30px",
        display: "flex",
        alignItems: "center",
    },
});
