export enum EShipmentSteps {
    orderConfirmed, // ATA
    booked, // ATA - actually its mainCarriageETD
    cargoReady, // ETD, ATD
    // steps coming from the server
    pickup, // all 4 time values
    mainCarriage, // all 4 time values
    transshipment1, // all 4 time values
    transshipment2, // all 4 time values
    transshipment3, // all 4 time values
    onCarriage, // all 4 time values
    availableForDelivery, // ATA
    pickupForDelivery, // all 4 time values
}
