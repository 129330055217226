import { FC, useEffect, useState } from "react";
import SpinnerLoader from "../../../components/ui-decorations/SpinnerLoader/SpinnerLoader";
import { sendCustomPostMessageToParent } from "../../../helpers/services/PostMessageService.service";
import styles from "./LoadingYaadSarigQueryParamsPaymentPage.module.scss";

// ! TODO: This whole loading page should exist in the BE to avoid accidentally closing the window while loading which will escape the payment flow before sending the payment result query params to the BE & the parent window
const LoadingYaadSarigQueryParamsPaymentPage: FC = () => {
    const [urlQueryString, setUrlQueryString] = useState<string | null>(null);

    useEffect(() => {
        // on component mount, send a message to the parent window to notify it that the payment flow has started
        sendCustomPostMessageToParent({
            message: "customMessage",
            status: "pending",
            messageData: "paymentFlowStarted",
        });
    }, []);

    useEffect(() => {
        if (!urlQueryString) return;
        // Using postMessage to send the payment result query params to the parent window
        sendCustomPostMessageToParent({
            message: "customMessage",
            status: "success",
            messageData: urlQueryString,
        });
    }, [urlQueryString]);

    useEffect(() => {
        setUrlQueryString(window.location.search);
    }, []);

    return (
        <div className={styles.LoadingYaadSarigQueryParamsPaymentPage}>
            <h1 className={styles.PageTitle}>Proccessing ...</h1>
            <div className={styles.PageContent}>
                <div className={styles.PageContentTitle}>{"Please wait :)"}</div>
                <SpinnerLoader size="80px" />
            </div>
        </div>
    );
};

export default LoadingYaadSarigQueryParamsPaymentPage;
