export const STRINGS = {
    nullPlaceholder: "TBD",
};

interface IBaseLabelsVertical {
    [key: string]: string;

    cargoReady: string;
    pickupDeparture: string;
    pickupArrival: string;
    mainCarriageDeparture: string;
    mainCarriageArrival: string;
    transshipment1Departure: string;
    transshipment1Arrival: string;
    transshipment2Departure: string;
    transshipment2Arrival: string;
    transshipment3Departure: string;
    transshipment3Arrival: string;
    destinationPort: string; // (ETA of last available mainCarriage process step) The text to display when the current step is the actual destination port
    onCarriageDeparture: string;
    onCarriageArrival: string;
    pickupForDeliveryDeparture: string;
    pickupForDeliveryArrival: string;
}
interface IActualLabelsVertical extends IBaseLabelsVertical {
    orderConfirmedDate: string;
    booked: string;
    availableForDeliveryDate: string; // !
}

export const ACTIVE_STEP_LABELS: IActualLabelsVertical = {
    orderConfirmedDate: "Shipment Created",
    booked: "Booking Confirmed",
    cargoReady: "Booking Confirmed",
    pickupDeparture: "Confirmed Pickup",
    pickupArrival: "Confirmed Arrival to Port",
    mainCarriageDeparture: "Confirmed Departure",
    mainCarriageArrival: "Confirmed Arrival at Final Port",
    availableForDeliveryDate: "Available for Delivery",
    pickupForDeliveryDeparture: "Confirmed Pickup for Delivery",
    pickupForDeliveryArrival: "Delivered",
    // Need to be like mainCarriageDeparture
    transshipment1Departure: "Confirmed Departure",
    transshipment1Arrival: "Confirmed Departure",
    transshipment2Departure: "Confirmed Departure",
    transshipment2Arrival: "Confirmed Departure",
    transshipment3Departure: "Confirmed Departure",
    transshipment3Arrival: "Confirmed Departure",
    destinationPort: "Confirmed Departure",
    onCarriageDeparture: "Confirmed Departure",
    onCarriageArrival: "Confirmed Departure",
};
