import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    iconContainer: {
        "& path": {
            fill: (props: any) => props.color,
        },
    },
});

export default useStyles;
