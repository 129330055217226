import { Box } from "@mui/system";
import { FormInputMultiCheckbox } from "../form-elements/checkbox/FormInputCheckbox";
import { ExclamationInfoText, ISecondPhaseProps, checkboxOptions } from "./SecondPhaseUtils";
import ExclamtionInfo from "../exclamation-info/ExclamationInfo";
import { useStyles } from "./useStyles";

const SecondPhaseInsure = ({ control, setFormValue, initialValue }: ISecondPhaseProps) => {
    const classes = useStyles();
    return (
        <Box className={classes.insureWrapper}>
            <FormInputMultiCheckbox
                control={control}
                name="goodsInsurance"
                required={false}
                label="insurance"
                options={checkboxOptions}
                setValue={setFormValue}
                initialValue={initialValue}
            />
            <ExclamtionInfo text={ExclamationInfoText} />
        </Box>
    );
};

export default SecondPhaseInsure;
