import "./Block.scss";
import { Box } from "@mui/material";
import { MuiTheme } from "../../../helpers";
import { IBlock } from "../../../models";

const Block = ({ className, children, ...rest }: IBlock) => (
    <MuiTheme>
        <Box className={`Block ${className ? className : ""}`} {...rest}>
            {children}
        </Box>
    </MuiTheme>
);

export default Block;
