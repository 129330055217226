import { Popper } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import { ScreenWidthType } from "../../assets/data/ui";
import { ReactComponent as BoxIcon } from "../../assets/icons/box.svg";
import { ReactComponent as PaymentsIcon } from "../../assets/icons/payments.svg";
import { ReactComponent as QuotesIcon } from "../../assets/icons/quotes.svg";
import { ReactComponent as RectanglesIcon } from "../../assets/icons/rectangles.svg";
import { ReactComponent as WarehouseIcon } from "../../assets/icons/warehouse.svg";
import { useScreenWidthType } from "../../helpers/custom-hooks";
import { useOutsideClick } from "../../helpers/custom-hooks/useOutsideClick";
import { tryTrackEvent } from "../../helpers/services/MixPanelService";
import { INavLinkObject } from "../../models";
import { useAppSelector } from "../../store";
import { NavigationBarTooltip } from "./navigation-bar-tooltip/NavigationBarTooltip";

import styles from "./NavigationBar.module.scss";

const NavigationBar = () => {
    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const popperRef = useRef<HTMLDivElement>(null);
    const userData = useAppSelector((state) => state.rootReducer.users);

    useEffect(() => {
        // Display the tooltip at the starting point only if a new user signed in
        if (userData.isNewUser) setIsTooltipOpen(userData.isNewUser);
    }, [userData.isNewUser]);

    const navLinks: INavLinkObject[] = [
        {
            navTo: "/overview",
            icon: RectanglesIcon,
            label: "Overview",
        },
        {
            navTo: "/shipments",
            icon: BoxIcon,
            label: "Shipments",
        },
    ];
    if (!isMobile)
        navLinks.push(
            {
                navTo: "/payments",
                icon: PaymentsIcon,
                label: "Payments",
                specialFillIcon: true,
            },
            {
                navTo: "javascript:void(0)",
                icon: WarehouseIcon,
                label: "Warehouse",
                isComingSoon: true,
            },
            {
                navTo: "javascript:void(0)",
                icon: QuotesIcon,
                label: "Quotes",
                isComingSoon: true,
            },
        );

    useOutsideClick(popperRef, () => {
        setIsTooltipOpen(false);
    });

    const loadNavLinks = (navLinksArray: INavLinkObject[]) => {
        const navLinkNormalClasses = `${styles.iconContainer} ${styles.navigationTab}`;
        const specialNavLinkClasses = `${navLinkNormalClasses} ${styles.specialIcon}`;
        return navLinksArray.map((navLink) => {
            const IconComponent = navLink.icon;
            const { label, navTo } = navLink;
            const navLinkClasses = navLink.specialFillIcon ? specialNavLinkClasses : navLinkNormalClasses;

            const handleClick = () => {
                if (label === "Warehouse" || label === "Quotes") {
                    setIsTooltipOpen(true);
                } else if (label === "Shipments") {
                    tryTrackEvent(`[Navbar]: ${label}' option clicked`);
                }
            };

            return (
                <li className={styles.navigationTab} key={label}>
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? `${navLinkClasses} ${styles.activeTab}` : `${navLinkClasses}`
                        }
                        to={navTo}
                        onClick={handleClick}
                    >
                        <IconComponent />
                        {navLink.isComingSoon && <span className={styles.comingSoonText}>Soon</span>}
                        <span>{label}</span>
                    </NavLink>
                </li>
            );
        });
    };

    return (
        <nav className={styles.NavigationBar}>
            <ul className={styles.navigationTabsContainer}>{loadNavLinks(navLinks)}</ul>
            {!isMobile && (
                <Popper
                    ref={popperRef}
                    open={isTooltipOpen}
                    style={{
                        zIndex: 2,
                        top: 355,
                        left: 62,
                    }}
                >
                    <NavigationBarTooltip onClose={() => setIsTooltipOpen(false)} />
                </Popper>
            )}
        </nav>
    );
};

export default NavigationBar;
