import { makeStyles } from "@mui/styles";
import { TYPOGRAPHY, UiSchemeV2 } from "../../../../../../../assets/data/ui";

const paddingLeftMobile = "1.5rem";
const paddingBottomMobile = "1.5rem";
const useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        gap: "10px",
        padding: `1.5rem 0 ${paddingBottomMobile} ${paddingLeftMobile}`,
        position: "relative",
        width: "inherit",
        overflow: "hidden",
        textTransform: "capitalize",
    },

    sumOfDays: {
        paddingTop: "1rem",
        fontWeight: "700",
        fontSize: TYPOGRAPHY.title,
        color: UiSchemeV2.colors.primaryDark,
    },
});

export default useStyles;
