import { useLottie } from "lottie-react";
import rfqModalAnimationJson from "../../../assets/animations/unicargo-rfq-animation.lottie.json";

export const RFQModalAnimation = () => {
    const options = {
        animationData: rfqModalAnimationJson,
        loop: false,
        autoplay: true,
        style: {
            width: "1260px",
            height: "563px",
        },
    };

    const { View } = useLottie(options);

    return <>{View}</>;
};
