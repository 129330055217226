import { createTheme } from "@mui/material";
import { COLORS } from "../../../assets/data/ui";

export const stepperTheme = createTheme({
    components: {
        MuiLinearProgress: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: COLORS.general.dividers,
                },
                barColorPrimary: {
                    backgroundColor: COLORS.success,
                },
            },
        },
    },
});
