import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { Box, Modal, FormControl } from "@mui/material";
import { useStyles } from "./useStyles";
import { ButtonText, IHelpType, TextModal, helpTypeContent, radioOptions } from "./RfqFinalModalUtil";
import { FormInputRadio } from "../../form-elements/radio/FormInputRadio";
import { useForm } from "react-hook-form";
import { FormInputTextField } from "../../form-elements/text-field/formInputTextField";
import { Chip, ContainedButton } from "../../../buttons";
import { IFinaltModalPhaseFormInput } from "../../../../models/rfq/rfq-types";
import { ExtraDetails, useSendSubmitRfqMutation } from "../../../../store/api-slices/rfqSlice.api";
import { tryTrackEvent } from "../../../../helpers/services/MixPanelService";
import { useScreenWidthType } from "../../../../helpers/custom-hooks";
import { ScreenWidthType } from "../../../../assets/data/ui";

interface IRfqModalProps {
    isOpen: boolean;
    setIsOpen: () => void;
    toggleSuccessModal: () => void;
}

const RfqFinalModal = ({ isOpen, setIsOpen, toggleSuccessModal }: IRfqModalProps) => {
    const classes = useStyles();
    const { firstPhaseForm, secondPhaseForm, thirdPhaseForm, finalPhaseForm, shipmentMethod } = useAppSelector(
        (state) => state.rootReducer.rfq,
    );
    const [isCoupon, setIsCoupon] = useState<boolean>(false);
    const [needHelpType, setNeedHelpType] = useState<IHelpType[]>(helpTypeContent);
    const [emailSentCounter, setEmailSentCounter] = useState<number>(0);
    const methods = useForm<IFinaltModalPhaseFormInput>();
    const [triggerRfq] = useSendSubmitRfqMutation();
    const { handleSubmit, control } = methods;

    const sendEmail = (isSubmitButtonClicked: boolean) => {
        const extraDetails: ExtraDetails = {
            finalModalPhaseForm: {
                ...methods.getValues(),
                helpeType: needHelpType.filter((help) => help.isSelected).map((help) => help.label),
            },
            email: finalPhaseForm?.email,
            fullName: finalPhaseForm?.fullName,
            companyName: finalPhaseForm?.companyName,
        };

        triggerRfq({
            firstPhaseForm: firstPhaseForm,
            secondPhaseForm: secondPhaseForm,
            thirdPhaseForm: thirdPhaseForm,
            finalPhaseForm: finalPhaseForm,
            shipmentMethod: shipmentMethod,
            extraDetails: extraDetails,
        }).then((result: any) => {
            if (result.data.statusCode === 200) {
                setEmailSentCounter((prev) => prev + 1);
            }
        });

        handleFinalModalCloseModal(isSubmitButtonClicked);
    };

    const onSubmit = () => {
        sendEmail(true);
    };

    const handleFinalModalCloseModal = (isSubmitButtonClicked: boolean) => {
        if (isSubmitButtonClicked) {
            tryTrackEvent("[Rfq Personalize Popup]: Submit button clicked");
        } else {
            tryTrackEvent("[Rfq Personalize Popup]: Surrounding screen clicked");
        }
        setNeedHelpType([]);
        setIsOpen();
        toggleSuccessModal();
    };
    const onChipSelected = (index: number) => {
        const updatedContent = [...helpTypeContent];
        updatedContent[index].isSelected = !updatedContent[index].isSelected;
        setNeedHelpType(updatedContent);
    };

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (emailSentCounter === 0) sendEmail(false);
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [
        needHelpType,
        firstPhaseForm,
        secondPhaseForm,
        thirdPhaseForm,
        finalPhaseForm,
        shipmentMethod,
        emailSentCounter,
    ]);

    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;
    const finalStyleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isMobile ? "370px" : "620px",
        minHeight: "540px",
        overflow: "auto",
        bgcolor: "background.paper",
        borderRadius: "4px",
        boxShadow: 24,
    };

    return (
        <Modal open={isOpen} onClose={() => sendEmail(false)}>
            <Box sx={finalStyleModal}>
                <Box className={classes.modal}>
                    <Box className={classes.modalHeader}>
                        <Box className={classes.modalHeaderContent}>
                            <span className={classes.headerTitle}>{TextModal.textHeaderFirst}</span>
                            <span className={classes.headerSubTitle}>{TextModal.textHeaderSecond}</span>
                            <span className={`${classes.headerSubTitle} ${classes.mtm} ${classes.mbm}`}>
                                {TextModal.textHeaderThird}
                            </span>
                        </Box>
                        <Box className={classes.content}>
                            <span className={classes.shippedRadioText}>{TextModal.radioText}</span>
                            <FormControl className={classes.shipedRadioWrapper}>
                                <FormInputRadio
                                    name="shipped"
                                    control={control}
                                    required={true}
                                    label={""}
                                    options={radioOptions}
                                />
                            </FormControl>
                        </Box>
                        <Box className={classes.content}>
                            <span className={classes.shippedRadioText}>{TextModal.helpType}</span>
                            <Box className={classes.helpTypeContent}>
                                {needHelpType.map((chip, index) => (
                                    <Chip
                                        key={chip.label}
                                        label={chip.label}
                                        selected={chip.isSelected}
                                        onSelect={() => onChipSelected(index)}
                                        isRfqChip
                                    />
                                ))}
                            </Box>
                        </Box>
                        <Box className={classes.couponContainer}>
                            <span className={classes.shippedRadioText}>
                                {TextModal.textCode}
                                <a className={classes.couponCode} onClick={() => setIsCoupon(!isCoupon)}>
                                    Insert
                                </a>
                            </span>
                            <Box className={classes.couponContent}>
                                {isCoupon && (
                                    <FormControl className={classes.inputWrapper}>
                                        <FormInputTextField
                                            type="text"
                                            size="md"
                                            required={true}
                                            name="couponCode"
                                            control={control}
                                            label={"Enter your affiliate code"}
                                            maxLength={15}
                                        />
                                    </FormControl>
                                )}
                            </Box>
                        </Box>
                        <ContainedButton
                            className={`${classes.button} ${classes.noBoxShadow}`}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {ButtonText.submit}
                        </ContainedButton>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default RfqFinalModal;
