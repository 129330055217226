import useStyles from "./useStyle";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormInputDropdown } from "../RFQ/form-elements/dropdown/formInputDropdown";
import { ContainedButton } from "../../components";
import { HeaderTexts, subjectDropdownOptions } from "./contactUsFormUtils";
import { useAppSelector } from "../../store";
import { FormInputTextField } from "../../components/RFQ/form-elements/text-field/formInputTextField";
import { TextFieldContactUs } from "../RFQ/form-elements/text-field/textField";
import { useSendEmailMutation } from "../../store/api-slices/customersSlice.api";
import { IContactFormInput } from "../../models/contact/contact-types";
import { IShipment } from "../../models";
import PhoneInput from "../RFQ/Phone-input/PhoneInput";
import { useState } from "react";
import ContactSuccessModal from "./ContactSuccessModal";
import { validateEmail } from "../../pages/rfq/final-form/finalPhaseFormUtils";
import { tryTrackEvent } from "../../helpers/services/MixPanelService";

export interface IContactUsProps {
    shipment?: IShipment;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactUsForm = ({ shipment, setIsOpen }: IContactUsProps) => {
    const classes = useStyles();
    const user = useAppSelector((state) => state.rootReducer.users);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [first, ...last] = user.name.split(" ");
    const methods = useForm<any>({
        defaultValues: {
            shipmentNumber: shipment?.shipmentDetails?.shipmentNumber ? shipment?.shipmentDetails.shipmentNumber : "",
            customerName: shipment?.shipmentDetails?.c_customerReferenceID
                ? shipment?.customerCode
                : user?.customerName ?? "",
            firstName: first,
            lastName: last.join(" "),
            email: user.email,
            phoneNumber: "",
            subject: shipment ? `${shipment.shipmentDetails.shipmentNumber}:` : "",
            message: "",
        },
    });

    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = methods;
    const [triggerEmail, emailResult] = useSendEmailMutation();
    const onSubmit = (data: IContactFormInput) => {
        triggerEmail({
            ...data,
            userId: user.sub!,
            customerCode: user.code,
            customerName: user.customerName,
        });
        setIsSuccess(true);
        tryTrackEvent("[Lets talk popUp]: 'Submit' button clicked");
    };

    return (
        <>
            {!isSuccess ? (
                <Box className={classes.formWrapper}>
                    <Box className={classes.headerWrapper}>
                        <h1 className={classes.header}>{HeaderTexts.mainHeader}</h1>
                        <h1 className={classes.header}>{HeaderTexts.subHeader}</h1>
                    </Box>
                    <Box className={classes.formbox}>
                        <div className={classes.formLine}>
                            <FormInputTextField
                                type="text"
                                size="md"
                                required={true}
                                name="firstName"
                                control={control}
                                label={"First Name"}
                                borderRadius="16px"
                            />
                            <FormInputTextField
                                type="text"
                                size="md"
                                required={true}
                                name="lastName"
                                control={control}
                                label={"Last Name"}
                                borderRadius="16px"
                            />

                            {/* TODO: change to autoComplete */}
                        </div>
                        <div className={classes.formLine}>
                            <FormInputTextField
                                type="text"
                                size="md"
                                required={true}
                                name="email"
                                control={control}
                                label={"Email"}
                                borderRadius="16px"
                                validation={validateEmail}
                            />

                            <PhoneInput
                                required={false}
                                name="phoneNumber"
                                control={control}
                                label={"Phone-Number"}
                                dropdownWidth="230px"
                                dropdownWidthMobile="257px"
                            />

                            {/* TODO: change to autoComplete */}
                        </div>
                        <div className={classes.formLine}>
                            {/* //{shipment ? } */}
                            {shipment ? (
                                <FormInputTextField
                                    type="text"
                                    size="md"
                                    required={true}
                                    name="subject"
                                    control={control}
                                    label={"Subject"}
                                    borderRadius="16px"
                                />
                            ) : (
                                <FormInputDropdown
                                    required={true}
                                    items={subjectDropdownOptions}
                                    name="subject"
                                    control={control}
                                    label="Subject"
                                />
                            )}
                        </div>
                        <div className={classes.formLine}>
                            <TextFieldContactUs
                                size="md"
                                type="text"
                                name="message"
                                control={control}
                                required={true}
                                label={"How can we help you?*"}
                                borderRadius="16px"
                            />
                        </div>
                    </Box>
                    <ContainedButton disabled={!isValid} className={classes.button} onClick={handleSubmit(onSubmit)}>
                        Submit
                    </ContainedButton>
                </Box>
            ) : (
                <ContactSuccessModal />
            )}
        </>
    );
};

export default ContactUsForm;
