import styles from "./SidebarMobile.module.scss";
import { MenuBurgerIcon, EmailIcon, LogOutIcon, ArrowLeftIcon, SearchBlackIcon } from "../../../assets/icons";
import { UnicargoLogo } from "../../../assets/images";
import { Dialog, SwipeableDrawer } from "@mui/material";
import { OutlinedButton } from "../../buttons";
import { useEffect, useState } from "react";
import NavigationBar from "../../navigation-bar/NavigationBar";
import { useAppDispatch } from "../../../store";
import ContactUsForm from "../../contactus/ContactUs";
import { useNavigate } from "react-router-dom";
import { handleFocus } from "../../../store/reducers/searchBarSlice";
import { userLogOut } from "../../../store/reducers/usersSlice.store";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";

const SidebarMobile = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
    }, [isScrolling]);

    const handleGetQuote = () => {
        navigate("/rfq");
    };
    const handleLogout = () => {
        dispatch(userLogOut());
    };
    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        if (!isScrolling) {
            if (currentScrollY > 145) {
                setIsScrolling(true);
            }
        }
        if (currentScrollY < 145) {
            setIsScrolling(false);
        }
    };

    const emailUsBtnPress = () => {
        setIsOpen(true);
        tryTrackEvent("[NavBar]: 'Email us' button clicked (in mobile)");
    };

    return (
        <section className={styles.SidebarMobile}>
            {/* Think of a better name */}
            <div className={styles.headContainer}>
                <div className={styles.iconsContainer}>
                    <MenuBurgerIcon stroke="none" onClick={() => setIsDrawerOpen(true)} />
                    <UnicargoLogo key={"mobile-log"} />
                </div>
                <div className={styles.quoteButtonContainer}>
                    <SearchBlackIcon onClick={() => dispatch(handleFocus())} />
                    <OutlinedButton onClick={() => handleGetQuote()}>{"Get A Quote >"}</OutlinedButton>
                </div>
            </div>
            <SwipeableDrawer
                className={styles.sidebarDrawerContainer}
                anchor="left"
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                onOpen={() => setIsDrawerOpen(true)}
                PaperProps={{ className: styles.sidebarDrawerPaperContainer }}
            >
                <div className={styles.sidebarDrawerInnerContainer}>
                    <div className={styles.returnIconContainer}>
                        <ArrowLeftIcon className={styles.closeDrawerIcon} onClick={() => setIsDrawerOpen(false)} />
                    </div>
                    <div className={styles.sidebarDrawerBodyContainer}>
                        <div className={styles.navigationBarContainer}>
                            <NavigationBar />
                        </div>
                        <div className={styles.utilsContainer}>
                            <div className={styles.emailUsIconContainer}>
                                <button
                                    onClick={() => emailUsBtnPress()}
                                    className={`${styles.icon} ${styles.emailUsIconContainer}`}
                                >
                                    <EmailIcon />
                                    {"Email us"}
                                </button>
                                <Dialog
                                    open={isOpen}
                                    onClose={() => setIsOpen(false)}
                                    PaperProps={{
                                        style: {
                                            margin: "0",
                                        },
                                    }}
                                >
                                    {/* Inject form component here */}
                                    <ContactUsForm setIsOpen={setIsOpen} />
                                </Dialog>
                            </div>
                            <div className={`${styles.iconContainer} ${styles.logOutContainer}`} onClick={handleLogout}>
                                <LogOutIcon />
                                Log out
                            </div>
                        </div>
                    </div>
                </div>
            </SwipeableDrawer>
        </section>
    );
};

export default SidebarMobile;
