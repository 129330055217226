import { CargoReadyTime } from "../../../models/rfq/rfq-types";

export const HeaderTexts = {
    stepOne: "My cargo will be ready to ship",
    stepTwo: "How can we reach you?",
    additionalTextHeader: "Anything else to add?",
};
export const ButtonText = "Review and finalize";
export const SubmitButtonText = "Submit";
export const radioOptions = [
    {
        label: "Today",
        value: CargoReadyTime.Now,
    },
    {
        label: "In 2 weeks",
        value: CargoReadyTime.TwoWeeks,
    },
    {
        label: "At a later date",
        value: CargoReadyTime.Later,
    },
];
