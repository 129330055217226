import { Dialog } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { AssistanceBlock, Block, NewsBlock, RFQBlock, NotificationsBlock } from "../../components";
import NotificationMarkAllPopup from "../../components/blocks/notifications/NotificationMarkAllPopup";
import { ShipmentsBlock } from "../../components/blocks";
import { tryTrackEvent } from "../../helpers/services/MixPanelService";
import { useAppDispatch, useAppSelector } from "../../store";
import { useUpdateUserByEmailMutation } from "../../store/api-slices/customersSlice.api";
import { updateIsNewUser } from "../../store/reducers/usersSlice.store";
import useStyles from "./useStyles";
import Header from "../../components/overview/Header";
import { useLazyGetNotificationsByCustomerCodeQuery } from "../../store/api-slices/notificationsSlice.api";

type ComponentNamesToWaitFor = "shipments" | "news" | "notifications";

const OverviewPage = () => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const userState = useAppSelector((state) => state.rootReducer.users);
    const dispatch = useAppDispatch();
    const [nameShipmentModal, setNameShipmentModal] = useState(!!userState.isNewUser);
    const [triggerPut] = useUpdateUserByEmailMutation();
    const [getNotifications, { currentData: { notifications } = { notifications: [] } }] = useLazyGetNotificationsByCustomerCodeQuery();
    const [isNewsLoading, setIsNewsLoading] = useState(true);
    const [componentsDataFinishedLoadingStatus, setComponentsDataFinishedLoadingStatus] = useState({
        shipments: false,
        notifications: false,
    });

    useEffect(() => {
        tryTrackEvent("[Overview Page]: Page viewed");
        (async () => {
            await getNotifications({ customerCode: userState.code });
        })();
    }, [userState.code]);

    const shouldShowLoader = useMemo(() => {
        const isAnyComponentLoading = Object.values(componentsDataFinishedLoadingStatus).some((value) => !value);
        return isAnyComponentLoading;
    }, [componentsDataFinishedLoadingStatus]);

    const handleGotItClicked = () => {
        setNameShipmentModal(false);
        dispatch(updateIsNewUser());
        triggerPut({ email: userState.email, isNewUser: false });
    };

    const handleComponentFinishedLoading = (componentName: ComponentNamesToWaitFor) => {
        setComponentsDataFinishedLoadingStatus((prev) => ({ ...prev, [componentName]: true }));
    };

    return (
        <div
            className={`${classes.OverviewPage} ${
                !notifications?.length && !shouldShowLoader && classes.OverviewPageNoNotifications
            }`}
        >
            <Header shouldShowLoader={shouldShowLoader} />
            <Block className={classes.notificationsSection}>
                {isOpen && (
                    <Dialog open={true} onClose={() => setIsOpen(false)}>
                        <NotificationMarkAllPopup markAllAsRead={setIsOpen} getNotifications={getNotifications} />
                    </Dialog>
                )}
                <NotificationsBlock
                    setIsOpen={setIsOpen}
                    notifications={notifications}
                    shouldShowLoader={shouldShowLoader}
                    onFinishedLoading={() => handleComponentFinishedLoading("notifications")}
                />
            </Block>
            <Block className={classes.activeShipmentsSection}>
                <ShipmentsBlock
                    isShowModal={nameShipmentModal}
                    setShowModal={handleGotItClicked}
                    onFinishedLoading={() => handleComponentFinishedLoading("shipments")}
                    shouldShowLoader={shouldShowLoader}
                />
            </Block>
            <Block className={classes.RFQBlockSection}>
                <RFQBlock shouldShowLoader={shouldShowLoader} />
            </Block>
            <Block className={classes.newsBlockSection}>
                <NewsBlock onFinishedLoading={() => setIsNewsLoading(false)} shouldShowLoader={isNewsLoading} />
            </Block>
            <Block className={classes.assistanceBlockSection}>
                <AssistanceBlock shouldShowLoader={shouldShowLoader} />
            </Block>
        </div>
    );
};

export default OverviewPage;
