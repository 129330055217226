import dayjs, { Dayjs } from "dayjs";
import { InvoicesTableEmptyStateType } from "../../components/InvoicesTable/InvoicesTableEmptyState";
import {
    YYYY_MM_DD,
    DateRangeFilterOptions,
    IDateRangeFilterOptionValue,
    IFilter,
    IFilterOption,
    IFilters,
    InvoicesFilterKeyNames,
    IDateRangeFilter,
} from "../../models/filters/filters.interfaces";
import { ValidInvoiceStatus } from "../../models/invoices/invoices.interfaces";
export const getSelectedOptionsFromAllOptions = (options: IFilterOption[]): IFilterOption[] =>
    options.filter((option) => option.isSelected);

export const getDatesFromSelectedOptions = (
    selectedDateOption: DateRangeFilterOptions,
): IDateRangeFilterOptionValue => {
    const today = dayjs().format(YYYY_MM_DD);
    const firstDayOfThisMonth = dayjs().startOf("month").format(YYYY_MM_DD);
    const firstDayOfLastMonth = dayjs().subtract(1, "month").startOf("month").format(YYYY_MM_DD);
    const lastDayOfLastMonth = dayjs().subtract(1, "month").endOf("month").format(YYYY_MM_DD);
    const firstDayOfThisYear = dayjs().startOf("year").format(YYYY_MM_DD);
    switch (selectedDateOption) {
        case DateRangeFilterOptions.AllDates:
            return DateRangeFilterOptions.AllDates;
        case DateRangeFilterOptions.CurrentMonth:
            return [firstDayOfThisMonth, today];
        case DateRangeFilterOptions.LastMonth:
            return [firstDayOfLastMonth, lastDayOfLastMonth];
        case DateRangeFilterOptions.CurrentYear:
            return [firstDayOfThisYear, today];
        case DateRangeFilterOptions.Custom:
            return DateRangeFilterOptions.Custom;
        default:
            return DateRangeFilterOptions.AllDates;
    }
};

// This function prints the filters in a table format indicating which options are selected
export const printFilters = (filters: IFilters, prefixString = "") => {
    const filtersPrintingObj = filters.reduce((acc, filter) => {
        return {
            ...acc,
            [filter.label]: filter.options.reduce((acc, option) => {
                return {
                    ...acc,
                    [option.label]: option.isSelected,
                };
            }, {}),
        };
    }, {});
    console.table(filtersPrintingObj);
};

export const selectDeselectAll = (allOptions: IFilterOption[], deselectAllInstead = false): IFilterOption[] =>
    allOptions.map((option) => ({
        ...option,
        isSelected: !deselectAllInstead,
    }));

export const getAllOptionsWithUpdatedOption = (
    allOptions: IFilterOption[],
    updatedOption: IFilterOption,
    isSingleChoice: boolean,
): IFilterOption[] =>
    isSingleChoice
        ? allOptions.map((option) => {
              if (option.label === updatedOption.label) return updatedOption;
              return {
                  ...option,
                  isSelected: false,
              };
          })
        : allOptions.map((option) => {
              if (option.label === updatedOption.label) option = updatedOption;
              return option;
          });

export const getNewUpdatedFilterWithUpdatedOption = (
    filter: IFilter,
    updatedOption: IFilterOption,
    isSingleChoice: boolean,
): IFilter => ({
    ...filter,
    options: getAllOptionsWithUpdatedOption(filter.options, updatedOption, isSingleChoice),
});

export const getNewFiltersWithNewFilter = (filters: IFilters, updatedFilter: IFilter): IFilters => {
    const newFilters = filters.map((filter) => {
        if (filter.label === updatedFilter.label) return updatedFilter;
        return filter;
    });
    return newFilters;
};

export const getInvoicesEmptyStateType = (filters: IFilters): InvoicesTableEmptyStateType => {
    let isStatusFilterOnlyUnpaid = false;
    let isStatusFilterSelected = false;
    const isThereAnyFilterSelectedExceptStatus = filters.some((filter) => {
        if (filter.keyName === InvoicesFilterKeyNames.STATUS) {
            isStatusFilterOnlyUnpaid = filter.options.every((option) => {
                if (option.isSelected) {
                    isStatusFilterSelected = true;
                    return option.value === ValidInvoiceStatus.UNPAID;
                }
                return option.value !== ValidInvoiceStatus.UNPAID;
            });
            return false;
        }
        return filter.options.some((option) => option.isSelected);
    });

    if (isStatusFilterOnlyUnpaid && !isThereAnyFilterSelectedExceptStatus) {
        return InvoicesTableEmptyStateType.NoUnpaid;
    }
    if (isThereAnyFilterSelectedExceptStatus || (isStatusFilterSelected && !isStatusFilterOnlyUnpaid)) {
        return InvoicesTableEmptyStateType.NoMatched;
    }
    return InvoicesTableEmptyStateType.NoInvoicesAtAll;
};

export const isStatusFilterOnlyUnpaid = (filter: IFilter): boolean => {
    if (filter.keyName === InvoicesFilterKeyNames.STATUS) {
        return filter.options.every((option) => {
            if (option.isSelected) {
                return option.value === ValidInvoiceStatus.UNPAID;
            }
            return option.value !== ValidInvoiceStatus.UNPAID;
        });
    }
    return false;
};

export const areFiltersSameAsInitialFilters = (filters: IFilters): boolean => {
    return filters.every((filter) => {
        if (filter.keyName === InvoicesFilterKeyNames.STATUS) return isStatusFilterOnlyUnpaid(filter);
        return filter.options.every((option) => !option.isSelected);
    });
};

export const areFiltersCleared = (filters: IFilters): boolean => {
    return filters.every((filter) => {
        return filter.options.every((option) => !option.isSelected);
    });
};

export const getDateRangeFilterCustomDates = (dateRangeFilter: IDateRangeFilter): [Dayjs | null, Dayjs | null] => {
    const customDatesOption = dateRangeFilter.options.find(
        (option) => option.isSelected && option.label === DateRangeFilterOptions.Custom,
    )?.value;
    if (!customDatesOption) return [null, null];
    return [dayjs(customDatesOption[0]), dayjs(customDatesOption[1])];
};
