import { Box } from "@mui/material";
import { ReceiveMailIcon } from "../../assets/icons";
import { SuccessModalTextFirst } from "./contactUsFormUtils";
import { SuccessModalTextSecound } from "./contactUsFormUtils";
import useStyles from "./useStyle";

const ContactSuccessModal = () => {
    const classes = useStyles();
    return (
        <Box className={classes.successModal}>
            <ReceiveMailIcon />
            <span className={classes.successModalText}>
                <div>
                    {SuccessModalTextFirst}
                    <br />
                    {SuccessModalTextSecound}
                </div>
            </span>
        </Box>
    );
};

export default ContactSuccessModal;
