import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IShipmentQueryParams } from "../../helpers/services/ShipmentService";
import { IShipment } from "../../models";

interface IShipmentSlice {
    shipment?: IShipment;
    params?: IShipmentQueryParams;
}
const initialState: IShipmentSlice = {
    shipment: undefined,
    params: undefined,
};
const shipmentsSlice = createSlice({
    name: "shipments",
    initialState,
    reducers: {
        setGlobalShipment(state, action: PayloadAction<IShipmentSlice>) {
            const { shipment, params } = action.payload;
            state.shipment = shipment;
            state.params = params;
        },
        clearGlobalShipment(state) {
            state.shipment = undefined;
            state.params = undefined;
        },
    },
});

export const { setGlobalShipment, clearGlobalShipment } = shipmentsSlice.actions;
export default shipmentsSlice.reducer;
