import { makeStyles } from "@mui/styles";
import { COLORS, UiSchemeV2 } from "../../../assets/data/ui";
import { theme } from "../../../assets/theme/theme";

export const useStyles = makeStyles({
    bannerWrapper: {
        padding: "0 30px",
        display: "flex",
        maxWidth: "720px",
        width: "100%",
        gap: "10px",
        margin: "0px auto 12px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            alignItems: "start",
            padding: "0px",
        },
    },
    bottomDivider:{
        borderBottom: `1px solid ${COLORS.general.dividers}`,
        paddingBottom: "32px",
        marginBottom: "12px"
    },
    iconV: {
        height: "16px",
        width: "16px",
        display: "flex",
        alignItems: "center",
        transform: "translateY(3px)"
    },
    bannerTitle: {
        color: COLORS.main,
        fontWeight: "600",
        fontSize: "14px",
        whiteSpace: "nowrap",
        paddingRight: "20px",
        paddingLeft: "10px",
    },
    bannerDescription: {
        color: COLORS.primaryDark,
        fontSize: "14px",
        fontWeight: "400",
        whiteSpace: "break-spaces",
        overflow: "hidden",
        width: "70%",
        textOverflow: "ellipsis",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    greyWord:{
        color: UiSchemeV2.colors.greys.grey300,
    },
    
    inlineWordDivider: {
        color: UiSchemeV2.colors.greys.grey100,
        margin:"0 8px"
    },

    editIcon: {
        height: "24px",
        width: "14px",
        display: "flex",
        alignItems: "center",
        marginLeft: "auto",
        // button rules
        background: "none",
        color: "inherit",
        border: "none",
        padding: "0",
        font: "inherit",
        cursor: "pointer",
        outline: "inherit",
    },
    textWrapper: {
        display: "flex",
        alignItems: "baseline",
        width: "90%",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            overflow: "hidden",
        },
    },
   
});
