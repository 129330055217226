import { InputBaseClassKey } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const inputBaseStyles: Partial<OverridesStyleRules<InputBaseClassKey>> = {
    //         root: {
    //             height: 42,
    //             "&:focus-within": {
    //                 "&:not(.SearchBarTextField)": {
    //                     border: "1px solid #06cea8",
    //                     boxShadow: "0px 0px 4px rgba(6, 206, 168, 0.23)",
    //                 },
    //                 height: 40, // decrease height when :focus-within so changes to layout won't appear.
    //             },
    //             "&.MuiOutlinedInput-root": {
    //                 input: {
    //                     padding: "0",
    //                 },
    //                 "&.MuiOutlinedInput-root": {
    //                     input: {
    //                         padding: "0",
    //                     },
    //                     // Disable notch + border
    //                     ".MuiOutlinedInput-notchedOutline": {
    //                         border: "none",
    //                     },
    //                 // Disable notch + border
    //                 "& .MuiOutlinedInput-notchedOutline": {
    //                     border: "none",
    //                 },
    //             },
    //         },
    //     }
    // };
    root: {},
};
