import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { MuiTheme } from "../../../../helpers";
import { useStyles } from "./useStyles";
import { COLORS, UiSchemeV2 } from "../../../../assets/data/ui";
import { TextInputWithDropdownProps } from "../formInputProps";
import { Controller } from "react-hook-form";
import { HeightUnit, MeasureType, VolumeUnit, WeightUnit } from "../../../../models/rfq/rfq-types";
import { ArrowDown } from "../../../../assets/icons";
import { useMediaQuery } from "@mui/material";
import { handleNumericInput } from "../../../../pages/rfq/RfqPageUtils";

const TextInputWithUnitDropdown: React.FC<TextInputWithDropdownProps> = ({
    options,
    name,
    control,
    label,
    required,
    unitName,
    setFormValue,
    borderRadius,
}) => {
    const [selectFocus, setSelectFocus] = useState<boolean>(false);
    const classes = useStyles();
    const sxInputSyles = {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
            boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
            borderRadius: "16px",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${COLORS.veryLight} !important`,
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${COLORS.primaryDark} !important`,
        },
        "& .MuiSelect-select": {
            display: "flex",
            justifyContent: "end",
            fontSize: "12px",
            color: COLORS.main,
            alignItems: "center",
            paddingRight: "25px !important",
            borderRadius: "16px",
            background: COLORS.veryLight,
            height: "15px",
        },
        "& svg": {
            right: "9px",
        },
        "& input": {
            color: COLORS.main,
            fontFamily: "Lato-Regular",
            padding: "20px 14px 8px 14px",
        },
        "& legend": {
            span: {
                display: "none",
            },
        },
    };
    const menuPaperSx = {
        padding: "0px",
        maxHeight: "150px",
        maxWidth: "186px !important",
        paddingLeft: "0px",
        marginTop: "10px",
        borderRadius: "16px",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        handleNumericInput(event);
    };

    const handleUnitChange = (event: any) => {
        if (
            event.target.value === WeightUnit.KG ||
            event.target.value === HeightUnit.CM ||
            event.target.value === VolumeUnit.CBM
        ) {
            setFormValue("measureType", MeasureType.Metric);
        } else {
            setFormValue("measureType", MeasureType.Imperial);
        }
    };

    const isMobile = useMediaQuery("(max-width:600px)");

    return (
        <MuiTheme>
            <Controller
                name={name}
                rules={{ required: required }}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <FormControl
                        sx={{
                            width: "100%",
                        }}
                    >
                        <TextField
                            value={value}
                            type="number"
                            className={classes.numericInput}
                            onKeyDown={handleKeyDown}
                            onChange={onChange}
                            onFocus={() => setSelectFocus((prevState) => !prevState)}
                            onBlur={() => setSelectFocus((prevState) => !prevState)}
                            label={label + "*"}
                            sx={sxInputSyles}
                            inputProps={{ inputMode: "numeric" }}
                            InputProps={{
                                endAdornment: (
                                    <Controller
                                        name={unitName}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl sx={{ minWidth: "auto" }}>
                                                <Select
                                                    IconComponent={ArrowDown}
                                                    onOpen={() => setSelectFocus((prev) => !prev)}
                                                    onClose={() => setSelectFocus((prev) => !prev)}
                                                    className={classes.select}
                                                    value={value}
                                                    onChange={(e: any) => {
                                                        onChange(e);
                                                        handleUnitChange(e);
                                                    }}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: menuPaperSx,
                                                        },
                                                    }}
                                                >
                                                    {options.map((unit) => (
                                                        <MenuItem
                                                            sx={{
                                                                padding: "6px 24.5px",
                                                                color: COLORS.main,
                                                                fontSize: "14px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                            key={unit}
                                                            value={unit}
                                                        >
                                                            {unit}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                ),
                                style: {
                                    paddingRight: "0px",
                                    width: "100%",
                                    borderRadius: borderRadius || "4px",
                                },
                                sx: {
                                    "& input": {
                                        padding: "20px 14px 8px 14px",
                                        fontSize: "14px",
                                        color: COLORS.main,
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    fontSize: isMobile ? "0.65rem" : "0.8rem",
                                    color: selectFocus
                                        ? UiSchemeV2.colors.primaryDark
                                        : UiSchemeV2.colors.greys.grey300,
                                },
                                variant: "filled",
                            }}
                        />
                    </FormControl>
                )}
            />
        </MuiTheme>
    );
};

export default TextInputWithUnitDropdown;
